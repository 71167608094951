import React from 'react';
import styled from 'styled-components';

import { H2Headline } from 'components/ui/Headline';
import { Column, Copy, InlineRow, Row, Spacer } from 'ui';
import TextButton from 'components/ui/Button/TextButton';
import { Dialog } from '@material-ui/core';
import Form from 'components/Form';

const ModalContent = styled(Column)`
    padding: 24px;
    width: 400px;
`;

const SpacedCopy = styled(Copy)`
    line-height: 1.6;
`;

const ConstrainedForm = styled(Form)``;

const currencyLabels: { [code: string]: string } = {
    CAD: 'CAD (Canadian dollar)',
    EUR: 'EUR (Euro)',
    GBP: 'GBP (pound sterling)',
    MXN: 'MXN (Mexican peso)',
    USD: 'USD (United States dollar)',
};

export const COUNTRY_TO_CURRENCY: Partial<{ [country: string]: string }> = {
    US: 'USD',
    GB: 'GBP',
    MX: 'MXN',
    CA: 'CAD',
};

export default function CurrencyField({
    field,
    value = 'USD',
    onChange,
    options = [{ id: value }],
    disabled,
}: {
    field: string;
    value: string;
    onChange: (update: { field: string; value: string }) => void;
    options?: { id: string }[];
    disabled?: boolean;
}) {
    const [currency, setCurrency] = React.useState<string | null>(null);

    const selectOptions = options.map(option => ({
        ...option,
        label: currencyLabels[option.id] ?? option.id,
    }));

    return (
        <InlineRow alignItems="center" style={{ minHeight: 40, margin: '8px 0 4px 0' }}>
            <TextButton
                onClick={() => {
                    setCurrency(value);
                }}
                disabled={disabled}
            >
                {value}
            </TextButton>
            <Dialog open={!!currency} onClose={() => setCurrency(null)}>
                <ModalContent>
                    <Column itemSpacing="small">
                        <H2Headline>Change Currency</H2Headline>
                        <SpacedCopy>
                            Update the currency for the entire proposal.
                            <br />
                            Previously entered prices values will not be converted.
                        </SpacedCopy>
                    </Column>
                    <Spacer smallish />
                    <ConstrainedForm
                        fields={{
                            currency: {
                                type: 'select',
                                options: {
                                    options: selectOptions,
                                },
                            },
                        }}
                        schema={[{ fields: ['currency'], spacing: false }]}
                        value={{ currency }}
                        onChange={({ value: { currency } }: { value: { currency: string } }) => {
                            setCurrency(currency);
                        }}
                    />
                    <Spacer large />
                    <Row itemSpacing="smallish">
                        <TextButton
                            onClick={() => {
                                setCurrency(null);
                            }}
                            secondary
                        >
                            Cancel
                        </TextButton>
                        <TextButton
                            onClick={() => {
                                if (currency) onChange({ field, value: currency });
                                setCurrency(null);
                            }}
                        >
                            Update
                        </TextButton>
                    </Row>
                </ModalContent>
            </Dialog>
        </InlineRow>
    );
}
