import React from 'react';

import styled from 'styled-components';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { format, isEqual as isDateEqual, isBefore } from 'date-fns';

import { useSnackbar } from 'notistack';
import { getDateRange, datetimeStrToLocalDate, apiDateToDateObj } from 'utils/date_util';
import { useHistory, useParams, useRouteMatch, matchPath } from 'react-router';
import useUnsavedPrompt from 'hooks/useUnsavedPrompt';
import usePersistentSnackbar from 'hooks/usePersistentSnackbar';

import { useEvent } from 'providers/event';
import { useCurrentInquiry, currentInquiryActions, hasBookedVenue } from 'stores/current-inquiry';

import { Column, Copy, Spacer, Row, LabeledCheckbox } from 'ui';
import { H5Headline } from 'components/ui/Headline';
import { SpinnerOverlay } from 'components/Spinner';
import Button from 'components/ui/Button';
import Form from 'components/Form';
import InquiryCart from 'components/InquiryCart';

import PlannerPage, { ACCORDION_WIDTH } from 'pages/Planner';
import ConfirmationModal from 'components/ConfirmationModal';
import { MeetingHeaderRightFill } from 'pages/CreateMeeting/components/Header';
import { createMeetingActions, ESteps, useCreateMeeting, ValidationError } from 'pages/CreateMeeting/store';

const SINGLE_DATE_FORMAT = 'MMM dd, yyyy';
const START_RANGE_FORMAT = 'MMM dd';
const END_RANGE_FORMAT = 'dd, yyyy';

const BuildInquiryPage = styled(Column)`
    height: 100%;
    max-width: 1080px;
`;

const PageDescription = styled(Copy)`
    line-height: 27px;
`;

const AutoRow = styled(Row)`
    width: auto;
`;

const DatesCopy = styled(Copy)`
    min-width: 140px;
`;

const DatesFlexibleCheckbox = styled(LabeledCheckbox)`
    max-height: 19px;
`;

const NotesForm = styled(Form)`
    width: ${ACCORDION_WIDTH}px;
`;

const notesForm = {
    fields: {
        notes: {
            type: 'textarea',
            prompt: 'Notes',
            options: {
                rows: 5,
                rowsMax: 5,
                placeholder: 'Include any additional details or information',
            },
        },
    },
    schema: [{ key: 'notes', fields: ['notes'], spacing: false }],
};

type TNotesValue = { notes?: string };

const NotesField = ({
    value = {},
    onChange,
    disabled,
}: {
    value?: TNotesValue;
    onChange: (update: { value: TNotesValue }) => void;
    disabled?: boolean;
}) => <NotesForm {...notesForm} value={value} onChange={onChange} disabled={disabled} />;

const getSubmissionError = (eventSpaces?: BizlyAPI.EventSpace[], accommodations?: BizlyAPI.Accommodation[]) => {
    if ((!eventSpaces || eventSpaces.length === 0) && (!accommodations || accommodations.length === 0)) {
        return 'There are no requested bookings to send.';
    }

    const renderError = (name: string, error: string) => (
        <span>
            <b>{`${name}:`}</b>
            <br />
            {`${error}.`}
        </span>
    );

    const getEventSpaceError = ({ date, startTime, endTime, attendees, setupId }: BizlyAPI.EventSpace) => {
        if (!date) return 'Date is required';

        if (!startTime) return 'Start time is required';

        const dateObj = datetimeStrToLocalDate(date, startTime);
        if (isBefore(dateObj, new Date())) return 'Start date cannot be in the past';

        if (!endTime) return 'End time is required';

        if (!setupId) return 'Setup is required';

        if (!attendees) return 'Attendees must be greater than 0';
    };

    if (eventSpaces) {
        const badEventSpaceIdx = eventSpaces.findIndex(getEventSpaceError);
        const badEventSpace = badEventSpaceIdx !== -1 && eventSpaces[badEventSpaceIdx];

        if (badEventSpace) {
            const error = getEventSpaceError(badEventSpace);
            return renderError(badEventSpace.spaceName || `Meeting Space ${badEventSpaceIdx + 1}`, error!);
        }
    }

    const getAccommodationError = ({ date, count }: BizlyAPI.Accommodation) => {
        if (!date) return 'Date is required';

        const dateObj = apiDateToDateObj(date);
        if (isBefore(dateObj, new Date())) return 'Date cannot be in the past';

        if (!count) return 'Rooms must be greater than 0';
    };

    if (accommodations) {
        const badAccommodationIdx = accommodations.findIndex(getAccommodationError);
        const badAccommodation = badAccommodationIdx !== -1 && accommodations[badAccommodationIdx];

        if (badAccommodation) {
            const error = getAccommodationError(badAccommodation);
            return renderError(badAccommodation.roomName || `Guest Rooms ${badAccommodationIdx + 1}`, error!);
        }
    }

    return undefined;
};

export function BuildInquiryContent({ editable, eventId }: { editable?: boolean; eventId: number }) {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    //Inquiry
    const currentInquiry = useCurrentInquiry();
    const currentInquiryId = currentInquiry?.inquiry?.id;

    const [stagedInquiry, setStagedInquiry] = React.useState<Partial<BizlyAPI.Inquiry>>({});
    const hasSaved = isEmpty(stagedInquiry);

    const inquiryFormValue = React.useMemo(() => ({ ...currentInquiry?.inquiry, ...stagedInquiry }), [
        currentInquiry,
        stagedInquiry,
    ]);

    const submitted = !!inquiryFormValue.submittedAt;
    const disabled = !editable || submitted;

    // Planner
    const [plannerData, setPlannerData] = React.useState<BizlyAPI.Planner>({});
    const [plannerHasSaved, setPlannerHasSaved] = React.useState(true);
    const [queuedPlannerSave, setQueuedPlannerSave] = React.useState(false);
    const NONE = '',
        SAVE = 'save',
        SUBMIT = 'submit';
    type TCallback = typeof NONE | typeof SAVE | typeof SUBMIT;
    const [plannerSavedCallback, setPlannerSavedCallback] = React.useState<TCallback>(NONE);

    const { eventSpaces = [], accommodations = [] } = plannerData;
    const [startDate, endDate] = getDateRange(map([...eventSpaces, ...accommodations], 'date'));
    const dateDisplay =
        startDate &&
        endDate &&
        (isDateEqual(startDate, endDate)
            ? format(startDate, SINGLE_DATE_FORMAT)
            : `${format(startDate, START_RANGE_FORMAT)} - ${format(endDate, END_RANGE_FORMAT)}`);

    // Actions
    const persistentSnackbar = usePersistentSnackbar([hasSaved, plannerHasSaved]);

    const [saving, setSaving] = React.useState(false);

    const [showEditConfirm, setShowEditConfirm] = React.useState(false);

    const onEdit = React.useCallback(async () => {
        if (!currentInquiry.inquiry) return;

        setShowEditConfirm(false);
        setSaving(true);
        try {
            const newInquiry = await currentInquiryActions.cancelAndCopy(currentInquiry.inquiry.id);
            if (newInquiry) history.replace(`/event/${eventId}/venue/inquiries/${newInquiry.id}`);
        } catch {
            enqueueSnackbar('Something went wrong. Please try again', { variant: 'error' });
            setSaving(false);
        }
    }, [eventId, enqueueSnackbar, currentInquiry, history]);

    const { isPublished } = useCreateMeeting();
    const { id } = useParams<{ id?: string }>();

    const saveMeeting = React.useCallback(async () => {
        try {
            if (isPublished && id) {
                return await createMeetingActions.savePublished(id);
            }

            const resultMeeting = await createMeetingActions.saveDraft(id);
            if (id === undefined && resultMeeting) {
                history.replace(`/events/${resultMeeting.id}/edit`, { skipLoading: true, step: ESteps.venues });
            }
        } catch (e) {
            if (!(e instanceof ValidationError))
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            throw e;
        }
    }, [enqueueSnackbar, history, id, isPublished]);

    const onSave = React.useCallback(async () => {
        if (!plannerHasSaved) {
            setSaving(true);
            setPlannerSavedCallback(SAVE);
            return setQueuedPlannerSave(true);
        }
        if (plannerSavedCallback) {
            setSaving(false);
            setPlannerSavedCallback(NONE);
        }

        setSaving(true);

        try {
            if (currentInquiryId) await currentInquiryActions.updateDraft(currentInquiryId, inquiryFormValue);
            else await currentInquiryActions.createAndUpdateDraft(eventId, inquiryFormValue);
            setStagedInquiry({});
        } catch {
            enqueueSnackbar('Something went wrong. Please try again', { variant: 'error' });
        } finally {
            setSaving(false);
        }
    }, [plannerHasSaved, plannerSavedCallback, eventId, currentInquiryId, inquiryFormValue, enqueueSnackbar]);

    const publishMeeting = React.useCallback(async () => {
        try {
            const meeting = await createMeetingActions.publish(id);
            if (meeting) {
                if (meeting.published) {
                    history.replace(`/events/${meeting.id}`);
                    return;
                } else if (id === undefined) {
                    history.replace(`/events/${meeting.id}/edit`, { skipLoading: true });
                }
            }
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        } catch (e) {
            if (!(e instanceof ValidationError))
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            throw e;
        }
    }, [enqueueSnackbar, history, id]);

    const onSubmit = React.useCallback(async () => {
        if (!plannerHasSaved) {
            setSaving(true);
            setPlannerSavedCallback(SUBMIT);
            return setQueuedPlannerSave(true);
        }
        if (plannerSavedCallback) {
            setSaving(false);
            setPlannerSavedCallback(NONE);
        }

        const venues = currentInquiry?.inquiry?.venues || [];
        if (venues.length === 0)
            return enqueueSnackbar('There are no venues to send inquiries to.', { variant: 'error' });

        const { eventSpaces, accommodations } = plannerData;
        const submissionError = getSubmissionError(eventSpaces, accommodations);
        if (submissionError) {
            return persistentSnackbar(submissionError, { variant: 'error' });
        }

        const scheduleItemIds = [...eventSpaces, ...accommodations].map(item => item.id);

        setSaving(true);
        try {
            if (currentInquiry?.inquiry?.id) {
                await currentInquiryActions.updateAndSubmit(currentInquiry.inquiry.id, {
                    ...inquiryFormValue,
                    scheduleItemIds,
                });

                setStagedInquiry({});

                try {
                    if (isPublished) {
                        await saveMeeting();
                    } else {
                        await publishMeeting();
                    }
                } catch (e) {
                    setSaving(false);
                    return;
                }
            }
        } catch {
            enqueueSnackbar('Something went wrong. Please try again', { variant: 'error' });
            setSaving(false);
        }
    }, [
        plannerHasSaved,
        plannerSavedCallback,
        plannerData,
        currentInquiry,
        inquiryFormValue,
        enqueueSnackbar,
        persistentSnackbar,
        isPublished,
        saveMeeting,
        publishMeeting,
    ]);
    React.useEffect(() => {
        if (!queuedPlannerSave) {
            if (plannerSavedCallback === SAVE) onSave();
            if (plannerSavedCallback === SUBMIT) onSubmit();
        }
    }, [queuedPlannerSave, plannerSavedCallback, onSave, onSubmit]);

    const curRouteMatch = useRouteMatch();
    const renderUnsavedPrompt = useUnsavedPrompt(!hasSaved, newLocation => {
        const isCreateNew = !currentInquiryId;
        const newLocationIsUpdatedId = !!matchPath(newLocation.pathname, curRouteMatch);
        const newLocationIsSamePath = !!matchPath(newLocation.pathname, {
            path: history.location.pathname,
            exact: true,
        });

        return isCreateNew ? newLocationIsUpdatedId : newLocationIsSamePath;
    });

    return (
        <BuildInquiryPage itemSpacing="large">
            {renderUnsavedPrompt()}
            {editable && (
                <MeetingHeaderRightFill>
                    {submitted ? (
                        <>
                            <Button width={74} onClick={onSave} disabled={saving}>
                                Save
                            </Button>
                            <Button width={135} onClick={() => setShowEditConfirm(true)}>
                                Edit Inquiries
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button width={74} onClick={onSave} disabled={saving}>
                                Save
                            </Button>
                            <Button width={135} onClick={onSubmit} disabled={saving}>
                                Submit Inquiries
                            </Button>
                        </>
                    )}
                </MeetingHeaderRightFill>
            )}

            <Column itemSpacing="default">
                <H5Headline>Build Your Inquiry</H5Headline>
                <PageDescription large>
                    Once you add the venues you are interested in, we just need to know your meeting needs.
                    <br />
                    Once you're confident everything is accurate, you're ready to submit!
                </PageDescription>
            </Column>

            <Column>
                <InquiryCart hideBuildInquiry isCreateMeeting />
                <Spacer small />
            </Column>

            <Column itemSpacing="default">
                <Row itemSpacing="smallish">
                    <AutoRow itemSpacing="xsmall">
                        <Copy>
                            <b>Meeting Date:</b>
                        </Copy>
                        <DatesCopy>{dateDisplay}</DatesCopy>
                    </AutoRow>
                    <DatesFlexibleCheckbox
                        label="Dates are flexible"
                        onChange={(_, checked) =>
                            setStagedInquiry(prevStaged => ({ ...prevStaged, flexibleDates: checked }))
                        }
                        isChecked={!!inquiryFormValue.flexibleDates}
                        disabled={disabled}
                    />
                </Row>

                <PlannerPage
                    asInquiryModule
                    onLoad={setPlannerData}
                    onHasSaved={setPlannerHasSaved}
                    queuedSave={queuedPlannerSave}
                    onQueuedSave={newData => {
                        setPlannerData(newData);
                        setQueuedPlannerSave(false);
                    }}
                    disabled={disabled}
                />

                <NotesField
                    value={inquiryFormValue}
                    onChange={({ value }: { value: TNotesValue }) =>
                        setStagedInquiry(prevStaged => ({
                            ...prevStaged,
                            ...value,
                        }))
                    }
                    disabled={disabled}
                />
            </Column>

            <ConfirmationModal
                onDismiss={() => setShowEditConfirm(false)}
                onProceed={onEdit}
                headline="Warning"
                prompt={
                    <span>
                        Editing your inquiry will cancel all submitted inquiries and reject received proposals.
                        <Spacer small />
                        Do you still want to continue to edit your inquiry?
                    </span>
                }
                isActive={showEditConfirm}
            />

            {saving && <SpinnerOverlay />}
        </BuildInquiryPage>
    );
}

export default function BuildInquiry() {
    const history = useHistory();
    React.useEffect(() => {
        history.replace({ ...history.location, state: { ...history.location.state, shouldGoBack: true } });
    }, [history]);

    const {
        event: { id: eventId, editable },
    } = useEvent();

    const currentInquiry = useCurrentInquiry();

    const booked = currentInquiry.venues && hasBookedVenue(currentInquiry.venues);

    const loading = !currentInquiry.loaded || currentInquiry.eventId !== eventId || currentInquiry.venues === null;

    if (loading) return <SpinnerOverlay />;

    return <BuildInquiryContent editable={editable && !booked} eventId={eventId} />;
}
