import React from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';

import { ReactComponent as PersonIconSVG } from 'images/icons/person.svg';

import { withIconStyles } from 'shared';

import { IconHeadline } from 'components/ui/Headline';
import Button from 'components/ui/Button';
import TallyDisplay from 'components/ui/TallyDisplay';
import { Column, Row } from 'ui';

const PersonIcon = withIconStyles(PersonIconSVG);

const CardColumn = styled(Column)`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
`;

type TGuestlistSectionProps = {
    editable: boolean;
    attendeeCount: Bizly.AttendeeCounts;
};

export default function GuestlistSection({ editable, attendeeCount }: TGuestlistSectionProps) {
    const { eventId } = useParams();
    const { invited, attending, notAttending, total } = attendeeCount;

    const hasAttendees = total > 0;

    const CTAButton = () => (
        <Link to={`/event/${eventId}/guest-list`} style={{ width: 'fit-content' }}>
            <Button>{editable ? (hasAttendees ? 'Edit' : 'Manage') : 'View'}</Button>
        </Link>
    );

    return (
        <CardColumn
            {...(hasAttendees
                ? { itemSpacing: 'larger' }
                : { itemSpacing: 'default', justifyContent: 'space-between' })}
        >
            <Row alignItems="center" justifyContent="space-between">
                <IconHeadline
                    icon={<PersonIcon />}
                    headline="Guest List"
                    description={
                        !hasAttendees
                            ? 'A perfect meeting needs the perfect audience! Here, we’ll build and manage your guest list.'
                            : ''
                    }
                    flexShrink={1}
                />
                {hasAttendees && <CTAButton />}
            </Row>

            <TallyDisplay
                tallies={[
                    { label: 'total', tally: total },
                    { label: 'invited', tally: invited },
                    { label: 'attending', tally: attending },
                    { label: 'not attending', tally: notAttending },
                ]}
            />
            {!hasAttendees && <CTAButton />}
        </CardColumn>
    );
}
