import createStore from 'zustand';

import { cancelMeeting, getMeeting, getMeetings, updateMeeting } from 'api/meetings';
import { createEvent } from 'api/events';
import { getVenueInquiries } from 'api';

import moment from 'moment';
import keyBy from 'lodash/keyBy';
import isDate from 'lodash/isDate';

type Meeting = BizlyAPI.Meeting & { venueBooking?: boolean };

type State = {
    loadingByWeek: {
        [startOfWeek: string]: boolean;
    };
    idsByWeek: {
        [startOfWeek: string]: number[];
    };

    loading: { [id: string]: boolean; [id: number]: boolean };
    updating: { [id: string]: boolean; [id: number]: boolean };
    meetings: { [id: string]: Meeting; [id: number]: Meeting };
};
type Store = State;

const initialState: State = {
    loadingByWeek: {},
    idsByWeek: {},

    loading: {},
    updating: {},
    meetings: {},
};
export const [useMeetings, meetingsStoreApi] = createStore<Store>(() => initialState);

const { setState, getState } = meetingsStoreApi;

const strDate = (date?: Date) => (date ? moment(date).format('YYYY-MM-DD') : '');

const mergeByDate = <
    CurVal,
    NewVal extends CurVal,
    CurStateKey extends string,
    CurState extends { [key in CurStateKey]: CurVal }
>(
    current: CurState,
    input: NewVal,
    date?: Date
) => ({
    ...current,
    [strDate(date)]: input,
});

export const meetingsActions = {
    load: async (startOfWeek?: Date, limit?: number) => {
        setState({ loadingByWeek: mergeByDate(getState().loadingByWeek, true, startOfWeek) });

        try {
            const { meetings } = await getMeetings(startOfWeek, limit);
            const meetingsDict = keyBy(meetings, m => m.id);

            setState({
                loadingByWeek: mergeByDate(getState().loadingByWeek, false, startOfWeek),
                idsByWeek: mergeByDate(
                    getState().idsByWeek,
                    meetings.map(m => m.id),
                    startOfWeek
                ),
                meetings: { ...getState().meetings, ...meetingsDict },
            });

            return meetings;
        } catch (e) {
            setState({
                loadingByWeek: mergeByDate(getState().loadingByWeek, false, startOfWeek),
            });
            throw e;
        }
    },
    loadSingle: async (id: string | number) => {
        try {
            setState({
                loading: { ...getState().loading, [id]: true },
            });
            const { meeting } = await getMeeting(id);
            const venueInquiries = await getVenueInquiries(id);

            setState({
                meetings: {
                    ...getState().meetings,
                    [id]: {
                        ...meeting,
                        venueBooking: venueInquiries.some(venueInq => venueInq.booked),
                    },
                },
                loading: { ...getState().loading, [id]: false },
            });

            return meeting;
        } catch (e) {
            setState({
                loading: { ...getState().loading, [id]: false },
            });
            throw e;
        }
    },
    create: async (templateId?: number) => {
        return createEvent(templateId ? { templateId } : {});
    },
    merge: async (meeting: BizlyAPI.Meeting) => {
        setState({
            meetings: { ...getState().meetings, [meeting.id]: meeting },
        });
    },
    update: async (meeting: BizlyAPI.Meeting) => {
        if (isUpdating(meeting.id)(getState())) {
            return;
        }

        try {
            setState({
                updating: { ...getState().updating, [meeting.id]: true },
            });
            const { meeting: updatedMeeting } = await updateMeeting(meeting);
            setState({
                updating: { ...getState().updating, [meeting.id]: false },
                meetings: { ...getState().meetings, [meeting.id]: updatedMeeting },
            });

            return updatedMeeting;
        } catch (e) {
            setState({
                updating: { ...getState().updating, [meeting.id]: false },
            });
            throw e;
        }
    },
    add: (meetings: BizlyAPI.Meeting[]) => {
        const meetingsDict = keyBy(meetings, m => m.id);

        setState({
            meetings: { ...getState().meetings, ...meetingsDict },
        });
    },
    cancel: async (id: string | number, notes?: string) => {
        if (isUpdating(id)(getState())) {
            return;
        }

        try {
            setState({
                updating: { ...getState().updating, [id]: true },
            });
            const { meeting: updatedMeeting } = await cancelMeeting({ id, notes });
            setState({
                updating: { ...getState().updating, [id]: false },
                meetings: { ...getState().meetings, [id]: updatedMeeting },
            });

            return updatedMeeting;
        } catch (e) {
            setState({
                updating: { ...getState().updating, [id]: false },
            });
            throw e;
        }
    },
};

export const selectMeeting = (id?: string | number) => (state: State) => (id ? state.meetings[id] : undefined);

export function selectMeetings(startOfWeek?: Date): (state: State) => Meeting[];
export function selectMeetings(ids?: (string | number)[]): (state: State) => Meeting[];
export function selectMeetings(startOfWeekOrIds?: Date | (string | number)[]) {
    if (startOfWeekOrIds === undefined || isDate(startOfWeekOrIds))
        return (state: State) => state.idsByWeek[strDate(startOfWeekOrIds)]?.map(id => state.meetings[id]) ?? [];

    return (state: State) => (startOfWeekOrIds ?? []).map(id => state.meetings[id]).filter(m => m);
}

export function isUpdating(id: string | number) {
    return (state: State) => {
        return state.updating[id];
    };
}

export function isLoading(startOfWeek?: Date): (state: State) => boolean;
export function isLoading(id: string | number): (state: State) => boolean;
export function isLoading(idOrStartOfWeek?: Date | string | number) {
    if (idOrStartOfWeek === undefined || isDate(idOrStartOfWeek)) {
        return (state: State) => state.loadingByWeek[strDate(idOrStartOfWeek)];
    }

    return (state: State) => state.loading[idOrStartOfWeek];
}
