import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import Form from 'components/Form';
import PlaceInput from 'components/PlaceInput';
import UIButton from 'components/ui/Button';

type TCity = {
    location?: Bizly.Event['location'];
    googlePlaceId?: Bizly.Event['googlePlaceId'];
};

type TOnChangeArgs = { value: TCity; field: string; error: Object | null };

type TCityField = {
    field: string;
    defaultValue: TCity;
    placeholder?: string;
    error?: Object | null;
    disabled?: boolean;
    onChange: ({ value, field, error }: TOnChangeArgs) => void;
    allowGlobal?: boolean;
};

const CityField = ({ field, onChange: onChangeProp, defaultValue, placeholder, disabled, allowGlobal }: TCityField) => (
    <PlaceInput
        asFormField
        inModalOrPopover
        placeholder={placeholder}
        onChange={(location: string, googlePlaceId: string) =>
            onChangeProp({
                field,
                error: {},
                value: {
                    location,
                    googlePlaceId,
                },
            })
        }
        disabled={disabled}
        citiesOnly
        defaultValue={defaultValue?.location}
        defaultValueId={defaultValue?.googlePlaceId}
        allowGlobal={allowGlobal}
    />
);

const Button = styled(UIButton)`
    height: 100%;
    width: auto;
    border-radius: 0 4px 4px 0;
`;

const makeFields = ({
    city,
    onButtonClick,
    allowGlobal,
}: {
    city?: TCity;
    onButtonClick: () => void;
    allowGlobal: boolean;
}) => ({
    city: {
        type: CityField,
        perRow: '2/3',
        ...(city && {
            options: {
                defaultValue: city,
                placeholder: 'Select a city',
                allowGlobal,
            },
        }),
    },
    button: {
        type: Button,
        options: {
            onClick: onButtonClick,
            children: 'Find a venue',
            disabled: !(city?.location && city?.googlePlaceId),
        },
    },
});

const schema = [
    {
        key: 'cityAndType',
        fields: ['city', 'button'],
        combined: true,
        spacing: false,
        itemSpacing: false,
    },
];

type TFindVenueField = {
    onFindVenue: ({ location, googlePlaceId }: TCity) => void;
    editable?: boolean;
    allowGlobal?: boolean;
};

export default function FindVenueField({
    location,
    googlePlaceId,
    onFindVenue,
    editable,
    allowGlobal = false,
}: TCity & TFindVenueField) {
    const [city, setCity] = useState<Partial<TCity>>({ location, googlePlaceId });

    const handleSetCity = ({ value }: { value: { city: TCity } }) => setCity(value.city);

    const fields = useMemo(
        () =>
            makeFields({
                city,
                onButtonClick: () =>
                    onFindVenue({
                        location: city.location,
                        googlePlaceId: city.googlePlaceId,
                    }),
                allowGlobal,
            }),
        [city, onFindVenue, allowGlobal]
    );

    return <Form fields={fields} schema={schema} value={{}} onChange={handleSetCity} disabled={!editable} />;
}
