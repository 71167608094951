import React from 'react';
import { useSnackbar } from 'notistack';

import { eventTemplateTagsActions } from './store';

export default function LoadEventTemplateTags() {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        try {
            eventTemplateTagsActions.load();
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    }, [enqueueSnackbar]);

    return null;
}
