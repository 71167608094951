import React from 'react';
import styled from 'styled-components';
import { FormControlLabel, Checkbox } from '@material-ui/core';

import { TFBNeed } from 'components/Planner/types';
import { SelectField } from 'components/FormFields';
import { Grid } from 'ui';

const GridContainer = styled(Grid)`
    justify-content: space-between;
`;

const CheckboxContainer = styled.div`
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    border-radius: 8px;
    width: 168px;
    padding: 8px;
    margin-bottom: 16px;

    .checkbox_row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 2px;

        p {
            font-size: 13px;
            width: 100%;
            text-align: center;
            flex-grow: 1;
        }

        label {
            margin: 0;

            span {
                padding: 0;
            }
        }
    }

    .dropdown_row {
        div {
            width: 100%;
        }
    }
`;

type TDiningStyles = { id: number; name: string }[];

type TEventSpaceOption = {
    id: number;
    name: string;
    iconUrl: string;
    diningStyles?: TDiningStyles[];
};

const LabeledIconCheckbox = ({
    label,
    iconUrl,
    disabled,
    isChecked,
    options,
    selectedOption,
    optionsOnChange,
    onChange,
    readonly,
}: {
    label: string;
    iconUrl: string;
    disabled: boolean;
    isChecked: boolean;
    options?: TDiningStyles[];
    selectedOption?: number;
    optionsOnChange?: (e: number) => void;
    onChange: (e: any, checked: boolean) => void;
    readonly: boolean;
}) => (
    <CheckboxContainer>
        <div className="checkbox_row">
            <img alt={`${label}-icon`} src={iconUrl} width="24" height="24" />
            <p>{label}</p>
            {!readonly && (
                <FormControlLabel
                    label
                    control={<Checkbox color="primary" disabled={disabled} checked={isChecked} onChange={onChange} />}
                />
            )}
        </div>
        {isChecked && !!options?.length && optionsOnChange && (
            <div className="dropdown_row">
                <SelectField
                    field=""
                    readonly={disabled}
                    disabled={disabled}
                    options={options}
                    onChange={(e: any) => {
                        const diningStyle = e.value.target.value;
                        optionsOnChange(diningStyle);
                    }}
                    value={selectedOption}
                    placeholder="Dining Style"
                />
            </div>
        )}
    </CheckboxContainer>
);

export const CheckboxFields = ({
    field,
    value = [],
    disabled,
    onChange,
    options,
    readonly,
}: {
    field: string;
    value: number[];
    disabled: boolean;
    onChange: (evt: { field: string; value: number[] }) => void;
    options: TEventSpaceOption[];
    readonly?: boolean;
}) => {
    const displayOptions = readonly ? options.filter(option => value.includes(option.id)) : options;

    return (
        <GridContainer>
            {displayOptions.map((option: TEventSpaceOption) => (
                <LabeledIconCheckbox
                    key={option.id}
                    label={option.name}
                    iconUrl={option.iconUrl}
                    disabled={disabled}
                    isChecked={value.includes(option.id)}
                    onChange={(evt: MouseEvent, checked: boolean) => {
                        onChange({
                            field,
                            value: checked ? [...value, option.id] : value.filter((v: number) => v !== option.id),
                        });
                    }}
                    readonly={readonly ?? false}
                />
            ))}
        </GridContainer>
    );
};

export const CheckboxDropdownFields = ({
    field,
    value,
    disabled,
    onChange,
    options,
    readonly,
}: {
    field: string;
    value: TFBNeed[];
    disabled: boolean;
    onChange: (evt: { field: string; value: any }) => void;
    options: TEventSpaceOption[];
    readonly?: boolean;
}) => {
    const displayOptions = readonly
        ? options.filter(option => value?.some((val: TFBNeed) => val.option === option.id))
        : options;

    return (
        <GridContainer>
            {displayOptions.map((option, idx) => (
                <LabeledIconCheckbox
                    key={option.name + idx}
                    label={option.name}
                    iconUrl={option.iconUrl}
                    disabled={disabled}
                    isChecked={value?.some((val: TFBNeed) => val.option === option.id)}
                    onChange={(evt: MouseEvent, checked: boolean) =>
                        onChange({
                            field,
                            value: checked
                                ? [...(value || []), { option: option.id }]
                                : value?.filter(v => v.option !== option.id),
                        })
                    }
                    options={option.diningStyles}
                    selectedOption={(value?.find((val: TFBNeed) => val.option === option.id) || {}).diningStyle}
                    optionsOnChange={(diningStyle: number) => {
                        const updatedValue = value.slice();
                        const targetIndex = updatedValue.map(v => v.option).indexOf(option.id);
                        updatedValue[targetIndex] = { option: option.id, diningStyle };

                        onChange({
                            field,
                            value: updatedValue,
                        });
                    }}
                    readonly={readonly ?? false}
                />
            ))}
        </GridContainer>
    );
};
