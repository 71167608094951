import React, { useState, useEffect, useContext } from 'react';

import styled from 'styled-components';
import { useSnackbar } from 'notistack';

import { EventContext } from '../../providers/event';
import { useUser } from '../../providers/user';
import { useCurrentInquiry, hasBookedVenue, LoadCurrentInquiry } from 'stores/current-inquiry';

import { getBookingsForEvent, setBookingFinalSpend, setBookingInvoiceUrl, setBookingContractUrl } from '../../api';
import { uploadFile } from '../../cloudinary';

import { Row } from '../../ui';
import TextButton from '../ui/Button/TextButton';
import Popover from '@material-ui/core/Popover';
import { Tab, Tabs } from 'components/Tabs';

import EstimateForm, { TEstimateForm } from 'components/FinalSpendTracking/EstimateForm';
import BudgetForm from 'components/FinalSpendTracking/BudgetForm';
import FinalSpendForm, { TFinalSpendForm } from 'components/FinalSpendTracking/FinalSpendForm';
import { setBookingEstimates } from 'api';
import { H2Headline } from 'components/ui/Headline';

const BUDGET = 'budget';
const ESTIMATE = 'estimate';
const FINAL_SPEND = 'final spend';

const Container = styled.div`
    width: 650px;
    padding: 32px;
    max-height: 679px;
    flex: 1;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    position: relative;
`;

const TABS_CONTAINER_STYLES = {
    marginBottom: 32,
    width: 250,
};

const TAB_STYLES = {
    padding: 0,
    marginRight: 8,
    marginLeft: 8,
};

const RowRight = styled(Row)`
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
`;

const Headline = styled(H2Headline)`
    margin-bottom: 32px;
`;

type FlyoutProps = {
    finalSpendAnchor: Element;
    setFinalSpendAnchor: (anchor: any) => void;
};

const Flyout = ({ finalSpendAnchor, setFinalSpendAnchor }: FlyoutProps) => {
    const { event, onEventChange } = useContext(EventContext);
    const currentInquiry = useCurrentInquiry();
    const { user } = useUser();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState<boolean>(true);
    const [booking, setBooking] = useState<Bizly.Booking>();

    const [estimateForm, setEstimateForm] = useState<TEstimateForm>();
    const [finalSpendForm, setFinalSpendForm] = useState<TFinalSpendForm>();

    const [budget, setBudget] = useState<string>();

    const teamCompliance = user.team?.compliance || {};
    const [activeTab, setActiveTab] = useState<string>(BUDGET);

    useEffect(() => {
        getBookingsForEvent(event.id)
            .then(({ bookings = [] }: { bookings: Bizly.Booking[] }) => {
                // TODO: This works while we support only single booking (orders) per event. Need to update logic to handle multiple possible bookings when that becomes supported
                const firstBooking = bookings[0] || {};
                setBooking(firstBooking);

                setEstimateForm({
                    ...firstBooking.estimates,
                    existingDocs: {
                        contract1: firstBooking.contractUrl,
                        contract2: firstBooking.contract2Url,
                    },
                });

                if (firstBooking.finalSpend)
                    setFinalSpendForm({
                        ...firstBooking.finalSpend,
                        existingDocs: { invoice1: firstBooking.invoiceUrl, invoice2: firstBooking.invoice2Url },
                    });
                setLoading(false);
            })
            .catch(() => setLoading(false));
        setBudget(event.budget);
    }, [event]);

    const getExistingContracts = () => ({
        contract1: booking?.contractUrl,
        contract2: booking?.contract2Url,
    });

    const getExistingInvoices = () => ({
        invoice1: booking?.invoiceUrl,
        invoice2: booking?.invoice2Url,
    });

    const handleClose = () => setFinalSpendAnchor(null);

    const handleSubmitBudget = async (budget: string) => {
        if (!budget) return;
        setLoading(true);

        try {
            onEventChange({ ...event, budget });
            setBudget(budget);
            activeTab === BUDGET &&
                enqueueSnackbar('Budget was successfully saved.', {
                    variant: 'info',
                });
            handleClose();
        } catch (err) {
            setLoading(false);
            enqueueSnackbar(`Error attempting to submit data`, {
                variant: 'error',
            });
        }
    };

    const handleSubmitContracts = async (stagedDocs: { contract1?: File; contract2?: File }) => {
        if (!booking || !estimateForm) return;

        const { contractUrl: existingContract1 } = booking;
        const { contract1, contract2 } = stagedDocs;
        let stagedUpload1, stagedUpload2;

        try {
            setLoading(true);
            // TODO: Setting a contract and setting estimate are two separate endpoints; look into making a unified one for less calls
            if (contract1 && contract1.name) {
                const { url: firstUrl } = await uploadFile(contract1);
                stagedUpload1 = firstUrl;
            }

            if (contract2 && contract2.name) {
                const { url: secondUrl } = await uploadFile(contract2);
                stagedUpload2 = secondUrl;
            }

            if (stagedUpload1 || stagedUpload2) {
                const firstContract = stagedUpload1 || existingContract1;
                const secondContract = stagedUpload2 || null;
                const contractUrls = {
                    contractUrl: firstContract || secondContract,
                    contract2Url: !!firstContract ? secondContract : null,
                };

                const { booking: bookedContract } = await setBookingContractUrl(booking.id, contractUrls);

                setBooking(bookedContract);
            }

            const { booking: updatedBooking } = await setBookingEstimates(booking.id, estimateForm);

            setBooking({ ...booking, ...updatedBooking });

            activeTab === ESTIMATE &&
                enqueueSnackbar('Meeting estimate and contracts were successfully saved.', {
                    variant: 'info',
                });
            setLoading(false);
            handleClose();
        } catch (err) {
            setLoading(false);
            enqueueSnackbar(`Error attempting to submit data`, {
                variant: 'error',
            });
        }
    };

    const handleSubmitInvoices = async (stagedDocs: { invoice1?: File; invoice2?: File }) => {
        if (!booking) return;

        const { id: bookingId, invoiceUrl: existingInvoice1 } = booking;
        const { invoice1, invoice2 } = stagedDocs;
        let stagedUpload1, stagedUpload2;
        try {
            // TODO: Setting an invoice and setting final spend are two separate endpoints; look into making a unified one for less calls
            if (invoice1 && invoice1.name) {
                const { url: firstUrl } = await uploadFile(invoice1);
                stagedUpload1 = firstUrl;
            }

            if (invoice2 && invoice2.name) {
                const { url: secondUrl } = await uploadFile(invoice2);
                stagedUpload2 = secondUrl;
            }

            if (stagedUpload1 || stagedUpload2) {
                const firstInvoice = stagedUpload1 || existingInvoice1;
                const secondInvoice = stagedUpload2 || null;
                const invoiceUrls = {
                    invoiceUrl: firstInvoice || secondInvoice,
                    invoice2Url: !!firstInvoice ? secondInvoice : null,
                };

                const { booking: updatedBookingInvoice } = await setBookingInvoiceUrl(bookingId, invoiceUrls);
                setBooking(updatedBookingInvoice);
            }

            const { booking: updatedBookingTotals } = await setBookingFinalSpend(bookingId, finalSpendForm);
            setBooking(updatedBookingTotals);
            activeTab === FINAL_SPEND &&
                enqueueSnackbar('Meeting spend and invoices were successfully saved.', {
                    variant: 'info',
                });
            // check for a budget value so that the debounce on updateEvent doesn't cause it to be overwritten
            onEventChange({ ...event, finalSpendTracked: true, ...(budget ? { budget } : {}) });
            handleClose();
        } catch (err) {
            enqueueSnackbar(`Error attempting to submit data`, {
                variant: 'error',
            });
        }
    };

    return (
        <Popover
            anchorEl={finalSpendAnchor}
            open={!!finalSpendAnchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            onClose={() => setFinalSpendAnchor(null)}
        >
            <LoadCurrentInquiry />
            <Container>
                <Headline>Spend Tracking</Headline>

                {!loading && (
                    <Tabs value={activeTab} style={TABS_CONTAINER_STYLES}>
                        <Tab label="Budget" value={BUDGET} onClick={() => setActiveTab(BUDGET)} style={TAB_STYLES} />
                        <Tab
                            label="Estimate"
                            value={ESTIMATE}
                            onClick={() => setActiveTab(ESTIMATE)}
                            style={TAB_STYLES}
                        />
                        <Tab
                            label="Final Spend"
                            value={FINAL_SPEND}
                            onClick={() => setActiveTab(FINAL_SPEND)}
                            style={TAB_STYLES}
                        />
                    </Tabs>
                )}

                {activeTab === BUDGET && !loading && (
                    <>
                        <BudgetForm value={budget || ''} onChange={setBudget} hideHeader disabled={!event.editable} />
                        <RowRight itemSpacing="smallish">
                            <TextButton onClick={handleClose} secondary>
                                Cancel
                            </TextButton>
                            {event.editable && (
                                <TextButton onClick={() => handleSubmitBudget(budget || '')} disabled={!budget}>
                                    Save
                                </TextButton>
                            )}
                        </RowRight>
                    </>
                )}
                {activeTab === ESTIMATE && !loading && (
                    <>
                        <EstimateForm
                            canUpdate={(currentInquiry.venues && hasBookedVenue(currentInquiry.venues)) || false}
                            onFormUpdate={setEstimateForm}
                            formValues={{
                                ...estimateForm,
                                currency: event.currency?.code || 'USD',
                                existingDocs: getExistingContracts(),
                            }}
                            teamCompliance={teamCompliance}
                            disabled={!event.editable}
                            hideHeader
                        />
                        <RowRight itemSpacing="smallish">
                            <TextButton onClick={handleClose} secondary>
                                Cancel
                            </TextButton>
                            {event.editable && currentInquiry.venues && hasBookedVenue(currentInquiry.venues) && (
                                <TextButton
                                    onClick={() => handleSubmitContracts(estimateForm?.stagedDocs || {})}
                                    disabled={
                                        !estimateForm?.existingDocs?.contract1 &&
                                        !(
                                            estimateForm?.stagedDocs?.contract1?.name ||
                                            estimateForm?.stagedDocs?.contract2?.name
                                        )
                                    }
                                >
                                    Save
                                </TextButton>
                            )}
                        </RowRight>
                    </>
                )}
                {activeTab === FINAL_SPEND && !loading && booking && (
                    <>
                        <FinalSpendForm
                            booking={booking}
                            formValues={{
                                ...finalSpendForm,
                                currency: event.currency?.code || 'USD',
                                existingDocs: getExistingInvoices(),
                            }}
                            onFormUpdate={setFinalSpendForm}
                            teamCompliance={teamCompliance}
                            hideHeader
                            disabled={!event.editable}
                        />

                        <RowRight itemSpacing="smallish">
                            <TextButton onClick={handleClose} secondary>
                                Cancel
                            </TextButton>

                            {event.editable && booking?.id && (
                                <TextButton onClick={() => handleSubmitInvoices(finalSpendForm?.stagedDocs || {})}>
                                    Save
                                </TextButton>
                            )}
                        </RowRight>
                    </>
                )}
            </Container>
        </Popover>
    );
};

export default Flyout;
