import React from 'react';

import styled from 'styled-components';

import { Column, Spacer } from 'ui';
import { H5Headline } from 'components/ui/Headline';

import Form from 'components/Form';
import { fields, schema } from './proposalNotesFormSchema';
import { proposalFormToFormData, TProposalNotesFormValues, getErrorMessage, formDataToProposalForm } from './utils';
import { useRegisterValidator } from '../utils';
import { TFormSectionProps } from '../utils';
import { tzMoment, userTimeZone } from 'utils/moment';

const Copy = styled.div`
    font-size: 18px;
    line-height: 1.5;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
`;

const Width100Column = styled(Column)`
    width: 100%;
`;

const FormContent = styled(Width100Column)`
    margin: 0 48px;
`;

export default function ProposalNotesForm({
    onChange,
    registerValidator,
    disabled,
    venue,
    ...rest
}: TFormSectionProps) {
    const [data, setData] = React.useState<TProposalNotesFormValues>(proposalFormToFormData(rest.proposal ?? {}));

    const updateData = ({ value }: any) => {
        setData(value);
        onChange();
    };

    const formSchema = React.useMemo(
        () =>
            schema(
                data.expiryDate
                    ? `${tzMoment(data.expiryDate + ' 23:59:59', venue?.timeZone)
                          .tz(venue?.timeZone ?? userTimeZone)
                          .format('h:mm:ss a zz')}`
                    : ''
            ),
        [data, venue]
    );

    useRegisterValidator(data, registerValidator, getErrorMessage, formDataToProposalForm);

    return (
        <Width100Column>
            <H5Headline>You're ready to submit your proposal.</H5Headline>
            <Spacer small />
            <Copy>
                Please confirm that your proposal is complete and accurate.
                <br />
                This is your chance to share any additional information that will be useful in evaluating your proposal.
            </Copy>
            <Spacer largest />

            <FormContent>
                <Form
                    fields={fields}
                    schema={formSchema}
                    value={data}
                    onChange={updateData}
                    errors={{}}
                    disabled={disabled}
                />
            </FormContent>
        </Width100Column>
    );
}
