import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment-timezone';
import groupBy from 'lodash/groupBy';

import { useUser } from 'providers/user';
import { useEventTemplates } from 'stores/eventTemplates';
import { BLANK_TEMPLATE_ID, isBlankTemplateId } from 'api/eventTemplates';

import { Column, Row } from 'ui';
import { H3Headline } from './ui/Headline';
import Button from './ui/Button';
import TemplateTile, { TemplatelessTile, EmptyTemplateTile } from './TemplateTile';
import Carousel from './Carousel';

import { EColors } from 'theme';

const TemplatesCarouselColumn = styled(Column)`
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.strongCarouselBackground)};
    border-radius: 8px;

    padding: 12px 40px;
`;

const placeholderTiles = new Array(4).fill('').map((v, idx) => <EmptyTemplateTile key={idx} />);

const MAX_TEMPLATES = 3;

const sortByMostRecent = (templates: Bizly.EventTemplate[]) =>
    templates.slice().sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1));

export default function TemplatesCarousel({
    onCreateMeeting,
}: {
    onCreateMeeting?: (playbookId: number | string) => void;
}) {
    const { user } = useUser();
    const { templates, loading } = useEventTemplates();
    const history = useHistory();

    const arrangeTemplates = (allTemplates: Bizly.EventTemplate[]) => {
        const { global = [], shared = [], personal = [] } = groupBy(sortByMostRecent(allTemplates), 'category');

        const globalTempsWithoutBlank = global.filter(({ id }) => !isBlankTemplateId(id));

        return [
            ...shared.slice(0, MAX_TEMPLATES),
            ...personal.slice(0, MAX_TEMPLATES),
            ...globalTempsWithoutBlank.slice(0, MAX_TEMPLATES),
        ];
    };

    const arrangedTemplates = React.useMemo(() => arrangeTemplates(templates), [templates]);

    const templateTiles = arrangedTemplates
        .filter(t => !isBlankTemplateId(t.id))
        .map(t => (
            <TemplateTile
                key={t.id}
                template={t}
                team={user?.team}
                onSelectTemplate={templateId => {
                    onCreateMeeting?.(templateId);
                }}
            />
        ));

    const goToPlaybooksPage = () => history.push('/playbooks');

    return (
        <TemplatesCarouselColumn itemSpacing="medium">
            <H3Headline $color={EColors.strongCarouselText}>{`What kind of meeting can we help you plan?`}</H3Headline>
            <Carousel perRow={5} spacing={24} scrollAmount={5}>
                {[
                    <TemplatelessTile
                        key="blank"
                        onClick={() => {
                            onCreateMeeting?.(BLANK_TEMPLATE_ID);
                        }}
                    />,
                    ...(loading ? placeholderTiles : templateTiles),
                ]}
            </Carousel>
            <Row justifyContent="flex-end">
                <Button width={160} small onClick={goToPlaybooksPage}>
                    View All Playbooks
                </Button>
            </Row>
        </TemplatesCarouselColumn>
    );
}
