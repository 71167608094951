import React from 'react';
import styled from 'styled-components';

import usePersistentSnackbar from 'hooks/usePersistentSnackbar';

import { createMeetingActions, useCreateMeeting } from './store';
import { useUser } from 'providers/user';

import { Column, Copy, Row, Spacer } from 'ui';
import ManageAgenda from 'components/Schedule/ManageAgenda';
import { LargeHeadline } from 'components/ui/Headline';
import { ProTipsDropdown, filterProtips } from 'components/ProTip';
import AgendaTimeTable from 'components/ui/AgendaTimeTable';
import Errors from './components/Errors';
import colorFns from 'colorFns';

const CALENDAR_PAGE = 'planner',
    SCHEDULE_SECTION = 'schedule';

const FormCol = styled(Column)`
    width: 706px; // TODO: Maybe more dynamic?
`;

const PreviewCol = styled(Column)`
    position: sticky;
    top: 100px;
`;

const FormPage = styled(Row)`
    ${FormCol} {
        border-right: 1px solid ${colorFns.softBorder};
    }
`;

export default function AgendaForm() {
    const { agenda, agendaErrors, basicInfo, stepIdx } = useCreateMeeting();

    const enqueueSnackbar = usePersistentSnackbar([agendaErrors, stepIdx]);
    React.useEffect(() => {
        const hasErrors = Object.values(agendaErrors).some(v => v);
        if (hasErrors) {
            enqueueSnackbar(<Errors errors={agendaErrors} />, { variant: 'error' });
        }
    }, [agendaErrors, enqueueSnackbar]);

    const { user } = useUser();
    const proTips = React.useMemo(
        () => filterProtips([...(user.team?.proTips || [])], CALENDAR_PAGE, SCHEDULE_SECTION),
        [user.team]
    );

    return (
        <FormPage itemSpacing="largest" paddingSpacing>
            <FormCol>
                <Row alignItems="center" itemSpacing="small">
                    <LargeHeadline>Agenda</LargeHeadline>
                    <ProTipsDropdown protips={proTips} />
                </Row>

                <ManageAgenda
                    agendaEntries={agenda || []}
                    addAgendaEntry={createMeetingActions.addAgendaItem}
                    updateAgendaEntry={createMeetingActions.updateAgendaItem}
                    arrangeAgendaEntry={createMeetingActions.arrangeAgendaItem}
                    removeAgendaEntry={createMeetingActions.removeAgendaItem}
                    disabled={false}
                    noContainer
                />
                <Spacer largest />
                <Spacer largest />
                <Spacer largest />
            </FormCol>

            <PreviewCol>
                {basicInfo.name && (
                    <Copy>
                        <b>{basicInfo.name}</b>
                    </Copy>
                )}
                <Copy>
                    <b>Agenda</b>
                </Copy>
                <Spacer />
                <AgendaTimeTable agenda={agenda} />
            </PreviewCol>
        </FormPage>
    );
}
