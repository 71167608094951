import React from 'react';

import { parseISO, isSameDay } from 'date-fns';
import { roundEndOfDayto12AM } from 'utils/date_util';

import { View } from 'react-big-calendar';

import BigCalendar, { AddBlockModal, BlockSummaryPopover } from 'components/BigCalendar';

const DraftID = -Infinity;
type TDraft = {
    id: number;
    start: Date;
    end: Date;
    name?: string;
    colorId: number | null;
    selected: true;
    loading?: boolean;
};

type TCalBlock = BizlyAPI.ScheduleBlock & { start: Date; end: Date; multiDay?: boolean; loading?: boolean };

type TCalendar = {
    items: TCalBlock[];

    editable?: boolean;
    addItem: (data: TDraft) => void;
    deleteItem: (id: number) => void;
    goToMoreOptions: (data: TDraft) => void;
    goToEditItem: (id: number) => void;

    initFocus?: { id: number; date: Date };
    lastBlockWarning?: boolean;
};

export default function EventsCalendar({
    items,
    editable,
    addItem,
    deleteItem,
    goToMoreOptions,
    goToEditItem,
    initFocus,
    lastBlockWarning,
}: TCalendar) {
    const isMultiDaySchedule = React.useMemo(
        () => items.some(item => item.multiDay || !isSameDay(items[0].start, item.start)),
        [items]
    );

    const [curDate, setCurDate] = React.useState(initFocus?.date ?? items[0]?.start ?? new Date());
    const [curView, setCurView] = React.useState<View>(isMultiDaySchedule ? 'week' : 'day');
    const [selectedEventId, setSelectedEventId] = React.useState<number | undefined>(initFocus?.id);

    const [draft, setDraft] = React.useState<TDraft | undefined>();

    const itemsWithDraft = React.useMemo(() => [...items, ...(draft ? [draft] : [])], [items, draft]);

    return (
        <>
            <BigCalendar
                events={itemsWithDraft}
                startAccessor={event => event.start}
                endAccessor={event => event.end}
                date={curDate}
                onNavigate={date => setCurDate(date)}
                view={curView}
                onView={view => setCurView(view)}
                initScrollTo={initFocus?.date}
                step={60}
                timeslots={1}
                showMultiDayTimes
                selectable={editable}
                onSelectSlot={
                    editable
                        ? ({ start, end }) => {
                              setDraft({
                                  id: DraftID,
                                  start: typeof start === 'string' ? parseISO(start) : start,
                                  end: roundEndOfDayto12AM(typeof end === 'string' ? parseISO(end) : end),
                                  colorId: null,
                                  selected: true,
                              });
                          }
                        : undefined
                }
                selectedId={draft ? DraftID : selectedEventId}
                isDraftId={id => id === DraftID}
                onSelectEvent={(event, e) => {
                    if (!event.loading && event.id !== DraftID) setSelectedEventId(event.id);
                }}
                onClearSelected={() => setSelectedEventId(undefined)}
                renderEventDetails={e => (
                    <BlockSummaryPopover
                        block={e}
                        editable={editable}
                        onEdit={() => goToEditItem(e.id)}
                        onDelete={() => {
                            setSelectedEventId(undefined);
                            deleteItem(e.id);
                        }}
                        lastBlockWarning={lastBlockWarning && items.length === 1}
                    />
                )}
            />
            {draft && (
                <AddBlockModal
                    block={draft}
                    onChange={newBlock => setDraft(newBlock)}
                    onAdd={() => {
                        setDraft(undefined);
                        addItem(draft);
                    }}
                    onMoreOptions={() => goToMoreOptions(draft)}
                    onClose={() => setDraft(undefined)}
                />
            )}
        </>
    );
}
