import React from 'react';

import { useHistory, useParams } from 'react-router-dom';

import styled, { css } from 'styled-components/macro';
import he from 'he';

import { Popper, Paper } from '@material-ui/core';

import { format } from 'date-fns';
import { apiDateToDateObj, serializeDate } from 'utils/date_util';

import isEmpty from 'lodash/isEmpty';

import { EventContext } from '../providers/event';
import { EventOptionsContext } from '../providers/event-options';

import { getVenueProposal } from '../api';

import { formatCurrency, formatPercentage } from '../util';
import { tzMoment, userTimeZone } from 'utils/moment';

import {
    AlignedRow,
    Column,
    Copy,
    AmenitiesIconHolder,
    IconImg,
    Row,
    SmallerCopy,
    SpacedRow,
    Spacer,
    ExternalLink,
} from '../ui';

import Button from './ui/Button';
import { Headline } from './ui/Headline';
import VenueTile from './VenueTile/VenueTile';
import { EventHeaderIconsFill } from '../components/EventHeader';

import VenueProposalAccepted from './VenueProposalAccepted';
import { MessageCardFlyout } from '../components/MessageCard';

import ProposalAcceptModal from './ProposalAcceptModal';
import ProposalRejectModal from './ProposalRejectModal';

import { ReactComponent as DocumentIcon } from '../images/icons/document.svg';
import { ReactComponent as SpaceMisc } from '../images/icons/space-misc.svg';
import { ReactComponent as MessageBubbleSVG } from '../images/icons/message_bubble.svg';
import { withInteractibleIconStyles } from 'shared';
import colorFns from 'colorFns';
import fontFns from 'fontFns';
import { units, useMeasurementUnits } from 'stores/measurement-units';
import { sqftToSqm } from 'utils/units';

const MessageBubble = withInteractibleIconStyles(MessageBubbleSVG);

const SubSectionHeader = styled.h6<{ alert?: boolean; pending?: boolean }>`
    font-weight: normal;
    font-size: 25px;
    line-height: 1.28;
    letter-spacing: -0.5px;
    color: ${props =>
        props.alert ? colorFns.warningText : props.pending ? colorFns.darkGrey : colorFns.highlightedText};
    margin: 0;
`;

const VenueLabel = styled('div')<{ small?: boolean; href?: string }>`
    font-size: ${props => (props.small ? '15px' : '18px')};
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel}

    display: flex;
    align-items: center;
`;

const DayCardSection = styled.div`
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    border-top: none;
`;

const DisplayPricing = styled.div`
    font-size: 40px;
    font-weight: 300;
    line-height: 0.95;
    letter-spacing: -1.22px;
    color: ${colorFns.displayPricing};
`;

const Smaller = styled.span`
    font-size: 26px;
`;

const FormHeading = styled.h5`
    font-size: 18px;
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    margin: 10px 0;
`;

const NoSpaceImage = styled.div`
    width: 314px;
    height: 217px;
    border-radius: 8px;

    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.placeholderBackground)};

    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
        content: 'No Image Available';
        font-size: 15px;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
    }
`;

const Totals = styled(Column)`
    padding: 24px;
    background: ${colorFns.softAccentedBackground};
    border-radius: 8px;
`;

const StyledPaper = styled(Paper)`
    padding: 20px;
`;

type HoverableAmenitiesIconProps = {
    imgSrc: string;
    alt: string;
    popperContent: React.ReactNode | null;
};

const HoverableAmenitiesIcon = ({ imgSrc, alt, popperContent }: HoverableAmenitiesIconProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    function handleMouseEnter(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }

    function handleMouseLeave() {
        setAnchorEl(null);
    }
    return (
        <div>
            <AmenitiesIconHolder onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {popperContent && (
                    <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="top">
                        <StyledPaper>{popperContent}</StyledPaper>
                    </Popper>
                )}
                {imgSrc ? (
                    <IconImg src={imgSrc} alt={alt} />
                ) : (
                    <SpaceMisc
                        css={css`
                            min-width: 32px;
                        `}
                    />
                )}
            </AmenitiesIconHolder>
        </div>
    );
};

const grSubtotal = (guestroom: Bizly.VenueDayGR) => (guestroom?.quantity ? guestroom.rate * guestroom.quantity : null);
const esSubtotal = (eses: Bizly.EventSpace[], useDdr?: boolean) =>
    eses.length > 0
        ? eses.reduce((agg, cur) => {
              return agg + (useDdr ? (cur.ratePerPerson ?? 0) * (cur.minGuests ?? 0) : cur.fbMinimum + cur.rate);
          }, 0)
        : null;

const VenueProposal = ({
    viewVenueListing,
    proposalAccepted = false,
    venueBooked,
    setVenueBooked,
    sideMargin,
    withMessagesOpen = false,
}: {
    viewVenueListing: (venueId: number) => void;
    proposalAccepted?: boolean;
    venueBooked: boolean;
    setVenueBooked?: (booked: boolean) => void;
    sideMargin: number;
    withMessagesOpen?: boolean;
}) => {
    let { proposalId } = useParams();
    const history = useHistory();

    const { event, setCurrency } = React.useContext(EventContext);
    const { avOptions, fbOptions } = React.useContext(EventOptionsContext);

    const [venueProposal, setVenueProposal] = React.useState<Bizly.VenueProposal>();

    const [acceptModalOpen, setAcceptModalOpen] = React.useState<boolean>(false);
    const [rejectModalOpen, setRejectModalOpen] = React.useState<boolean>(false);

    const [venueMessagesAnchor, setVenueMessagesAnchor] = React.useState<SVGSVGElement | null>(null);
    const [messageBubbleIconRef, setMessageBubbleIconRef] = React.useState<SVGSVGElement | null>(null);

    const toggleMessagesFlyout = () => {
        let currentPath = history.location.pathname.split('/');
        if (currentPath[currentPath.length - 1] === 'messages') {
            currentPath.pop();
        } else {
            currentPath.push('messages');
        }
        history.replace(currentPath.join('/'));
        setVenueMessagesAnchor(prevAnchor => (prevAnchor ? null : messageBubbleIconRef));
    };
    const showMessagesFlyout = () => {
        history.replace(history.location.pathname + '/messages');
        setVenueMessagesAnchor(messageBubbleIconRef);
    };

    React.useEffect(() => {
        if (withMessagesOpen && messageBubbleIconRef) {
            setVenueMessagesAnchor(messageBubbleIconRef);
        }
    }, [messageBubbleIconRef, withMessagesOpen]);

    React.useEffect(() => {
        if (proposalId) {
            getVenueProposal(proposalId).then(res => setVenueProposal(res.proposal));
        }
    }, [proposalId, venueBooked]);

    const handleExtensionRequest = () => {
        if (proposalId) {
            getVenueProposal(proposalId).then(res => setVenueProposal(res.proposal));
        }
    };

    const onHoldUntilDate = tzMoment(
        venueProposal?.onHoldUntil.split(' ')[0] + ' 23:59:59',
        venueProposal?.venue.timeZone
    )
        .tz(venueProposal?.venue.timeZone ?? userTimeZone)
        .format('MM/DD/yy h:mm:ss a zz');

    const expired = venueProposal && new Date() > new Date(venueProposal.onHoldUntil);
    const extensionRequestDate = tzMoment(venueProposal?.extensionRequestedAt).format('MM/DD/yy');
    const extensionRejectDate = tzMoment(venueProposal?.extensionRejectedAt).format('MM/DD/yy');
    const hasBeenExtended = tzMoment(venueProposal?.onHoldUntil).isAfter(venueProposal?.extensionRequestedAt);

    const currencyCode = venueProposal?.currency?.code;

    const { area: areaUnit } = useMeasurementUnits();

    const summaryBlock = (
        <Column
            css={css`
                flex: 40% 0 0;
            `}
        >
            {venueProposal && venueProposal.onHoldUntil && !proposalAccepted && (
                <>
                    <Row>
                        <Column>
                            <SubSectionHeader>Space Held Until:</SubSectionHeader>
                            <SubSectionHeader>{onHoldUntilDate}</SubSectionHeader>
                        </Column>
                        <Spacer larger />

                        {venueProposal?.extensionRejectedAt ? (
                            <Column>
                                <SubSectionHeader alert>Extension rejected on:</SubSectionHeader>
                                <SubSectionHeader alert>{extensionRejectDate}</SubSectionHeader>
                            </Column>
                        ) : (
                            venueProposal?.extensionRequestedAt &&
                            !hasBeenExtended && (
                                <Column>
                                    <SubSectionHeader pending>Extension request sent on:</SubSectionHeader>
                                    <SubSectionHeader pending>{extensionRequestDate}</SubSectionHeader>
                                </Column>
                            )
                        )}
                    </Row>
                    <Spacer />
                </>
            )}
            <SpacedRow
                css={css`
                    width: 75%;

                    & > * {
                        flex: 1;
                    }
                `}
            >
                <VenueLabel small>Meeting Name</VenueLabel>
                <div>{event.name}</div>
            </SpacedRow>
            <Spacer small />
            <SpacedRow
                css={css`
                    width: 75%;

                    & > * {
                        flex: 1;
                    }
                `}
            >
                <VenueLabel small>Meeting Type</VenueLabel>
                <div>{event.type}</div>
            </SpacedRow>
            <Spacer small />
            <Column itemSpacing="largest">
                <SpacedRow
                    css={css`
                        width: 75%;

                        & > * {
                            flex: 1;
                        }
                    `}
                >
                    <VenueLabel small>Internal Reference</VenueLabel>
                    <div>{event.cventId}</div>
                </SpacedRow>
                {venueProposal && (!isEmpty(venueProposal.notes) || !isEmpty(venueProposal.datesFlexibleNotes)) ? (
                    <Row>
                        <Column itemSpacing="medium">
                            {venueProposal && !isEmpty(venueProposal.notes) && (
                                <div>
                                    <VenueLabel>Notes</VenueLabel>
                                    <Spacer small />
                                    <div>{he.decode(venueProposal.notes.replace(/(<([^>]+)>)/gi, ''))}</div>
                                </div>
                            )}
                            {venueProposal && !isEmpty(venueProposal.datesFlexibleNotes) && (
                                <div>
                                    <VenueLabel>Flexible Dates</VenueLabel>
                                    <Spacer small />
                                    <div>
                                        {he.decode(venueProposal.datesFlexibleNotes.replace(/(<([^>]+)>)/gi, ''))}
                                    </div>
                                </div>
                            )}
                        </Column>
                    </Row>
                ) : null}
                {venueProposal && !isEmpty(venueProposal.attachments) && (
                    <div>
                        <VenueLabel>Attachments</VenueLabel>
                        <Spacer />
                        {venueProposal.attachments.map(attachment => (
                            <VenueLabel>
                                <DocumentIcon />
                                <ExternalLink href={attachment.url} target="_blank" rel="noopener noreferrer">
                                    {attachment.name}
                                </ExternalLink>
                            </VenueLabel>
                        ))}
                    </div>
                )}
            </Column>
        </Column>
    );

    const venueImage = (
        <Column
            css={css`
                flex: 60% 0 0;
            `}
        >
            {venueProposal && venueProposal.venue && (
                <VenueTile
                    venue={venueProposal.venue}
                    format="proposal"
                    width="100%"
                    fixedRatio="calc(307 / 672 * 100%)"
                    pillType="preferenceCategory"
                    onClick={viewVenueListing}
                />
            )}
        </Column>
    );

    const renderGuestRooms = (venueProposal: Bizly.VenueProposal, guestRoom?: Bizly.VenueDayGR) =>
        guestRoom && (
            <DayCardSection>
                <Column>
                    <AlignedRow itemSpacing="default">
                        <img
                            src="https://res.cloudinary.com/hdd626jg7/image/upload/v1562953405/stencil/icons/guest-room.svg"
                            alt="meetingspace-icon"
                        />
                        <Copy>{guestRoom.quantity} guest rooms</Copy>
                    </AlignedRow>
                    <Spacer large />
                    <Row
                        css={css`
                            width: inherit;

                            justify-content: space-between;

                            margin-left: 60px;
                            margin-right: 32px;

                            & > * {
                                flex: 1;
                                margin-right: 32px;

                                &:last-child {
                                    margin-right: 0;
                                }
                            }
                        `}
                    >
                        <div>
                            {guestRoom.imageUrl ? (
                                <img
                                    css={css`
                                        width: 314px;
                                        height: 217px;
                                        border-radius: 8px;
                                    `}
                                    src={guestRoom.imageUrl}
                                    alt="venue-space"
                                />
                            ) : (
                                <NoSpaceImage />
                            )}
                        </div>
                        <Column>
                            <FormHeading as="label">Guest Room Rental</FormHeading>
                            <DisplayPricing>{formatCurrency(guestRoom.subTotal, currencyCode)}</DisplayPricing>
                            <Spacer />
                            {venueProposal.commissionable && <SmallerCopy>Guestrooms are commissionable.</SmallerCopy>}
                            <SmallerCopy>Amount excludes taxes and fees.</SmallerCopy>
                            <Spacer />
                            <Spacer />
                            <SpacedRow>
                                <VenueLabel small>Room Rate</VenueLabel>
                                <div>{formatCurrency(guestRoom.rate, currencyCode)}</div>
                            </SpacedRow>
                            <Line />
                            <SpacedRow>
                                <VenueLabel small>Occupancy Tax</VenueLabel>
                                <div>{Number(venueProposal.occupancyTax)}%</div>
                            </SpacedRow>
                            <Line />
                            <SpacedRow>
                                <VenueLabel small>Resort Fee</VenueLabel>
                                <div>{formatCurrency(venueProposal.resortFee, currencyCode)}</div>
                            </SpacedRow>
                        </Column>
                        <Column>
                            {guestRoom.name && (
                                <SpacedRow>
                                    <VenueLabel small>Room Name</VenueLabel>
                                    <div>{guestRoom.name}</div>
                                </SpacedRow>
                            )}
                        </Column>
                    </Row>
                </Column>
            </DayCardSection>
        );

    const renderEventSpaces = (venueProposal: Bizly.VenueProposal, eventSpaces: Bizly.EventSpace[]) =>
        eventSpaces.map(
            ({
                id,
                fbMinimum,
                rate,
                minGuests,
                ratePerPerson,
                imageUrl: eventSpaceImageUrl,
                requestedSpaceName,
                venueSpace,
                setup,
                startsAt,
                endsAt,
                fbItems,
                avItems,
                guests,
            }) => {
                const startTime = serializeDate(startsAt);
                const startTimeDisplay = startTime ? format(startTime, 'h:mm aaaa') : undefined;
                const endTime = serializeDate(endsAt);
                const endTimeDisplay = endTime ? format(endTime, 'h:mm aaaa') : undefined;
                const timesDisplay = [startTimeDisplay, endTimeDisplay].join(' - ');

                const useDdr = !!venueProposal.useDdr;

                const amenities = (
                    <Row itemSpacing="small" withBorderPadding="small" wrap>
                        <HoverableAmenitiesIcon
                            css={css`
                                max-height: 24px;
                                max-width: 24px;
                            `}
                            imgSrc={setup ? setup.iconUrl : ''}
                            alt="setup-icon"
                            key={setup.name}
                            popperContent={setup.name}
                        />
                        {avItems.map(avItem => {
                            const avOption = avOptions.find(avOption => avOption.name === avItem.name);

                            return (
                                <HoverableAmenitiesIcon
                                    imgSrc={avOption ? avOption.iconUrl : ''}
                                    alt="meetingspace-icon"
                                    key={avItem.name}
                                    popperContent={avItem.name}
                                />
                            );
                        })}
                        {fbItems.map(fbItem => {
                            const fbOption = fbOptions.find(fbOption => fbOption.name === fbItem.name);

                            return (
                                <HoverableAmenitiesIcon
                                    imgSrc={fbOption ? fbOption.iconUrl : ''}
                                    alt="meetingspace-icon"
                                    key={fbItem.name}
                                    popperContent={
                                        <Column itemSpacing="xsmall">
                                            <div>{fbItem.name}</div>
                                            {fbItem.diningStyle && <div>{fbItem.diningStyle.name}</div>}
                                        </Column>
                                    }
                                />
                            );
                        })}
                    </Row>
                );

                return (
                    <DayCardSection key={id}>
                        <Column>
                            <AlignedRow itemSpacing="default">
                                <img src={setup.iconUrl} alt="meetingspace-icon" />
                                <Column itemSpacing="xsmall">
                                    <Row itemSpacing="default">
                                        <Copy>{requestedSpaceName || setup.name}</Copy>
                                        <Copy>{timesDisplay}</Copy>
                                    </Row>
                                    <Copy>{guests} attendees</Copy>
                                </Column>
                            </AlignedRow>
                            <Spacer large />
                            <Row
                                css={css`
                                    width: inherit;

                                    justify-content: space-between;

                                    margin-left: 60px;
                                    margin-right: 32px;

                                    & > * {
                                        flex: 1;
                                        margin-right: 32px;

                                        &:last-child {
                                            margin-right: 0;
                                        }
                                    }
                                `}
                            >
                                <div>
                                    {eventSpaceImageUrl ? (
                                        <img
                                            css={css`
                                                width: 314px;
                                                height: 217px;
                                                border-radius: 8px;
                                            `}
                                            src={eventSpaceImageUrl}
                                            alt="venue-space"
                                        />
                                    ) : (
                                        <NoSpaceImage />
                                    )}
                                </div>
                                <Column>
                                    {useDdr ? (
                                        <Row itemSpacing="largest">
                                            <Column>
                                                <FormHeading as="label">Min. Guests</FormHeading>
                                                <DisplayPricing>
                                                    {minGuests}
                                                    <Smaller> guests</Smaller>
                                                </DisplayPricing>
                                            </Column>
                                            <Column>
                                                <FormHeading as="label">Per Person</FormHeading>
                                                <DisplayPricing>
                                                    {formatCurrency(ratePerPerson, currencyCode)}
                                                </DisplayPricing>
                                            </Column>
                                        </Row>
                                    ) : (
                                        <Row itemSpacing="largest">
                                            <Column>
                                                <FormHeading as="label">F&amp;B Min.</FormHeading>
                                                <DisplayPricing>
                                                    {formatCurrency(fbMinimum, currencyCode)}
                                                </DisplayPricing>
                                            </Column>
                                            <Column>
                                                <FormHeading as="label">Room Rental</FormHeading>
                                                <DisplayPricing>{formatCurrency(rate, currencyCode)}</DisplayPricing>
                                            </Column>
                                        </Row>
                                    )}
                                    <Spacer />
                                    <SmallerCopy>Amount excludes taxes and fees.</SmallerCopy>
                                    <Spacer />
                                    <Spacer />
                                    <SpacedRow>
                                        <VenueLabel small>Service Charge</VenueLabel>
                                        <div>{formatPercentage(venueProposal.serviceCharge)}</div>
                                    </SpacedRow>
                                    <Line />
                                    <SpacedRow>
                                        <VenueLabel small>Sales Tax</VenueLabel>
                                        <div>{formatPercentage(venueProposal.salesTax)}</div>
                                    </SpacedRow>
                                    <Line />
                                    <SpacedRow>
                                        <VenueLabel small>Gratuity</VenueLabel>
                                        <div>{formatPercentage(venueProposal.gratuity)}</div>
                                    </SpacedRow>
                                </Column>
                                <Column itemSpacing="default">
                                    <SpacedRow>
                                        <VenueLabel small>Room Name</VenueLabel>
                                        <div>{venueSpace.name}</div>
                                    </SpacedRow>
                                    <SpacedRow>
                                        <VenueLabel small>Room Size ({areaUnit})</VenueLabel>
                                        <div>
                                            {areaUnit === units.sqm ? sqftToSqm(venueSpace.size) : venueSpace.size}
                                        </div>
                                    </SpacedRow>
                                    <SpacedRow>
                                        <VenueLabel small>Room Max Capacity</VenueLabel>
                                        <div>{venueSpace.maxCapacity?.toLocaleString()}</div>
                                    </SpacedRow>
                                    <VenueLabel small>Essentials</VenueLabel>
                                    {amenities}
                                </Column>
                            </Row>
                        </Column>
                    </DayCardSection>
                );
            }
        );

    const renderTotals = (proposal: Bizly.VenueProposal) => {
        const useDdr = !!proposal.useDdr;

        const hasGR = proposal.byDay.some(day => day.guestRooms[0]?.quantity);
        const hasES = proposal.byDay.some(day => day.eventSpaces.length > 0);

        const grSubtotalVal = proposal.byDay.reduce((sum, day) => sum + (grSubtotal(day.guestRooms[0]) ?? 0), 0);
        const esSubtotalVal = proposal.byDay.reduce((sum, day) => sum + (esSubtotal(day.eventSpaces, useDdr) ?? 0), 0);
        const totalDisplay = formatCurrency(grSubtotalVal + esSubtotalVal, currencyCode);

        return (
            <Row justifyContent="center">
                <Totals itemSpacing="smallish">
                    <Row itemSpacing="largest">
                        <Column>
                            <FormHeading as="label">Total</FormHeading>
                            <DisplayPricing>{totalDisplay}</DisplayPricing>
                        </Column>
                        <Column>
                            <FormHeading as="label">Guest Rooms</FormHeading>
                            <DisplayPricing>{hasGR ? formatCurrency(grSubtotalVal, currencyCode) : '-'}</DisplayPricing>
                        </Column>
                        <Column>
                            <FormHeading as="label">Event Spaces</FormHeading>
                            <DisplayPricing>{hasES ? formatCurrency(esSubtotalVal, currencyCode) : '-'}</DisplayPricing>
                        </Column>
                    </Row>
                    <SmallerCopy>Amount excludes taxes and fees.</SmallerCopy>
                </Totals>
            </Row>
        );
    };

    return (
        <>
            {proposalId && event.editable && (
                <EventHeaderIconsFill>
                    <MessageBubble ref={element => setMessageBubbleIconRef(element)} onClick={toggleMessagesFlyout} />
                    {venueMessagesAnchor && (
                        <MessageCardFlyout
                            anchor={venueMessagesAnchor}
                            onClose={toggleMessagesFlyout}
                            proposalId={proposalId}
                        />
                    )}
                </EventHeaderIconsFill>
            )}
            {venueProposal?.accepted && (
                <VenueProposalAccepted onSendMessage={showMessagesFlyout} sideMargin={sideMargin} />
            )}
            <Spacer largest />
            <main
                css={css`
                    width: 100%;
                `}
            >
                {venueProposal && !venueProposal.accepted && (
                    <SpacedRow>
                        <Headline large>Proposal</Headline>
                        <SpacedRow
                            css={css`
                                width: auto;

                                & > * {
                                    margin-left: 20px;
                                }
                            `}
                        >
                            {event.editable && (
                                <Button
                                    css={css`
                                        width: auto;
                                        padding: 0 16px;
                                    `}
                                    secondary
                                    onClick={showMessagesFlyout}
                                >
                                    Message Venue
                                </Button>
                            )}
                            {venueProposal && event.editable && !venueProposal.rejected && !venueProposal.cancelled && (
                                <>
                                    <Button onClick={() => setRejectModalOpen(true)} warning>
                                        Reject
                                    </Button>
                                    <Button onClick={() => setAcceptModalOpen(true)} width={expired ? 150 : undefined}>
                                        {expired ? 'Request Extension' : 'Accept'}
                                    </Button>
                                </>
                            )}
                        </SpacedRow>
                    </SpacedRow>
                )}
                {proposalAccepted && (
                    <>
                        <Spacer />
                        <Line />
                        <Spacer />
                    </>
                )}
                <Row justifyContent="space-between">
                    {summaryBlock}
                    <Spacer />
                    {venueImage}
                </Row>
                <Spacer largest />
                <Spacer largest />
                {venueProposal && renderTotals(venueProposal)}
                <Spacer />
                <VenueLabel>Details</VenueLabel>
                <Spacer />
                {venueProposal &&
                    venueProposal.byDay.map((day, i) => (
                        <div
                            css={css`
                                & > * {
                                    padding: 32px;
                                }
                                & > div:last-child {
                                    border-bottom-left-radius: 8px;
                                    border-bottom-right-radius: 8px;
                                    margin-bottom: 24px;
                                }
                            `}
                        >
                            <div
                                css={css`
                                    width: 100%;
                                    border-top-left-radius: 8px;
                                    border-top-right-radius: 8px;
                                    background-color: ${({ theme: { getColor, EColors } }) =>
                                        getColor(EColors.agendaDayPane)};
                                    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.agendaDayPaneText)};

                                    display: flex;

                                    box-sizing: border-box;

                                    & > * {
                                        margin-right: 24px;
                                    }
                                `}
                            >
                                <div>Day {i + 1}</div>
                                <div>{day.day && format(apiDateToDateObj(day.day), 'iii, LLL dd, yyyy')}</div>
                            </div>
                            {renderGuestRooms(venueProposal, day.guestRooms[0])}
                            {renderEventSpaces(venueProposal, day.eventSpaces)}
                        </div>
                    ))}
            </main>
            {setVenueBooked && venueProposal && (
                <>
                    <ProposalAcceptModal
                        open={acceptModalOpen}
                        closeModal={() => setAcceptModalOpen(false)}
                        proposal={venueProposal}
                        eventId={event.id}
                        setVenueBooked={booked => {
                            acceptModalOpen && setCurrency(venueProposal?.currency);
                            setVenueBooked(booked);
                        }}
                        onExtensionRequest={handleExtensionRequest}
                    />
                    <ProposalRejectModal
                        open={rejectModalOpen}
                        closeModal={() => setRejectModalOpen(false)}
                        proposalId={venueProposal.id}
                        eventId={event.id}
                    />
                </>
            )}
        </>
    );
};

export default VenueProposal;
