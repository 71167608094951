import React, { useState, useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';

import { sendMagicLink, isAuthenticationPartialSuccess, isSSOOnlyAccount, loginWithGoogleId } from 'api';
import { TAuthProps, TGoogleAuth } from './types';
import { emailIsValid } from '../../util';

import ContentCard from '../../components/ui/ContentCard';
import SSOView from './SSOView';
import Policies from '../../components/ui/Policies';
import LinkSentView from './LinkSentView';

import { SpinnerOverlay } from 'components/Spinner';
import { Copy } from 'ui';
import { H2Headline } from 'components/ui/Headline';
import UITextButton from 'components/ui/Button/TextButton';

import Form from 'components/Form';
import { makeAuthFields, signUpEmailSchema, signUpCompanySchema } from './formSchema';

const SubCopy = styled(Copy)`
    font-size: 16px;
    line-height: 24px;
`;

const TextButton = styled(UITextButton)`
    font-size: inherit;
    font-weight: inherit;
    padding: 0;
`;

const LinkCopy = styled(Link)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
    cursor: pointer;
`;

export default function SignUpPage({
    authFormValue,
    onChange,
    onGoogleSignUpSuccess,
}: TAuthProps & { onGoogleSignUpSuccess: (user: BizlyAPI.User) => void }) {
    const [isLoading, setIsLoading] = useState(false);
    const [hasNewEmail, setHasNewEmail] = useState(false);
    const [linkIsSent, setLinkIsSent] = useState(false);
    const [SSOLink, setSSOLink] = useState<Nullable<string>>(null);

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { signInRedirect } = history.location.state || {};

    const onVerifyEmail = useCallback(
        async e => {
            e.preventDefault();

            if (!authFormValue.email || !emailIsValid(authFormValue.email)) {
                return enqueueSnackbar('Please enter a valid email.', {
                    variant: 'error',
                });
            }

            setIsLoading(true);
            try {
                const { success } = await sendMagicLink({ email: authFormValue.email });
                if (success) setLinkIsSent(true);
            } catch (e) {
                if (isAuthenticationPartialSuccess(e)) {
                    return setHasNewEmail(true);
                }

                if (isSSOOnlyAccount(e)) {
                    return setSSOLink(e.raw.ssoLink);
                }

                enqueueSnackbar('Something went wrong, please try again.', {
                    variant: 'error',
                });
            } finally {
                setIsLoading(false);
            }
        },
        [enqueueSnackbar, authFormValue]
    );

    const onGoogleAuthSuccess = useCallback(
        async (resp: TGoogleAuth) => {
            if (resp.tokenId === undefined) {
                return enqueueSnackbar('Something went wrong, please try again.', {
                    variant: 'error',
                });
            }

            const { tokenId: googleIdToken, profileObj } = resp;

            if (!profileObj?.email || !googleIdToken) {
                return enqueueSnackbar('Something went wrong, please try again.', {
                    variant: 'error',
                });
            }

            setIsLoading(true);
            try {
                const { user } = await loginWithGoogleId({ email: profileObj.email, googleIdToken });
                if (user?.authToken) onGoogleSignUpSuccess(user);
            } catch (e) {
                if (isAuthenticationPartialSuccess(e)) {
                    onChange({ value: { googleEmail: profileObj.email, googleIdToken } });
                    return setHasNewEmail(true);
                }

                enqueueSnackbar('Something went wrong, please try again.', {
                    variant: 'error',
                });
            } finally {
                setIsLoading(false);
            }
        },
        [enqueueSnackbar, onGoogleSignUpSuccess, onChange]
    );

    const onSubmitForm = useCallback(
        async e => {
            e.preventDefault();

            if (!authFormValue.teamName) {
                return enqueueSnackbar('Please enter a company or team name.', {
                    variant: 'error',
                });
            }

            setIsLoading(true);
            try {
                if (authFormValue.googleEmail && authFormValue.googleIdToken) {
                    const { user } = await loginWithGoogleId({
                        email: authFormValue.googleEmail,
                        googleIdToken: authFormValue.googleIdToken,
                        teamName: authFormValue.teamName,
                    });

                    return onGoogleSignUpSuccess(user);
                }

                const { success } = await sendMagicLink(authFormValue);
                if (success) setLinkIsSent(true);
            } catch {
                enqueueSnackbar('Something went wrong, please try again.', {
                    variant: 'error',
                });
            } finally {
                setIsLoading(false);
            }
        },
        [enqueueSnackbar, authFormValue, onGoogleSignUpSuccess]
    );

    const onResendLink = async () => {
        if (isLoading) return;

        if (!authFormValue.email || !emailIsValid(authFormValue.email)) {
            return enqueueSnackbar('Please enter a valid email.', {
                variant: 'error',
            });
        }

        setIsLoading(true);
        try {
            const { success } = await sendMagicLink({ email: authFormValue.email });
            if (success) setLinkIsSent(true);
        } catch {
            enqueueSnackbar('Something went wrong, please try again.', {
                variant: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const PathToSignIn = (
        <>
            Already have an account? You can <LinkCopy to="/sign-in">sign in here.</LinkCopy>
        </>
    );

    const ResendEmail = (
        <>
            Didn't receive an email? <TextButton onClick={onResendLink}>Resend Email</TextButton>
        </>
    );

    const dynamicHeadline = hasNewEmail
        ? 'Almost done!'
        : signInRedirect
        ? 'Looks like you don’t have a Bizly Account.'
        : 'Welcome to Bizly';
    const dynamicSubCopy = hasNewEmail
        ? 'Enter the name of your company or team.'
        : signInRedirect
        ? 'Enter your email address to create a Bizly account.'
        : 'First, let’s create your account. Please enter your work email address.';

    const authFields = useMemo(
        () =>
            makeAuthFields({
                onVerifyEmail,
                onSubmitForm,
                onGoogleAuthSuccess,
            }),
        [onVerifyEmail, onSubmitForm, onGoogleAuthSuccess]
    );

    return (
        <ContentCard headlineCopy="Sign Up" footer={!linkIsSent ? PathToSignIn : ResendEmail}>
            {!linkIsSent ? (
                <>
                    {!SSOLink ? (
                        <>
                            <H2Headline>{dynamicHeadline}</H2Headline>
                            <SubCopy>{dynamicSubCopy}</SubCopy>

                            <Form
                                fields={authFields}
                                schema={!hasNewEmail ? signUpEmailSchema : signUpCompanySchema}
                                value={authFormValue}
                                onChange={onChange}
                                disabled={isLoading}
                                isNested
                            />
                        </>
                    ) : (
                        <SSOView SSOLink={SSOLink} />
                    )}

                    {!hasNewEmail && <Policies prefix="By signing up or logging in" />}
                </>
            ) : (
                <LinkSentView />
            )}

            {isLoading && <SpinnerOverlay />}
        </ContentCard>
    );
}
