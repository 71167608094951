import styled from 'styled-components';

import { Column } from 'ui';

const DashboardCard = styled(Column)<{ scrollable?: boolean; small?: boolean; frameless?: boolean }>`
    position: relative;
    height: ${({ small }) => (small ? 372 : 569)}px;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 12px 24px 24px 24px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.dashboardCard)};

    ${({ scrollable }) => scrollable && 'overflow: auto;'}

    ${({ frameless, theme: { getColor, EColors } }) =>
        frameless
            ? `
        height: auto;
        
        padding: 0;

        background-color: ${getColor(EColors.pureWhite)};
    `
            : ''}
`;

export default DashboardCard;
