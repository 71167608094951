import React from 'react';

import { Link } from 'react-router-dom';

import { isBlankTemplateId } from 'api/eventTemplates';

import { Copy } from 'ui';
import PlaceInput from 'components/PlaceInput';
import { EventSettingsFields } from 'components/EventSettingsFlyout';
import Button from 'components/ui/Button';
import Form from 'components/Form';
import { getPassthroughProps } from 'components/Form/utils';
import { Spinner } from 'components/Spinner';

export type City = {
    location: Bizly.Event['location'];
    googlePlaceId: Bizly.Event['googlePlaceId'];
};

type TCityField = {
    field: string;
    defaultValue: City;
    error: Object | null;
    onChange: ({ value, field, error }: { value: City; field: string; error: Object | null }) => void;
};

const CityField = ({ field, onChange: onChangeProp, defaultValue }: TCityField) => (
    <PlaceInput
        asFormField
        inModalOrPopover
        onChange={(location: string, googlePlaceId: string) =>
            onChangeProp({
                field,
                error: {},
                value: {
                    location,
                    googlePlaceId,
                },
            })
        }
        defaultValue={defaultValue?.location}
        defaultValueId={defaultValue?.googlePlaceId}
    />
);

export type EventForm = Partial<
    {
        city: City;
        templateId: string | number;
    } & Pick<Bizly.Event, 'name' | 'cventId' | 'type' | 'costCenter' | 'budget'>
>;

type TemplateOption = Omit<Bizly.EventTemplate, 'name'> & { name?: JSX.Element | string };

const AugmentedTemplatesField = ({
    field,
    value,
    onChange,
    options,
    playbookDescription,
}: {
    field: string;
    value: number;
    onChange: (update: { field: string; value: number }) => void;
    options: {}[];
    playbookDescription?: string;
}) => {
    const fields = {
        [field]: {
            type: 'select',
            options: {
                options,
            },
        },
    };

    const ViewPlaybookField = {
        type: 'display',
        options: {
            label: (
                <Link to={`/playbooks/${value}`}>
                    <Button onClick={() => ({})} width={130}>
                        View Playbook
                    </Button>
                </Link>
            ),
        },
    };

    const schema = [
        {
            fields: [field, ...(value && !isBlankTemplateId(value) ? [ViewPlaybookField] : [])],
            spacing: false,
        },
    ];

    const { value: valueProp, onChange: onChangeProp } = getPassthroughProps({ field, value, onChange });

    return options.length ? (
        <>
            <Form
                fields={fields}
                schema={schema}
                value={{ ...valueProp, playbookDescription }}
                onChange={onChangeProp}
            />
            {playbookDescription && <Copy>{playbookDescription}</Copy>}
        </>
    ) : (
        <Spinner suppressMargin />
    );
};

export const EventCreateFields = (
    meetingInternalReferenceFieldLabel?: string,
    disabledFields?: string[],
    templates?: TemplateOption[],
    city?: City | null,
    playbookDescription?: string
) => ({
    ...EventSettingsFields(meetingInternalReferenceFieldLabel, disabledFields),
    name: {
        prompt: 'Meeting Name',
        type: 'text',
        disabled: disabledFields?.includes('name'),
        options: {
            autoFocus: true,
        },
    },
    city: {
        prompt: 'Location',
        type: CityField,
        disabled: disabledFields?.includes('city'),
        ...(city && {
            options: {
                defaultValue: city,
            },
        }),
        perRow: '2/3',
    },
    budget: {
        prompt: 'Budget',
        type: 'text',
        optional: true,
    },
    cityNote: {
        type: () => <Copy small>For virtual gatherings, input your current location to set the time zone.</Copy>,
        perRow: 2,
    },
    templateId: {
        prompt: 'Playbook',
        type: AugmentedTemplatesField,
        disabled: disabledFields?.includes('templateId'),
        options: {
            options: templates,
            playbookDescription: playbookDescription,
        },
        optional: true,
    },
});

export const schema = [
    {
        key: 'nameRow',
        fields: ['name'],
        spacing: 'medium',
    },
    {
        key: 'city',
        fields: ['city'],
        spacing: 'xsmall',
    },
    {
        fields: ['cityNote'],
        spacing: 'medium',
    },
    {
        key: 'budgetAndCostCenter',
        fields: ['budget', 'costCenter'],
    },
    {
        key: 'cventIdAndType',
        fields: ['cventId', 'type'],
        spacing: false,
    },
];

export const schemaWithTemplate = [
    {
        key: 'templateId',
        fields: ['templateId'],
        spacing: 'medium',
    },
    ...schema,
];

export const toBizlyEvent = ({ name, cventId, budget, type, costCenter, city, templateId }: EventForm) => ({
    name,
    type,
    cventId,
    budget,
    costCenter,
    templateId,
    ...city,
});

export const validateForm = ({ name, cventId, type, costCenter, city }: EventForm) =>
    name?.trim() && cventId?.toString().trim() && type && costCenter?.trim() && city?.location && city?.googlePlaceId;
