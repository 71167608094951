import { get, patch, put, post, deleteCall } from './';

export const updateTeamSettings = (teamId: string | number, data: Partial<Bizly.Team>) =>
    put(`teams/${teamId}`, data) as Promise<{ team: Bizly.Team }>;

export const setUserTeam = (userId: string | number, teamId: string | number) =>
    patch(`users/${userId}/set-team`, { teamId }) as Promise<{ user: Bizly.User }>;

export const getTeamMembers = (teamId: string | number) =>
    get(`teams/${teamId}/members`) as Promise<{ members: BizlyAPI.TeamMember[] }>;

export const addTeamMember = (teamId: string | number, email: string) =>
    post(`teams/${teamId}/members`, { email }) as Promise<{ message: string }>;

export const delTeamMember = (teamId: string | number, membershipId: string | number) =>
    deleteCall(`teams/${teamId}/members/${membershipId}`) as Promise<{ members: BizlyAPI.TeamMember[] }>;

export const updateTeamMember = (teamId: string | number, member: BizlyAPI.TeamMember) =>
    put(`teams/${teamId}/members/${member.id}`, member) as Promise<{ member: BizlyAPI.TeamMember }>;
