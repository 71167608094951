import React, { useState, useCallback, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';

import { sendMagicLink, isAuthenticationPartialSuccess, isSSOOnlyAccount, loginWithGoogleId } from 'api';
import { useAuthenticate } from 'pages/Login/util';

import mixpanel from 'utils/mixpanel';
import { TAuthProps, TGoogleAuth } from './types';
import { emailIsValid } from 'utils';

import ContentCard from 'components/ui/ContentCard';
import Policies from 'components/ui/Policies';
import SSOView from './SSOView';
import LinkSentView from './LinkSentView';

import { Copy } from 'ui';
import { H2Headline } from 'components/ui/Headline';
import { SpinnerOverlay, Spinner } from 'components/Spinner';
import UITextButton from 'components/ui/Button/TextButton';

import Form from 'components/Form';
import { makeAuthFields, signInEmailSchema } from './formSchema';

const SubCopy = styled(Copy)`
    font-size: 16px;
    line-height: 24px;
`;

const TextButton = styled(UITextButton)`
    font-size: inherit;
    font-weight: inherit;
    padding: 0;
`;

const StyledLink = styled(Link)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
    cursor: pointer;
`;

export default function SignInPage({
    authFormValue,
    onChange,
    onGoogleSignInSuccess,
}: TAuthProps & { onGoogleSignInSuccess: (user: BizlyAPI.User) => void }) {
    const [isLoading, setIsLoading] = useState(false);
    const [linkIsSent, setLinkIsSent] = useState(false);
    const [SSOLink, setSSOLink] = useState<Nullable<string>>(null);

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    React.useEffect(() => {
        mixpanel.track(`Viewed page: ${history.location.pathname}`);
    }, [history]);

    const [authenticating] = useAuthenticate();

    const onSendLink = useCallback(
        async e => {
            e.preventDefault();

            if (!authFormValue.email || !emailIsValid(authFormValue.email)) {
                return enqueueSnackbar('Please enter a valid email.', {
                    variant: 'error',
                });
            }

            setIsLoading(true);
            try {
                await sendMagicLink({ email: authFormValue.email });
                setLinkIsSent(true);

                mixpanel.track('Requested magic link login');
            } catch (e) {
                if (isSSOOnlyAccount(e)) {
                    return setSSOLink(e.raw.ssoLink);
                }

                if (isAuthenticationPartialSuccess(e)) {
                    if (process.env.REACT_APP_SIGN_UP_V2 === 'true') {
                        // TODO: Remove this env check once Sign Up is released for all environments
                        onChange({ value: { email: undefined } });
                        return history.push('/sign-up', { signInRedirect: true });
                    }

                    return enqueueSnackbar(
                        'We couldn’t find your Bizly Account. If you believe this is an error or would like to create an account, please contact concierge@bizly.com',
                        {
                            variant: 'error',
                        }
                    );
                }

                return enqueueSnackbar('Something went wrong, please try again.', {
                    variant: 'error',
                });
            } finally {
                setIsLoading(false);
            }
        },
        [enqueueSnackbar, authFormValue.email, history, onChange]
    );

    const onGoogleAuthSuccess = useCallback(
        async (resp: TGoogleAuth) => {
            if (resp.tokenId === undefined) {
                return enqueueSnackbar('Something went wrong, please try again.', {
                    variant: 'error',
                });
            }

            const { tokenId: googleIdToken, profileObj } = resp;

            if (!profileObj?.email || !googleIdToken) {
                return enqueueSnackbar('Something went wrong, please try again.', {
                    variant: 'error',
                });
            }
            setIsLoading(true);
            try {
                const { user } = await loginWithGoogleId({ email: profileObj.email, googleIdToken });

                if (user?.authToken) onGoogleSignInSuccess(user);
            } catch (e) {
                if (isSSOOnlyAccount(e)) {
                    return setSSOLink(e.raw.ssoLink);
                }

                if (isAuthenticationPartialSuccess(e)) {
                    if (process.env.REACT_APP_SIGN_UP_V2 === 'true') {
                        return enqueueSnackbar(
                            // TODO - Replace the below snackbar call with the commented out history push once Sign Up is released for all
                            'We couldn’t find your Bizly Account. If you believe this is an error or would like to create an account, please contact concierge@bizly.com',
                            {
                                variant: 'error',
                            }
                        );
                    } else {
                        onChange({ value: { email: undefined } });
                        return history.push('/sign-up', { signInRedirect: true });
                    }
                }

                return enqueueSnackbar('Something went wrong, please try again.', {
                    variant: 'error',
                });
            } finally {
                setIsLoading(false);
            }
        },
        [enqueueSnackbar, onGoogleSignInSuccess, onChange, history]
    );

    const PathToSignUp = process.env.REACT_APP_SIGN_UP_V2 === 'true' && ( // TODO: Remove this env check once Sign Up is released for all environments
        <>
            Don’t have an account? You can <StyledLink to="/sign-up">sign up here.</StyledLink>
        </>
    );

    const ResendEmail = (
        <>
            Didn't receive an email? <TextButton onClick={onSendLink}>Resend Email</TextButton>
        </>
    );

    const authFields = useMemo(
        () =>
            makeAuthFields({
                onSendLink,
                onGoogleAuthSuccess,
            }),
        [onSendLink, onGoogleAuthSuccess]
    );

    if (authenticating)
        return (
            <ContentCard headlineCopy="Sign In">
                <Spinner />
            </ContentCard>
        );

    return (
        <ContentCard headlineCopy="Sign In" footer={!linkIsSent ? PathToSignUp : ResendEmail}>
            {!linkIsSent ? (
                <>
                    {!SSOLink ? (
                        <>
                            <H2Headline>Welcome to Bizly</H2Headline>
                            <SubCopy>
                                Enter the email address associated with your <br />
                                account, and we'll send a magic link to your inbox.
                            </SubCopy>

                            <Form
                                fields={authFields}
                                schema={signInEmailSchema}
                                value={authFormValue}
                                onChange={onChange}
                                disabled={isLoading}
                                isNested
                            />
                        </>
                    ) : (
                        <SSOView SSOLink={SSOLink} />
                    )}
                    <Policies prefix="By signing up or logging in" />
                </>
            ) : (
                <LinkSentView />
            )}
            {isLoading && <SpinnerOverlay />}
        </ContentCard>
    );
}
