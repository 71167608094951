import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import map from 'lodash/map';
import { tzMoment } from 'utils/moment';
import { getDateRange } from 'utils/date_util';

import { getEventPlannerData } from 'api';
import { useEvent } from 'providers/event';
import { LoadSchedule, useSchedule } from 'stores/schedule';
import { LoadMeeting, selectMeeting, useMeetings } from 'stores/meetings';

import { Row, InlineRow, Strike } from 'ui';
import { Tooltip } from '@material-ui/core';
import { TimeZonePicker } from 'components/Form/fields';
import { Spinner } from 'components/Spinner';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { withIconStyles } from 'shared';

const DateInfoIcon = styled(withIconStyles(InfoOutlinedIcon))`
    height: 17px;
    width: 17px;
    margin: 0 -2px;
    -ms-transform: translateY(3px);
    transform: translateY(3px);
`;

const DateText = styled.span`
    font-size: 16px;
    line-height: 19px;
    margin-right: 4px;
`;

const Block = styled.div`
    display: inline-block;
    line-height: 19px;
`;

const EventDateDisplay = () => {
    const { event } = useEvent();
    const meeting = useMeetings(selectMeeting(event.id));
    const [loading, setLoading] = useState(false);
    const [inquiryHasDates, setInquiryHasDates] = useState(false); // TODO: use proposal info not inquiry info to check
    const schedule = useSchedule();

    useEffect(() => {
        const loadPlanner = async () => {
            setLoading(true);
            try {
                const { planner } = await getEventPlannerData(event.id);
                const { eventSpaces = [], accommodations = [] } = planner;
                const [startDate] = getDateRange(map([...eventSpaces, ...accommodations], 'date'));
                if (startDate) {
                    setInquiryHasDates(true);
                }
            } finally {
                setLoading(false);
            }
        };

        loadPlanner();
    }, [event.id]);

    const sameDate = tzMoment(meeting?.startsAt).isSame(tzMoment(meeting?.endsAt), 'date');
    const sameYear = tzMoment(meeting?.startsAt).isSame(tzMoment(meeting?.endsAt), 'year');

    const startTime = meeting?.startsAt
        ? tzMoment(meeting?.startsAt).format(`ddd, MMM DD,${sameYear && !sameDate ? ' ' : ' YYYY'} h:mm A`)
        : null;
    const endTime = meeting?.endsAt
        ? tzMoment(meeting?.endsAt).format(`${sameDate ? '' : 'ddd, MMM DD, YYYY '}h:mm A`)
        : null;

    const timeString = () => {
        if (!startTime) {
            return null;
        }

        if (!endTime || startTime === endTime) {
            return startTime;
        }

        return `${startTime} - ${endTime}`;
    };

    const timeInfo = () => {
        const useBooking = !!meeting?.venueBooking;
        const useInquiry = inquiryHasDates;
        const useAgenda = schedule?.items?.length;

        const infoString = [useAgenda ? 'agenda' : '', useInquiry ? 'inquiry' : useBooking ? 'booking' : '']
            .filter(val => val)
            .join(' and ');

        return (
            <>
                Dates calculated using {infoString}.
                {useInquiry || useBooking ? (
                    <>
                        <br />
                        Guestrooms are given a time of 12:00 PM for calculations.
                    </>
                ) : null}
            </>
        );
    };

    return (
        <InlineRow alignItems="stretch">
            <LoadMeeting id={event.id} />
            <LoadSchedule />
            {timeString() && (
                <Row>
                    <span>
                        <DateText>{meeting?.cancelledAt ? <Strike>{timeString()}</Strike> : timeString()}</DateText>
                        <Block>
                            {meeting && (
                                <TimeZonePicker
                                    date={meeting.startsAt || new Date().toISOString()}
                                    value={event.timeZone}
                                    overrideFormStyles
                                />
                            )}
                            <Tooltip title={!meeting || !event || loading ? <Spinner /> : timeInfo()}>
                                <DateInfoIcon />
                            </Tooltip>
                        </Block>
                    </span>
                </Row>
            )}
        </InlineRow>
    );
};

export default EventDateDisplay;
