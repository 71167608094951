import React from 'react';
import styled, { keyframes, css } from 'styled-components';

import { Copy } from 'ui';

import './Spinner.css';

// Pinched from http://tobiasahlin.com/spinkit/

const fadeInKeyframes = keyframes`
  0%   { opacity: 0; }
  100% { opacity: 1; }
`;

const fadeInStyle = css<{ delay?: number }>`
    animation: ${fadeInKeyframes} 0.3s ${({ delay }) => delay + 's '}ease-in both;
    opacity: 0;
`;

type SpinnerProps = {
    suppressMargin?: boolean;
    unsetMargin?: boolean;
    className?: string;
    delay?: number;
};

const BaseSpinner = ({ suppressMargin, unsetMargin, className }: SpinnerProps) => (
    <div
        style={unsetMargin ? {} : { margin: suppressMargin ? '0' : '20px auto' }}
        className={`sk-fading-circle ${className}`}
    >
        <div className="sk-circle1 sk-circle" />
        <div className="sk-circle2 sk-circle" />
        <div className="sk-circle3 sk-circle" />
        <div className="sk-circle4 sk-circle" />
        <div className="sk-circle5 sk-circle" />
        <div className="sk-circle6 sk-circle" />
        <div className="sk-circle7 sk-circle" />
        <div className="sk-circle8 sk-circle" />
        <div className="sk-circle9 sk-circle" />
        <div className="sk-circle10 sk-circle" />
        <div className="sk-circle11 sk-circle" />
        <div className="sk-circle12 sk-circle" />
    </div>
);

export const Spinner = styled(BaseSpinner)<{ delay?: number }>`
    ${({ delay }) => (delay ? fadeInStyle : '')};
`;

const SpinnerOverlayContainer = styled.div<{
    fixed?: boolean;
    fixedSpinner?: boolean;
    transparent?: boolean;
    clickThrough?: boolean;
    delay?: number;
}>`
    position: absolute;
    ${({ fixed }) =>
        fixed
            ? `
    position: fixed;
    z-index: 1300;
    `
            : ''}
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: ${({ transparent, theme: { getColor, EColors } }) =>
        getColor(EColors.pureWhite, transparent ? 0 : 0.8)};
    ${fadeInStyle}
    > ${Spinner} {
        ${({ fixedSpinner }) =>
            fixedSpinner
                ? `
        position: fixed;
        top: 50vh;
        transform: translateY(-50%);
        `
                : ''}
    }
    ${({ clickThrough }) => (clickThrough ? `pointer-events: none;` : '')}
`;

export const SpinnerOverlay = ({
    message,
    ...props
}: {
    className?: string;
    fixed?: boolean;
    fixedSpinner?: boolean;
    transparent?: boolean;
    clickThrough?: boolean;
    message?: string;
    delay?: number;
}) => (
    <SpinnerOverlayContainer {...props}>
        <BaseSpinner />
        {message && <Copy>{message}</Copy>}
    </SpinnerOverlayContainer>
);
