import React from 'react';
import { SelectField, TextArea } from 'components/FormFields';
import { Copy, Spacer } from 'ui';
import Form from 'components/Form';

const RejectionReasonForm = ({
    options,
    value,
    onChange,
    readonly,
}: {
    options?: BizlyAPI.InquiryRejectionReason[];
    value?: { reason: number | string; note: string };
    onChange?: ({ value }: { value: { reason: number; note: string } }) => void;
    readonly?: boolean;
}) => {
    const rejectionFields = {
        reason: {
            prompt: 'Turn Down Reason',
            type: SelectField,
            options: {
                options: options ? options.map(({ id, reason }) => ({ id, name: reason })) : [],
                placeholder: 'Select a reason',
            },
            perRow: '2/3',
        },
        note: {
            prompt: 'Notes',
            type: TextArea,
            options: {
                placeholder:
                    'Other dates available? Can only accommodate a portion of the inquiry? Let the client know.',
                maxLength: 255,
            },
            perRow: '2/3',
        },
    };

    const rejectionSchema = [
        { fields: ['reason'], spacing: 'small' },
        { fields: ['note'], spacing: false },
    ];

    return (
        <>
            {!readonly && <Copy>Are you sure you want to reject the inquiry?</Copy>}
            <Spacer small />
            <Form
                fields={rejectionFields}
                schema={rejectionSchema}
                onChange={onChange}
                value={value ?? {}}
                readonly={readonly}
                hideReadonlyEmpty
            />
        </>
    );
};

export default RejectionReasonForm;
