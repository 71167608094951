export default {
    brand: '#6236a3',

    /* Buttons */
    primaryAction: '#04c6bc',
    primaryActionHover: '#49e3db',

    warningAction: '#ff5959',
    warningActionHover: '#dd7e8c',

    secondaryTextAction: '#4a4a4a',
    secondaryTextActionHover: '#909090',
    warningTextAction: '#ff5959',
    warningTextActionHover: '#dd7e8c',

    disabledAction: '#e6e5e3',

    /* Icons */
    defaultIconColor: '#2f4bf2',

    primaryDisplayIcon: '#2f4bf2',
    invertedDisplayIcon: '#ffffff',

    protipsIcon: '#2f4bf2',
    protipsBubble: '#f5b11e',

    tilePinIcon: '#909090',

    amenitiesIconBackground: '#faf9f7',

    /* Attendee Status */
    totalAttendee: '#2c2c2c',
    invitedAttendee: '#04c6bc',
    notInvitedAttendee: '#c7c6c5',
    attendingAttendee: '#6236a3',
    notAttendingAttendee: '#ce5567',

    /* Survey Response Status */
    surveysSentTotal: '#000000',
    surveysPending: '#04c6bc',
    surveysRespondedTo: '#6236a3',

    /* Event Status */
    uneditedEvent: '#bed6f1',
    activeEvent: '#04c6bc',
    activeEventHover: '#49e3db',
    completedEvent: '#c7c6c5',
    completedEventHover: '#e6e5e3',

    /* Dashboard */
    dashboardCard: '#faf9f7',
    dashboardHeader: '#2f4bf2',

    /* Parcel Status */
    parcelInvite: '#94b8e0',
    parcelInviteHover: '#b7d0ed',
    parcelInviteSent: '#2f4bf2',
    parcelInviteSentHover: '#5168f2',

    parcelNote: '#baab88',
    parcelNoteHover: '#cdbf9f',
    parcelNoteSent: '#94835d',
    parcelNoteSentHover: '#a89977',

    /* Inquiry Status */
    submittedInquiryStatus: '#00cc87',
    receivedProposalStatus: '#2f4bf2',
    cancelledOrRejectedStatus: '#ff5959',
    contractPendingStatus: '#ed9a09',
    bookedVenueStatus: '#6236a3',

    /* Collaborators */
    canViewPillBackground: '#2f4bf2',
    canViewPillText: '#ffffff',

    /* Team Related */
    homeScreenBanner: '#0582ee',
    officePillColor: '#0582ee',
    teamTemplatePill: '#0582ee',

    teamPreferenceNotesHeader: '#0582ee',

    /*
        Venues                      
    */

    /* Venue Search */
    venueSearchToken: '#94b8e0',
    venueSearchTokenHover: '#b7d0ed',

    /* Venue Map Pins */
    venuePinBase: '#ff5959',
    venuePinOffice: '#0582ee',

    /* Venue Space OR Listing */
    featuredVenueOrSpaceName: '#fccd5f',

    tagColor: '#94b8e0',
    restaurantTagColor: '#c84d5f',

    venueListingHeaders: '#2f4bf2',

    preferenceNotesHighlightedText: '#2f4bf2',

    displayPricing: '#04c6bc',

    /* Forms */
    formLabel: '#2f4bf2',
    formHeading: '#2f4bf2',
    optionalSpecifier: '#4a4a4a',

    /* Panes */
    agendaDayPane: '#2f4bf2',
    agendaDayPaneText: '#ffffff',
    paneListItemBackground: '#faf9f7',
    paneInsideListItemBackground: '#ffffff',

    /* snackbars */
    snackbarSuccess: '#00cc87',
    snackbarError: '#ff5959',
    snackbarInfo: '#2f4bf2',

    /* dropdowns */
    dropdownCheck: '#04c6bc',
    dropdownItem: '#ffffff',
    dropdownItemHover: '#faf9f7',
    dropdownDescription: '#909090',
    dropdownTokenBackground: '#faf9f7',
    dropdownTokenText: '#303030',

    /* progress bars */
    progressBarForeground: '#2f4bf2',
    progressBarBackground: '#e5e5e3',

    /* misc ui */
    accentedHeadline: '#2f4bf2',
    highlightedText: '#2f4bf2',

    softAccentedBackground: '#faf9f7',
    softAccentedBackgroundNested: '#ffffff',
    strongAccentedBackground: '#2f4bf2',

    warningBackground: '#ff5959',
    warningText: '#ff5959',

    bizlyHeadlineDot: '#04c6bc',

    placeholderBackground: '#faf9f7',
    strongCarouselBackground: '#2f4bf2', //loading is a darker shade;
    strongCarouselText: '#ffffff',

    softBorder: '#e6e5e3',

    listItemBackground: '#faf9f7',
    listItemNestedBackground: '#ffffff',

    imagePlaceholderBackground: '#ededed',
    imagePlaceholderIcon: '#d5d5d5',

    /* Preference Pill */
    preferredVenue: '#2f4bf2',
    preferredRank10: '#0582ee',
    preferredRank20: '#b5d0da',
    preferredRank30: '#f8c50a',
    preferredRank40: '#bfbfbf',
    preferredRank50: '#ad843c',

    /* neutrals */
    black: '#2c2c2c',
    darkestGrey: '#303030',
    darkerGrey: '#4a4a4a',
    darkGrey: '#909090',
    grey: '#c7c6c5',
    lightGrey: '#e6e5e3',

    /* pures */
    pureWhite: '#ffffff',
    pureBlack: '#000000',
};
