import { tz as momentTz } from 'moment-timezone';
import carbonSupportedTimeZones from 'components/Form/TimeZonePicker/carbonSupportedTimeZones';

const guessedTimeZone = momentTz.guess();
export const userTimeZone = carbonSupportedTimeZones.includes(guessedTimeZone) ? guessedTimeZone : 'UTC';

export const tzMoment = (date?: string | Date | null | number, timeZone = 'UTC') =>
    date && timeZone
        ? momentTz(typeof date === 'number' ? new Date(date) : date, timeZone)
        : momentTz(undefined, timeZone);

export const userFormattedTimestamp = (isoTimestamp: string, addOnOrAt?: boolean) => {
    const inUserTz = tzMoment(isoTimestamp).tz(userTimeZone);
    const now = tzMoment('', userTimeZone);

    return inUserTz.format(
        inUserTz.isSame(now, 'date')
            ? `${addOnOrAt ? '[at] ' : ''}h:mm a z`
            : inUserTz.isSame(now, 'year')
            ? `${addOnOrAt ? '[on] ' : ''}MMM D, h:mm a z`
            : `${addOnOrAt ? '[on] ' : ''}MMM D, YYYY h:mm a z`
    );
};
