import React, { useState, useCallback, useMemo, forwardRef } from 'react';
import styled from 'styled-components';

import { useUser } from '../../providers/user';
import { units, useMeasurementUnits } from 'stores/measurement-units';
import { usePlaybooks } from 'stores/playbooks';

import Button from 'components/ui/Button';
import { Row } from '../../ui';

import VenueSearchFilters, { TFilterValue } from './VenueSearchFilters';
import { getTokens } from './TokenUtils';
import Tokenizer from '../Tokenizer';

const FiltersButtonButton = styled(Button)`
    margin-right: 16px;
    white-space: nowrap;
`;

type TFiltersButton = {
    toggleFiltersMenu: () => void;
    filterCount: number;
};
const FiltersButton = forwardRef(
    ({ toggleFiltersMenu, filterCount }: TFiltersButton, ref: React.Ref<HTMLButtonElement>) => {
        const hasFilters = filterCount > 0;
        return (
            <FiltersButtonButton secondary={!hasFilters} onClick={toggleFiltersMenu} ref={ref}>
                {hasFilters ? `Filters ${filterCount}` : 'Filters'}
            </FiltersButtonButton>
        );
    }
);

type TFiltersRow = {
    filters: TFilterValue;
    setFilters: (newFilters: TFilterValue) => void;
    disabledBrands?: boolean;
};

export default function FiltersRow({ filters, setFilters, disabledBrands }: TFiltersRow) {
    const filtersButtonRef = React.useRef<HTMLButtonElement>(null);
    const [showFiltersMenu, setShowFiltersMenu] = useState(false);
    const toggleFiltersMenu = useCallback(() => setShowFiltersMenu(prevShowFiltersMenu => !prevShowFiltersMenu), []);
    const { user } = useUser();
    const teamName = useMemo(() => (user.team ? user.team.name : ''), [user.team]);
    const onApply = (newFilters: TFilterValue) => {
        setFilters(newFilters);
        toggleFiltersMenu();
    };
    const { playbookOptions: { venueBrands = [] } = {} } = usePlaybooks();
    const { distance: distanceUnit } = useMeasurementUnits();

    const tokens = useMemo(
        () =>
            getTokens(filters, setFilters, {
                teamName,
                unit: distanceUnit === units.meter ? units.kilometer : units.mile,
                brands: venueBrands,
                disabledBrands,
            }),
        [filters, setFilters, teamName, distanceUnit, venueBrands, disabledBrands]
    );

    return (
        <Row style={{ flex: '1 1 0' }}>
            <FiltersButton toggleFiltersMenu={toggleFiltersMenu} filterCount={tokens.length} ref={filtersButtonRef} />
            <VenueSearchFilters
                filters={filters}
                onApply={onApply}
                anchor={filtersButtonRef.current}
                open={showFiltersMenu}
                onClose={toggleFiltersMenu}
                teamName={teamName}
            />
            <Tokenizer tokens={tokens} />
        </Row>
    );
}
