import React from 'react';
import styled, { css } from 'styled-components';

import { useHistory } from 'react-router-dom';

import { createMeetingActions, useCreateMeeting, ESteps, selCurStep } from './store';

import GenericNavButtons from 'components/GenericNavButtons';
import { ReactComponent as OverviewIconSvg } from 'images/icons/overview.svg';
import { ReactComponent as AgendaIconSvg } from 'images/icons/agenda.svg';
import { ReactComponent as GuestsIconSvg } from 'images/icons/guests.svg';
import { ReactComponent as SendIconSvg } from 'images/icons/send.svg';
import colorFns from 'colorFns';

const iconStyles = css<{ active?: boolean }>`
    height: 16px;
    width: 15px;
    color: ${colorFns.pureWhite};
    fill: ${colorFns.pureWhite};
    margin-left: 18px;
    margin-right: 9px;
`;

const OverviewIcon = styled(OverviewIconSvg)`
    ${iconStyles}
`;
const AgendaIcon = styled(AgendaIconSvg)`
    ${iconStyles}
`;
const GuestsIcon = styled(GuestsIconSvg)`
    ${iconStyles}
`;
const SendIcon = styled(SendIconSvg)`
    ${iconStyles}
`;

const stepToIcon = {
    [ESteps.basic]: OverviewIcon,
    [ESteps.agenda]: AgendaIcon,
    [ESteps.attendees]: GuestsIcon,
    [ESteps.invites]: SendIcon,
    [ESteps.venues]: SendIcon,
    [ESteps.inquiry]: SendIcon,
};

const stepToName = {
    [ESteps.basic]: 'Overview',
    [ESteps.agenda]: 'Agenda',
    [ESteps.attendees]: 'Attendees',
    [ESteps.invites]: 'Send',
    [ESteps.venues]: 'Venues',
    [ESteps.inquiry]: 'Inquiry',
};

export default function NavButtons() {
    const curStep = useCreateMeeting(selCurStep);
    const { stepIdx: curStepIdx, stepList, isPublished } = useCreateMeeting();
    const history = useHistory();

    const goBack = () => {
        history.goBack();
    };

    const linkList = stepList.map((step, stepIdx) => {
        const Icon = stepToIcon[step];
        const name = stepToName[step];

        const disabled = !isPublished && stepIdx > curStepIdx;

        return {
            key: name,
            active: curStep === step,
            disabled,
            onClick: disabled ? () => {} : () => createMeetingActions.goToStep(step),
            children: (
                <>
                    <Icon active={curStep === step} />
                    {name}
                </>
            ),
        };
    });

    return <GenericNavButtons onBack={goBack} links={linkList} />;
}
