import React from 'react';
import styled from 'styled-components/macro';

import Select from '@material-ui/core/Select';

import { Row } from 'ui';
import { ThinHeadline } from 'components/ui/Headline';
import Button from 'components/ui/Button';

import colorFns from 'colorFns';
import ContentCard from 'components/ui/ContentCard';
import LogoBackground from 'components/ui/LogoBackground';

export const Dropdown = styled(Select)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${colorFns.pureWhite};
    border: solid 1px ${colorFns.lightGrey};
    border-radius: 8px;
    height: 43px;
    min-width: 103px;
    outline: none;
    font-size: 15px;
    padding: 0 16px;
    cursor: pointer;

    &:before {
        border-bottom: none !important;
    }

    &:after {
        border-bottom: none !important;
    }

    .MuiSelect-selectMenu.MuiSelect-selectMenu {
        background: ${colorFns.pureWhite};
    }

    .MuiSelect-icon {
        position: initial;
    }
`;

export const UncasedHeadline = styled(ThinHeadline)<{ fontSize?: number }>`
    text-transform: unset;
    margin: 0;
    ${({ fontSize }) => fontSize && `font-size: ${fontSize}px`};

    white-space: unset;
`;

export const EndRow = styled(Row)`
    align-items: flex-end;

    margin-top: 20px;
`;

export const InlineButtonRight = styled(Button)`
    display: block;
    margin-left: auto;
    ${({ width }) => width && `width: ${width}${typeof width === 'number' ? 'px' : ''}`};
`;

export const BizlyLogoPageWithCard = ({ heading = '', children }: { heading?: string; children?: React.ReactNode }) => {
    return (
        <LogoBackground>
            <ContentCard headlineCopy={heading}>{children}</ContentCard>
        </LogoBackground>
    );
};
