import React from 'react';
import Tokenizer from 'components/Tokenizer';

import { getTemplatesUrlFilters, setTemplatesUrlFilters } from './utils';

import { useLocation, useHistory } from 'react-router';

import { useEventTemplateTags } from 'stores/event-template-tags';
import { keyBy } from 'lodash';

export default function FilterTokens() {
    const location = useLocation();
    const history = useHistory();

    const filterValue = getTemplatesUrlFilters(location);

    const tagsStore = useEventTemplateTags();
    const tagsById = tagsStore.tags ? keyBy(tagsStore.tags, 'id') : null;

    const tokens =
        filterValue?.tags?.map(tagId => ({
            id: tagId,
            label: tagsById?.[tagId].name ?? '',
            onRemove: () =>
                setTemplatesUrlFilters(history, curFilters => ({
                    ...curFilters,
                    tags: curFilters.tags?.filter(id => id !== tagId),
                })),
        })) ?? [];

    return <Tokenizer tokens={tokens} />;
}
