import React from 'react';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { captureException } from '@sentry/minimal';

import useKey from '../hooks/useKey';
import { userFormattedTimestamp } from 'utils/moment';

import { acceptProposal, requestExtendProposal } from '../api';
import { useUser } from 'providers/user';

import ConfirmationModal from './ConfirmationModal';

const ProposalAcceptModal = ({
    eventId,
    proposal,
    open,
    closeModal,
    setVenueBooked,
    onExtensionRequest,
}: {
    eventId: number;
    proposal: Bizly.VenueProposal;
    open: boolean;
    closeModal: () => void;
    setVenueBooked: (booked: boolean) => void;
    onExtensionRequest?: () => void;
}) => {
    useKey('Escape', closeModal);
    const { enqueueSnackbar } = useSnackbar();

    const history = useHistory();
    const { toggles } = useUser();
    const expired = proposal && new Date() > new Date(proposal.onHoldUntil);

    const confirm = async () => {
        if (proposal) {
            try {
                await acceptProposal(proposal.id);
                setVenueBooked(true);
                history.push(`/event/${eventId}/venue/proposal/${proposal.id}`);
                closeModal();
            } catch (error) {
                captureException(error);
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            }
        }
    };
    const extend = async () => {
        if (proposal) {
            try {
                await requestExtendProposal(proposal.id);
                if (onExtensionRequest) onExtensionRequest();
                history.push(`/event/${eventId}/venue/proposal/${proposal.id}`);
                closeModal();
                enqueueSnackbar('Request successfully sent.', { variant: 'success' });
            } catch (error) {
                captureException(error);
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            }
        }
    };

    const modalContent = () => {
        if (expired && !toggles.gate.proposalExpiredExtension) {
            return {
                headline: 'Proposal Expired',
                prompt: (
                    <>
                        Unfortunately, this proposal has expired. <br />
                        <br />
                        Please contact the venue.
                    </>
                ),
                warning: true,
            };
        }

        if (expired && proposal?.extensionRejected) {
            return {
                headline: 'Extension Rejected',
                prompt: (
                    <>
                        Unfortunately, this proposal has expired.
                        <br />
                        <br />
                        <b>{proposal.venue.name}</b> has already rejected an extension request{' '}
                        <b>{userFormattedTimestamp(proposal.extensionRequestedAt, true)}</b>.
                        <br />
                        <br />
                        You can still contact the venue to have them update the expiration date.
                    </>
                ),
                warning: true,
            };
        }

        if (expired && proposal?.extensionRequestedAt && !proposal?.extensionRejected) {
            return {
                headline: 'Extension Requested',
                prompt: (
                    <>
                        You sent an extension request{' '}
                        <b>{userFormattedTimestamp(proposal.extensionRequestedAt, true)}</b>.
                        <br />
                        <br />
                        Do you want to resend the request?
                    </>
                ),
                ctaLabel: 'Resend Request',
                onProceed: extend,
            };
        }

        if (expired) {
            return {
                headline: 'Proposal Expired',
                prompt: (
                    <>
                        This proposal can’t be accepted after the expiration date has been reached.
                        <br />
                        <br />
                        Would you like to request an extension?
                    </>
                ),
                ctaLabel: 'Request Extension',
                onProceed: extend,
            };
        }

        return {
            headline: 'Are you sure?',
            prompt:
                'Accepting this proposal will notify the venue and cancel all remaining active inquiries and proposals.',
            ctaLabel: 'Confirm',
            onProceed: confirm,
        };
    };

    return <ConfirmationModal isActive={open} onDismiss={closeModal} {...modalContent()} />;
};

export default ProposalAcceptModal;
