import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import { getEvents, groupEventsByProgress, sortEventsByEndsAt } from 'api';
import { useUser } from 'providers/user';

import { Row, Grid, Spacer, SPACER_SPACES } from 'ui';
import { Headline, H5Headline } from 'components/ui/Headline';
import { SpinnerOverlay } from 'components/Spinner';
import SideNav from 'components/SideNav';
import EventBox, { padWithPlaceholder } from 'components/Events/EventBox';
import SortControl, { sortEvents, TSortBy, TSortOption } from 'components/Events/SortControl';

const Divider = styled.div`
    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    margin-bottom: ${SPACER_SPACES.larger}px;
`;

export const LeftAlignedGrid = styled(Grid)`
    margin: -12px;
    justify-content: flex-start;
`;

const SORT_OPTIONS = [
    {
        key: 'homepageDropdownUniqueKey1',
        value: 'date',
        text: 'Date',
    },
    {
        key: 'homepageDropdownUniqueKey2',
        value: 'location',
        text: 'Location',
    },
    {
        key: 'homepageDropdownUniqueKey3',
        value: 'eventTitle',
        text: 'Meeting name',
    },
] as TSortOption[];

type TAllEvents = {
    active: Bizly.Event[];
    past: Bizly.Event[];
};
const DEFAULT_EVENTS: TAllEvents = {
    active: [],
    past: [],
};

export default function Events() {
    const [events, setEvents] = useState<TAllEvents>(DEFAULT_EVENTS);
    const [sortBy, setSortBy] = useState<TSortBy>('date');
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { enqueueSnackbar } = useSnackbar();
    const { user } = useUser();
    const history = useHistory();

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            try {
                const { events } = await getEvents();
                const { dateless, active, past } = groupEventsByProgress(sortEventsByEndsAt(events));

                setEvents({
                    active: [...active, ...dateless],
                    past,
                });
            } catch (e) {
                enqueueSnackbar('Something went wrong. Please refresh the page and try again.', { variant: 'error' });
            } finally {
                setIsLoading(false);
            }
        })();
    }, [enqueueSnackbar]);

    const handleSort = (newSortBy: TSortBy) => {
        setSortBy(newSortBy);
        setEvents({
            active: sortEvents(events.active, newSortBy),
            past: sortEvents(events.past, newSortBy),
        });
    };

    const viewEvent = (event: Bizly.Event) => history.push(`/event/${event.id}`);

    return (
        <SideNav>
            <Spacer smallish />

            <H5Headline>My Meetings</H5Headline>
            <Spacer larger />

            <div>
                {/* div wrappers to preserve Safari element alignment */}
                <Row justifyContent="space-between" alignItems="flex-end">
                    <Headline>Active Meetings</Headline>
                    <SortControl sortValue={sortBy} options={SORT_OPTIONS} onChange={handleSort} />
                </Row>
                <Divider />
                <LeftAlignedGrid>
                    {padWithPlaceholder(events.active, 12, event => (
                        <EventBox key={event.id} event={event} user={user} onSelect={viewEvent} />
                    ))}
                </LeftAlignedGrid>
            </div>

            <Spacer larger />

            <div>
                <Row justifyContent="space-between" alignItems="flex-end">
                    <Headline>Past Meetings</Headline>
                </Row>
                <Divider />
                <LeftAlignedGrid>
                    {padWithPlaceholder(events.past, 12, event => (
                        <EventBox key={event.id} event={event} user={user} onSelect={viewEvent} />
                    ))}
                </LeftAlignedGrid>
            </div>

            {isLoading && <SpinnerOverlay fixedSpinner />}
        </SideNav>
    );
}
