import React from 'react';

import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';

import { scheduleActions } from './store';

export default function LoadSchedule() {
    const { eventId } = useParams<{ eventId: string }>();

    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        try {
            scheduleActions.load(parseInt(eventId));
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    }, [eventId, enqueueSnackbar]);

    return null;
}
