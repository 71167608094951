import React from 'react';

import { searchCollaborators } from 'api';

import { Column, Copy } from 'ui';

import AutoComplete from 'components/ui/AutoComplete';
import { emailIsValid } from 'utils';

type TOption = PartialExcept<BizlyAPI.EventCollaboratorSuggestion, 'email'>;

export const AddAttendees = ({
    attendees,
    addAttendee,
    delAttendee,
}: {
    attendees: BizlyAPI.BasicAttendee[];
    addAttendee: (attendee: BizlyAPI.BasicAttendee) => void;
    delAttendee: (email?: string) => void;
}) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<TOption[]>([]);
    const [value, setValue] = React.useState<TOption>({ email: '' });

    async function handleSuggestions(query: string) {
        if (!query) {
            setLoading(false);
        }
        setValue({ email: query });
        if (query?.length < 3) {
            setOptions([]);
        }
        setLoading(true);
        const response = await searchCollaborators(query);
        setLoading(false);

        const queryIsValidEmail = emailIsValid(query);
        const emailIsNew =
            queryIsValidEmail &&
            !response.suggestions.find(response => response.email.toLowerCase() === query.toLowerCase());

        setOptions([...(emailIsNew ? [{ email: query }] : []), ...response.suggestions]);
    }

    const clear = () => {
        setValue({ email: '' });
        setLoading(false);
    };

    return (
        <AutoComplete
            blurOnSelect
            options={options}
            loading={loading}
            getOptionLabel={option => option?.email?.toString() || ''}
            getOptionSelected={option => attendees.some(p => p.email === option.email)}
            noOptionsText={
                (value?.email?.length ?? 0) < 3 ? 'A minimum of 3 characters is required to search' : 'No results'
            }
            value={value}
            onChange={(_, newValue) => {
                if (attendees.some(p => p.email === newValue.email)) {
                    delAttendee(newValue.email);
                    clear();
                    return;
                }
                addAttendee(newValue);
                clear();
            }}
            asField
            inModalOrPopover
            InputProps={{
                autoFocus: true,
                placeholder: 'Add attendee by email',
            }}
            onKeyUp={e => {
                if (e.key === 'Enter' && emailIsValid(value.email)) {
                    addAttendee(value);
                    clear();
                }
            }}
            filterOptions={o => o}
            onInputChange={handleSuggestions}
            renderOption={contact => (
                <Column itemSpacing="xsmall">
                    <b>
                        <Copy>{[contact.firstName, contact.lastName].join(' ')}</Copy>
                    </b>
                    <Copy>{contact.email}</Copy>
                </Column>
            )}
        />
    );
};
