import React from 'react';

import { createPlaybookActions, useCreatePlaybook } from './store';

import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import { Spinner } from 'components/Spinner';

import Communication from 'pages/Communication';
import EditParcel from 'pages/EditParcel';

export default function CommunicationForm() {
    const { id: playbookId, parcelLoadKey } = useCreatePlaybook();
    const routeMatch = useRouteMatch();

    if (!playbookId) return <Spinner />;

    return (
        <>
            <Communication playbookId={playbookId} key={parcelLoadKey} />
            <Switch>
                <Route exact path={routeMatch.path + '/communication/create/:type'}>
                    <Dialog open fullScreen>
                        <EditParcel
                            context={{ id: playbookId, editable: true, asPlaybookParcel: true }}
                            onParcelCreate={createPlaybookActions.triggerParcelsLoad}
                        />
                    </Dialog>
                </Route>
                <Route exact path={routeMatch.path + '/communication/edit/:type/:parcelId'}>
                    <Dialog open fullScreen>
                        <EditParcel
                            context={{ id: playbookId, editable: true, asPlaybookParcel: true }}
                            onParcelSave={createPlaybookActions.triggerParcelsLoad}
                        />
                    </Dialog>
                </Route>
            </Switch>
        </>
    );
}
