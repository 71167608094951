import React from 'react';

import { useSnackbar } from 'notistack';

import { playbooksActions } from './store';

export default function LoadPlaybook({ id }: { id: number | string }) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        try {
            playbooksActions.loadSingle(id);
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    }, [id, enqueueSnackbar]);

    return null;
}
