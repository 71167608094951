import React from 'react';
import { useSnackbar } from 'notistack';
import pluckFileName from 'utils/pluckFileName';
import { Copy, ExternalLink, Spacer } from 'ui';
import Form from 'components/Form';
import { fields, schema, schemaWithoutGuestrooms } from 'components/FinalSpendTracking/finalSpendFormSchema';
import styled from 'styled-components';
import { H2Headline } from 'components/ui/Headline';
import { useDropzone } from 'react-dropzone';
import Button from 'components/ui/Button';
import fontFns from 'fontFns';

export type TFinalSpendForm = {
    av?: number;
    catering?: number;
    guestrooms?: number;
    misc?: number;
    roomRental?: number;
    total?: number;
    existingDocs: {
        invoice1?: string;
        invoice2?: string;
    };
    stagedDocs?: {
        invoice1?: File;
        invoice2?: File;
    };
    currency?: string | null;
};

const Headline = styled(H2Headline)`
    margin-bottom: 32px;
`;

const PlaceholderText = styled.p`
    font-size: 15px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
`;

const FormLabel = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel}
`;

const HighlightedCopy = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.highlightedText)};
`;

const SmallCopy = styled(Copy)`
    font-size: 13px;
`;

const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.listItemBackground)};
    height: 60px;
    margin: 12px 0;
    padding: 0 16.9px;
`;

type UploadBoxProps = {
    fileName?: string;
    target: string;
    onUpload: any;
    link?: string;
    readOnly?: boolean;
};

const UploadBox = ({ fileName, target, onUpload, link, readOnly = false }: UploadBoxProps) => {
    const onDrop = (files: any) => onUpload(files, target);
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    const { color: inputColor, ...inputProps } = getInputProps();
    const { color: rootColor, ...rootProps } = getRootProps();

    return (
        <Box>
            {link ? (
                <ExternalLink href={link} target="_blank" rel="noopener noreferrer" openInNewTab>
                    {fileName || link}
                </ExternalLink>
            ) : fileName ? (
                <p>{fileName}</p>
            ) : (
                <PlaceholderText>No file selected</PlaceholderText>
            )}
            <input {...inputProps} />
            {!readOnly && (
                <div {...rootProps}>
                    <Button onClick={() => null} small secondary width={92}>
                        {fileName ? 'Replace' : 'Select File'}
                    </Button>
                </div>
            )}
        </Box>
    );
};

export default function FinalSpendForm({
    booking,
    formValues,
    onFormUpdate,
    teamCompliance,
    readonly,
    disabled,
    hideHeader,
}: {
    booking: Bizly.Booking;
    formValues: TFinalSpendForm;
    onFormUpdate: (finalSpendForm: TFinalSpendForm) => void;
    teamCompliance: Bizly.TeamCompliance;
    readonly?: boolean;
    disabled?: boolean;
    hideHeader?: boolean;
}) {
    const { enqueueSnackbar } = useSnackbar();
    const { existingDocs, currency, stagedDocs = {} } = formValues;

    const handleStagingDoc = async (files: any, target: string) => {
        const maxMB = 41943040; // 40mb
        const file = files[0] || {};
        if (file.size > maxMB) {
            enqueueSnackbar(`File ${file.name} is too large.`, {
                variant: 'error',
            });
        } else {
            onFormUpdate({ ...formValues, stagedDocs: { ...stagedDocs, [target]: file } });
        }
    };

    return (
        <>
            {!hideHeader && <Headline>Final Spend Tracker</Headline>}
            {booking?.id ? (
                <>
                    <FormLabel>Upload Final Invoice</FormLabel>
                    <UploadBox
                        onUpload={handleStagingDoc}
                        link={stagedDocs?.invoice1?.name ? undefined : booking?.invoiceUrl}
                        fileName={stagedDocs?.invoice1?.name ?? pluckFileName(existingDocs.invoice1)}
                        target={'invoice1'}
                        readOnly={readonly || disabled}
                    />
                    <UploadBox
                        onUpload={handleStagingDoc}
                        link={stagedDocs?.invoice2?.name ? undefined : booking?.invoice2Url}
                        fileName={stagedDocs?.invoice2?.name ?? pluckFileName(existingDocs.invoice2)}
                        target={'invoice2'}
                        readOnly={readonly || disabled}
                    />

                    <SmallCopy>Max file upload size: 40MB.</SmallCopy>

                    <Spacer large />

                    <Form
                        fields={fields(currency ?? 'USD')}
                        schema={teamCompliance?.guestroomsDisabled ? schemaWithoutGuestrooms : schema}
                        value={{ ...formValues, currencyCode: currency ?? 'USD' }}
                        onChange={({ value }: { value: TFinalSpendForm }) => onFormUpdate(value)}
                        isNested={false}
                        densePadding={false}
                        readonly={readonly}
                        disabled={disabled}
                    />
                </>
            ) : (
                <HighlightedCopy>Final spend tracking becomes available once a venue has been booked.</HighlightedCopy>
            )}
        </>
    );
}
