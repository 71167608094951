import React from 'react';
import styled from 'styled-components';

import moment from 'moment';
import { tzMoment, userTimeZone } from 'utils/moment';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';

import { createMeeting } from 'api/meetings';

import { ReactComponent as ClockIconSVG } from 'images/icons/clock.svg';
import { ReactComponent as PlusSVG } from 'images/icons/plus.svg';

import Button from 'components/ui/Button';
import { Row, Copy, Column, TruncatingSingleLineCopy, InlineRow, Strike } from 'ui';
import { SpinnerOverlay } from 'components/Spinner';
import Pill from 'components/ui/Pill';
import { Tooltip } from '@material-ui/core';

import useThemedColor from 'hooks/useThemedColor';
import colorFns from 'colorFns';

const ClockIcon = styled(ClockIconSVG)`
    width: 22px;
    height: 22px;
`;

const HoverRow = styled(Row)`
    padding: 12px 0;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
        background-color: ${colorFns.listItemBackground};
    }
`;

const CircleImg = styled.img`
    height: 36px;
    width: 36px;
    border-radius: 50%;
    object-fit: cover;
`;

const AutoRow = styled(Row)`
    width: auto;
`;
const FullWidthCol = styled(Column)`
    position: relative;
    width: 100%;
`;

const ClockCol = styled(Column)`
    flex: 0 0 20px;
    min-width: 20px;
`;
const TimeCol = styled(Column)<{ wide?: boolean }>`
    flex: 0 0 ${({ wide }) => (wide ? 60 : 40)}px;
    min-width: ${({ wide }) => (wide ? 60 : 40)}px;
`;
const NameCol = styled(Column)`
    flex: 0 0 300px;
    min-width: 300px;
    align-items: flex-start;
`;
const PlaceCol = styled(Column)`
    flex: 0 0 200px;
    min-width: 200px;
`;

const CreatedByRow = styled(Row)`
    align-items: center;
    justify-content: flex-end;
    flex: 1 0 120px;
    min-width: 120px;
`;

const PlusIcon = styled(PlusSVG)``;
const QuickCreateButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: auto;

    padding: 12px;

    border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    border-radius: 12px;

    ${PlusIcon} {
        display: inline-flex;
        white-space: nowrap;
        padding: 0;
        width: 20px;
        height: 20px;

        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

        border-radius: 50%;
        min-width: 0;
        align-items: center;
        justify-content: center;
    }
`;

const DateRowSticky = styled(Row)<{ sticky?: boolean }>`
    min-height: 28px;

    ${props =>
        props.sticky &&
        `
    position: sticky;
    top: 0;
    background: ${colorFns.pureWhite(props)};
    z-index: 10;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: -12px;
    margin-right: -12px;
`}
`;

export default function ScheduledList({
    perWeek,
    onClick,
    small,
    showQuickCreate,
    loading,
    stickyDates,
}: {
    perWeek: [moment.Moment, BizlyAPI.Meeting[]][];
    onClick: (meeting: BizlyAPI.Meeting) => void;
    small?: boolean;
    showQuickCreate?: boolean;
    loading?: boolean;
    stickyDates?: boolean;
}) {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const { warningAction } = useThemedColor();

    const handleCreateMeetingAtDate = async (date: moment.Moment) => {
        const startsAt = date.format('YYYY-MM-DD [12:00]');
        const endsAt = date.format('YYYY-MM-DD [12:30]');
        try {
            const { meeting } = await createMeeting({ startsAt, endsAt });
            history.push(`/event/${meeting.id}`);
        } catch {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        } finally {
        }
    };

    const DateRow = ({ date }: { date: moment.Moment }) => (
        <DateRowSticky justifyContent="space-between" sticky={stickyDates}>
            <Row>
                <b>
                    <Copy>{date.format('dddd')}</Copy>
                </b>
                {!small && <Copy>, {date.format('MMMM DD, YYYY')}</Copy>}
            </Row>
            {showQuickCreate && (
                <QuickCreateButton secondary onClick={() => handleCreateMeetingAtDate(date)}>
                    <PlusIcon />
                </QuickCreateButton>
            )}
        </DateRowSticky>
    );

    const TimesCol = ({ event, collapsed }: { event: BizlyAPI.Meeting; collapsed?: boolean }) => {
        const daysBetween = moment(event.endsAt).diff(moment(event.startsAt), 'days');

        return (
            <AutoRow itemSpacing="xsmall">
                <TimeCol wide={collapsed}>
                    <Copy>{moment(event.startsAt).format('h:mm')}</Copy>
                    {collapsed && (
                        <Copy small>
                            {moment(event.endsAt).format('h:mm')} {daysBetween > 1 && <span>+{daysBetween}</span>}
                        </Copy>
                    )}
                </TimeCol>
                {!collapsed && (
                    <TimeCol wide>
                        <Copy>
                            {moment(event.endsAt).format('h:mm')} {daysBetween > 1 && <span>+{daysBetween}</span>}
                        </Copy>
                    </TimeCol>
                )}
            </AutoRow>
        );
    };

    const MeetingsList = ({ meetings }: { meetings: BizlyAPI.Meeting[] }) => (
        <FullWidthCol itemSpacing="xsmall">
            {meetings.map(meeting => {
                const cancelledAtTime =
                    meeting.cancelledAt &&
                    `Cancelled on ${tzMoment(meeting.cancelledAt.date, meeting.cancelledAt.timezone)
                        .tz(userTimeZone)
                        .format('MMM DD, YYYY h:mm a z')}`;

                const MeetingName = (
                    <TruncatingSingleLineCopy>
                        {meeting.name ? <b>{meeting.name}</b> : <i>Untitled</i>}
                    </TruncatingSingleLineCopy>
                );

                const content = (
                    <HoverRow
                        key={meeting.id}
                        itemSpacing="default"
                        alignItems="center"
                        onClick={() => onClick(meeting)}
                    >
                        <ClockCol>
                            <ClockIcon />
                        </ClockCol>
                        <TimesCol event={meeting} collapsed={small} />
                        <NameCol>
                            {cancelledAtTime && !small && (
                                <Tooltip title={cancelledAtTime}>
                                    <Pill color={warningAction} label="Cancelled" noShadow centerText />
                                </Tooltip>
                            )}
                            <InlineRow itemSpacing="xsmall">
                                {cancelledAtTime ? <Strike>{MeetingName}</Strike> : MeetingName}
                            </InlineRow>
                            {small && (
                                <Copy small>
                                    {[meeting.createdBy?.firstName, meeting.createdBy?.lastName].join(' ')}
                                </Copy>
                            )}
                        </NameCol>
                        {!small && (
                            <PlaceCol>
                                <Copy>{meeting?.virtualLocation ?? 'Meeting room'}</Copy>
                            </PlaceCol>
                        )}
                        {!small && (
                            <CreatedByRow itemSpacing="small">
                                <Copy>{[meeting.createdBy?.firstName, meeting.createdBy?.lastName].join(' ')}</Copy>
                                <CircleImg src={meeting.createdBy?.imageUrl} />
                            </CreatedByRow>
                        )}
                    </HoverRow>
                );

                return cancelledAtTime && small ? (
                    <Tooltip title={cancelledAtTime} placement="top">
                        {content}
                    </Tooltip>
                ) : (
                    content
                );
            })}
        </FullWidthCol>
    );

    return (
        <FullWidthCol itemSpacing="medium">
            {perWeek.map(([date, meetings]) =>
                meetings.length ? (
                    <Row key={date.toISOString()}>
                        <FullWidthCol itemSpacing="xsmall">
                            <DateRow date={date} />
                            <MeetingsList meetings={meetings} />
                        </FullWidthCol>
                    </Row>
                ) : null
            )}
            {loading && <SpinnerOverlay transparent clickThrough />}
        </FullWidthCol>
    );
}
