import React from 'react';

import Form from '../components/Form';

export const TRAVEL_NEEDS_VALUES = {
    // if typescripting, enum this
    NO: 'No',
    YES: 'Yes',
};

const ATTENDANCE_TYPE_VALUES = {
    // if typescripting, enum this
    PHYSICAL: 'In-person',
    VIRTUAL: 'Virtual',
};

function TravelAccomodationsField({
    field: formField,
    value = {
        needsTravel: TRAVEL_NEEDS_VALUES.NO,
    },
    errors = {},
    onChange: onChangeProp,
}) {
    const fields = {
        needsTravel: {
            prompt: 'Do you need overnight accommodation?',
            type: 'radioselect',
            options: {
                options: Object.values(TRAVEL_NEEDS_VALUES),
                perRow: 6,
            },
        },
        checkInDate: {
            prompt: 'Check-in Date',
            type: 'date',
            perRow: 3,
        },
        checkOutDate: {
            prompt: 'Check-out Date',
            type: 'date',
            perRow: 3,
        },
    };

    const schema = [
        {
            key: 'needsTravel',
            fields: ['needsTravel'],
            spacing: value.needsTravel === TRAVEL_NEEDS_VALUES.YES ? 'small' : false,
        },
        ...(value.needsTravel === TRAVEL_NEEDS_VALUES.YES
            ? [
                  {
                      key: 'dates',
                      fields: ['checkInDate', 'checkOutDate'],
                      spacing: false,
                  },
              ]
            : []),
    ];

    const onChange = ({ value: newValue, errors: newErrors, field }) =>
        onChangeProp({
            value: newValue[field],
            errors: newErrors[field],
            field: `${formField}.${field}`,
        });

    const props = { fields, schema, value, errors, onChange };
    return <Form {...props} />;
}

const dietaryIdToField = (id, allergies = false) => `${allergies ? 'allergy' : 'dietary'}-${id}`;
const fieldIdToDietary = id => parseInt(id.split('-')[1]);

export function DietaryQuestionsField({
    value = {
        dietaryRestrictions: [],
        foodAllergies: [],
    },
    errors = {},
    optional,
    readonly,
    densePadding,
    onChange: onChangeProp,
    prompt = 'Do you have any dietary needs?',
    dietaryRestrictionOptions = [],
    foodAllergyOptions = [],
    perRow = 4,
}) {
    // API data to field data by combining both dietary values:
    const { dietaryRestrictions, foodAllergies } = value;
    const fieldValue = {
        dietary: [
            ...dietaryRestrictions.map(id => dietaryIdToField(id)),
            ...foodAllergies.map(id => dietaryIdToField(id, true)),
        ],
    };

    // API options to field options:
    const restrictions = dietaryRestrictionOptions.map(r => ({
        ...r,
        id: dietaryIdToField(r.id),
    }));
    const allergies = foodAllergyOptions.map(r => ({
        ...r,
        id: dietaryIdToField(r.id, true),
    }));
    const restrictionSet = new Set(restrictions.map(r => r.id));
    const allergySet = new Set(allergies.map(a => a.id));

    const fields = {
        dietary: {
            prompt,
            type: 'multiselect',
            optional,
            readonly,
            options: {
                options: [...restrictions, ...allergies],
                perRow,
                placeholder: 'No dietary needs selected.',
            },
        },
    };

    const schema = [
        {
            key: 'dietary',
            fields: ['dietary'],
            spacing: false,
        },
    ];

    const onChange = ({ value: newValue, errors: newErrors, field }) => {
        // field data to API data by splitting dietary value:
        onChangeProp({
            value: {
                dietaryRestrictions: newValue.dietary.filter(v => restrictionSet.has(v)).map(v => fieldIdToDietary(v)),
                foodAllergies: newValue.dietary.filter(v => allergySet.has(v)).map(v => fieldIdToDietary(v)),
            },
            errors: newErrors,
            field,
        });
    };

    const props = {
        fields,
        schema,
        value: fieldValue,
        errors,
        onChange,
        densePadding,
    };
    return <Form {...props} />;
}

export const DEFAULT_QUESTION_TO_ID = {
    DIETARY_RESTRICTIONS: 1,
    TRAVEL_ACCOMMODATIONS: 3,
    LOGISTICS: 4,
    ATTENDANCE_TYPE: 11,
};

export default function RSVPForm({ attendee = {}, formSettings = {}, onChange: onChangeProp }) {
    const { dietaryRestrictions, foodAllergies, checkInDate, checkOutDate, ...formValue } = attendee;
    formValue.travel = formValue.travel || {
        needsTravel: checkInDate || checkOutDate ? TRAVEL_NEEDS_VALUES.YES : TRAVEL_NEEDS_VALUES.NO,
        checkInDate,
        checkOutDate,
    };
    formValue.dietary = { dietaryRestrictions, foodAllergies };

    const { defaultFieldsEnabled, dietaryRestrictionOptions, foodAllergyOptions } = formSettings;
    const defaultFieldsEnabledSet = new Set(defaultFieldsEnabled.map(d => d.id));

    const fields = {
        firstName: {
            prompt: 'First Name',
            type: 'text',
            perRow: 2,
            options: {
                placeholder: 'Enter first name',
            },
        },
        lastName: {
            prompt: 'Last Name',
            type: 'text',
            perRow: 2,
            options: {
                placeholder: 'Enter last name',
            },
        },
        phone: {
            type: 'phone',
            prompt: 'Phone Number',
            perRow: 2,
        },
        email: {
            prompt: 'Email',
            type: 'text',
            perRow: 2,
            readonly: true,
        },
        travel: {
            type: TravelAccomodationsField,
        },
        dietary: {
            type: DietaryQuestionsField,
            optional: true,
            options: {
                ...(defaultFieldsEnabledSet.has(DEFAULT_QUESTION_TO_ID.DIETARY_RESTRICTIONS)
                    ? { dietaryRestrictionOptions, foodAllergyOptions }
                    : {}),
            },
        },
        rsvpComments: {
            prompt: 'Itinerary/Travel Notes',
            type: 'textarea',
            perRow: 2,
            optional: true,
            options: {
                placeholder: 'Add a note for the meeting organizer.',
            },
        },
        attendanceType: {
            prompt: 'How do you plan to attend?',
            type: 'radioselect',
            options: {
                options: Object.values(ATTENDANCE_TYPE_VALUES),
                perRow: 4,
            },
            optional: false,
        },
        customQuestions: {
            type: CustomQuestionsForm,
            options: {
                questions: formValue.customQuestions,
            },
        },
    };

    const schema = [
        {
            key: 'name',
            fields: ['firstName', 'lastName'],
        },
        {
            key: 'basic',
            fields: ['phone', 'email'],
        },
        ...(defaultFieldsEnabledSet.has(DEFAULT_QUESTION_TO_ID.TRAVEL_ACCOMMODATIONS)
            ? [
                  {
                      key: 'travel',
                      fields: ['travel'],
                  },
              ]
            : []),
        ...(defaultFieldsEnabledSet.has(DEFAULT_QUESTION_TO_ID.DIETARY_RESTRICTIONS)
            ? [
                  {
                      key: 'dietary',
                      fields: ['dietary'],
                  },
              ]
            : []),
        ...(defaultFieldsEnabledSet.has(DEFAULT_QUESTION_TO_ID.LOGISTICS)
            ? [
                  {
                      key: 'travelNotes',
                      fields: ['rsvpComments'],
                  },
              ]
            : []),
        ...(defaultFieldsEnabledSet.has(DEFAULT_QUESTION_TO_ID.ATTENDANCE_TYPE)
            ? [
                  {
                      key: 'attendanceType',
                      fields: ['attendanceType'],
                  },
              ]
            : []),
        ...(formValue.customQuestions.length
            ? [
                  {
                      key: 'customQuestions',
                      fields: ['customQuestions'],
                  },
              ]
            : []),
    ];

    const onChange = ({ value: { dietary, ...newValue }, errors: newErrors, field }) => {
        const { checkInDate: newCheckInDate, checkOutDate: newCheckOutDate, needsTravel } = newValue.travel || {};
        const customQuestionResponses = newValue.customQuestions.map(({ id, response }) => ({ id, response }));
        onChangeProp({
            value: {
                ...newValue,
                ...dietary,
                needsTravel,
                ...(needsTravel === TRAVEL_NEEDS_VALUES.NO
                    ? { checkInDate: null, checkOutDate: null }
                    : {
                          checkInDate: newCheckInDate,
                          checkOutDate: newCheckOutDate,
                      }),
                customQuestionResponses,
            },
            errors: newErrors,
            field,
        });
    };

    const props = { fields, schema, value: formValue, errors: {}, onChange };
    return <Form {...props} />;
}

const customQuestionToField = {
    5: {
        type: 'text',
        perRow: 2,
        options: {},
    },
    6: {
        type: 'radioselect',
        perRow: 1,
        options: {
            perRow: 4,
        },
    },
    7: {
        type: 'multiselect',
        perRow: 1,
        options: {
            perRow: 4,
        },
    },
    8: {
        type: 'date',
        perRow: 2,
        options: {},
    },
    9: {
        type: 'linear_scale',
        perRow: 1,
        options: {},
    },
};

const questionsToFields = (questions = [], readonly, placeholder) =>
    questions.reduce((agg, { id, definition, name, prompt, options, responseRequired }) => {
        const { type, perRow, options: customOptions } = customQuestionToField[definition.id];
        agg[id] = {
            prompt: prompt || name,
            type,
            perRow,
            optional: !responseRequired,
            readonly,
            options: {
                options,
                placeholder,
                ...customOptions,
            },
        };
        return agg;
    }, {});

const questionsAPItoValue = (APIVal = []) =>
    APIVal.reduce((agg, { id, response }) => {
        agg[id] = response;
        return agg;
    }, {});

const questionsToSchema = ({ questions, header, bottomSpacing }) =>
    questions.map(({ id }, idx) => ({
        key: id,
        header: idx === 0 ? header : undefined,
        fields: [id],
        ...(!bottomSpacing && idx === questions.length - 1 ? { spacing: false } : {}),
    }));

const valueToQuestionsAPI = ({ field, value, questions }) =>
    questions.map(q => ({
        ...q,
        response: field === q.id ? value : q.response,
    }));

export function CustomQuestionsForm({
    field: formField,
    value,
    errors = {},
    onChange: onChangeProp,
    readonly = false,
    isNested = false,
    questions,
    placeholder = '',
    header = '',
}) {
    const onChange = ({ value: newValue, errors: newErrors, field }) => {
        onChangeProp({
            value: valueToQuestionsAPI({
                field,
                value: newValue[field],
                questions,
            }),
            errors: newErrors[field],
            field: `${formField}`,
        });
    };

    const props = {
        fields: questionsToFields(questions, readonly, placeholder),
        schema: questionsToSchema({
            questions,
            header,
            bottomSpacing: !isNested,
        }),
        value: questionsAPItoValue(value),
        errors,
        onChange,
    };

    return <Form {...props} />;
}
