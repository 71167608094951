import React from 'react';
import VenueFiltersForm from 'pages/PlaybookWizard/VenueFiltersForm';
import { toMiles } from 'utils';

const PlaybookVenueFilters = ({ playbook }: { playbook: BizlyAPI.Complete.Playbook }) => {
    const { radius, brandIds = [], ...filtersVal } = playbook.venueSearchParameters ?? {};
    const value = {
        ...filtersVal,
        ...(radius
            ? {
                  radius: toMiles(radius),
              }
            : {}),
        brandIds,
    };

    return <VenueFiltersForm value={value} readonly />;
};

export default PlaybookVenueFilters;
