import React from 'react';
import styled from 'styled-components';
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router';
import pick from 'lodash/pick';

import { createMeetingActions, useCreateMeeting, ESteps, selCurStep, LoadCreateMeeting } from './store';
import { LoadPlaybooks, selectPlaybook, usePlaybooks } from 'stores/playbooks';
import { useUser } from 'providers/user';

import { Column, Spacer } from 'ui';
import Header from './components/Header';
import { SpinnerOverlay } from 'components/Spinner';
import BasicInfoForm from './BasicInfoForm';
import AgendaForm from './AgendaForm';
import AttendeesForm from './AttendeesForm';
import InvitesForm from './InvitesForm';

import EventComponent from 'components/EventComponent';
import BuildInquiryComponent from 'components/BuildInquiryComponent';
import { Provider as SlotFillProvider } from 'react-slot-fill';
import EventProvider from 'providers/event';
import EventOptionsProvider from 'providers/event-options';
import { LoadCurrentInquiry } from 'stores/current-inquiry';

const Container = styled(Column)`
    position: relative;
    width: 100%;
    min-height: 100vh;
    align-items: flex-start;
    flex: 0 0 auto;
`;

const SpinnerOverlayAbove = styled(SpinnerOverlay)`
    z-index: 10;
`;

const WideHeader = styled(Header)`
    width: calc(100% + 64px);
    margin-left: -32px;
`;

const Sticky = styled.div<{ float?: boolean }>`
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    ${({ float }) =>
        float
            ? `
        & > ${WideHeader} {
            margin-bottom: -100%;
        }
    `
            : ''}
`;

const Offset = styled.div`
    margin-left: -94px;
`;

const stepToForm = {
    [ESteps.basic]: <BasicInfoForm />,
    [ESteps.agenda]: <AgendaForm />,
    [ESteps.attendees]: <AttendeesForm />,
    [ESteps.invites]: <InvitesForm />,
    [ESteps.venues]: <EventComponent />,
    [ESteps.inquiry]: <BuildInquiryComponent />,
};

const SetStep = ({ step, onChange }: { step: ESteps; onChange?: () => void }) => {
    const { loaded } = useCreateMeeting();

    React.useEffect(() => {
        if (loaded) {
            createMeetingActions.goToStep(step);
            onChange?.();
        }
    }, [step, onChange, loaded]);

    return null;
};

const RouteStep = ({ id }: { id: string }) => {
    const match = useRouteMatch();
    const [init, setInit] = React.useState(false);
    const setInitTrue = React.useCallback(() => setInit(true), [setInit]);
    const curStep = useCreateMeeting(selCurStep);

    const history = useHistory();

    React.useEffect(() => {
        if (curStep === ESteps.venues) history.replace({ ...history.location, pathname: `/events/${id}/edit/venue` });
        else {
            history.replace({ ...history.location, pathname: `/events/${id}/edit`, search: '' });
        }
    }, [curStep, history, id]);

    return init ? null : (
        <Switch>
            <Route path={[`${match.path}/venue`]}>
                <SetStep step={ESteps.venues} onChange={setInitTrue} />
            </Route>
            <Route path={[`${match.path}/inquiry`]}>
                <SetStep step={ESteps.inquiry} onChange={setInitTrue} />
            </Route>
        </Switch>
    );
};

export default function CreateMeeting() {
    React.useEffect(() => createMeetingActions.reset, []);

    const curStep = useCreateMeeting(selCurStep);
    const { loading, loaded, saving, publishing, isPublished } = useCreateMeeting();

    const { id } = useParams<{ id?: string }>();
    const location = useLocation<Partial<{ skipLoading?: boolean; playbookId?: number | string; step?: ESteps }>>();
    const history = useHistory();

    React.useEffect(() => {
        if (location.state?.skipLoading) {
            history.replace({ ...history.location, state: undefined });
        }
    }, [location, history]);

    const playbookId = location.state?.playbookId;
    const { loadingAll: loadingPlaybooks } = usePlaybooks();
    const playbook = usePlaybooks(selectPlaybook(playbookId));

    const waitingForPlaybooks = playbookId && loadingPlaybooks;

    React.useEffect(() => {
        if (playbookId && playbook) {
            createMeetingActions.applyPlaybook(playbook);
            history.replace({
                ...history.location,
                state: history.location.state
                    ? { ...history.location.state, playbookId: undefined }
                    : history.location.state,
            });
        }
    }, [playbookId, playbook, history]);

    const initStep = location.state?.step;
    React.useEffect(() => {
        if (initStep) {
            createMeetingActions.goToStep(initStep);
        }
    }, [initStep]);

    React.useEffect(() => {
        if (!id || isPublished === false) {
            history.replace({
                ...history.location,
                state: history.location.state ? { ...history.location.state, step: undefined } : history.location.state,
            });
        }
    }, [id, history, isPublished]);

    React.useEffect(() => {
        if (isPublished) {
            history.replace({
                ...history.location,
                state: { ...history.location.state, step: curStep },
            });
        }
    }, [isPublished, curStep, history]);

    const fullscreenForm = curStep === ESteps.invites || curStep === ESteps.venues;
    const form = stepToForm[curStep];

    React.useEffect(() => {
        if (!id) createMeetingActions.setSteps([ESteps.basic, ESteps.venues, ESteps.inquiry]);
    }, [id, isPublished]);

    const { user } = useUser();
    const useCreateEventModal = user.team?.useCreateEventModal;
    React.useEffect(() => {
        if (useCreateEventModal)
            createMeetingActions.setAdditionalRequired(['location', 'type', 'costCenter', 'internalReference']);
    }, [useCreateEventModal]);

    React.useEffect(() => {
        if (!id) createMeetingActions.setAttendees([{ ...pick(user, ['firstName', 'lastName', 'email', 'phone']) }]);
    }, [id, user]);

    return (
        <SlotFillProvider>
            <Container>
                <LoadCreateMeeting id={id} skip={location.state?.skipLoading} />
                <LoadPlaybooks />
                <Sticky float={fullscreenForm}>
                    <WideHeader noBackground={fullscreenForm} />
                </Sticky>
                {id && <RouteStep id={id} />}

                {!fullscreenForm && <Spacer small />}

                {id && loaded ? (
                    <EventOptionsProvider eventId={id}>
                        <EventProvider eventId={Number(id)}>
                            <LoadCurrentInquiry />

                            {fullscreenForm ? <Offset>{form}</Offset> : form}
                        </EventProvider>
                    </EventOptionsProvider>
                ) : fullscreenForm ? (
                    <Offset>{form}</Offset>
                ) : (
                    form
                )}

                {(loading || saving || publishing || waitingForPlaybooks) && <SpinnerOverlayAbove fixed />}
            </Container>
        </SlotFillProvider>
    );
}
