import { get, post, put, deleteCall } from '.';

export const getSchedule = (eventId: number) =>
    get(`events/${eventId}/schedule-items`) as Promise<{ scheduleItems: BizlyAPI.ScheduleItem[] }>;

export const addScheduleItem = (eventId: number, data: Omit<BizlyAPI.ScheduleBlock, 'id'>) =>
    post(`events/${eventId}/schedule-items`, data) as Promise<{ scheduleItem: BizlyAPI.ScheduleItem }>;

export const getScheduleItem = (eventId: number, scheduleItemId: number) =>
    get(`events/${eventId}/schedule-items/${scheduleItemId}`).then(({ status, scheduleItem }) => ({
        // TODO: Remove this once the backend is supplying unique IDs for all agenda entries.
        status,
        scheduleItem: {
            ...scheduleItem,
            agenda: scheduleItem.agenda.map((agendaEntry: BizlyAPI.ScheduleAgendaEntry, idx: number) => ({
                ...agendaEntry,
                id: idx,
            })),
        },
    })) as Promise<{
        scheduleItem: BizlyAPI.ScheduleItem;
    }>;

export const updateScheduleItem = (eventId: number, scheduleItemId: number, data: Partial<BizlyAPI.ScheduleBlock>) =>
    put(`events/${eventId}/schedule-items/${scheduleItemId}`, data) as Promise<{
        scheduleItem: BizlyAPI.ScheduleItem;
    }>;

export const deleteScheduleItem = (eventId: number, scheduleItemId: number) =>
    deleteCall(`events/${eventId}/schedule-items/${scheduleItemId}`) as Promise<{
        scheduleItems: BizlyAPI.ScheduleItem[];
    }>;
