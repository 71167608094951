import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import * as URI from 'uri-js';
import { useSnackbar } from 'notistack';

import { useUser } from 'providers/user';
import { useEvent } from 'providers/event';

import { Column as UIColumn, Row as UIRow, Spacer } from 'ui';
import { ProTip } from 'components/ProTip';
import { H5Headline as UIH5Headline } from 'components/ui/Headline';
import Accordion from 'components/ui/Accordion';
import {
    SCHEDULE,
    EVENT_SPACES,
    ACCOMMODATIONS,
    VIRTUAL,
    formatScheduleSummary,
    formatEventSpacesSummary,
    formatAccommodationsSummary,
    formatVirtualMeetingsSummary,
} from 'components/Planner/utils';
import {
    TPlannerDataChange,
    TPlannerSections,
    TPlannerForm,
    TAccommodation,
    TEventSpace,
} from 'components/Planner/types';
import {
    getEventPlannerData,
    updateEventPlannerData,
    getPlaybookPlannerData,
    updatePlaybookPlannerData,
    createVirtualMeeting,
    updateVirtualMeeting,
    deleteVirtualMeeting,
} from 'api';
import { useUnsavedPrompt } from 'components/ProposalForm/utils';

import PlannerAccordionSummary from 'components/Planner/PlannerAccordionSummary';
import ScheduleDetails from 'components/Planner/ScheduleDetails';
import EventSpaceDetails from 'components/Planner/EventSpaceDetails';
import AccommodationsDetails from 'components/Planner/AccommodationsDetails';
import VirtualMeetingDetails from 'components/Planner/VirtualMeetingDetails';
import { SpinnerOverlay as UISpinnerOverlay } from 'components/Spinner';

export const ACCORDION_WIDTH = 672;

const Column = styled(UIColumn)<{ noMargin?: boolean }>`
    ${({ noMargin }) => (noMargin ? '' : `margin: 50px auto 0;`)}

    width: 100%;
    max-width: 1092px;
`;

const Row = styled(UIRow)`
    .accordion {
        width: 100%;
        max-width: ${ACCORDION_WIDTH}px;
        box-sizing: border-box;
    }
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .pro-tip {
        margin-bottom: 24px;
        animation: fadeIn 1s ease;
    }
`;

// TODO: That -17% margin needs to be accounted for in a more responsible way
const ProTipColumn = styled(UIColumn)`
    width: 100%;
    margin-right: -17%;
    max-width: 382px;
    max-height: 1px;
    overflow: visible;
`;

const H5Headline = styled(UIH5Headline)`
    line-height: 1.8em;
`;

const Subheadline = styled.p`
    line-height: 1.4em;
    font-size: 18px;
    margin-top: 0;
    font-weight: 400;
`;
const SpinnerOverlay = styled(UISpinnerOverlay)`
    z-index: 100;
`;

const SectionProTips = ({ protips = [] }: { protips?: Bizly.ProTip[] }) =>
    !!protips.length ? (
        <>
            <Spacer col smallish />
            <Spacer col largest />
            <ProTipColumn>
                {protips.map((protip: any) => (
                    <ProTip key={protip.id} message={protip.content} />
                ))}
            </ProTipColumn>
        </>
    ) : null;

const defaultData = {
    schedule: [{}],
    eventSpaces: [{ spaceName: 'Meeting Space 1' }],
    accommodations: [{}],
    virtualMeeting: null,
};

type TPlanner = {
    asInquiryModule?: boolean;
    disabled?: boolean;
    onLoad?: (planner: BizlyAPI.Planner) => void;
    onHasSaved?: (hasSaved: boolean) => void;
    queuedSave?: boolean;
    onQueuedSave?: (updatedPlanner: BizlyAPI.Planner) => void;
    options?: BizlyAPI.Complete.PlaybookOptions;
    playbookId?: number | string;
    readonly?: boolean;
};

export default function Planner({
    asInquiryModule,
    disabled,
    onLoad,
    onHasSaved,
    queuedSave,
    onQueuedSave,
    options,
    playbookId,
    readonly,
}: TPlanner) {
    const { user, toggles } = useUser();
    const { event, template, refreshEvent } = useEvent();
    const [loading, setLoading] = useState<boolean>(true);
    const [hasSaved, setHasSaved] = useState<boolean>(true);
    const [data, setData] = useState<TPlannerForm>({ ...defaultData });
    const [stagedData, setStagedData] = useState<TPlannerForm>({});

    const editable = (event.editable || !!playbookId) && !disabled;

    const getPlannerData = playbookId ? getPlaybookPlannerData : getEventPlannerData;
    const updatePlannerData = playbookId ? updatePlaybookPlannerData : updateEventPlannerData;
    const id = playbookId ?? event.id;

    React.useEffect(() => {
        onHasSaved?.(hasSaved);
    }, [hasSaved, onHasSaved]);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [snackbarKey, setSnackbarKey] = React.useState<string | number | undefined>();
    React.useEffect(
        () => () => {
            if (snackbarKey) closeSnackbar(snackbarKey);
        },
        [stagedData, snackbarKey, closeSnackbar]
    );

    const history = useHistory<{ expanded?: string }>();
    const expanded = history.location.state?.expanded;
    useEffect(() => {
        history.replace(history.location.pathname, { ...history.location.state, expanded: SCHEDULE });
    }, [history]);
    const renderUnsavedPrompt = useUnsavedPrompt(!hasSaved, newLocation => newLocation.state?.expanded === expanded);

    useEffect(() => {
        const loadPlanner = async () => {
            setLoading(true);
            try {
                const { planner = {} } = await getPlannerData(id);
                onLoad?.(planner);
                setData(prevData => ({
                    schedule: planner?.schedule?.length ? planner.schedule : defaultData.schedule,
                    eventSpaces: planner?.eventSpaces?.length
                        ? planner.eventSpaces.map((eventSpace, idx) =>
                              !eventSpace.spaceName
                                  ? { ...eventSpace, spaceName: `Meeting Space ${idx + 1}` }
                                  : eventSpace
                          )
                        : defaultData.eventSpaces,
                    accommodations: planner?.accommodations?.length
                        ? planner.accommodations
                        : defaultData.accommodations,
                    virtualMeeting: prevData.virtualMeeting,
                }));
            } finally {
                setLoading(false);
            }
        };

        loadPlanner();
    }, [event.id, onLoad, getPlannerData, id]);

    // This will keep the virtualMeeting data fresh on every refreshEvent call
    useEffect(() => {
        setData(prevData => ({ ...prevData, virtualMeeting: event.virtualMeeting }));
    }, [event.virtualMeeting]);

    useEffect(() => {
        setHasSaved(true);
        setStagedData({});
        // we add blank items when a section is opened
        if (expanded)
            setData(prevData => ({
                ...prevData,
                schedule: prevData?.schedule?.length ? prevData.schedule : defaultData.schedule,
                eventSpaces: prevData?.eventSpaces?.length ? prevData.eventSpaces : defaultData.eventSpaces,
                accommodations: prevData?.accommodations?.length ? prevData.accommodations : defaultData.accommodations,
            }));
    }, [expanded]);

    function handleAccordionChange(identity: string) {
        history.replace(history.location.pathname, {
            ...history.location.state,
            expanded: identity !== history.location.state?.expanded ? identity : '',
        });
    }

    const protipsFor = useMemo(() => {
        const protips = [...(user.team?.proTips ?? []), ...(template.proTips ?? [])];
        const protipSections: Partial<Record<Bizly.ProTip['section'] & string, Bizly.ProTip[]>> = {
            schedule: [],
            eventSpaces: [],
            accommodations: [],
            virtualMeetings: [],
        };

        protips.forEach((protip: Bizly.ProTip) => {
            if (protip.page === 'planner' && protip.section) {
                protipSections[protip.section]?.push(protip);
            }
        });

        return protipSections;
    }, [user.team, template]);

    const scheduleSummary = useMemo(() => formatScheduleSummary(data.schedule), [data.schedule]);
    const eventSpaceSummary = useMemo(() => formatEventSpacesSummary(data.eventSpaces), [data.eventSpaces]);
    const accommodationsSummary = useMemo(() => formatAccommodationsSummary(data.accommodations), [
        data.accommodations,
    ]);
    const virtualMeetingSummary = useMemo(() => formatVirtualMeetingsSummary(data.virtualMeeting?.serviceProvider), [
        data.virtualMeeting,
    ]);

    function addEntry(targetSection: TPlannerSections) {
        const currentData: TEventSpace[] | TAccommodation[] =
            (hasSaved ? data[targetSection] : stagedData[targetSection]) || [];
        const updatedSection = currentData.slice();
        setHasSaved(false);
        updatedSection.push(
            targetSection === EVENT_SPACES ? { spaceName: `Meeting Space ${currentData.length + 1}` } : {}
        );

        setStagedData((prevData: TPlannerForm) => ({
            ...prevData,
            [targetSection]: updatedSection,
        }));
    }

    function updateEntry(updatedData: TPlannerDataChange, idx: number, targetSection: TPlannerSections) {
        const updatedSection = ((hasSaved ? data[targetSection] : stagedData[targetSection]) || []).slice();
        setHasSaved(false);
        updatedSection[idx] = updatedData.value;

        setStagedData((prevData: TPlannerForm) => ({
            ...prevData,
            [targetSection]: updatedSection,
        }));
    }

    function removeEntry(idx: number, targetSection: TPlannerSections) {
        const updatedSection = ((hasSaved ? data[targetSection] : stagedData[targetSection]) || []).slice();
        setHasSaved(false);
        updatedSection[idx] = { ...updatedSection[idx], delete: true };

        setStagedData((prevData: TPlannerForm) => ({
            ...prevData,
            [targetSection]: updatedSection,
        }));
    }

    function addScheduleDay(place: 'before' | 'after') {
        const updatedSched = ((hasSaved ? data.schedule : stagedData.schedule) || [{}]).slice();

        if (place === 'before') {
            updatedSched.unshift({});
        } else if (place === 'after') {
            updatedSched.push({});
        }

        setHasSaved(false);
        setStagedData((prevData: TPlannerForm) => ({
            ...prevData,
            schedule: updatedSched,
        }));
    }

    function clearAccommodationsData(idx: number) {
        const updatedAccommodations = ((hasSaved ? data.accommodations : stagedData.accommodations) || [{}]).slice();
        setHasSaved(false);
        updatedAccommodations[idx] = { ...updatedAccommodations[idx], notes: '' };

        setStagedData((prevData: TPlannerForm) => ({
            ...prevData,
            accommodations: updatedAccommodations,
        }));
    }

    function setVirtualMeetingsData(vmFormData: TPlannerDataChange & { value: TPlannerForm['virtualMeeting'] }) {
        setHasSaved(false);
        setStagedData((prevData: TPlannerForm) => ({
            ...prevData,
            virtualMeeting: {
                ...(prevData?.virtualMeeting || {}),
                ...vmFormData.value,
            },
        }));
    }

    const onSave = React.useCallback(
        async function() {
            if (loading) return;

            setLoading(true);
            try {
                const { planner: updatedPlanner = {} } = await updatePlannerData(id, stagedData);

                setData((prevData: TPlannerForm) => ({
                    ...prevData,
                    ...updatedPlanner,
                }));
                setStagedData({});
                setHasSaved(true);
                onQueuedSave?.(updatedPlanner);
            } catch (e) {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            } finally {
                setLoading(false);
                refreshEvent();
            }
        },
        [onQueuedSave, refreshEvent, stagedData, loading, enqueueSnackbar, updatePlannerData, id]
    );

    const [prevQueuedSave, setPrevQueuedSave] = React.useState(!!queuedSave);
    React.useEffect(() => () => setPrevQueuedSave(!!queuedSave), [queuedSave]);

    React.useEffect(() => {
        if (queuedSave && !prevQueuedSave) {
            onSave();
        }
    }, [queuedSave, prevQueuedSave, onSave]);

    function addVirtualMeeting() {
        setStagedData(prevData => {
            const { deleted, ...stagedVM } = prevData.virtualMeeting || {};
            setHasSaved(isEmpty(stagedVM));

            return {
                ...prevData,
                virtualMeeting: stagedVM,
            };
        });
    }

    function getErrorVirtualMeeting(data: Partial<Bizly.VirtualMeeting>) {
        if (!data.link) {
            return 'A link is required for a virtual meeting';
        }

        const parsedLink = URI.parse(data.link);
        if (!parsedLink.scheme) {
            return 'Make sure the link includes the protocol (http or https)';
        }

        return false;
    }

    async function onVirtualMeetingSave() {
        const payload = { ...(data.virtualMeeting || {}), ...(stagedData.virtualMeeting || {}) };

        if (payload.deleted && payload.id) {
            return removeVirtualMeeting();
        }

        const validationError = getErrorVirtualMeeting(payload);
        if (validationError) {
            setSnackbarKey(enqueueSnackbar(validationError, { variant: 'error', persist: true }) || undefined);
            return;
        }

        setLoading(true);
        try {
            let updatedVirtualMeeting: Bizly.VirtualMeeting;

            if (payload.id) {
                const { virtualMeeting: vmResponse } = await updateVirtualMeeting(event.id, payload, payload.id);
                updatedVirtualMeeting = vmResponse;
            } else {
                const { virtualMeeting: vmResponse } = await createVirtualMeeting(event.id, payload);
                updatedVirtualMeeting = vmResponse;
            }

            setData((prevData: TPlannerForm) => ({
                ...prevData,
                virtualMeeting: updatedVirtualMeeting,
            }));
            setStagedData({});
            setHasSaved(true);
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        } finally {
            setLoading(false);
            refreshEvent();
        }
    }

    async function removeVirtualMeeting() {
        const vmId = data.virtualMeeting?.id;
        if (!vmId) return;

        setLoading(true);
        try {
            await deleteVirtualMeeting(event.id, vmId);
            setHasSaved(true);

            // refreshEvent must happen before setStaged to not show old data
            await refreshEvent();
            setStagedData({});
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            await refreshEvent();
        } finally {
            setLoading(false);
        }
    }

    function onVirtualMeetingRemove() {
        setStagedData(prev => ({
            ...prev,
            virtualMeeting: { ...prev.virtualMeeting, deleted: true },
        }));
        setHasSaved(!data.virtualMeeting);
    }

    return (
        <Column itemSpacing="default" noMargin={asInquiryModule || !!playbookId}>
            {renderUnsavedPrompt()}

            {!asInquiryModule && !playbookId && (
                <Column>
                    <H5Headline>Planner</H5Headline>
                    <Subheadline>
                        Every great meeting was once a blank canvas – and here’s yours!
                        <br />
                        We’ll build the structure and details of your day, including meeting
                        <br />
                        spaces, guest rooms, A/V and catering.
                    </Subheadline>
                </Column>
            )}

            {!asInquiryModule && !playbookId && (
                <Row>
                    <Accordion
                        identity={SCHEDULE}
                        expanded={expanded === SCHEDULE}
                        summaryContent={
                            <PlannerAccordionSummary
                                headline="Schedule"
                                description="Start with the dates and times of your meeting"
                                expanded={expanded === SCHEDULE}
                                label="Scheduled"
                                summary={scheduleSummary}
                            />
                        }
                        detailContent={
                            <>
                                {loading && <SpinnerOverlay />}
                                <ScheduleDetails
                                    editable={editable}
                                    schedule={(hasSaved ? data.schedule : stagedData.schedule) || []}
                                    addScheduleDay={addScheduleDay}
                                    removeScheduleDay={removeEntry}
                                    setScheduleData={updateEntry}
                                    hasSaved={hasSaved}
                                    onSave={onSave}
                                />
                            </>
                        }
                        handleChange={handleAccordionChange}
                    />
                    {expanded === SCHEDULE && editable && !readonly && <SectionProTips protips={protipsFor.schedule} />}
                </Row>
            )}

            <Row>
                <Accordion
                    identity={EVENT_SPACES}
                    expanded={expanded === EVENT_SPACES}
                    summaryContent={
                        <PlannerAccordionSummary
                            headline="Meeting Spaces"
                            description={readonly ? '' : 'Determine what rooms, A/V, and catering needs you need'}
                            expanded={expanded === EVENT_SPACES}
                            label="Meeting Spaces"
                            summary={eventSpaceSummary}
                        />
                    }
                    detailContent={
                        <>
                            {loading && <SpinnerOverlay />}
                            <EventSpaceDetails
                                editable={editable}
                                eventSpaces={(hasSaved ? data.eventSpaces : stagedData.eventSpaces) || []}
                                addEventSpace={addEntry}
                                removeEventSpace={removeEntry}
                                setEventSpaceData={updateEntry}
                                hasSaved={hasSaved}
                                onSave={onSave}
                                disableDates={!!playbookId}
                                options={options}
                                readonly={readonly}
                            />
                        </>
                    }
                    handleChange={handleAccordionChange}
                />
                {expanded === EVENT_SPACES && editable && !readonly && (
                    <SectionProTips protips={protipsFor.eventSpaces} />
                )}
            </Row>

            {!toggles.compliance.guestroomsDisabled && (
                <Row>
                    <Accordion
                        identity={ACCOMMODATIONS}
                        expanded={expanded === ACCOMMODATIONS}
                        summaryContent={
                            <PlannerAccordionSummary
                                headline="Accommodations"
                                description={
                                    readonly ? '' : 'Identify how many guest rooms are needed for overnight attendees'
                                }
                                expanded={expanded === ACCOMMODATIONS}
                                label="Days / Guestrooms"
                                summary={accommodationsSummary}
                            />
                        }
                        detailContent={
                            <>
                                {loading && <SpinnerOverlay />}
                                <AccommodationsDetails
                                    editable={editable}
                                    accommodations={(hasSaved ? data.accommodations : stagedData.accommodations) || []}
                                    addAccommodation={addEntry}
                                    removeAccommodation={removeEntry}
                                    setAccommodationsData={updateEntry}
                                    clearAccommodationsData={clearAccommodationsData}
                                    hasSaved={hasSaved}
                                    onSave={onSave}
                                    disableDates={!!playbookId}
                                    readonly={readonly}
                                />
                            </>
                        }
                        handleChange={handleAccordionChange}
                    />
                    {expanded === ACCOMMODATIONS && editable && !readonly && (
                        <SectionProTips protips={protipsFor.accommodations} />
                    )}
                </Row>
            )}

            {!asInquiryModule && !playbookId && (
                <Row>
                    <Accordion
                        identity={VIRTUAL}
                        expanded={expanded === VIRTUAL}
                        summaryContent={
                            <PlannerAccordionSummary
                                headline="Virtual Meeting"
                                description="Select the right hosting platform to support virtual attendees"
                                expanded={expanded === VIRTUAL}
                                label="Type"
                                summary={virtualMeetingSummary}
                            />
                        }
                        detailContent={
                            <>
                                {loading && <SpinnerOverlay />}
                                <VirtualMeetingDetails
                                    editable={editable}
                                    virtualSettings={toggles?.compliance?.virtual}
                                    serviceProviders={user.team?.virtualMeetingServiceProviders || []}
                                    virtualMeeting={
                                        !data?.virtualMeeting && !stagedData?.virtualMeeting
                                            ? null
                                            : { ...data?.virtualMeeting, ...stagedData?.virtualMeeting }
                                    }
                                    setVirtualMeetingsData={setVirtualMeetingsData}
                                    hasSaved={hasSaved}
                                    addVirtualMeeting={addVirtualMeeting}
                                    onSave={onVirtualMeetingSave}
                                    onRemove={onVirtualMeetingRemove}
                                />
                            </>
                        }
                        handleChange={handleAccordionChange}
                    />
                    {expanded === VIRTUAL && editable && !readonly && (
                        <SectionProTips protips={protipsFor.virtualMeetings} />
                    )}
                </Row>
            )}
        </Column>
    );
}
