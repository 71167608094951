import React from 'react';

import styled from 'styled-components';

import { EColors } from 'types/themenum';
import { TStyledTheme } from 'theme';

export const statusColors = {
    total: EColors.totalAttendee,
    'not invited': EColors.notInvitedAttendee,
    'not sent': EColors.notInvitedAttendee, // Related to the attendee status being enumerable on the database; this is a workaround
    invited: EColors.invitedAttendee,
    attending: EColors.attendingAttendee,
    'not attending': EColors.notAttendingAttendee,

    // Survey Response Status
    sent: EColors.surveysSentTotal,
    pending: EColors.surveysPending,
    responded: EColors.surveysRespondedTo,
};

type StatusMap = {
    'not sent': 'Not Invited';
    invited: 'Invited';
    attending: 'Attending';
    'not attending': 'Not Attending';
    [key: string]: string;
};

export const statusMap: StatusMap = {
    // This is a workaround to bypass attendee statuses being enumerable values and not easily modified
    'not sent': 'Not Invited',
    invited: 'Invited',
    attending: 'Attending',
    'not attending': 'Not Attending',
};

export const statusOptions = Object.keys(statusMap).map(status => ({
    id: status,
    label: statusMap[status],
}));

type AttendanceTypeMap = {
    'In-person': 'In-person';
    Virtual: 'Virtual';
    [key: string]: string;
};

export const attendanceTypeMap: AttendanceTypeMap = {
    'In-person': 'In-person',
    Virtual: 'Virtual',
};

export const attendanceTypeOptions = Object.keys(attendanceTypeMap).map(attendanceType => ({
    id: attendanceType,
    label: attendanceTypeMap[attendanceType],
}));

const iconStyledFn = ({
    inverted,
    colorOverride,
    theme: { getColor, EColors },
}: {
    inverted?: boolean;
    colorOverride?: EColors;
    theme: TStyledTheme;
}) => ({
    color: getColor(
        colorOverride ? colorOverride : inverted ? EColors.invertedDisplayIcon : EColors.primaryDisplayIcon
    ),
    '> path': {
        fill: getColor(
            colorOverride ? colorOverride : inverted ? EColors.invertedDisplayIcon : EColors.primaryDisplayIcon
        ),
    },
});
const interactibleIconStyledFn = ({
    inverted,
    warning,
    theme,
}: { inverted?: boolean | undefined; warning?: boolean | undefined; theme: TStyledTheme } & {
    inverted?: boolean;
    warning?: boolean;
}) => ({
    ...iconStyledFn({ inverted, theme }),
    ...(!inverted
        ? {
              color: theme.getColor(theme.EColors.primaryAction),
              '> path': {
                  fill: theme.getColor(theme.EColors.primaryAction),
              },
              '&:hover': {
                  color: theme.getColor(theme.EColors.primaryActionHover),
                  '> path': {
                      fill: theme.getColor(theme.EColors.primaryActionHover),
                  },
              },
          }
        : {}),
    ...(warning && {
        color: theme.getColor(theme.EColors.warningAction),
        '> path': {
            fill: theme.getColor(theme.EColors.warningAction),
        },
        '&:hover': {
            color: theme.getColor(theme.EColors.warningActionHover),
            '> path': {
                fill: theme.getColor(theme.EColors.warningActionHover),
            },
        },
    }),
    cursor: 'pointer',
});

export const withIconStyles = <P extends {}, T extends React.ComponentType<P>>(icon: T) => styled(icon)(iconStyledFn);

export const withInteractibleIconStyles = <P extends {}, T extends React.ComponentType<P>>(icon: T) =>
    styled(icon)(interactibleIconStyledFn);

export const contentWidth = '550px';

type CopyFontSizes = {
    large: string;
    default: string;
    regular: string;
    small: string;
    xsmall: string;
    [key: string]: string;
};

export const copyFontSizes: CopyFontSizes = {
    large: '18px',
    default: '15px',
    regular: '15px',
    small: '13px',
    xsmall: '11px',
};

export const OnlySingleLine = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
