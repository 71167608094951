import React from 'react';

import { format } from 'date-fns/esm';
import { parseISO } from 'date-fns';
import omitBy from 'lodash/omitBy';

import { TProposalForm, TGRBooking, TGRBookingProposal, TProposal } from '../types';
import { isEmptyString, isEmptyStringOrNumber } from '../utils';
import { COUNTRY_TO_CURRENCY } from '../CurrencyField';

type TFees = Pick<TProposal, 'resortFee' | 'occupancyTax'> & {
    currencyCode?: Exclude<TProposal['currency'], null | undefined>['code'];
};

export type TGRFormBooking = TGRBooking & TFees;

export type TGuestRoomsFormValue = {
    guestRooms: TGRFormBooking[];
    commissionable: TProposal['commissionable'];
};

const proposedEqualRequestedGR = (grBookings: TGRBooking[]) =>
    grBookings.map(booking => ({
        ...booking,
        proposedRoomRate: booking.proposedRoomRate || 0,
    }));

export const setFeeTaxOnAllGR = (
    grBookings: (TGRBooking & Partial<TFees>)[],
    { resortFee, occupancyTax, currencyCode }: Partial<TFees>
) => {
    return grBookings.map(booking => ({
        ...booking,
        resortFee: resortFee !== undefined ? resortFee : booking?.resortFee || null,
        occupancyTax: occupancyTax !== undefined ? occupancyTax : booking?.occupancyTax || null,
        currencyCode: currencyCode !== undefined ? currencyCode : booking?.currencyCode || undefined,
    }));
};

export const proposalFormToFormData = ({ guestRooms = [], proposal = {}, venue = {} }: Partial<TProposalForm>) => {
    const { commissionable = null, resortFee = null, occupancyTax = null, currency } = proposal;
    return {
        guestRooms: setFeeTaxOnAllGR(proposedEqualRequestedGR(guestRooms), {
            resortFee,
            occupancyTax,
            currencyCode: currency?.code ?? (venue.country?.code && COUNTRY_TO_CURRENCY[venue.country.code]) ?? 'USD',
        }),
        commissionable,
    };
};

export const formDataToProposalForm = ({ guestRooms = [], commissionable = null }: TGuestRoomsFormValue) => {
    const { resortFee, occupancyTax, currencyCode } = guestRooms[0] || {};

    const filterOutNoAnswers = (booking: typeof guestRooms[number]) => booking.proposedRoomCount !== null;
    const filteredBookings = guestRooms.filter(filterOutNoAnswers);

    const formattedBookings = filteredBookings.map(({ resortFee, occupancyTax, ...booking }) => ({
        ...(omitBy<TGRBookingProposal>(booking, (value, key) => key.includes('request')) as TGRBookingProposal),
        ...(booking.proposedRoomCount === 0
            ? {
                  proposedRoomRate: 0,
                  proposedRoomName: null,
                  image: null,
              }
            : {}),
    }));

    return {
        guestRooms: formattedBookings,
        proposal: { resortFee, occupancyTax, commissionable, currencyCode },
    };
};

export const getErrorMessage = ({ guestRooms, commissionable }: TGuestRoomsFormValue, isContinue?: boolean) => {
    const missingInfoError = ({ dayIdx, headingText }: { dayIdx: number; headingText?: string }) => (
        <span>
            {headingText || 'Please make sure to fill out all fields for:'}
            <br />
            {`Day ${dayIdx + 1} - ${format(parseISO(guestRooms[dayIdx].date), 'EEEE, MMMM dd, yyyy')}`}
        </span>
    );

    const isMissingInfo = ({
        proposedRoomCount,
        proposedRoomRate,
        proposedRoomName,
        resortFee,
        occupancyTax,
    }: TGRBooking & TFees) =>
        proposedRoomCount &&
        (isEmptyStringOrNumber(proposedRoomRate) ||
            isEmptyString(proposedRoomName || '') ||
            isEmptyStringOrNumber(resortFee) ||
            isEmptyStringOrNumber(occupancyTax));

    const missingInfoIdx = guestRooms.findIndex(isMissingInfo);

    if (missingInfoIdx !== -1) {
        return missingInfoError({ dayIdx: missingInfoIdx });
    }

    if (isContinue) {
        const hasNoAnswersIdx = guestRooms.findIndex(booking => booking.proposedRoomCount === null);

        if (hasNoAnswersIdx !== -1) {
            return missingInfoError({
                dayIdx: hasNoAnswersIdx,
                headingText: 'Please make sure to answer the request for:',
            });
        }

        const anyBookings = guestRooms.some(booking => booking.proposedRoomCount);

        if (anyBookings && typeof commissionable !== 'boolean') {
            return 'Please let the client know if guest rooms are commissionable';
        }
    }
};
