import { addDays } from 'date-fns';
import fromPairs from 'lodash/fromPairs';

const ENV = process.env.REACT_APP_ENV === 'dev' ? 'dev_bizly_authentication_token' : 'bizly_authentication_token';
const developmentDomain = window.location.hostname === 'localhost' || window.location.hostname.includes('.netlify');
const DOMAIN = developmentDomain ? '' : 'domain=.bizly.com;';
const newCookieString = (key: string, value: string, expiration: string) =>
    `${key}=${value}; path=/; ${DOMAIN} expires=${expiration}; secure=true;`;
const iarDevCookieString = (key: string, value: string, expiration: string) =>
    `${key}=${value}; path=/; domain=.bizly.com; expires=${expiration}; secure=true;`;

export const setCookie = (token: string) => {
    const today = new Date();
    const validExpirationDate = addDays(today, 3).toUTCString();

    document.cookie = newCookieString(ENV, token, validExpirationDate);
    if (process.env.REACT_APP_ENV === 'dev')
        document.cookie = iarDevCookieString('dev_bizly_auth_token', token, validExpirationDate);
};

type CookieObj = {
    [key: string]: string;
} & {
    [tokenCookie in typeof ENV]: string;
};

const parseCookie = () =>
    fromPairs(
        (document.cookie || '').split(';').map(cookieString =>
            cookieString
                .split('=')
                .map(cookieParts => cookieParts.trim())
                .map(decodeURIComponent)
        )
    ) as Partial<CookieObj>;

export const getCookie = () => {
    const cookies = parseCookie();
    const token = cookies[ENV];
    const hasToken = typeof token === 'string';

    return hasToken && token;
};

export const removeCookie = () => {
    const expiredDate = new Date(0).toUTCString();
    document.cookie = newCookieString(ENV, '', expiredDate);
    if (process.env.REACT_APP_ENV === 'dev')
        document.cookie = iarDevCookieString('dev_bizly_auth_token', '', expiredDate);
};
