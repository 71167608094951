import styled from 'styled-components';

import { TGoogleAuth } from './types';

import UIButton from 'components/ui/Button';
import { Copy } from 'ui';

import GoogleAuthentication from './GoogleAuthentication';

const Button = styled(UIButton)`
    height: 40px;
    margin-top: 8px;

    font-size: 16px;
    font-weight: 400;

    width: auto;
    border-radius: 0 8px 8px 0;
`;

const DividerText = styled(Copy)`
    margin: 24px 0;
    font-size: 18px;
    line-height: 15px;
    text-align: center;
`;

const NextButton = styled(UIButton)`
    align-self: flex-end;
`;

type TAuthFields = {
    onSendLink?: (e: React.SyntheticEvent) => void;
    onVerifyEmail?: (e: React.SyntheticEvent) => void;
    onSubmitForm?: (e: React.SyntheticEvent) => void;
    onGoogleAuthSuccess?: (e: TGoogleAuth) => void;
};

export const makeAuthFields = ({ onSendLink, onVerifyEmail, onSubmitForm, onGoogleAuthSuccess }: TAuthFields) => ({
    emailLabel: {
        prompt: 'Email',
        type: null,
    },
    email: {
        type: 'text',
        perRow: '2/3',
        options: {
            placeholder: 'name@company.com',
        },
    },
    ...(onSendLink
        ? {
              sendLinkButton: {
                  type: Button,
                  options: {
                      onClick: onSendLink,
                      children: 'Send Link',
                  },
              },
          }
        : {}),
    ...(onVerifyEmail
        ? {
              verifyEmailButton: {
                  type: Button,
                  options: {
                      onClick: onVerifyEmail,
                      children: 'Verify Email',
                  },
              },
          }
        : {}),
    teamName: {
        prompt: 'Company Name',
        type: 'text',
        options: {
            placeholder: 'Enter Company Name',
        },
    },
    ...(onSubmitForm
        ? {
              nextButton: {
                  type: NextButton,
                  options: {
                      onClick: onSubmitForm,
                      children: 'Next',
                  },
              },
          }
        : {}),
    dividerText: {
        type: DividerText,
        options: {
            children: 'or',
        },
    },
    ...(onGoogleAuthSuccess
        ? {
              signInWithGoogle: {
                  type: GoogleAuthentication,
                  options: {
                      buttonText: 'Sign in with Google',
                      onSuccess: onGoogleAuthSuccess,
                  },
              },
              signUpWithGoogle: {
                  type: GoogleAuthentication,
                  options: {
                      buttonText: 'Sign up with Google',
                      onSuccess: onGoogleAuthSuccess,
                  },
              },
          }
        : {}),
});

export const signInEmailSchema = [
    {
        fields: ['emailLabel'],
        spacing: false,
        itemSpacing: false,
    },
    {
        fields: ['email', 'sendLinkButton'],
        combined: true,
        spacing: false,
        itemSpacing: false,
    },
    {
        fields: ['dividerText'],
        spacing: false,
        itemSpacing: false,
    },
    {
        fields: ['signInWithGoogle'],
        spacing: false,
        itemSpacing: false,
    },
];

export const signUpEmailSchema = [
    {
        fields: ['emailLabel'],
        spacing: false,
        itemSpacing: false,
    },
    {
        fields: ['email', 'verifyEmailButton'],
        combined: true,
        spacing: false,
        itemSpacing: false,
    },
    {
        fields: ['dividerText'],
        spacing: false,
        itemSpacing: false,
    },
    {
        fields: ['signUpWithGoogle'],
        spacing: false,
        itemSpacing: false,
    },
];

export const signUpCompanySchema = [
    {
        fields: ['teamName'],
        spacing: 'default',
    },
    {
        fields: ['nextButton'],
        spacing: false,
    },
];
