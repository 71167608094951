import { get, patch, post } from '.';

type bookingEstimateTypes = Bizly.Estimates;

export const getBookingsForEvent = (eventId: number | string) =>
    get(`/events/${eventId}/bookings`) as Promise<{ bookings: Bizly.Booking[] }>;

export const setBookingContractUrl = (
    bookingId: number | string,
    contractUrls: { contractUrl: string; contractUrl2?: string }
) => patch(`bookings/${bookingId}/contract`, contractUrls);

export const setBookingEstimates = (bookingId: number | string, estimates: bookingEstimateTypes) =>
    patch(`bookings/${bookingId}/estimates`, estimates);

export const setBookingInvoiceUrl = (
    bookingId: number | string,
    invoiceUrls: { invoiceUrl: string; invoiceUrl2?: string }
) => patch(`bookings/${bookingId}/invoice`, invoiceUrls);

export const setBookingFinalSpend = (bookingId: number | string, spendTotals: Bizly.Booking['finalSpend']) =>
    patch(`bookings/${bookingId}/final-spend`, spendTotals);

export const submitPaymentCard = (bookingId: number | string, userId: string) =>
    post(`bookings/${bookingId}/payment-cards`, { userId });

export const sendCancellationEmail = (bookingId: number | string) =>
    post(`bookings/${bookingId}/send-cancellation-notice`);
