import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Headline } from '../components/ui/Headline';

import { Spinner } from './Spinner';

import { useEvent } from '../providers/event';
import { useUser } from '../providers/user';

import { Row, Spacer } from '../ui';
import Dialog from '@material-ui/core/Dialog';

import { getVenueProposalAccepted, setBookingContractUrl, setBookingEstimates } from '../api';
import { uploadFile } from '../cloudinary';

// TODO: Move this entire file into the VenueAccepted folder and rename the folder to be VenueProposalAccepted, and rename this file to index.tsx. Adjust the below imports to match accordingly
import CongratsMessage from './VenueAccepted/CongratsMessage';
import VenueContact from './VenueAccepted/VenueContact';
import VenueDocs from './VenueAccepted/VenueDocs';
import CompleteAndSign from './VenueAccepted/CompleteAndSign';
import UploadSignedDocs from './VenueAccepted/UploadSignedDocs';
import PaymentDetails from './VenueAccepted/PaymentDetails';
import FinalizeDetails from './VenueAccepted/FinalizeDetails';
import Carousel from './Carousel';
import EstimateForm, { TEstimateForm } from 'components/FinalSpendTracking/EstimateForm';

import styled, { css } from 'styled-components/macro';
import useKey from 'hooks/useKey';
import TextButton from 'components/ui/Button/TextButton';

const ENABLE_VIRTUAL_PAYMENT = process.env.REACT_APP_ENV !== 'prod';

const ProposalStepsToComponent = {
    download_documents: VenueDocs,
    complete_sign_documents: CompleteAndSign,
    upload_signed_documents: UploadSignedDocs,
    payment_details: PaymentDetails,
    finalize_details: FinalizeDetails,
} as const;

const Container = styled.div`
    width: 640px;
    padding: 32px;
    flex: 1;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    position: relative;
`;

const RowRight = styled(Row)`
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
`;

const VenueProposalAccepted = ({ onSendMessage, sideMargin }: { onSendMessage: () => void; sideMargin: number }) => {
    const { proposalId } = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const { event } = useEvent();
    const { user } = useUser();

    const proposalSteps = user.team?.proposalSteps ?? [];
    const paymentCardRules = user.team?.paymentCardRules;
    const teamCompliance = user.team?.compliance ?? {};

    const [venueProposal, setVenueProposal] = useState<Bizly.VenueProposal | undefined>();
    const [loading, setLoading] = useState(true);
    const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
    const [estimatesForm, setEstimatesForm] = useState<TEstimateForm>();

    useKey('Escape', () => setUploadModalOpen(false));

    useEffect(() => {
        getVenueProposalAccepted(Number(proposalId))
            .then(proposal => {
                proposal && setVenueProposal(proposal);

                if (proposal?.booking) {
                    setEstimatesForm({
                        ...proposal.booking.estimates,
                        existingDocs: {
                            contract1: proposal.booking.contractUrl,
                            contract2: proposal.booking.contract2Url,
                        },
                    });
                }
            })
            .finally(() => setLoading(false));
    }, [event.id, proposalId]);

    const getExistingDocs = () => ({
        contract1: venueProposal?.booking?.contractUrl,
        contract2: venueProposal?.booking?.contract2Url,
    });

    const updatePaymentCard = (paymentCard: Bizly.PaymentCard) =>
        setVenueProposal(prevVenueProposal =>
            prevVenueProposal && prevVenueProposal.booking
                ? {
                      ...prevVenueProposal,
                      booking: {
                          ...prevVenueProposal.booking,
                          paymentCard,
                      },
                  }
                : prevVenueProposal
        );

    const handleSubmit = async (stagedDocs: { contract1?: File; contract2?: File }) => {
        if (!venueProposal || !venueProposal.booking || !estimatesForm) return;
        const {
            booking: { id: bookingId, contractUrl: existingContract1 },
        } = venueProposal;
        const { contract1, contract2 } = stagedDocs;
        let stagedUpload1, stagedUpload2;

        try {
            setLoading(true);
            // TODO: Setting a contract and setting estimates are two separate endpoints; look into making a unified one for less calls
            if (contract1 && contract1.name) {
                const { url: firstUrl } = await uploadFile(contract1);
                stagedUpload1 = firstUrl;
            }

            if (contract2 && contract2.name) {
                const { url: secondUrl } = await uploadFile(contract2);
                stagedUpload2 = secondUrl;
            }

            if (stagedUpload1 || stagedUpload2) {
                const firstContract = stagedUpload1 || existingContract1;
                const secondContract = stagedUpload2 || null;
                const contractUrls = {
                    contractUrl: firstContract || secondContract,
                    contract2Url: !!firstContract ? secondContract : null,
                };

                const { booking: bookedContract } = await setBookingContractUrl(bookingId, contractUrls);
                setVenueProposal({ ...(venueProposal as Bizly.VenueProposal), booking: bookedContract });
            }

            const { booking: updatedBooking } = await setBookingEstimates(bookingId, estimatesForm);

            setVenueProposal(prevVenueProposal =>
                prevVenueProposal
                    ? {
                          ...prevVenueProposal,
                          booking: {
                              ...prevVenueProposal.booking,
                              ...updatedBooking,
                          },
                      }
                    : prevVenueProposal
            );

            setUploadModalOpen(false);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            enqueueSnackbar(`Error attempting to submit data`, {
                variant: 'error',
            });
        }
    };

    return loading ? (
        <Spinner />
    ) : venueProposal ? (
        <>
            <main
                css={css`
                    margin: 0 12px;
                `}
            >
                <Row
                    css={css`
                        margin: 75px 0;
                        justify-content: space-between;
                        align-items: flex-start;

                        > * {
                            margin-right: 24px;
                        }
                    `}
                >
                    <CongratsMessage cancelled={!!event.cancelledAt} />
                    <VenueContact
                        contact={venueProposal.contact}
                        venue={venueProposal.venue}
                        onSendMessage={event.editable ? onSendMessage : undefined}
                        booking={venueProposal.booking}
                        bookingCancelled={!!event.cancelledAt}
                    />
                </Row>
                <Headline>Here's what to do next:</Headline>
                <Spacer />
                <Spacer />
                <div
                    style={{
                        margin: `0 -${sideMargin}px`,
                        padding: `0 ${sideMargin}px`,
                        /* TODO: pass these padding values down to this component */

                        overflowX: 'hidden',
                    }}
                >
                    <Carousel perRow={3} spacing={24} overflowPreviews={1} buttonsOnTop>
                        {proposalSteps.map(proposalStep => {
                            const Section = ProposalStepsToComponent[proposalStep.type];
                            return Section ? (
                                <Section
                                    paymentCardRules={paymentCardRules}
                                    key={proposalStep.number}
                                    number={proposalStep.number}
                                    venue={venueProposal}
                                    updatePaymentCard={updatePaymentCard}
                                    openUploadModal={() => setUploadModalOpen(true)}
                                    existingDocs={getExistingDocs()}
                                    copy={proposalStep.copy}
                                    altCopy={proposalStep.altCopy}
                                    redText={proposalStep.redText}
                                    readonly={!event.editable}
                                    enableVirtualPayment={ENABLE_VIRTUAL_PAYMENT && !!paymentCardRules?.enabled}
                                />
                            ) : null;
                        })}
                    </Carousel>
                </div>
            </main>
            <Dialog open={uploadModalOpen} maxWidth="md" onClose={() => setUploadModalOpen(false)}>
                <Container>
                    <EstimateForm
                        canUpdate={true}
                        onFormUpdate={setEstimatesForm}
                        formValues={{
                            ...estimatesForm,
                            currency: event.currency?.code,
                            existingDocs: getExistingDocs(),
                        }}
                        teamCompliance={teamCompliance}
                    />
                    <RowRight itemSpacing="smallish">
                        <TextButton onClick={() => setUploadModalOpen(false)} secondary>
                            Cancel
                        </TextButton>

                        <TextButton
                            onClick={() => handleSubmit(estimatesForm?.stagedDocs || {})}
                            disabled={
                                !estimatesForm?.existingDocs?.contract1 &&
                                !(
                                    estimatesForm?.stagedDocs?.contract1?.name ||
                                    estimatesForm?.stagedDocs?.contract2?.name
                                )
                            }
                        >
                            Upload
                        </TextButton>
                    </RowRight>
                </Container>
            </Dialog>
        </>
    ) : (
        <Redirect to={`/event/${event.id}/venue/inquiries`} />
    );
};

export default VenueProposalAccepted;
