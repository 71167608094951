import React from 'react';

import { useParams } from 'react-router';
import { useSnackbar } from 'notistack';

import { currentInquiryActions } from './store';

export default function LoadCurrentInquiry() {
    const { eventId, id } = useParams<{ eventId: string; id: string }>();

    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        try {
            currentInquiryActions.load(parseInt(eventId ?? id));
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    }, [eventId, id, enqueueSnackbar]);

    return null;
}
