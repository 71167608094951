import styled from 'styled-components';

export const HorizontalDivide = styled.div`
    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    margin-bottom: 48px;
`;

export const VerticalDivide = styled.div`
    border-left: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    margin-left: 48px;
`;
