import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colorFns from 'colorFns';

import { ReactComponent as HealthIconSVG } from 'images/icons/health.svg';

import { Row, Copy } from 'ui';

const HealthIcon = styled(HealthIconSVG).attrs(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.primaryDisplayIcon),
}))``;

const HealthSafetyIcon = styled.div`
    width: 30px;
    height: 30px;

    ${HealthIcon} {
        width: 30px;
        height: 30px;
    }
`;

const HealthSafetyRow = styled(Row)`
    display: flex;
    align-items: center;
    width: 700px;
    max-width: 100%;

    ${HealthSafetyIcon} {
        margin-right: 12px;
    }
`;

const StyledLink = styled(Link)`
    color: ${colorFns.primaryAction};
    text-decoration: underline;
`;

export default function HealthAndSafetyInfo() {
    return (
        <HealthSafetyRow>
            <HealthSafetyIcon>
                <HealthIcon />
            </HealthSafetyIcon>
            <Copy regular>
                Health and safety are top of mind for all. Prior to planning your meeting, be sure to check out these
                considerations for how to mitigate risk and make everyone more comfortable. &nbsp;
                <StyledLink to={`/health-safety`}>View Details Here</StyledLink>
            </Copy>
        </HealthSafetyRow>
    );
}
