import React from 'react';

import { useHistory } from 'react-router';
import queryString from 'query-string';

import { WhiteActionButton } from '../ui';

type TViewAllButton = {
    label: string;
    facets?: BizlyAPI.VenueFacets;
    eventId: number;
    isCreateMeeting?: boolean;
};

export default function VenueSearchButton({ label, facets = {}, eventId, isCreateMeeting }: TViewAllButton) {
    const history = useHistory();

    const onClick = () =>
        isCreateMeeting
            ? history.replace({
                  ...history.location,
                  pathname: `/events/${eventId}/edit/venue/search`,
                  search: queryString.stringify(facets),
              })
            : history.push(`/event/${eventId}/venue/search?${queryString.stringify(facets)}`);

    return <WhiteActionButton onClick={onClick}>{label}</WhiteActionButton>;
}
