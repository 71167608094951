import React from 'react';

import BottomBanner from 'components/ui/BottomBanner';
import { RichTextDisplay } from 'components/Form/RichTextEditor';
import { InlineRow } from 'ui';

type THomeScreenBanner = { message: string; teamImage: string };

export default function HomeScreenBanner({ message, teamImage }: THomeScreenBanner) {
    return (
        <BottomBanner>
            <InlineRow itemSpacing="smallish" alignItems="center">
                <img style={{ height: '26px' }} src={teamImage} alt="logo-white" />
                <RichTextDisplay value={message} />
            </InlineRow>
        </BottomBanner>
    );
}
