import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import { LoadEventTemplates } from 'stores/eventTemplates';
import { LoadEventTemplateTags } from 'stores/event-template-tags';
import Templates from 'pages/Templates';
import PlaybooksPage from 'pages/Playbooks';
import Playbook from 'pages/Playbook';
import { PageNotFound } from 'pages/PageNotFound';

import { useUser } from 'providers/user';
import PlaybookWizard from 'pages/PlaybookWizard';
import SideNav from 'components/SideNav';
import NavButtons from 'pages/PlaybookWizard/NavButtons';

const ANIMATION_KEY = '1';

export default function TemplatesRoute() {
    const match = useRouteMatch();
    const { toggles } = useUser();

    return (
        <>
            <LoadEventTemplateTags />
            <Route>
                <Switch>
                    <Route path={`${match.path}/:playbookId/edit`}>
                        {toggles.gate.createPlaybooksFlow ? (
                            <SideNav
                                fillWidth
                                fillHeight
                                fullWidth
                                hideUser
                                routeChildren={<NavButtons />}
                                key={ANIMATION_KEY}
                            >
                                <PlaybookWizard />
                            </SideNav>
                        ) : (
                            <PageNotFound />
                        )}
                    </Route>
                    <Route
                        path={`${match.path}/:playbookId`}
                        render={(props: RouteComponentProps<{ playbookId: string }>) => (
                            <SideNav key={ANIMATION_KEY}>
                                <LoadEventTemplates />
                                <Playbook playbookId={props.match.params.playbookId} />
                            </SideNav>
                        )}
                    />
                    <Route>
                        <LoadEventTemplates />
                        {toggles.gate.upgradedDashboard ? (
                            <SideNav key={ANIMATION_KEY}>
                                <PlaybooksPage />
                            </SideNav>
                        ) : (
                            <Templates />
                        )}
                    </Route>
                </Switch>
            </Route>
        </>
    );
}
