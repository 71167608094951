import React from 'react';

import { useSnackbar } from 'notistack';

import { searchMeetingsActions } from './searchStore';
import { debounce } from 'lodash';

const debouncedSearch = debounce(searchMeetingsActions.search, 200);

export default function SearchMeetings({ query, page }: { query: string; page?: number }) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        try {
            debouncedSearch(query, page);
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    }, [page, query, enqueueSnackbar]);

    return null;
}
