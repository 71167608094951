import React, { useState } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { tzMoment, userTimeZone } from 'utils/moment';

import { ReactComponent as DocumentIconSVG } from 'images/icons/document.svg';
import { ReactComponent as FlagIconSVG } from 'images/icons/flag.svg';
import { ReactComponent as PencilIconSVG } from 'images/icons/pencil2.svg';
import { ReactComponent as CancelIconSVG } from 'images/icons/cancel.svg';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import { isUpdating, meetingsActions, useMeetings } from 'stores/meetings';
import { getBookingsForEvent, sendCancellationEmail } from 'api';

import { withIconStyles, withInteractibleIconStyles } from 'shared';
import useShowModal from 'hooks/useShowModal';

import { Column, Copy, InlineRow, Row, Spacer } from 'ui';
import { H1Headline, H2Headline, H3Headline, IconHeadline } from 'components/ui/Headline';
import { LabeledTextField as UntypedLabeledTextField, TextArea as UntypedTextArea } from 'components/FormFields';
import HelpPopover from './HelpPopover';
import { Spinner, SpinnerOverlay as UISpinnerOverlay } from 'components/Spinner';
import TextButton from 'components/ui/Button/TextButton';
import Button from 'components/ui/Button';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal';
import { SingleFieldForm } from 'components/Form';
import Pill from 'components/ui/Pill';
import DashboardCard from './DashboardCard';
import { Dialog, Tooltip, DialogContent, Popover } from '@material-ui/core';
import { RichTextDisplay } from 'components/Form/RichTextEditor';

import useThemedColor from 'hooks/useThemedColor';
import EventDateDisplay from 'components/EventDateDisplay';

const SpinnerOverlay = styled(UISpinnerOverlay)``;

const Container = styled(DashboardCard)`
    position: relative;

    ${SpinnerOverlay} {
        z-index: 100;
    }
`;

const DocumentIcon = withIconStyles(DocumentIconSVG);
const FlagIcon = withIconStyles(FlagIconSVG);
const PencilIcon = withInteractibleIconStyles(PencilIconSVG);
const CancelIcon = withInteractibleIconStyles(CancelIconSVG);
const InfoIcon = styled(InfoOutlinedIcon)``;
const HelpIcon = styled(withInteractibleIconStyles(HelpOutlinedIcon))`
    height: 17px;
    width: 17px;
    margin-top: 1.5px;
`;

const EventTitle = styled(Row)`
    ${H1Headline} {
        font-weight: 700;
    }
`;

const WideDialogContent = styled(DialogContent)`
    width: 480px;
    padding: 32px !important;
    box-sizing: border-box;
`;

const CancelButton = styled(TextButton)`
    font-size: 18px;
    weight: 400;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.black)};
`;

type TTextField = {
    field: keyof TEventDetailsData;
    value?: string;
    onChange: ({ field, value }: { field: keyof TEventDetailsData; value?: string }) => void;
};

const TextField = ({ field, value, onChange }: TTextField) => (
    <UntypedLabeledTextField
        field={field}
        value={value}
        onChange={onChange}
        label=""
        /* TODO: These fields are required until we type the LabeledTextField component */
        readonly={false}
        disabled={false}
        densePadding={false}
        numberType={null}
        labelSpan={null}
        inputSpan={null}
    />
);

const ScrollableCopy = styled(Copy)`
    max-height: 57px;
    overflow-y: auto;
    padding: 2px 0;
    word-break: break-word;
`;

type TLabeledTextArea = TTextField & {
    label: string;
    Icon: React.ReactNode;
    helpText?: string;
    readonly: boolean;
};

const LabeledTextArea = ({ label, Icon, helpText, readonly, field, value, onChange }: TLabeledTextArea) =>
    (readonly && !!value) || !readonly ? (
        <Column>
            <Row alignItems="center" itemSpacing="small">
                <IconHeadline icon={Icon} headline={label} />
                {helpText && <HelpPopover copy={helpText} />}
            </Row>
            {readonly ? (
                <ScrollableCopy>{value}</ScrollableCopy>
            ) : (
                <UntypedTextArea
                    field={field}
                    value={value}
                    onChange={onChange}
                    rows={2}
                    rowsMax={3}
                    /* TODO: These fields are required until we type the LabeledTextField component */
                    readonly={false}
                    densePadding={false}
                    disabled={false}
                    maxLength={undefined}
                />
            )}
        </Column>
    ) : null;

const MediumDialogContent = styled(DialogContent)`
    width: 320px;
    padding: 32px !important;
`;

function CancellationInfoModal({ meeting, onClose }: { meeting: BizlyAPI.Meeting; onClose?: () => any }) {
    const { warningText, accentedHeadline } = useThemedColor();
    const { user } = useUser();

    const hasSpecialCancellationEmail = function(
        setting?: Bizly.Team['specialCancellationEmail']
    ): setting is Exclude<Bizly.Team['specialCancellationEmail'], []> {
        return !!setting && !Array.isArray(setting);
    };
    const [booking, setBooking] = useState<{ id: string | number } | null>(null);
    const [loading, setLoading] = useState(true);
    const [emailSent, setEmailSent] = useState(false);

    React.useEffect(() => {
        getBookingsForEvent(meeting.id)
            .then(({ bookings = [] }) => {
                const firstBooking = bookings[0] || {};
                setBooking(firstBooking);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }, [meeting.id]);

    const sendEmail = () => {
        if (!booking) return;
        setLoading(true);
        sendCancellationEmail(booking.id)
            .then(() => {
                setEmailSent(true);
            })
            .finally(() => setLoading(false));
    };

    if (!meeting.cancelledAt) return null;

    const time = tzMoment(meeting.cancelledAt.date, meeting.cancelledAt.timezone)
        .tz(userTimeZone)
        .format('MMM DD, YYYY h:mm a z');

    return (
        <Dialog open onBackdropClick={onClose} onClose={onClose}>
            <WideDialogContent>
                <Column itemSpacing="smallish">
                    <H2Headline $color={warningText}>Cancellation Details</H2Headline>
                    <Column itemSpacing="small">
                        <Column itemSpacing="small">
                            <H3Headline $color={accentedHeadline}>Time</H3Headline>
                            <Copy>{time}</Copy>
                        </Column>
                        <Column itemSpacing="small">
                            <H3Headline $color={accentedHeadline}>Notes</H3Headline>
                            <Copy>{meeting.cancellationNotes ? meeting.cancellationNotes : <em>No notes.</em>}</Copy>
                        </Column>
                    </Column>
                    {user.team?.cancellationInformationMessage && (
                        <Column itemSpacing="small">
                            <H3Headline $color={accentedHeadline}>{user.team.name} Cancellation Protocol</H3Headline>
                            <RichTextDisplay value={user.team.cancellationInformationMessage} />
                        </Column>
                    )}
                    {hasSpecialCancellationEmail(user.team?.specialCancellationEmail) &&
                        (loading ? (
                            <Spinner />
                        ) : (
                            booking && (
                                <Button width="auto" onClick={sendEmail} disabled={emailSent}>
                                    {emailSent ? 'Sent!' : user.team?.specialCancellationEmail.invoicingInstructions}
                                </Button>
                            )
                        ))}
                </Column>
            </WideDialogContent>
        </Dialog>
    );
}

const deleteConfirmationMessage = (
    <Copy>
        Event will be marked as cancelled.
        <br />
        <br />
        Existing venue booking inquiries and proposals will be closed and venues and attendees will not be notified.
        <br />
        <br />
        No further venue inquiries will be possible. Be sure to follow up with the venue and/or attendees.
    </Copy>
);

type TEventDetailsSectionProps = {
    meeting: BizlyAPI.Meeting;
    editable?: boolean;
    templateDescription?: string;
    templateObjective?: string;
    superHeading?: string;
};

type TEventDetailsData = Partial<Pick<BizlyAPI.Meeting, 'name' | 'description' | 'purpose'>>;

export default function EventDetailsSection({
    meeting,
    editable,
    templateDescription,
    templateObjective,
    superHeading,
}: TEventDetailsSectionProps) {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [stagedData, setStagedData] = useState<TEventDetailsData>({});

    const { refreshEvent, reloadProvider } = useEvent();
    const { enqueueSnackbar } = useSnackbar();

    const meetingUpdating = useMeetings(isUpdating(meeting.id));

    const handleStartEditing = () => {
        setStagedData({
            name: meeting.name,
            description: meeting.description,
            purpose: meeting.purpose,
        });
        setIsEditing(true);
    };

    const handleChange = ({ field, value }: { field: keyof TEventDetailsData; value?: string }) =>
        setStagedData(prevData => ({
            ...prevData,
            [field]: value,
        }));

    const handleClose = () => {
        setIsEditing(false);
        setStagedData({});
    };

    const handleSave = async () => {
        setIsLoading(true);
        try {
            await meetingsActions.update({ ...meeting, ...stagedData });
            await refreshEvent();
            handleClose();
        } catch {
            enqueueSnackbar('Something went wrong, please try again', { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    const { modalShown, showModal, hideModal } = useShowModal();
    const [cancellationNotes, setCancellationNotes] = useState('');
    const cancelMeeting = async () => {
        await meetingsActions.cancel(meeting.id, cancellationNotes);
        await refreshEvent();
        reloadProvider();
        hideModal();
    };
    const { warningAction } = useThemedColor();

    const { modalShown: helpModalShown, showModal: showHelpModal, hideModal: hideHelpModal } = useShowModal();

    const { user } = useUser();
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const editingButtons = (
        <InlineRow alignItems="center" itemSpacing="small" style={{ marginTop: 8 }}>
            <Tooltip title="Edit Name/Description/Purpose">
                <PencilIcon onClick={editable ? handleStartEditing : () => null} />
            </Tooltip>
            {!meeting.cancelledAt && (
                <Tooltip title="Cancel with Note">
                    <CancelIcon
                        warning
                        onClick={e => {
                            showModal();
                            e.stopPropagation();
                        }}
                    />
                </Tooltip>
            )}
        </InlineRow>
    );

    return (
        <Container itemSpacing="small" frameless>
            {isLoading && <SpinnerOverlay />}
            {!isEditing ? (
                <Column>
                    <InlineRow itemSpacing="smallish">
                        {superHeading && <Copy faded>{superHeading}</Copy>}
                        {meeting.cancelledAt && (
                            <InlineRow itemSpacing="xsmall">
                                <Pill color={warningAction} label="Cancelled" noShadow centerText />
                                <Tooltip title="Cancellation Details">
                                    <HelpIcon onClick={showHelpModal} />
                                </Tooltip>

                                {helpModalShown && <CancellationInfoModal meeting={meeting} onClose={hideHelpModal} />}
                            </InlineRow>
                        )}
                    </InlineRow>
                    <EventTitle itemSpacing="smallish" justifyContent="space-between" alignItems="center">
                        <H1Headline>{meeting.name || <em>Untitled Meeting</em>}</H1Headline>
                        {editable && editingButtons}
                    </EventTitle>
                    <EventDateDisplay />
                </Column>
            ) : (
                <TextField field="name" value={stagedData.name ?? ''} onChange={handleChange} />
            )}
            <LabeledTextArea
                label="Description"
                Icon={<DocumentIcon />}
                helpText={templateDescription}
                readonly={!isEditing}
                field="description"
                value={(isEditing ? stagedData.description : meeting.description) ?? ''}
                onChange={handleChange}
            />

            <LabeledTextArea
                label="Purpose"
                Icon={<FlagIcon />}
                helpText={templateObjective}
                readonly={!isEditing}
                field="purpose"
                value={(isEditing ? stagedData.purpose : meeting.purpose) ?? ''}
                onChange={handleChange}
            />
            {isEditing && (
                <Row justifyContent="flex-end" alignItems="center" itemSpacing="small">
                    <CancelButton onClick={handleClose}>Cancel</CancelButton>
                    <Button onClick={handleSave}>Save</Button>
                </Row>
            )}
            <DeleteConfirmationModal
                isActive={modalShown && !meeting.cancelledAt}
                wide
                onDismiss={hideModal}
                onProceed={cancelMeeting}
                ctaLabel="Proceed"
                prompt={
                    <>
                        <Spacer small />
                        {deleteConfirmationMessage}
                        <Spacer small />
                        {user.team?.cancellationInformationMessage && (
                            <>
                                <Popover
                                    open={!!anchorEl}
                                    anchorEl={anchorEl}
                                    onClose={() => setAnchorEl(null)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <MediumDialogContent>
                                        <RichTextDisplay value={user.team.cancellationInformationMessage} />
                                    </MediumDialogContent>
                                </Popover>
                                <TextButton
                                    onClick={e => {
                                        setAnchorEl(e.currentTarget);
                                    }}
                                    warning
                                    width="100%"
                                >
                                    <InlineRow alignItems="center" justifyContent="center" itemSpacing="small">
                                        <InfoIcon />
                                        {user.team.name} has a cancellation protocol.
                                    </InlineRow>
                                </TextButton>
                            </>
                        )}
                        <Spacer default />

                        <SingleFieldForm
                            field={{
                                type: 'textarea',
                                prompt: 'Cancellation Notes',
                                options: { rows: 2, placeholder: 'Leave notes for internal tracking' },
                            }}
                            value={cancellationNotes}
                            onChange={setCancellationNotes}
                        />
                    </>
                }
                loading={meetingUpdating}
            />
        </Container>
    );
}
