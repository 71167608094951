import React from 'react';

import { usePlaybooks } from 'stores/playbooks';
import { LoadCurrentInquiry, useCurrentInquiry } from 'pages/Playbook/current-inquiry';

import { Spacer } from 'ui';
import Planner from 'pages/Planner';
import { NotesField, TNotesValue } from 'pages/BuildInquiry';

const PlaybookInquiry = ({ playbookId }: { playbookId: number | string }) => {
    const { playbookOptions } = usePlaybooks();

    const currentInquiry = useCurrentInquiry();

    const inquiryFormValue = currentInquiry?.inquiry;

    return (
        <>
            <LoadCurrentInquiry playbookId={playbookId} />

            <Planner options={playbookOptions} playbookId={playbookId} readonly />
            {inquiryFormValue && (
                <>
                    <Spacer small />
                    <NotesField
                        value={inquiryFormValue}
                        onChange={({ value }: { value: TNotesValue }) => {}}
                        readonly
                    />
                </>
            )}
        </>
    );
};

export default PlaybookInquiry;
