import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { parcelType } from '../../util';

import { Column, Copy, Matchbox, Row, SpacedColumn } from '../../ui';

import { ReactComponent as EnvelopeIconSVG } from '../../images/icons/envelope.svg';
import { ReactComponent as NoteIconSVG } from '../../images/icons/note.svg';

const recipientCount = (count = 0) => `${count} Recipient${count !== 1 ? 's' : ''}`;

const EnvelopeIcon = styled(EnvelopeIconSVG).attrs(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.pureWhite),
}))``;
const NoteIcon = styled(NoteIconSVG).attrs(({ theme: { getColor, EColors } }) => ({
    color: getColor(EColors.pureWhite),
}))``;

const Status = styled.span<{ sent?: boolean }>(({ sent, theme: { getColor, EColors } }) => ({
    fontSize: '12px',
    marginTop: '8px',
    color: sent ? 'inherit' : getColor(EColors.warningText),
}));

const ParcelMatchbox = ({
    onCopy,
    onDelete,
    editable,
    parcel,
    readonly,
    editParcelLink,
}: {
    parcel: Bizly.Parcel;
    onCopy: (parcel: Bizly.Parcel) => void;
    onDelete: (parcel: Bizly.Parcel) => void;
    editable: boolean;
    playbookId?: number | string;
    readonly?: boolean;
    editParcelLink: string;
}) => {
    const [menuAnchor, setMenuAnchor] = React.useState(null);
    const handleMenuClose = () => setMenuAnchor(null);

    return (
        <Matchbox>
            <Link to={editParcelLink}>
                <MatchboxTop parcel={parcel}>
                    {parcelType(parcel) === 'invite' && <EnvelopeIcon />}
                    {parcelType(parcel) === 'note' && <NoteIcon />}
                    <span>{parcel.subject}</span>
                </MatchboxTop>
            </Link>
            <MatchboxBottom>
                <BottomSpacedRow>
                    <Column>
                        <span>{recipientCount(parcel.recipients?.length)}</span>
                        {readonly ? (
                            <Copy small>&nbsp;</Copy>
                        ) : (
                            <Status sent={!!parcel.sent}>{parcel.sent ? 'SENT' : 'DRAFT'}</Status>
                        )}
                    </Column>
                    {editable && !readonly && (
                        <>
                            <MoreHorizIcon
                                style={{ cursor: 'pointer' }}
                                onClick={e => setMenuAnchor(menuAnchor ? null : (e.target as any))}
                            />
                            <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={handleMenuClose}>
                                <Link to={editParcelLink}>
                                    <MenuItem onClick={() => handleMenuClose()}>
                                        {parcel.sent ? 'View' : 'Edit'}
                                    </MenuItem>
                                </Link>
                                <MenuItem
                                    onClick={() => {
                                        handleMenuClose();
                                        onCopy(parcel);
                                    }}
                                >
                                    Copy
                                </MenuItem>
                                {!parcel.sent && (
                                    <MenuItem
                                        onClick={() => {
                                            handleMenuClose();
                                            onDelete(parcel);
                                        }}
                                    >
                                        Delete
                                    </MenuItem>
                                )}
                            </Menu>
                        </>
                    )}
                </BottomSpacedRow>
            </MatchboxBottom>
        </Matchbox>
    );
};

const MatchboxTop = styled(SpacedColumn)<{ thumb?: boolean; parcel: any }>`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    ${props =>
        props.thumb
            ? `
        padding: 8px;
    `
            : `
        padding: 12px;
        height: 126px;
    `}
    border-radius: 8px 8px 0 0;
    background-color: ${({ parcel, theme: { getColor, EColors } }) =>
        parcelType(parcel) === 'invite'
            ? parcel.sent
                ? getColor(EColors.parcelInviteSent)
                : getColor(EColors.parcelInvite)
            : parcel.sent
            ? getColor(EColors.parcelNoteSent)
            : getColor(EColors.parcelNote)};

    transition: background-color 0.15s ease-in-out;

    ${Matchbox}:hover & {
        background-color: ${({ parcel, theme: { getColor, EColors } }) =>
            parcelType(parcel) === 'invite'
                ? parcel.sent
                    ? getColor(EColors.parcelInviteSentHover)
                    : getColor(EColors.parcelInviteHover)
                : parcel.sent
                ? getColor(EColors.parcelNoteSentHover)
                : getColor(EColors.parcelNoteHover)};
    }
`;

const MatchboxBottom = styled(SpacedColumn)<{ thumb?: boolean }>`
    ${props =>
        props.thumb
            ? `
        padding: 6px 8px;
    `
            : `
        padding: 12px;
    `}
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    border-radius: 0 0 8px 8px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const BottomSpacedRow = styled(Row)`
    justify-content: space-between;
    align-items: flex-end;
`;

export default ParcelMatchbox;

const MatchboxThumb = styled(Matchbox).attrs({ flat: true })`
    min-width: 71px;
    height: 88px;
`;

const ThumbType = styled.span`
    line-height: 13px;
    font-size: 13px;
    margin-top: 2px;
`;
const ThumbStatus = styled.span<{ sent?: boolean }>`
    font-size: 11px;
    line-height: 11px;
    ${({ sent, theme: { getColor, EColors } }) =>
        sent ? `color: ${getColor(EColors.warningText)};` : 'color: inherit;'}
`;

export const ParcelMatchboxThumb = ({
    parcel,
    eventId,
    className,
}: {
    parcel: Bizly.Parcel;
    eventId?: string;
    className?: string;
}) => {
    const LinkOrFragment = eventId ? Link : React.Fragment;
    //TODO: Does this get used in a place where we need to adapt this link off for Playbooks?
    return (
        <MatchboxThumb className={className}>
            <LinkOrFragment to={`/event/${eventId}/communication/edit/${parcelType(parcel)}/${parcel.id}`}>
                <MatchboxTop thumb parcel={parcel}>
                    {parcelType(parcel) === 'invite' && <EnvelopeIcon />}
                    {parcelType(parcel) === 'note' && <NoteIcon />}
                    <ThumbType>
                        {parcelType(parcel) === 'invite' && 'Invite'}
                        {parcelType(parcel) === 'note' && 'Note'}
                    </ThumbType>
                </MatchboxTop>
                <MatchboxBottom thumb>
                    <BottomSpacedRow>
                        <ThumbStatus sent={!!parcel.sent}>{parcel.sent ? 'SENT' : 'DRAFT'}</ThumbStatus>
                    </BottomSpacedRow>
                </MatchboxBottom>
            </LinkOrFragment>
        </MatchboxThumb>
    );
};
