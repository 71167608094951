import React from 'react';
import styled, { css } from 'styled-components';

import { useSnackbar } from 'notistack';
import usePersistentSnackbar from 'hooks/usePersistentSnackbar';

import { updateTeamSettings } from 'api/settings';
import { useUser } from 'providers/user';
import { nudgesActions } from 'stores/nudges';

import { Column, Row, Spacer } from 'ui';
import { H3Headline } from 'components/ui/Headline';
import Button from 'components/ui/Button';
import Form from 'components/Form';
import { LabeledTextField } from 'components/FormFields';

import colorFns from 'colorFns';

const FormCol = styled(Column)`
    min-width: 600px;
`;

const TRANSPARENT = 'transparent';

const ColorBubbleColor = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    border: 1px solid ${colorFns.softBorder};
`;

const transparentSlash = css`
    transform: rotate(45deg);

    &::after {
        content: '';
        display: block;
        height: 100%;
        width: 1px;
        margin-left: calc(50% - 1px);
        background: ${colorFns.softBorder};
    }
`;

const ColorBubble = styled.div<{ fill: string }>`
    width: 28px;
    height: 28px;
    padding: 1px;
    box-sizing: border-box;

    border-radius: 28px;
    border: 1px solid transparent;

    > ${ColorBubbleColor} {
        border-radius: 26px;

        background: ${({ fill }) => fill};

        ${({ fill }) => fill === TRANSPARENT && transparentSlash}
    }

    flex: 0 0 auto;
`;

const ColorHex = ({
    field,
    value,
    onChange,
    readonly,
}: {
    field: string;
    value?: string;
    onChange: (update: { field: string; value: string }) => void;
    readonly?: boolean;
}) => {
    return (
        <Row alignItems="center" itemSpacing="small">
            <LabeledTextField field={field} value={value} onChange={onChange} maxLength={7} readonly={readonly} />
            <ColorBubble fill={value && value.length === 7 ? value : TRANSPARENT}>
                <ColorBubbleColor />
            </ColorBubble>
        </Row>
    );
};

const CancellationEmailField = ({
    field,
    value,
    onChange,
    readonly,
}: {
    field: string;
    value: Bizly.SpecialCancellationEmail;
    onChange: (update: { field: string; value: Bizly.SpecialCancellationEmail }) => void;
    readonly?: boolean;
}) => {
    return (
        <Form
            fields={{
                mandrillTemplate: { type: 'text', prompt: 'Email Name', readonly: true, perRow: 2 },
                invoicingInstructions: { type: 'text', prompt: 'Button Label', readonly, perRow: 1 },
            }}
            schema={[{ fields: ['mandrillTemplate', 'invoicingInstructions'] }]}
            value={value}
            onChange={({ value }: { value: Bizly.SpecialCancellationEmail }) => {
                onChange({ field, value });
            }}
        />
    );
};

export default function TeamSettings() {
    const { user, isTeamAdmin, setTeam } = useUser();

    const hasSpecialCancellationEmail = function(
        setting?: Bizly.Team['specialCancellationEmail']
    ): setting is Exclude<Bizly.Team['specialCancellationEmail'], []> {
        return !!setting && !Array.isArray(setting);
    };

    const fields = {
        imageUrl: {
            type: 'image_uploader',
            prompt: 'Team Logo',
            fixedWidth: 200,
        },
        name: {
            type: 'text',
            prompt: 'Team Name',
            fixedWidth: 320,
        },
        color: {
            type: ColorHex,
            prompt: 'Team Color',
            fixedWidth: 200,
        },
        bulletinMessage: {
            type: 'rich_text',
            prompt: 'Homepage Banner Message',
            nullSwitch: true,
        },
        meetingInternalReferenceFieldLabel: {
            type: 'text',
            prompt: 'Internal Reference Label',
        },
        authMeetingCreateRedirect: {
            type: 'rich_text',
            prompt: 'Meeting Creation Redirect Message',
            nullSwitch: true,
        },
        cancellationInformationMessage: {
            type: 'rich_text',
            prompt: 'Cancellation Information Message',
            nullSwitch: true,
        },
        ...(hasSpecialCancellationEmail(user.team?.specialCancellationEmail) && {
            specialCancellationEmail: {
                type: CancellationEmailField,
            },
        }),
    };

    const schema = [
        { fields: ['imageUrl'] },
        { fields: ['name'] },
        { fields: ['color'] },
        { fields: ['bulletinMessage'] },
        { fields: ['meetingInternalReferenceFieldLabel'] },
        { fields: ['authMeetingCreateRedirect'] },
        { fields: ['cancellationInformationMessage'] },
        ...(hasSpecialCancellationEmail(user.team?.specialCancellationEmail)
            ? [{ fields: ['specialCancellationEmail'], header: 'Cancellation Emails' }]
            : []),
    ];

    const [value, setValue] = React.useState<Partial<Bizly.Team>>(user.team ?? {});

    const [saving, setSaving] = React.useState(false);

    const getValidationError = () => {
        if (!value.name?.trim()) {
            return 'Team Name is required.';
        }

        if (!value.imageUrl?.trim()) {
            return 'Team Logo is required.';
        }

        if (!/#[A-Fa-f0-9]{6}/.test(value.color ?? '')) {
            return 'Team Color must be a hex value like #ffffff';
        }
    };

    const reset = () => {
        setValue(user.team ?? {});
    };

    const enqueuePersistentSnackbar = usePersistentSnackbar([value]);

    const { enqueueSnackbar } = useSnackbar();
    const update = async () => {
        if (!user.team?.id) return;
        const error = getValidationError();

        if (error) {
            return enqueuePersistentSnackbar(error, { variant: 'error' });
        }

        try {
            setSaving(true);
            const { team } = await updateTeamSettings(user.team.id, value);
            if (team.name) {
                nudgesActions.mergeNudges({ showTeamSettings: false });
            }
            setTeam(team);
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        } finally {
            setSaving(false);
        }
    };

    if (!user.team) return null;

    return (
        <>
            <FormCol>
                {!isTeamAdmin && (
                    <>
                        <H3Headline>Contact your team admin to change these settings.</H3Headline>
                        <Spacer medium />
                    </>
                )}
                <Form
                    fields={fields}
                    schema={schema}
                    value={value}
                    onChange={({ value }: { value: Bizly.Team }) => {
                        setValue(value);
                    }}
                    readonly={!isTeamAdmin}
                />
            </FormCol>
            {isTeamAdmin && (
                <Row itemSpacing="smallish" justifyContent="flex-end">
                    <Button onClick={reset} disabled={saving} secondary>
                        Reset
                    </Button>
                    <Button onClick={update} disabled={saving}>
                        Save
                    </Button>
                </Row>
            )}
        </>
    );
}
