import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import sortBy from 'lodash/sortBy';

import { createEvent } from 'api/events';
import { useUser } from 'providers/user';
import { BLANK_TEMPLATE_ID, isBlankTemplateId } from 'api/eventTemplates';
import { LoadEventTemplates, LoadPlaybook, selectPlaybook, useEventTemplates } from 'stores/eventTemplates';

import { Spacer, Copy, Row, Column, CopyFaded } from 'ui';
import { H2Headline } from 'components/ui/Headline';
import TextButton from 'components/ui/Button/TextButton';
import { SpinnerOverlay } from 'components/Spinner';
import { RichTextDisplay } from 'components/Form/RichTextEditor';

import { Dialog, DialogContent, Popover } from '@material-ui/core';

import Form from 'components/Form';
import { EventForm, EventCreateFields, schema, schemaWithTemplate, toBizlyEvent, validateForm } from './formSchema';

const WideDialogContent = styled(DialogContent)`
    width: 640px;
    padding: 32px !important;
`;

const MediumDialogContent = styled(DialogContent)`
    width: 320px;
    padding: 32px !important;
`;

const SpacedCopy = styled(Copy)`
    line-height: 1.6;
`;

export default function EventCreateModal({
    template,
    defaultFormValues,
    onClose,
}: {
    template?: Bizly.EventTemplate;
    defaultFormValues?: Partial<{
        name: string;
        budget: string;
        costCenter: string;
        cventId: string;
        type: string;
        templateId: string | number;
    }>;
    onClose: () => void;
}) {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const templateLocation =
        template?.location?.name && template?.location?.googlePlaceId
            ? {
                  location: template?.location?.name,
                  googlePlaceId: template?.location?.googlePlaceId,
              }
            : null;

    const { templates, loading: loadingEventTemplates } = useEventTemplates();
    const templateOptions = React.useMemo(
        () =>
            sortBy(templates, ['id']).map(template => ({
                ...template,
                name: isBlankTemplateId(template.id) ? (
                    <CopyFaded>
                        <em>No Playbook</em>
                    </CopyFaded>
                ) : (
                    template.name
                ),
            })),
        [templates]
    );

    const { user } = useUser();

    const [value, setValue] = React.useState<EventForm>({
        ...(templateLocation && { city: templateLocation }),
        templateId: template?.id ?? BLANK_TEMPLATE_ID,
        ...defaultFormValues,
    });
    const updateForm = ({ value }: { value: EventForm }) => setValue(value);

    const playbook = useEventTemplates(selectPlaybook(value.templateId));
    React.useEffect(() => {
        function isNotEmpty<T>(value: T | null | undefined): value is Exclude<T, null | undefined> {
            return value !== undefined && value !== null;
        }
        if (playbook && value.templateId !== BLANK_TEMPLATE_ID)
            setValue(curValue => {
                return {
                    ...curValue,
                    ...(isNotEmpty(playbook.location) || isNotEmpty(playbook.googlePlaceId)
                        ? {
                              city: {
                                  location: playbook.location ?? undefined,
                                  googlePlaceId: playbook.googlePlaceId ?? undefined,
                              },
                          }
                        : {}),
                    ...(isNotEmpty(playbook.id) ? { templateId: playbook.id } : {}),
                    ...(isNotEmpty(playbook.internalId) ? { cventId: playbook.internalId } : {}),
                    ...(isNotEmpty(playbook.meetingType) ? { type: playbook.meetingType } : {}),
                    ...(isNotEmpty(playbook.costCenter) ? { costCenter: playbook.costCenter } : {}),
                    ...(isNotEmpty(playbook.budget) ? { budget: playbook.budget } : {}),
                };
            });
    }, [playbook, value.templateId]);

    const playbookLocation = playbook
        ? {
              location: playbook.location ?? undefined,
              googlePlaceId: playbook.googlePlaceId ?? undefined,
          }
        : undefined;

    const playbookDescription = playbook && playbook.description ? playbook.description : undefined;

    const formFields = useMemo(
        () =>
            EventCreateFields(
                user.team?.meetingInternalReferenceFieldLabel,
                user.team?.authMeetingCreateFields,
                templateOptions,
                playbookLocation ?? templateLocation,
                playbookDescription
            ),
        [user, templateOptions, templateLocation, playbookLocation, playbookDescription]
    );
    const formSchema = loadingEventTemplates || templates?.length ? schemaWithTemplate : schema;

    const [submitting, setSubmitting] = React.useState(false);
    const onSubmit = async () => {
        if (!validateForm(value)) {
            return enqueueSnackbar('All fields are required.', { variant: 'error' });
        }

        setSubmitting(true);
        try {
            const { event } = await createEvent(toBizlyEvent(value));
            history.push(`/event/${event.id}`);
            onClose();
        } catch {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            setSubmitting(false);
        }
    };

    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

    return (
        <Dialog open maxWidth="xl" onBackdropClick={submitting ? undefined : onClose}>
            <LoadEventTemplates />
            {value.templateId && <LoadPlaybook id={value.templateId} />}
            <WideDialogContent>
                <Column itemSpacing="small">
                    <H2Headline>Create a new {template?.name ? template?.name + ' ' : ''}meeting</H2Headline>
                    <SpacedCopy>
                        Almost there!
                        <br />
                        To create a new meeting, we need some basic information to get you started.
                        <br />
                        You'll be able to view and edit this later if something changes.
                    </SpacedCopy>
                    {user.team?.authMeetingCreateFields && user.team.authMeetingCreateRedirect && (
                        <Row justifyContent="center">
                            <Popover
                                open={!!anchorEl}
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <MediumDialogContent>
                                    <RichTextDisplay value={user.team.authMeetingCreateRedirect} />
                                </MediumDialogContent>
                            </Popover>
                            <TextButton
                                onClick={e => {
                                    setAnchorEl(e.currentTarget);
                                }}
                                warning
                            >
                                {user.team.name} has a meeting creation protocol.
                            </TextButton>
                        </Row>
                    )}
                </Column>

                <Spacer large />

                <Form
                    key={playbook?.id ?? 0}
                    fields={formFields}
                    schema={formSchema}
                    value={value}
                    onChange={updateForm}
                />
                <Spacer largest />

                <Row justifyContent="flex-end" itemSpacing="smallish">
                    <TextButton onClick={onClose} secondary>
                        Cancel
                    </TextButton>
                    <TextButton onClick={onSubmit}>Create</TextButton>
                </Row>

                {submitting && <SpinnerOverlay />}
            </WideDialogContent>
        </Dialog>
    );
}
