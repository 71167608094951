import React, { useState } from 'react';
import { useHistory, Switch, Route } from 'react-router-dom';
import styled from 'styled-components/macro';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import moment from 'moment';

import { isBlankTemplateId } from 'api/eventTemplates';
import { LoadEventTemplates, useEventTemplates } from 'stores/eventTemplates';
import { LoadPlaybook, LoadPlaybookOptions, usePlaybooks, selectPlaybook } from 'stores/playbooks';

import useShowModal from 'hooks/useShowModal';
import { useUser } from 'providers/user';
import { eventTemplatesActions } from 'stores/eventTemplates';

import { Row, Spacer, Column } from 'ui';
import { H5Headline } from 'components/ui/Headline';
import { SpinnerOverlay } from 'components/Spinner';
import Button from 'components/ui/Button';
import EventCreateModal, { EventCreateProtocolModal } from 'components/EventCreateModal';
import ConfirmationModal from 'components/ConfirmationModal';
import PagedPlaybooks from 'components/Templates/PagedPlaybooks';
import { Footer, IconButton, PlusButtonIcon } from './components/ui';

import { ESECTION } from 'components/Templates/utils';
import Dialog from '@material-ui/core/Dialog';
import EditParcel from 'pages/EditParcel';

import PlaybookDetails from './components/PlaybookDetails';

const Fixed = styled.div`
    position: fixed;
`;

const Hidden = styled.div`
    visibility: hidden;
`;

const PlaybookRecommendationsCol = ({
    globalTemplates,
    isLoading,
}: {
    globalTemplates: Bizly.EventTemplate[];
    isLoading?: boolean;
}) => (
    <Column>
        <LoadEventTemplates />
        <Hidden>
            <PagedPlaybooks
                title="Playbooks"
                subheading="Choose from our well crafted pre-built meetings."
                playbooks={globalTemplates}
                isSummary
                isLoading={isLoading}
            />
        </Hidden>
        <Fixed>
            <PagedPlaybooks
                title="Playbooks"
                subheading="Choose from our well crafted pre-built meetings."
                playbooks={globalTemplates}
                isSummary
                isLoading={isLoading}
            />
        </Fixed>
    </Column>
);

const FooterButtons = ({
    canShare,
    canEdit,
    canDelete,
    onCreateMeeting,
    sharePlaybook,
    goToEdit,
    deletePlaybook,
}: {
    canShare?: boolean;
    canEdit?: boolean;
    canDelete?: boolean;
    onCreateMeeting: () => void;
    sharePlaybook: () => void;
    goToEdit: () => void;
    deletePlaybook: () => void;
}) => {
    const [shareIntent, setShareIntent] = useState(false);
    const [deleteIntent, setDeleteIntent] = useState(false);

    const onDelete = () => {
        setDeleteIntent(false);
        deletePlaybook();
    };

    const onShare = () => {
        setShareIntent(false);
        sharePlaybook();
    };

    return (
        <Footer>
            <IconButton width="auto" onClick={onCreateMeeting}>
                <Row alignItems="center">
                    <PlusButtonIcon />
                    Create Meeting
                </Row>
            </IconButton>

            {canShare && (
                <>
                    <Button onClick={() => setShareIntent(true)} secondary width="auto">
                        Publish to Team
                    </Button>
                    <ConfirmationModal
                        headline="Publish Playbook with Team"
                        onDismiss={() => setShareIntent(false)}
                        onProceed={onShare}
                        prompt="By publishing a playbook, all members of your team will have access to it when creating a future meeting."
                        isActive={shareIntent}
                    />
                </>
            )}
            {canEdit && (
                <Button onClick={goToEdit} secondary>
                    Edit
                </Button>
            )}
            {canDelete && (
                <>
                    <Button onClick={() => setDeleteIntent(true)} warning>
                        Delete
                    </Button>
                    <ConfirmationModal
                        warning
                        headline="Delete Playbook"
                        onDismiss={() => setDeleteIntent(false)}
                        onProceed={onDelete}
                        prompt="By deleting this playbook you will be removing it from your Playbooks. If it is shared with a team, they will no longer have access to it as well."
                        isActive={deleteIntent}
                    />
                </>
            )}
        </Footer>
    );
};

const sortByMostRecent = (templates: Bizly.EventTemplate[]) =>
    templates.slice().sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1));

const { PERSONAL, SHARED } = ESECTION;

export default function Playbook({ playbookId }: { playbookId: string | number }) {
    const history = useHistory();
    const { templates, loading: loadingTemplates } = useEventTemplates();
    const { isTeamAdmin, user } = useUser();
    const { modalShown: createModalShown, showModal: showCreateModal, hideModal: hideCreateModal } = useShowModal();

    const [globalTemplates, setGlobalTemplates] = useState<Bizly.EventTemplate[]>([]);

    const arrangeTemplates = (allTemplates: Bizly.EventTemplate[]) => {
        const { global = [] } = groupBy(sortByMostRecent(allTemplates), 'category');
        const globalTempsWithoutBlank = global.filter(({ id }) => !isBlankTemplateId(id));

        return setGlobalTemplates(globalTempsWithoutBlank.slice(0, 5));
    };

    React.useEffect(() => arrangeTemplates(templates), [templates]);

    const template = find(templates, ['id', Number(playbookId)]);
    const playbookFull = usePlaybooks(selectPlaybook(playbookId));
    const playbook = template
        ? {
              id: template.id,
              name: template.name,
              tags: template.tags,
              purpose: playbookFull?.purpose ?? template.objectiveDescription,
              image: template.briefImageLarge ?? template.briefImageSmall,
              description: template.longDescription ?? template.shortDescription,
          }
        : {};

    const {
        modalShown: protocolModalShown,
        showModal: showProtocolModal,
        hideModal: hideProtocolModal,
    } = useShowModal();

    const onCreateMeeting = async () => {
        if (user.team?.authMeetingCreateRedirect) return showProtocolModal();
        if (!playbook.id) return;
        if (user.featureFlags?.createMeetingsFlow) {
            return history.push(`/events/new`, { playbookId: playbook.id });
        }

        showCreateModal();
    };

    const isUsersPlaybook = template?.category === PERSONAL && template?.userId === user.id;
    const canShare = isUsersPlaybook && isTeamAdmin;
    const canDelete = isUsersPlaybook || (isTeamAdmin && template?.category === SHARED);

    const sharePlaybook = () => {
        return playbook.id && eventTemplatesActions.share(playbook.id);
    };

    const deletePlaybook = () => {
        return (
            playbook.id &&
            eventTemplatesActions.delete(playbook.id).then(() => {
                history.push('/playbooks');
            })
        );
    };

    const goToEdit = () => {
        history.push(`/playbooks/${playbook.id}/edit`);
    };

    if (!template) return <SpinnerOverlay />;

    return (
        <>
            <LoadPlaybook id={playbookId} />
            {user.featureFlags?.createPlaybooksFlow && (
                <>
                    <LoadPlaybookOptions />
                </>
            )}
            <Spacer smallish />
            <H5Headline>{playbook.name}</H5Headline>
            <Spacer larger />
            <Row itemSpacing="largest" alignItems="stretch">
                <PlaybookDetails playbookId={playbookId} />
                <PlaybookRecommendationsCol globalTemplates={globalTemplates} isLoading={loadingTemplates} />
            </Row>
            <FooterButtons
                canShare={canShare}
                canEdit={user.featureFlags?.createPlaybooksFlow && canDelete}
                canDelete={canDelete}
                onCreateMeeting={onCreateMeeting}
                sharePlaybook={sharePlaybook}
                goToEdit={goToEdit}
                deletePlaybook={deletePlaybook}
            />

            {createModalShown && <EventCreateModal {...(template?.id ? { template } : {})} onClose={hideCreateModal} />}
            {protocolModalShown && <EventCreateProtocolModal onBackdropClick={hideProtocolModal} />}

            <Switch>
                <Route exact path="/playbooks/:id/communication/:type/:parcelId">
                    <Dialog open fullScreen>
                        <EditParcel context={{ id: +playbookId, editable: false, asPlaybookParcel: true }} />
                    </Dialog>
                </Route>
            </Switch>
        </>
    );
}
