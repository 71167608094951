import React from 'react';

import { useSnackbar } from 'notistack';

import { meetingsPagesSortedActions } from './pagesSortedStore';
import { FilterOptions } from 'stores/meetings/filterStore';

export default function LoadMeetingsPage({
    page,
    scheduled,
    sortBy,
    mixed,
}: {
    page?: number;
    scheduled?: boolean;
    sortBy: FilterOptions;
    mixed?: boolean;
}) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        try {
            mixed
                ? meetingsPagesSortedActions.loadMixed(page, 'updated_at')
                : meetingsPagesSortedActions.load(page, scheduled, sortBy);
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    }, [page, scheduled, sortBy, enqueueSnackbar, mixed]);

    return null;
}
