import React, { useState, useEffect, useMemo } from 'react';

import debounce from 'lodash/debounce';

import styled from 'styled-components/macro';

import { ReactComponent as ForwardArrowIcon } from '../images/icons/forward_arrow.svg';
import { Row } from '../ui';
import SmallCircleButton from './ui/SmallCircleButton';

import Tabs from '@material-ui/core/Tabs';

const CenteredTabs = styled(Tabs)<{ buttonsOnTop?: boolean; solid?: boolean }>`
    flex: 1;
    ${props => (props.buttonsOnTop ? `` : `align-items: center;`)}
    ${({ solid, theme: { getColor, EColors } }) =>
        solid ? `background: ${getColor(EColors.pureWhite)};` : ``}
    .MuiTabs-indicator {
        display: none;
    }

    /* block scrolling */
    .MuiTabs-scroller {
        pointer-events: none;
        white-space: unset;
    }

    /* still allow clicking */
    .MuiTabs-flexContainer {
        pointer-events: all;
    }
`;

const NonInteractableRow = styled(Row)`
    position: absolute;
    z-index: 0;
    opacity: 0.2;
    pointer-events: none;
`;

const NextButtonBase = styled(SmallCircleButton)<{ left?: boolean; buttonsOnTop?: boolean }>`
    position: absolute;
    z-index: 2;

    ${props =>
        props.left
            ? `
      left: 0; 
      right: unset;
      transform: rotateY(180deg) translateX(50%);
      ${props.buttonsOnTop ? `transform: rotateY(180deg) translateY(calc(-100% - 20px))` : ``}
      `
            : `
      right: 0; 
      transform: translateX(50%);
      ${props.buttonsOnTop ? `transform: translateY(calc(-100% - 20px))` : ``}
  `}
`;

type TNextButton = {
    onClick: () => void;
    direction: 'left' | 'right';
    visible: boolean;
    buttonsOnTop?: boolean;
    amount?: number;
};

const NextButton = ({
    direction,
    visible,
    increment,
    buttonsOnTop,
    amount = 1,
}: TNextButton & { increment: (increment: number) => void }) =>
    visible ? (
        <NextButtonBase
            onClick={() => {
                increment(direction === 'left' ? 0 - amount : amount);
            }}
            left={direction === 'left'}
            buttonsOnTop={buttonsOnTop}
        >
            <ForwardArrowIcon />
        </NextButtonBase>
    ) : null;

const Blank = styled.div`
    width: 100%;
`;

const getItemWidth = (perRow: number, spacing?: number) =>
    `calc(100% / ${perRow} - ${spacing || 0}px * ${perRow - 1} / ${perRow})`;

type TCarouselProps = {
    children: React.ReactNode;
    perRow: number;
    spacing?: number;
    overflowPreviews?: number;
    buttonsOnTop?: boolean;
    scrollAmount?: number;
    className?: string;
};

const Carousel = ({
    children,
    perRow,
    spacing = 0,
    overflowPreviews = 0,
    buttonsOnTop,
    scrollAmount = 1,
    className,
}: TCarouselProps) => {
    // TODO: since many setStates are batched, useReducer would help
    const lastItemInRow = Math.min(React.Children.count(children), perRow);
    const [selected, setSelected] = useState(lastItemInRow - 1);
    const [offset, setOffset] = useState(overflowPreviews * 2);
    const [bounds, setBounds] = useState([0, lastItemInRow - 1] as [number, number]);

    const incrementBounds = (increment: number) => {
        const newBounds: [number, number] = [
            Math.max(0, Math.min(bounds[0] + increment, React.Children.count(children) - perRow)),
            Math.max(perRow - 1, Math.min(bounds[1] + increment, React.Children.count(children) - 1)),
        ];

        setBounds(newBounds);
        setSelected(increment > 0 ? newBounds[1] : newBounds[0]);
        setOffset(increment > 0 ? overflowPreviews * 2 : 0);
    };

    /* any scrollable element will scroll around upon resize
  this forces a re-alignment */
    useEffect(() => {
        const listener = debounce(() => {
            setTimeout(() => {
                setSelected(bounds[0]);
                setOffset(0);
            }, 0);
            setTimeout(() => {
                setSelected(bounds[1]);
                setOffset(overflowPreviews * 2);
            }, 100);
        }, 100);
        window.addEventListener('resize', listener);

        return () => window.removeEventListener('resize', listener);
    }, [bounds, overflowPreviews, setSelected, setOffset]);

    const ScrollButtonComponent = (props: TNextButton) => (
        <NextButton {...props} buttonsOnTop={buttonsOnTop} increment={incrementBounds} amount={scrollAmount} />
    );

    const wrapperStyle = useMemo(
        () => ({
            width: getItemWidth(perRow, spacing),
            minWidth: getItemWidth(perRow, spacing),
            maxWidth: getItemWidth(perRow, spacing),
            marginRight: spacing,
        }),
        [perRow, spacing]
    );

    const shadowWrapperStyle = useMemo(() => {
        const overflowPerRow = perRow + overflowPreviews * 2;

        return {
            width: getItemWidth(overflowPerRow, spacing),
            minWidth: getItemWidth(overflowPerRow, spacing),
            maxWidth: getItemWidth(overflowPerRow, spacing),
            marginRight: spacing,
        };
    }, [perRow, spacing, overflowPreviews]);

    return (
        <Row
            className={className}
            style={{ position: 'relative', marginTop: buttonsOnTop ? 48 : 0, background: 'transparent', zIndex: 1 }}
        >
            <CenteredTabs
                value={selected}
                variant="scrollable"
                scrollButtons="on"
                ScrollButtonComponent={ScrollButtonComponent}
                buttonsOnTop={buttonsOnTop}
            >
                {React.Children.map(children, c => {
                    const childKey = (c as React.ReactElement).key;
                    return (
                        <div style={wrapperStyle} key={childKey !== null ? childKey : undefined}>
                            {c}
                        </div>
                    );
                })}
            </CenteredTabs>
            {overflowPreviews > 0 && (
                <NonInteractableRow
                    style={{
                        width: `calc((100% + ${spacing}px) * ${perRow +
                            overflowPreviews * 2} / ${perRow} - ${spacing}px)`,
                        transform: `translateX(calc(-100% * ${overflowPreviews} / ${perRow +
                            overflowPreviews * 2} - ${spacing}px * ${overflowPreviews} / ${perRow +
                            overflowPreviews * 2}))`,
                    }}
                >
                    <CenteredTabs
                        value={selected + offset}
                        variant="scrollable"
                        scrollButtons="on"
                        ScrollButtonComponent={ScrollButtonComponent}
                        buttonsOnTop={buttonsOnTop}
                    >
                        {[
                            ...new Array(overflowPreviews).fill('').map((v, idx) => (
                                <div style={shadowWrapperStyle}>
                                    <Blank key={idx} />
                                </div>
                            )),
                            ...React.Children.map(children, c => {
                                const childKey = (c as React.ReactElement).key;
                                return (
                                    <div style={shadowWrapperStyle} key={childKey !== null ? childKey : undefined}>
                                        {c}
                                    </div>
                                );
                            }),
                            ...new Array(overflowPreviews).fill('').map((v, idx) => (
                                <div style={shadowWrapperStyle}>
                                    <Blank key={idx} />
                                </div>
                            )),
                        ]}
                    </CenteredTabs>
                </NonInteractableRow>
            )}
        </Row>
    );
};

export default Carousel;
