import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Location } from 'history';
import { useSnackbar } from 'notistack';
import { Redirect, useHistory, useParams } from 'react-router';
import useShowModal from 'hooks/useShowModal';

import { selectMeeting, useMeetings, LoadMeeting } from 'stores/meetings';
import { ESteps } from 'pages/CreateMeeting/store';
import { attendeesActions } from 'stores/attendees';
import { useUser } from 'providers/user';

import { Column, Copy, ExternalLink, Row, Spacer } from 'ui';
import { SpinnerOverlay } from 'components/Spinner';
import SmallCircleButton from 'components/ui/SmallCircleButton';
import { Dialog } from '@material-ui/core';
import FinalSpendForm, { TFinalSpendForm } from 'components/FinalSpendTracking/FinalSpendForm';
import MeetingDetail from 'components/MeetingDetails';

import Menu from './components/Menu';
import VirtualMeetingPopover from './components/VirtualMeetingPopover';
import QuickInviteAttendees from './components/QuickInviteAttendees';
import DocumentsCard from './components/DocumentsCard';
import VenuesCard from './components/VenuesCard';
import CommunicationsCard from './components/CommunicationsCard';
import RespondableHeadline from './components/RespondableHeadline';

import { ReactComponent as PlusIconSvg } from 'images/icons/plus.svg';
import { ReactComponent as CalculatorSvg } from 'images/icons/calculator.svg';
import { ReactComponent as GuestsIconSvg } from 'images/icons/guests.svg';
import { ReactComponent as EditSvg } from 'images/icons/edit.svg';
import { withInteractibleIconStyles } from 'shared';
import colorFns from 'colorFns';
import { getBookingsForEvent } from 'api';

const DetailsCol = styled(Column)`
    max-width: 460px;
`;

const CardsCol = styled(Column)`
    max-width: 400px;
`;

const Date = styled(Copy)`
    color: ${colorFns.pureWhite};
    background: ${colorFns.brand};
    border-radius: 0 0 8px 8px;

    padding: 12px 16px;
`;

const LocationLink = styled(ExternalLink)``;

const DetailsRow = styled(Row)`
    & > ${LocationLink} {
        margin-left: 60px;
    }
`;

const Card = styled(Column)`
    position: relative;
    width: 400px;
    min-height: 200px;

    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    border-radius: 8px;

    padding: 32px;
    box-sizing: border-box;
`;

const PlusIcon = () => (
    <SmallCircleButton secondary={false}>
        <PlusIconSvg />
    </SmallCircleButton>
);

const CalculatorIcon = styled(withInteractibleIconStyles(CalculatorSvg))``;
const GuestsIcon = styled(withInteractibleIconStyles(GuestsIconSvg))``;
const EditIcon = styled(withInteractibleIconStyles(EditSvg))``;

export default function Meeting() {
    const { id } = useParams<{ id?: string }>();

    const meeting = useMeetings(selectMeeting(id));
    const { user } = useUser();
    const teamCompliance = user.team?.compliance || {};

    const start = moment(meeting?.startsAt);
    const end = moment(meeting?.endsAt);

    const history = useHistory<{ step?: ESteps; shouldGoBack?: boolean; backgroundLocation?: Location }>();
    const editAttendeesLocation = () => ({ pathname: `/events/${id}/edit`, state: { step: ESteps.attendees } });
    const editDocsLocation = () => ({ pathname: `/events/${id}/edit` });

    const viewVenueListing = (venueId: number) =>
        history.push(`/event/${id}/venue/listing/${venueId}`, {
            shouldGoBack: true,
            backgroundLocation: history.location,
        });
    const goToVenueSearch = () => history.push(`/event/${id}/venue`);
    const viewInquiries = () => history.push(`/event/${id}/venue/inquiries`);

    const viewProposal = (proposalId: number) => {
        let url = `/event/${id}/venue/proposal/${proposalId}`;
        history.push(url, {
            shouldGoBack: true,
        });
    };
    const editable = meeting?.editable;

    const { modalShown, showModal, hideModal } = useShowModal();

    const menuItems = [
        {
            icon: <PlusIcon />,
            label: 'Create Note / Survey',
            to: `/event/${id}/communication/create/note`,
        },
        {
            icon: <PlusIcon />,
            label: 'Create Additional Invites',
            to: `/event/${id}/communication/create/invite`,
        },

        ...(id && meeting?.venueBooking
            ? [
                  {
                      icon: <CalculatorIcon />,
                      label: 'Final Spend Tracking',
                      onClick: showModal,
                  },
              ]
            : []),
        {
            icon: <GuestsIcon />,
            label: 'Guest List',
            to: editAttendeesLocation,
        },
        { icon: <EditIcon />, label: 'Edit Meeting', to: editDocsLocation },
    ];

    const OverflowMenu = () => {
        const [finalSpendForm, setFinalSpendForm] = useState<TFinalSpendForm>();
        const [booking, setBooking] = useState<Bizly.Booking>();
        useEffect(() => {
            if (id) {
                getBookingsForEvent(id)
                    .then(({ bookings = [] }: { bookings: Bizly.Booking[] }) => {
                        // TODO: This works while we support only single booking (orders) per event. Need to update logic to handle multiple possible bookings when that becomes supported
                        const firstBooking = bookings[0] || {};
                        setBooking(firstBooking);

                        if (firstBooking.finalSpend)
                            setFinalSpendForm({
                                ...firstBooking.finalSpend,
                                existingDocs: { invoice1: firstBooking.invoiceUrl, invoice2: firstBooking.invoice2Url },
                            });
                    })
                    .catch();
            }
        }, []);

        return editable ? (
            <>
                <Menu items={menuItems} />
                {id && meeting?.venueBooking && booking && finalSpendForm && (
                    <Dialog open={modalShown} onBackdropClick={hideModal}>
                        <FinalSpendForm
                            booking={booking}
                            formValues={finalSpendForm}
                            onFormUpdate={setFinalSpendForm}
                            teamCompliance={teamCompliance}
                            readonly={!editable}
                        />
                    </Dialog>
                )}
            </>
        ) : null;
    };

    const { enqueueSnackbar } = useSnackbar();

    const [responding, setResponding] = React.useState(false);
    const respond = async (response: 'accept' | 'reject') => {
        if (!id || !user.email) return;
        const action = response === 'accept' ? attendeesActions.accept : attendeesActions.reject;

        try {
            setResponding(true);
            await action(id, user.email);
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        } finally {
            setResponding(false);
        }
    };

    if (!meeting) {
        return (
            <>
                <LoadMeeting id={id} />
                <SpinnerOverlay />
            </>
        );
    }

    if (!meeting.published) {
        return <Redirect to={`${history.location.pathname}/edit`} push={false} />;
    }

    return (
        <>
            <LoadMeeting id={id} />
            <DetailsRow alignItems="center" itemSpacing="default">
                <Date>
                    {meeting.startsAt && meeting.endsAt
                        ? [
                              start.calendar({ sameElse: 'ddd, MMM Do, yyyy hh:mm A' }).replace('at ', ''),
                              end.format('hh:mm A'),
                          ].join(' - ')
                        : 'Unscheduled'}
                </Date>
                {meeting.virtualMeeting?.link && <VirtualMeetingPopover virtualMeeting={meeting.virtualMeeting} />}

                {meeting.location && (
                    <LocationLink
                        href={
                            meeting.googlePlaceId
                                ? `https://www.google.com/maps/place/?q=place_id:${meeting.googlePlaceId}`
                                : `https://www.google.com/maps/place/?q=${meeting.location}`
                        }
                        openInNewTab
                        underline
                    >
                        {meeting.location}
                    </LocationLink>
                )}
                <Row style={{ marginLeft: 'auto', width: 'auto' }} alignItems="center" itemSpacing="smallish">
                    {id && (
                        <QuickInviteAttendees
                            meetingId={id}
                            editable={editable}
                            disableInvites
                            editAttendeesLocation={editAttendeesLocation}
                        />
                    )}

                    <OverflowMenu />
                </Row>
            </DetailsRow>

            <RespondableHeadline meeting={meeting} onRespond={respond} disabled={responding} />
            <Row itemSpacing="largest">
                <DetailsCol>
                    <MeetingDetail meeting={meeting} />
                </DetailsCol>
                <CardsCol itemSpacing="smallish">
                    <Card>
                        <DocumentsCard
                            attachments={meeting.attachments}
                            editable={editable}
                            editDocsLocation={editDocsLocation}
                        />
                    </Card>
                    <Card>
                        {id && (
                            <VenuesCard
                                eventId={id}
                                {...meeting}
                                editable={editable}
                                viewVenueListing={viewVenueListing}
                                viewProposal={viewProposal}
                                onSearch={goToVenueSearch}
                                onEditInquiries={viewInquiries}
                            />
                        )}
                    </Card>
                    {id && (
                        <Card>
                            <CommunicationsCard
                                meetingId={id}
                                editable={editable}
                                basicParcels={meeting.currentUserAttendee?.parcels}
                                toCreateInvite={`/event/${id}/communication/create/invite`}
                                toCreateNote={`/event/${id}/communication/create/note`}
                            />
                        </Card>
                    )}
                </CardsCol>
            </Row>

            <Spacer largest />
            <Spacer largest />
        </>
    );
}
