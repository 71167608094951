import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';

import { LargeHeadline } from 'components/ui/Headline';

import SideNav from 'components/SideNav';

import IntegrationConnection from './IntegrationConnection';
import IntegrationCallback from './IntegrationCallback';

export default function IntegrationsPage() {
    let { path } = useRouteMatch();

    return (
        <SideNav>
            <LargeHeadline>Integrations</LargeHeadline>

            <Switch>
                <Route exact path={`${path}`}>
                    <IntegrationConnection />
                </Route>
                <Route exact path={`${path}/callback/:integration`}>
                    <IntegrationCallback />
                </Route>
            </Switch>
        </SideNav>
    );
}
