import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import usePersistentSnackbar from 'hooks/usePersistentSnackbar';
import { timeOptions } from 'utils/date_util';
import { userTimeZone } from 'utils/moment';
import timeZoneOptions from 'components/Form/TimeZonePicker/timeZoneOptions';
import useShowModal from 'hooks/useShowModal';

import { createMeetingActions, useCreateMeeting } from './store';
import { selectPlaybooksAsList, usePlaybooks } from 'stores/playbooks';
import { useUser } from 'providers/user';
import { nudgesActions, useNudges } from 'stores/nudges';

import { Spacer } from 'ui';
import PlaceInput from 'components/PlaceInput';
import { VirtualMeetingFieldDeferred, TVMValue } from 'components/Schedule/VirtualMeetingField';
import { default as FormBase } from 'components/Form';
import ConfirmationModal from 'components/ConfirmationModal';
import { Spinner } from 'components/Spinner';
import Errors from './components/Errors';
import IntegrationsPrompt from 'components/IntegrationsPrompt';

type Place = {
    location: Bizly.Event['location'];
    googlePlaceId: Bizly.Event['googlePlaceId'];
};

type TPlaceField = {
    field: string;
    error: Object | null;
    defaultValue: Place;
    onChange: ({ value, field, error }: { value: Place; field: string; error: Object | null }) => void;
    allowGlobal?: boolean;
};

const PlaceField = ({ field, onChange: onChangeProp, defaultValue, allowGlobal = false }: TPlaceField) => {
    return (
        <PlaceInput
            asFormField
            inModalOrPopover
            onChange={(location, googlePlaceId) =>
                onChangeProp({
                    field,
                    error: {},
                    value: {
                        location,
                        googlePlaceId,
                    },
                })
            }
            inputAsSuggestion
            selectInputOnBlur
            key={defaultValue?.location}
            defaultValue={defaultValue?.location}
            defaultValueId={defaultValue?.googlePlaceId}
            allowGlobal={allowGlobal}
        />
    );
};

const VMField = (props: Parameters<typeof VirtualMeetingFieldDeferred>[any]) => {
    const { showVMIntegrations } = useNudges();
    const { modalShown, showModal, hideModal } = useShowModal();
    const [openField, setOpenField] = React.useState(false);
    const skip = (forever?: boolean) => {
        if (forever) {
            nudgesActions.updateIntegrationsPrompts('showVMIntegrations', false);
        } else {
            nudgesActions.mergeNudges({
                showVMIntegrations: false,
            });
        }
        hideModal();
        setOpenField(true);
    };

    return showVMIntegrations ? (
        <>
            <div
                onClickCapture={e => {
                    showModal();
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <VirtualMeetingFieldDeferred {...props} />
            </div>
            {modalShown && (
                <IntegrationsPrompt
                    onSkip={skip}
                    title="Consider"
                    benefits={{
                        zoom: ['Create Zoom links'],
                        'ms-graph': ['Create Teams links'],
                        google: ['Create Meet links'],
                    }}
                />
            )}
        </>
    ) : (
        <VirtualMeetingFieldDeferred openModal={openField} {...props} />
    );
};

const fields = ({
    place,
    integratedVmServices,
    playbooks,
    isPublished,
    allowGlobal,
}: {
    place?: Place;
    integratedVmServices?: string[];
    playbooks: BizlyAPI.Complete.Playbook[] | null;
    isPublished?: boolean;
    allowGlobal?: boolean;
}) => ({
    name: {
        prompt: 'Title',
        type: 'text',
        perRow: '2/3',
        options: { autoFocus: true },
    },
    playbookId: {
        ...(playbooks
            ? {
                  type: 'select',
                  perRow: '2/3',
                  options: {
                      placeholder: 'Select a playbook',
                      options: playbooks.map(playbook => ({ ...playbook, value: playbook.id })),
                  },
              }
            : { type: Spinner, options: { suppressMargin: true } }),
    },
    startDate: {
        prompt: 'Start Date',
        type: 'date_outlined',
        options: {
            ...(!isPublished && {
                minDate: Date.now(),
            }),
            placeholder: 'Date',
            format: 'MMM do',
        },
    },
    startTime: {
        prompt: 'Start Time',
        type: 'select',

        options: {
            options: timeOptions,
            placeholder: 'Start Time',
            autoFocusOptionKey: '12:00:00',
        },
    },
    endDate: {
        prompt: 'End Date',
        type: 'date_outlined',

        options: {
            ...(!isPublished && {
                minDate: Date.now(),
            }),
            placeholder: 'Date',
            format: 'MMM do, yyyy',
        },
    },
    endTime: {
        prompt: 'End Time',
        type: 'select',

        options: {
            options: timeOptions,
            placeholder: 'End Time',
            autoFocusOptionKey: '12:00:00',
        },
    },
    timeZone: {
        prompt: ' ',
        type: 'select',
        perRow: 'auto',
        options: {
            options: timeZoneOptions.map(option => ({ ...option, label: option.abbr })), //(GMT${tzOffset})
            autoFocusOptionKey: userTimeZone,
        },
    },
    description: {
        prompt: 'Description',
        type: 'textarea',
        perRow: '2/3',
        options: {
            rows: 2,
            rowsMax: 4,
        },
    },
    location: {
        prompt: 'Location',
        type: PlaceField,
        perRow: '2/3',
        options: {
            defaultValue: place ?? {},
            allowGlobal: allowGlobal,
        },
    },
    virtualMeeting: {
        type: VMField,
        perRow: '2/3',
        options: {
            cta: 'Add Video Conferencing',
            limit: 1,
            integratedVmServices,
            videoConferencingText: true,
        },
    },
    attachments: {
        type: 'upload_list',
        perRow: '2/3',
        options: {
            prompt: 'Add Attachment',
        },
    },
    type: {
        prompt: 'Meeting Type',
        type: 'radioselect',
        perRow: 2,
        options: {
            options: ['Internal', 'External'],
        },
    },
    costCenter: {
        prompt: 'Cost Center',
        type: 'text',
        perRow: 3,
    },
    internalReference: {
        prompt: 'Internal Reference',
        type: 'text',
        perRow: 3,
    },
});

const startDateSchema = {
    type: 'nested',
    perRow: '4/9',
    schema: [{ fields: ['startDate', 'startTime'], combined: true, itemSpacing: false, spacing: false }],
};
const endDateSchema = {
    type: 'nested',
    perRow: '5/9',
    schema: [{ fields: ['endDate', 'endTime', 'timeZone'], combined: true, itemSpacing: false, spacing: false }],
};

const internalSchema = [
    {
        fields: ['type'],
        itemSpacing: 'large',
        spacing: 'default',
    },
    {
        fields: ['costCenter', 'internalReference'],
        itemSpacing: 'large',
        spacing: false,
    },
];

const schema = (hasPlaybooks?: boolean) => [
    { fields: ['name'], itemSpacing: 'large', spacing: 'default' },
    ...(hasPlaybooks ? [{ fields: ['playbookId'], itemSpacing: 'large', spacing: 'default' }] : []),
    { fields: [startDateSchema, endDateSchema], itemSpacing: 'medium', spacing: 'default' },
    ...internalSchema,
    { fields: ['description'], itemSpacing: 'large', spacing: 'default' },
    { fields: ['location'], itemSpacing: 'large', spacing: 'default' },
    { fields: ['virtualMeeting'], itemSpacing: 'large', spacing: 'default' },
    { fields: ['attachments'], itemSpacing: 'large', spacing: false },
];

export type TBasicInfoValue = Partial<{
    name: string | null;
    playbookId: number;
    startDate: Date;
    startTime: string;
    endDate: Date;
    endTime: string;
    timeZone: string;
    purpose: string | null;
    description: string | null;
    location: Place;
    agendaDescription: string | null;
    virtualMeeting: TVMValue;
    attachments: BizlyAPI.Attachment[];

    type?: BizlyAPI.Meeting['type'];
    internalReference?: BizlyAPI.Meeting['internalReference'];
    costCenter?: BizlyAPI.Meeting['costCenter'];
}>;

const Form = styled(FormBase)`
    min-width: 660px;
    max-width: 660px;
`;

const fixDates = (value: TBasicInfoValue, reference: 'start' | 'end') => {
    let { startDate, startTime, endDate, endTime } = value;
    startDate = startDate || endDate || moment().toDate();
    endDate = endDate || startDate || moment().toDate();
    startTime = startTime || endTime || '12:00:00';
    endTime = endTime || startTime || '12:30:00';

    let start = moment(startDate)
        .startOf('day')
        .add(moment.duration(startTime));
    let end = moment(endDate)
        .startOf('day')
        .add(moment.duration(endTime));

    if (start >= end) {
        if (reference === 'start') {
            end = start.clone().add(30, 'minutes');
        }
        if (reference === 'end') {
            start = end.clone().add(-30, 'minutes');
        }
    }

    return {
        startTime: start.format('HH:mm:ss'),
        startDate: moment(start)
            .startOf('day')
            .toDate(),
        endTime: end.format('HH:mm:ss'),
        endDate: moment(end)
            .startOf('day')
            .toDate(),
    };
};

export default function BasicInfoForm() {
    const { basicInfo, basicInfoErrors, stepIdx, changed, loading: loadingMeeting, isPublished } = useCreateMeeting();
    const { user } = useUser();
    const playbooksList = usePlaybooks(selectPlaybooksAsList);
    const { loadingAll, playbooksById } = usePlaybooks();

    const enqueueSnackbar = usePersistentSnackbar([basicInfoErrors, stepIdx]);
    React.useEffect(() => {
        const hasErrors = Object.values(basicInfoErrors).some(v => v);
        if (hasErrors) {
            enqueueSnackbar(<Errors errors={basicInfoErrors} />, { variant: 'error' });
        }
    }, [basicInfoErrors, enqueueSnackbar]);

    const [confirmPlaybook, setConfirmPlaybook] = React.useState<BizlyAPI.Complete.Playbook>();

    const onChange = ({ field, value }: { field: keyof ReturnType<typeof fields>; value: TBasicInfoValue }) => {
        if (field === 'virtualMeeting' && value.virtualMeeting?.deferredService) {
            value.virtualMeeting.notes = 'A link will be created when the meeting is published'; // handle if already published
        }

        if (field === 'playbookId' && value.playbookId && playbooksById) {
            const playbook = playbooksById[value.playbookId];
            if (playbook) {
                if (changed) return setConfirmPlaybook(playbook);

                return createMeetingActions.applyPlaybook(playbook);
            } else {
                return;
            }
        }

        if (field === 'startDate' || field === 'startTime') {
            value = { ...value, ...fixDates(value, 'start') };
        }

        if (field === 'endDate' || field === 'endTime') {
            value = { ...value, ...fixDates(value, 'end') };
        }

        createMeetingActions.setBasicForm(value, field === 'name');
        createMeetingActions.setBasicFormErrors({});
    };

    return (
        <>
            {!loadingMeeting && (
                <Form
                    fields={fields({
                        place: basicInfo.location,
                        integratedVmServices: user?.connectors?.map(connector => connector.type),
                        playbooks: loadingAll ? null : playbooksList ?? [],
                        isPublished: !!isPublished,
                        allowGlobal: user.team?.allowGlobal,
                    })}
                    schema={schema(loadingAll || playbooksList.length > 0)}
                    value={basicInfo}
                    onChange={onChange}
                />
            )}

            <Spacer largest />
            <Spacer largest />
            <Spacer largest />
            {confirmPlaybook && (
                <ConfirmationModal
                    headline="Apply Playbook"
                    onDismiss={() => setConfirmPlaybook(undefined)}
                    onProceed={() => {
                        createMeetingActions.applyPlaybook(confirmPlaybook);
                        setConfirmPlaybook(undefined);
                    }}
                    prompt="Applying playbook may overwrite some data, continue?"
                    isActive
                />
            )}
        </>
    );
}
