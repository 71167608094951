import React, { Component } from 'react';
import styled from 'styled-components/macro';

import { CloudinaryUploader } from './CloudinaryUploader';
import { Row, Copy } from '../ui';
import Button from './ui/Button';

import { EColors } from 'theme';
import colorFns from 'colorFns';

const UploaderContainer = styled(Row)`
    position: relative;

    ${({ width }) => (width ? `width: ${width}px;` : '')}
`;

const SquareContainer = styled(Row)`
    padding-bottom: ${({ heightRatio }) => heightRatio || '100%'};

    background-image: linear-gradient(
            45deg,
            ${colorFns.softAccentedBackground} 25%,
            ${colorFns.softAccentedBackground.alpha(0)} 25%
        ),
        linear-gradient(-45deg, ${colorFns.softAccentedBackground} 25%, ${colorFns.softAccentedBackground.alpha(0)} 25%),
        linear-gradient(45deg, ${colorFns.softAccentedBackground.alpha(0)} 75%, ${colorFns.softAccentedBackground} 75%),
        linear-gradient(-45deg, ${colorFns.softAccentedBackground.alpha(0)} 75%, ${colorFns.softAccentedBackground} 75%);
    background-size: 24px 24px;
    background-position: 0 0, 0 12px, 12px -12px, -12px 0px;
    border-radius: 8px;
    overflow: hidden;
`;

const FittedImage = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

const Fill = styled(Row)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    ${({ bottomRight }) =>
        bottomRight
            ? `    
    align-items: flex-end;
    justify-content: flex-end;
    padding: 11px;
    box-sizing: border-box;`
            : `
    align-items: center;
    justify-content: center;
    text-align: center;
    `}

    border-radius: 8px;
    overflow: hidden;
`;

export const FittedSquareImage = ({ src, width, placeholder = '' }) => (
    <UploaderContainer width={width}>
        <SquareContainer>
            <Fill>{src ? <FittedImage src={src} /> : <Copy $color={EColors.darkGrey}>{placeholder}</Copy>}</Fill>
        </SquareContainer>
    </UploaderContainer>
);

const SpinnerUnderlay = styled(Fill)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite, 0.7)};
`;

export class ImageUploader extends Component {
    state = {
        resource: this.props.resource,
    };

    static getDerivedStateFromProps(newProps, currentState) {
        return newProps.resource !== currentState.resource ? { resource: newProps.resource } : null;
    }

    handleUploadStart() {
        this.setState({
            error: null,
            uploading: true,
        });
    }

    handleUploadSuccess(cloudinaryResponse) {
        const { onChange } = this.props;
        this.setState({
            resource: cloudinaryResponse,
            uploading: false,
        });
        onChange && onChange(cloudinaryResponse);
    }

    handleUploadError(error) {
        this.setState({
            error,
            uploading: false,
        });
    }

    render() {
        const { resource, uploading } = this.state;
        const { className, disabled, heightRatio } = this.props;

        const replacing = resource && uploading;

        return (
            <UploaderContainer className={className}>
                <SquareContainer heightRatio={heightRatio}>
                    {resource && (
                        <Fill>
                            <FittedImage src={resource ? resource.url : undefined} />
                        </Fill>
                    )}
                    {replacing && <SpinnerUnderlay />}
                    <Fill bottomRight={resource && !uploading}>
                        <CloudinaryUploader
                            onUploadStart={() => this.handleUploadStart()}
                            onUploadSuccess={document => this.handleUploadSuccess(document)}
                            onUploadError={error => this.handleUploadError(error)}
                            accept={'image/*'}
                            disabled={disabled}
                        >
                            <Button
                                onClick={
                                    e => e.preventDefault()
                                    // There's a <form> inside CloudinaryUploader
                                }
                                secondary={resource}
                                width={resource ? 122 : 98}
                            >
                                {resource ? 'Replace Image' : 'Add Image'}
                            </Button>
                        </CloudinaryUploader>
                    </Fill>
                </SquareContainer>
            </UploaderContainer>
        );
    }
}
