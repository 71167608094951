import React from 'react';

import EventCollaboratorsProvider from './event-collaborators';

import { timeValue, mergeDateTime, moveDayBy } from '../utils/date_util';
import pick from 'lodash/pick';

import {
    loadEventWithTemplateAndCollaborators,
    loadSuggestedVenuesForEvent,
    selectVenue,
    deselectVenue,
    updateEvent,
    loadEvent,
    updateEventNoDebounce,
} from '../api';
import { toQueryParams } from 'components/VenueSearch/SearchUtils';
import { toMiles } from 'utils';

const initialEvent: Bizly.Event = {
    id: 0,
    name: '',
    schedule: [],
    description: '',
    objective: '',
    attendeeCounts: {
        total: 0,
        attending: 0,
        notAttending: 0,
        invited: 0,
        notSent: 0,
    },
    editable: false,
    plannedBy: { firstName: '', lastName: '', imageUrl: '', email: '' },
    currency: { id: -1, code: 'USD' },
};

const initialVenues: Array<Bizly.Venue> = [];

export const EventContext = React.createContext<Bizly.EventContext>({
    loading: true,
    event: initialEvent,
    template: { id: 0 },
    error: '',
    suggestedVenues: initialVenues,
    transparentHeader: false,
    setTransparentHeader: (collapsed: boolean) => {},
    onEventChange: (newEvent: Bizly.Event) => {},
    refreshEvent: () => {},
    onDescriptionChange: (newValue: string) => {},
    onObjectiveChange: (newValue: string) => {},
    onAddDay: ({ append = true }: { append: boolean }) => {},
    onRemoveDay: (dayIndex: number) => {},
    onDayChange: (dayIndex: number, newValue: Bizly.Day) => {},
    updateSchedule: (schedule: Bizly.Day[]) => {},
    onVenueSelect: (venue: Bizly.Venue) => {},
    onLocationChange: (location: string, googlePlaceId: string) => {},
    updateAttendeesCount: (attendeeCounts: Bizly.AttendeeCounts) => {},
    setEvent: (event: Bizly.Event) => {},
    setCurrency: (currency?: Bizly.Currency | null) => {},
    reloadProvider: () => {},
});

type EventProviderProps = {
    eventId: number;
    children: React.ReactNode;
};

function EventProvider({ eventId, children }: EventProviderProps) {
    const [loading, setLoading] = React.useState(true);
    const [event, setEvent] = React.useState(initialEvent);
    const [template, setTemplate] = React.useState<any>(initialEvent);
    const [collaborators, setCollaborators] = React.useState<BizlyAPI.EventCollaboratorOrPending[]>([]);
    const [error, setError] = React.useState('');
    const [suggestedVenues, setSuggestedVenues] = React.useState<Array<Bizly.Venue>>([]);

    const [transparentHeader, setTransparentHeader] = React.useState(false);

    React.useEffect(() => {
        async function fetchEventAndSuggestedVenues() {
            const { event, template, collaborators } = await loadEventWithTemplateAndCollaborators(eventId);
            if (event && !event.schedule.length) {
                event.schedule.push({
                    start: null,
                    end: null,
                    guestrooms: [],
                    agenda: [],
                });
            }
            setEvent(event);
            setTemplate(template);
            setCollaborators(collaborators);
            if (event.city) {
                const venueSearchParams = eventParamsToSearchParams(event.venueSearchParameters);
                const suggestedVenues: Array<Bizly.Venue> = await loadSuggestedVenuesForEvent(
                    eventId,
                    venueSearchParams
                );
                setSuggestedVenues(suggestedVenues);
            }
        }

        setLoading(true);

        fetchEventAndSuggestedVenues()
            .then(() => setLoading(false))
            .catch(e => {
                console.error(`Could not fetch data for meeting ${eventId}`);
                setError(e);
                setLoading(false);
            });
    }, [eventId]);

    const eventParamsToSearchParams = (venueSearchParameters: Bizly.Event['venueSearchParameters']) => {
        const { radius, brandIds, ...params } = venueSearchParameters ?? {};
        return venueSearchParameters
            ? toQueryParams({
                  ...params,
                  ...(brandIds ? { brandIds } : {}),
                  ...(radius ? { radius: toMiles(radius) } : {}),
              })
            : undefined;
    };

    const venueSearchParams = React.useMemo(() => eventParamsToSearchParams(event.venueSearchParameters), [
        event.venueSearchParameters,
    ]);

    async function onEventChange(newEvent: Bizly.Event) {
        const mergedEvent = {
            ...event,
            ...newEvent,
        };
        setEvent(mergedEvent);
        await updateEvent(mergedEvent);
    }

    async function refreshEvent() {
        const refreshedEvent = await loadEvent(event.id);
        setEvent(refreshedEvent);
    }

    async function onDescriptionChange(newValue: string) {
        const newEvent = {
            ...event,
            description: newValue,
        };
        setEvent(newEvent);
        await updateEvent(newEvent);
    }

    async function onObjectiveChange(newValue: string) {
        const newEvent = {
            ...event,
            objective: newValue,
        };
        setEvent(newEvent);
        await updateEvent(newEvent);
    }

    async function onVenueSelect(venue: Bizly.Venue) {
        const existingSelections = event.selectedVenues || [];
        let newSelections;
        let isAdding = !existingSelections.find((v: Bizly.Venue) => v.id === venue.id);
        if (!isAdding) {
            newSelections = existingSelections.filter((v: Bizly.Venue) => v.id !== venue.id);
            await deselectVenue(event, venue);
        } else {
            newSelections = existingSelections.concat(venue);
            await selectVenue(event, venue);
        }
        setEvent({ ...event, selectedVenues: newSelections });
    }

    async function onLocationChange(location: string, googlePlaceId: string) {
        // optimistic update:
        setEvent(prevEvent => ({
            ...prevEvent,
            location,
            googlePlaceId,
        }));

        const updatedEvent = await updateEventNoDebounce({
            ...event,
            id: event.id,
            location,
            googlePlaceId,
        });

        const suggestedVenues = await loadSuggestedVenuesForEvent(event.id, venueSearchParams);

        setEvent(prevEvent => ({
            ...prevEvent,
            ...pick(updatedEvent, ['location', 'googlePlaceId', 'lat', 'lng']),
        }));

        setSuggestedVenues(suggestedVenues);
    }

    /* Planner Page Handlers */
    async function onAddDay({ append = true }) {
        const delta = append ? 1 : -1;
        const clonedArray = event.schedule.slice();
        const { startDate } = clonedArray[append ? clonedArray.length - 1 : 0];

        const newDay: Bizly.Day = {
            start: null, // TODO: Remove after retiring legacy date/time management
            end: null, // TODO: Remove after retiring legacy date/time management
            startDate: moveDayBy(startDate, delta),
            startTime: null,
            endTime: null,
            agenda: [],
            guestrooms: [],
            addToInquiry: true,
        };

        const updatedEvent = {
            ...event,
            schedule: append ? clonedArray.concat(newDay) : [newDay].concat(clonedArray),
        };
        setEvent(updatedEvent);
        const updatedEventFromResponse = await updateEvent(updatedEvent);
        setEvent(updatedEventFromResponse);
    }

    async function onRemoveDay(dayIndex: number) {
        const clonedArray = event.schedule.slice();
        const updatedEvent = {
            ...event,
            schedule: clonedArray.filter((d, index) => index !== dayIndex),
        };
        setEvent(updatedEvent);
        const updatedEventFromResponse = await updateEvent(updatedEvent);
        setEvent(updatedEventFromResponse);
    }

    async function onDayChange(dayIndex: number, newValue: Bizly.Day) {
        const { schedule: currentSchedule, startsAt: currentStart, endsAt: currentEnd } = event;
        const { start: currentDayStart, end: currentDayEnd } = currentSchedule[dayIndex];
        const { startDate, startTime, endTime } = newValue;

        const endDate =
            startDate && startTime && endTime && timeValue(startTime) > timeValue(endTime)
                ? moveDayBy(startDate)
                : startDate;
        const newDayStart = startDate && startTime && mergeDateTime(startDate, startTime);
        const newDayEnd = endDate && endTime && mergeDateTime(endDate, endTime);

        const updatedDay = {
            ...newValue,
            start: newDayStart || currentDayStart,
            end: newDayEnd || currentDayEnd,
            agenda: newValue.agenda.map(entry => {
                const { startTime: agStartTime, endTime: agEndTime, start, end } = entry;
                return {
                    ...entry,
                    start: (startDate && agStartTime && mergeDateTime(startDate, agStartTime)) || start,
                    end: (endDate && agEndTime && mergeDateTime(endDate, agEndTime)) || end,
                };
            }),
        };

        const isFirstDay = dayIndex === 0;
        const isLastDay = dayIndex === currentSchedule.length - 1;

        const updatedEvent = {
            ...event,
            startsAt: isFirstDay && newDayStart ? newDayStart : currentStart,
            endsAt: isLastDay && newDayEnd ? newDayEnd : currentEnd,
            schedule: currentSchedule.map((day, index) => (index === dayIndex ? updatedDay : day)),
        };

        setEvent(updatedEvent);
        const updatedEventFromResponse = await updateEvent(updatedEvent);
        setEvent(updatedEventFromResponse);
    }

    async function updateSchedule(schedule: Bizly.Day[]) {
        setEvent({ ...event, schedule });

        const updatedEventFromResponse = await updateEvent({
            ...event,
            schedule,
        });
        setEvent(updatedEventFromResponse);
    }

    function updateAttendeesCount(attendeeCounts: Bizly.AttendeeCounts) {
        setEvent({
            ...event,
            attendeeCounts,
        });
    }
    const [key, setKey] = React.useState(0);

    function reloadProvider() {
        setKey(curKey => curKey + 1);
    }

    function setCurrency(currency?: Bizly.Currency | null) {
        setEvent({
            ...event,
            currency: currency ?? { id: -1, code: 'USD' },
        });
    }

    return (
        <EventContext.Provider
            key={key}
            value={{
                event,
                template,
                error,
                loading,
                suggestedVenues,
                onEventChange,
                refreshEvent,
                onDescriptionChange,
                onObjectiveChange,
                onVenueSelect,
                onLocationChange,
                onAddDay,
                onRemoveDay,
                onDayChange,
                updateSchedule,
                updateAttendeesCount,
                transparentHeader,
                setTransparentHeader,
                setEvent,
                setCurrency,
                reloadProvider,
                venueSearchParams: venueSearchParams ?? undefined,
            }}
        >
            <EventCollaboratorsProvider eventId={eventId} collaborators={collaborators} onChange={setCollaborators}>
                {children}
            </EventCollaboratorsProvider>
        </EventContext.Provider>
    );
}

export function useEvent() {
    const context = React.useContext(EventContext);
    if (context === undefined) {
        throw new Error('useEvent must be used within a EventProvider');
    }
    return context;
}

export function withEventContext<T>(Component: React.ComponentType<T>) {
    return (props: T) => (
        <EventContext.Consumer>{context => <Component eventContext={context} {...props} />}</EventContext.Consumer>
    );
}

export default EventProvider;
