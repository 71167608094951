import React from 'react';

import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

import { useUser } from 'providers/user';

import Events from 'pages/Events';
import Meetings from 'pages/Meetings';
import CreateMeeting, { NavButtons } from 'pages/CreateMeeting';
import Meeting from 'pages/Meeting';
import SideNav from 'components/SideNav';
import GenericNavButtons from 'components/GenericNavButtons';
import { getLast } from 'components/EventHeader';

const ANIMATION_KEY = '1';

export default function MeetingsRoute() {
    const match = useRouteMatch();
    const { toggles } = useUser();
    const history = useHistory();

    const shouldGoBack = history.location.state && history.location.state.shouldGoBack;
    const props = shouldGoBack
        ? { onBack: history.goBack }
        : { backTo: getLast(history.location, toggles.gate.useTemplatesV2) };

    return (
        <Route>
            <Switch>
                {toggles.gate.upgradedDashboard && (
                    <Route path={[`${match.path}/unscheduled`, `${match.path}/drafts`]}>
                        <SideNav fillWidth fillHeight key={ANIMATION_KEY}>
                            <Meetings unscheduled />
                        </SideNav>
                    </Route>
                )}
                {toggles.gate.createMeetingsFlow && (
                    <Route path={[`${match.path}/new`, `${match.path}/:id/edit`]}>
                        <SideNav
                            fillWidth
                            fullHeight
                            hideUser
                            routeChildren={
                                <Switch>
                                    <Route path={`${match.path}/:id/edit/venue/listing`}>
                                        <GenericNavButtons {...props} />
                                    </Route>
                                    <Route>
                                        <NavButtons />
                                    </Route>
                                </Switch>
                            }
                            key={ANIMATION_KEY}
                        >
                            <CreateMeeting />
                        </SideNav>
                    </Route>
                )}

                {toggles.gate.createMeetingsFlow && (
                    <Route path={`${match.path}/:id`}>
                        <SideNav fillWidth fullHeight style={{ paddingRight: 0 }} key={ANIMATION_KEY}>
                            <Meeting />
                        </SideNav>
                    </Route>
                )}

                {toggles.gate.upgradedDashboard && (
                    <Route>
                        <SideNav fillWidth fillHeight key={ANIMATION_KEY}>
                            <Meetings />
                        </SideNav>
                    </Route>
                )}

                <Route>
                    <Events />
                </Route>
            </Switch>
        </Route>
    );
}
