import createStore from 'stores';
import { getCurrentInquiry, createInquiryDraft, updateBookingInquiry } from 'api/playbooks';

type State = {
    loading: boolean;
} & (
    | {
          loaded: false;
          playbookId?: number | string;
          inquiry: null;
      }
    | {
          loaded: true;
          playbookId: number | string;
          inquiry: null | BizlyAPI.Inquiry;
      }
);
type Store = State;

const initialState: State = {
    inquiry: null,
    loaded: false,
    loading: false,
};

export const [useCurrentInquiry, currentInquiryStoreApi] = createStore<Store>(() => initialState);

const { setState, getState } = currentInquiryStoreApi;

export const currentInquiryActions = {
    load: async (playbookId: number | string) => {
        let { playbookId: curPlaybookId } = getState();
        setState({
            loading: true,
            ...(playbookId !== curPlaybookId
                ? {
                      inquiry: null,
                      venues: null,
                      playbookId,
                      loaded: false,
                  }
                : {}),
        });

        try {
            const inquiry = await getCurrentInquiry(playbookId);

            ({ playbookId: curPlaybookId } = getState());
            if (curPlaybookId === playbookId)
                setState({
                    inquiry,
                    playbookId,
                    loaded: true,
                    loading: false,
                });

            return inquiry;
        } catch (e) {
            setState({
                loading: false,
            });
            throw e;
        }
    },

    createDraft: async (playbookId: number | string) => {
        let currentState = getState();
        if (playbookId !== currentState.playbookId) return;
        if (currentState.inquiry) throw new Error('Meeting already has an inquiry.');

        setState({ loading: true });

        try {
            const { inquiry } = await createInquiryDraft(playbookId);
            currentState = getState();
            if (playbookId !== currentState.playbookId) return;

            setState({
                inquiry,
                playbookId,
                loaded: true,
                loading: false,
            });

            return inquiry;
        } catch (e) {
            currentState = getState();
            if (playbookId !== currentState.playbookId) return;

            setState({ loading: false });
            throw e;
        }
    },

    updateDraft: async (inquiryId: number | string, data: Partial<BizlyAPI.Inquiry>) => {
        let currentState = getState();
        if (!currentState.loaded || !currentState.inquiry || inquiryId !== currentState.inquiry.id) return;
        if (!currentState.inquiry.draft) throw new Error('Can only edit unsubmitted inquiries');

        setState({ loading: true });

        try {
            const { inquiry } = await updateBookingInquiry(inquiryId, data);

            currentState = getState();
            if (!currentState.loaded || !currentState.inquiry || inquiryId !== currentState.inquiry.id) return;

            setState({
                inquiry,
                loading: false,
            });

            return inquiry;
        } catch (e) {
            currentState = getState();
            if (!currentState.loaded || !currentState.inquiry || inquiryId !== currentState.inquiry.id) return;

            setState({ loading: false });
            throw e;
        }
    },

    createAndUpdateDraft: async (playbookId: number | string, data: Partial<BizlyAPI.Inquiry>) => {
        try {
            let inquiry = await currentInquiryActions.createDraft(playbookId);
            if (!inquiry) return;
            const inquiryId = inquiry.id;
            return await currentInquiryActions.updateDraft(inquiryId, data);
        } catch (e) {
            throw e;
        }
    },

    createOrUpdateDraft: async (playbookId: number | string, data: Partial<BizlyAPI.Inquiry>) => {
        const inquiryId = getState().inquiry?.id;
        if (inquiryId) {
            return await currentInquiryActions.updateDraft(inquiryId, data);
        }
        return await currentInquiryActions.createAndUpdateDraft(playbookId, data);
    },
};
