import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { chunk, clamp } from 'lodash';

import { withInteractibleIconStyles } from 'shared';

import { ReactComponent as ArrowIcon } from 'images/icons/gallery_arrow.svg';
import { Row, Column } from 'ui';
import { HorizontalDivide } from 'components/ui/Divider';
import { H2Headline } from 'components/ui/Headline';
import Button from 'components/ui/Button';

import TemplateCard, { CARD_WIDTH } from 'components/Templates/TemplateCard';

const CARD_SPACING = 24;

const Container = styled(Column)<{ perRow: number }>`
    width: ${({ perRow }) => perRow * CARD_WIDTH + (perRow - 1) * CARD_SPACING + 'px'};
`; // TODO: Base contained template card height/width on % of container for full responsiveness

const Page = styled(Row)`
    flex-wrap: wrap;
    width: unset;
    margin: -${CARD_SPACING / 2}px;

    > * {
        margin: ${CARD_SPACING / 2}px;
    }
`;

const ArrowBack = styled(withInteractibleIconStyles(ArrowIcon))`
    height: auto;
    width: 16px;
`;
const ArrowForward = styled(ArrowBack)`
    transform: rotate(180deg);
`;

const Controls = styled(Row)`
    width: auto;
    align-items: center;
`;

type TPagedTemplatesProps = {
    templates: Bizly.EventTemplate[];
    title?: string;
    hideMenu?: boolean;
    showAll?: boolean;
    onShowAll?: () => void;
    onBack?: () => void;
    perRow?: number;
};

const MAX_ENTRIES = 6;

const BACK = -1,
    FORWARD = 1;

type TPageDirection = typeof BACK | typeof FORWARD;

export default function PagedTemplates({
    templates,
    title,
    hideMenu,
    showAll,
    onShowAll,
    onBack,
    perRow = 3,
}: TPagedTemplatesProps) {
    const [page, setPage] = useState<number>(1);

    const totalPages = Math.ceil(templates.length / MAX_ENTRIES);

    const handlePageChange = (direction: TPageDirection) =>
        setPage(prevPage => clamp(prevPage + direction, 1, totalPages));

    const limitedTemplates = useMemo(() => chunk(templates, MAX_ENTRIES)[page - 1], [templates, page]);

    if (templates.length === 0) return <Container perRow={perRow} />;

    return (
        <Container itemSpacing="small" perRow={perRow}>
            {(title || onShowAll) && (
                <>
                    <Row justifyContent="space-between">
                        <div>{title && <H2Headline>{title}</H2Headline>}</div>
                        <Controls itemSpacing="medium">
                            {!showAll && templates.length > MAX_ENTRIES && (
                                <>
                                    <div>
                                        <ArrowBack onClick={() => handlePageChange(BACK)} />
                                        {page} / {totalPages}
                                        <ArrowForward onClick={() => handlePageChange(FORWARD)} />
                                    </div>
                                    {onShowAll && (
                                        <Button secondary small onClick={onShowAll}>
                                            Show All
                                        </Button>
                                    )}
                                </>
                            )}
                            {showAll && onBack && (
                                <Button onClick={onBack} secondary small>
                                    Back
                                </Button>
                            )}
                        </Controls>
                    </Row>
                    <HorizontalDivide />
                </>
            )}
            <Page>
                {(showAll ? templates : limitedTemplates).map(template => (
                    <TemplateCard key={template.id} template={template} hideMenu={hideMenu} />
                ))}
            </Page>
        </Container>
    );
}
