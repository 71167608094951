import React, { useState } from 'react';
import styled from 'styled-components';
import UIDialog from '@material-ui/core/Dialog';

import fontFns from 'fontFns';
import timeZoneOptions from './timeZoneOptions';
import { getShortTimeZone } from 'utils/date_util';
import { setEventTimeZone } from 'api/events';
import { userTimeZone } from 'utils/moment';

import colorFns from 'colorFns';

import UITextButton from 'components/ui/Button/TextButton';
import { Column, Copy, Row } from 'ui';
import { H2Headline as UIH2Headline } from 'components/ui/Headline';
import { DisplayField, SelectField } from 'components/FormFields';
import { useEvent } from 'providers/event';

const TextButton = styled(UITextButton)<{ readonly?: boolean; overrideFormStyles?: boolean }>`
    font-size: 16px;
    font-weight: 400;

    ${({ overrideFormStyles }) =>
        overrideFormStyles &&
        `
        padding-left: 0;
        padding-right: 0;
        margin-right: 4px;
        
        ${Copy} {
        margin: 0;
        padding: 0;
    }`}

    ${({ readonly, theme }) =>
        readonly &&
        `
        cursor: unset;
        color: ${colorFns.pureBlack({ theme })} !important;
    `}
`;

const Modal = styled(Column)``;

const ButtonRow = styled(Row)``;

const Dialog = styled(UIDialog)`
    .MuiDialog-paperScrollPaper {
        padding: 32px;
        overflow-y: unset;

        ${Modal} {
            max-width: 400px;
            margin: 0;

            ${Copy} {
                font-size: 18px;
                line-height: 27px;
            }

            ${ButtonRow} {
                margin-bottom: 0;
            }
        }
    }
`;

const H2Headline = styled(UIH2Headline)<{ warning?: boolean }>`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.accentedHeadline)};
    text-transform: none;
    ${fontFns.heading}
`;

type TTimeZonePickerProps = {
    /** @example "America/New_York" */
    value?: string;
    date: string | Date; // to determine if a timezone shorthand is EST or EDT
    readonly?: boolean;
    disabled?: boolean;
    overrideFormStyles?: boolean;
};

export default function TimeZonePicker({
    value: timeZone = 'UTC',
    date,
    readonly,
    disabled,
    overrideFormStyles = false,
}: TTimeZonePickerProps) {
    const [selectedTimeZone, setSelectedTimeZone] = useState(timeZone);
    const [isOpen, setIsOpen] = useState(false);
    const { event, refreshEvent } = useEvent();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newTimeZone = e.target.value;
        setSelectedTimeZone(newTimeZone);
    };

    const handleOpen = () => {
        setSelectedTimeZone(timeZone);
        setIsOpen(true);
    };

    const handleSave = async () => {
        try {
            if (selectedTimeZone) {
                await setEventTimeZone(event.id, selectedTimeZone);
                refreshEvent();
            }
        } finally {
            setIsOpen(false);
        }
    };

    const tzAbbreviation = getShortTimeZone(date, timeZone ?? 'UTC');

    return (
        <>
            <TextButton
                readonly={readonly}
                disabled={readonly || disabled}
                onClick={handleOpen}
                overrideFormStyles={overrideFormStyles}
            >
                <DisplayField iconUrl="" iconAlt="" label="" value={tzAbbreviation} />
            </TextButton>

            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <Modal itemSpacing="large">
                    <H2Headline>Meeting Time Zone</H2Headline>
                    <Copy>Updating the time zone will affect all times for this meeting.</Copy>

                    <SelectField
                        field="eventTimeZone"
                        placeholder="Select a Time Zone"
                        value={selectedTimeZone}
                        autoFocusOptionKey={timeZone ?? userTimeZone}
                        options={timeZoneOptions}
                        onChange={({ value: e }: { value: React.ChangeEvent<HTMLInputElement> }) => handleChange(e)}
                        overflowTruncate
                        readonly={readonly}
                        disabled={disabled}
                    />

                    <ButtonRow justifyContent="flex-end" itemSpacing="smallish">
                        <TextButton secondary onClick={() => setIsOpen(false)}>
                            Cancel
                        </TextButton>
                        <TextButton onClick={handleSave}>Save</TextButton>
                    </ButtonRow>
                </Modal>
            </Dialog>
        </>
    );
}
