import React, { useState } from 'react';

import styled from 'styled-components/macro';
import Popover from '@material-ui/core/Popover';

import { Column, Row, Copy, Spacer } from '../ui';
import { Spinner } from '../components/Spinner';
import TextButton from './ui/Button/TextButton';

import Form from '../components/Form';
import { useUser } from 'providers/user';

const Pop = styled(Column)`
    width: 340px;

    box-sizing: border-box;
    padding: 32px;
`;

const Centered = styled(Column)`
    flex: 1;
    align-items: center;
    justify-content: center;
`;

export const EventSettingsFields = (internalReferencePrompt, disabledFields) => ({
    type: {
        prompt: 'Meeting Type',
        type: 'radioselect',
        options: {
            options: ['Internal', 'External'],
        },
        disabled: disabledFields?.includes('type'),
    },
    costCenter: {
        prompt: 'Cost Center',
        type: 'text',
        disabled: disabledFields?.includes('costCenter'),
    },
    cventId: {
        prompt: internalReferencePrompt ?? 'Internal Reference',
        type: 'text',
        disabled: disabledFields?.includes('cventId'),
    },
    // TODO: move this to the final spend panel
    budget: {
        prompt: 'Budget',
        type: 'text',
        disabled: disabledFields?.includes('budget'),
    },
});

export function EventSettingsFlyout({ event: eventProp, onEventChange, anchor, onClose }) {
    const { user } = useUser();
    const [event, setEvent] = useState(eventProp);
    const [updating, setUpdating] = useState(false);

    const schema = [
        {
            key: 'type',
            fields: ['type'],
            spacing: 'large',
        },
        {
            key: 'costCenter',
            fields: ['costCenter'],
        },
        {
            key: 'cventId',
            fields: ['cventId'],
        },
    ];

    const handleEventUpdate = () => {
        setUpdating(true);
        onEventChange(event).then(() => {
            setUpdating(false);
            onClose();
        });
    };

    const handleEventChange = ({ value }) => setEvent(value);

    return (
        <Popover
            anchorEl={anchor}
            open={!!anchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            onClose={onClose}
        >
            <Pop>
                <Copy large>Meeting Settings</Copy>
                <Spacer larger />
                {updating ? (
                    <Centered>
                        <Spinner />
                    </Centered>
                ) : (
                    <>
                        <Form
                            fields={EventSettingsFields(
                                user.team.meetingInternalReferenceFieldLabel,
                                user.team.authMeetingCreateFields
                            )}
                            schema={schema}
                            value={event}
                            errors={{}}
                            onChange={handleEventChange}
                            disabled={!event.editable}
                        />
                        {event.editable && (
                            <Row style={{ justifyContent: 'flex-end' }}>
                                <TextButton onClick={handleEventUpdate}>Save</TextButton>
                            </Row>
                        )}
                    </>
                )}
            </Pop>
        </Popover>
    );
}
