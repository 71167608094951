import React from 'react';
import styled, { css } from 'styled-components';

import { ToolbarProps, View } from 'react-big-calendar';
import { format, startOfWeek, endOfWeek } from 'date-fns';

import { DatePicker as DatePickerMui, DatePickerProps } from '@material-ui/pickers';

import { Row } from 'ui';
import TextButton from 'components/ui/Button/TextButton';

import { ReactComponent as CalendarIconSVG } from 'images/icons/calendar-picker.svg';
import { ReactComponent as BackArrowSVG } from 'images/icons/back_arrow.svg';
import { ReactComponent as ForwardArrowSVG } from 'images/icons/forward_arrow.svg';
import { withInteractibleIconStyles } from 'shared';

import moment from 'moment';

import colorFns from 'colorFns';

import { TIME_GUTTER_WIDTH } from '../styles';
import { createStyles, makeStyles } from '@material-ui/core';

const WEEK_START_FORMAT = 'MMM dd';
const WEEK_END_FORMAT = 'dd, yyyy';
const WEEK_END_FORMAT_NEW_MONTH = 'MMM dd, yyyy';
const DAY_DATE_FORMAT = 'EEE, MMM dd, yyyy';

const AutoRow = styled(Row)`
    width: auto;
`;

const ToolbarRow = styled(AutoRow)<{ view: View; noMargin?: boolean; noBorder?: boolean }>`
    height: 64px;
    border-bottom: 1px solid #ddd;
    ${({ noBorder }) => (noBorder ? `border-bottom: 0;` : '')}

    ${({ view, noMargin }) =>
        view !== 'month' && view !== 'agenda' && !noMargin
            ? `
    margin-left: ${TIME_GUTTER_WIDTH}px;
    `
            : ''}
`;

const InputSpan = styled.span`
    min-width: 220px;

    font-size: 24px;
    font-weight: 700;

    height: 37px;
    line-height: 35px;
    padding-top: 0;
    padding-bottom: 5px;
`;

const DatePicker = styled(DatePickerMui)`
    *::before,
    *::after {
        border: 0 !important;
    }

    .MuiInput-root {
        > ${InputSpan}, > svg {
            color: ${colorFns.primaryAction};
            cursor: pointer;
        }

        &:hover,
        &:focus {
            > ${InputSpan}, > svg {
                color: ${colorFns.primaryActionHover};
            }
        }
    }
`;

const BottomAlignedTextButton = styled(TextButton)`
    padding-top: 12px;
`;

const CalendarIcon = styled(withInteractibleIconStyles(CalendarIconSVG))`
    margin-right: 16px;
    height: 32px;
    width: 32px;
}`;

const IconSizesStyle = css`
    height: 24px;
    width: 24px;
`;
const BackArrow = styled(withInteractibleIconStyles(BackArrowSVG))`
    ${IconSizesStyle}
`;
const ForwardArrow = styled(withInteractibleIconStyles(ForwardArrowSVG))`
    ${IconSizesStyle}
`;

const MidWeek = styled.div`
    background: ${colorFns.primaryAction};
    .MuiPickersDay-day {
        color: ${colorFns.pureWhite};
    }
`;
const StartWeek = styled(MidWeek)`
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
`;
const EndWeek = styled(MidWeek)`
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
`;

type TToolbar = Pick<ToolbarProps, 'date' | 'onNavigate' | 'view' | 'onView'>;

export default function Toolbar(
    props: TToolbar &
        Partial<{
            className: string;
            hideRange?: boolean;
            noMargin?: boolean;
            noIcon?: boolean;
            noBorder?: boolean;
        }>
) {
    const { date, onNavigate, view, onView, className, hideRange, noMargin, noIcon, noBorder } = props;

    const labelfn = (date: Date | null) => {
        const isNewMonth = date && startOfWeek(date).getMonth() !== endOfWeek(date).getMonth();
        return date
            ? view === 'week'
                ? [
                      format(startOfWeek(date), WEEK_START_FORMAT),
                      format(endOfWeek(date), isNewMonth ? WEEK_END_FORMAT_NEW_MONTH : WEEK_END_FORMAT),
                  ].join(' - ')
                : format(date, DAY_DATE_FORMAT)
            : '';
    };

    const highlightWeek: DatePickerProps['renderDay'] = (date, selected, dayInMonth, component) => {
        const curDate = moment(date);
        const selectedDate = moment(selected);

        if (!curDate.isSame(selectedDate, 'week')) return component;

        if (curDate.isSame(selectedDate.startOf('week'), 'day')) return <StartWeek>{component}</StartWeek>;
        if (curDate.isSame(selectedDate.endOf('week'), 'day')) return <EndWeek>{component}</EndWeek>;

        return <MidWeek>{component}</MidWeek>;
    };

    const useStyles = makeStyles(({ getColor, EColors }) =>
        createStyles({
            datePicker: {
                '& .MuiPickersCalendar-week:hover > div': {
                    backgroundColor: getColor(EColors.pureBlack, 0.08),
                    '&:first-child': {
                        borderTopLeftRadius: '50%',
                        borderBottomLeftRadius: '50%',
                    },
                    '&:last-child': {
                        borderTopRightRadius: '50%',
                        borderBottomRightRadius: '50%',
                    },
                },
            },
        })
    );

    const { datePicker } = useStyles();

    return (
        <ToolbarRow
            alignItems="center"
            justifyContent="space-between"
            view={view}
            className={className}
            noMargin={noMargin}
            noBorder={noBorder}
        >
            <AutoRow alignItems="center" itemSpacing="smallish">
                <DatePicker
                    variant="inline"
                    inputVariant="standard"
                    InputProps={{
                        startAdornment: noIcon ? undefined : <CalendarIcon />,
                        inputComponent: ({ id, className, value, inputRef }) => (
                            <InputSpan id={id} className={className} ref={inputRef}>
                                {value}
                            </InputSpan>
                        ),
                    }}
                    value={date}
                    onChange={newDate => {
                        onNavigate('DATE', newDate as Date);
                    }}
                    labelFunc={labelfn}
                    renderDay={view === 'week' ? highlightWeek : undefined}
                    PopoverProps={{ className: datePicker }}
                />
                <BackArrow onClick={() => onNavigate('PREV')} />
                <ForwardArrow onClick={() => onNavigate('NEXT')} />
            </AutoRow>

            {!hideRange && (
                <>
                    {view === 'day' && (
                        <BottomAlignedTextButton onClick={() => onView('week')}>Week</BottomAlignedTextButton>
                    )}
                    {view === 'week' && (
                        <BottomAlignedTextButton onClick={() => onView('day')}>Day</BottomAlignedTextButton>
                    )}
                </>
            )}
        </ToolbarRow>
    );
}
