import createStore from 'zustand';
import { searchMeetingsActions } from 'stores/meetings/searchStore';

import keyBy from 'lodash/keyBy';

export const sortOptions = [
    { id: 'default', value: 'default', name: 'Sort By...' },
    { id: 'updated_at', value: 'updated_at', name: 'Recent Activity' },
] as const;

export const sortValues = keyBy(sortOptions.map(option => option.value)) as {
    [key in FilterOptions]: key;
};

export const isFilterOption = (option: string): option is FilterOptions => {
    return sortValues[option as FilterOptions] !== undefined;
};
export type FilterOptions = ArrayType<typeof sortOptions>['value'];

type State = {
    sort: FilterOptions;
};

type Store = State;

const initialState: State = {
    sort: 'default',
};

export const [useFilters, filtersStoreApi] = createStore<Store>(() => initialState);

const { setState, getState } = filtersStoreApi;

const LOCAL_STORAGE_KEY = 'meetingFilters';

const isState = (parsed: Object): parsed is State => {
    const stateKeys = new Set(Object.keys(initialState));

    return Object.keys(parsed).some(key => stateKeys.has(key));
};

export const filtersActions = {
    load: () => {
        const loadedFilters = localStorage.getItem(LOCAL_STORAGE_KEY);
        try {
            const parsedFilters = loadedFilters && JSON.parse(loadedFilters);
            if (parsedFilters && isState(parsedFilters)) {
                setState(parsedFilters);
            }
        } catch {}
    },

    setSort: (sortBy: FilterOptions) => {
        setState({ sort: sortBy });
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(getState()));
        searchMeetingsActions.setSort(sortBy);
    },
};
