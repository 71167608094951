import createStore from 'stores';

import { isEmptyString } from 'components/ProposalForm/utils';
import { miToKm, mToKm, toMeters, toMiles } from 'utils';

import { createPlaybook, updatePlaybook } from 'api/playbooks';
import { playbooksActions } from 'stores/playbooks';
import { currentInquiryActions } from 'pages/Playbook/current-inquiry';

import { TBasicInfoValue } from '../BasicInfoForm';
import { TInternalDetailsValue } from '../InternalDetailsForm';
import { TTagsValue } from '../TagsForm';
import { TVenueFiltersValue } from '../VenueFiltersForm';

export enum ESteps {
    basic,
    preview,
    venues,
    inquiry,
    communication,
    collaboration,
}

export const stepList = [
    ESteps.basic,
    ESteps.venues,
    ESteps.inquiry,
    ESteps.communication,
    ESteps.collaboration,
    ESteps.preview,
];

export const stepToTitle = {
    [ESteps.basic]: 'Basic Details',
    [ESteps.venues]: 'Search',
    [ESteps.inquiry]: 'Inquiry',
    [ESteps.communication]: 'Communication',
    [ESteps.collaboration]: 'Collaboration',
    [ESteps.preview]: 'Preview',
} as const;

const defaultSearchParams = {
    grades: [0, 2],
    radius: 15 as Distance.Mile,
    radiusKm: miToKm(15 as Distance.Mile),
} as const;

export const maxStep = stepList.length - 1;

type TErrors<T> = Partial<Record<keyof T, string>>;
const hasErrors = <T extends Partial<Record<string, string>>>(errors: T) => Object.values(errors).some(v => v);

type TBasicInfoErrors = TErrors<TBasicInfoValue>;
type TInternalDetailsErrors = TErrors<TInternalDetailsValue>;
type TTagsErrors = TErrors<{ count?: string }>;

type State = {
    stepIdx: number;

    playbook?: BizlyAPI.Complete.Playbook;
    id?: number;

    basicInfo: TBasicInfoValue;
    basicInfoErrors: TBasicInfoErrors & TInternalDetailsErrors & TTagsErrors;

    internalDetails: TInternalDetailsValue;
    internalDetailsErrors: TInternalDetailsErrors;

    tags: TTagsValue;
    tagsErrors: TTagsErrors;

    venueFilters: TVenueFiltersValue;

    parcelLoadKey: number;

    inquiry: Partial<BizlyAPI.Inquiry>;
    inquirySaved: boolean;
    savingInquiry: boolean;
    stepAfterInquirySave: ESteps | null;

    hasSaved?: boolean;

    creating?: boolean;
    loading?: boolean;
    loaded?: boolean;
    saving?: boolean;
};
type Store = State;

const initialState: State = {
    stepIdx: 0,

    basicInfo: {},
    basicInfoErrors: {},

    internalDetails: {},
    internalDetailsErrors: {},

    tags: [],

    tagsErrors: {},

    venueFilters: {
        grades: [0, 2],
        radius: 15 as Distance.Mile,
        radiusKm: miToKm(15 as Distance.Mile),
    },

    parcelLoadKey: 1,

    inquiry: {},
    inquirySaved: true,
    savingInquiry: false,
    stepAfterInquirySave: null,

    creating: false,
    loading: false,
    loaded: false,
    hasSaved: true,
};

export const [useCreatePlaybook, createPlaybookApi] = createStore<Store>(() => initialState);

const { setState, getState } = createPlaybookApi;

export const selCurStep = (state: State) => stepList[state.stepIdx];
export const selNextStep = (state: State) => stepList[state.stepIdx + 1];

const basicFormActions = {
    setBasicForm: (basicInfo: TBasicInfoValue) => {
        setState({ basicInfo, basicInfoErrors: {} });
    },
    setBasicFormErrors: (basicInfoErrors: TBasicInfoErrors) => {
        setState({ basicInfoErrors, hasSaved: false });
    },
    validateBasicForm: () => {
        const basicInfoErrors: TBasicInfoErrors = {};
        const data = getState().basicInfo;

        if (isEmptyString(data.name)) basicInfoErrors.name = 'Playbook name is required';

        setState({ basicInfoErrors });
    },
};

const internalFormActions = {
    setInternalForm: (internalDetails: TInternalDetailsValue) => {
        setState({ internalDetails, internalDetailsErrors: {} });
    },
    setInternalFormErrors: (internalDetailsErrors: TInternalDetailsErrors) => {
        setState({ internalDetailsErrors, hasSaved: false });
    },
    validateInternalForm: () => {},
};

const tagsFormActions = {
    updateTags: (updatedTags: { value: { tags: TTagsValue } }) => {
        setState({ tags: updatedTags.value.tags });
    },
    setTagsErrors: (tagsErrors: TTagsErrors) => {
        setState({ tagsErrors, hasSaved: false });
    },
    validateTags: () => {},
};

const venueFiltersFormActions = {
    updateVenueFilters: (updatedFilters: { value: TVenueFiltersValue }) => {
        setState({
            venueFilters: {
                ...getState().venueFilters,
                ...updatedFilters.value,
            },
            hasSaved: false,
        });
    },
    setVenueFiltersErrors: () => {},
};

const inquiryFormActions = {
    setInquiry: (data: Partial<BizlyAPI.Inquiry>) => {
        setState({ inquiry: data, inquirySaved: false });
    },
    saveInquiry: async (stepAfter?: ESteps) => {
        setState({
            savingInquiry: true,
            ...(stepAfter !== undefined ? { stepAfterInquirySave: stepAfter } : {}),
        });
        const playbookId = getState().playbook?.id;
        if (playbookId) await currentInquiryActions.createOrUpdateDraft(playbookId, getState().inquiry);
    },
    savedInquiry: () => {
        setState({ savingInquiry: false });
        const { stepAfterInquirySave } = getState();
        if (stepAfterInquirySave !== undefined && stepAfterInquirySave !== null) {
            setState({
                stepAfterInquirySave: null,
            });
            navActions.goToStep(stepAfterInquirySave);
        }
    },
    needSaveInquiry: (hasSaved: boolean) => {
        setState({ inquirySaved: hasSaved });
    },

    validateInquiry: () => {},
};

const communicationFormActions = {
    triggerParcelsLoad: () => setState({ parcelLoadKey: getState().parcelLoadKey + 1 }),
};

export class ValidationError extends Error {}

const stepToValidator = {
    [ESteps.basic]: basicFormActions.validateBasicForm,
    [ESteps.venues]: () => {},
    [ESteps.inquiry]: () => {},
    [ESteps.communication]: () => {},
    [ESteps.collaboration]: () => {},
    [ESteps.preview]: () => {},
};

const selectErrors = (step: ESteps) => {
    const stepToError = {
        [ESteps.basic]: getState().basicInfoErrors,
        [ESteps.venues]: {},
        [ESteps.inquiry]: {},
        [ESteps.communication]: {},
        [ESteps.collaboration]: {},
        [ESteps.preview]: {},
    };

    return stepToError[step];
};

const navActions = {
    prevStep: () => {
        setState({ stepIdx: Math.max(getState().stepIdx - 1, 0) });
    },
    nextStep: () => {
        const curStep = selCurStep(getState());
        stepToValidator[curStep]();
        if (!hasErrors(selectErrors(curStep))) {
            setState({ stepIdx: Math.min(getState().stepIdx + 1, maxStep) });
        }
    },
    goToStep: (toStep: ESteps) => {
        setState({ stepIdx: stepList.findIndex(step => step === toStep) });
    },
    reset: () => setState(initialState),
};

export const createPlaybookActions = {
    ...navActions,
    ...basicFormActions,
    ...internalFormActions,
    ...tagsFormActions,
    ...venueFiltersFormActions,
    ...inquiryFormActions,
    ...communicationFormActions,

    load: async (id: number | string) => {
        if (getState().loading) {
            return;
        }
        setState({
            loading: true,
            loaded: false,
        });

        try {
            const playbook = await playbooksActions.loadSingle(id);

            if (!playbook) {
                setState({
                    loading: false,
                    loaded: false,
                });
                return;
            }

            const basicInfo = {
                name: playbook.name,
                imageUrl: playbook.imageUrl ?? '',
                description: playbook.description ?? '',
            };

            const internalDetails: TInternalDetailsValue = {
                costCenter: playbook.costCenter,
                budget: playbook.budget,
                cventId: playbook.internalId,
                type: playbook.meetingType,
            };

            const tags = playbook.tags?.map(({ id }) => id) ?? getState().tags;
            const { radius, ...searchParams } = playbook.venueSearchParameters ?? {};

            const venueFilters: TVenueFiltersValue =
                Object.values(playbook.venueSearchParameters ?? {}).length === 0
                    ? defaultSearchParams
                    : {
                          ...searchParams,
                          ...(radius ? { radius: toMiles(radius), radiusKm: mToKm(radius) } : {}),
                      };

            setState({
                playbook,
                id: playbook.id,
                basicInfo,
                internalDetails,
                tags,
                venueFilters: {
                    ...venueFilters,
                    place: { location: playbook.location, googlePlaceId: playbook.googlePlaceId },
                },
                loading: false,
                loaded: true,
            });

            return playbook;
        } catch (e) {
            setState({
                loading: false,
            });
            throw e;
        }
    },

    create: async () => {
        if (getState().creating) return;

        stepList.forEach(step => stepToValidator[step]());
        stepList.forEach(step => {
            if (Object.values(selectErrors(step)).some(v => v)) {
                navActions.goToStep(step);
                throw new ValidationError();
            }
        });

        const curState = getState();
        setState({ creating: true });
        try {
            const tags = curState.tags.map(id => ({ id }));
            const { playbook } = await createPlaybook({
                ...curState.basicInfo,
                tags,
            });
            playbooksActions.merge(playbook);

            return playbook;
        } catch (e) {
            throw e;
        } finally {
            setState({ creating: false });
        }
    },

    update: async (nextStep?: ESteps) => {
        if (getState().creating) return;
        if (getState().saving) return;
        const { id } = getState();
        if (!id) return;

        stepToValidator[stepList[getState().stepIdx]]();

        try {
            const { basicInfo, internalDetails, tags, venueFilters, inquirySaved } = getState();

            const { costCenter, type, budget, cventId } = internalDetails;

            const playbookInternalDetails = {
                costCenter,
                budget,
                meetingType: type,
                internalId: cventId,
            };

            const data = {
                ...basicInfo,
                ...playbookInternalDetails,
                tagIds: tags,
                ...(venueFilters.place ? venueFilters.place : { location: null, googlePlaceId: null }),
                venueSearchParameters: {
                    ...venueFilters,
                    radius: venueFilters.radius ? toMeters(venueFilters.radius) : undefined,
                },
            };

            setState({ saving: true });

            const updatedPlaybook = await updatePlaybook(id, data);

            playbooksActions.merge(updatedPlaybook.playbook);

            setState({ saving: false, hasSaved: true });

            if (selCurStep(getState()) === ESteps.inquiry && !inquirySaved) {
                return await inquiryFormActions.saveInquiry(nextStep);
            } else if (nextStep !== undefined) navActions.goToStep(nextStep);
        } catch (e) {
            setState({ saving: false });
            throw e;
        }
    },
};
