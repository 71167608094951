import React from 'react';
import styled from 'styled-components';

import { useHistory, useParams, useRouteMatch } from 'react-router';
import { Switch, Route } from 'react-router-dom';

import { createPlaybookActions, useCreatePlaybook, ESteps, selCurStep, stepToTitle } from './store';
import { LoadPlaybookOptions } from 'stores/playbooks';
import LoadPlaybook from './store/LoadPlaybook';

import { Column, Spacer } from 'ui';
import Header from './components/Header';
import { H1Headline } from 'components/ui/Headline';
import { SpinnerOverlay } from 'components/Spinner';

import { PageNotFound } from 'pages/PageNotFound';
import BasicInfoForm from './BasicInfoForm';
import VenueFilters from './VenueFilters';
import InquiryForm from './InquiryForm';
import CommunicationForm from './CommunicationForm';
import Collaboration from './Collaboration';
import Preview from './Preview';
import useUnsavedPrompt from 'hooks/useUnsavedPrompt';

const Sticky = styled.div`
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
`;

const Container = styled(Column)`
    padding: 0 94px;
`;

const stepToForm = {
    [ESteps.basic]: <BasicInfoForm />,
    [ESteps.venues]: <VenueFilters />,
    [ESteps.inquiry]: <InquiryForm />,
    [ESteps.communication]: <CommunicationForm />,
    [ESteps.collaboration]: <Collaboration />,
    [ESteps.preview]: <Preview />,
};

const stepToWidth = {
    [ESteps.basic]: 640,
    [ESteps.venues]: 640,
    [ESteps.inquiry]: '100%',
    [ESteps.communication]: '100%',
    [ESteps.collaboration]: 640,
    [ESteps.preview]: '100%',
};

const GoTo = ({ step }: { step: ESteps }) => {
    React.useEffect(() => {
        createPlaybookActions.goToStep(step);
    }, [step]);

    return null;
};

export default function PlaybookWizard() {
    React.useEffect(() => createPlaybookActions.reset, []);
    const history = useHistory();
    const playbookWizardPath = useRouteMatch().path;

    const curStep = useCreatePlaybook(selCurStep);
    const { loading, saving, savingInquiry, hasSaved } = useCreatePlaybook();

    const { playbookId } = useParams<{ playbookId?: string }>();
    const renderUnsavedPrompt = useUnsavedPrompt(
        !hasSaved,
        newLocation => newLocation.pathname === history.location.pathname
    );

    if (!playbookId) {
        return <PageNotFound />;
    }

    return (
        <>
            <LoadPlaybook id={playbookId} />
            <LoadPlaybookOptions />

            {renderUnsavedPrompt()}

            <Sticky>
                <Header playbookId={playbookId} />
            </Sticky>
            <Container fillWidth>
                <Spacer larger />
                <H1Headline>{stepToTitle[curStep]}</H1Headline>
                <Spacer medium />
                <Column style={{ width: stepToWidth[curStep] }}>{stepToForm[curStep]}</Column>
                <Switch>
                    <Route
                        path={[
                            playbookWizardPath + '/communication/create',
                            playbookWizardPath + '/communication/edit',
                        ]}
                    >
                        <GoTo step={ESteps.communication} />
                    </Route>
                    <Route path={playbookWizardPath + '/communication/:type'}>
                        <GoTo step={ESteps.preview} />
                    </Route>
                </Switch>
                {(loading || saving || savingInquiry) && <SpinnerOverlay delay={1} />}
            </Container>
        </>
    );
}
