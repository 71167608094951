import React, { useState } from 'react';
import styled from 'styled-components';

import useShowModal from 'hooks/useShowModal';

import { eventTemplatesActions } from 'stores/eventTemplates';
import { isBlankTemplateId } from 'api/eventTemplates';
import { useUser } from 'providers/user';

import { Column, Row, Copy } from 'ui';
import UIMenuSelect from 'components/ui/MenuSelect';
import EventCreateModal from 'components/EventCreateModal';
import SmallCircleButton from 'components/ui/SmallCircleButton';
import ConfirmationModal from 'components/ConfirmationModal';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import colorFns from 'colorFns';

import { ESECTION } from './utils';

export const CARD_WIDTH = 300;

const Container = styled(Column)`
    position: relative;

    width: ${CARD_WIDTH}px;
    height: 318px;
    box-sizing: border-box;
    overflow: hidden;

    ${props => (props.onClick ? `cursor: pointer;` : ``)}

    border-radius: 8px;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
`;

const PAD_VERTICAL = 24,
    PAD_HORIZONTAL = 16;

const TRANSPARENT = colorFns.pureBlack.alpha(0),
    SOFT_BLACK = colorFns.pureBlack.alpha(0.7);

const HoverOverlay = styled(Column)`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: ${PAD_VERTICAL}px ${PAD_HORIZONTAL}px;

    justify-content: flex-end;

    background-image: linear-gradient(to bottom, ${TRANSPARENT} -47%, ${SOFT_BLACK});

    color: ${colorFns.pureWhite};

    opacity: 0;
    ${Container}:hover & {
        opacity: 1;
    }
    transition: opacity ease 0.3s;
`;

const Top = styled(Row)<{ disabled?: boolean; imgSrc?: string; backgroundColor?: Themed.Color }>`
    position: relative;
    height: 218px;
    max-height: 218px;
    min-height: 218px;

    background-color: ${({ backgroundColor, theme: { getColor, EColors } }) =>
        backgroundColor || getColor(EColors.strongAccentedBackground)};

    ${({ disabled }) => (disabled ? `` : `cursor: pointer;`)}

    ${({ imgSrc }) =>
        imgSrc
            ? `
        background-image: url(${imgSrc});

        background-size: cover;
        background-position: center center;
        `
            : ``};
`;

const MenuSelect = styled(UIMenuSelect)``;

const Bottom = styled(Row)`
    height: 100%;
    width: auto;
    padding: ${PAD_VERTICAL}px ${PAD_HORIZONTAL}px;
    justify-content: space-between;
    background-color: ${colorFns.pureWhite};

    ${MenuSelect} {
        position: absolute;
        right: 4px;
        bottom: 30px;
    }
`;

const CenteredButton = styled(SmallCircleButton)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    height: 52px;
    width: 52px;

    margin: auto;

    pointer-events: none;

    svg {
        height: auto;
        width: 30px;
    }
`;

const { PERSONAL, SHARED } = ESECTION;

type TExistingTemplate = Omit<Bizly.EventTemplate, 'id'> & { id: number };
type TNewTemplate = Omit<Bizly.EventTemplate, 'id'> & { id?: undefined };
type TTemplateCard = (
    | { template: TExistingTemplate; children?: undefined }
    | { template: TNewTemplate; children?: JSX.Element }
) & { disabled?: boolean; hideMenu?: boolean };

const TemplateCard = ({ template, disabled, hideMenu, children }: TTemplateCard) => {
    const { modalShown: createModalShown, showModal: showCreateModal, hideModal: hideCreateModal } = useShowModal();
    const [shareIntent, setShareIntent] = useState<boolean>(false);
    const [deleteIntent, setDeleteIntent] = useState<boolean>(false);

    const { isTeamAdmin } = useUser();

    const onClickCard = showCreateModal;

    const isBlankTemplate = isBlankTemplateId(template.id);
    const isPersonalTemplate = template.category === PERSONAL;
    const isSharedTemplate = template.category === SHARED;

    const handleShareTemplate = () => {
        setShareIntent(false);
        return template.id && eventTemplatesActions.share(template.id);
    };

    const handleDeleteTemplate = async () => {
        setDeleteIntent(false);
        return template.id && eventTemplatesActions.delete(template.id);
    };

    const shareTemplateAction = {
        label: 'Share',
        onClick: () => setShareIntent(true),
    };
    const deleteTemplateAction = {
        label: 'Delete',
        onClick: () => setDeleteIntent(true),
    };
    const menuOptions = [
        ...(isTeamAdmin && isPersonalTemplate ? [shareTemplateAction] : []),
        ...(isPersonalTemplate || (isTeamAdmin && isSharedTemplate) ? [deleteTemplateAction] : []),
    ];

    const IMAGE_TRANSFORM = 'h_400,q_auto:best';

    return (
        <>
            <Container>
                <Top
                    imgSrc={template.briefImageSmall?.replace('image/upload/', `image/upload/${IMAGE_TRANSFORM}/`)}
                    disabled={disabled}
                    {...(disabled ? {} : { onClick: onClickCard })}
                >
                    {!template.briefImageSmall && children}
                    {isBlankTemplate && (
                        <CenteredButton>
                            <PlusIcon />
                        </CenteredButton>
                    )}
                    {!isBlankTemplate && template.shortDescription && (
                        <HoverOverlay>
                            <Copy>{template.shortDescription}</Copy>
                        </HoverOverlay>
                    )}
                </Top>
                <Bottom>
                    <Copy>{template.name}</Copy>
                    {!(disabled || hideMenu) && template.id && !!menuOptions.length && (
                        <MenuSelect options={menuOptions} />
                    )}
                </Bottom>
            </Container>

            <ConfirmationModal
                headline="Share Playbook with Team"
                onDismiss={() => setShareIntent(false)}
                onProceed={handleShareTemplate}
                prompt="By sharing a playbook, all members of your team will have access to it when creating a future meeting."
                isActive={shareIntent}
            />
            <ConfirmationModal
                warning
                headline="Delete Playbook"
                onDismiss={() => setDeleteIntent(false)}
                onProceed={handleDeleteTemplate}
                prompt="By deleting this playbook you will be removing it from your Playbooks. If it is shared with a team, they will no longer have access to it as well."
                isActive={deleteIntent}
            />
            {createModalShown && <EventCreateModal {...(template.id ? { template } : {})} onClose={hideCreateModal} />}
        </>
    );
};

export default TemplateCard;
