import React from 'react';
import styled from 'styled-components/macro';

import { Column, Copy, Row, Spacer } from 'ui';
import { H2Headline as UIH2Headline } from 'components/ui/Headline';
import TextButton from 'components/ui/Button/TextButton';
import { SpinnerOverlay } from 'components/Spinner';

import Dialog from '@material-ui/core/Dialog';
import fontFns from 'fontFns';

const Modal = styled(Column)`
    width: 400px;
    padding: 24px;
`;

const H2Headline = styled(UIH2Headline)<{ warning?: boolean }>`
    color: ${({ theme: { getColor, EColors }, warning }) =>
        warning ? getColor(EColors.warningText) : getColor(EColors.accentedHeadline)};
    text-transform: none;
    ${fontFns.heading}
`;

const EndRow = styled(Row)`
    justify-content: flex-end;
`;

type TConfirmationModal = {
    isActive?: boolean;
    warning?: boolean;

    onDismiss: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>,
        reason?: 'backdropClick' | 'escapeKeyDown'
    ) => any;
    onProceed?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;

    headline?: React.ReactNode;
    prompt?: React.ReactNode;
    ctaLabel?: React.ReactNode;
    dismissLabel?: React.ReactNode;

    loading?: boolean;
};

export const ConfirmationModal = ({
    isActive,
    warning,
    onDismiss,
    onProceed,
    headline = warning ? 'Are you sure?' : null,
    prompt,
    ctaLabel = warning ? 'Delete' : 'Continue',
    dismissLabel = 'Cancel',
    loading,
}: TConfirmationModal) => (
    <Dialog open={!!isActive} onClose={onDismiss}>
        <Modal>
            {headline && (
                <>
                    <H2Headline warning={warning}>{headline}</H2Headline>
                    <Spacer />
                </>
            )}
            {prompt && (
                <>
                    <Copy>{prompt}</Copy>
                    <Spacer larger />
                </>
            )}
            <EndRow itemSpacing="smallish">
                <TextButton secondary onClick={onDismiss}>
                    {dismissLabel}
                </TextButton>
                {onProceed && (
                    <TextButton warning={warning} onClick={onProceed}>
                        {ctaLabel}
                    </TextButton>
                )}
            </EndRow>
            {loading && <SpinnerOverlay />}
        </Modal>
    </Dialog>
);

export default ConfirmationModal;
