import React, { useCallback } from 'react';

import { Column, Row, Spacer } from '../ui';
import { H2Headline } from './ui/Headline';
import VenueTile, { VenueTileEmpty } from './VenueTile/VenueTile';
import VenueSearchButton from './VenueSearchButton';

import { useAsync } from '../hooks/useAsync';
import VenueSearchFetcher from '../api/VenueSearchFetcher';

import Carousel from './Carousel';

const LoadingVenues = () =>
    new Array(10)
        .fill('')
        .map((v, idx) => <VenueTileEmpty fixedRatio="calc((160 / 208) * 100%)" width="100%" key={idx} />);

export type TFacet = {
    label: string;
    facets: BizlyAPI.VenueFacets;
};

type TVenueFacet = {
    eventId: number;
    onSelect?: (selected: Bizly.Venue) => void;
    onDeselect?: (selected: Bizly.Venue) => void;
    bottomSpacing?: boolean;
    selectedVenues: { [id: number]: Bizly.Venue };
    viewVenueListing: (venueId: number) => void;
    isCreateMeeting?: boolean;
} & TFacet;

export const VenueFacet = ({
    facets,
    label,
    eventId,
    onSelect,
    onDeselect,
    bottomSpacing,
    viewVenueListing,
    selectedVenues,
    isCreateMeeting,
}: TVenueFacet) => {
    const search = () =>
        new VenueSearchFetcher(eventId, {
            facets,
            perPage: 20,
        }).getNextPage() as Promise<Bizly.Venue[]>;
    const { data } = useAsync(useCallback(search, []));

    if (data && data.length === 0) {
        return null;
    }

    return (
        <Column style={{ width: '100%' }}>
            <H2Headline>{`${label} for Your Meeting`}</H2Headline>
            <Spacer />
            <Carousel perRow={5} spacing={24}>
                {data === undefined
                    ? LoadingVenues()
                    : data.map(v => (
                          <VenueTile
                              key={v.id}
                              venue={v}
                              format="tile"
                              pillType="preferenceCategory"
                              fixedRatio="calc((160 / 208) * 100%)"
                              width="100%"
                              selected={!!selectedVenues[v.id]}
                              onSelect={onSelect ? () => onSelect(v) : undefined}
                              onDeselect={onDeselect ? () => onDeselect(v) : undefined}
                              onClick={() => viewVenueListing(v.id)}
                          />
                      ))}
            </Carousel>

            <Spacer />
            <Row>
                <VenueSearchButton
                    label={`View all ${label}`}
                    eventId={eventId}
                    facets={facets}
                    isCreateMeeting={isCreateMeeting}
                />
            </Row>
            {bottomSpacing && <Spacer large />}
        </Column>
    );
};
