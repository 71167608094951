import React, { useState, useMemo, useEffect } from 'react';

import styled from 'styled-components/macro';
import { kmToMi, miToKm } from 'utils';

import { useUser } from 'providers/user';
import { usePlaybooks } from 'stores/playbooks';
import { useEvent } from 'providers/event';
import { units, useMeasurementUnits } from 'stores/measurement-units';

import { Column, Row, Copy, Spacer } from 'ui';
import Popover from '@material-ui/core/Popover';
import TextButton from 'components/ui/Button/TextButton';
import Form from 'components/Form';

import { fields, schema } from './filtersSchema';

import { EColors } from 'theme';

const Pop = styled(Column)`
    min-height: 538px;
    width: 574px;

    box-sizing: border-box;
    padding: 32px;
`;

const errors = {};

export type TFilterValueBase = {
    grades: [number, number];
    hideUngraded: boolean;
    types: BizlyAPI.Venue.Types[];
    decors: BizlyAPI.Venue.Decors[];
    preferredOnly: boolean;
    radius: Distance.Mile;
    radiusKm: Distance.Kilometer;
    brandIds: number[];
};
export type TFilterValue = Partial<TFilterValueBase>;

export type TFilterValueInitial = TFilterValueBase & Omit<TFilterValue, 'grades' | 'types'>;

type TProps = {
    filters: TFilterValue;
    onApply: (filterVal: TFilterValue) => void;
    anchor: any;
    open: boolean;
    onClose: () => void;
    teamName: string;
};
const baseFormProps = {
    isNested: undefined,
    readonly: undefined,
    densePadding: undefined,
};
export default function VenueSearchFilters({ filters: filtersProp, onApply, anchor, open, onClose, teamName }: TProps) {
    const { user } = useUser();
    const { playbookOptions: { venueBrands = [] } = {} } = usePlaybooks();
    const { event } = useEvent();
    const brandRestrictions = useMemo(
        () => (event.venueSearchParameters?.brandIds ? new Set(event.venueSearchParameters.brandIds) : undefined),
        [event.venueSearchParameters]
    );
    const brands = useMemo(
        () => (brandRestrictions ? venueBrands.filter(({ id }) => brandRestrictions.has(id)) : venueBrands),
        [venueBrands, brandRestrictions]
    );
    const computedFilters = useMemo(
        () => ({
            ...filtersProp,
            // to be clear, these are the default search params:
            grades: filtersProp.grades || [0, 2],
            radius: filtersProp.radius || (15 as Distance.Mile),
            radiusKm: filtersProp.radiusKm || miToKm(15 as Distance.Mile),
        }),
        [filtersProp]
    );
    const [filters, setFilters] = useState(computedFilters);
    useEffect(() => {
        setFilters(computedFilters);
    }, [computedFilters]);
    const fieldsWithTeam = useMemo(() => fields(teamName, brands, { disabledBrands: !!brandRestrictions }), [
        teamName,
        brands,
        brandRestrictions,
    ]);
    const handleEventChange = ({
        field,
        value,
    }: {
        field: keyof typeof fieldsWithTeam;
        value: TFilterValueInitial;
    }) => {
        const newVal = { ...value };

        if (field === 'radius') {
            newVal.radiusKm = miToKm(newVal.radius);
        }

        if (field === 'radiusKm') {
            newVal.radius = kmToMi(newVal.radiusKm);
        }

        setFilters(newVal);
    };
    const { distance: distanceUnit } = useMeasurementUnits();

    return (
        <Popover
            anchorEl={anchor}
            open={open}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transitionDuration={150}
            onClose={onClose}
        >
            <Pop>
                <Copy $color={EColors.darkGrey}>Filter by</Copy>
                <Spacer />
                <Form
                    fields={fieldsWithTeam}
                    schema={schema(
                        distanceUnit === units.meter ? units.kilometer : units.mile,
                        user.featureFlags?.createPlaybooksFlow
                    )}
                    value={filters}
                    errors={errors}
                    onChange={handleEventChange}
                    {...baseFormProps}
                />
                <Spacer larger />
                <Row style={{ justifyContent: 'flex-end' }}>
                    <TextButton secondary onClick={onClose}>
                        Cancel
                    </TextButton>
                    <Spacer col smallish />
                    <TextButton onClick={() => onApply(filters)}>Apply</TextButton>
                </Row>
            </Pop>
        </Popover>
    );
}
