import React from 'react';
import { useSnackbar } from 'notistack';

import { playbooksActions } from 'stores/playbooks';

export default function LoadPlaybookOptions() {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const load = async () => {
            try {
                await playbooksActions.loadPlaybookOptions();
            } catch (e) {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            }
        };
        load();
    }, [enqueueSnackbar]);

    return null;
}
