import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { groupBy } from 'lodash';
import { useSnackbar } from 'notistack';
import moment from 'moment';

import { parseName, randomSelect } from '../util';
import useShowModal from 'hooks/useShowModal';
import { useUser } from 'providers/user';
import { useEventTemplates } from 'stores/eventTemplates';
import { getEvents } from 'api';
import { isBlankTemplateId } from 'api/eventTemplates';

import { Row, Copy, Spacer, Column } from 'ui';
import { LargeHeadline as BaseLargeHeadline, H2Headline } from 'components/ui/Headline';
import Button from 'components/ui/Button';
import { SpinnerOverlay } from 'components/Spinner';
import SideNav from 'components/SideNav';
import HomeScreenBanner from 'components/Home/HomeScreenBanner';
import HealthAndSafetyInfo from 'components/HealthAndSafetyInfo';
import PagedTemplates from 'components/Templates/PagedTemplates';

import * as copy from 'copy/home.json';
import EventCreateModal, { useCreateEventModalUrlValues } from 'components/EventCreateModal';

const LargeHeadline = styled(BaseLargeHeadline)`
    margin: 0;
`;

const MAX_TEMPLATES = 6;

const sortByMostRecent = (templates: Bizly.EventTemplate[]) =>
    templates.slice().sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1));

export default function Home() {
    const [activeEvents, setActiveEvents] = useState<Bizly.Event[]>([]);
    const [homeTemplates, setHomeTemplates] = useState<Bizly.EventTemplate[]>([]);
    const [loadingEvents, setLoadingEvents] = useState(false);

    const { user } = useUser();
    const { templates, loading: loadingTemplates } = useEventTemplates();
    const { enqueueSnackbar } = useSnackbar();

    const loadActiveEvents = async () => {
        const now = Date.now();
        const { events: allEvents } = await getEvents();
        return setActiveEvents(allEvents.filter(({ endsAt }) => endsAt && parseISO(endsAt).getTime() >= now));
    };

    const arrangeTemplates = (allTemplates: Bizly.EventTemplate[]) => {
        const { global = [] } = groupBy(sortByMostRecent(allTemplates), 'category');

        const blankTemplate = global.find(({ id }) => isBlankTemplateId(id));
        const globalTempsWithoutBlank = global.filter(({ id }) => !isBlankTemplateId(id));

        return setHomeTemplates(
            [...(blankTemplate ? [blankTemplate] : []), ...globalTempsWithoutBlank].slice(0, MAX_TEMPLATES)
        );
    };

    useEffect(() => arrangeTemplates(templates), [templates]);

    useEffect(() => {
        (async () => {
            setLoadingEvents(true);
            try {
                await loadActiveEvents();
            } catch (e) {
                enqueueSnackbar('Something went wrong. Please refresh the page and try again.', { variant: 'error' });
            } finally {
                setLoadingEvents(false);
            }
        })();
    }, [enqueueSnackbar]);

    const parsedHeading = useMemo(() => {
        const { noEventsCopy, noEventsNoNameCopy } = copy.heading;
        let selectedCopy;

        if (activeEvents.length) {
            selectedCopy = randomSelect(copy.heading.hasEventsCopy);
        } else {
            selectedCopy = user.firstName ? noEventsCopy : noEventsNoNameCopy;
        }

        return parseName(selectedCopy, user.firstName || undefined);
    }, [activeEvents.length, user.firstName]);

    const parsedEventsSubheading = useMemo(() => {
        const upcomingEvent = activeEvents[0];
        const { hasEventsCopy, noUpcomingEventsCopy, noEventsCopy } = copy.subheading;
        let selectedCopy;

        if (upcomingEvent?.startsAt) {
            selectedCopy = hasEventsCopy
                .replace('%C', activeEvents.length.toString())
                .replace('%N', upcomingEvent.name || '')
                .replace('%D', format(parseISO(upcomingEvent.startsAt), 'MMMM do') || '');
        } else {
            selectedCopy = activeEvents.length ? noUpcomingEventsCopy : noEventsCopy;
        }

        return selectedCopy;
    }, [activeEvents]);

    const hasHealthSafetyPolicies = useMemo(() => {
        const healthSafetyCategories = user?.team?.healthAndSafety?.categories || [];
        for (let key in healthSafetyCategories) {
            const healthSafetyCategory = healthSafetyCategories[key];
            if (healthSafetyCategory.policies.length > 0) {
                return true;
            }
        }
        return false;
    }, [user]);

    const urlCreateFormValues = useCreateEventModalUrlValues();
    const { modalShown: hasUrlCreateFormValues, hideModal } = useShowModal(!!urlCreateFormValues);

    return (
        <SideNav>
            <Spacer smallish />

            {!loadingEvents && (
                <>
                    <LargeHeadline>{parsedHeading}</LargeHeadline>
                    <Spacer default />
                    <Copy>{parsedEventsSubheading}</Copy>
                </>
            )}
            <Spacer largest />

            <Column itemSpacing="small">
                <Row justifyContent="space-between">
                    <H2Headline> What kind of meeting can we help you plan?</H2Headline>
                    <Link to="/playbooks">
                        <Button width="auto" secondary>
                            View all Playbooks
                        </Button>
                    </Link>
                </Row>

                <PagedTemplates templates={homeTemplates} hideMenu perRow={3} />

                {hasHealthSafetyPolicies && <HealthAndSafetyInfo />}
            </Column>

            {user.team?.bulletinMessage && (
                <>
                    <Spacer larger />
                    <HomeScreenBanner message={user.team.bulletinMessage} teamImage={user.team.imageUrlWhite} />
                </>
            )}

            {hasUrlCreateFormValues && <EventCreateModal defaultFormValues={urlCreateFormValues} onClose={hideModal} />}

            {(loadingEvents || loadingTemplates) && <SpinnerOverlay fixedSpinner />}
        </SideNav>
    );
}
