import React from 'react';

import styled from 'styled-components';

import { TruncatingSingleLineCopy, Copy, Row, Column, Spacer, InlineRow } from 'ui';

import { SelectField } from 'components/FormFields';
import { FittedSquareImage } from 'components/ImageUploader';
import Button from 'components/ui/Button';
import UnitField from './UnitsField';

import colorFns from 'colorFns';
import fontFns from 'fontFns';

import { spacesToOptions, TSpace } from './utils';
import { measurementUnitsActions, useMeasurementUnits } from 'stores/measurement-units';
import { sqftToSqm } from 'utils/units';

const FittedSingleLine = styled(TruncatingSingleLineCopy)`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    width: 0;
    min-width: 0;
`;

const FlexColumn = styled(Column)`
    flex-grow: 1;
`;

const CenteredRow = styled(Row)`
    align-items: center;
`;

const SelectFieldWrap = styled(Column)`
    width: 240px;
`;

const ButtonWrap = styled.div`
    margin: 8px 0 4px 0;
`;

const FormLabel = styled.b`
    color: ${colorFns.formLabel};
    ${fontFns.formLabel}
`;

export default function VenueSpacesSelectField({
    field,
    value,
    onChange,
    spaces,
    onEdit,
    disabled,
}: {
    field: string;
    value?: TSpace['id'];
    onChange: (update: { field: string; value: TSpace['id']; error?: {} | null }) => void;
    spaces: TSpace[];
    onEdit: () => void;
    disabled?: boolean;
}) {
    const { area } = useMeasurementUnits();

    const selectedOption = spaces.find((space: TSpace) => space.id === value);
    const size = area === 'sq. m' ? sqftToSqm(selectedOption?.size) : selectedOption?.size;

    const selectField = (
        <SelectFieldWrap>
            <SelectField
                field={field}
                value={value}
                options={spacesToOptions(spaces, true)}
                onChange={onChange}
                readonly={false}
                disabled={disabled}
                placeholder="Select a space..."
            />
        </SelectFieldWrap>
    );

    return selectedOption ? (
        <CenteredRow itemSpacing="default">
            <FittedSquareImage width={140} src={selectedOption?.images[0]?.srcUrl} placeholder="No Image Available" />

            <FlexColumn itemSpacing="small">
                <CenteredRow itemSpacing="small">
                    {selectField}

                    {!disabled && (
                        <ButtonWrap>
                            <Button onClick={onEdit}>Edit Space</Button>
                        </ButtonWrap>
                    )}
                </CenteredRow>

                {selectedOption.description && (
                    <Row>
                        <FittedSingleLine>{selectedOption.description}</FittedSingleLine>
                    </Row>
                )}

                {(size !== null || selectedOption.maxCapacity !== null) && (
                    <Row itemSpacing="large" alignItems="center">
                        <InlineRow alignItems="center">
                            <Copy regular>
                                <FormLabel>size:</FormLabel> {size || 'n/a'}{' '}
                            </Copy>
                            <UnitField
                                field="unit"
                                onChange={({ value }) => {
                                    measurementUnitsActions.save({ area: value });
                                }}
                                type="area"
                                inline
                            />
                        </InlineRow>
                        <Copy regular>
                            <FormLabel>capacity</FormLabel>: {selectedOption.maxCapacity || 'n/a'} people
                        </Copy>
                    </Row>
                )}
                <Spacer xsmall />
            </FlexColumn>
        </CenteredRow>
    ) : (
        selectField
    );
}
