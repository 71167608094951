import React from 'react';
import styled from 'styled-components';

import { useSnackbar } from 'notistack';
import useShowModal from 'hooks/useShowModal';

import { useEvent } from 'providers/event';
import { deleteVirtualMeeting } from 'api';

import { Column, Row, Spacer } from 'ui';
import { Dialog } from '@material-ui/core';
import { IconHeadline } from 'components/ui/Headline';
import { Spinner } from 'components/Spinner';
import ManageVirtualMeeting from 'components/Schedule/ManageVirtualMeeting';
import VirtualMeetingField from 'components/Schedule/VirtualMeetingField';

import { ReactComponent as VideoConferenceIconSVG } from 'images/icons/Video_Conference.svg';
import { withIconStyles } from 'shared';

const VideoConferenceIcon = withIconStyles(VideoConferenceIconSVG);

const RoundedDialog = styled(Dialog)`
    .MuiPaper-root {
        border-radius: 8px;
    }
`;

const VMFieldStretchRow = styled(Row)<{ hasVM?: boolean }>`
    ${({ hasVM }) =>
        hasVM
            ? `
    > * {
        width: 100%;
    }
    
    `
            : ''}
`;

export default function VendorsSection() {
    const { event, refreshEvent } = useEvent();

    const { modalShown, showModal, hideModal } = useShowModal();

    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = React.useState(false);

    async function removeVirtualMeeting() {
        const vmId = event.virtualMeeting?.id;
        if (!vmId) return;

        setLoading(true);
        try {
            await deleteVirtualMeeting(event.id, vmId);

            // refreshEvent must happen before setStaged to not show old data
            await refreshEvent();
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            await refreshEvent();
        } finally {
            setLoading(false);
        }
    }

    return (
        <Column alignItems="flex-start" style={{ width: '100%' }}>
            <IconHeadline
                icon={<VideoConferenceIcon />}
                headline="Virtual Meeting"
                description={
                    event.virtualMeeting
                        ? 'Manage your virtual meeting below.'
                        : 'Add a virtual meeting to make your meeting more inclusive!'
                }
            />
            <Spacer large />

            {loading ? (
                <Spinner />
            ) : (
                <VMFieldStretchRow hasVM={!!event.virtualMeeting}>
                    <VirtualMeetingField
                        value={event.virtualMeeting ?? null}
                        integratedVmServices={[]}
                        onAdd={async () => {
                            showModal();
                        }}
                        onEdit={showModal}
                        onDelete={removeVirtualMeeting}
                        cta="Add Virtual Meeting"
                        regularButtonStyle
                        noIcon
                        noPadding
                    />
                </VMFieldStretchRow>
            )}

            <RoundedDialog open={modalShown} onBackdropClick={hideModal}>
                <ManageVirtualMeeting onSave={() => {}} onClose={hideModal} asModalContent />
            </RoundedDialog>
        </Column>
    );
}
