import React from 'react';
import styled from 'styled-components';

import { Column, Copy as UICopy } from '../../ui';
import { Headline as UIHeadline } from '../../components/ui/Headline';

const Container = styled(Column)``;

const Headline = styled(UIHeadline)`
    margin: 0;
    font-size: 36px;
    letter-spacing: -1.1px;
    text-transform: unset;
`;

const Copy = styled(UICopy)`
    font-size: 18px;
    line-height: 1.5;
    max-width: 466px;
`;

export default function CongratsMessage({ cancelled }: { cancelled?: boolean }) {
    return (
        <Container itemSpacing="small">
            <Headline large>
                {cancelled ? (
                    <>
                        This booking <br />
                        has been cancelled.
                    </>
                ) : (
                    'Congrats!'
                )}
            </Headline>
            {!cancelled && (
                <Copy large>
                    Now that you’ve accepted a proposal, you’ll work directly with the venue to sign a contract, provide
                    payment info and finalize all the details of your meeting.
                </Copy>
            )}
        </Container>
    );
}
