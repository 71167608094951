import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';

import { tzMoment, userTimeZone } from 'utils/moment';
import groupBy from 'lodash/groupBy';
import { useHistory, useLocation } from 'react-router';

import { useUser } from 'providers/user';
import {
    useMeetings,
    isLoadingPage,
    useMeetingsPages,
    selectMeetingIds,
    selectMeetings,
    isLoadingSortedPage,
    useMeetingsPagesSorted,
    selectMeetingIdsSorted,
} from 'stores/meetings';
import LoadMeetingsPage from 'stores/meetings/LoadMeetingsPage';
import LoadMeetingsPageSorted from 'stores/meetings/LoadMeetingsPageSorted';
import SearchMeetings from 'stores/meetings/SearchMeetings';
import {
    useSearchMeetings,
    selectMeetingIds as selectSearchMeetingIds,
    isLoadingPage as isLoadingSearchPage,
} from 'stores/meetings/searchStore';
import LoadMeetingsFilters from 'stores/meetings/LoadMeetingsFilters';

import { useFilters, filtersActions, sortOptions, isFilterOption } from 'stores/meetings/filterStore';

import { Waypoint } from 'react-waypoint';

import SideNav from 'components/SideNav';
import ScheduledList from 'pages/Meetings/ScheduledList';
import UnschedList from 'pages/Meetings/UnschedList';

import { AlignedRow, Column, InlineRow, Spacer } from 'ui';
import { Spinner } from 'components/Spinner';
import { H2Headline, LargeHeadline } from 'components/ui/Headline';
import { Tab, Tabs } from 'components/Tabs';
import MuiTextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import SortedList from 'pages/Meetings/SortedList';
import { SelectField } from 'components/FormFields';

type BasicBlock = { startsAt?: string | null; endsAt?: string | null; timeZone?: string };

const groupMeetingsByDay = <TMeeting extends BasicBlock>(meetings: TMeeting[]) => {
    const withUnixTime = meetings.map(meeting => ({
        ...meeting,
        unixTime: tzMoment(meeting.startsAt, meeting.timeZone).valueOf(),
    }));

    return (Object.entries(
        groupBy(withUnixTime, meeting =>
            tzMoment(meeting.unixTime)
                .tz(userTimeZone)
                .startOf('day')
                .valueOf()
        )
    ) as [string, typeof withUnixTime][]).map(([unixTime, meetings]) => {
        return [tzMoment(parseInt(unixTime)), meetings];
    }) as [moment.Moment, typeof withUnixTime][];
};

const TABS_CONTAINER_STYLES = {
    width: 'auto',
    display: 'inline-block',
    margin: '0 -10px',
};

const TAB_STYLES = {
    padding: 0,
    margin: '0 10px',
};

const List = styled(Column)`
    overflow: auto;
    width: 100%;
    padding: 0 12px;
    margin: 0 -12px;
`;

const TextField = styled(MuiTextField)`
    .MuiSvgIcon-root {
        max-height: 51px;
        height: 51px;
        width: auto;
    }

    .MuiInputBase-input {
        font-size: 51px;
        font-weight: 600;
    }
`;

const SortDropdown = styled(SelectField)`
    width: 200px;
`;

const SearchPage = () => {
    const { user } = useUser();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const { sort: sortParam } = queryString.parse(location.search);
        if (typeof sortParam === 'string' && isFilterOption(sortParam)) filtersActions.setSort(sortParam);
    }, [location.search]);

    const viewEvent = (event: { id: number; published?: boolean }) => {
        if (user.featureFlags?.createMeetingsFlow) return history.push(`/events/${event.id}`);

        return history.push(`/event/${event.id}`);
    };

    const asPublished = user.featureFlags?.createMeetingsFlow;
    const [viewSched, setViewSched] = useState(true);
    const { sort } = useFilters();
    const viewSorted = sort !== 'default';

    const [schedPageUnsorted, setSchedPageUnsorted] = useState(1);
    const [unschedPageUnsorted, setUnschedPageUnsorted] = useState(1);
    const [schedPageSorted, setSchedPageSorted] = useState(1);
    const [unschedPageSorted, setUnschedPageSorted] = useState(1);
    const [searchPage, setSearchPage] = useState(1);

    // scheduled meetings, no sort
    const meetingIdsSchedUnsorted = useMeetingsPages(selectMeetingIds());
    const meetingsSchedListUnsorted = useMeetings(selectMeetings(meetingIdsSchedUnsorted));
    const loadingSchedUnsorted = useMeetingsPages(isLoadingPage(schedPageUnsorted, true));

    // unscheduled meetings, no sort
    const meetingIdsUnschedUnsorted = useMeetingsPages(selectMeetingIds(false));
    const meetingsUnschedListUnsorted = useMeetings(selectMeetings(meetingIdsUnschedUnsorted));
    const loadingUnschedUnsorted = useMeetingsPages(isLoadingPage(unschedPageUnsorted, false));

    // scheduled meetings, sorted
    const meetingIdsSchedSorted = useMeetingsPagesSorted(selectMeetingIdsSorted());
    const meetingsSchedListSorted = useMeetings(selectMeetings(meetingIdsSchedSorted));
    const loadingSchedSorted = useMeetingsPagesSorted(isLoadingSortedPage(schedPageSorted, true));

    // unscheduled meetings, sorted
    const meetingIdsUnschedSorted = useMeetingsPagesSorted(selectMeetingIdsSorted(false));
    const meetingsUnschedListSorted = useMeetings(selectMeetings(meetingIdsUnschedSorted));
    const loadingUnschedSorted = useMeetingsPagesSorted(isLoadingSortedPage(unschedPageSorted, false));

    // search related values
    const searchIds = useSearchMeetings(selectSearchMeetingIds()) ?? [];
    const searchPageLoaded = !!useSearchMeetings(selectSearchMeetingIds(searchPage));
    const meetingsSearch = useMeetings(selectMeetings(searchIds));
    const { lastPage } = useSearchMeetings();
    const [query, setQuery] = useState('');

    const { lastPageSched: lastPageSchedUnsorted, lastPageUnsched: lastPageUnschedUnsorted } = useMeetingsPages();
    const { lastPageSchedSorted, lastPageUnschedSorted } = useMeetingsPagesSorted();

    // extrapolations for sorted or non sorted values
    const lastPageSched = viewSorted ? lastPageSchedSorted : lastPageSchedUnsorted;
    const lastPageUnsched = viewSorted ? lastPageUnschedSorted : lastPageUnschedUnsorted;
    const loadingSched = viewSorted ? loadingSchedSorted : loadingSchedUnsorted;
    const loadingUnsched = viewSorted ? loadingUnschedSorted : loadingUnschedUnsorted;
    const meetingsSchedList = viewSorted ? meetingsSchedListSorted : meetingsSchedListUnsorted;
    const meetingsUnschedList = viewSorted ? meetingsUnschedListSorted : meetingsUnschedListUnsorted;
    const schedPage = viewSorted ? schedPageSorted : schedPageUnsorted;
    const setSchedPage = viewSorted ? setSchedPageSorted : setSchedPageUnsorted;
    const unschedPage = viewSorted ? unschedPageSorted : unschedPageUnsorted;
    const setUnschedPage = viewSorted ? setUnschedPageSorted : setUnschedPageUnsorted;

    const loadingSearch = useSearchMeetings(isLoadingSearchPage(searchPage));
    const loading = query ? loadingSearch : loadingSched || loadingUnsched;

    const meetingsSched = query
        ? meetingsSearch.filter(meeting => (asPublished ? meeting.published : meeting.startsAt))
        : meetingsSchedList;
    const meetingsUnsched = query
        ? meetingsSearch.filter(meeting => (asPublished ? !meeting.published : !meeting.startsAt))
        : meetingsUnschedList;

    const hasMore = query
        ? lastPage !== null && lastPage !== searchPage
        : viewSched
        ? lastPageSched !== schedPage
        : lastPageUnsched !== unschedPage;

    const pullMore = () =>
        query
            ? setSearchPage(searchPage + 1)
            : viewSched
            ? setSchedPage(schedPage + 1)
            : setUnschedPage(unschedPage + 1);

    const waypointKey = query
        ? `search-${searchIds.length}`
        : viewSched
        ? `sched-${meetingsSched.length}`
        : `unsched-${meetingsUnsched.length}`;

    const waypointProps = {
        key: waypointKey,
        onEnter: pullMore,
    };

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery('');
        if (isFilterOption(e.target.value)) {
            filtersActions.setSort(e.target.value);
            history.push(`/search${e.target.value !== 'default' ? `?sort=${e.target.value}` : ''}`);
        }
    };

    return (
        <SideNav fillWidth fillHeight style={{ paddingTop: 24 }}>
            <LoadMeetingsFilters />
            {query && <SearchMeetings query={query} page={searchPage} />}
            {viewSorted ? (
                viewSched ? (
                    <LoadMeetingsPageSorted page={schedPageSorted} scheduled sortBy={sort} />
                ) : (
                    <LoadMeetingsPageSorted page={unschedPageSorted} sortBy={sort} />
                )
            ) : viewSched ? (
                <LoadMeetingsPage page={schedPage} scheduled />
            ) : (
                <LoadMeetingsPage page={unschedPage} />
            )}
            <InlineRow alignItems="center" itemSpacing="smallish">
                <LargeHeadline>
                    <TextField
                        placeholder="Search..."
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={query}
                        onChange={e => {
                            setSearchPage(1);
                            setQuery(e.target.value);
                        }}
                    />
                </LargeHeadline>
                {loading && <Spinner unsetMargin />}
            </InlineRow>
            <Spacer medium />
            <H2Headline>
                <b>Meetings</b>
            </H2Headline>
            <AlignedRow justifyContent="space-between">
                <Tabs value={Number(viewSched)} style={TABS_CONTAINER_STYLES}>
                    <Tab
                        label={
                            (asPublished ? `Meetings` : `Scheduled`) +
                            (query
                                ? ` (${meetingsSched.length || ''}${hasMore || !searchPageLoaded ? '...' : ''})`
                                : '')
                        }
                        value={1}
                        onClick={() => setViewSched(true)}
                        style={TAB_STYLES}
                    />
                    <Tab
                        label={
                            (asPublished ? `Drafts` : `Unscheduled`) +
                            (query
                                ? ` (${meetingsUnsched.length || ''}${hasMore || !searchPageLoaded ? '...' : ''})`
                                : '')
                        }
                        value={0}
                        onClick={() => setViewSched(false)}
                        style={TAB_STYLES}
                    />
                </Tabs>
                <Spacer smallish />
                <SortDropdown
                    field={''}
                    readonly={false}
                    disabled={false}
                    value={sortOptions.find(s => s.value === sort)}
                    placeholder={''}
                    options={sortOptions}
                    onChange={({ value: e }: { value: React.ChangeEvent<HTMLInputElement> }) => handleFilterChange(e)}
                />
            </AlignedRow>
            <Spacer medium />
            {sort === 'default' && (
                <List key={query + '-' + viewSched}>
                    {viewSched ? (
                        <ScheduledList perWeek={groupMeetingsByDay(meetingsSched)} onClick={viewEvent} stickyDates />
                    ) : (
                        <UnschedList meetings={meetingsUnsched} onClick={viewEvent} />
                    )}
                    {!loading && hasMore && <Waypoint {...waypointProps} />}
                </List>
            )}
            {sort === 'updated_at' && (
                <List key={query + '-' + viewSorted + viewSched}>
                    <SortedList meetings={viewSched ? meetingsSched : meetingsUnsched} onClick={viewEvent} />
                    {!loading && hasMore && <Waypoint {...waypointProps} />}
                </List>
            )}
        </SideNav>
    );
};

export default SearchPage;
