import React from 'react';
import styled from 'styled-components';

import { Row, Copy, Column, Card } from 'ui';
import { SpinnerOverlay } from 'components/Spinner';

import { ReactComponent as EditIconSVG } from 'images/icons/pencil.svg';

import { withInteractibleIconStyles } from 'shared';
import colorFns from 'colorFns';

const EditIcon = styled(withInteractibleIconStyles(EditIconSVG)).attrs({ viewBox: '1.5 2.5 18 21.5' })`
    height: 21px;
    width: 21px;
`;

const ListItem = styled(Card)`
    margin: 0 !important;
    padding: 12px;
    cursor: pointer;
    background-color: ${colorFns.listItemBackground};
`;

export default function UnschedList({
    meetings,
    loading,
    onClick,
}: {
    meetings: BizlyAPI.Meeting[];
    loading?: boolean;
    onClick: (meeting: BizlyAPI.Meeting) => void;
}) {
    return (
        <Column itemSpacing="default">
            {meetings.map(event => (
                <div>
                    <ListItem onClick={() => onClick(event)}>
                        <Row itemSpacing="default" alignItems="center" justifyContent="space-between">
                            <Column>
                                <Copy>{event.name || 'Untitled'}</Copy>
                                <Copy small>{[event.createdBy.firstName, event.createdBy.lastName].join(' ')}</Copy>
                            </Column>
                            <Column>
                                <EditIcon />
                            </Column>
                        </Row>
                    </ListItem>
                </div>
            ))}
            {loading && <SpinnerOverlay transparent clickThrough />}
        </Column>
    );
}
