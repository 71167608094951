import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { format, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';

import { useUser } from 'providers/user';
import { useAsync } from 'hooks/useAsync';
import useThemedColor from 'hooks/useThemedColor';
import { getVenueInquiries, loadSuggestedVenuesForEvent } from 'api/venues';
import { meetingsActions, selectMeeting, useMeetings } from 'stores/meetings';

import { Column, CopyFaded } from 'ui';
import Button from 'components/ui/Button';
import { H3Headline } from 'components/ui/Headline';
import { SpinnerOverlay } from 'components/Spinner';
import { VenueTileWithoutActions } from 'components/VenueTile/VenueTile';
import Grid from '@material-ui/core/Grid';

import {
    DEFAULT,
    HAS_BOOKED,
    HAS_INQUIRIES,
    NO_CITY,
    TBookedStatus,
} from 'components/EventDashboard/VenueSection/utils';
import { VenueTiles } from 'components/EventDashboard/VenueSection';
import FindVenueField from 'components/EventDashboard/VenueSection/FindVenueField';

const CardColumn = styled(Column)`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
`;

const StyledGrid = styled(Grid)`
    margin: -10px;
    width: calc(100% + 20px);
`;

export default function VenuesCard({
    eventId,
    editable,
    location,
    googlePlaceId,
    startsAt,
    endsAt,

    viewVenueListing,
    viewProposal,
    onSearch,
    onEditInquiries,
}: {
    eventId: string | number;
    editable?: boolean;
    location?: BizlyAPI.Meeting['location'];
    googlePlaceId?: BizlyAPI.Meeting['googlePlaceId'];
    startsAt?: BizlyAPI.Meeting['startsAt'];
    endsAt?: BizlyAPI.Meeting['endsAt'];

    viewVenueListing: (venueId: number) => void;
    viewProposal: (proposalId: number) => void;
    onSearch: () => void;
    onEditInquiries: () => void;
}) {
    const [isLoading, setIsLoading] = useState(false);
    const { highlightedText, softAccentedBackground } = useThemedColor();
    const { toggles, user } = useUser();

    const { enqueueSnackbar } = useSnackbar();
    const meeting = useMeetings(selectMeeting(eventId));
    const onLocationChange = async (location?: string, googlePlaceId?: string) => {
        if (!meeting) return;

        try {
            await meetingsActions.update({ ...meeting, location, googlePlaceId });
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    const handleFindVenue = async ({
        location: newLocation,
        googlePlaceId: newGooglePlaceId,
    }: Pick<Bizly.Event, 'location' | 'googlePlaceId'>) => {
        const locationChanged = location !== newLocation && googlePlaceId !== newGooglePlaceId;

        try {
            if (!!(newLocation?.length && newGooglePlaceId?.length) && locationChanged) {
                setIsLoading(true);
                await onLocationChange?.(newLocation, newGooglePlaceId);
            }
            onSearch();
        } finally {
            setIsLoading(false);
        }
    };

    const [suggestedVenues, setSuggestedVenues] = React.useState<Bizly.Venue[]>([]);
    const meetingLocation = meeting?.location;
    React.useEffect(() => {
        const load = async () => {
            if (eventId) setSuggestedVenues(await loadSuggestedVenuesForEvent(eventId));
        };
        load();
    }, [eventId, meetingLocation]);

    const { data: inquiries, loading: loadingInquiries } = useAsync(
        React.useCallback(() => getVenueInquiries(eventId), [eventId])
    );

    const bookedInquiry = inquiries?.find(inquiry => inquiry.booked);
    const timeFormat = 'MMM do h:mm a';
    const copy = {
        [DEFAULT]: {
            subheading: 'Find a great venue to help make your meeting shine. Here are some suggestions in the area.',
        },
        [NO_CITY]: {
            subheading:
                'Let us know where you’re looking by selecting a city up top, then we’ll help you find a great venue for your meeting.',
        },
        [HAS_INQUIRIES]: {
            subheading:
                'You’ve submitted an inquiry. If venues can accommodate your meeting, they’ll respond with a proposal.',
        },
        [HAS_BOOKED]: {
            subheading: toggles.gate.useActivityCalendar
                ? ''
                : startsAt && endsAt
                ? `Your booking’s confirmed for ${format(parseISO(startsAt), timeFormat)}–${format(
                      parseISO(endsAt),
                      timeFormat
                  )}.`
                : 'Unable to retrieve the dates of your booking.',
        },
    };

    let bookedState: TBookedStatus;

    const hasInquiryVenues = inquiries && inquiries.some(inquiry => inquiry.venue && inquiry.status !== 'Added');

    if (hasInquiryVenues) {
        bookedState = bookedInquiry ? HAS_BOOKED : HAS_INQUIRIES;
    } else {
        bookedState = location ? DEFAULT : NO_CITY;
    }

    const venues = hasInquiryVenues
        ? inquiries?.map(inquiry => ({ status: inquiry.status, ...inquiry.venue }))
        : location
        ? suggestedVenues
        : [];

    const renderVenueCards = () =>
        bookedInquiry ? (
            <VenueTileWithoutActions
                key={bookedInquiry.venue.id}
                venue={{ ...bookedInquiry.venue, status: bookedInquiry.status }}
                format="proposalThumb"
                pillType="inquiryStatus"
                fixedRatio="56%"
                width="100%"
                onClick={viewVenueListing}
            />
        ) : (
            venues &&
            editable && (
                <StyledGrid container justify="center">
                    <VenueTiles
                        venues={venues || []}
                        bookedState={bookedState}
                        viewVenueListing={viewVenueListing}
                        placeholderBackground={softAccentedBackground}
                    />
                </StyledGrid>
            )
        );

    const header = (
        <>
            <H3Headline>Venue</H3Headline>
            {!loadingInquiries && (
                <CopyFaded small style={bookedInquiry ? { color: highlightedText } : {}}>
                    {editable || bookedInquiry ? copy[bookedState].subheading : 'No Venue Booked.'}
                </CopyFaded>
            )}
        </>
    );

    if (loadingInquiries) {
        return (
            <Column itemSpacing="small">
                {header}
                <SpinnerOverlay transparent />
            </Column>
        );
    }

    return (
        <CardColumn alignItems="flex-start" itemSpacing="default">
            <Column itemSpacing="small">{header}</Column>

            <div style={{ width: '100%' }}>
                <CardColumn itemSpacing="default" justifyContent="space-between">
                    {editable && ![HAS_BOOKED, HAS_INQUIRIES].includes(bookedState) && (
                        <FindVenueField
                            location={location || undefined}
                            googlePlaceId={googlePlaceId || undefined}
                            onFindVenue={handleFindVenue}
                            editable={editable}
                            key={meeting?.location ?? ''}
                            allowGlobal={user.team?.allowGlobal}
                        />
                    )}

                    <div>{renderVenueCards()}</div>
                </CardColumn>
            </div>
            {editable && [HAS_BOOKED, HAS_INQUIRIES].includes(bookedState) ? (
                <Button
                    width={94}
                    onClick={() => {
                        if (bookedState === 'hasBooked') {
                            viewProposal(bookedInquiry?.proposalId as number);
                        } else if (bookedState === HAS_INQUIRIES) {
                            onEditInquiries();
                        } else {
                            onSearch();
                        }
                    }}
                >
                    {bookedState === HAS_BOOKED || !editable ? 'View' : 'Edit'}
                </Button>
            ) : null}
            {isLoading && <SpinnerOverlay transparent />}
        </CardColumn>
    );
}
