import React from 'react';

import { Copy, ExternalLink } from 'ui';

const TAC_URL = process.env.REACT_APP_TAC_URL;
const PRIV_POLICY_URL = process.env.REACT_APP_PRIV_POLICY_URL;

export default function Policies({ prefix }: { prefix: string }) {
    return (
        <Copy small>
            {prefix}, I accept the{' '}
            <ExternalLink href={TAC_URL} openInNewTab>
                Terms and Conditions
            </ExternalLink>{' '}
            and confirm that I have read the{' '}
            <ExternalLink href={PRIV_POLICY_URL} openInNewTab>
                Privacy Policy
            </ExternalLink>
            .
        </Copy>
    );
}
