import React from 'react';

import styled from 'styled-components';

import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Autocomplete from '@material-ui/lab/Autocomplete';

import TAutoCompleteFn, {
    TAutoCompleteAllProps,
    MATERIAL_BOX_SHADOW_STYLED_FN,
    MATERIAL_BORDER_COLOR_STYLED_FN,
    INPUT_PADDING,
    TAG_HEIGHT,
    TAG_MARGIN,
} from './AutoCompleteTypes';

import { ReactComponent as CheckMarkIcon } from '../../../images/icons/check-mark.svg';

import { Column, Row } from '../../../ui';
import AutoCompleteTag, { AdornmentPill } from './AutoCompletePills';
import getAutocompleteRenderScrollableInput from './AutoCompleteInput';

import { useTrackAutocompleteFocus, useTrackAndSimplifyAutocompleteInputChange } from './AutoCompleteUtils';
import { FormTextFieldBorders } from '../../FormFields';

const RelativeCol = styled(Column)`
    position: relative;
`;

const StyledAutoComplete = styled(Autocomplete)<{ asField?: boolean }>`
    /* remove any buttons from autocomplete input itself as we don't use them (dropdown arrow or x icon) */
    .MuiAutocomplete-endAdornment {
        display: none;
    }

    /* input field style that autocomplete injects wrapping the <input> */
    .MuiAutocomplete-inputRoot {
        padding: ${INPUT_PADDING - TAG_MARGIN}px;
        ${({ asField }) => (asField ? `padding: ${10 - TAG_MARGIN}px ${14 - TAG_MARGIN}px;` : '')}
    }

    /* actual <input> field */
    .MuiAutocomplete-input {
        margin: 0 ${TAG_MARGIN}px;
        padding: ${TAG_MARGIN}px 0 !important;

        ${({ asField }) => (asField ? '' : `height: ${TAG_HEIGHT}px;`)}

        min-width: 170px;
    }

    ${({ asField }) => (asField ? FormTextFieldBorders : '')}
`;

const Dropdown = styled(Popper)`
    /* dropdown options */
    .MuiAutocomplete-paper {
        margin: 0;
        border: solid 1px;
        ${MATERIAL_BORDER_COLOR_STYLED_FN}
        border-top: 0;
        border-radius: 0;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        ${MATERIAL_BOX_SHADOW_STYLED_FN}
    }

    /* cut off top of Paper's box shadow to not occlude input field */
    padding: 8px;
    margin: -8px;
    padding-top: 0px;
    margin-top: 0px;
    overflow: hidden;

    /* dropdown options wrapping component */
    .MuiAutocomplete-listbox {
        padding: 7px 12px;
        .MuiAutocomplete-option {
            padding: 0;
            margin: 5px 0;

            border-radius: 4px;
        }

        /* we use a checkmark to denote selected */
        .MuiAutocomplete-option[aria-selected='true'] {
            background-color: transparent;
        }

        .MuiAutocomplete-option:hover,
        .MuiAutocomplete-option[data-focus='true'] {
            background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.dropdownItemHover)};
        }
    }

    /* no options text */
    .MuiAutocomplete-noOptions {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
        word-break: break-word;
    }
`;

const Option = styled(Row)`
    align-items: center;
    padding: 12px;
    box-sizing: border-box;
`;

const OptionContent = styled(Row)`
    min-width: 0;
    flex: 1 0 0;
`;

const CheckMark = styled(CheckMarkIcon)`
    margin-left: 12px;
`;

const FlatPaper = styled(Paper).attrs({ elevation: 0 })``;
// Used to hide the Options, and reset highlighted option
const NullComponent = styled.span`
    display: none;
`;

const DUMMY = {};
// Autocomplete doesn't reset highlight position to first item properly for async case,
// It only resets when the input value changes, but not when options changes (after fetch)
// so we must always render a dummy option (for it to highlight) and ignore the dummy option properly:
const DUMMY_OPTIONS = [DUMMY];

const AutoComplete: TAutoCompleteFn = props => {
    const {
        renderTag,
        renderOption,
        onChange,
        onInputChange: onInputChangeProp,
        InputProps,
        onFocus: onFocusProp,
        onBlur: onBlurProp,
        inModalOrPopover,
        color,
        ...filteredProps
    } = props as TAutoCompleteAllProps<typeof props>;
    const { focus, ...onFocusAndOnBlur } = useTrackAutocompleteFocus({
        initialFocus: InputProps?.autoFocus,
        onFocus: onFocusProp,
        onBlur: onBlurProp,
    });
    // Track input to hide/show options
    const { input, onInputChange } = useTrackAndSimplifyAutocompleteInputChange({
        initialInput: undefined,
        onInputChange: onInputChangeProp,
    });

    // hiding the options when no input can solve a race condition, refer to PlaceInput
    const visible = filteredProps.open || (focus && !filteredProps.loading && !!input);
    const options = focus ? (filteredProps.loading ? DUMMY_OPTIONS : filteredProps.options) : undefined;

    const renderInput = getAutocompleteRenderScrollableInput({
        InputProps,
        visible,
        focus,
        loading: filteredProps.loading,
        ignoreEnterKeyPress: () => options === DUMMY_OPTIONS || options?.length === 0,
        inModalOrPopover,
    });

    return (
        <RelativeCol>
            <StyledAutoComplete
                {...filteredProps} // block overwriting certain props like disablePortal, disableClearable, open, renderInput, onFocus and onBlur for proper functioning
                PaperComponent={FlatPaper}
                PopperComponent={
                    visible ? Dropdown : NullComponent // Always show options to reset highlighted option
                }
                open={focus}
                options={options}
                autoHighlight
                disableClearable={!filteredProps.clearable}
                clearble={filteredProps.clearable}
                filterOptions={
                    filteredProps.filterOptions
                        ? (options, state) => {
                              if (options === DUMMY_OPTIONS) return options;
                              return filteredProps.filterOptions?.(options, state);
                          }
                        : undefined
                }
                renderInput={renderInput}
                renderTags={(tags: any[], getTagProps) =>
                    tags.map((tag, idx) => (
                        <AutoCompleteTag
                            key={renderTag ? renderTag(tag).key : idx}
                            onRemove={
                                (getTagProps as (prop: { index: number }) => { onDelete: () => void })({
                                    index: idx,
                                }).onDelete
                            }
                            label={renderTag ? renderTag(tag).component : tag}
                        />
                    ))
                }
                renderOption={(option, state) =>
                    option !== DUMMY && (
                        <Option>
                            <OptionContent>{renderOption ? renderOption(option, state) : option}</OptionContent>
                            {state.selected && <CheckMark />}
                        </Option>
                    )
                }
                onInputChange={onInputChange}
                onChange={(e, val) => {
                    if (onChange) onChange(e, val);
                    // blurring on enter key press isn't supported right now, let's support it
                    const target = e.target as any;
                    if (filteredProps.blurOnSelect && focus && target?.blur) target.blur();
                }}
                {...onFocusAndOnBlur}
            />
        </RelativeCol>
    );
};

export { AdornmentPill };

export default AutoComplete;
