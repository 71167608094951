import { sendCancellationEmail } from 'api';
import { Spinner } from 'components/Spinner';
import { useUser } from 'providers/user';
import React from 'react';
import styled from 'styled-components';

import { Column, Row, Copy } from '../../ui';
import Button from '../ui/Button';

const Container = styled(Column)`
    border-radius: 8px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackground)};
    width: 492px;
    padding: 32px;
`;

const Headline = styled.h3`
    font-size: 18px;
    letter-spacing: -0.1px;
    margin: 0;
    font-weight: normal;
`;

const AccentedHeadline = styled(Headline)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.accentedHeadline)};
`;

const ContactCol = styled(Column)`
    margin-right: 42px;
    font-size: 15px;

    div {
        line-height: 1.47;
    }
`;

const AddressCol = styled(Column)`
    font-size: 15px;

    div {
        line-height: 1.47;
    }
`;

const MessageButton = styled(Button)`
    font-size: 13px;
`;

type VenueContactProps = {
    contact: {
        firstName: string;
        lastName?: string;
        title?: string;
        email: string;
        phone?: string;
    };
    venue: {
        name: string;
        address: string;
    };
    onSendMessage?: () => void;
    booking?: Bizly.Booking;
    bookingCancelled?: boolean;
};

const joinName = (firstName: string, lastName?: string) => (lastName ? `${firstName} ${lastName}` : firstName);

const parseAddress = (address: string) => {
    const [street, city, state]: Array<string> = address.split(', ');
    return [street, `${city}, ${state}`];
};

export default function VenueContact({ contact, venue, onSendMessage, booking, bookingCancelled }: VenueContactProps) {
    const { user } = useUser();

    const hasSpecialCancellationEmail = function(
        setting?: Bizly.Team['specialCancellationEmail']
    ): setting is Exclude<Bizly.Team['specialCancellationEmail'], []> {
        return !!setting && !Array.isArray(setting);
    };
    const [loading, setLoading] = React.useState(false);
    const [emailSent, setEmailSent] = React.useState(false);

    const sendEmail = () => {
        if (!booking) return;
        setLoading(true);
        sendCancellationEmail(booking.id)
            .then(() => {
                setEmailSent(true);
            })
            .finally(() => setLoading(false));
    };
    return (
        <Container itemSpacing="smallish">
            <Row>
                <Headline>Your venue contact</Headline>
            </Row>

            <Row>
                <ContactCol>
                    <AccentedHeadline>{joinName(contact.firstName, contact.lastName)}</AccentedHeadline>
                    {contact.title && <Copy>{contact.title}</Copy>}
                    <Copy>{contact.email}</Copy>
                    <Copy>{contact.phone}</Copy>
                </ContactCol>

                <AddressCol>
                    <AccentedHeadline>{venue.name}</AccentedHeadline>
                    {venue.address &&
                        parseAddress(venue.address).map((line, idx) => <Copy key={line + idx}>{line}</Copy>)}
                </AddressCol>
            </Row>

            <Row itemSpacing="smallish">
                {onSendMessage && (
                    <MessageButton onClick={onSendMessage} width={120} small secondary>
                        Send Message
                    </MessageButton>
                )}
                {hasSpecialCancellationEmail(user.team?.specialCancellationEmail) &&
                    (loading ? (
                        <Spinner unsetMargin />
                    ) : (
                        booking &&
                        bookingCancelled && (
                            <Button small width="auto" onClick={sendEmail} disabled={emailSent}>
                                {emailSent ? 'Sent!' : user.team?.specialCancellationEmail.invoicingInstructions}
                            </Button>
                        )
                    ))}
            </Row>
        </Container>
    );
}
