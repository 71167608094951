import React from 'react';
import styled from 'styled-components';

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import {
    createPlaybookActions,
    useCreatePlaybook,
    stepList,
    maxStep,
    ValidationError,
    stepToTitle,
    selNextStep,
    ESteps,
    selCurStep,
} from '../store';

import Button from 'components/ui/Button';
import { Row, Column, InlineRow } from 'ui';

import ProgressTracker from 'components/ProposalForm/ProgressTracker';
import { StickyHeader, stickyHeaderHeight } from 'components/StickyHeader';

const StickyOffset = styled.div`
    height: ${stickyHeaderHeight};
`;

const HeaderCol = styled(Column)`
    background: white;
    width: 100%;
`;

export default function Header({ playbookId }: { playbookId: number | string }) {
    const { stepIdx, id } = useCreatePlaybook();
    const nextStep = useCreatePlaybook(selNextStep);
    const curStep = useCreatePlaybook(selCurStep);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const goToPlaybook = (id: number | string) => history.push(`/playbooks/${id}`);

    const create = async () => {
        try {
            await createPlaybookActions.update(curStep);
            if (id) goToPlaybook(id);
        } catch (e) {
            if (!(e instanceof ValidationError))
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    const goToStep = async (step: ESteps) => {
        try {
            await createPlaybookActions.update(step);
        } catch (e) {
            if (!(e instanceof ValidationError))
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    const save = async () => {
        try {
            await createPlaybookActions.update();
            enqueueSnackbar('Saved!', { variant: 'success' });
        } catch (e) {
            if (!(e instanceof ValidationError))
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    return (
        <HeaderCol alignItems="center">
            <StickyHeader>
                <Row justifyContent="flex-end">
                    <InlineRow itemSpacing="smallish">
                        {stepIdx > 0 && (
                            <Button width="80px" secondary onClick={createPlaybookActions.prevStep}>
                                Prev
                            </Button>
                        )}
                        {stepIdx < maxStep && (
                            <Button width="80px" secondary onClick={save}>
                                Save
                            </Button>
                        )}
                        {stepIdx < maxStep && (
                            <Button width="140px" onClick={() => goToStep(nextStep)}>
                                Next
                            </Button>
                        )}
                        {stepIdx === maxStep && (
                            <Button width="140px" onClick={create}>
                                Update Playbook
                            </Button>
                        )}
                    </InlineRow>
                </Row>
            </StickyHeader>
            <StickyOffset />
            <ProgressTracker
                sections={stepList.map(key => ({ key, label: stepToTitle[key] }))}
                currentSection={stepIdx}
                horizontal
                inverted
                large
                onClick={goToStep}
            />
        </HeaderCol>
    );
}
