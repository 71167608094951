import React from 'react';
import styled from 'styled-components';

import { InlineRow } from 'ui';

import { OnlySingleLine } from 'shared';
import { EColors } from 'theme';
import colorFns from 'colorFns';

export const DEFAULT_PREFERENCE_COLOR = EColors.preferredVenue;

const Label = styled.div``;

// TODO: Implement this UI pill throughout the app where we repeat this theme
const StyledPill = styled.div<{
    backgroundColor?: Themed.Color;
    hoverColor?: Themed.Color;
    maxWidth?: string;
    noShadow?: boolean;
    centerText?: boolean;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    onClick?: () => void;
}>`
    font-size: 11px;
    font-weight: 500;
    line-height: ${({ centerText }) => (centerText ? '1' : '1.18')};
    text-transform: uppercase;

    max-height: 11px;
    max-width: 100%;
    ${Label} {
        ${OnlySingleLine};
    }

    color: ${({ color, theme: { getColor, EColors } }) => color || getColor(EColors.pureWhite)};
    background-color: ${props => props.backgroundColor || DEFAULT_PREFERENCE_COLOR};

    transition: all 0.15s ease-out;

    &:hover {
        ${({ hoverColor }) => (hoverColor ? `background-color: ${hoverColor};` : '')}
    }

    padding: 4px 12px;
    ${({ startAdornment }) => startAdornment && `padding-left: 4px;`}
    ${({ endAdornment }) => endAdornment && `padding-right: 4px;`}

    border-radius: 100px;
    ${({ noShadow, ...props }) => (noShadow ? '' : `box-shadow: 0 2px 10px 0 ${colorFns.pureBlack.alpha(0.3)(props)}`)};

    ${({ onClick }) => onClick && `cursor: pointer;`}
`;

type PillTypes = {
    color: Themed.Color;
    hoverColor?: Themed.Color;
    label: React.ReactNode;
    noShadow?: boolean;
    centerText?: boolean;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    onClick?: () => void;
};

const Pill = React.forwardRef<
    HTMLDivElement,
    PillTypes &
        Pick<
            React.HTMLAttributes<HTMLDivElement>,
            'onBlur' | 'onFocus' | 'onMouseOver' | 'onMouseLeave' | 'onTouchStart' | 'onTouchEnd' | 'title'
        >
>(({ color, hoverColor, label, noShadow, centerText, onClick, startAdornment, endAdornment, ...props }, ref) => (
    <StyledPill
        backgroundColor={color}
        hoverColor={hoverColor}
        noShadow={noShadow}
        centerText={centerText}
        ref={ref}
        {...props}
        onClick={onClick}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
    >
        <InlineRow itemSpacing="xsmall">
            {startAdornment}
            <Label>{label}</Label>
            {endAdornment}
        </InlineRow>
    </StyledPill>
));

export default Pill;
