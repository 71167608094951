import React from 'react';
import { useSnackbar } from 'notistack';

import { LoadPlaybookResources, playbooksActions, selectResources, usePlaybooks } from 'stores/playbooks';

import { Column } from 'ui';
import { Uploader } from 'components/Uploader';
import { CloudinaryUploader } from 'components/CloudinaryUploader';
import { Spinner } from 'components/Spinner';
import { DisplayField } from 'components/FormFields';

const ResourcesList = ({ playbookId, readonly }: { playbookId: string | number; readonly?: boolean }) => {
    const playbookResources = usePlaybooks(selectResources(playbookId));
    const { enqueueSnackbar } = useSnackbar();

    const handleAddResource = (document: Bizly.EventResource) => {
        if (!playbookId) return;
        playbooksActions.addPlaybookResources(playbookId, document);
    };

    const handleRemoveResource = (resourceId: number) => {
        if (!playbookId) return;
        playbooksActions.removePlaybookResource(playbookId, resourceId);
    };

    const handleUploadError = (error: string) => enqueueSnackbar(error);

    if (!playbookId || !playbookResources)
        return (
            <>
                <LoadPlaybookResources id={playbookId} />
                <Spinner />
            </>
        );

    return readonly && playbookResources.length === 0 ? null : (
        <Column itemSpacing="small">
            <LoadPlaybookResources id={playbookId} />
            {!readonly && (
                <CloudinaryUploader
                    ctaLabel="Upload"
                    onUploadSuccess={(document: Bizly.EventResource) => handleAddResource(document)}
                    onUploadError={handleUploadError}
                />
            )}
            {readonly && playbookResources.length === 0 && <DisplayField label="No Documents Added" />}
            {playbookResources.map((document, index) => (
                <Column key={`${document.url}_${index}`}>
                    <Uploader
                        resource={document}
                        onChange={(newValue: Bizly.EventResource) =>
                            newValue === null && handleRemoveResource(document.id)
                        }
                        readonly={readonly}
                    />
                </Column>
            ))}
        </Column>
    );
};

export default ResourcesList;
