import { timeOptions } from 'utils/date_util';
import CurrencyField from '../CurrencyField';

import { TOption, EVENT_SPACES_REQUEST_WIDTH, EVENT_SPACES_REQUEST_ITEM_SPACING, TSpace } from './utils';

import VenueSpacesModal from './VenueSpacesModal';

const standardizedTimeOptions = timeOptions.map(o => ({
    ...o,
    name: o.name.replace(' ', '').toLowerCase(),
    id: o.name.replace(' ', '').toLowerCase(),
}));

export const timeAndSpaceFields = (
    onlyShowRequest?: boolean,
    spaces?: TSpace[],
    onUpdateVenueSpaces?: (spaces: any) => void
) => ({
    requestedGuests: {
        type: 'display',
        fixedWidth: EVENT_SPACES_REQUEST_WIDTH,
        options: { formatter: (value: string) => `${value} Guests` },
    },
    proposedVenueSpaceId: {
        type: VenueSpacesModal,
        options: {
            spaces,
            onUpdateVenueSpaces,
        },
    },
    requestedStartTime: {
        type: 'display',
        perRow: 'auto',
    },
    requestedEndTime: {
        type: 'display',
        perRow: 'auto',
    },
    proposedStartTime: {
        type: 'select',
        perRow: 4,
        disabled: onlyShowRequest,
        options: {
            options: standardizedTimeOptions,
        },
    },
    proposedEndTime: {
        type: 'select',
        perRow: 4,
        disabled: onlyShowRequest,
        options: {
            options: standardizedTimeOptions,
        },
    },
});

const dash = { type: 'display', options: { label: '-' } };
const requestedTimesSchema = {
    type: 'nested',
    fixedWidth: EVENT_SPACES_REQUEST_WIDTH,
    schema: [
        {
            fields: ['requestedStartTime', dash, 'requestedEndTime'],
            itemSpacing: 'small',
            spacing: false,
        },
    ],
};
const proposedTimesSchema = {
    type: 'nested',
    schema: [
        {
            fields: ['proposedStartTime', dash, 'proposedEndTime'],
            itemSpacing: 'small',
            spacing: false,
        },
    ],
};

export const timeAndSpaceSchema = (eventSpaceName: string) => [
    {
        key: 'space',
        fields: ['requestedGuests', 'proposedVenueSpaceId'],
        itemSpacing: EVENT_SPACES_REQUEST_ITEM_SPACING,
        spacing: 'xsmall',
        header: eventSpaceName,
        headerSpacing: 'small',
    },
    {
        key: 'time',
        fields: [requestedTimesSchema, proposedTimesSchema],
        itemSpacing: EVENT_SPACES_REQUEST_ITEM_SPACING,
        spacing: false,
    },
];

/*


SETUP


*/

export const setupFields = (options: TOption[]) => ({
    requestedSetupId: {
        type: 'select',
        readonly: true,
        fixedWidth: EVENT_SPACES_REQUEST_WIDTH,
        prompt: 'Seating',
        options: {
            options,
        },
    },
    proposedSetupId: {
        type: 'select',
        perRow: 3,
        prompt: ' ',
        options: {
            options,
        },
    },
});

export const setupSchema = (onlyShowRequest?: boolean) => [
    {
        key: 'setup',
        fields: onlyShowRequest ? ['requestedSetupId'] : ['requestedSetupId', 'proposedSetupId'],
        itemSpacing: EVENT_SPACES_REQUEST_ITEM_SPACING,
        spacing: false,
    },
];

/*


COSTS


*/

export const costsFields = (currencyOptions: { id: string }[], currencyCode?: string | null) => ({
    currencyCode: {
        type: CurrencyField,
        prompt: ' ',
        perRow: 'auto',
        options: { options: currencyOptions },
    },
    proposedRoomRate: {
        type: 'text',
        prompt: 'Daily Room Rental Rate',
        perRow: 'auto',
        options: { numberType: 'currency', currency: currencyCode },
    },
    proposedFbMinimum: {
        type: 'text',
        prompt: 'Daily F/B Minimum',
        perRow: 'auto',
        options: { numberType: 'currency', currency: currencyCode },
    },
    proposedRatePerPerson: {
        type: 'text',
        prompt: 'Per Person Rate',
        perRow: 'auto',
        options: { numberType: 'currency', currency: currencyCode },
    },
    proposedMinGuests: { type: 'counter', prompt: 'Minimum Number of Guests', perRow: 'auto' },
    salesTax: {
        type: 'text',
        prompt: 'Sales Tax',
        perRow: 3,
        options: { numberType: 'percentage' },
    },
    serviceCharge: {
        type: 'text',
        prompt: 'Service Charge',
        perRow: 3,
        options: { numberType: 'percentage' },
    },
    gratuity: {
        type: 'text',
        prompt: 'Gratuity',
        perRow: 3,
        options: { numberType: 'percentage' },
    },
    useDdr: {
        type: 'radioselect',
        fixedWidth: 440,
        options: {
            options: [
                { id: false, label: 'Daily Room Rate' },
                { id: true, label: 'Per Person Rate' },
            ],
        },
    },
});

const roomRateSchema = {
    type: 'nested',
    perRow: 3,
    schema: [
        {
            key: 'rateAndCurrency',
            fields: ['proposedRoomRate', 'currencyCode'],
            spacing: false,
            itemSpacing: 'xsmall',
        },
    ],
};

const fbSchema = {
    type: 'nested',
    perRow: 3,
    schema: [
        {
            key: 'fbAndCurrency',
            fields: ['proposedFbMinimum', 'currencyCode'],
            spacing: false,
            itemSpacing: 'xsmall',
        },
    ],
};

const perPersonSchema = {
    type: 'nested',
    perRow: 3,
    schema: [
        {
            key: 'perPersonAndCurrency',
            fields: ['proposedRatePerPerson', 'currencyCode'],
            spacing: false,
            itemSpacing: 'xsmall',
        },
    ],
};

export const costsSchema = (ddr?: boolean | null) => [
    {
        header: 'Pricing',
        fields: ['useDdr'],
        spacing: 'smallish',
    },
    {
        ...{},
        fields: ddr ? [perPersonSchema, 'proposedMinGuests'] : [roomRateSchema, fbSchema],
        spacing: 'largest',
    },
    { header: 'Fees and Taxes', fields: ['salesTax', 'serviceCharge', 'gratuity'], spacing: false },
];

export { AVFields, AVSchema } from './eventSpacesAVField';
export { FBFields, FBSchema } from './eventSpacesFBField';
