import VenueSpaceSelectField from './VenueSpacesSelectField';
import UnitField from './UnitsField';

import { TSpace, spacesToOptions } from './utils';
import { units } from 'stores/measurement-units';

const VENUE_SPACES_MODAL_LEFT_COLUMN = 360;

type TModalValueBase = {
    id: number;
    name: string;
    imageUrl: string;
    description: string | null;
    maxCapacity: number | string | null;
};

export type TModalValueSqft = TModalValueBase & {
    size: Area.Sqft | string | null;
    sizeUnit: units.sqft;
};
export type TModalValueSqm = TModalValueBase & {
    size: Area.Sqm | string | null;
    sizeUnit: units.sqm;
};

export type TModalValue = TModalValueSqft | TModalValueSqm;

const modalFields = (spaces: TSpace[]) =>
    ({
        id: {
            type: 'select',
            fixedWidth: VENUE_SPACES_MODAL_LEFT_COLUMN,
            options: {
                options: spacesToOptions(spaces),
                placeholder: 'Select a space...',
            },
        },
        name: {
            type: 'text',
            perRow: '2/3',
            prompt: 'Name',
        },
        imageUrl: {
            type: 'image_uploader',
            prompt: 'Image',
            fixedWidth: VENUE_SPACES_MODAL_LEFT_COLUMN,
        },
        description: {
            type: 'textarea',
            prompt: 'Description',
            optional: true,
            options: {
                placeholder: 'Description that shows up on our platform. Make it awesome!',
                maxLength: 275,
            },
        },
        size: {
            type: 'text',
            prompt: 'Size',
            options: {
                numberType: 'integer',
                placeholder: 'sq. ft',
            },
        },
        sizeUnit: {
            type: UnitField,
            prompt: ' ',
            fixedWidth: 100,
            options: {
                type: 'area',
            },
        },
        maxCapacity: {
            type: 'text',
            prompt: 'Max Capacity',
            options: {
                numberType: 'integer',
            },
        },
    } as const);

const sizeSchema = {
    type: 'nested',

    schema: [{ fields: ['size', 'sizeUnit'], itemSpacing: 'small', spacing: false }],
};

const maxCapacitySchema = {
    type: 'nested',

    schema: [
        {
            fields: ['maxCapacity', { type: 'display', prompt: ' ', value: 'people', perRow: 'auto' }],
            itemSpacing: 'small',
            spacing: false,
        },
    ],
};

const detailsSchema = {
    type: 'nested',
    perRow: '2/3',
    schema: [
        {
            fields: ['name'],
            itemSpacing: 'small',
            spacing: 'default',
        },
        {
            fields: ['description'],
            itemSpacing: 'small',
            spacing: 'default',
        },
        {
            fields: [sizeSchema, maxCapacitySchema],
            itemSpacing: 'default',
            spacing: false,
        },
    ],
};

const modalSchema = [
    { fields: ['id'], itemSpacing: 'larger', spacing: 'larger' },
    { fields: ['imageUrl', detailsSchema], itemSpacing: 'larger', spacing: false },
];

const smallFields = (spaces: TSpace[], onEdit?: () => void) => ({
    id: {
        type: VenueSpaceSelectField,
        options: {
            spaces,
            onEdit,
        },
    },
});

const smallSchema = [{ fields: ['id'], spacing: false }];

export { smallFields, smallSchema, modalFields, modalSchema };
