import React from 'react';

import styled from 'styled-components';

import { Dialog, DialogContent } from '@material-ui/core';
import { RichTextDisplay } from 'components/Form/RichTextEditor';
import { useUser } from 'providers/user';

const MediumDialogContent = styled(DialogContent)`
    width: 320px;
    padding: 32px !important;
`;

export default function EventCreateProtocolModal({
    onBackdropClick,
}: {
    onBackdropClick?: React.ReactEventHandler<{}> | undefined;
}) {
    const { user } = useUser();
    return (
        <Dialog open maxWidth="xl" onBackdropClick={onBackdropClick}>
            <MediumDialogContent>
                <RichTextDisplay value={user.team?.authMeetingCreateRedirect ?? ''} />
            </MediumDialogContent>
        </Dialog>
    );
}
