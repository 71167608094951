import React from 'react';

import styled from 'styled-components';

const Segment = styled.div<{ size?: number }>`
    width: ${({ size }) => Math.ceil(size || 0)}%;
    height: 8px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.progressBarForeground)};

    border-radius: 4px;
`;

const Bar = styled.div`
    display: flex;
    flex-shrink: 0;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.progressBarBackground)};

    border-radius: 4px;
`;

export const SegmentedBar = ({ size }: { size: number }) => (
    <Bar>
        <Segment size={size} />
    </Bar>
);
