import { TStatusAttributes } from 'components/ProposalForm/statusUtils';
import { useMediaQuery } from '@material-ui/core';
import Button from 'components/ui/Button';
import React from 'react';
import styled from 'styled-components';
import { Row, SpacedRow } from 'ui';

const SCROLLBAR_WIDTH = 20; // at most
const WIDE_PAGE_WIDTH = 1366 - SCROLLBAR_WIDTH;

const sideWidth = 280;
const contentPadding = 94;

const WidthAutoRow = styled(Row)`
    width: auto;
`;

const ButtonsRow = styled(WidthAutoRow)<{ fullscreen?: boolean }>`
    position: fixed;
    left: ${({ fullscreen }) => (fullscreen ? 0 : sideWidth)}px;
    right: 0;
    bottom: 0;

    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border-top: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};

    z-index: 20;
`;

const ButtonsRowContent = styled(SpacedRow)`
    box-sizing: border-box;
    padding: 24px ${contentPadding}px;
    max-width: 1280px;
    margin: auto;
`;

const NoWrapButton = styled(Button)`
    white-space: nowrap;
`;

const FooterButtons = ({
    disabled,

    onBack,
    onSave,
    onContinue,
    onRestart,
    onCancel,
    onSubmit,
    onReject,

    isSubmitStep,
    isStatusStep,
    statusAttributes,
}: {
    disabled: boolean;

    onBack?: () => void;
    onSave: () => void;
    onContinue: () => void;
    onRestart: () => void;
    onSubmit: () => void;
    onCancel: () => void;
    onReject: () => void;

    isSubmitStep: boolean;
    isStatusStep: boolean;
    statusAttributes: TStatusAttributes;
}) => {
    const { canView, canCreate, canSubmit, isResubmit, canTurnDown, canWithdraw } = statusAttributes;

    const renderInProgress = () => (
        <>
            {(canSubmit || canCreate) && (
                <NoWrapButton onClick={onSave} secondary disabled={disabled}>
                    Save
                </NoWrapButton>
            )}
            {isSubmitStep && (canSubmit || canCreate) ? (
                <NoWrapButton onClick={onSubmit} disabled={disabled}>
                    {isResubmit ? 'Resubmit' : 'Submit'}
                </NoWrapButton>
            ) : (
                <NoWrapButton onClick={onContinue} disabled={disabled}>
                    Continue
                </NoWrapButton>
            )}
        </>
    );

    const renderStatus = () => (
        <>
            {canWithdraw && (
                <NoWrapButton onClick={onCancel} disabled={disabled} secondary width={150} warning>
                    Withdraw Proposal
                </NoWrapButton>
            )}

            {(canView || canCreate || canSubmit) && (
                <NoWrapButton onClick={onRestart} disabled={disabled} width={140}>
                    {canSubmit || canCreate ? `${canCreate ? 'Create' : 'Edit'} Proposal` : 'View Proposal'}
                </NoWrapButton>
            )}
        </>
    );

    const isWidthConstrained = useMediaQuery(`(max-width: ${WIDE_PAGE_WIDTH}px)`);

    return (
        <ButtonsRow fullscreen={isWidthConstrained}>
            <ButtonsRowContent>
                <span>
                    {!isStatusStep &&
                        (onBack ? (
                            <Button key="back" onClick={onBack} secondary disabled={disabled}>
                                Back
                            </Button>
                        ) : (
                            canTurnDown && (
                                <Button key="reject" onClick={onReject} warning disabled={disabled} width="auto">
                                    Turn Down Inquiry
                                </Button>
                            )
                        ))}
                </span>

                <WidthAutoRow itemSpacing="default">{isStatusStep ? renderStatus() : renderInProgress()}</WidthAutoRow>
            </ButtonsRowContent>
        </ButtonsRow>
    );
};

export default FooterButtons;
