import createStore from 'zustand';

import { getMeetingsPageAll, getMeetingsPageBySort } from 'api/meetings';
import { meetingsActions } from './store';
import { FilterOptions } from 'stores/meetings/filterStore';

type LoadingPages = {
    [page: number]: boolean;
};
type IdsPages = {
    [page: number]: number[];
};

type State = {
    loadingScheduledByPage: LoadingPages;
    loadingUnscheduledByPage: LoadingPages;
    loadingAllByPage: LoadingPages;

    scheduledIdsByPage: IdsPages;
    unscheduledIdsByPage: IdsPages;
    allIdsByPage: IdsPages;

    lastPageSchedSorted: number | null;
    lastPageUnschedSorted: number | null;
    lastPageAll: number | null;

    pageLimit: number;
    sort: FilterOptions;
};
type Store = State;

const initialState: State = {
    loadingScheduledByPage: {},
    loadingUnscheduledByPage: {},
    loadingAllByPage: {},

    scheduledIdsByPage: {},
    unscheduledIdsByPage: {},
    allIdsByPage: {},

    lastPageSchedSorted: null,
    lastPageUnschedSorted: null,
    lastPageAll: null,

    pageLimit: 20,
    sort: 'default',
};
export const [useMeetingsPagesSorted, meetingsPagesSortedStoreApi] = createStore<Store>(() => initialState);

const { setState, getState } = meetingsPagesSortedStoreApi;

export const meetingsPagesSortedActions = {
    load: async (page = 1, scheduled = false, sortBy: FilterOptions) => {
        const storeKeys = {
            loading: scheduled ? 'loadingScheduledByPage' : 'loadingUnscheduledByPage',
            pages: scheduled ? 'scheduledIdsByPage' : 'unscheduledIdsByPage',
            lastPage: scheduled ? 'lastPageSchedSorted' : 'lastPageUnschedSorted',
        } as const;

        setState({ [storeKeys.loading]: { ...getState()[storeKeys.loading], [page]: true } });

        const pullCount = getState().pageLimit;

        try {
            const { meetings } = await getMeetingsPageBySort(page, pullCount, scheduled, sortBy);

            setState({
                [storeKeys.loading]: { ...getState()[storeKeys.loading], [page]: false },
                [storeKeys.pages]: { ...getState()[storeKeys.pages], [page]: meetings.map(m => m.id) },
                ...(meetings.length < pullCount ? { [storeKeys.lastPage]: page } : {}),
            });

            meetingsActions.add(meetings);

            return meetings;
        } catch (e) {
            setState({ [storeKeys.loading]: { ...getState()[storeKeys.loading], [page]: false } });

            throw e;
        }
    },

    loadMixed: async (page = 1, sortBy: FilterOptions) => {
        setState({ loadingAllByPage: { ...getState().loadingAllByPage, [page]: true } });
        const pullCount = getState().pageLimit;

        try {
            const { meetings } = await getMeetingsPageAll(page, pullCount, sortBy);

            switch (sortBy) {
                case 'updated_at':
                    setState({
                        loadingAllByPage: { ...getState().loadingAllByPage, [page]: false },
                        allIdsByPage: { ...getState().allIdsByPage, [page]: meetings.map(m => m.id) },
                        ...(meetings.length < pullCount ? { lastPageAll: page } : {}),
                    });
                    break;
                // future sort criteria might result in more cases here
                default:
                    break;
            }
            meetingsActions.add(meetings);

            return meetings;
        } catch (e) {
            setState({ loadingAllByPage: { ...getState().loadingAllByPage, [page]: false } });
            throw e;
        }
    },

    setSort: (sortBy: FilterOptions) => {
        setState({
            ...initialState,
            sort: sortBy,
        });
    },
};

export const selectMeetingIdsSorted = (scheduled = true) => (state: State) =>
    Object.values(scheduled ? state.scheduledIdsByPage : state.unscheduledIdsByPage).flat();

export const selectAllMeetingIds = () => (state: State) => Object.values(state.allIdsByPage).flat();

export function isLoadingSortedPage(page: number = 1, scheduled = true, mixed = false) {
    return (state: State) =>
        mixed
            ? state.loadingAllByPage[page]
            : scheduled
            ? state.loadingScheduledByPage[page]
            : state.loadingUnscheduledByPage[page];
}
