import React from 'react';
import styled from 'styled-components';

import { createPlaybookActions } from './store';
import { useUser } from 'providers/user';

import { default as FormBase } from 'components/Form';
import { EventSettingsFields } from 'components/EventSettingsFlyout';

const schema = [
    {
        fields: ['costCenter', 'cventId'],
        spacing: 'default',
    },
    {
        fields: ['budget', 'type'],
        spacing: false,
    },
];

export type TInternalDetailsValue = Partial<Pick<Bizly.Event, 'type' | 'costCenter' | 'cventId'> & { budget?: string }>;

const Form = styled(FormBase)`
    width: 100%;
`;

export default function InternalDetailsForm({ value, readonly }: { value: TInternalDetailsValue; readonly?: boolean }) {
    const { user } = useUser();

    const fields = EventSettingsFields(user.team?.meetingInternalReferenceFieldLabel);

    return (
        <Form
            fields={fields}
            schema={schema}
            value={value}
            onChange={({ value }: { value: TInternalDetailsValue }) => {
                createPlaybookActions.setInternalForm(value);
                createPlaybookActions.setInternalFormErrors({});
            }}
            readonly={readonly}
            hideReadonlyEmpty
        />
    );
}
