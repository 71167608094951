import React from 'react';
import styled from 'styled-components';

import { GUEST_ROOMS_REQUEST_WIDTH } from '../EventSpacesForm/utils';

import { SPACER_SPACES, Row } from 'ui';
import Button from 'components/ui/Button';
import Form from 'components/Form';
import { getPassthroughProps } from 'components/Form/utils';
import CurrencyField from '../CurrencyField';

const requestColumnWidth = GUEST_ROOMS_REQUEST_WIDTH + SPACER_SPACES['large'];

const proposedRoomCountForm = {
    fields: {
        proposedRoomCount: {
            type: 'counter',
            perRow: 'auto',
            options: {
                min: 0,
            },
        },
    },
    schema: [
        {
            key: 'proposedRoomCount',
            fields: [
                'proposedRoomCount',
                {
                    type: 'display',
                    options: { label: 'rooms available' },
                },
            ],
            spacing: false,
        },
    ],
};

const FormField = styled(Row)`
    margin: 8px 0 4px;
    height: 40px;
    align-items: center;
`;

const ProposedRoomCountFormField = ({
    field,
    value = null,
    onChange,
    disabled,
    requestedGuests,
}: {
    field: string;
    value?: number | null;
    onChange: (update: { field: string; value: number }) => void;
    disabled?: boolean;
    requestedGuests: number;
}) =>
    value === null ? (
        <FormField itemSpacing="smallish">
            <Button onClick={() => onChange({ field, value: requestedGuests })} disabled={disabled}>
                Add Rooms
            </Button>
            <Button onClick={() => onChange({ field, value: 0 })} secondary disabled={disabled}>
                No Rooms
            </Button>
        </FormField>
    ) : (
        <Form
            {...proposedRoomCountForm}
            {...getPassthroughProps({ field: 'proposedRoomCount', value, onChange })}
            disabled={disabled}
        />
    );

const dayFields = ({
    requestedGuests,
    currencyOptions,
    currencyCode,
}: {
    requestedGuests: number;
    currencyOptions: { id: string }[];
    currencyCode?: string | null;
}) => ({
    requestedGuests: {
        type: 'display',
        prompt: 'No. of Rooms',
        fixedWidth: requestColumnWidth,
        options: {
            formatter: (guests: string) => `${guests} Guest rooms`,
        },
    },
    requestNotes: {
        type: 'display',
        fixedWidth: requestColumnWidth,
    },
    proposedRoomCount: {
        type: ProposedRoomCountFormField,
        prompt: ' ',
        options: {
            requestedGuests,
        },
    },
    proposedImageUrl: {
        type: 'image_uploader',
        prompt: 'Room Image',
        optional: true,
        fixedWidth: requestColumnWidth,
    },
    proposedRoomName: {
        type: 'text',
        prompt: 'Room Type',
    },
    proposedRoomRate: {
        type: 'text',
        prompt: 'Room Rate',
        options: {
            numberType: 'currency',
            currency: currencyCode,
        },
    },
    currencyCode: {
        type: CurrencyField,
        prompt: ' ',
        perRow: 'auto',
        options: { options: currencyOptions },
    },
    resortFee: {
        type: 'text',
        prompt: 'Resort Fee',
        options: {
            numberType: 'currency',
            currency: currencyCode,
        },
    },
    occupancyTax: {
        type: 'text',
        prompt: 'Occupancy Tax',
        options: {
            numberType: 'percentage',
        },
    },
});

const roomRateSchema = {
    type: 'nested',
    schema: [
        {
            key: 'rateAndCurrency',
            fields: ['proposedRoomRate', 'currencyCode'],
            spacing: false,
            itemSpacing: 'xsmall',
        },
    ],
};
const resortFeeSchema = {
    type: 'nested',
    schema: [
        {
            key: 'feeAndCurrency',
            fields: ['resortFee', 'currencyCode'],
            spacing: false,
            itemSpacing: 'small',
        },
    ],
};

const schemaRoomDetails = {
    type: 'nested',
    schema: [
        { key: 'nameAndRate', fields: ['proposedRoomName', roomRateSchema], spacing: 'default' },
        { key: 'feesAndTaxes', fields: [resortFeeSchema, 'occupancyTax'], spacing: 'default' },
    ],
};

export const dayForm = {
    fields: dayFields,
    schemaRooms: [
        {
            key: 'guests',
            fields: ['requestedGuests', 'proposedRoomCount'],
            spacing: false,
            itemSpacing: 'large',
        },
        { key: 'notes', fields: ['requestNotes'], spacing: false, itemSpacing: 'large' },
    ],
    schemaDetails: [
        {
            key: 'details',
            fields: ['proposedImageUrl', schemaRoomDetails],
            spacing: false,
            itemSpacing: 'large',
        },
    ],
};

export const commissionableForm = {
    fields: {
        commissionable: {
            type: 'checkbox',
            perRow: 4,
            options: {
                asRadio: true,
            },
        },
    },
    schema: [{ key: 'commissionable', fields: ['commissionable'], spacing: false }],
};
