import React from 'react';

import styled, { css } from 'styled-components/macro';

import Dialog from '@material-ui/core/Dialog';

import useKey from '../hooks/useKey';

import { EventOptionsContext } from '../providers/event-options';

import { Column, Copy, Row, Spacer } from '../ui';

import { ReactComponent as CloseIcon } from '../images/icons/close.svg';
import fontFns from 'fontFns';
import { SpinnerOverlay } from './Spinner';
import { tzMoment } from 'utils/moment';
import { format, parseISO } from 'date-fns';
import RejectionReasonForm from 'components/ProposalForm/RejectionReasonForm';
import { EColors } from 'types/themenum';

const AccentedHeadline = styled.h3`
    font-size: 18px;
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.accentedHeadline)};
`;

const ITEM_MARGIN = 12;

const DayHeader = styled(Row)`
    height: 60px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.agendaDayPane)};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.agendaDayPaneText)};

    box-sizing: border-box;
    padding: 0 ${ITEM_MARGIN}px;
    & > * {
        margin: 0 ${ITEM_MARGIN}px;
    }
`;

const DayContent = styled.div`
    & > * {
        border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};

        &:last-child {
            border: none;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
`;

const GRRow = styled(Row)`
    display: flex;
    align-items: center;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};

    box-sizing: border-box;
    padding: 28px ${ITEM_MARGIN}px;
    & > * {
        margin: 0 ${ITEM_MARGIN}px;
    }
`;

const HEADER_ICON_WIDTH = 40;

const HeaderIconImg = styled.img`
    width: ${HEADER_ICON_WIDTH}px;
`;

const ESContent = styled.div`
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    padding: 28px 0;
`;

const ESHeader = styled(Row)`
    flex-wrap: wrap;

    box-sizing: border-box;
    padding: 0 ${ITEM_MARGIN}px;
    padding-bottom: 12px;
    & > * {
        margin: 0 ${ITEM_MARGIN}px;
    }
`;

const AmenitiesRow = styled(Row)`
    width: unset;
    flex-wrap: wrap;

    box-sizing: border-box;
    padding: 0 ${ITEM_MARGIN}px;
    padding-left: ${ITEM_MARGIN + ITEM_MARGIN + HEADER_ICON_WIDTH + ITEM_MARGIN}px;
    & > * {
        margin: ${ITEM_MARGIN}px;
    }
`;

const AMENITY_ICON_WIDTH = 28;

const AmenityImg = styled.img`
    max-width: ${AMENITY_ICON_WIDTH}px;
    max-height: ${AMENITY_ICON_WIDTH}px;
`;

const InquiryDetailsModal = ({
    inquiryDetails,
    open,
    closeModal,
}: {
    inquiryDetails?: {
        schedule: any[];
        submittedAt?: string;
        notes?: string;
        datesFlexible?: boolean;
        rejectedAt?: string;
        rejectionReason?: string;
        rejectionNotes?: string;
    };
    open: boolean;
    closeModal: () => void;
}) => {
    useKey('Escape', closeModal);

    const { avOptions, fbOptions, setupOptions } = React.useContext(EventOptionsContext);

    const renderDay = (day: any, idx: number) => (
        <div key={day.id}>
            <DayHeader alignItems="center">
                <div>Day {idx + 1}</div>
                {day.start && <div>{format(parseISO(day.start), 'EEE, LLL dd, yyyy')}</div>}
            </DayHeader>
            <DayContent>
                {!!day.guestrooms.length && (
                    <GRRow>
                        <HeaderIconImg
                            src="https://res.cloudinary.com/hdd626jg7/image/upload/v1562953405/stencil/icons/guest-room.svg"
                            alt="guestroom-icon"
                        />
                        <div>{day.guestrooms[0].count} guest rooms</div>
                    </GRRow>
                )}
                {day.agenda.map((agendaItem: any, idx: number) => (
                    <ESContent key={agendaItem.id}>
                        <ESHeader alignItems="center">
                            <HeaderIconImg src={setupOptions[agendaItem.roomSetup].iconUrl} alt="meetingspace-icon" />
                            <Column>
                                <Row itemSpacing="smallish">
                                    <div>{agendaItem.name || `Meeting Space ${idx + 1}`}</div>
                                    <div>
                                        {format(parseISO(agendaItem.start), 'h:mm aaaa')} -{' '}
                                        {format(parseISO(agendaItem.end), 'h:mm aaaa')}
                                    </div>
                                </Row>
                                <div>{agendaItem.attendees} attendees</div>
                            </Column>
                        </ESHeader>

                        <AmenitiesRow alignItems="center">
                            {agendaItem.amenities.av.map((avId: number) => (
                                <AmenityImg
                                    src={
                                        (avOptions as any[]).find(avOption => avOption.id === avId)
                                            ? (avOptions as any[]).find(avOption => avOption.id === avId).iconUrl
                                            : ''
                                    }
                                    alt="meetingspace-icon"
                                    key={avId}
                                />
                            ))}
                            {agendaItem.amenities.foodAndBeverage.map((foodAndBeverageItem: { optionId: number }) => (
                                <AmenityImg
                                    src={
                                        (fbOptions as any[]).find(
                                            fbOption => fbOption.id === foodAndBeverageItem.optionId
                                        )
                                            ? (fbOptions as any[]).find(
                                                  fbOption => fbOption.id === foodAndBeverageItem.optionId
                                              ).iconUrl
                                            : ''
                                    }
                                    alt="meetingspace-icon"
                                    key={foodAndBeverageItem.optionId}
                                />
                            ))}
                        </AmenitiesRow>
                    </ESContent>
                ))}
            </DayContent>
        </div>
    );

    return (
        <Dialog open={open} maxWidth="md" onClose={closeModal}>
            <div
                css={css`
                    width: 480px;

                    max-height: 571px;

                    flex: 1;
                    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackground)};

                    height: 100%;
                    box-sizing: border-box;

                    overflow: auto;

                    position: relative;

                    & > *:not(:first-child) {
                        padding: 32px;
                    }
                `}
            >
                <CloseIcon
                    css={css`
                        position: absolute;
                        top: 30px;
                        right: 30px;

                        cursor: pointer;
                    `}
                    onClick={closeModal}
                />
                <section
                    css={css`
                        background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
                    `}
                >
                    <AccentedHeadline
                        css={css`
                            margin-top: 0;
                        `}
                    >
                        Inquiry Details
                    </AccentedHeadline>
                    {inquiryDetails && inquiryDetails.submittedAt && (
                        <Copy>Submitted on {format(parseISO(inquiryDetails.submittedAt), 'iii, LLL dd, yyyy')}</Copy>
                    )}
                    {inquiryDetails?.rejectedAt && (
                        <>
                            <Spacer small />
                            <Column fillWidth itemSpacing="small">
                                <Copy $color={EColors.warningText}>
                                    Rejected on {tzMoment(inquiryDetails.rejectedAt).format('ddd, MMM DD, YYYY')}
                                </Copy>
                                <RejectionReasonForm
                                    value={{
                                        reason: inquiryDetails.rejectionReason || '',
                                        note: inquiryDetails.rejectionNotes || '',
                                    }}
                                    readonly
                                />
                            </Column>
                        </>
                    )}
                </section>
                <section>
                    <Column fillWidth itemSpacing="medium">
                        {inquiryDetails && inquiryDetails.datesFlexible && <Copy>Dates are flexible</Copy>}

                        {inquiryDetails && inquiryDetails.schedule && inquiryDetails.schedule.map(renderDay)}

                        {inquiryDetails && inquiryDetails.notes && (
                            <Column fillWidth>
                                <div
                                    css={css`
                                        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
                                        ${fontFns.formLabel}
                                    `}
                                >
                                    Notes
                                </div>
                                <div>{inquiryDetails.notes}</div>
                            </Column>
                        )}
                    </Column>
                </section>
            </div>
            {!inquiryDetails && <SpinnerOverlay />}
        </Dialog>
    );
};

export default InquiryDetailsModal;
