import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import moment from 'moment';
import { range, groupBy } from 'lodash';

import useShowModal from 'hooks/useShowModal';
import { useUser } from 'providers/user';
import { LoadEventTemplates } from 'stores/eventTemplates';
import { useEventTemplates as useEventPlaybooks } from 'stores/eventTemplates'; // TODO: Update these helper names to use "Playbook" once old "Templates" page is depricated
import { isBlankTemplateId as isBlankPlaybookId } from 'api/eventTemplates'; // TODO: Update these helper names to use "Playbook" once old "Templates" page is depricated
import { useMeetings, selectMeetings, isLoading, LoadMeetings } from 'stores/meetings';
import { nudgesActions, useNudges } from 'stores/nudges';

import { Row, Copy, Spacer, Column } from 'ui';
import { LargeHeadline, H3Headline } from 'components/ui/Headline';
import TextButton from 'components/ui/Button/TextButton';

import SideNav from 'components/SideNav';
import HomeScreenBanner from 'components/Home/HomeScreenBanner';
import PagedPlaybooks from 'components/Templates/PagedPlaybooks';
import ScheduledList from 'pages/Meetings/ScheduledList';
import UnschedList from 'pages/Meetings/UnschedList';
import EventCreateModal, { useCreateEventModalUrlValues, EventCreateProtocolModal } from 'components/EventCreateModal';
import HealthAndSafetyInfo from 'components/HealthAndSafetyInfo';
import IntegrationsPrompt from 'components/IntegrationsPrompt';

import { ReactComponent as CalIconSVG } from 'images/icons/calendar.svg';
import TemplatesCarousel from 'components/TemplatesCarousel';

const CalIcon = styled(CalIconSVG).attrs({ viewBox: '-3 0 27 27' })`
    width: 22px;
    height: 22px;
`;

const Card = styled(Column)<{ flex?: boolean }>`
    position: relative;
    width: 455px;
    ${({ flex }) => flex && `flex: 1;`}

    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    border-radius: 8px;

    padding: 24px;
    box-sizing: border-box;
`;

const CardTitle = styled(H3Headline)`
    font-weight: 700;
`;

const SubHeading = styled(Copy)`
    font-size: 12px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
`;

const sortByMostRecent = (templates: Bizly.EventTemplate[]) =>
    templates.slice().sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1));

export default function Homebase() {
    const { user } = useUser();

    const history = useHistory();
    const goToScheduled = () => history.push(`/events`);
    const goToUnscheduled = () =>
        history.push(user.featureFlags?.createMeetingsFlow ? `/events/drafts` : `/events/unscheduled`);

    const viewEvent = (event: { id: number; published?: boolean }) => {
        if (user.featureFlags?.createMeetingsFlow) return history.push(`/events/${event.id}`);

        return history.push(`/event/${event.id}`);
    };

    const date = React.useMemo(() => new Date(), []);

    const startOfWeek = moment(date)
        .startOf('week')
        .toDate();

    const week = range(7).map(days =>
        moment(date)
            .startOf('week')
            .add(days, 'days')
    );

    const weekStartFromToday = week.filter(day => day.isSameOrAfter(moment(), 'day'));

    const ofWeek = useMeetings(selectMeetings(startOfWeek));
    const isLoadingWeek = useMeetings(isLoading(startOfWeek));
    const unsched = useMeetings(selectMeetings());
    const loadingUnscheduled = useMeetings(isLoading());

    const [globalPlaybooks, setGlobalPlaybooks] = useState<Bizly.EventTemplate[]>([]);
    const { templates: playbooks, loading: loadingPlaybooks } = useEventPlaybooks();

    const arrangeTemplates = (allPlaybooks: Bizly.EventTemplate[]) => {
        const { global = [] } = groupBy(sortByMostRecent(allPlaybooks), 'category');
        const globalTempsWithoutBlank = global.filter(({ id }) => !isBlankPlaybookId(id));

        return setGlobalPlaybooks(globalTempsWithoutBlank.slice(0, 5));
    };

    useEffect(() => arrangeTemplates(playbooks), [playbooks]);

    const perWeek = weekStartFromToday.map(date => [
        date,
        ofWeek.filter(e => moment(e.startsAt).isSame(date, 'day')),
    ]) as [moment.Moment, BizlyAPI.Meeting[]][];

    const urlCreateFormValues = useCreateEventModalUrlValues();
    const { modalShown: createModalShown, showModal: showCreateModal, hideModal: hideCreateModal } = useShowModal(
        !!urlCreateFormValues
    );
    const [carouselPlaybook, setCarouselPlaybook] = React.useState<number | string | null>(null);
    const closeCreateModal = () => {
        hideCreateModal();
        setCarouselPlaybook(null);
    };

    const {
        modalShown: protocolModalShown,
        showModal: showProtocolModal,
        hideModal: hideProtocolModal,
    } = useShowModal();

    const onCreateMeeting = async (playbookId: string | number) => {
        setCarouselPlaybook(playbookId);

        if (user.team?.authMeetingCreateRedirect) return showProtocolModal();
        if (user?.featureFlags?.createMeetingsFlow) return history.push(`/events/new`);

        showCreateModal();
    };

    const hasHealthSafetyPolicies = React.useMemo(() => {
        const healthSafetyCategories = user?.team?.healthAndSafety?.categories || [];
        return healthSafetyCategories.some(category => category.policies.length > 0);
    }, [user]);

    const Header = () => (
        <>
            <LargeHeadline>Welcome Back, {user.firstName}</LargeHeadline>
            <Spacer small />
        </>
    );

    const { showFirstTimeIntegrations } = useNudges();
    const skip = () => {
        nudgesActions.mergeNudges({
            showFirstTimeIntegrations: false,
        });
    };

    const meetingCards = (flex?: boolean) => (
        <>
            <Card itemSpacing="small" flex={flex}>
                <LoadMeetings date={date} />
                <Row alignItems="flex-start" justifyContent="space-between">
                    <Column>
                        <CardTitle>
                            {user.featureFlags?.createMeetingsFlow ? 'Meetings' : 'Scheduled Meetings'}
                        </CardTitle>
                        <SubHeading>{[week[0].format('MM/DD'), week[6].format('MM/DD')].join(' - ')}</SubHeading>
                    </Column>
                    <TextButton onClick={goToScheduled}>
                        <Row alignItems="center" itemSpacing="small">
                            <CalIcon />
                            View all meetings
                        </Row>
                    </TextButton>
                </Row>
                <ScheduledList perWeek={perWeek} onClick={viewEvent} small loading={isLoadingWeek} />
            </Card>
            <Card itemSpacing="small" flex={flex}>
                <LoadMeetings limit={5} />
                <Row>
                    <CardTitle>{user.featureFlags?.createMeetingsFlow ? 'Drafts' : 'Unscheduled Meetings'}</CardTitle>
                </Row>

                <UnschedList meetings={unsched} loading={loadingUnscheduled} onClick={viewEvent} />

                <Row justifyContent="center">
                    <TextButton onClick={goToUnscheduled} asLink>
                        View all {user.featureFlags?.createMeetingsFlow ? 'drafts' : 'meetings'}
                    </TextButton>
                </Row>
            </Card>
        </>
    );

    return (
        <SideNav>
            <Header />

            <Spacer small />
            {user.featureFlags?.playbooksCarousel ? (
                <Row>
                    <Column fillWidth itemSpacing="medium">
                        <TemplatesCarousel onCreateMeeting={onCreateMeeting} />
                        <Row justifyContent="space-between" itemSpacing="medium">
                            {meetingCards(true)}
                        </Row>
                    </Column>
                </Row>
            ) : (
                <Row itemSpacing="small">
                    <Column itemSpacing="default">{meetingCards(false)}</Column>

                    <Column itemSpacing="default">
                        {/* <LoadMeetings limit={10} />
                    <Card itemSpacing="small">
                        <CardTitle>Recent Activity</CardTitle>
                        <LoadMeetingsPageSorted mixed sortBy={sort} />
                        <SortedList meetings={meetingsSorted.slice(0, 7)} onClick={goToMeeting} small />
                        <Row justifyContent="center">
                            <TextButton onClick={goToSorted} asLink>
                                View all recent activity
                            </TextButton>
                        </Row>
                    </Card> */}
                        <LoadEventTemplates />
                        <PagedPlaybooks
                            title="Playbooks"
                            subheading="Choose from our well crafted pre-built meetings."
                            playbooks={globalPlaybooks}
                            isSummary
                            isLoading={loadingPlaybooks}
                        />
                    </Column>
                </Row>
            )}

            {hasHealthSafetyPolicies && (
                <>
                    <Spacer large />
                    <HealthAndSafetyInfo />
                </>
            )}

            {user.team?.bulletinMessage && (
                <>
                    <Spacer larger />
                    <Spacer larger />
                    <HomeScreenBanner message={user.team.bulletinMessage} teamImage={user.team.imageUrlWhite} />
                </>
            )}

            {showFirstTimeIntegrations && <IntegrationsPrompt onSkip={skip} onlySkip title="Connect an Integration?" />}

            {createModalShown && (
                <EventCreateModal
                    defaultFormValues={{
                        ...urlCreateFormValues,
                        ...(carouselPlaybook ? { templateId: carouselPlaybook } : {}),
                    }}
                    onClose={closeCreateModal}
                />
            )}
            {protocolModalShown && <EventCreateProtocolModal onBackdropClick={hideProtocolModal} />}
        </SideNav>
    );
}
