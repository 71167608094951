import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { groupBy } from 'lodash';
import { useHistory } from 'react-router';
import moment from 'moment';

import { useUser } from 'providers/user';
import { useEventTemplates } from 'stores/eventTemplates';
import { isBlankTemplateId } from 'api/eventTemplates';

import { Column, Row, Spacer } from 'ui';
import Button from 'components/ui/Button';
import { H5Headline } from 'components/ui/Headline';
import { SpinnerOverlay } from 'components/Spinner';
import SideNav from 'components/SideNav';
import PagedTemplates from 'components/Templates/PagedTemplates';

import { ESECTION, sectionToTitle, getTemplatesUrlFilters, setTemplatesUrlFilters } from 'components/Templates/utils';
import FilterTokens from 'components/Templates/FilterTokens';
import FiltersPopover from 'components/Templates/FiltersPopover';

const TopHeader = styled(Row)`
    max-width: 970px;
`;

const ButtonRow = styled(Row)`
    width: auto;

    ${Button} {
        width: auto;
    }
`;

const { PERSONAL, SHARED, GLOBAL } = ESECTION;

type TTemplatesByCategory = Record<ESECTION, Bizly.EventTemplate[]>;

const DEFAULT_TEMPLATES: TTemplatesByCategory = {
    [PERSONAL]: [],
    [SHARED]: [],
    [GLOBAL]: [],
};
const ORDERED_CATEGORIES: ESECTION[] = [PERSONAL, SHARED, GLOBAL];

const sortByMostRecent = (templates: Bizly.EventTemplate[]) =>
    templates.slice().sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1));

export default function Templates() {
    const [templatesByCategory, setTemplatesByCategory] = useState<TTemplatesByCategory>(DEFAULT_TEMPLATES);
    const { templates, loading: loadingTemplates } = useEventTemplates();
    useEffect(() => {
        const arrangeTemplates = (allTemplates: Bizly.EventTemplate[]) => {
            const { personal, shared, global } = {
                ...DEFAULT_TEMPLATES,
                ...groupBy(sortByMostRecent(allTemplates), 'category'),
            };
            const globalTempsWithoutBlank = global.filter(({ id }) => !isBlankTemplateId(id));

            return setTemplatesByCategory({
                personal: personal,
                shared: shared,
                global: globalTempsWithoutBlank,
            });
        };

        return templates && arrangeTemplates(templates);
    }, [templates]);

    const history = useHistory();
    const { user } = useUser();

    const { section: currentSection, tags: currentTags } = getTemplatesUrlFilters(history.location);
    useEffect(() => {
        if (currentSection && ![PERSONAL, SHARED, GLOBAL].includes(currentSection))
            setTemplatesUrlFilters(history, curFilterValue => ({ ...curFilterValue, section: undefined }));
    }, [currentSection, history]);

    const showAllCategory = (section: ESECTION) => currentSection === section;
    const showCategory = (section: ESECTION) => !currentSection || showAllCategory(section);

    const getOnShowAll = (category: ESECTION) => () =>
        setTemplatesUrlFilters(history, curFilters => ({ ...curFilters, section: category }));
    const onBack = () => setTemplatesUrlFilters(history, curFilters => ({ ...curFilters, section: undefined }));

    const [showFiltersPopover, setShowFiltersPopover] = useState(false);

    const tagsSet = new Set(currentTags);
    const applyFilter = (templates: Bizly.EventTemplate[]) =>
        tagsSet.size ? templates.filter(template => template.tags?.some(tag => tagsSet.has(tag.id))) : templates;

    const categoryToTitle = (category: ESECTION) => sectionToTitle(category, user);

    return (
        <SideNav>
            <Spacer smallish />

            <Column itemSpacing="medium">
                <Column itemSpacing="xsmall">
                    <TopHeader justifyContent="space-between" alignItems="center">
                        <H5Headline>Playbooks</H5Headline>
                        <ButtonRow itemSpacing="medium">
                            <Button secondary small onClick={() => setShowFiltersPopover(true)}>
                                Filter
                            </Button>
                            <Button onClick={() => history.push('/playbooks/new')} small>
                                Create Playbook
                            </Button>
                        </ButtonRow>
                    </TopHeader>
                    <FilterTokens />
                </Column>
                {showFiltersPopover && <FiltersPopover onClose={() => setShowFiltersPopover(false)} />}

                {ORDERED_CATEGORIES.map(
                    category =>
                        showCategory(category) && (
                            <PagedTemplates
                                key={category}
                                templates={applyFilter(templatesByCategory[category])}
                                title={categoryToTitle(category)}
                                showAll={showAllCategory(category)}
                                onShowAll={getOnShowAll(category)}
                                onBack={onBack}
                                perRow={3}
                            />
                        )
                )}
            </Column>
            {loadingTemplates && <SpinnerOverlay fixedSpinner />}
        </SideNav>
    );
}
