import React from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import keyBy from 'lodash/keyBy';

import { deleteParcel, loadParcels } from 'api';
import { useUser } from 'providers/user';

import { parcelType } from 'utils';

import { Column, CopyFaded, Row, Spacer, Copy, ExternalLink } from 'ui';
import { H3Headline } from 'components/ui/Headline';
import LongButton, { LongButtonMenu } from 'components/ui/Button/LongButton';
import { SpinnerOverlay } from 'components/Spinner';
import { Link, LinkProps } from 'react-router-dom';
import { MenuItem, Tooltip as MuiTooltip } from '@material-ui/core';
import { ProTipsDropdown, filterProtips } from 'components/ProTip';
import CircleCheckIcon from 'components/CircleCheckIcon';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal';

import { ReactComponent as PencilIconSVG } from 'images/icons/pencil.svg';
import { ReactComponent as DeleteIconSVG } from 'images/icons/trash-can.svg';
import { ReactComponent as AirplaneSVG } from 'images/icons/airplane.svg';
import { withInteractibleIconStyles } from 'shared';
import colorFns from 'colorFns';

const CardColumn = styled(Column)`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
`;

const Parcel = styled(LongButton)<{ button?: boolean }>`
    color: inherit;
    cursor: default;

    ${({ button }) =>
        button
            ? `
        width: 215px;
        cursor: pointer;
    `
            : ''}

    > a {
        margin: auto;
    }
`;

const ParcelName = styled(Row)`
    white-space: normal;
    word-break: break-word;
`;

const ColoredLink = styled(Link)`
    color: ${colorFns.primaryAction};
    &:hover {
        color: ${colorFns.primaryActionHover};
    }
`;

const IconsRow = styled(Row)<{ hidden?: boolean }>`
    width: auto;

    ${({ hidden }) => (hidden ? `visibility: hidden;` : '')}
`;

const Warning = styled.span`
    color: ${colorFns.warningText};
`;

const AutoLongButtonMenu = styled(LongButtonMenu)`
    width: 215px;
`;

const SendIcon = withInteractibleIconStyles(AirplaneSVG);
const EditIcon = styled(withInteractibleIconStyles(PencilIconSVG)).attrs({ viewBox: '1.5 2.5 18 21.5' })`
    height: 21px;
    width: 21px;
`;
const DeleteIcon = styled(withInteractibleIconStyles(DeleteIconSVG)).attrs({ viewBox: '2 4 15 21' })`
    height: 21px;
    width: 21px;
`;

const CircleCheckSmall = styled(CircleCheckIcon)`
    width: 24px;
    height: 24px;
    box-sizing: border-box;
`;

const Tooltip = styled(MuiTooltip)`
    cursor: pointer;
`;

const UniversalLink = ({
    link,
    external,
    children,
}: {
    link?: string | false;
    external?: boolean;
    children?: React.ReactNode;
}) => {
    if (link) {
        if (external) {
            return (
                <ExternalLink href={link} openInNewTab>
                    {children}
                </ExternalLink>
            );
        }
        return <ColoredLink to={link}>{children}</ColoredLink>;
    }

    return <>{children}</>;
};

type BasicParcel = { id: number; link: string; name: string; responded: boolean };

type TCommunicationSectionProps = {
    meetingId: string | number;
    editable?: boolean;
    basicParcels?: BasicParcel[];
    toCreateNote: LinkProps['to'];
    toCreateInvite: LinkProps['to'];
};

export default function CommunicationsCard({
    meetingId,
    editable,
    basicParcels = [],
    toCreateNote,
    toCreateInvite,
}: TCommunicationSectionProps) {
    const { enqueueSnackbar } = useSnackbar();

    const [parcels, setParcels] = React.useState<(Bizly.Parcel & { id: string | number })[]>([]);
    const [loading, setLoading] = React.useState(true);
    React.useEffect(() => {
        const load = async () => {
            if (!editable) return setLoading(false);

            try {
                const parcels = await loadParcels(meetingId);
                setParcels(parcels);
            } catch (e) {
                enqueueSnackbar('Something went wrong. Please try again', { variant: 'error' });
            } finally {
                setLoading(false);
            }
        };
        load();
    }, [meetingId, editable, enqueueSnackbar]);

    const [deleteParcelId, setDeleteParcelId] = React.useState<string | number | null>(null);
    const [deleting, setDeleting] = React.useState(false);
    const delParcel = async () => {
        if (deleteParcelId === null) return;

        try {
            setDeleting(true);
            await deleteParcel(meetingId, { id: deleteParcelId });
            setParcels(parcels => parcels.filter(parcel => parcel.id !== deleteParcelId));
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again', { variant: 'error' });
        } finally {
            setDeleteParcelId(null);
            setDeleting(false);
        }
    };

    const { user } = useUser();
    const protips = filterProtips([...(user?.team?.proTips || [])], null, 'communications');

    const basicParcelsDict: Partial<Record<BasicParcel['id'], BasicParcel>> = keyBy(basicParcels, 'id');
    const draftIds = new Set(parcels.filter(parcel => !parcel.sent).map(parcel => parcel.id));

    const Buttons = ({
        hidden,
        parcel,
    }: {
        hidden?: boolean;
        parcel: (Bizly.Parcel & { id: string | number }) | BasicParcel;
    }) => {
        const parcelResponse = basicParcelsDict[parcel.id];

        return (
            <IconsRow alignItems="center" itemSpacing="small" hidden={hidden}>
                {parcelResponse && (
                    <ExternalLink href={parcelResponse.link} openInNewTab>
                        {parcelResponse.responded ? (
                            <Tooltip title="You've already responded to this">
                                <CircleCheckSmall />
                            </Tooltip>
                        ) : (
                            editable && <SendIcon />
                        )}
                    </ExternalLink>
                )}
                {editable && (
                    <Link to={`/event/${meetingId}/communication/edit/${parcelType(parcel)}/${parcel.id}`}>
                        <EditIcon />
                    </Link>
                )}
                {editable && draftIds.has(parcel.id) && <DeleteIcon onClick={() => setDeleteParcelId(parcel.id)} />}
            </IconsRow>
        );
    };

    const header = (
        <>
            <Row alignItems="center" itemSpacing="small">
                <span>
                    <H3Headline>Communications</H3Headline>
                </span>
                {protips.length > 0 ? <ProTipsDropdown protips={protips} /> : null}
            </Row>
            {editable && (
                <>
                    <CopyFaded small>
                        Let’s get your message out there. Here you can create branded invites and send meeting updates.
                    </CopyFaded>
                    <Spacer xsmall />
                </>
            )}
        </>
    );

    if (loading) {
        return (
            <CardColumn itemSpacing="small">
                {header}
                <SpinnerOverlay transparent />
            </CardColumn>
        );
    }

    const parcelItems = [...(editable ? parcels : basicParcels)];

    return (
        <CardColumn itemSpacing="small">
            {header}
            {parcelItems.map(parcel => {
                const parcelName = (
                    <ParcelName alignItems="center" justifyContent="center" itemSpacing="xsmall">
                        <Copy>{parcel.name ? <b>{parcel.name}</b> : <i>Untitled</i>}</Copy>
                        {editable && draftIds.has(parcel.id) && (
                            <Copy small>
                                <Warning>DRAFT</Warning>
                            </Copy>
                        )}
                    </ParcelName>
                );

                const parcelResponse = basicParcelsDict[parcel.id];

                const content = (
                    <Parcel key={parcel.id} nonplussed isSecondary={!editable} button={!editable} onClick={() => {}}>
                        <Buttons hidden parcel={parcel} />
                        {editable ? (
                            <UniversalLink
                                link={
                                    parcelResponse?.link ||
                                    `/event/${meetingId}/communication/edit/${parcelType(parcel)}/${parcel.id}`
                                }
                                external={!!parcelResponse?.link}
                            >
                                {parcelName}
                            </UniversalLink>
                        ) : (
                            parcelName
                        )}
                        <Buttons parcel={parcel} />
                    </Parcel>
                );

                return editable ? (
                    content
                ) : (
                    <UniversalLink link={!editable ? parcelResponse?.link : undefined} external>
                        {content}
                    </UniversalLink>
                );
            })}

            {deleteParcelId !== null && (
                <DeleteConfirmationModal
                    onDismiss={() => setDeleteParcelId(null)}
                    onProceed={delParcel}
                    prompt="Are you sure you want to delete this parcel?"
                    isActive={deleteParcelId !== null}
                    loading={deleting}
                />
            )}

            {editable && (
                <AutoLongButtonMenu label="Add Communication" isSecondary>
                    <MenuItem>
                        <Link to={toCreateNote}>Note / Survey</Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to={toCreateInvite}>Additional Invites</Link>
                    </MenuItem>
                </AutoLongButtonMenu>
            )}
        </CardColumn>
    );
}
