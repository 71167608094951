import colorFns from 'colorFns';
import React from 'react';
import styled from 'styled-components';

import { Copy, Column } from 'ui';
import { RichTextDisplay } from 'components/Form/fields';
import AgendaTimeTable from 'components/ui/AgendaTimeTable';

const Content = styled(Column)`
    min-width: 480px;
`;

const Purpose = styled.p`
    padding: 16px 18px;
    border-radius: 8px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

interface CoverImageProps {
    src: string;
}

const CoverImage = styled.div<CoverImageProps>`
    border-radius: 8px;
    width: 100%;
    height: 0;
    padding: 60% 0 0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-attachment: scroll;
    background-size: cover;

    background-image: url(${props => props.src || 'none'});
`;

const Card = styled(Column)`
    position: relative;
    width: 455px;

    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    border-radius: 8px;

    box-sizing: border-box;

    > * {
        padding: 24px;

        &:not(:last-child) {
            border-bottom: 1px solid ${colorFns.softBorder};
        }
    }

    background-color: ${colorFns.pureWhite};
`;

type TDetails = {
    purpose?: string | null;
    description?: string | null;
    agenda?: BizlyAPI.Meeting['agenda'];
    agendaDescription?: string | null;
};
const MeetingDetail = ({ meeting, imageUrl }: { meeting: TDetails; imageUrl?: string }) => {
    const renderedAgendaDescription = RichTextDisplay({ value: meeting?.agendaDescription || '' });
    const hasAgendaCard = (meeting?.agenda && meeting?.agenda.length > 0) || renderedAgendaDescription;

    return (
        <div>
            <Content itemSpacing="large">
                {meeting.purpose && <Purpose>{meeting.purpose}</Purpose>}

                {imageUrl && <CoverImage src={imageUrl} />}
                <Copy>{meeting.description}</Copy>
                {hasAgendaCard && (
                    <Card>
                        {renderedAgendaDescription && <div>{renderedAgendaDescription}</div>}
                        {meeting?.agenda && meeting.agenda.length > 0 && <AgendaTimeTable agenda={meeting.agenda} />}
                    </Card>
                )}
            </Content>
        </div>
    );
};

export default MeetingDetail;
