import React, { useMemo } from 'react';

import styled from 'styled-components';

import { OnlySingleLine } from '../../shared';
import { bookedStateToBackgroundColor, BookedState } from '../VenueCard';
import { EColors } from 'theme';

export const DEFAULT_PREFERENCE_COLOR = EColors.preferredVenue;

export const ERankColors = {
    r10: EColors.preferredRank10,
    r20: EColors.preferredRank20,
    r30: EColors.preferredRank30,
    r40: EColors.preferredRank40,
    r50: EColors.preferredRank50,
};

export type TPillTypes = 'preferenceCategory' | 'inquiryStatus';

function getPillProps(venue: Bizly.Venue, pillType?: TPillTypes | 'temporarilyClosed') {
    if (pillType === 'temporarilyClosed') {
        return {
            label: 'Temporarily Closed',
            color: EColors.cancelledOrRejectedStatus,
        };
    }

    if (pillType === 'preferenceCategory') {
        const { preferred, preferenceCategory } = venue;
        if (preferenceCategory) {
            return {
                label: preferenceCategory.name,
                color:
                    ERankColors[('r' + preferenceCategory.rank) as keyof typeof ERankColors] ||
                    DEFAULT_PREFERENCE_COLOR,
            };
        }
        if (preferred) {
            return {
                label: 'Preferred',
                color: DEFAULT_PREFERENCE_COLOR,
            };
        }
    }

    if (pillType === 'inquiryStatus') {
        const { status } = venue;
        return {
            label: status,
            color: bookedStateToBackgroundColor[status as BookedState],
        };
    }

    return {};
}

const Pill = styled.div<{ backgroundColor?: EColors }>`
    font-size: 11px;
    font-weight: 500;
    line-height: 1.18;
    text-transform: uppercase;

    max-height: 11px;
    ${OnlySingleLine};

    color: ${({ color, theme: { getColor, EColors } }) => color || getColor(EColors.pureWhite)};
    background-color: ${({ backgroundColor, theme: { getColor } }) =>
        getColor(backgroundColor || DEFAULT_PREFERENCE_COLOR)};

    padding: 4px 12px;
    border-radius: 100px;
    box-shadow: 0 2px 10px 0 ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.3)};
`;

type TPillProps = {
    venue: Bizly.Venue;
    pillType?: TPillTypes | 'temporarilyClosed';
};
export default function VenuePill({ venue, pillType }: TPillProps) {
    const { label, color } = useMemo(() => (venue ? getPillProps(venue, pillType) : {}), [venue, pillType]);

    if (!label && !color) {
        return null;
    }

    return <Pill backgroundColor={color}>{label}</Pill>;
}
