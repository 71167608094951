import { get, post, deleteCall } from '.';
import debounce from 'awesome-debounce-promise';

export const searchCollaborators = debounce(
    (query: string, eventId?: number | string) =>
        get(`collaborators/suggested?query=${query}${eventId ? `&event_id=${eventId}` : ''}`) as Promise<{
            suggestions: BizlyAPI.EventCollaboratorSuggestion[];
        }>,
    200
);

export const addCollaborators = (collaboratorsArr: BizlyAPI.NewEventCollaborator[], eventId: number) =>
    post(`events/${eventId}/collaborators/batch`, { collaborators: collaboratorsArr }, { showErrorCode: true });

export function isAddCollaboratorsPartialError(error: unknown): error is BizlyAPIError.AddCollaborators {
    const expectedError = error as BizlyAPIError.AddCollaborators;
    return expectedError?.raw?.success === false && Array.isArray(expectedError?.raw?.failedEmails);
}

export const removeCollaborator = (eventId: number, collaboratorId: number) =>
    deleteCall(`events/${eventId}/collaborators/${collaboratorId}`);
