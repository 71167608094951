import React, { useState } from 'react';

import { css } from 'styled-components/macro';

import Popover from '@material-ui/core/Popover';
import HelpSharpIcon from '@material-ui/icons/HelpSharp';

import { withIconStyles } from 'shared';

const HelpIcon = withIconStyles(HelpSharpIcon);

type helpPopoverTypes = {
    copy: string;
};

const HelpPopover = ({ copy }: helpPopoverTypes) => {
    const [hoverAnchorEl, setHoverAnchorEl] = useState<Nullable<Element>>(null);

    return (
        <>
            <HelpIcon
                onMouseEnter={(event: React.MouseEvent<SVGElement, MouseEvent>) => {
                    setHoverAnchorEl(event.currentTarget);
                }}
                onMouseLeave={() => setHoverAnchorEl(null)}
            />
            <Popover
                css={css`
                    pointer-events: none;
                    div {
                        max-width: 450px;
                        padding: 15px;
                    }
                `}
                open={!!hoverAnchorEl}
                anchorEl={hoverAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {copy}
            </Popover>
        </>
    );
};

export default HelpPopover;
