import React from 'react';

import styled from 'styled-components';
import { useParams, useLocation } from 'react-router';
import { parse } from 'query-string';
import { useSnackbar } from 'notistack';
import sortBy from 'lodash/sortBy';

import { Column } from 'ui';
import FormManager from 'components/ProposalForm/FormManager';
import { Spinner } from 'components/Spinner';

import {
    getProposalForm,
    putProposalForm,
    sendProposalForm,
    postProposalFormContact,
    rejectProposalForm,
    cancelProposalForm,
    rejectProposalExtension,
    putVenueSpace,
    postVenueSpace,
    TSpacePOST,
    getInquiryRejectionReasons,
} from 'api';

import { TProposalFormUpdate, TProposalForm } from 'components/ProposalForm/types';
import { TSpace } from 'components/ProposalForm/EventSpacesForm/utils';

import { takeFirstIfArray } from '../util';
import { useAuthLocation } from 'App';

const FullScreenCentered = styled(Column)`
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
`;

const MainTitle = styled.h1`
    font-size: 72px;
    line-height: 0;
`;

const SectionHeader = styled.h2`
    font-size: 44px;
    line-height: 0;
`;

export default function ProposalForm() {
    const [loading, setLoading] = React.useState(true);
    const [proposalForm, setProposalForm] = React.useState<Partial<TProposalForm>>({});
    const [rejectionReasons, setRejectionReasons] = React.useState<BizlyAPI.InquiryRejectionReason[]>([]);
    const [rejectionValues, setRejectionValues] = React.useState<{ reason: number; note: string }>();

    const handleRejectionValueChange = ({ value }: { value: typeof rejectionValues }) => {
        setRejectionValues(value);
    };

    const [loadError, setLoadError] = React.useState<any>();

    const authLocation = useAuthLocation();
    const authError = authLocation.state?.error;
    const hasError = authError || loadError;

    const [venueId] = React.useState<string>(takeFirstIfArray(parse(useLocation().search)).venueId || '');
    const { proposalId } = useParams<{ proposalId: string }>();

    React.useEffect(() => {
        const loadReasons = async () => {
            const { inquiryRejectionReasons } = await getInquiryRejectionReasons();
            setRejectionReasons(inquiryRejectionReasons);
        };

        const loadForm = async () => {
            setLoading(true);
            try {
                const data = await getProposalForm(venueId, proposalId);
                await loadReasons();

                data.options.currencies = data.options.currencies && sortBy(data.options.currencies, 'code');
                setProposalForm(data);
            } catch (e) {
                setLoadError(e);
            } finally {
                setLoading(false);
            }
        };
        if (!hasError) loadForm();
    }, [proposalId, venueId, setProposalForm, setLoading, hasError]);

    const { enqueueSnackbar } = useSnackbar();

    const onSave = async (newData: Partial<TProposalFormUpdate>) => {
        const { contact, ...newProposalData } = newData;

        const hasContact = !!contact;
        const hasProposal =
            !!newProposalData.proposal ||
            (newProposalData.eventSpaces?.length || 0) > 0 ||
            (newProposalData.guestRooms?.length || 0) > 0;

        try {
            let response = proposalForm;

            if (hasContact) response = await postProposalFormContact(venueId, proposalId, newData.contact);

            if (hasProposal) {
                const currencyId = newData?.proposal?.currencyCode
                    ? proposalForm.options?.currencies?.find(
                          currencyOption => currencyOption.code === newData.proposal?.currencyCode
                      )?.id
                    : undefined;
                response = await putProposalForm(venueId, proposalId, {
                    // a minimum save needs to have blank arrays
                    eventSpaces: newProposalData.eventSpaces || [],
                    guestRooms: newProposalData.guestRooms || [],
                    proposal: { ...proposalForm.proposal, ...newData.proposal, currencyId },
                });
            }

            setProposalForm(response);
            return true;
        } catch {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            return false;
        }
    };

    const onSubmit = async () => {
        try {
            const response = await sendProposalForm(venueId, proposalId);
            setProposalForm(response);

            return true;
        } catch {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            return false;
        }
    };

    const onReject = async () => {
        // 1 will always be the ID for "Other"
        if (!rejectionValues?.reason) {
            enqueueSnackbar('Please select a Turn Down Reason', { variant: 'error' });
            return false;
        } else if (rejectionValues?.reason === 1 && !rejectionValues?.note) {
            enqueueSnackbar('Please provide a note to explain your selection.', { variant: 'error' });
            return false;
        }

        try {
            const response = await rejectProposalForm(venueId, proposalId, {
                rejectionReason: rejectionReasons.find(reason => reason.id === rejectionValues.reason)?.reason ?? '',
                rejectionNotes: rejectionValues.note ?? '',
            });
            setProposalForm(response);

            return true;
        } catch {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            return false;
        }
    };

    const onCancel = async () => {
        try {
            const response = await cancelProposalForm(venueId, proposalId);
            setProposalForm(response);

            return true;
        } catch {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            return false;
        }
    };

    const onRejectExtension = async () => {
        try {
            const response = await rejectProposalExtension(proposalId);
            setProposalForm(response);

            return true;
        } catch {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            return false;
        }
    };

    const onUpdateVenueSpaces = async (updatedSpace: TSpacePOST = {}) => {
        const { id, ...spaceData } = updatedSpace;

        try {
            const props = {
                venueId,
                proposalId,
                data: spaceData,
            };

            const response = id ? await putVenueSpace({ ...props, spaceId: id }) : await postVenueSpace(props);

            const spaces = response.options.venueSpaces as TSpace[];
            setProposalForm((prev: any) => ({ ...prev, options: { ...prev.options, venueSpaces: spaces } }));
            return spaces;
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            return false;
        }
    };

    if (authError) {
        const code = authError.code;
        return (
            <FullScreenCentered>
                <MainTitle>Something Went Wrong.</MainTitle>
                <SectionHeader>
                    {code === 401 ? 'Please contact support.' : 'Please contact support or try refreshing the page.'}
                </SectionHeader>
            </FullScreenCentered>
        );
    }

    if (loadError) {
        const code = loadError.code;
        return (
            <FullScreenCentered>
                <MainTitle>Something Went Wrong.</MainTitle>
                <SectionHeader>
                    {code === 401
                        ? 'Please contact support or try visiting the link in your email again.'
                        : 'Please contact support.'}
                </SectionHeader>
            </FullScreenCentered>
        );
    }

    return loading ? (
        <FullScreenCentered>
            <Spinner />
        </FullScreenCentered>
    ) : (
        <>
            <FormManager
                proposalForm={proposalForm}
                onSave={onSave}
                onSubmit={onSubmit}
                onReject={onReject}
                onCancel={onCancel}
                onRejectExtension={onRejectExtension}
                onUpdateVenueSpaces={onUpdateVenueSpaces}
                inquiryRejectionReasons={rejectionReasons}
                handleRejectionValueChange={handleRejectionValueChange}
                rejectionValues={rejectionValues}
            />
        </>
    );
}
