import React, { createRef, useState } from 'react';
import styled from 'styled-components';

import { Popover } from '@material-ui/core';

import { Column, Copy } from 'ui';
import SmallCircleButton from 'components/ui/SmallCircleButton';

import TemplatePopover from './TemplatePopover';
import Pill from 'components/ui/Pill';

import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';
import { EColors } from 'theme';
import useThemedColor from 'hooks/useThemedColor';
import colorFns from 'colorFns';

const Tile = styled(Column)<{ imgSrc?: string; backgroundColor?: Themed.Color }>`
    position: relative;

    width: 100%;
    height: 189px;
    overflow: hidden;

    ${props => (props.onClick ? `cursor: pointer;` : ``)}

    border-radius: 8px;
    background-color: ${({ backgroundColor, theme: { getColor, EColors } }) =>
        backgroundColor || getColor(EColors.pureWhite)};

    ${({ imgSrc }) =>
        imgSrc
            ? `
        background-image: url(${imgSrc});
        background-size: cover;
        `
            : ``};

    z-index: 0;
`;

const TopRow = styled.div`
    max-width: 110px;
    padding: 15px;
`;

const ShadedTileContent = styled(Column)`
    height: 100%;
    padding: 12px;

    justify-content: flex-end;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        z-index: -1;

        border-radius: 8px;
        background-image: linear-gradient(
            to bottom,
            ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0)} 17%,
            ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.7)}
        );

        opacity: 1; /* gradients can't be animated */
        transition: 0.2s all ease-out;
    }

    &:hover::before {
        opacity: 0.8;
    }
`;

const WrappingCopy = styled(Copy)`
    word-break: break-word;
    max-height: 200px;
`;

const TilePopover = styled(Popover)`
    pointer-events: none;

    .MuiPopover-paper {
        border-radius: 8px;
        box-shadow: 0 2px 44px 0 ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.1)};

        pointer-events: all;
    }
`;

type TTemplateTile = {
    template: Bizly.EventTemplate;
    team?: Bizly.Team;
    onSelectTemplate: (templateId: number) => void;
};
export default function TemplateTile({ template, team, onSelectTemplate }: TTemplateTile) {
    const { teamTemplatePill } = useThemedColor();

    const { id, teamId, name, briefImageSmall } = template;
    const selectTemplate = () => onSelectTemplate(id);

    const tileRef = createRef<HTMLDivElement>();
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const open = !!anchorEl;
    const showPopover = () => !open && setAnchorEl(tileRef.current);
    const hidePopover = () => open && setAnchorEl(null);

    return (
        <Tile
            imgSrc={briefImageSmall}
            ref={tileRef}
            onMouseMove={showPopover}
            onMouseLeave={hidePopover}
            onClick={selectTemplate}
        >
            {teamId && team?.name && (
                <TopRow>
                    <Pill color={teamTemplatePill} label={`${team.name || 'Team'} Playbook`} />
                </TopRow>
            )}

            <TilePopover
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                onClose={hidePopover}
                onClick={e => e.stopPropagation()}
                PaperProps={{
                    // prevents user from hovering back onto a disappearing popover
                    style: { ...(anchorEl ? {} : { pointerEvents: 'none' }) },
                }}
            >
                <TemplatePopover template={template} onSelectTemplate={selectTemplate} />
            </TilePopover>

            <ShadedTileContent>
                <WrappingCopy $color={EColors.pureWhite}>{name}</WrappingCopy>
            </ShadedTileContent>
        </Tile>
    );
}

const CenteredButton = styled(SmallCircleButton)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    width: 36px;
    height: 36px;
    border-radius: 18px;

    margin: auto;

    pointer-events: none;
`;

const BlankTileContent = styled(Column)`
    flex: 1 0 0;
    position: relative;
    min-height: 0;
`;

const BlankTileName = styled(Column)`
    flex: 0 0 auto;
    padding: 12px;
    background-color: ${colorFns.pureWhite};
    color: ${colorFns.darkestGrey};
`;

export function TemplatelessTile({ onClick }: { onClick: () => void }) {
    const { lightGrey } = useThemedColor();

    return (
        <Tile onClick={onClick} backgroundColor={lightGrey}>
            <BlankTileContent>
                <CenteredButton secondary={false}>
                    <PlusIcon />
                </CenteredButton>
            </BlankTileContent>
            <BlankTileName>
                <Copy>Create from blank</Copy>
            </BlankTileName>
        </Tile>
    );
}

export const EmptyTemplateTile = () => {
    const { pureBlack: translucentPureBlack } = useThemedColor(0.05);
    return <Tile backgroundColor={translucentPureBlack} />;
};
