import { get, put, post, deleteCall } from '.';

import maxBy from 'lodash/maxBy';

export const getInquiriesForEvent = (eventId: number) =>
    get(`events/${eventId}/inquiries`) as Promise<{ inquiries: BizlyAPI.Inquiry[] }>;

export const getCurrentInquiry = async (eventId: number) => {
    const { inquiries: existingInquiries } = await getInquiriesForEvent(eventId);
    return maxBy(existingInquiries, 'id') ?? null;
};

export const createInquiryDraft = (eventId: number) =>
    post(`events/${eventId}/inquiries`) as Promise<{ inquiry: BizlyAPI.Inquiry }>;

export const updateBookingInquiry = (
    inquiryId: number,
    data: Partial<Pick<BizlyAPI.Inquiry, 'notes' | 'flexibleDates' | 'scheduleItemIds'>>
) => put(`booking-inquiries/${inquiryId}`, data) as Promise<{ inquiry: BizlyAPI.Inquiry }>;

export const removeVenueFromInquiry = (inquiryId: number, venueId: number) =>
    deleteCall(`booking-inquiries/${inquiryId}/venues/${venueId}`) as Promise<{
        inquiry: BizlyAPI.Inquiry;
    }>;

export const submitInquiry = (inquiryId: number) =>
    post(`booking-inquiries/${inquiryId}/submit`) as Promise<{
        inquiry: BizlyAPI.Inquiry;
    }>;

export const cancelAndCopyInquiry = (inquiryId: number | string) =>
    post(`booking-inquiries/${inquiryId}/copy`) as Promise<{ inquiry: BizlyAPI.Inquiry }>;
