import React from 'react';

import { useSnackbar } from 'notistack';

import { playbooksActions } from 'stores/playbooks';

export default function LoadPlaybookCollaborators({ id }: { id: number | string }) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        const load = async () => {
            try {
                await playbooksActions.loadPlaybookCollaborators(id);
            } catch (e) {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            }
        };
        load();
    }, [enqueueSnackbar, id]);
    return null;
}
