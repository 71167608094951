import React from 'react';

import styled from 'styled-components';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { DisplayField } from 'components/FormFields';

import colorFns from 'colorFns';

const containerClass = 'phoneContainer';
const inputClass = 'phoneInput';
const buttonClass = 'phoneDropdown';

const Wrapper = styled.div`
    & .${containerClass} {
        margin-top: 8px;
        margin-bottom: 4px;
    }

    & .${inputClass} {
        padding-top: 10.5px;
        padding-bottom: 10.5px;

        width: 100%;

        height: calc(1.1875em + 10.5px + 10.5px);
        box-sizing: border-box;

        border-radius: 8px;
        border-color: ${colorFns.lightGrey};

        transition: border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, border-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

        &:hover,
        &:focus {
            border-color: ${colorFns.pureBlack.alpha(0.23)};
            box-shadow: 0 2px 4px 0 ${colorFns.pureBlack.alpha(0.06)};
        }
    }

    & .${buttonClass} .selected-flag {
        & .flag {
            margin-top: 0 !important;
            transform: translateY(calc(-50% - 2px));
        }

        & .arrow {
            margin-top: 1px !important;
        }
    }

    & .${buttonClass} .selected-flag[title=''] .flag {
        background-position: 24px 24px;
    }

    & .special-label {
        display: none;
    }
`;

declare namespace Intl {
    export class Locale {
        constructor(languageCode: string);

        region?: string;
    }
}

const getCountry = () => {
    try {
        const Locale = Intl.Locale;
        return Locale ? new Locale(navigator.language).region?.toLowerCase() : undefined;
    } catch {}
};

export default function PhoneField({
    field,
    value,
    onChange,
    disabled,
    readonly,
    initialCountry,
}: {
    field: string;
    value?: string;
    onChange: (update: { field: string; value: string }) => void;
    disabled?: boolean;
    readonly?: boolean;
    initialCountry?: string;
}) {
    const [eraseCount, setEraseCount] = React.useState(0);
    return readonly ? (
        <DisplayField label={value} />
    ) : (
        <Wrapper>
            <PhoneInput
                key={eraseCount}
                value={value}
                onChange={(newVal, country) => {
                    type CountryData = typeof country extends {} | infer CountryDataType ? CountryDataType : never;

                    function isCountryData(data?: typeof country): data is CountryData {
                        return (data as CountryData)?.countryCode !== undefined;
                    }

                    if (isCountryData(country) && newVal.length === 0) {
                        if (value === '+' + country.dialCode) return setEraseCount(count => count + 1);
                        return onChange({ field, value: '+' + country.dialCode });
                    }

                    if (isCountryData(country) && !newVal.startsWith(country.dialCode)) {
                        return onChange({
                            field,
                            value: '+' + country.dialCode,
                        });
                    }

                    onChange({ field, value: '+' + newVal });
                }}
                containerClass={containerClass}
                inputClass={inputClass}
                buttonClass={buttonClass}
                country={initialCountry || getCountry()}
                disabled={disabled}
            />
        </Wrapper>
    );
}
