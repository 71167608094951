import React, { MouseEvent } from 'react';

import styled, { css } from 'styled-components/macro';

import MenuOriginal from '@material-ui/core/Menu';
import MenuItemOriginal from '@material-ui/core/MenuItem';

import { ReactComponent as CloseIcon } from '../images/icons/close.svg';
import { ReactComponent as VenuePin } from '../images/icons/venue_pin.svg';

import { Row } from '../ui';
import { EColors } from 'theme';

export type BookedState = Bizly.Venue['status'];

export const REJECTED_STATUSES = new Set<BookedState>([
    'Proposal Rejected',
    'Inquiry Rejected',
    'Inquiry Cancelled',
    'Proposal Cancelled',
    'Booking Cancelled',
    'Extension Rejected',
]);
export const REJECTED_OR_ADDED = new Set<BookedState>([...REJECTED_STATUSES, 'Added']);

type VenueCardProps = {
    wide?: boolean;
    narrow?: boolean;
    onClick?: () => void;
    children: React.ReactNode;
};

export const bookedStateToBackgroundColor: { [key in BookedState]: EColors } = {
    Added: EColors.officePillColor, // this pill type shouldn't appear any more
    Submitted: EColors.submittedInquiryStatus,
    'Proposal Received': EColors.receivedProposalStatus,
    'Proposal Rejected': EColors.cancelledOrRejectedStatus,
    'Proposal Cancelled': EColors.cancelledOrRejectedStatus,
    'Extension Pending': EColors.contractPendingStatus,
    'Extension Rejected': EColors.cancelledOrRejectedStatus,
    'Proposal Expired': EColors.cancelledOrRejectedStatus,
    'Inquiry Rejected': EColors.cancelledOrRejectedStatus,
    'Inquiry Cancelled': EColors.cancelledOrRejectedStatus,
    'Contract Pending': EColors.contractPendingStatus,
    Booked: EColors.bookedVenueStatus,
    'Booking Cancelled': EColors.cancelledOrRejectedStatus,
};

export const EmptyCardWrapper = styled.div`
    width: 266px;
    height: 172px;
    border-radius: 8px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.placeholderBackground)};

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const EmptyCard = (props: any) => (
    <EmptyCardWrapper {...props}>
        <VenuePin
            css={css`
                color: ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
            `}
        />
    </EmptyCardWrapper>
);

const VenueCardCloseWrapper = styled.div``;

export const VenueCardClose = ({ onClick, ...props }: { onClick?: () => void }) => (
    <VenueCardCloseWrapper
        onClick={e => {
            e.stopPropagation();
            if (onClick) {
                onClick();
            }
        }}
        {...props}
    >
        <CloseIcon />
    </VenueCardCloseWrapper>
);

const VenueCardStatusBannerWrapper = styled.div``;

export const VenueCardStatusBanner = ({ children }: { children: BookedState }) => (
    <VenueCardStatusBannerWrapper
        css={css`
            background-color: ${({ theme: { getColor } }) => getColor(bookedStateToBackgroundColor[children])};
        `}
    >
        <div
            css={css`
                margin-bottom: 2px;
            `}
        >
            {children.toUpperCase()}
        </div>
    </VenueCardStatusBannerWrapper>
);

export const VenueCardAddress = styled.div``;

export const VenueCardHeadline = styled.div``;
export const VenueCardName = styled.label``;
export const VenueCardImage = styled.img``;

export const VenueCardPopoverItem = ({
    children,
    ...props
}: {
    onClick: (event: any) => void;
    children: React.ReactNode;
}) => <MenuItemOriginal {...props}>{children}</MenuItemOriginal>;

const VenueCardPopoverWrapper = styled.div`
    cursor: pointer;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const Dot = styled.div`
    width: 4px;
    height: 4px;

    background-color: currentColor;
    border-radius: 50%;

    &:not(:last-child) {
        margin-right: 3px;
    }
`;
const MenuIconTarget = styled(Row)<{ alignBottom?: boolean }>`
    width: 24px;
    height: 24px;
    padding-bottom: 12px;
    transform: translateY(12px);

    justify-content: center;
    align-items: ${props => (props.alignBottom ? 'flex-end' : 'center')};

    &:hover {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
    }
    transition: 0.2s all ease-in;
`;
const MenuIcon = () => (
    <MenuIconTarget alignBottom>
        <Dot />
        <Dot />
        <Dot />
    </MenuIconTarget>
);

export const VenueCardPopover = ({ options }: { options: { label: string; handler: () => void }[] }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | Element>(null);
    function handleClick(event: MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget as Element);
    }

    function handleClose() {
        setAnchorEl(null);
    }
    return (
        <VenueCardPopoverWrapper onClick={e => e.stopPropagation()}>
            <div aria-owns={anchorEl ? 'simple-menu' : undefined} aria-haspopup="true" onClick={handleClick}>
                <MenuIcon />
            </div>
            <MenuOriginal
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                disableAutoFocusItem
            >
                {options.map(({ label, handler }) => (
                    <VenueCardPopoverItem key={label} onClick={handler}>
                        {label}
                    </VenueCardPopoverItem>
                ))}
            </MenuOriginal>
        </VenueCardPopoverWrapper>
    );
};

type VenueCardWrapperProps = {
    bookedState?: BookedState;
    wide?: boolean;
    narrow?: boolean;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
};

const VenueCardWrapper = styled('div')<VenueCardWrapperProps>`
    width: ${props => (props.wide ? '672px' : '266px')};
    height: ${props => (props.wide ? '307px' : '172px')};

    position: relative;
    ${props => props.onClick && 'cursor: pointer;'}

    &::after {
        display: block;
        position: relative;
        margin-top: ${props => (props.wide ? '-46%' : props.narrow ? '-84%' : '-66%')};
        height: 100%;
        width: 100%;
        content: '';
        border-radius: 8px;

        background: ${({ bookedState, theme: { getColor, EColors } }) =>
            bookedState && ['Inquiry Cancelled', 'Proposal Cancelled', 'Proposal Rejected'].includes(bookedState)
                ? getColor(EColors.lightGrey)
                : `linear-gradient(to bottom, 
                    ${getColor(EColors.pureBlack, 0)}, ${getColor(EColors.pureBlack, 0.87)}
                )`};
        opacity: ${props =>
            props.bookedState &&
            ['Inquiry Cancelled', 'Proposal Cancelled', 'Proposal Rejected'].includes(props.bookedState)
                ? 0.7
                : null};
    }

    & > ${VenueCardCloseWrapper} {
        position: absolute;
        top: 3px;
        right: 3px;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

        z-index: 10;
        cursor: pointer;

        & path {
            stroke-width: 1px;
            stroke: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        }
    }

    & > ${VenueCardStatusBannerWrapper} {
        height: 21px;
        border-radius: 14px;
        box-shadow: 0 2px 10px 0 ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.3)};

        font-size: 11px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.18;
        letter-spacing: normal;
        text-align: center;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

        position: absolute;
        top: 10px;
        left: 10px;

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 0 10px;

        z-index: 1;
    }

    & > ${VenueCardAddress} {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        position: absolute;
        bottom: 20px;
        right: 13px;

        z-index: 1;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 248px;
    }

    & > ${VenueCardHeadline} {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        text-transform: uppercase;

        position: absolute;
        bottom: 56px;
        left: 13px;

        z-index: 1;
    }

    & > ${VenueCardName} {
        font-size: ${props => (props.wide ? '36px' : '20px')};
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: -0.4px;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.featuredVenueOrSpaceName)};

        position: absolute;
        bottom: 12px;
        left: 10px;

        z-index: 1;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: ${props => (props.wide ? '328px' : '220px')};
    }

    & > ${VenueCardPopoverWrapper} {
        cursor: pointer;

        position: absolute;

        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        right: 10px;
        bottom: 10px;
        z-index: 20;
    }

    & > ${VenueCardImage} {
        height: 100%;
        width: 100%;
        border-radius: 8px;
    }
`;

export const VenueCard = ({ wide, narrow, children, ...props }: VenueCardProps) => (
    <VenueCardWrapper wide={wide} narrow={narrow} {...props}>
        {children}
    </VenueCardWrapper>
);

export default VenueCard;
