import React from 'react';

import styled from 'styled-components';
import { parse } from 'query-string';
import { useLocation } from 'react-router';
import { useMediaQuery } from '@material-ui/core';

import { takeFirstIfArray } from '../../util';

import { FORM_SECTIONS, TProposalFormUpdate, TProposal, TProposalForm } from './types';
import { TSpace } from './EventSpacesForm/utils';
import { TSpacePOST } from 'api';

import { Row, Column, Spacer, Copy } from 'ui';
import Button from 'components/ui/Button';
import { Headline } from 'components/ui/Headline';
import ThemedLogo from 'components/ui/ThemedLogo';
import { ReactComponent as ForwardArrowSVG } from 'images/icons/forward_arrow.svg';

import { IntroHeader, ProposalDetailsHeader } from './MainFormHeaders';
import FooterButtons from './FooterButtons';
import ContactInformationForm from './ContactInformationForm';
import GuestroomsForm from './GuestroomsForm';
import EventSpacesForm from './EventSpacesForm';
import ProposalNotesForm from './ProposalNotesForm';
import ProposalFormStatus from './ProposalFormStatus';

import ProgressTracker from './ProgressTracker';
import { SpinnerOverlay } from 'components/Spinner';

import { useFormSectionsHistory, useUnsavedPrompt, useFormValidator, useConfirmModal } from './utils';

import { getProposalFormStatus, getStatusAttributes, TAction } from './statusUtils';
import colorFns from 'colorFns';
import { withIconStyles } from 'shared';
import RejectionReasonForm from 'components/ProposalForm/RejectionReasonForm';

const SECTIONS = [
    FORM_SECTIONS.CONTACT,
    FORM_SECTIONS.GUEST_ROOMS,
    FORM_SECTIONS.EVENT_SPACES,
    FORM_SECTIONS.NOTES,
    FORM_SECTIONS.PROPOSAL_STATUS,
];
const PROGRESS_SECTIONS = SECTIONS.slice(0, -1);

const SECTION_TO_COMPONENT = {
    [FORM_SECTIONS.CONTACT]: ContactInformationForm,
    [FORM_SECTIONS.GUEST_ROOMS]: GuestroomsForm,
    [FORM_SECTIONS.EVENT_SPACES]: EventSpacesForm,
    [FORM_SECTIONS.NOTES]: ProposalNotesForm,
    [FORM_SECTIONS.PROPOSAL_STATUS]: ProposalFormStatus,
};

type TFormManager = {
    proposalForm: Partial<TProposalForm>;
    onSave: (newData: Partial<TProposalFormUpdate>) => Promise<boolean>;
    onSubmit: () => Promise<boolean>;
    onReject: () => Promise<boolean>;
    onCancel: () => Promise<boolean>;
    onRejectExtension: () => Promise<boolean>;
    onUpdateVenueSpaces: (space: TSpacePOST) => Promise<TSpace[] | false>;
    inquiryRejectionReasons: BizlyAPI.InquiryRejectionReason[];
    rejectionValues?: { reason: number; note: string };
    handleRejectionValueChange: ({ value }: { value: { reason: number; note: string } }) => void;
};

const SCROLLBAR_WIDTH = 20; // at most
const WIDE_PAGE_WIDTH = 1366 - SCROLLBAR_WIDTH;

const Shell = styled(Row)`
    position: relative;
    margin: 0;
    overflow: auto;
`;

const sideWidth = 280;
const topHeight = 140;
const padding = 36;
const contentPadding = 94;

const Side = styled(Column)`
    position: absolute;
    width: ${sideWidth}px;
    height: 100%;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    padding: ${padding}px;
    box-sizing: border-box;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    z-index: 100;
`;

const TopStepTracker = styled(Row)`
    height: ${topHeight}px;

    ${Headline} {
        margin-top: -12px;
    }
`;

const Top = styled(Column)`
    position: fixed;
    width: 100vw;
    align-items: center;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    padding: 0 ${padding}px;
    box-sizing: border-box;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    z-index: 100;
`;

const Banner = styled(Row)<{ isWidthConstrained?: boolean }>`
    padding: 8px 0;
    ${({ ...props }) => `    
    background: ${colorFns.strongAccentedBackground(props)};
    color: ${colorFns.pureWhite(props)};`}
    position: fixed;
    align-items: center;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    box-sizing: border-box;
    z-index: 100;
    ${({ isWidthConstrained }) =>
        isWidthConstrained
            ? `
    width: 100vw;
    top: ${topHeight}px;
    `
            : `
    width: calc(100% - ${sideWidth}px);
    left: ${sideWidth}px;
    `}
`;

const Main = styled(Column)<{ fullscreen?: boolean }>`
    position: relative;
    width: 100%;
    min-width: 968px;
    max-width: 1280px;
    border-radius: 12px;
    margin-top: ${padding}px;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    ${({ fullscreen }) =>
        fullscreen
            ? `
    margin-top: ${topHeight + padding}px;
    `
            : `
    margin-left: ${sideWidth}px;
    `}
    box-sizing: border-box;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const PaddedColumn = styled(Column)`
    padding: 0 ${contentPadding}px;
    min-height: 100vh;
`;

const BizlyLogo = styled(ThemedLogo)`
    max-width: 0;
`;

const ForwardArrow = styled(withIconStyles(ForwardArrowSVG))`
    height: 16px;
    width: 16px;
    padding-top: 4px;
    color: #ffffff;
`;

const StickyWrapper = styled(Column)`
    position: sticky;
    top: ${padding}px;
    max-height: calc(100vh - ${padding * 2}px);
    overflow: auto;
`;

const FixedSpinnerOverlay = styled(SpinnerOverlay)`
    position: fixed;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 200;
`;

const FormColumn = styled(Column)`
    flex-grow: 1;
    max-width: 1080px;
`;

const SECTION_TO_LABEL = {
    [FORM_SECTIONS.CONTACT]: 'Contact Information',
    [FORM_SECTIONS.GUEST_ROOMS]: 'Guest Rooms',
    [FORM_SECTIONS.EVENT_SPACES]: 'Meeting Spaces',
    [FORM_SECTIONS.NOTES]: 'Finishing Touches',
    [FORM_SECTIONS.PROPOSAL_STATUS]: 'Proposal Status',
};

export default function FormManager({
    proposalForm = {},
    onSave: onSaveProp,
    onSubmit: onSubmitProp,
    onUpdateVenueSpaces,
    onReject,
    onCancel,
    onRejectExtension,
    inquiryRejectionReasons,
    rejectionValues,
    handleRejectionValueChange,
}: TFormManager) {
    const [loading, setLoading] = React.useState(false);
    const [hasSaved, setHasSaved] = React.useState(true);

    const [userChangedStatus, setUserChangedStatus] = React.useState(false);

    const action = takeFirstIfArray(parse(useLocation().search)).action || '';
    const userAction = (action as TAction) || 'accept';

    const status = getProposalFormStatus(proposalForm);
    const statusAttributes = getStatusAttributes(status, userAction, userChangedStatus);
    const pendingExtensionRequest =
        proposalForm &&
        !!proposalForm.proposal?.extensionRequestedAt &&
        !proposalForm.proposal.extensionRejectedAt &&
        statusAttributes.canSubmit;

    const {
        sectionIndex,
        goBack: onBack,
        nextSection: onContinueProp,
        restart: onRestartProp,
        goToDateStep,
        goToEnd,
    } = useFormSectionsHistory(
        SECTIONS,
        userAction === 'accept-extension' && pendingExtensionRequest ? 3 : statusAttributes.skipToStatus ? 4 : 0
    );
    const section = SECTIONS[sectionIndex];

    const FormSection = SECTION_TO_COMPONENT[section];
    const onSectionChange = React.useCallback(() => setHasSaved(false), [setHasSaved]);

    const [headerProposalData, setHeaderProposalData] = React.useState<Partial<TProposal>>();
    const onHeaderChange = React.useCallback(
        (newProposalData: Partial<TProposal>) => {
            setHasSaved(false);
            setHeaderProposalData(newProposalData);
        },
        [setHeaderProposalData]
    );

    React.useEffect(() => {
        setHasSaved(true);
        setHeaderProposalData(undefined);
    }, [FormSection, setHeaderProposalData]);

    const renderPrompt = useUnsavedPrompt(!hasSaved, newLocation => newLocation.state?.sectionIndex === sectionIndex);

    const { validationFn, registerValidator } = useFormValidator(section);

    const onSave = React.useCallback(
        async (isContinue?: boolean) => {
            const skipSaving = statusAttributes.readonly;
            if (skipSaving) return true;

            let newData = validationFn(isContinue);
            if (!newData) return false;
            if (headerProposalData) newData = { ...newData, proposal: { ...newData.proposal, ...headerProposalData } };

            if (!hasSaved || !isContinue) {
                setLoading(true);
                const success = await onSaveProp(newData);
                if (success) {
                    setHasSaved(true);
                }
                if (!isContinue) setLoading(false);
                return success;
            }

            return true;
        },
        [validationFn, hasSaved, onSaveProp, headerProposalData, statusAttributes.readonly]
    );

    const buttonOnSave = React.useCallback(() => onSave(), [onSave]);

    const onContinue = React.useCallback(async () => {
        const saved = await onSave(true);
        setLoading(false);

        if (saved) onContinueProp();
    }, [onSave, onContinueProp]);

    const onSubmit = React.useCallback(async () => {
        const saved = await onSave(true);
        if (saved) {
            setLoading(true);
            const submitted = await onSubmitProp();
            setLoading(false);

            if (submitted) onContinueProp();
        }
    }, [onSave, onSubmitProp, onContinueProp]);

    const onSuccess = React.useCallback(() => {
        goToEnd();
        setUserChangedStatus(true);
    }, [goToEnd, setUserChangedStatus]);

    const rejectModalProps = React.useMemo(
        () => ({
            showOnLoad: userAction === 'reject' && status === 'Inquiry Submitted',
            prompt: (
                <RejectionReasonForm
                    onChange={handleRejectionValueChange}
                    value={rejectionValues}
                    options={inquiryRejectionReasons}
                />
            ),
            onConfirm: onReject,
            onDismiss: onRestartProp,
            onSuccess,
        }),
        [
            userAction,
            status,
            handleRejectionValueChange,
            rejectionValues,
            inquiryRejectionReasons,
            onReject,
            onRestartProp,
            onSuccess,
        ]
    );

    const { renderModal: renderRejectModal, loading: rejectLoading, openModal: showRejectModal } = useConfirmModal(
        rejectModalProps
    );

    const extensionRequestModalProps = React.useMemo(
        () => ({
            showOnLoad: userAction === 'reject-extension' && status === 'Proposal Submitted' && pendingExtensionRequest,
            prompt: 'Are you sure you want to reject this extension request?',
            onConfirm: onRejectExtension,
            onDismiss: () => {},
            onSuccess,
        }),
        [onRejectExtension, onSuccess, status, userAction, pendingExtensionRequest]
    );

    const {
        renderModal: renderRejectExtensionModal,
        loading: rejectExtensionLoading,
        openModal: showRejectExtensionModal,
    } = useConfirmModal(extensionRequestModalProps);

    const cancelModalProps = React.useMemo(
        () => ({
            showOnLoad: userAction === 'reject' && status === 'Proposal Submitted',
            prompt: 'Are you sure you want to cancel your proposal?',
            onConfirm: onCancel,
            onDismiss: goToEnd,
            onSuccess,
        }),
        [userAction, status, onCancel, goToEnd, onSuccess]
    );

    const { renderModal: renderCancelModal, loading: cancelLoading, openModal: showCancelModal } = useConfirmModal(
        cancelModalProps
    );

    const loadingAsync = loading || rejectLoading || rejectExtensionLoading || cancelLoading;

    const isWidthConstrained = useMediaQuery(`(max-width: ${WIDE_PAGE_WIDTH}px)`);

    return (
        <Shell>
            {pendingExtensionRequest && status === 'Proposal Submitted' && (
                <Banner
                    justifyContent="center"
                    itemSpacing="small"
                    withBorderPadding="small"
                    isWidthConstrained={isWidthConstrained}
                >
                    <Copy large>You have received an extension request for this proposal</Copy>
                    <Button width={200} onClick={goToDateStep}>
                        <Row justifyContent="center" itemSpacing="small" alignItems="center">
                            <Copy>Edit Expiration Date</Copy>
                            <ForwardArrow />
                        </Row>
                    </Button>
                    <Button onClick={showRejectExtensionModal} warning width={130}>
                        Reject Request
                    </Button>
                </Banner>
            )}
            {isWidthConstrained ? (
                <Top>
                    <TopStepTracker alignItems="center">
                        <BizlyLogo />
                        <Column alignItems="center" justifyContent="center" fillWidth>
                            {proposalForm.venue?.name && <Headline>{proposalForm.venue.name}</Headline>}
                            {!(statusAttributes.noProposal && section === FORM_SECTIONS.PROPOSAL_STATUS) && (
                                <ProgressTracker
                                    sections={PROGRESS_SECTIONS.map(section => ({
                                        key: section,
                                        label: SECTION_TO_LABEL[section],
                                    }))}
                                    currentSection={sectionIndex}
                                    horizontal
                                />
                            )}
                        </Column>
                    </TopStepTracker>
                </Top>
            ) : (
                <Side>
                    <IntroHeader {...proposalForm} />
                    <StickyWrapper>
                        {!(statusAttributes.noProposal && section === FORM_SECTIONS.PROPOSAL_STATUS) && (
                            <ProgressTracker
                                sections={PROGRESS_SECTIONS.map(section => ({
                                    key: section,
                                    label: SECTION_TO_LABEL[section],
                                }))}
                                currentSection={sectionIndex}
                            />
                        )}
                    </StickyWrapper>
                </Side>
            )}
            {renderPrompt()}
            <Main fullscreen={isWidthConstrained}>
                <PaddedColumn>
                    <ProposalDetailsHeader
                        {...proposalForm}
                        data={headerProposalData}
                        onChange={onHeaderChange}
                        disabled={statusAttributes.readonly || section === FORM_SECTIONS.PROPOSAL_STATUS}
                    />

                    <FormColumn>
                        {FormSection && (
                            <FormSection
                                {...proposalForm}
                                onUpdateVenueSpaces={onUpdateVenueSpaces}
                                onChange={onSectionChange}
                                registerValidator={registerValidator}
                                action={userAction}
                                userChangedStatus={userChangedStatus}
                                disabled={statusAttributes.readonly}
                            />
                        )}
                    </FormColumn>
                </PaddedColumn>
                {loadingAsync && <FixedSpinnerOverlay />}

                <Spacer largest />
                <Spacer largest />

                <FooterButtons
                    onBack={onBack}
                    onSave={buttonOnSave}
                    onContinue={onContinue}
                    onSubmit={onSubmit}
                    onCancel={showCancelModal}
                    onRestart={onRestartProp}
                    onReject={showRejectModal}
                    disabled={loadingAsync}
                    isSubmitStep={section === FORM_SECTIONS.NOTES}
                    isStatusStep={section === FORM_SECTIONS.PROPOSAL_STATUS}
                    statusAttributes={statusAttributes}
                />

                {renderRejectModal()}
                {renderRejectExtensionModal()}
                {renderCancelModal()}
            </Main>
        </Shell>
    );
}
