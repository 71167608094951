import React from 'react';

import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';

import Communication from 'pages/Communication';
import EditParcel from 'pages/EditParcel';

export default function PlaybookParcels({
    playbookId,
    asWizardModule = false,
}: {
    playbookId: number | string;
    asWizardModule?: boolean;
}) {
    const routeMatch = useRouteMatch();

    return (
        <>
            <Communication playbookId={playbookId} readonly asWizardModule={asWizardModule} />
            <Switch>
                <Route exact path={routeMatch.path + '/communication/:type/:parcelId'}>
                    <Dialog open fullScreen>
                        <EditParcel context={{ id: playbookId, editable: false, asPlaybookParcel: true }} />
                    </Dialog>
                </Route>
            </Switch>
        </>
    );
}
