import React from 'react';
import styled from 'styled-components';
import { GoogleLogin as BaseGoogleLogin } from 'react-google-login';

import { TGoogleAuth } from './types';

import { ReactComponent as GoogleSVG } from 'images/icons/google_auth.svg';

import { Row } from 'ui';
import UIButton from 'components/ui/Button';

const GoogleIcon = styled(GoogleSVG)``;

const StyledButton = styled(UIButton)`
    width: 100%;

    font-size: 15px;
    font-weight: 400;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};

    border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};

    ${GoogleIcon} {
        width: 21px;
        margin-left: -21px; // SVG takes no width space in the row so button text can take center
    }
`;

const GOOGLE_CLIENT_ID =
    (process.env.REACT_APP_ENV === 'prod'
        ? process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID_PRODUCTION
        : process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID) || '';

type TButtonTypes = {
    onClick: () => void;
    disabled?: boolean;
    children?: React.ReactNode;
};

const GoogleButton = ({ onClick, disabled, children }: TButtonTypes) => (
    <StyledButton onClick={onClick} disabled={disabled} secondary>
        <Row justifyContent="center" alignItems="center" itemSpacing="default">
            <GoogleIcon />
            {children}
        </Row>
    </StyledButton>
);

type TGoogleAuthenticationProps = {
    onSuccess: (resp: TGoogleAuth) => void;
    buttonText: string;
};

export default function GoogleAuthentication({ onSuccess, buttonText }: TGoogleAuthenticationProps) {
    return (
        <BaseGoogleLogin
            clientId={GOOGLE_CLIENT_ID}
            render={renderProps => <GoogleButton onClick={renderProps.onClick}>{buttonText}</GoogleButton>}
            onSuccess={onSuccess}
            onFailure={err => console.log(err)}
        />
    );
}
