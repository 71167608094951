import React from 'react';

import { useSnackbar } from 'notistack';

import { meetingsPagesActions } from './pagesStore';

export default function LoadMeetingsPage({ page, scheduled }: { page?: number; scheduled?: boolean }) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        try {
            meetingsPagesActions.load(page, scheduled);
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    }, [page, scheduled, enqueueSnackbar]);

    return null;
}
