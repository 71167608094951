import React from 'react';

import { css } from 'styled-components/macro';

import { useHistory } from 'react-router';

import Dialog from '@material-ui/core/Dialog';

import { rejectProposal } from '../api';

import { AlignedRow, Column, Copy } from '../ui';
import TextButton from './ui/Button/TextButton';

import useKey from '../hooks/useKey';

const ProposalRejectModal = ({
    proposalId,
    open,
    closeModal,
    eventId,
}: {
    eventId: number;
    proposalId?: number;
    open: boolean;
    closeModal: () => void;
}) => {
    useKey('Escape', closeModal);

    const history = useHistory();

    function alreadyOnInquiriesRoute() {
        return (
            history.location.pathname ===
            `
        /event/${eventId}/venue/inquiries`
        );
    }

    return (
        <Dialog open={open} maxWidth="md">
            <Column
                css={css`
                    padding: 20px;

                    width: 400px;
                `}
            >
                <h2
                    css={css`
                        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.accentedHeadline)};

                        font-size: 25px;
                        line-height: 1.28;
                        letter-spacing: -0.5px;

                        font-weight: normal;
                        font-style: normal;
                        font-stretch: normal;
                    `}
                >
                    Are you sure?
                </h2>
                <Copy>Rejecting this proposal will notify the venue. This process cannot be undone.</Copy>
                <AlignedRow
                    css={css`
                        justify-content: flex-end;

                        margin-top: 20px;
                    `}
                    itemSpacing="smallish"
                >
                    <TextButton secondary onClick={closeModal}>
                        Cancel
                    </TextButton>
                    <TextButton
                        onClick={async () => {
                            if (proposalId) {
                                await rejectProposal(proposalId);
                                if (alreadyOnInquiriesRoute()) {
                                    window.location.reload();
                                } else {
                                    history.push(`/event/${eventId}/venue/inquiries`);
                                }
                            }
                        }}
                    >
                        Confirm
                    </TextButton>
                </AlignedRow>
            </Column>
        </Dialog>
    );
};

export default ProposalRejectModal;
