import React, { useState } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import { playbooksActions } from 'stores/playbooks';
import { useUser } from 'providers/user';

import { Spacer, Row, Column } from 'ui';
import { Dialog, DialogContent } from '@material-ui/core';
import { H2Headline } from 'components/ui/Headline';
import Form from 'components/Form';
import TextButton from 'components/ui/Button/TextButton';
import { SpinnerOverlay } from 'components/Spinner';

const LargeDialogContent = styled(DialogContent)`
    width: 480px;
    padding: 32px !important;
`;

type PlaybookCreateForm = Pick<BizlyAPI.Complete.Playbook, 'name'>;

const formFields = {
    name: {
        prompt: 'What would you like to name this playbook?',
        type: 'text',
        options: {
            autoFocus: true,
        },
    },
};

const formSchema = [
    {
        key: 'nameRow',
        fields: ['name'],
        spacing: 'medium',
    },
];

const validateForm = ({ name }: PlaybookCreateForm) => !!name?.trim();

const PlaybookCreateModal = ({ onClose }: { onClose: () => void }) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { toggles } = useUser();

    const [value, setValue] = useState({
        name: '',
    });
    const [submitting, setSubmitting] = useState(false);

    const updateForm = ({ value }: { value: { name: string } }) => setValue(value);

    const onSubmit = async () => {
        if (!validateForm(value)) {
            return enqueueSnackbar('A name is required to create a playbook.', { variant: 'error' });
        }

        setSubmitting(true);
        try {
            const playbook = await playbooksActions.create(value);
            history.push(`/playbooks/${playbook.id}${toggles.gate.createPlaybooksFlow ? '/edit' : ''}`);
            onClose();
        } catch {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            setSubmitting(false);
        }
    };

    return (
        <Dialog open maxWidth="md" onBackdropClick={submitting ? undefined : onClose}>
            <LargeDialogContent>
                <Column itemSpacing="small">
                    <H2Headline>Create a new playbook</H2Headline>
                    <Spacer />
                    <Form fields={formFields} schema={formSchema} value={value} onChange={updateForm} />
                </Column>

                <Spacer large />

                <Row justifyContent="flex-end" itemSpacing="smallish">
                    <TextButton onClick={onClose} secondary>
                        Cancel
                    </TextButton>
                    <TextButton onClick={onSubmit}>Create</TextButton>
                </Row>

                {submitting && <SpinnerOverlay />}
            </LargeDialogContent>
        </Dialog>
    );
};

export default PlaybookCreateModal;
