import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import * as serviceWorker from './serviceWorker';

import * as Sentry from '@sentry/browser';

import ThemedApp from './ThemedApp';

import './index.css';
import './SFFont.css';

const GOOGLE_ANALYTICS_ID =
    process.env.REACT_APP_ENV === 'prod'
        ? process.env.REACT_APP_GOOGLE_ANALYTICS_ID_PRODUCTION
        : process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN_URL;

Sentry.init({
    dsn: SENTRY_DSN,
});

if (process.env.NODE_ENV !== 'production') {
    // TODO: perhaps have a separate env var to toggle axe on/off
    // const axe = require('react-axe');
    // axe(React, ReactDOM, 1000);
}

// Initialize Google Analytics
if (GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID);
}

ReactDOM.render(<ThemedApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
