import React from 'react';
import styled from 'styled-components/macro';
import { format, parseISO } from 'date-fns';

import { EColors } from 'theme';

import { Column, Row, Spacer, Button } from '../../ui';
import AddToCalendarButton from 'components/ui/AddToCalendar';

import { H2Headline, WhiteHeadliner } from '../ui/Headline';

const CARD_WIDTH = 680;

const WhiteButton = styled(Button)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
`;

const RSVPHeader = ({
    name,
    startsAt,
    endsAt,
    cancelledAt,
    description,
    location,
    virtualMeeting,
    timeZone,
    attending,
    submitted,
    editResponse,
}: {
    name: string;
    startsAt: string;
    endsAt: string;
    cancelledAt?: string;
    description: string;
    location?: string;
    virtualMeeting?: {
        link: string;
        notes: string;
    };
    timeZone: string;
    attending: boolean;
    submitted: boolean;
    editResponse: () => void;
}) => {
    const headline = cancelledAt
        ? 'This event has been cancelled'
        : submitted
        ? 'Wahooo, all done!!'
        : attending
        ? 'Register to Attend'
        : 'Thanks for the response.';
    const message = cancelledAt
        ? ''
        : `You're ${attending ? '' : 'not '}attending ${name || ''} on ${
              startsAt ? format(parseISO(startsAt), 'MMM do') : ''
          }.`;
    const hasDescription = submitted || !attending;

    return (
        <Column style={{ width: CARD_WIDTH }}>
            <WhiteHeadliner noDot>{headline}</WhiteHeadliner>
            <Spacer large />

            {hasDescription && (
                <>
                    {message && <H2Headline $color={EColors.pureWhite}>{message}</H2Headline>}
                    {submitted && (
                        <>
                            <Spacer />
                            <Row itemSpacing="smallish">
                                <WhiteButton onClick={editResponse}>Edit Response</WhiteButton>
                                {startsAt && endsAt && (
                                    <AddToCalendarButton
                                        title={name}
                                        location={location || ''}
                                        timeZone={timeZone}
                                        startsAt={startsAt}
                                        endsAt={endsAt}
                                        fileName={name}
                                    />
                                )}
                            </Row>
                        </>
                    )}
                    <Spacer larger />
                </>
            )}

            <Spacer small />
        </Column>
    );
};

export default RSVPHeader;
