import React from 'react';

import { useHistory } from 'react-router';

import MenuOriginal from '@material-ui/core/Menu';
import MenuItemOriginal from '@material-ui/core/MenuItem';

import { withStyles } from '@material-ui/core/styles';
import { TStyledTheme } from 'theme';

import { useUser } from 'providers/user';

const HELP_URL = 'https://help.bizly.com/en/';

const menuStyles = {
    paper: {
        width: '180px',
    },
};

const Menu = withStyles(menuStyles)(MenuOriginal);

const menuItemStyles = ({ getColor, EColors }: TStyledTheme) => ({
    root: {
        margin: '0 12px',
        borderRadius: '10px',
        '&:hover': {
            backgroundColor: getColor(EColors.dropdownItemHover),
        },
        fontSize: '15px',
    },
});

const MenuItem = withStyles(menuItemStyles)(MenuItemOriginal);

function UserMenu(props: { style: object; label: string }) {
    const { toggles, isTeamAdmin } = useUser();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    function handleClick(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function openNewTab(url: string) {
        const win = window.open(url, '_blank');
        win?.focus();
    }

    return (
        <div {...props} style={{ cursor: 'pointer', ...props.style }}>
            <div aria-owns={anchorEl ? 'simple-menu' : undefined} aria-haspopup="true" onClick={handleClick}>
                {props.label}
            </div>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                disableAutoFocusItem
            >
                <MenuItem onClick={() => history.push('/profile')}>My Profile</MenuItem>
                {toggles.gate.integrations && (
                    <MenuItem onClick={() => history.push('/integrations')}>Integrations</MenuItem>
                )}
                {isTeamAdmin && <MenuItem onClick={() => history.push('/settings/team')}>Team Settings</MenuItem>}
                <MenuItem onClick={() => openNewTab(HELP_URL)}>Help</MenuItem>
                <MenuItem onClick={() => history.push('/logout')}>Sign Out</MenuItem>
            </Menu>
        </div>
    );
}

export default UserMenu;
