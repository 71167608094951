/* eslint no-unexpected-multiline: "off" */

import React, { ChangeEvent } from 'react';
import CSS from 'csstype';
import styled, { css } from 'styled-components/macro';
import camelize from 'camelize';

import Checkbox from '@material-ui/core/Checkbox';
import HelpIcon from '@material-ui/icons/HelpOutline';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select, { SelectProps } from '@material-ui/core/Select';
import MuiTextField, { OutlinedTextFieldProps as MUIOutlinedTextFieldProps } from '@material-ui/core/TextField';
import VanillaButton, { ButtonProps as VanillaButtonProps } from '@material-ui/core/Button';
import VanillaChip from '@material-ui/core/Chip';
import MuiCard, { CardProps as MuiCardProps } from '@material-ui/core/Card';
import MuiSwitch from '@material-ui/core/Switch';
import { GridContentAlignment, GridItemsAlignment } from '@material-ui/core';

import { ReactComponent as CloseIcon } from './images/icons/close.svg';
import { ReactComponent as DeleteIcon } from './images/icons/trash-can.svg';

import { copyFontSizes, withIconStyles } from './shared';
import { EColors, colorStyledFn, TColorStyledFn } from 'theme';
import useThemedColor from 'hooks/useThemedColor';
import colorFns from 'colorFns';
import fontFns from 'fontFns';

import { getFirstMatchingKey, matchLastOrDefault } from './util';

export const MAX_WIDTH = 1350;

export const SPACER_SPACES = {
    xsmall: 6,
    small: 12,
    smallish: 20,
    default: 24,
    medium: 32,
    large: 36,
    larger: 48,
    largest: 60,
} as const;
export type TSpacerNames = keyof typeof SPACER_SPACES;

type TItemSpacingProps = {
    itemSpacing?: TSpacerNames;
    paddingSpacing?: boolean;
    // meant to be used with itemSpacing to allow proper borders
    withBorderPadding?: TSpacerNames;
};

type TColProps = {
    scrollable?: boolean;
    alignItems?: string;
    justifyContent?: string;
    fillWidth?: boolean;
    wrap?: boolean;
} & TItemSpacingProps;

export const ColBase = styled.div<TColProps>`
    display: flex;
    flex-direction: column;
    ${props => props.scrollable && 'overflow: auto;'}
    ${props => props.alignItems && `align-items: ${props.alignItems};`}
    ${props => props.justifyContent && `justify-content: ${props.justifyContent};`}
    ${props => props.fillWidth && `width: 100%;`}
    ${props => (props.wrap ? 'flex-wrap: wrap;' : '')};
`;

type RowProps = {
    alignItems?: GridItemsAlignment | CSS.Globals;
    justifyContent?: GridContentAlignment | CSS.Globals;
    wrap?: boolean;
} & TItemSpacingProps;

export const RowBase = styled.div<RowProps>`
    display: flex;
    width: 100%;
    align-items: ${props => (props.alignItems ? props.alignItems : 'flex-start')};
    justify-content: ${props => (props.justifyContent ? props.justifyContent : 'flex-start')};
    ${props => (props.wrap ? 'flex-wrap: wrap;' : '')};
`;

export const Column = styled(ColBase)<TColProps>`
    ${({ itemSpacing, paddingSpacing }) =>
        itemSpacing
            ? `
    margin-top: -${SPACER_SPACES[itemSpacing] / 2}px;
    margin-bottom: -${SPACER_SPACES[itemSpacing] / 2}px;

    ${
        paddingSpacing
            ? `
    > *,
    > ${ColBase},
    > ${RowBase} {
        padding-top: ${SPACER_SPACES[itemSpacing] / 2}px;
        padding-bottom: ${SPACER_SPACES[itemSpacing] / 2}px;
        box-sizing: border-box;
    }`
            : `
    > *,
    > ${ColBase},
    > ${RowBase} {
        margin-top: ${SPACER_SPACES[itemSpacing] / 2}px;
        margin-bottom: ${SPACER_SPACES[itemSpacing] / 2}px;
    }`
    }`
            : ''}

    ${({ withBorderPadding, itemSpacing }) =>
        withBorderPadding
            ? `
    margin-top: 0px;
    margin-bottom: 0px;

    padding-top: ${SPACER_SPACES[withBorderPadding] - (itemSpacing ? SPACER_SPACES[itemSpacing] / 2 : 0)}px;
    padding-bottom: ${SPACER_SPACES[withBorderPadding] - (itemSpacing ? SPACER_SPACES[itemSpacing] / 2 : 0)}px;
            `
            : ``}
`;

export const AlignedColumn = styled(Column)`
    align-items: center;
`;

export const SpacedColumn = styled(Column)`
    justify-content: space-between;
`;

export const Row = styled(RowBase)<RowProps>`
    ${({ itemSpacing, paddingSpacing }) =>
        itemSpacing
            ? `
    width: calc(100% + ${SPACER_SPACES[itemSpacing]}px);
    margin-left: -${SPACER_SPACES[itemSpacing] / 2}px;
    margin-right: -${SPACER_SPACES[itemSpacing] / 2}px;

    ${
        paddingSpacing
            ? `
    > *,
    > ${ColBase},
    > ${RowBase} {
        padding-left: ${SPACER_SPACES[itemSpacing] / 2}px;
        padding-right: ${SPACER_SPACES[itemSpacing] / 2}px;
        box-sizing: border-box;
    }`
            : `
    > *,
    > ${ColBase},
    > ${RowBase} {
        margin-left: ${SPACER_SPACES[itemSpacing] / 2}px;
        margin-right: ${SPACER_SPACES[itemSpacing] / 2}px;
    }`
    }`
            : ''}

    ${({ withBorderPadding, itemSpacing }) =>
        withBorderPadding
            ? `
    margin-left: 0px;
    margin-right: 0px;

    padding-left: ${SPACER_SPACES[withBorderPadding] - (itemSpacing ? SPACER_SPACES[itemSpacing] / 2 : 0)}px;
    padding-right: ${SPACER_SPACES[withBorderPadding] - (itemSpacing ? SPACER_SPACES[itemSpacing] / 2 : 0)}px;
            `
            : ``}
`;

export const ResponsiveRow = styled.div`
    display: block;

    @media only screen and (min-width: 600px) {
        display: flex;
    }
`;

export const AlignedRow = styled(Row)`
    align-items: center;
`;

export const InlineRow = styled(Row)`
    width: auto;
`;

export const SpacedRow = styled(AlignedRow)`
    justify-content: space-between;
`;

export const ShadedRow = styled(SpacedRow)`
    width: unset;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackground)};
    padding: 12px 16px;
`;

type TopRoundedProps = {
    background: Themed.Color;
    hasContentBelow: boolean;
    backgroundHover: Themed.Color;
};

export const TopRounded = styled(ShadedRow)<Partial<TopRoundedProps>>`
    background-color: ${({ background, theme: { getColor, EColors } }) =>
        background || getColor(EColors.paneListItemBackground)};
    border-radius: ${props => (props.hasContentBelow ? '12px 12px 0 0' : '12px')};
    border-bottom: ${({ hasContentBelow, theme: { getColor, EColors } }) =>
        hasContentBelow ? `1px solid ${getColor(EColors.softBorder)}` : 'none'};

    ${props =>
        props.backgroundHover &&
        css`
            &:hover {
                background-color: ${props.backgroundHover};
            }
        `}
`;

export const ExpandedPane = styled(Column)<{ constrain?: boolean }>`
    max-height: ${props => (props.constrain ? '330px' : 'unset')};
    overflow: ${props => (props.constrain ? 'scroll' : 'unset')};
    border-radius: 0 0 12px 12px;
`;

export const SmallTextRow = styled(SpacedRow)`
    font-size: 12px;
    font-weight: 400;
`;

export const MaxWidthRow = styled(Row)`
    max-width: ${MAX_WIDTH}px;
    align-items: center;
    align-self: center;
`;

export const Line = styled.div`
    height: 1px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
`;

export const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

type TSpacesProps = Record<TSpacerNames, boolean>;

export const Spacer = styled.div<{ col?: boolean } & Partial<TSpacesProps>>`
    ${props =>
        props.col
            ? `    
        height: 24px;
        width: ${matchLastOrDefault(SPACER_SPACES, props)}px;
        min-width: ${matchLastOrDefault(SPACER_SPACES, props)}px;
    `
            : `
        width: 24px;
        height: ${matchLastOrDefault(SPACER_SPACES, props)}px;
        min-height: ${matchLastOrDefault(SPACER_SPACES, props)}px;
    `}
`;

export const Card = styled.div<{ scrollable?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 24px 24px 24px;
    border-radius: 12px;
    margin-right: 24px;
    margin-bottom: 24px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.dashboardCard)};
    ${props => props.scrollable && 'overflow: auto;'}
`;

type CopyProps = {
    maxWidth?: string | number;
    faded?: boolean;
    htmlFor?: string;
    large?: boolean;
    small?: boolean;
    xsmall?: boolean;
    regular?: boolean;
} & TColorStyledFn;

export const Copy = styled.div<Partial<CopyProps>>`
    ${({ $color, color }) => (!($color || color) ? 'color: inherit' : color ? `color: ${color}` : '')};
    ${colorStyledFn};
    font-size: ${props =>
        getFirstMatchingKey(props, copyFontSizes)
            ? copyFontSizes[getFirstMatchingKey(props, copyFontSizes)]
            : copyFontSizes.regular};
    line-height: 18px;
    max-width: ${props =>
        props.maxWidth ? (props.maxWidth.toString().includes('%') ? props.maxWidth : `${props.maxWidth}px`) : 'unset'};
    ${props => props.faded && 'opacity: 0.5;'}
`;

export const CopyFaded = (props: any) => <Copy faded {...props} />;
export const SmallerCopy = (props: any) => <Copy small {...props} />;

export const TruncatingSingleLineCopy = styled(Copy)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const TruncatingSmallerCopy = styled(SmallerCopy)`
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ExternalLinkBase = styled.a<{ openInNewTab?: boolean; underline?: boolean }>`
    color: ${colorFns.primaryAction};
    &:hover,
    &:focus {
        color: ${colorFns.primaryActionHover};
    }
    cursor: pointer;
    ${({ underline }) => (underline ? `text-decoration: underline;` : '')}
`;

export const ExternalLink = styled(ExternalLinkBase).attrs<typeof ExternalLinkBase>(({ openInNewTab }) =>
    openInNewTab
        ? {
              target: '_blank',
              rel: 'noopener noreferrer external',
          }
        : {}
)``;

const FlatVanillaButton = styled(VanillaButton)`
    border-radius: 8px;

    box-shadow: none;

    &:hover,
    &:active {
        box-shadow: none;
        ${({ variant, theme: { getColor, EColors } }) =>
            variant === 'contained' && `background-color: ${getColor(EColors.primaryActionHover)};`}
    }

    ${({ variant, theme: { getColor, EColors } }) =>
        variant === 'outlined'
            ? {
                  backgroundColor: getColor(EColors.pureWhite),
                  borderColor: getColor(EColors.primaryAction),
                  '&:hover, &:focus': {
                      backgroundColor: getColor(EColors.primaryAction),
                      '.MuiButton-label': {
                          color: getColor(EColors.pureWhite),
                      },
                  },
              }
            : {}}
`;

export const Button = ({
    children,
    style,
    ...props
}: {
    style?: React.CSSProperties;
    children: React.ReactNode;
    [key: string]: any;
}) => (
    <FlatVanillaButton variant="contained" color="primary" style={style} {...props}>
        {children}
    </FlatVanillaButton>
);

export const WhiteButton = styled(Button)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)} !important;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
`;

const whiteButtonStyles = (themedColors: ReturnType<typeof useThemedColor>) => ({
    display: 'inline-block',
    backgroundColor: `${themedColors.pureWhite} !important`,
    color: themedColors.primaryAction,
    whiteSpace: 'nowrap',
});

export const WhiteActionButton = ({ children, ...props }: any) => {
    const themedColors = useThemedColor();
    return (
        <Button style={{ ...whiteButtonStyles(themedColors), marginRight: 16 }} variant="outlined" {...props}>
            {children}
        </Button>
    );
};

export const Chip = (props: any) => (
    <VanillaChip color="primary" {...props} style={{ height: '20px', ...props.style }} />
);

export const StatusChip = styled(VanillaChip)<{ statusColor: EColors }>`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    background-color: ${({ statusColor, theme: { getColor } }) => getColor(statusColor)};
    height: 29px;
    text-transform: uppercase;
`;

export const EditButton = () => <Chip label="EDIT" style={{ cursor: 'inherit', fontSize: '10px' }} />;
export const BackButton = ({ label, ...props }: { label: string }) => (
    <Button style={{ width: '200px' }} onClick={() => window.history.back()} {...props}>
        {label || 'Back'}
    </Button>
);
export const TextButton = ({ children, ...props }: VanillaButtonProps) => {
    const { primaryAction } = useThemedColor();
    return (
        <VanillaButton style={{ color: primaryAction }} {...props}>
            {children}
        </VanillaButton>
    );
};

export const PaddedCard = styled(MuiCard)<MuiCardProps & { padding?: number }>`
    padding: ${props => (props.padding || props.padding === 0 ? props.padding : 32)}px;

    border-radius: 8px;
    box-shadow: 0 2px 44px 0 ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.1)};
`;

export const PaddedBox = styled.div<any>`
    padding: 24px;
    background-color: ${props => props.color};
    width: ${props => props.size}px;
    height: ${props => props.size * 0.75}px;
    margin-right: 12px;
    margin-bottom: 12px;
`;

export const RoundedBox = styled(PaddedBox)`
    border-radius: 12px;
`;

export type RoundedTopImageProps = {
    url: string;
    width: string | number;
    height: string | number;
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
    fixedRatio: string;
    linearGradient?: boolean;
};

// eslint-disable-next-line
export const RoundedImageWithText = styled(RoundedBox)<Partial<RoundedTopImageProps>>`
    display: flex;
    color: ${({ theme: { getColor, EColors } }) =>
        getColor(EColors.pureWhite)}; /* TODO: invert if image is too bright */
    cursor: ${props => (props.onClick ? 'pointer' : 'inherit')};
    padding: 12px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    background-image: url(${props => props.url});
    background-size: cover;

    ${({ linearGradient, theme: { getColor, EColors } }) =>
        linearGradient
            ? `
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 12px;
        background-image: linear-gradient(
            to bottom, 
            ${getColor(EColors.pureBlack, 0)}, 
            ${getColor(EColors.pureBlack, 0.87)}
        );
    }
    `
            : `
    box-shadow: inset 0px -15px 30px 30px ${getColor(EColors.pureBlack, 0.4)};
  `}

    ${props =>
        props.fixedRatio
            ? `
    position: relative;
    padding: 0;
    padding-top: ${props.fixedRatio};
    width: ${props.width};
  `
            : `
    max-width: ${props.width}px;
    width: ${props.width}px;
    height: ${props.height}px;
    max-height: ${props.height}px;
  `}
`;

export const Matchbox = styled(Column)<{ flat?: boolean; selected?: boolean; onClick?: () => any }>`
    width: 150px;
    margin-right: 24px;
    margin-bottom: 24px;

    ${({ selected, theme: { getColor, EColors } }) =>
        selected &&
        `
    border-radius: 12px 12px;
    box-shadow: 0 4px 12px 0 ${getColor(EColors.pureBlack, 0.05)},
                0 6px 20px 0 ${getColor(EColors.pureBlack, 0.05)};
              `}

    ${({ flat, theme: { getColor, EColors } }) =>
        flat
            ? ''
            : `
          &:hover {
            border-radius: 12px 12px;
            box-shadow: 0 4px 12px 0 ${getColor(EColors.pureBlack, 0.05)},
                        0 6px 20px 0 ${getColor(EColors.pureBlack, 0.05)};
          }
        `}

    ${({ onClick }) => onClick && 'cursor: pointer;'}
`;

export const MatchboxPlaceholder = styled(Matchbox)`
    height: 216px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.placeholderBackground)};
    border-radius: 12px 12px;

    &:hover {
        box-shadow: none;
    }
`;

export const AmenitiesIconHolder = styled.div`
    max-width: 29px;
    height: 32px;
    border-radius: 8px;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.amenitiesIconBackground)};

    flex: 1 0 9%;
    margin: 7px;
`;

export const IconImg = styled.img`
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: 5px;
`;

type LabeledCheckboxProps = {
    disabled: boolean;
    isChecked: boolean;
    label: React.ReactNode;
    onChange: (evt: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    className?: string;
    customIcon?: React.ReactSVGElement;
    customIconChecked?: React.ReactSVGElement;
};

export const LabeledCheckbox = ({
    disabled = false,
    isChecked = false,
    label = '',
    onChange,
    className = '',
    customIcon,
    customIconChecked,
}: LabeledCheckboxProps) => (
    <FormControlLabel
        className={className}
        label={label}
        control={
            <Checkbox
                color="primary"
                disabled={disabled}
                checked={isChecked}
                onChange={onChange}
                icon={customIcon}
                checkedIcon={customIconChecked}
            />
        }
    />
);

export const LabeledTextField = ({ label, ...props }: { label: string } & MUIOutlinedTextFieldProps) => (
    <AlignedRow
        style={{
            justifyContent: 'space-between',
            width: '100%',
        }}
    >
        <Copy style={{ marginRight: '16px' }}>{label}</Copy>
        <TextField rowStyle={{ width: 'unset' }} {...props} />
    </AlignedRow>
);

type TextFieldProps = {
    rowStyle?: object;
    charLimit?: number;
};

export const TextField = ({
    children,
    rowStyle,
    className,
    charLimit,
    ...props
}: Partial<TextFieldProps & MUIOutlinedTextFieldProps>) => {
    const { pureWhite } = useThemedColor();
    return (
        <AlignedRow style={rowStyle} className={className}>
            <MuiTextField
                margin="dense"
                style={{
                    backgroundColor: pureWhite,
                }}
                variant="outlined"
                inputProps={{ ...(charLimit ? { maxLength: charLimit } : {}) }}
                {...props}
            />
            {children}
        </AlignedRow>
    );
};

export const TextArea = (props: Partial<TextFieldProps & MUIOutlinedTextFieldProps>) => (
    <TextField multiline rows={4} rowsMax={8} fullWidth={true} {...props} />
);

export const PromptedTextField = ({
    children,
    prompt,
    optional,
    onDelete,
    ...props
}: MUIOutlinedTextFieldProps & {
    prompt?: string;
    charLimit?: number;
    optional?: boolean;
    onDelete?: () => void;
}) => (
    <Prompted prompt={prompt} optional={optional} onDelete={onDelete}>
        <TextField id={prompt} fullWidth={true} {...props} />
        {children}
    </Prompted>
);

export const PromptedTextArea = ({
    prompt,
    onDelete,
    optional,
    ...props
}: {
    prompt: string;
    onDelete?: () => void;
    optional?: boolean;
} & MUIOutlinedTextFieldProps) => (
    <Prompted prompt={prompt} optional={optional} onDelete={onDelete}>
        <TextArea {...props} />
    </Prompted>
);

const DeleteIconWithStyles = withIconStyles(DeleteIcon);

const FormLabel = styled(Copy)`
    color: ${colorFns.formLabel};
    ${fontFns.formLabel}
`;

export const Prompted = ({
    children,
    prompt,
    onDelete,
    optional,
}: {
    children: React.ReactNode;
    prompt?: string;
    optional?: boolean;
    onDelete?: () => void;
}) => {
    const themedColors = useThemedColor();

    return (
        <Column style={{ marginBottom: !optional ? 16 : 0 }}>
            <SpacedRow style={{ minHeight: 30 }}>
                {prompt && (
                    <FormLabel as="label" htmlFor={prompt}>
                        {prompt}{' '}
                        {optional && (
                            <Copy as="span" color={themedColors.optionalSpecifier}>
                                (optional)
                            </Copy>
                        )}
                    </FormLabel>
                )}
                {onDelete && (
                    <DeleteIconWithStyles
                        style={{
                            marginLeft: '16px',
                        }}
                        onClick={onDelete}
                    />
                )}
            </SpacedRow>
            {children}
        </Column>
    );
};

export const LabeledDropdown = ({ label, width = 120, ...props }: { label: string; width: number }) => (
    <FormControl variant="outlined" style={{ margin: '8px 0', width: `${width}px` }}>
        <InputLabel>{label}</InputLabel>
        <Dropdown width={width} label={label} {...props} />
    </FormControl>
);

type DropdownProps = {
    label: string;
    width: number;
};

export const Dropdown = ({
    children,
    label,
    onChange,
    value,
    className,
    labelWidth,
    width = 120,
}: SelectProps & DropdownProps) => (
    <Select
        value={value}
        onChange={onChange}
        className={className}
        input={
            <OutlinedInput
                margin="dense"
                labelWidth={labelWidth || width} // material-ui hack to fit label within outline border
                name={camelize(label)}
                id={camelize(label)}
            />
        }
    >
        {children}
    </Select>
);

export const PlaceholderIcon = ({ color, style }: Partial<{ color: Themed.Color; style: object }>) => {
    const { darkestGrey } = useThemedColor();
    return <HelpIcon style={{ ...style, color: color || darkestGrey, fontSize: '24px' }} />;
};

export const AccentedPlaceholderIcon = styled(PlaceholderIcon)`
    color: ${colorFns.primaryDisplayIcon};
`;

export const FixedBackground = styled.div<{ backgroundColor: string }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;

    ${props => (props.backgroundColor ? 'background-color: ' + props.backgroundColor : '')};
`;

const CloseIconTopLeft = styled.a`
    display: flex;

    position: fixed;
    top: 44px;
    right: 44px;

    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
    cursor: pointer;
`;

export const BackgroundWithClose = ({ backgroundColor, onClose }: { backgroundColor: string; onClose: () => void }) => (
    <div>
        <FixedBackground backgroundColor={backgroundColor} />
        <CloseIconTopLeft>
            <CloseIcon onClick={onClose} />
        </CloseIconTopLeft>
    </div>
);

const ViewportFullscreenFixedBottom = styled(Row)`
    position: fixed;
    bottom: 0;
    z-index: 1000;

    width: 100vw;
`;

const HomeScreenBannerDiv = styled.div`
    max-width: 1020px;
    min-height: 90px;
    padding: 40px;
    box-sizing: border-box;
    border-radius: 2000px; /* always make it look like a pill */
    box-shadow: 0 2px 44px 0 ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.3)};
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.homeScreenBanner, 0.9)};

    display: flex;
    justify-content: space-around;
    align-items: center;

    position: absolute;
    margin: auto;
    left: ${({ left }: { left: number }) => left + 94}px;
    right: 94px;
    bottom: 36px;

    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    font-size: 13px;
`;

export const HomeScreenBanner = ({ children, left }: { children: React.ReactNode; left: number }) => (
    <ViewportFullscreenFixedBottom>
        <HomeScreenBannerDiv left={left}>{children}</HomeScreenBannerDiv>
    </ViewportFullscreenFixedBottom>
);

export const TernaryElement: React.FC<any> = ({ condition, children, trueElement, fallbackElement, ...props }) => {
    const Element = condition ? trueElement : fallbackElement;
    return Element ? <Element {...props}>{children}</Element> : children;
};

export const Switch = styled(MuiSwitch)`
    width: 40px;
    height: 20px;
    padding: 0;
    display: flex;

    .MuiSwitch-switchBase {
        padding: 2px;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};

        &.Mui-checked {
            color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
            transform: translateX(100%);

            & + .MuiSwitch-track {
                opacity: 1;
                background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
                border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
            }
        }
    }
    .MuiSwitch-thumb {
        width: 16px;
        height: 16px;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        box-shadow: none;
        box-sizing: border-box;
    }
    .MuiSwitch-track {
        border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
        border-radius: ${20 / 2}px;
        opacity: 1;
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
        box-sizing: border-box;
    }
`;

export const Strike = styled.s`
    text-decoration-thickness: from-font;
    text-decoration-style: double;
`;
