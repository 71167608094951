import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import MUIMenu from '@material-ui/core/Menu';
import MUIMenuItem from '@material-ui/core/MenuItem';

import { Column } from 'ui';

const MenuIconTarget = styled(Column)`
    width: 24px;
    align-items: center;

    cursor: pointer;

    &:hover {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
    }
    transition: 0.2s all ease-in;
`;

const Dot = styled.div`
    width: 4px;
    height: 4px;

    background-color: currentColor;
    border-radius: 50%;

    &:not(:last-child) {
        margin-bottom: 3px;
    }
`;

type TOption = {
    label: string;
    onClick: () => void;
};

const MenuIcon = ({ options, className }: { options: TOption[]; className?: string }) => {
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const anchorRef = useRef<HTMLDivElement | null>(null);

    const handleCallback = async (callback: () => void) => {
        await callback();
        setShowMenu(false);
    };

    return (
        <>
            <MenuIconTarget ref={anchorRef} className={className} onClick={() => setShowMenu(true)}>
                <Dot />
                <Dot />
                <Dot />
            </MenuIconTarget>
            <MUIMenu
                anchorEl={anchorRef.current}
                open={showMenu}
                disableAutoFocusItem
                onClose={() => setShowMenu(false)}
            >
                {options.map(({ label, onClick }) => (
                    <MUIMenuItem key={label} onClick={() => handleCallback(onClick)}>
                        {label}
                    </MUIMenuItem>
                ))}
            </MUIMenu>
        </>
    );
};

export default MenuIcon;
