import React from 'react';
import isEqual from 'lodash/isEqual';

import {
    usePlaybooks,
    selectPlaybook,
    selectCollaborators,
    selectResources,
    LoadPlaybookCollaborators,
    LoadPlaybookResources,
} from 'stores/playbooks';

import { useUser } from 'providers/user';

import { Column, Spacer } from 'ui';
import { H3Headline } from 'components/ui/Headline';
import { SpinnerOverlay } from 'components/Spinner';
import PlaybookBasicDetails from 'components/Playbook/PlaybookBasicDetails';
import PlaybookAgenda from './PlaybookAgenda';
import PlaybookInquiry from 'pages/Playbook/components/PlaybookInquiry';
import PlaybookParcels from 'pages/Playbook/components/PlaybookParcels';
import PlaybookCollaboration from 'pages/Playbook/components/PlaybookCollaboration';
import PlaybookVenueFilters from 'pages/Playbook/components/PlaybookVenueFilters';
import PlaybookInteralDetails from 'pages/Playbook/components/PlaybookInternalDetails';

export default function PlaybookDetails({ playbookId }: { playbookId: string | number }) {
    const { user } = useUser();

    const playbook = usePlaybooks(selectPlaybook(playbookId));
    const collaborators = usePlaybooks(selectCollaborators(playbookId));
    const resources = usePlaybooks(selectResources(playbookId));

    const hasCollaboration = (collaborators && collaborators.length > 0) || (resources && resources.length > 0);
    const hasFilters = !isEqual(playbook?.venueSearchParameters, {
        grades: [0, 2],
        radius: 24141,
        radiusKm: 24,
    });

    if (!playbook) return <SpinnerOverlay />;

    return (
        <Column itemSpacing="large">
            <PlaybookBasicDetails playbook={playbook} />
            <PlaybookAgenda playbookId={playbookId} />
            {user.featureFlags?.createPlaybooksFlow && (
                <>
                    <LoadPlaybookCollaborators id={playbookId} />
                    <LoadPlaybookResources id={playbookId} />

                    <PlaybookInteralDetails playbook={playbook} />
                    {hasFilters && (
                        <Column>
                            <H3Headline>Venue Search Filters</H3Headline>
                            <Spacer default />
                            <PlaybookVenueFilters playbook={playbook} />
                        </Column>
                    )}
                    <Column itemSpacing="xsmall">
                        <H3Headline>Inquiry</H3Headline>
                        <PlaybookInquiry playbookId={playbookId} />
                    </Column>
                    <Column itemSpacing="xsmall">
                        <H3Headline>Communications</H3Headline>
                        <PlaybookParcels playbookId={playbookId} />
                    </Column>
                    {hasCollaboration && (
                        <Column itemSpacing="large">
                            <H3Headline>Collaboration</H3Headline>
                            <PlaybookCollaboration playbookId={playbookId} />
                        </Column>
                    )}
                </>
            )}
        </Column>
    );
}
