import createStore from 'zustand';

import { getMeetingsPageBySchedule } from 'api/meetings';
import { meetingsActions } from './store';

type State = {
    loadingScheduledByPage: {
        [page: number]: boolean;
    };
    loadingUnscheduledByPage: {
        [page: number]: boolean;
    };
    scheduledIdsByPage: {
        [page: number]: number[];
    };
    unscheduledIdsByPage: {
        [page: number]: number[];
    };
    pageLimit: number;
    lastPageSched: number | null;
    lastPageUnsched: number | null;
};
type Store = State;

const initialState: State = {
    loadingScheduledByPage: {},
    loadingUnscheduledByPage: {},
    scheduledIdsByPage: {},
    unscheduledIdsByPage: {},
    pageLimit: 20,
    lastPageSched: null,
    lastPageUnsched: null,
};
export const [useMeetingsPages, meetingsPagesStoreApi] = createStore<Store>(() => initialState);

const { setState, getState } = meetingsPagesStoreApi;

export const meetingsPagesActions = {
    load: async (page = 1, scheduled = false) => {
        if (scheduled) setState({ loadingScheduledByPage: { ...getState().loadingScheduledByPage, [page]: true } });
        else
            setState({
                loadingUnscheduledByPage: { ...getState().loadingUnscheduledByPage, [page]: true },
            });

        const pullCount = getState().pageLimit;

        try {
            const { meetings } = await getMeetingsPageBySchedule(page, pullCount, scheduled);

            if (scheduled)
                setState({
                    loadingScheduledByPage: { ...getState().loadingScheduledByPage, [page]: false },
                    scheduledIdsByPage: { ...getState().scheduledIdsByPage, [page]: meetings.map(m => m.id) },
                    ...(meetings.length < pullCount ? { lastPageSched: page } : {}),
                });
            else
                setState({
                    loadingUnscheduledByPage: { ...getState().loadingUnscheduledByPage, [page]: false },
                    unscheduledIdsByPage: {
                        ...getState().unscheduledIdsByPage,
                        [page]: meetings.map(m => m.id),
                    },
                    ...(meetings.length < pullCount ? { lastPageUnsched: page } : {}),
                });

            meetingsActions.add(meetings);

            return meetings;
        } catch (e) {
            if (scheduled)
                setState({
                    loadingScheduledByPage: { ...getState().loadingScheduledByPage, [page]: false },
                });
            else
                setState({
                    loadingUnscheduledByPage: { ...getState().loadingUnscheduledByPage, [page]: false },
                });
            throw e;
        }
    },
};

export const selectMeetingIds = (scheduled = true) => (state: State) =>
    Object.values(scheduled ? state.scheduledIdsByPage : state.unscheduledIdsByPage).flat();

export function isLoadingPage(page: number = 1, scheduled = true) {
    return (state: State) => (scheduled ? state.loadingScheduledByPage[page] : state.loadingUnscheduledByPage[page]);
}
