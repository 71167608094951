import React from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router';
import { EventHeaderIconsFill, EventHeaderLeftFill } from 'components/EventHeader';
import { Fill, Slot } from 'react-slot-fill';

import { createMeetingActions, useCreateMeeting, selMaxStep, ValidationError, ESteps, selCurStep } from '../store';

import { Step, StepLabel, Stepper as StepperMui } from '@material-ui/core';
import Button from 'components/ui/Button';
import { Row } from 'ui';
import colorFns from 'colorFns';

const HeaderRow = styled(Row)<{ noBackground?: boolean }>`
    ${({ noBackground }) =>
        noBackground
            ? `
    background: transparent;
    pointer-events: none;

    & ${Button} {
        pointer-events: all;
    }
    `
            : `
    background:white;`};
`;

const AutoRow = styled(Row)`
    width: auto;
`;

const Stepper = styled(StepperMui)`
    min-width: 200px;
    padding-left: 0;
    background: transparent;

    .MuiStep-root,
    .MuiStepLabel-iconContainer {
        padding: 0;
    }

    .MuiStepIcon-root.MuiStepIcon-active {
        color: ${colorFns.brand};
    }
`;

const MeetingButtonsHeaderName = 'Create.Meeting.Header.Right.Slot';
const MeetingHeaderRightSlot = styled(Slot).attrs({
    name: MeetingButtonsHeaderName,
})``;
export const MeetingHeaderRightFill = ({ children }: { children: React.ReactNode }) => (
    <Fill name={MeetingButtonsHeaderName}>{children}</Fill>
);

export default function Header({ noBackground, className }: { noBackground?: boolean; className?: string }) {
    const { stepIdx, stepList, isPublished } = useCreateMeeting();
    const maxStep = useCreateMeeting(selMaxStep);
    const curStep = useCreateMeeting(selCurStep);

    const { id } = useParams<{ id?: string }>();
    const history = useHistory<{ skipLoading?: boolean; step?: ESteps }>();
    const { enqueueSnackbar } = useSnackbar();

    const match = useRouteMatch();

    const save = async () => {
        try {
            if (isPublished && id) {
                return await createMeetingActions.savePublished(id);
            }

            const resultMeeting = await createMeetingActions.saveDraft(id);
            if (id === undefined && resultMeeting) {
                history.replace(`/events/${resultMeeting.id}/edit`, { skipLoading: true, step: ESteps.venues });
            } else if (!isPublished) {
                createMeetingActions.goToStep(ESteps.venues);
            }
        } catch (e) {
            if (!(e instanceof ValidationError))
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    };

    const saveable = true;

    return (
        <HeaderRow alignItems="center" justifyContent="space-between" noBackground={noBackground} className={className}>
            <Switch>
                <Route path={`${match.path}/venue`}>
                    <EventHeaderLeftFill>
                        <AutoRow>
                            <Stepper>
                                {stepList.length > 1 &&
                                    stepList.map((name, idx) => (
                                        <Step key={idx} completed={false} active={idx <= stepIdx}>
                                            <StepLabel>{}</StepLabel>
                                        </Step>
                                    ))}
                            </Stepper>
                        </AutoRow>
                    </EventHeaderLeftFill>
                </Route>
                <Route>
                    <AutoRow>
                        <Stepper>
                            {stepList.length > 1 &&
                                stepList.map((name, idx) => (
                                    <Step key={idx} completed={false} active={idx <= stepIdx}>
                                        <StepLabel>{}</StepLabel>
                                    </Step>
                                ))}
                        </Stepper>
                    </AutoRow>
                </Route>
            </Switch>

            <Switch>
                <Route path={`${match.path}/venue/listing`} />
                <Route path={`${match.path}/venue`}>
                    <EventHeaderIconsFill>
                        <AutoRow itemSpacing="smallish">
                            {stepIdx > 0 && (
                                <Button width="60px" secondary onClick={createMeetingActions.prevStep}>
                                    Prev
                                </Button>
                            )}
                            {stepIdx < maxStep && (
                                <Button width="80px" onClick={createMeetingActions.nextStep}>
                                    Next
                                </Button>
                            )}
                        </AutoRow>
                    </EventHeaderIconsFill>
                </Route>
                <Route>
                    {curStep !== ESteps.inquiry ? (
                        <AutoRow itemSpacing="smallish">
                            {stepIdx > 0 && (
                                <Button width="60px" secondary onClick={createMeetingActions.prevStep}>
                                    Prev
                                </Button>
                            )}
                            {saveable && (
                                <Button width="140px" onClick={save}>
                                    {!isPublished ? 'Next' : 'Save'}
                                </Button>
                            )}
                            {id && isPublished && stepIdx < maxStep && (
                                <Button width="80px" onClick={createMeetingActions.nextStep}>
                                    Next
                                </Button>
                            )}
                        </AutoRow>
                    ) : (
                        <AutoRow itemSpacing="smallish">
                            {stepIdx > 0 && (
                                <Button width="60px" secondary onClick={createMeetingActions.prevStep}>
                                    Prev
                                </Button>
                            )}
                            <MeetingHeaderRightSlot />
                        </AutoRow>
                    )}
                </Route>
            </Switch>
        </HeaderRow>
    );
}
