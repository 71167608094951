import React, { useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import { loadParcels } from 'api';
import { withIconStyles } from 'shared';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import { useAsync } from 'hooks/useAsync';

import { ReactComponent as DocumentIconSVG } from 'images/icons/document.svg';
import { ReactComponent as FlagIconSVG } from 'images/icons/flag.svg';
import { ReactComponent as LocationPinIconSVG } from 'images/icons/location_pin.svg';
import { ReactComponent as MegaphoneIconSVG } from 'images/icons/megaphone.svg';
import { ReactComponent as PersonIconSVG } from 'images/icons/person.svg';

import { Button, Card, Column, ResponsiveRow, Row, Spacer, TextField } from 'ui';
import { IconHeadline } from 'components/ui/Headline';
import { ParcelMatchboxThumb } from 'components/Communication/CommunicationParcel';
import PlaceInput from 'components/PlaceInput';
import PlannerSection from 'components/PlannerSection';
import VenueSection from 'components/EventDashboard/OldVenueSection';
import AgendaSection from './AgendaSection';
import TallyDisplay from 'components/ui/TallyDisplay';
import EventBriefing from './OldEventBriefing';
import Checklist from './Checklist';
import HelpPopover from './HelpPopover';

const DocumentIcon = withIconStyles(DocumentIconSVG);
const FlagIcon = withIconStyles(FlagIconSVG);
const LocationPinIcon = withIconStyles(LocationPinIconSVG);
const MegaphoneIcon = withIconStyles(MegaphoneIconSVG);
const PersonIcon = withIconStyles(PersonIconSVG);

const StyledGrid = withStyles({})(Grid);

const TopRowItemInner = styled.div`
    margin-right: 24px;
`;

const TopRowItem = styled.div`
    width: 100%;
`;

type DashboardProps = Pick<
    Bizly.EventContext,
    'onDescriptionChange' | 'onLocationChange' | 'onObjectiveChange' | 'suggestedVenues'
> & {
    viewVenueListing: (venueId: number) => void;
    viewProposal: (proposalId: number) => void;
    goToVenueSearch: () => void;
    viewInquiries: () => void;
};

const LabelRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const StyledColumn = styled(Column)`
    position: relative;
    flex-shrink: 0;
    width: 100%;
    justify-content: space-between;
`;

const DashboardParcelMatchboxThumb = styled(ParcelMatchboxThumb)`
    width: 71px;
    margin-bottom: 0;
`;

export default function Dashboard({
    onDescriptionChange,
    onLocationChange,
    onObjectiveChange,
    suggestedVenues,
    viewVenueListing,
    viewProposal,
    goToVenueSearch,
    viewInquiries,
}: DashboardProps) {
    const { event, template } = useEvent();
    const { toggles } = useUser();

    const {
        attendeeCounts: { total, invited, attending, notAttending },
        description,
        id,
        location,
        lat,
        lng,
        name,
        objective,
        startsAt,
        endsAt,
        editable,
    } = event;
    const {
        longDescription: templateDescription,
        objectiveDescription: templateObjective,
        showBrief,
        briefTitle,
        shortDescription,
        briefImageSmall,
        readMoreLink,
    } = template;

    const { data: parcels } = useAsync(useCallback(() => loadParcels(id), [id]));
    const hasAttendees = !!total;
    const hasParcels = parcels?.length;

    return (
        <Column style={{ width: '100%' }}>
            <ResponsiveRow>
                <TopRowItem>
                    <TopRowItemInner>
                        <LabelRow>
                            <IconHeadline icon={<DocumentIcon />} headline="Description" />
                            {templateDescription && <HelpPopover copy={templateDescription} />}
                        </LabelRow>
                        <TextField
                            fullWidth
                            multiline
                            rows="2"
                            rowsMax="3"
                            disabled={!editable}
                            value={description || undefined}
                            onChange={(newValue: any) => onDescriptionChange(newValue.target.value)}
                            margin="none"
                            placeholder="Add a clear description to ensure everyone’s on the same page."
                            variant="outlined"
                        />
                    </TopRowItemInner>
                </TopRowItem>
                <TopRowItem>
                    <TopRowItemInner>
                        <LabelRow>
                            <IconHeadline icon={<FlagIcon />} headline="Objective" />
                            {templateObjective && <HelpPopover copy={templateObjective} />}
                        </LabelRow>
                        <TextField
                            fullWidth
                            multiline
                            rows="2"
                            rowsMax="3"
                            disabled={!editable}
                            value={objective || undefined}
                            onChange={(newValue: any) => onObjectiveChange(newValue.target.value)}
                            margin="none"
                            placeholder="What does success look like for this meeting? Tangible goals will help you and your collaborators stay on task."
                            variant="outlined"
                        />
                    </TopRowItemInner>
                </TopRowItem>
                <TopRowItem>
                    <TopRowItemInner>
                        <IconHeadline icon={<LocationPinIcon />} headline="City" />
                        <PlaceInput
                            onChange={onLocationChange}
                            defaultValue={location || undefined}
                            disabled={!editable}
                            placeholder="Enter a city"
                            citiesOnly
                        />
                    </TopRowItemInner>
                </TopRowItem>
            </ResponsiveRow>

            <Spacer />

            <StyledGrid container justify="center">
                <StyledGrid item xs={4}>
                    <Checklist eventId={id} showBrief={showBrief} readOnly={!editable} />
                    {showBrief && (
                        <EventBriefing
                            title={briefTitle}
                            desc={shortDescription}
                            imageSmall={briefImageSmall}
                            readMoreLink={readMoreLink}
                        />
                    )}
                </StyledGrid>
                <StyledGrid item xs={4}>
                    <Card
                        scrollable
                        style={{
                            height: '400px',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {toggles.gate.useActivityCalendar ? <AgendaSection /> : <PlannerSection />}
                    </Card>
                    <Card style={{ height: '164px' }}>
                        <StyledColumn>
                            <IconHeadline
                                icon={<PersonIcon />}
                                headline="Guest List"
                                description={
                                    !hasAttendees
                                        ? 'A perfect meeting needs the perfect audience! Here, we’ll build and manage your guest list.'
                                        : ''
                                }
                            />
                            {hasAttendees ? (
                                <Link to={`/event/${id}/guest-list`}>
                                    <Button
                                        style={{
                                            position: 'absolute',
                                            top: '0',
                                            right: '0',
                                            margin: '0.5em 0',
                                        }}
                                        variant="outlined"
                                    >
                                        {editable ? 'Edit' : 'View'}
                                    </Button>
                                </Link>
                            ) : null}
                        </StyledColumn>
                        {hasAttendees ? (
                            <TallyDisplay
                                tallies={[
                                    { label: 'invited', tally: invited },
                                    { label: 'attending', tally: attending },
                                    { label: 'not attending', tally: notAttending },
                                ]}
                            />
                        ) : (
                            <Link to={`/event/${id}/guest-list`}>
                                <Button style={{ margin: '0.5em 0' }}>{editable ? 'Add people' : 'View'}</Button>
                            </Link>
                        )}
                    </Card>
                </StyledGrid>
                <StyledGrid item xs={4}>
                    <Card
                        style={{
                            justifyContent: 'space-between',
                            height: '400px',
                        }}
                        scrollable
                    >
                        <VenueSection
                            suggestedVenues={suggestedVenues}
                            eventId={id}
                            eventName={name}
                            location={location}
                            lat={lat}
                            lng={lng}
                            startsAt={startsAt}
                            endsAt={endsAt}
                            viewVenueListing={viewVenueListing}
                            viewProposal={viewProposal}
                            onSearch={goToVenueSearch}
                            onEditInquiries={viewInquiries}
                        />
                    </Card>

                    <Card style={{ height: '164px' }}>
                        <StyledColumn>
                            <IconHeadline
                                icon={<MegaphoneIcon />}
                                headline="Communication"
                                description={
                                    hasParcels
                                        ? ''
                                        : 'Send beautifully branded invites, notes and reminders to your guests.'
                                }
                            />
                            {hasParcels ? (
                                <Link to={`/event/${id}/communication`}>
                                    <Button
                                        style={{
                                            position: 'absolute',
                                            top: '0',
                                            right: '0',
                                            margin: '0.5em 0',
                                        }}
                                        variant="outlined"
                                    >
                                        {editable ? 'Edit' : 'View'}
                                    </Button>
                                </Link>
                            ) : null}
                        </StyledColumn>
                        {hasParcels ? (
                            <Row
                                css={css`
                                    overflow-x: auto;
                                    overflow-y: hidden;
                                `}
                            >
                                {parcels.map((parcel: Bizly.Parcel & { id: string }) => (
                                    <DashboardParcelMatchboxThumb key={parcel.id} parcel={parcel} />
                                ))}
                            </Row>
                        ) : (
                            <Link to={`/event/${id}/communication`}>
                                <Button style={{ margin: '0.5em 0' }}>{editable ? 'Create' : 'View'}</Button>
                            </Link>
                        )}
                    </Card>
                </StyledGrid>
            </StyledGrid>
        </Column>
    );
}
