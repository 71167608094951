import React, { useMemo } from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';

import { usePlaybooks } from 'stores/playbooks';
import { useUser } from 'providers/user';
import { units, useMeasurementUnits } from 'stores/measurement-units';

import { Copy, Column, Spacer } from 'ui';
import { H3Headline } from 'components/ui/Headline';
import PlaceInput from 'components/PlaceInput';
import { default as FormBase } from 'components/Form';
import { fields, schema } from 'components/VenueSearch/filtersSchema';
import { TFilterValueInitial } from 'components/VenueSearch/VenueSearchFilters';
import { DisplayField } from 'components/FormFields';

type Place = Partial<{
    location: BizlyAPI.Complete.Playbook['location'];
    googlePlaceId: BizlyAPI.Complete.Playbook['googlePlaceId'];
}> | null;

type TPlaceField = {
    field: string;
    value?: Place;
    error: Object | null;
    defaultValue: Place;
    onChange: ({ value, field, error }: { value: Place; field: string; error: Object | null }) => void;
    allowGlobal?: boolean;
    readonly?: boolean;
};

const PlaceField = ({
    field,
    onChange: onChangeProp,
    defaultValue,
    allowGlobal = false,
    readonly = false,
}: TPlaceField) => {
    return readonly ? (
        <DisplayField value={defaultValue?.location || 'No City Selected'} />
    ) : (
        <PlaceInput
            asFormField
            inModalOrPopover
            onChange={(location, googlePlaceId) =>
                onChangeProp({
                    field,
                    error: {},
                    value: {
                        location,
                        googlePlaceId,
                    },
                })
            }
            clearable
            onRemove={() =>
                onChangeProp({
                    field,
                    error: {},
                    value: null,
                })
            }
            key={defaultValue?.location ?? undefined}
            defaultValue={defaultValue?.location ?? undefined}
            defaultValueId={defaultValue?.googlePlaceId ?? undefined}
            allowGlobal={allowGlobal}
        />
    );
};

const venueFilterFields = ({
    teamName,
    venueBrands,
    place,
    hideBrandPrompt,
}: {
    teamName?: string;
    venueBrands?: Bizly.VenueBrand[];
    place?: Place;
    hideBrandPrompt?: boolean;
}) => ({
    place: {
        prompt: 'Location',
        type: PlaceField,
        perRow: '1/2',
        options: {
            ...(place ? { defaultValue: place } : {}),
        },
    },
    ...fields(teamName, venueBrands, { hideBrandPrompt }),
});

const placeSchema = [{ fields: ['place'], spacing: 'default' }];
const filtersSchema = (unit: units.kilometer | units.mile) => [...schema(unit)] as const;
const brandsSchema = [
    {
        fields: ['brandIds'],
        spacing: false,
    },
];

export type TVenueFiltersValue = Partial<{
    place: Place;
    grades: [number, number] | readonly [number, number];
    hideUngraded: boolean;
    types: BizlyAPI.Venue.Types[];
    decors: BizlyAPI.Venue.Decors[];
    preferredOnly: boolean;
    radius: Distance.Mile;
    radiusKm: Distance.Kilometer;
    brandIds: number[];
}>;

const Form = styled(FormBase)``;

type Fields = keyof ReturnType<typeof venueFilterFields>;

export default function VenueFiltersForm({
    value = {},
    onChange,
    readonly,
}: {
    value?: TVenueFiltersValue;
    onChange?: (update: { field: Fields; value: TFilterValueInitial }) => void;
    readonly?: boolean;
}) {
    const { playbookOptions: { venueBrands = [] } = {} } = usePlaybooks();
    const { distance: distanceUnit } = useMeasurementUnits();
    const { user } = useUser();
    const teamName = useMemo(() => (user.team ? user.team.name : ''), [user.team]);

    const fields = venueFilterFields({
        teamName,
        venueBrands,
        place: value.place,
        hideBrandPrompt: !readonly,
    });

    const formProps = {
        fields,
        value,
        onChange,
        readonly,
        hideReadonlyEmpty: true,
    };

    const hidePlacesForm = readonly && (!value.place || isEmpty(pickBy(value.place, v => v)));

    return (
        <Column itemSpacing="default">
            {hidePlacesForm ? null : <Form schema={placeSchema} {...formProps} />}
            <Form schema={filtersSchema(distanceUnit === units.meter ? units.kilometer : units.mile)} {...formProps} />
            {!readonly ? (
                <Column itemSpacing="smallish">
                    <Spacer small />
                    <H3Headline>Venue Brands</H3Headline>
                    <Copy faded>
                        Selecting venue brands for this playbook will restrict this filter
                        <br />
                        for events created from this playbook.
                    </Copy>
                    <Form schema={brandsSchema} {...formProps} />
                </Column>
            ) : (
                <Form schema={brandsSchema} {...formProps} />
            )}
        </Column>
    );
}
