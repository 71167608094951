import React from 'react';
import styled from 'styled-components';
import { tzMoment, userTimeZone } from 'utils/moment';

import { Column, Copy, Row, InlineRow, TruncatingSingleLineCopy, Strike } from 'ui';
import { SpinnerOverlay } from 'components/Spinner';
import { Tooltip } from '@material-ui/core';
import Pill from 'components/ui/Pill';

import { ReactComponent as ClockIconSVG } from 'images/icons/clock.svg';
import { ReactComponent as EditIconSVG } from 'images/icons/registration.svg';
import colorFns from 'colorFns';
import useThemedColor from 'hooks/useThemedColor';

const EditIcon = styled(EditIconSVG)`
    height: 21px;
    width: 21px;
`;

const ClockIcon = styled(ClockIconSVG)`
    width: 22px;
    height: 22px;
`;

const HoverRow = styled(Row)`
    padding: 12px 0;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
        background-color: ${colorFns.listItemBackground};
    }
`;

const CenteredRow = styled(InlineRow)`
    align-items: center;
    justify-content: center;
`;

const CircleImg = styled.img`
    height: 36px;
    width: 36px;
    border-radius: 50%;
    object-fit: cover;
`;

const CreatedByRow = styled(Row)`
    align-items: center;
    justify-content: flex-end;
    flex: 1 0 180px;
    min-width: 180px;
    max-height: 60px;
`;

const RightAlignCopy = styled(Copy)`
    text-align: right;
`;

const NameCol = styled(Column)`
    flex: 1 0 240px;
    min-width: 200px;
    align-items: flex-start;
`;

const FullWidthCol = styled(Column)`
    position: relative;
    width: 100%;
`;

const ActivityCol = styled(Column)`
    position: relative;
    flex: 0 0 140px;
    min-width: 140px;
`;

const LocationCol = styled(Column)`
    position: relative;
    flex: 0 0 120px;
    min-width: 120px;
`;

const ActivityColSmall = styled(Column)`
    position: relative;
    width: 110px;
    align-items: flex-start;
`;

const NameColSmall = styled(Column)`
    flex: 1 0 120px;
    width: 120px;
    align-items: flex-start;
`;

const ClockCol = styled(Column)`
    flex: 0 0 16px;
    min-width: 16px;
    align-items: flex-start;
`;
const TimeCol = styled(Column)`
    flex: 1;
`;

const DateOrEditCol = styled(Column)`
    flex: 0 0 120px;
    width: 120px;
`;

const shortTimestamp = (lastActiveAt: string) => {
    const lastSeen = tzMoment(lastActiveAt, 'UTC').tz(userTimeZone);
    const now = tzMoment('', userTimeZone);

    if (now.diff(lastSeen, 'days') < 1) {
        return lastSeen.fromNow();
    }

    if (lastSeen.month() === now.month() && lastSeen.year() === now.year()) {
        return lastSeen.format('MMM D, h:mm a');
    }

    return lastSeen.format('MMM D, YYYY');
};

const fullTimestamp = (isoTimestamp: string, timeZone?: string) =>
    tzMoment(isoTimestamp, timeZone)
        .tz(userTimeZone)
        .format('MMM D, YYYY h:mm a z');

const cancelledAtTime = (meeting: BizlyAPI.Meeting) =>
    meeting.cancelledAt && `Cancelled on ${fullTimestamp(meeting.cancelledAt.date, meeting.cancelledAt.timezone)}`;

const MeetingName = (meeting: BizlyAPI.Meeting) => (
    <TruncatingSingleLineCopy maxWidth="100%">
        {meeting.name ? <b>{meeting.cancelledAt ? <Strike>{meeting.name}</Strike> : meeting.name}</b> : <i>Untitled</i>}
    </TruncatingSingleLineCopy>
);

export default function SortedList({
    meetings,
    loading,
    onClick,
    small,
}: {
    meetings: BizlyAPI.Meeting[];
    loading?: boolean;
    onClick: (meeting: BizlyAPI.Meeting) => void;
    small?: boolean;
}) {
    const { warningAction } = useThemedColor();
    const today = tzMoment();

    const renderShort = (meeting: BizlyAPI.Meeting) => {
        const startsAt = tzMoment(meeting.startsAt, meeting.timeZone).tz(userTimeZone);
        const isSameYear = startsAt.isSame(today, 'year');

        return (
            <>
                <ActivityColSmall>
                    <Copy>Activity</Copy>
                    <Tooltip title={fullTimestamp(meeting.updatedAt ?? meeting.createdAt.date)}>
                        <Copy regular>{shortTimestamp(meeting.updatedAt ?? meeting.createdAt.date)}</Copy>
                    </Tooltip>
                </ActivityColSmall>
                <NameColSmall>
                    {meeting.cancelledAt && (
                        <Tooltip title={cancelledAtTime(meeting)}>
                            <Pill color={warningAction} label="Cancelled" noShadow centerText />
                        </Tooltip>
                    )}
                    {MeetingName(meeting)}
                    <Copy small>{[meeting.createdBy.firstName, meeting.createdBy.lastName].join(' ')}</Copy>
                </NameColSmall>
                <DateOrEditCol alignItems="center">
                    {meeting.startsAt ? (
                        <Tooltip title={fullTimestamp(meeting.startsAt, meeting.timeZone)}>
                            <Row itemSpacing="small" alignItems="center">
                                <ClockCol>
                                    <ClockIcon />
                                </ClockCol>
                                <TimeCol>
                                    <Copy regular>{startsAt.format(`MMM D${isSameYear ? '' : ', YYYY'}`)}</Copy>
                                    <Copy small>{startsAt.format('h:mm a')}</Copy>
                                </TimeCol>
                            </Row>
                        </Tooltip>
                    ) : (
                        <CenteredRow>
                            <EditIcon />
                        </CenteredRow>
                    )}
                </DateOrEditCol>
            </>
        );
    };

    const renderFull = (meeting: BizlyAPI.Meeting) => {
        const startsAt = tzMoment(meeting.startsAt, meeting.timeZone).tz(userTimeZone);

        return (
            <>
                <ActivityCol>
                    <Copy>Activity</Copy>
                    <Tooltip title={fullTimestamp(meeting.updatedAt ?? meeting.createdAt.date)}>
                        <Copy regular>{shortTimestamp(meeting.updatedAt ?? meeting.createdAt.date)}</Copy>
                    </Tooltip>
                </ActivityCol>
                <NameCol>
                    {meeting.cancelledAt && (
                        <Tooltip title={cancelledAtTime(meeting)}>
                            <Pill color={warningAction} label="Cancelled" noShadow centerText />
                        </Tooltip>
                    )}
                    {meeting.cancelledAt ? <Strike>{MeetingName(meeting)}</Strike> : MeetingName(meeting)}
                </NameCol>
                <LocationCol>{meeting?.virtualLocation ?? 'Meeting room'}</LocationCol>
                <FullWidthCol alignItems="center">
                    {meeting.startsAt ? (
                        <Row itemSpacing="small" alignItems="center">
                            <ClockCol>
                                <ClockIcon />
                            </ClockCol>
                            <TimeCol>
                                <Copy regular>{startsAt.format(`MMM D, YYYY h:mm a`)}</Copy>
                            </TimeCol>
                        </Row>
                    ) : (
                        <EditIcon />
                    )}
                </FullWidthCol>

                <CreatedByRow itemSpacing="small">
                    <RightAlignCopy>
                        {[meeting.createdBy?.firstName, meeting.createdBy?.lastName].join(' ')}
                    </RightAlignCopy>
                    <CircleImg src={meeting.createdBy?.imageUrl} />
                </CreatedByRow>
            </>
        );
    };

    return (
        <Column itemSpacing="small">
            {meetings.map(meeting => (
                <HoverRow
                    key={meeting.id}
                    itemSpacing="default"
                    alignItems="center"
                    justifyContent="space-between"
                    onClick={() => onClick(meeting)}
                >
                    {small ? renderShort(meeting) : renderFull(meeting)}
                </HoverRow>
            ))}
            {loading && <SpinnerOverlay transparent clickThrough />}
        </Column>
    );
}
