import React from 'react';

import { useSnackbar } from 'notistack';

import { attendeesActions } from './store';

export default function LoadAttendees({ meetingId }: { meetingId: string | number }) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        try {
            attendeesActions.load(meetingId);
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    }, [meetingId, enqueueSnackbar]);

    return null;
}
