import React, { useMemo } from 'react';
import pickBy from 'lodash/pickBy';

import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useUser } from '../providers/user';
import { useEvent } from '../providers/event';
import { VenueFacet, TFacet } from '../components/VenueFacet';

import { Column, Copy, Row, Spacer } from '../ui';
import { Headline } from '../components/ui/Headline';
import VenueTile from '../components/VenueTile/VenueTile';
import VenueSearchButton from '../components/VenueSearchButton';

const FacetsList: TFacet[] = [
    {
        label: 'Hotels',
        facets: { types: ['hotel'] },
    },
    {
        label: 'Restaurants',
        facets: { types: ['restaurant'] },
    },
    {
        label: 'Unique Venues',
        facets: { types: ['unique venue'] },
    },
    {
        label: 'Modern Venues',
        facets: { decors: ['Modern'] },
    },
];

type TProps = {
    suggestedVenues: Bizly.Venue[];
    selectedVenues: { [id: number]: Bizly.Venue };
    onSelect?: (selected: Bizly.Venue) => void;
    onDeselect?: (selected: Bizly.Venue) => void;
    viewVenueListing: (venueId: number) => void;
    isCreateMeeting?: boolean;
} & RouteComponentProps;

const VenueDiscovery = ({
    suggestedVenues,
    onSelect,
    onDeselect,
    viewVenueListing,
    selectedVenues,
    isCreateMeeting,
}: TProps) => {
    const { user } = useUser();
    const { event, template, venueSearchParams } = useEvent();

    const facetsList: TFacet[] = useMemo(
        () =>
            user.team
                ? [
                      {
                          label: `${user.team.name} Locations`,
                          facets: { types: ['corporate office'] },
                      },
                      ...FacetsList,
                  ]
                : FacetsList,
        [user.team]
    );

    return (
        <Column>
            <Headline>Suggestions for Your Meeting</Headline>
            <Copy>Here are some venues that fit your meeting requirements</Copy>
            <Spacer />
            <Row style={{ marginBottom: -24 }}>
                {suggestedVenues[0] && (
                    <Column style={{ flex: '2 0 0' }}>
                        <VenueTile
                            venue={suggestedVenues[0]}
                            format="featured"
                            pillType="preferenceCategory"
                            /* venues on the right have a 24px extra height, 12px accounts for 24px right padding */
                            fixedRatio="calc(50% + 24px + 12px)"
                            padding="0 24px 0 0"
                            selected={suggestedVenues[0] && !!selectedVenues[suggestedVenues[0].id]}
                            onSelect={onSelect ? () => onSelect(suggestedVenues[0]) : undefined}
                            onDeselect={onDeselect ? () => onDeselect(suggestedVenues[0]) : undefined}
                            onClick={() => viewVenueListing(suggestedVenues[0].id)}
                        />
                    </Column>
                )}

                <Column style={{ flex: '1 0 0' }}>
                    {suggestedVenues.slice(1, 3).map(v => (
                        <VenueTile
                            key={v.id}
                            venue={v}
                            pillType="preferenceCategory"
                            fixedRatio="calc(50%)"
                            padding="0 0 24px 0"
                            selected={!!selectedVenues[v.id]}
                            onSelect={onSelect ? () => onSelect(v) : undefined}
                            onDeselect={onDeselect ? () => onDeselect(v) : undefined}
                            onClick={() => viewVenueListing(v.id)}
                        />
                    ))}
                </Column>
            </Row>
            <Spacer />
            <Row>
                <VenueSearchButton label="View more" eventId={event.id} facets={template?.venueSearchParameters} />
            </Row>
            <Spacer />

            {facetsList.map((props, idx) => (
                <>
                    <VenueFacet
                        key={props.label}
                        {...props}
                        facets={{ ...pickBy(venueSearchParams, k => k !== undefined && k !== null), ...props.facets }}
                        eventId={event.id}
                        onSelect={onSelect}
                        onDeselect={onDeselect}
                        viewVenueListing={viewVenueListing}
                        bottomSpacing={idx !== facetsList.length - 1}
                        selectedVenues={selectedVenues}
                        isCreateMeeting={isCreateMeeting}
                    />
                </>
            ))}
        </Column>
    );
};

const VenueDiscoveryPage = withRouter(VenueDiscovery);

export default VenueDiscoveryPage;
