import React from 'react';

import styled, { css } from 'styled-components/macro';
import { format, parseISO } from 'date-fns';

import { useAsync } from 'hooks/useAsync';
import { getVenueInquiries } from 'api';

import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';

import { padArray } from '../../util';

import { ReactComponent as VenuePinIconSVG } from 'images/icons/venue_pin.svg';
import { withIconStyles } from 'shared';

import { Button, Column, Spacer } from 'ui';
import { IconHeadline } from 'components/ui/Headline';
import { Spinner } from 'components/Spinner';
import Grid from '@material-ui/core/Grid';
import { VenueTileWithoutActions, VenueTileEmpty } from 'components/VenueTile/VenueTile';

import useThemedColor from 'hooks/useThemedColor';

const VenuePinIcon = withIconStyles(VenuePinIconSVG);

const StyledGrid = styled(Grid)<{ padTop?: boolean }>`
    margin: auto -10px;
    width: calc(100% + 20px);
    ${({ padTop }) => (padTop ? `padding-top: 10%;` : '')}

    > * {
        padding: 10px;
    }
`;

type BookedStatus = 'default' | 'hasBooked' | 'hasInquiries' | 'noCity';

export const BookedStateBanner = ({ bookedState, preferred }: { bookedState: BookedStatus; preferred: boolean }) => {
    const { bookedVenueStatus, submittedInquiryStatus, preferredVenue } = useThemedColor();

    let text;
    let backgroundColor;

    switch (bookedState) {
        case 'hasBooked':
            text = 'BOOKED';
            backgroundColor = bookedVenueStatus;
            break;
        case 'hasInquiries':
            text = 'SUBMITTED';
            backgroundColor = submittedInquiryStatus;
            break;
        default:
            if (preferred) {
                text = 'PREFERRED';
                backgroundColor = preferredVenue;
            }
            break;
    }

    return text ? (
        <div
            css={css`
                width: 84px;
                height: 21px;
                border-radius: 14px;
                box-shadow: 0 2px 10px 0 ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.3)};
                background-color: ${backgroundColor};

                display: flex;
                align-items: center;
                justify-content: center;

                font-size: 11px;
            `}
        >
            {text}
        </div>
    ) : null;
};

const VenueTilePlaceholder = styled(VenueTileEmpty).attrs(({ theme: { getColor, EColors } }) => ({
    'data-testid': 'venue-thumb',
    backgroundColor: getColor(EColors.softAccentedBackgroundNested),
    fixedRatio: '62%',
    width: '50%',
    padding: '10px',
}))``;

export const VenueTiles = ({
    venues,
    bookedState,
    viewVenueListing,
}: {
    venues: Bizly.Venue[];
    bookedState: BookedStatus;
    viewVenueListing?: (venueId: number) => void;
}) => (
    <>
        {padArray(venues, 4)
            .slice(0, 4)
            .map((venue: Bizly.Venue | undefined) =>
                venue ? (
                    <VenueTileWithoutActions
                        key={venue.id}
                        venue={venue}
                        format="tile"
                        pillType={
                            bookedState === 'default'
                                ? 'preferenceCategory'
                                : bookedState !== 'noCity'
                                ? 'inquiryStatus'
                                : undefined
                        }
                        fixedRatio="63%"
                        width="50%"
                        padding="10px"
                        onClick={viewVenueListing}
                    />
                ) : (
                    <VenueTilePlaceholder key={Math.random()} />
                )
            )}
    </>
);

const CenteredItemsCol = styled(Column)`
    flex: 1 1 0;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

const StyledColumn = styled(Column)`
    position: relative;
    flex-shrink: 0;
    width: 100%;
`;

const VenueSection = ({
    suggestedVenues,
    eventId,
    location,
    startsAt,
    endsAt,
    viewVenueListing,
    viewProposal,
    onSearch,
    onEditInquiries,
}: Partial<Bizly.Event> & {
    eventId: number;
    eventName: string;
    suggestedVenues: Bizly.Venue[];
    viewVenueListing: (venueId: number) => void;
    viewProposal: (proposalId: number) => void;
    onSearch: () => void;
    onEditInquiries: () => void;
}) => {
    const { highlightedText } = useThemedColor();

    const {
        toggles: { gate },
    } = useUser();

    const { event } = useEvent();
    const editable = event.editable;

    const { data: inquiries, loading } = useAsync(React.useCallback(() => getVenueInquiries(eventId), [eventId]));
    const bookedInquiry = inquiries && inquiries.find(inquiry => inquiry.booked);
    const timeFormat = 'MMM do h:mm a';
    const copy = {
        default: {
            subheading: 'Find a great venue to help make your meeting shine. Here are some suggestions in the area.',
        },
        noCity: {
            subheading:
                'Let us know where you’re looking by selecting a city up top, then we’ll help you find a great venue for your meeting.',
        },
        hasInquiries: {
            subheading:
                'You’ve submitted an inquiry. If venues can accommodate your meeting, they’ll respond with a proposal.',
        },
        hasBooked: {
            subheading: gate.useActivityCalendar
                ? ''
                : startsAt && endsAt
                ? `Your booking’s confirmed for ${format(parseISO(startsAt), timeFormat)}–${format(
                      parseISO(endsAt),
                      timeFormat
                  )}.`
                : 'Unable to retrieve the dates of your booking.',
        },
    };

    let bookedState: 'default' | 'noCity' | 'hasBooked' | 'hasInquiries';

    const hasInquiryVenues = inquiries && inquiries.some(inquiry => inquiry.venue && inquiry.status !== 'Added');

    if (hasInquiryVenues) {
        bookedState = bookedInquiry ? 'hasBooked' : 'hasInquiries';
    } else {
        bookedState = location ? 'default' : 'noCity';
    }

    const venues = hasInquiryVenues
        ? inquiries?.map(inquiry => ({ status: inquiry.status, ...inquiry.venue }))
        : location
        ? suggestedVenues
        : [];

    const renderVenueCards = () => (
        <StyledGrid container justify="center" padTop={!!bookedInquiry}>
            {bookedInquiry ? (
                <VenueTileWithoutActions
                    key={bookedInquiry.venue.id}
                    venue={{ ...bookedInquiry.venue, status: bookedInquiry.status }}
                    format="proposalThumb"
                    pillType="inquiryStatus"
                    fixedRatio="56%"
                    width="100%"
                    onClick={viewVenueListing}
                />
            ) : (
                venues && (
                    <VenueTiles venues={venues || []} bookedState={bookedState} viewVenueListing={viewVenueListing} />
                )
            )}
        </StyledGrid>
    );

    if (loading) {
        return (
            <>
                <StyledColumn>
                    <IconHeadline icon={<VenuePinIcon />} headline="Venue" description={''} />
                </StyledColumn>
                <Spacer />
                <CenteredItemsCol>
                    <Spinner />
                </CenteredItemsCol>
            </>
        );
    }

    return (
        <>
            <StyledColumn>
                <IconHeadline
                    icon={<VenuePinIcon />}
                    headline="Venue"
                    description={copy[bookedState].subheading}
                    {...(bookedInquiry ? { descriptionColor: highlightedText } : {})}
                />
                {['hasBooked', 'hasInquiries'].includes(bookedState) ? (
                    <Button
                        style={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            margin: '0.5em 0px',
                        }}
                        variant="outlined"
                        onClick={() => {
                            if (bookedState === 'hasBooked') {
                                viewProposal(bookedInquiry?.proposalId as number);
                            } else if (bookedState === 'hasInquiries') {
                                onEditInquiries();
                            } else {
                                onSearch();
                            }
                        }}
                    >
                        {bookedState === 'hasBooked' || !editable ? 'View' : 'Edit'}
                    </Button>
                ) : null}
            </StyledColumn>

            {location && !hasInquiryVenues && (
                <>
                    <Button
                        style={{
                            margin: '11px 0 20px 0',
                        }}
                        onClick={onSearch}
                    >
                        Find a venue
                    </Button>
                </>
            )}

            {renderVenueCards()}
        </>
    );
};

export default VenueSection;
