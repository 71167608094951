import React from 'react';
import styled from 'styled-components';

import { InlineRow, Row } from 'ui';
import { H3Headline, LargeHeadline } from 'components/ui/Headline';
import Button from 'components/ui/Button';
import CircleCheckIcon from 'components/CircleCheckIcon';
import CircleXIcon from 'components/CircleXIcon';

const BaselineAlignmentSvg = styled.svg.attrs({ height: 30, width: 0 })`
    margin: 0;
`;

export default function RespondableHeadline({
    meeting,
    onRespond,
    disabled,
}: {
    meeting: BizlyAPI.Meeting;
    onRespond: (response: 'accept' | 'reject') => void;
    disabled?: boolean;
}) {
    return (
        <Row alignItems="baseline" itemSpacing="small">
            <LargeHeadline>{meeting.name}</LargeHeadline>
            <InlineRow alignItems="center" itemSpacing="smallish">
                {meeting.currentUserAttendee && meeting.currentUserAttendee.status !== 'not sent' && (
                    <>
                        <BaselineAlignmentSvg />
                        <InlineRow alignItems="center" itemSpacing="small">
                            {meeting.currentUserAttendee?.status === 'attending' && (
                                <>
                                    <CircleCheckIcon />
                                    <H3Headline>Attending</H3Headline>
                                </>
                            )}
                            {meeting.currentUserAttendee?.status === 'not attending' && (
                                <>
                                    <CircleXIcon />
                                    <H3Headline>Rejected</H3Headline>
                                </>
                            )}
                        </InlineRow>
                        {meeting.currentUserAttendee.status !== 'attending' && (
                            <Button onClick={() => onRespond('accept')} disabled={disabled}>
                                Accept
                            </Button>
                        )}
                        {meeting.currentUserAttendee.status !== 'not attending' && (
                            <Button onClick={() => onRespond('reject')} disabled={disabled} warning>
                                Reject
                            </Button>
                        )}
                    </>
                )}
            </InlineRow>
        </Row>
    );
}
