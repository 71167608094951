import React from 'react';

import styled from 'styled-components/macro';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { format, parse } from 'date-fns';
import { useSnackbar } from 'notistack';

import { API_DATE_FORMAT, API_TIME_FORMAT } from 'utils/date_util';

import { ReactComponent as CalendarIconSVG } from 'images/icons/calendar.svg';
import { withIconStyles } from 'shared';

import { useSchedule, scheduleActions } from 'stores/schedule';
import { copyTemplateSchedule } from 'api/eventTemplates';
import { useEvent } from 'providers/event';

import { Link, useHistory } from 'react-router-dom';

import { Column, Copy, TruncatingSingleLineCopy, Spacer } from 'ui';
import { IconHeadline } from 'components/ui/Headline';
import { SpinnerOverlay } from 'components/Spinner';
import Button from 'components/ui/Button';
import Form from 'components/Form';

import colorFns from 'colorFns';
import fontFns from 'fontFns';

const formatDate = (date: string) => format(parse(date, API_DATE_FORMAT, new Date()), 'EEE, MMM dd, yyyy');
const formatTime = (time: string) => format(parse(time, API_TIME_FORMAT, new Date()), 'h:mm a');

const CalendarIcon = withIconStyles(CalendarIconSVG);

const TopRightButton = styled(Button)`
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5em 0;
`;

const StyledColumn = styled(Column)`
    position: relative;
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
    align-items: flex-start;
`;

const NoBackgroundSpinner = styled(SpinnerOverlay)`
    background: transparent;
`;

const AgendaDays = styled(Column)`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    min-height: 0;
    width: 100%;

    overflow: auto;
`;

const DateTableRow = styled.tr``;

const AgendaDaysTable = styled.table`
    > tr > td {
        padding: 6px 0;
    }

    > ${DateTableRow} ~ ${DateTableRow} > td {
        padding-top: 16px;
    }
`;

const FillWidthTableCell = styled.td`
    width: 100%;
`;

const FormLabel = styled(Copy)`
    color: ${colorFns.formLabel};
    ${fontFns.formLabel}
    font-weight: 600;
`;

const BlockName = styled(TruncatingSingleLineCopy)``;
const BlockNameWrapper = styled.div`
    position: relative;
    height: 100%;
    margin-right: 16px;

    > ${BlockName} {
        position: absolute;
        width: 100%;
    }
`;

const BlockTimes = styled(Copy)<{ alignLeft?: boolean }>`
    text-align: right;
    ${({ alignLeft }) => alignLeft && 'text-align: left;'}
    white-space: nowrap;
`;

const BlockTimesDelimiter = styled.td`
    min-width: 26px;
    text-align: center;
`;

const RightButton = styled(Button)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    margin-top: 8.5px;
    margin-bottom: 4px;

    height: 40px;
`;

const FullWidthForm = styled(Form)`
    width: 100%;
`;

type TFormValue = Partial<{ date: Date | null }>;

export default function AgendaSection() {
    const {
        event: { id: eventId, editable, scheduleCopied },
        setEvent,
    } = useEvent();

    const schedule = useSchedule();

    const scheduleItems =
        (schedule.loaded &&
            schedule.eventId === eventId &&
            schedule.items.filter(item => item.date && item.startTime && item.endTime)) ||
        [];
    const hasScheduleItems = scheduleItems.length > 0;
    const loadedAndEmpty = schedule.loaded && !hasScheduleItems;

    const scheduleItemsByDate: Record<string, BizlyAPI.ScheduleItem[]> = groupBy(
        orderBy(scheduleItems, ['date', 'startTime']),
        item => item.date
    );

    const history = useHistory();
    const goToBlock = (item?: BizlyAPI.ScheduleItem) => history.push(`/event/${eventId}/agenda`, item);
    const goToAgenda = () => goToBlock();

    const [formValue, setFormValue] = React.useState<TFormValue>({});
    const [saving, setSaving] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const copySchedule = async () => {
        const { date } = formValue;
        if (!date) return;
        try {
            setSaving(true);
            const { event: updatedEvent } = await copyTemplateSchedule(eventId, date);
            await scheduleActions.load(eventId);
            goToAgenda();
            setEvent(updatedEvent);
        } catch {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        } finally {
            setSaving(false);
        }
    };

    const fields = {
        date: {
            type: 'date_outlined',
            perRow: '2/3',
            options: {
                placeholder: 'Select a Date',
            },
        },
        button: {
            type: RightButton,
            perRow: '1/3',
            disabled: !formValue.date,
            options: {
                children: 'Create',
                width: 'auto',
                onClick: () => copySchedule(),
            },
        },
    };

    const schema = [
        {
            fields: ['date', 'button'],
            itemSpacing: false,
            combined: true,
            spacing: false,
        },
    ];

    return (
        <StyledColumn>
            <IconHeadline
                icon={<CalendarIcon />}
                headline="Agenda"
                description="Plan the details to make your meeting a huge success."
            />

            <Spacer />

            {hasScheduleItems && (
                <Link to={`/event/${eventId}/agenda`}>
                    <TopRightButton secondary variant="outlined">
                        {editable ? 'Edit' : 'View'}
                    </TopRightButton>
                </Link>
            )}

            {hasScheduleItems && (
                <AgendaDays>
                    <AgendaDaysTable>
                        {Object.entries(scheduleItemsByDate).map(([date, items]) => (
                            <React.Fragment key={date}>
                                <DateTableRow>
                                    <td colSpan={4}>
                                        <FormLabel regular>{formatDate(date)}</FormLabel>
                                    </td>
                                </DateTableRow>
                                {items.map(({ name, startTime, endTime }, index) => (
                                    <tr key={index} onClick={() => goToBlock(items[index])}>
                                        <FillWidthTableCell>
                                            <BlockNameWrapper>
                                                <BlockName regular title={name}>
                                                    {name || <em>Untitled</em>}
                                                </BlockName>
                                            </BlockNameWrapper>
                                        </FillWidthTableCell>

                                        <td>
                                            <BlockTimes regular>{formatTime(startTime)}</BlockTimes>
                                        </td>

                                        <BlockTimesDelimiter>
                                            <Copy regular>to</Copy>
                                        </BlockTimesDelimiter>

                                        <td>
                                            <BlockTimes regular alignLeft>
                                                {formatTime(endTime)}
                                            </BlockTimes>
                                        </td>
                                    </tr>
                                ))}
                            </React.Fragment>
                        ))}
                    </AgendaDaysTable>
                </AgendaDays>
            )}

            {loadedAndEmpty ? (
                scheduleCopied ? (
                    <Link to={`/event/${eventId}/agenda`}>
                        <Button width={78}>{editable ? 'Create' : 'View'}</Button>
                    </Link>
                ) : (
                    <FullWidthForm
                        fields={fields}
                        schema={schema}
                        value={formValue}
                        onChange={({ value }: { value: TFormValue }) => {
                            setFormValue(value);
                        }}
                        disabled={saving || !editable}
                    />
                )
            ) : null}

            {(schedule.loading || saving) && <NoBackgroundSpinner />}
        </StyledColumn>
    );
}
