import { tzMoment, userTimeZone } from 'utils/moment';

import { TProposalForm } from './types';
import RejectionReasonForm from 'components/ProposalForm/RejectionReasonForm';
import React, { ReactNode } from 'react';

type TProposalFormStatus =
    | 'Inquiry Invalid'
    | 'Inquiry Submitted'
    | 'Inquiry Cancelled'
    | 'Inquiry Rejected Reversible'
    | 'Inquiry Rejected Terminary'
    | 'Proposal Submitted'
    | 'Proposal Accepted'
    | 'Proposal Rejected'
    | 'Proposal Cancelled Reversible'
    | 'Proposal Cancelled Terminary';

export const submittedProposal = (proposal: TProposalForm['proposal'] = {}) => proposal.status !== 'draft';

export const getProposalFormStatus = ({
    proposal = {},
    inquiry = {},
}: Partial<TProposalForm>): TProposalFormStatus | undefined => {
    const { status: proposalStatus } = proposal;
    const { status: inquiryStatus, isValid } = inquiry;

    if (!proposalStatus || !inquiryStatus) {
        return undefined;
    }

    if (submittedProposal(proposal)) {
        switch (proposalStatus) {
            case 'submitted':
                return 'Proposal Submitted';
            case 'accepted':
                return 'Proposal Accepted';
            case 'rejected':
                return 'Proposal Rejected';
            case 'cancelled':
                return isValid ? 'Proposal Cancelled Reversible' : 'Proposal Cancelled Terminary';
            default:
                return undefined;
        }
    } else {
        switch (inquiryStatus) {
            case 'submitted':
                return isValid ? 'Inquiry Submitted' : 'Inquiry Invalid';
            case 'rejected':
                return isValid ? 'Inquiry Rejected Reversible' : 'Inquiry Rejected Terminary';
            case 'cancelled':
                return 'Inquiry Cancelled';
            default:
                return isValid ? 'Inquiry Submitted' : 'Inquiry Cancelled';
        }
    }
};

export type TAction = 'accept' | 'reject' | 'accept-extension' | 'reject-extension';

const rejectedInquiryHeading = (action?: TAction) => () =>
    action === 'reject' ? 'The inquiry has already been rejected' : 'The inquiry has been rejected';

const cancelledProposalHeading = (action?: TAction) => () =>
    action === 'reject' ? 'The proposal has already been cancelled' : 'The proposal has been cancelled';

export type TStatusAttributes = {
    skipToStatus: boolean;

    noProposal: boolean;
    readonly: boolean;

    canView: boolean;
    canCreate: boolean;
    canSubmit: boolean;
    isResubmit: boolean;
    canTurnDown: boolean;
    canWithdraw: boolean;

    heading: (proposalForm: Partial<TProposalForm>) => string;
    copy: (proposalForm: Partial<TProposalForm>) => ReactNode;
    links: (proposalForm: Partial<TProposalForm>) => { link: string; label: string }[];
};

export const getStatusAttributes = (
    status?: TProposalFormStatus,
    action?: TAction,
    userChangedStatus?: boolean
): TStatusAttributes => {
    const defaults = {
        skipToStatus: true,

        noProposal: false,
        readonly: false,

        canView: true,
        canCreate: false,
        canSubmit: false,
        isResubmit: false,
        canTurnDown: false,
        canWithdraw: false,

        heading: () => '',
        copy: () => '',
        links: () => [],
    };

    switch (status) {
        case 'Inquiry Submitted':
            return {
                ...defaults,
                skipToStatus: false,
                canSubmit: true,
                canTurnDown: true,
            };

        case 'Inquiry Invalid':
            return {
                ...defaults,
                noProposal: true,
                canView: false,

                heading: () => 'Unfortunately, this inquiry is no longer open to new proposals.',
            };

        case 'Inquiry Rejected Reversible':
            return {
                ...defaults,
                skipToStatus: action === 'reject',
                noProposal: false,
                canCreate: true,

                heading: rejectedInquiryHeading(action),
                copy: ({ inquiry }: Partial<TProposalForm>) => (
                    <RejectionReasonForm
                        value={{ reason: inquiry?.rejectionReason || '', note: inquiry?.rejectionNotes || '' }}
                        readonly
                    />
                ),
            };

        case 'Inquiry Rejected Terminary':
            return {
                ...defaults,
                noProposal: true,
                canView: false,

                heading: rejectedInquiryHeading(action),
                copy: ({ inquiry }: Partial<TProposalForm>) => (
                    <RejectionReasonForm
                        value={{ reason: inquiry?.rejectionReason || '', note: inquiry?.rejectionNotes || '' }}
                        readonly
                    />
                ),
            };
        case 'Inquiry Cancelled':
            return {
                ...defaults,
                noProposal: true,
                canView: false,

                heading: () => 'Unfortunately, the inquiry has been withdrawn.',
            };
        case 'Proposal Submitted':
            return {
                ...defaults,
                canSubmit: true, // no need to worry about isValid because accepting a proposal rejects all others
                isResubmit: true,
                canWithdraw: true,

                heading: () =>
                    userChangedStatus
                        ? 'Congrats! Your proposal has been submitted.'
                        : 'Your proposal has been submitted.',

                copy: ({ client = {}, proposal = {}, venue = {} }: Partial<TProposalForm>) => [
                    `${[client.firstName, client.lastName].join(' ')} has until ${tzMoment(
                        proposal.expiryDate + ' 23:59:59',
                        venue.timeZone
                    )
                        .tz(venue.timeZone ?? userTimeZone)
                        .format('MMM DD, YYYY h:mm:ss a zz')} to respond.`,
                ],
            };
        case 'Proposal Accepted':
            return {
                ...defaults,
                readonly: true,

                heading: ({ client = {} }: Partial<TProposalForm>) =>
                    `Congrats! Your proposal was accepted by ${[client.firstName, client.lastName].join(' ')}.`,
                copy: ({ booking = {} }: Partial<TProposalForm>) =>
                    booking?.contractUrl ? [''] : ['When the contract is complete, it will appear here.'],
                links: ({ booking = {} }: Partial<TProposalForm>) => {
                    const urls: { link: string; label: string }[] = [];
                    booking?.contractUrl &&
                        urls.push({
                            link: booking.contractUrl,
                            label: booking?.contract2Url ? 'View Contract 1' : 'View Contract',
                        });
                    booking?.contract2Url && urls.push({ link: booking.contract2Url, label: 'View Contract 2' });
                    return urls;
                },
            };
        case 'Proposal Rejected':
            return {
                ...defaults,
                readonly: true,

                heading: () => 'Unfortunately, your proposal was rejected.',
            };
        case 'Proposal Cancelled Terminary':
            return {
                ...defaults,
                readonly: true,

                heading: cancelledProposalHeading(action),
            };
        case 'Proposal Cancelled Reversible':
            return {
                ...defaults,
                canSubmit: true,

                heading: cancelledProposalHeading(action),
            };
        default:
            return defaults;
    }
};
