import { addDays, addSeconds, differenceInSeconds, format, parse, parseISO, startOfDay } from 'date-fns';
import { maxBy, minBy } from 'lodash';
import { tz as momentTz } from 'moment-timezone';
import moment from 'moment';

export const DATE_USED_TO_FORMAT_TIME = '2020-01-01';
const dayFormat = 'yyyy-MM-dd';
const timeFormat = `${dayFormat} HH:mm`; // zero indexed 24 hour clock

export const API_DATE_FORMAT = 'yyyy-MM-dd';
export const API_TIME_FORMAT = 'HH:mm:ss';
const ISO_DATETIME_FORMAT = `${API_DATE_FORMAT} ${API_TIME_FORMAT}`;

export const serializeDate = (dateStr?: string) => (dateStr ? parse(dateStr, timeFormat, new Date()) : null);

export const moveTimeToDay = (newDate: Date, dateWithWantedTime: Date) =>
    !dateWithWantedTime
        ? newDate
        : addSeconds(startOfDay(newDate), differenceInSeconds(dateWithWantedTime, startOfDay(dateWithWantedTime)));

export const moveDays = (date: Date, quantity?: number) => addDays(date, quantity || 0);

export const timeValue = (timeString: string) => timeString && Number(timeString.split(':').join(''));

export const mergeDateTime = (date: string, time: string) => `${date} ${time}`;

export const moveDayBy = (date: string, delta: number = 1) => {
    const currentDate = mergeDateTime(date, '00:00');
    const newDay = addDays(parseISO(currentDate), delta);

    return format(newDay, dayFormat);
};

export const adjustTime = (timeString: string) => {
    const [hours, minutes, seconds] = timeString.split(':');
    const halfHour = Number(minutes) > 0;
    const adjustedMinutes = halfHour ? '00' : '30';
    const adjustedHours = halfHour ? Number(hours) + 1 : hours;
    const resetHours = Number(adjustedHours) > 23;
    return `${resetHours ? 0 : adjustedHours}:${adjustedMinutes}:${seconds}`;
};

export const fixedTime = (baseTime: string, minTime: string) =>
    baseTime && minTime && timeValue(baseTime) <= timeValue(minTime) ? adjustTime(minTime) : baseTime;

const TIME_CHECK_FORMAT = 'H:mm:ss';
export const roundEndOfDayto12AM = (date: Date) =>
    format(date, TIME_CHECK_FORMAT) === '23:59:59' ? addDays(startOfDay(date), 1) : date;

export const is12AM = (date: Date) => moment(date).format(TIME_CHECK_FORMAT) === '0:00:00';

export const datetimeStrToLocalDate = (date: string, time: string) =>
    parse(`${date} ${time}`, ISO_DATETIME_FORMAT, new Date());

export const apiDateToDateObj = (date: string) => parse(date, API_DATE_FORMAT, new Date());

//ISO Dates are string sortable
export const getDateRange = (list: string[]): [Date | undefined, Date | undefined] => {
    const start = minBy(list);
    const end = maxBy(list);

    const startDate = start ? apiDateToDateObj(start) : undefined;
    const endDate = end ? apiDateToDateObj(end) : undefined;

    return [startDate, endDate];
};

export const getDateTimeStrings = (baseDate?: string | Date, zone?: string) =>
    baseDate && zone
        ? {
              date: momentTz(baseDate, zone).format('YYYY-MM-DD'),
              time: momentTz(baseDate, zone).format('HH:mm:ss'),
          }
        : {};

export const getShortTimeZone = (baseDate: string | Date, zone: string) => momentTz(baseDate, zone).format('z');

export const timeOptions = [
    { id: '00:00:00', value: '00:00:00', name: '12:00 AM' },
    { id: '00:30:00', value: '00:30:00', name: '12:30 AM' },
    { id: '01:00:00', value: '01:00:00', name: '1:00 AM' },
    { id: '01:30:00', value: '01:30:00', name: '1:30 AM' },
    { id: '02:00:00', value: '02:00:00', name: '2:00 AM' },
    { id: '02:30:00', value: '02:30:00', name: '2:30 AM' },
    { id: '03:00:00', value: '03:00:00', name: '3:00 AM' },
    { id: '03:30:00', value: '03:30:00', name: '3:30 AM' },
    { id: '04:00:00', value: '04:00:00', name: '4:00 AM' },
    { id: '04:30:00', value: '04:30:00', name: '4:30 AM' },
    { id: '05:00:00', value: '05:00:00', name: '5:00 AM' },
    { id: '05:30:00', value: '05:30:00', name: '5:30 AM' },
    { id: '06:00:00', value: '06:00:00', name: '6:00 AM' },
    { id: '06:30:00', value: '06:30:00', name: '6:30 AM' },
    { id: '07:00:00', value: '07:00:00', name: '7:00 AM' },
    { id: '07:30:00', value: '07:30:00', name: '7:30 AM' },
    { id: '08:00:00', value: '08:00:00', name: '8:00 AM' },
    { id: '08:30:00', value: '08:30:00', name: '8:30 AM' },
    { id: '09:00:00', value: '09:00:00', name: '9:00 AM' },
    { id: '09:30:00', value: '09:30:00', name: '9:30 AM' },
    { id: '10:00:00', value: '10:00:00', name: '10:00 AM' },
    { id: '10:30:00', value: '10:30:00', name: '10:30 AM' },
    { id: '11:00:00', value: '11:00:00', name: '11:00 AM' },
    { id: '11:30:00', value: '11:30:00', name: '11:30 AM' },
    { id: '12:00:00', value: '12:00:00', name: '12:00 PM' },
    { id: '12:30:00', value: '12:30:00', name: '12:30 PM' },
    { id: '13:00:00', value: '13:00:00', name: '1:00 PM' },
    { id: '13:30:00', value: '13:30:00', name: '1:30 PM' },
    { id: '14:00:00', value: '14:00:00', name: '2:00 PM' },
    { id: '14:30:00', value: '14:30:00', name: '2:30 PM' },
    { id: '15:00:00', value: '15:00:00', name: '3:00 PM' },
    { id: '15:30:00', value: '15:30:00', name: '3:30 PM' },
    { id: '16:00:00', value: '16:00:00', name: '4:00 PM' },
    { id: '16:30:00', value: '16:30:00', name: '4:30 PM' },
    { id: '17:00:00', value: '17:00:00', name: '5:00 PM' },
    { id: '17:30:00', value: '17:30:00', name: '5:30 PM' },
    { id: '18:00:00', value: '18:00:00', name: '6:00 PM' },
    { id: '18:30:00', value: '18:30:00', name: '6:30 PM' },
    { id: '19:00:00', value: '19:00:00', name: '7:00 PM' },
    { id: '19:30:00', value: '19:30:00', name: '7:30 PM' },
    { id: '20:00:00', value: '20:00:00', name: '8:00 PM' },
    { id: '20:30:00', value: '20:30:00', name: '8:30 PM' },
    { id: '21:00:00', value: '21:00:00', name: '9:00 PM' },
    { id: '21:30:00', value: '21:30:00', name: '9:30 PM' },
    { id: '22:00:00', value: '22:00:00', name: '10:00 PM' },
    { id: '22:30:00', value: '22:30:00', name: '10:30 PM' },
    { id: '23:00:00', value: '23:00:00', name: '11:00 PM' },
    { id: '23:30:00', value: '23:30:00', name: '11:30 PM' },
] as const;

export const durationOptions = [
    { id: 0, name: '-' },
    { id: 5, name: '5 mins' },
    { id: 10, name: '10 mins' },
    { id: 15, name: '15 mins' },
    { id: 20, name: '20 mins' },
    { id: 25, name: '25 mins' },
    { id: 30, name: '30 mins' },
    { id: 35, name: '35 mins' },
    { id: 40, name: '40 mins' },
    { id: 45, name: '45 mins' },
    { id: 50, name: '50 mins' },
    { id: 55, name: '55 mins' },
    { id: 60, name: '1 hour' },
] as const;
