export default {
    brand: '#1e1e1f',

    /* Buttons */
    primaryAction: '#007aff',
    primaryActionHover: '#005bbe',

    warningAction: '#FF2D55',
    warningActionHover: '#E00C35',

    secondaryTextAction: '#FF2D55',
    secondaryTextActionHover: '#E00C35',
    warningTextAction: '#007AFF',
    warningTextActionHover: '#005BBE',

    disabledAction: '#e6e5e3',

    /* Icons */
    defaultIconColor: '#576cf4',

    primaryDisplayIcon: '#1d1d1f',
    invertedDisplayIcon: '#ffffff',

    protipsIcon: '#ffcc00',
    protipsBubble: '#f5b11e',

    tilePinIcon: '#c7c6c5',

    amenitiesIconBackground: '#ffffff',

    /* Attendee Status */
    totalAttendee: '#2c2c2c',
    invitedAttendee: '#FF9500',
    notInvitedAttendee: '#909090',
    attendingAttendee: '#28cd41',
    notAttendingAttendee: '#FF2D55',

    /* Survey Response Status */
    surveysSentTotal: '#000000',
    surveysPending: '#FF9500',
    surveysRespondedTo: '#28cd41',

    /* Event Status */
    uneditedEvent: '#007aff',
    activeEvent: '#007aff',
    activeEventHover: '#005bbe',
    completedEvent: '#28cd41',
    completedEventHover: '#00B71C',

    /* Dashboard */
    dashboardCard: '#f2f2f2',
    dashboardHeader: '#1d1d1f',

    /* Parcel Status */
    parcelInvite: '#007AFF',
    parcelInviteHover: '#005BBE',
    parcelInviteSent: '#28CD41',
    parcelInviteSentHover: '#00B71C',

    parcelNote: '#5856D6',
    parcelNoteHover: '#3F3DB2',
    parcelNoteSent: '#AF52DE',
    parcelNoteSentHover: '#A152C9',

    /* Inquiry Status */
    submittedInquiryStatus: '#28cd41',
    receivedProposalStatus: '#FF9500',
    cancelledOrRejectedStatus: '#FF2D55',
    contractPendingStatus: '#FF9500',
    bookedVenueStatus: '#AF52DE',

    /* Collaborators */
    canViewPillBackground: '#5856D6', // waiting
    canViewPillText: '#ffffff', // waiting

    /* Team Related */
    homeScreenBanner: '#1e1e1f',
    officePillColor: '#0582ee',
    teamTemplatePill: '#0582ee',

    teamPreferenceNotesHeader: '#0582ee',

    /*
        Venues                      
    */

    /* Venue Search */
    venueSearchToken: '#909090',
    venueSearchTokenHover: '#787878',

    /* Venue Map Pins */
    venuePinBase: '#ff5959',
    venuePinOffice: '#0582ee',

    /* Venue Space OR Listing */
    featuredVenueOrSpaceName: '#ffffff',

    tagColor: '#909090',
    restaurantTagColor: '#909090',

    venueListingHeaders: '#1d1d1f',

    preferenceNotesHighlightedText: '#1d1d1f',

    displayPricing: '#28cd41',

    /* Forms */
    formLabel: '#1d1d1f',
    formHeading: '#1d1d1f',
    optionalSpecifier: '#909090',

    /* Panes */
    agendaDayPane: '#4a4a4a',
    agendaDayPaneText: '#ffffff',
    paneListItemBackground: '#f2f2f2',
    paneInsideListItemBackground: '#ffffff',

    /* snackbars */
    snackbarSuccess: '#28cd41',
    snackbarError: '#ff2d55',
    snackbarInfo: '#FF9500',

    /* dropdowns */
    dropdownCheck: '#007aff',
    dropdownItem: '#ffffff',
    dropdownItemHover: '#f2f2f2',
    dropdownDescription: '#909090',
    dropdownTokenBackground: '#f2f2f2',
    dropdownTokenText: '#1d1d1f',

    /* progress bars */
    progressBarForeground: '#28cd41',
    progressBarBackground: '#e5e5e3',

    /* misc ui */
    accentedHeadline: '#1d1d1f',
    highlightedText: '#1d1d1f',

    softAccentedBackground: '#f2f2f2',
    softAccentedBackgroundNested: '#ffffff',
    strongAccentedBackground: '#1d1d1f',

    warningBackground: '#ff2d55',
    warningText: '#FF2D55',

    bizlyHeadlineDot: '#04c6bc',

    placeholderBackground: '#f2f2f2',
    strongCarouselBackground: '#f2f2f2', //loading is a darker shade;
    strongCarouselText: '#1d1d1f',

    softBorder: '#e6e5e3',

    listItemBackground: '#f2f2f2',
    listItemNestedBackground: '#ffffff',

    imagePlaceholderBackground: '#ededed',
    imagePlaceholderIcon: '#d5d5d5',

    /* Preference Pills */
    preferredVenue: '#2f4bf2',
    preferredRank10: '#0582ee',
    preferredRank20: '#b5d0da',
    preferredRank30: '#f8c50a',
    preferredRank40: '#bfbfbf',
    preferredRank50: '#ad843c',

    /* neutrals */
    black: '#1d1d1f',
    darkestGrey: '#303030',
    darkerGrey: '#4a4a4a',
    darkGrey: '#909090',
    grey: '#c7c6c5',
    lightGrey: '#e6e5e3',

    /* pures */
    pureWhite: '#ffffff',
    pureBlack: '#000000',
};
