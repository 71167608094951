import React from 'react';
import { kmToMi, miToKm } from 'utils';

import { createPlaybookActions, useCreatePlaybook } from './store';

import VenueFiltersForm from './VenueFiltersForm';

export default function VenueFilters() {
    const { venueFilters: value } = useCreatePlaybook();
    return (
        <VenueFiltersForm
            value={value}
            onChange={({ field, value }) => {
                const newVal = { ...value };

                if (field === 'radius') {
                    newVal.radiusKm = miToKm(newVal.radius);
                }

                if (field === 'radiusKm') {
                    newVal.radius = kmToMi(newVal.radiusKm);
                }

                createPlaybookActions.updateVenueFilters({ value: newVal });
            }}
        />
    );
}
