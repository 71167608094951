import React from 'react';
import styled from 'styled-components';
import {
    InputLabel as MUIInputLabel,
    FormControl,
    Select as MUISelect,
    withStyles,
    InputBase,
    MenuItem,
} from '@material-ui/core';
import { parseISO } from 'date-fns';

const InputLabel = styled(MUIInputLabel)`
    top: auto;
    bottom: 32px;
    left: 8.5px;
`;

const Select = styled(MUISelect)`
    width: 120px;
`;

const BootstrapInput = withStyles(() => ({
    input: {
        borderBottom: 'none',
        padding: '0.5em',
    },
}))(InputBase);

function sortAlphabeticallyForProperty<TKey extends string, TObject extends { [key in TKey]?: string }>(
    arr: TObject[],
    key: TKey
) {
    return arr.slice().sort((a, b) => a[key]?.localeCompare(b[key] ?? '', 'en', { sensitivity: 'base' }) ?? 1);
}

const sortByDate = (arr: Bizly.Event[]) =>
    arr.slice().sort((a, b) => {
        const aTime = a.startsAt ? parseISO(a.startsAt).getTime() : Infinity;
        const bTime = b.startsAt ? parseISO(b.startsAt).getTime() : Infinity;

        return aTime < bTime ? -1 : 1; // negative = prioritize aTime
    });

export const sortEvents = (events: Bizly.Event[], method: TSortBy) => {
    switch (method) {
        case 'date':
            return sortByDate(events);
        case 'location':
            return sortAlphabeticallyForProperty(events, 'location');
        case 'eventTitle':
            return sortAlphabeticallyForProperty(events, 'name');
        default:
            return events;
    }
};

export type TSortBy = 'date' | 'location' | 'eventTitle';

export type TSortOption = {
    key: string;
    value: TSortBy;
    text: string;
};

type TSortControlProps = {
    sortValue: string;
    options: TSortOption[];
    onChange: (newSortValue: TSortBy) => void;
};

const SortControl = ({
    // TODO: Make this more universal
    sortValue,
    options,
    onChange,
}: TSortControlProps) => (
    <FormControl>
        <InputLabel htmlFor="meeting-list-sort">Sort by</InputLabel>
        <Select
            value={sortValue}
            onChange={(e: any) => onChange(e.target.value)}
            input={<BootstrapInput name="sort" id="sort-simple" />}
        >
            {options.map(({ key, value, text }) => (
                <MenuItem key={key} value={value}>
                    {text}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

export default SortControl;
