import React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components/macro';

import { useSnackbar } from 'notistack';

import { useUser } from 'providers/user';
import { isAddCollaboratorsPartialError } from 'api';
import { LoadPlaybookCollaborators, playbooksActions, selectCollaborators, usePlaybooks } from 'stores/playbooks';

import { Button, Column, Copy, Row, Spacer } from 'ui';
import { Spinner } from 'components/Spinner';
import Avatar from 'components/ui/Avatar';
import SearchCollaborators from 'components/Collaborators/SearchCollaborators';
import { DisplayField } from 'components/FormFields';

import { ReactComponent as DeleteIconSVG } from 'images/icons/trash-can.svg';
import { withInteractibleIconStyles } from 'shared';
import colorFns from 'colorFns';

const ListItem = styled(Row)`
    padding: 12px;
    width: 500px;
`;

const DeleteIcon = styled(withInteractibleIconStyles(DeleteIconSVG))`
    height: 35px;
    width: 35px;
`;

const SearchCollaboratorsInput = styled(SearchCollaborators)`
    flex: 1;
`;

export default function CollaboratorsList({
    playbookId,
    readonly,
}: {
    playbookId: string | number;
    readonly?: boolean;
}) {
    const playbookCollaborators = usePlaybooks(selectCollaborators(playbookId));
    const { user, toggles } = useUser();
    const { enqueueSnackbar } = useSnackbar();

    const [fieldKey, setFieldKey] = React.useState(1);

    const [newCollaborators, setNewCollaborators] = React.useState<BizlyAPI.NewEventCollaborator[]>([]);

    const handleSaveCollaborators = async () => {
        if (!playbookId) return;
        try {
            await playbooksActions.addPlaybookCollaborators(newCollaborators, playbookId);
            enqueueSnackbar(
                `Collaborators ${toggles.gate.inviteViaCollaborator ? 'invited' : 'added'} to this playbook.`,
                {
                    variant: 'info',
                }
            );
            setFieldKey(curKey => curKey + 1);
        } catch (e) {
            const { failedEmails = [] } = isAddCollaboratorsPartialError(e) ? e.raw : {};
            const message = [
                `Something went wrong when ${
                    toggles.gate.inviteViaCollaborator ? 'inviting' : 'adding'
                } collaborators.`,
                ...(failedEmails.length > 0
                    ? [`Some emails could not be ${toggles.gate.inviteViaCollaborator ? 'invited' : 'added'}.`]
                    : []),
                `Please try again.`,
            ].join(' ');

            enqueueSnackbar(message, { variant: 'error' });
        }
    };

    const handleRemoveCollaborator = (collaboratorId: number) => {
        if (!playbookId) return;
        playbooksActions.removePlaybookCollaborator(playbookId, collaboratorId);
    };

    if (!playbookId || !playbookCollaborators)
        return (
            <>
                <LoadPlaybookCollaborators id={playbookId} />
                <Spinner />
            </>
        );

    return readonly && playbookCollaborators.length === 0 ? null : (
        <Column itemSpacing="small">
            <LoadPlaybookCollaborators id={playbookId} />
            {!readonly && (
                <Row alignItems="center" itemSpacing="small">
                    <SearchCollaboratorsInput
                        playbookId={playbookId}
                        existingCollaboratorEmails={[
                            ...playbookCollaborators.map(collaborator => collaborator.email),
                            user.email,
                        ]}
                        onChange={setNewCollaborators}
                        allowNewEmails={toggles.gate.inviteViaCollaborator}
                        key={fieldKey}
                    />
                    <Button onClick={handleSaveCollaborators} disabled={newCollaborators.length === 0}>
                        Add
                    </Button>
                </Row>
            )}
            {readonly && playbookCollaborators.length === 0 && <DisplayField label="No Collaborators Added" />}
            {playbookCollaborators.map(collaborator => (
                <ListItem itemSpacing="default" justifyContent="space-around" key={collaborator.id}>
                    <Row alignItems="center">
                        <Avatar
                            css={css`
                                margin-right: -12px;
                                border: 2px solid ${colorFns.pureWhite};
                            `}
                            user={{ ...collaborator, imageUrl: collaborator.imageUrl ?? '' }}
                        />
                        <Spacer />
                        <Column>
                            <Copy>{[collaborator.firstName, collaborator.lastName].join(' ')}</Copy>
                            <Copy small>{collaborator.email}</Copy>
                        </Column>
                    </Row>
                    <Spacer />
                    {!readonly && <DeleteIcon onClick={() => handleRemoveCollaborator(collaborator.id)} />}
                </ListItem>
            ))}
        </Column>
    );
}
