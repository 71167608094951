import React from 'react';
import { useAsync } from '../hooks/useAsync';

import { Link } from 'react-router-dom';

import { format, parseISO } from 'date-fns';
import { mergeDateTime } from '../utils/date_util';

import styled, { css } from 'styled-components/macro';

import { useEvent } from '../providers/event';

import { ReactComponent as CalendarIconSVG } from '../images/icons/calendar.svg';
import { ReactComponent as SpaceMisc } from '../images/icons/space-misc.svg';

import { withIconStyles } from 'shared';

import { Button, AmenitiesIconHolder, IconImg, Column, Spacer } from '../ui';

import { IconHeadline } from './ui/Headline';

import { loadEventOptions, loadEventDeserialized } from '../api';

import getPlannerItemsFromDay from '../getPlannerItemsFromDay';
import { Popper, Paper, withStyles } from '@material-ui/core';

const CalendarIcon = withIconStyles(CalendarIconSVG);

const StyledColumn = styled(Column)`
    position: relative;
    flex-shrink: 0;
    width: 100%;
    justify-content: space-between;
`;

const DayDetails = styled.div`
    display: flex;
    align-items: center;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.agendaDayPane)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.agendaDayPaneText)};
    height: 40px;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    padding: 0 12px;

    & > * {
        margin-right: 12px;
    }
`;

const DayAmenities = styled.div`
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    flex-wrap: wrap;

    padding: 24px;
`;

const DateDisplay = styled.div`
    font-size: 18px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.highlightedText)};
`;

const paperStyles = {
    root: {
        padding: '20px',
    },
};

const StyledPaper = withStyles(paperStyles)(Paper);

type DayCardIconProps = {
    imgSrc: string | undefined;
    alt: string;
    popperContent: () => React.ReactNode | null;
};

const DayCardIcon = ({ imgSrc, alt, popperContent = () => null }: DayCardIconProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    function handleMouseEnter(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }

    function handleMouseLeave() {
        setAnchorEl(null);
    }
    return (
        <AmenitiesIconHolder onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {popperContent && (
                <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="top">
                    <StyledPaper>{popperContent()}</StyledPaper>
                </Popper>
            )}
            {imgSrc ? (
                <IconImg src={imgSrc} alt={alt} />
            ) : (
                <SpaceMisc
                    css={css`
                        min-width: 32px;
                    `}
                />
            )}
        </AmenitiesIconHolder>
    );
};

type DayCardProps = {
    day: Bizly.Day;
    dayDeserialized: Bizly.Day;
    dayNum: number;
    eventOptions?: any;
};

export const DayCard = ({ day, dayDeserialized, dayNum, eventOptions }: DayCardProps) => {
    const { eventSpaces, avNeeds = [], fbNeeds = [], guestRoomCount } = getPlannerItemsFromDay(
        dayDeserialized && dayDeserialized.agenda
            ? dayDeserialized
            : {
                  agenda: [],
                  start: '',
                  end: '',
                  guestrooms: [],
                  addToInquiry: true,
                  startDate: '',
                  startTime: '',
                  endTime: '',
              }
    );

    const eventSpacesOrDefault = eventSpaces.length || guestRoomCount ? eventSpaces : [];

    const renderDayAmenitiesContents = () =>
        eventOptions && (
            <>
                {eventSpacesOrDefault.map((eventSpace, index) => {
                    const { name, setup, start, end, attendees } = eventSpace;
                    return (
                        <DayCardIcon
                            key={name}
                            alt={'meeting-option-' + setup + '-' + name + '-icon'}
                            imgSrc={
                                eventOptions.setupOptions.find(
                                    (setupOption: { name: string }) => setupOption.name === setup
                                )?.iconUrl
                            }
                            popperContent={() => (
                                <>
                                    <div>{name && name.length ? name : `Meeting Space ${index + 1}`}</div>
                                    <div>
                                        {start && format(start, 'h:mm aaaa') + ' - '}
                                        {end && format(end, 'h:mm aaaa')}
                                    </div>
                                    <div>{setup}</div>
                                    {!!attendees && <div>{`${attendees} attendee${attendees > 1 ? 's' : ''}`}</div>}
                                </>
                            )}
                        />
                    );
                })}
                {day.guestrooms.length ? (
                    <DayCardIcon
                        imgSrc="https://res.cloudinary.com/hdd626jg7/image/upload/v1562953405/stencil/icons/guest-room.svg"
                        alt="meetingoption-guestroom-icon"
                        popperContent={() => <div>{guestRoomCount} Guestrooms</div>}
                    />
                ) : null}
                {avNeeds.map(avNeed => (
                    <DayCardIcon
                        key={avNeed.name}
                        alt={'meeting-option-' + avNeed.name + '-icon'}
                        imgSrc={
                            eventOptions.avOptions.find((avOption: { name: string }) => avOption.name === avNeed.name)
                                .iconUrl
                        }
                        popperContent={() => (
                            <>
                                <div>{avNeed.name}</div>
                                {avNeed.eventSpaces.map(eventSpace => (
                                    <div key={eventSpace}>{eventSpace}</div>
                                ))}
                            </>
                        )}
                    />
                ))}
                {fbNeeds.map(fbNeed => (
                    <DayCardIcon
                        key={fbNeed.name}
                        alt={'meeting-option-' + fbNeed.name + '-icon'}
                        imgSrc={
                            eventOptions.fbOptions.find((fbOption: { name: string }) => fbOption.name === fbNeed.name)
                                .iconUrl
                        }
                        popperContent={() => (
                            <>
                                <div>{fbNeed.name}</div>
                                {fbNeed.eventSpaces.map(eventSpace => (
                                    <div key={eventSpace}>{eventSpace}</div>
                                ))}
                            </>
                        )}
                    />
                ))}
            </>
        );
    return (
        <>
            <DayDetails>
                <div>Day {dayNum}</div>
                {day.agenda && day.startDate && (
                    <div>{format(parseISO(mergeDateTime(day.startDate, '00:00')), 'EEE, LLL dd')}</div>
                )}
            </DayDetails>
            <DayAmenities>{renderDayAmenitiesContents()}</DayAmenities>
        </>
    );
};

const PlannerSection = () => {
    const { event } = useEvent();

    const { data: eventOptions } = useAsync(React.useCallback(() => loadEventOptions(event.id), [event.id]));

    const { data: eventDeserialized } = useAsync(React.useCallback(() => loadEventDeserialized(event.id), [event.id]));

    const dateSet = event && event.startsAt;
    const isMultiDay = event && event.schedule.length > 1;

    const dateDisplayText = event.startsAt
        ? isMultiDay && event.endsAt
            ? `${format(parseISO(event.startsAt), 'EEE, LLL dd, yyyy')} - ${format(
                  parseISO(event.endsAt),
                  'EEE, LLL dd, yyyy'
              )}`
            : format(parseISO(event.startsAt), 'EEEE, MMMM dd, y	')
        : '';

    return (
        <>
            <StyledColumn>
                <IconHeadline
                    icon={<CalendarIcon />}
                    headline="Planner"
                    description={
                        event.startsAt && event.endsAt
                            ? 'Your agenda’s set! Here’s a high-level view:'
                            : 'Plan the details to make your meeting a huge success.'
                    }
                />
                <Link to={`/event/${event.id}/planner`}>
                    <Button
                        style={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            margin: '0.5em 0',
                        }}
                        variant="outlined"
                    >
                        {event.editable ? 'Edit' : 'View'}
                    </Button>
                </Link>
            </StyledColumn>
            <Spacer style={{ minHeight: '24px' }} />
            {dateSet && (
                <>
                    <DateDisplay>{dateDisplayText}</DateDisplay>
                    <Spacer style={{ minHeight: '24px' }} />
                </>
            )}
            {event?.schedule.map((day, i) => (
                <div
                    key={day?.start?.toString() || Math.random()}
                    css={css`
                        width: 100%;
                    `}
                >
                    <DayCard
                        day={day}
                        dayNum={i + 1}
                        eventOptions={eventOptions}
                        dayDeserialized={eventDeserialized ? eventDeserialized.schedule[i] : {}}
                    />
                    <Spacer />
                </div>
            ))}
        </>
    );
};

export default PlannerSection;
