import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { withSnackbar } from 'notistack';
import { useParams } from 'react-router';
import { downloadFromUrl } from 'utils';
import usePersistentSnackbar from 'hooks/usePersistentSnackbar';

import { exportAttendees, removeAttendee, updateAttendee } from 'api';
import { createMeetingActions, useCreateMeeting } from './store';
import { EventContext } from 'providers/event';

import { AlignedColumn, Row, Spacer, StatusChip } from 'ui';
import { LargeHeadline } from 'components/ui/Headline';
import { Dialog, Tooltip } from '@material-ui/core';
import Button from 'components/ui/Button';
import Table from 'components/ui/Table';
import { DeleteConfirmationModal } from 'components/DeleteConfirmationModal';
import { AddAttendees } from './components/AddAttendees';
import { AddAttendeesImport } from 'pages/AddAttendeesImport';
import AttendeeModal from 'components/Attendees/AttendeeModal';
import Errors from './components/Errors';

import { ReactComponent as EditIconSVG } from 'images/icons/pencil.svg';
import { ReactComponent as DeleteIconSVG } from 'images/icons/trash-can.svg';
import { statusColors, statusMap, withInteractibleIconStyles } from 'shared';

const EditIcon = styled(withInteractibleIconStyles(EditIconSVG))`
    width: 29px;
    height: 29px;
`;
const DeleteIcon = withInteractibleIconStyles(DeleteIconSVG);

const AutoRow = styled(Row)`
    width: auto;
`;

const NoEditIcon = styled.div`
    width: 29px;
    height: 29px;
`;

class AttendeesClass extends Component {
    static contextType = EventContext;

    state = {
        deleteIntent: {},
        editIntent: null,
        pending: false,
    };

    handleEditClick(id) {
        const { attendees } = this.props;
        this.setState({
            editIntent: attendees.find(a => a.id === id),
        });
    }

    handleRemoveClick(email) {
        this.props.delAttendee(email);
    }

    async handleExportClick() {
        const { id } = this.props;
        downloadFromUrl(await exportAttendees(id));
    }

    async handleDeleteConfirm() {
        const { enqueueSnackbar } = this.props;
        const { event } = this.context;
        const { deleteIntent } = this.state;
        this.setState({
            pending: true,
        });
        try {
            await removeAttendee(event.id, deleteIntent);
            enqueueSnackbar(`${deleteIntent.email} was removed`, {
                variant: 'info',
            });
        } catch (e) {
            console.error(e);
            enqueueSnackbar(`Could not remove attendee ${deleteIntent.id} from meeting ${event.id}`, {
                variant: 'error',
            });
        }
        this.setState({
            deleteIntent: {},
            pending: false,
        });
        this.refreshAttendees(event.id);
    }

    handleModalDismiss() {
        this.setState({
            editIntent: null,
            deleteIntent: {},
        });
    }

    async handleAttendeeSave(draft) {
        const { enqueueSnackbar, id } = this.props;
        try {
            await updateAttendee(id, draft);
            enqueueSnackbar(`${draft.email} was updated`, { variant: 'info' });
            this.setState({
                editIntent: null,
            });
        } catch (e) {
            enqueueSnackbar(`Could not save attendee data for ${draft.email}`, {
                variant: 'error',
            });
        }
    }

    handleImportClick() {
        this.setState({ importing: true });
    }

    handleImportClose() {
        this.setState({ importing: false });
    }

    render() {
        const { editIntent, deleteIntent } = this.state;
        const { attendees, exportable, id } = this.props;

        return (
            <AlignedColumn style={{ width: '100%', alignItems: 'flex-start' }}>
                <DeleteConfirmationModal
                    isActive={deleteIntent.status}
                    onDismiss={() => this.handleModalDismiss()}
                    onProceed={() => this.handleDeleteConfirm()}
                    prompt={
                        deleteIntent.status === 'attending'
                            ? 'We’ll remove this person from your meeting, along with any details you or they have entered. They’ve RSVPed as ‘Attending’, so we’ll notify them.'
                            : 'We’ll remove this person from your meeting, along with any details you or they have entered. They haven’t RSVPed as ‘Attending’, so we won’t notify them.'
                    }
                />
                {editIntent && (
                    <AttendeeModal
                        editable
                        attendee={editIntent}
                        eventId={id}
                        onDismiss={() => this.handleModalDismiss()}
                        onSave={draft => this.handleAttendeeSave(draft)}
                    />
                )}
                {this.state.importing && (
                    <Dialog open fullScreen>
                        <AddAttendeesImport
                            onAdd={newAdditions => {
                                createMeetingActions.addAttendees(newAdditions);
                                this.handleImportClose();
                            }}
                            onClose={() => this.handleImportClose()}
                            isNested
                        />
                    </Dialog>
                )}
                <LargeHeadline>Add Attendees</LargeHeadline>

                <Row alignItems="center" justifyContent="space-between">
                    <AddAttendees
                        attendees={attendees}
                        addAttendee={createMeetingActions.addAttendee}
                        delAttendee={createMeetingActions.delAttendee}
                    />
                    <Row style={{ justifyContent: 'flex-end' }} itemSpacing="smallish">
                        <Tooltip title={exportable ? 'abc' : 'Draft must be saved first'}>
                            <span>
                                <Button
                                    onClick={() => this.handleExportClick()}
                                    secondary
                                    width={120}
                                    disabled={!exportable}
                                >
                                    Export CSV
                                </Button>
                            </span>
                        </Tooltip>
                        <Button onClick={() => this.handleImportClick()} width={120}>
                            Import CSV
                        </Button>
                    </Row>
                </Row>
                <Spacer />

                <Spacer />
                <Table
                    columnTitles={[
                        { rowKey: 'status', label: 'Status' },
                        { rowKey: 'name', label: 'Name' },
                        { rowKey: 'email', label: 'Email' },
                        { rowKey: 'phone', label: 'Phone' },
                        { rowKey: 'buttons', label: '' },
                    ]}
                    rowValues={attendees}
                    dynamicColumns={{
                        name: (_, attendee) => [attendee.firstName, attendee.lastName].join(' ').trim() || undefined,
                        status: value => (
                            <StatusChip
                                style={{ width: '118px' }}
                                label={statusMap[value ?? 'not sent']}
                                statusColor={statusColors[value ?? 'not sent']}
                            />
                        ),
                        buttons: (_, attendee) => (
                            <AutoRow itemSpacing="smallish">
                                {attendee.id ? (
                                    <EditIcon onClick={() => this.handleEditClick(attendee.id)} />
                                ) : (
                                    <NoEditIcon />
                                )}
                                <DeleteIcon onClick={() => this.handleRemoveClick(attendee.email)} />
                            </AutoRow>
                        ),
                    }}
                    placeholder="-"
                />
            </AlignedColumn>
        );
    }
}

export const Attendees = withSnackbar(AttendeesClass);

export default function AttendeesForm() {
    const { attendees, attendeesChanged, attendeeErrors, stepIdx } = useCreateMeeting();
    const { id } = useParams();

    const enqueueSnackbar = usePersistentSnackbar([attendeeErrors, stepIdx]);
    React.useEffect(() => {
        const hasErrors = Object.values(attendeeErrors).some(v => v);
        if (hasErrors) {
            enqueueSnackbar(<Errors errors={attendeeErrors} />, { variant: 'error' });
        }
    }, [attendeeErrors, enqueueSnackbar]);

    return (
        <Attendees
            id={id}
            attendees={attendees}
            delAttendee={createMeetingActions.delAttendee}
            exportable={!attendeesChanged}
        />
    );
}
