import { parse } from 'query-string';
import mapValues from 'lodash/mapValues';

import { useHistory } from 'react-router';

import { takeFirstIfArray } from '../../util';
import { BLANK_TEMPLATE_ID } from 'api/eventTemplates';
import { useUser } from 'providers/user';

const URLFieldNames: Partial<{ [fieldName: string]: string }> = {
    name: 'event_name',
    costCenter: 'cost_center',
    cventId: 'internal_ref',
    type: 'event_type',
    templateId: 'template_id',
};

export const useCreateEventModalUrlValues = () => {
    const { location } = useHistory();
    const { user } = useUser();

    const parsedEvent = takeFirstIfArray(parse(location.search));

    const { event_name, cost_center, internal_ref, event_type, auth } = mapValues(parsedEvent, value =>
        value === null ? undefined : value
    );

    let formValues: Partial<{ [fieldName: string]: string | number }> = {
        name: event_name,
        costCenter: cost_center,
        cventId: internal_ref,
        type: event_type,
        templateId: BLANK_TEMPLATE_ID,
    };

    const checkFields = user.team?.authMeetingCreateFields;
    if (checkFields) {
        const urlCheckVals = auth && JSON.parse(atob(auth));
        if (typeof urlCheckVals !== 'object' || urlCheckVals === null) return undefined;
        if (
            checkFields.some(checkFieldName => {
                const URLFieldName = URLFieldNames[checkFieldName];
                if (!URLFieldName) return false;

                if (!urlCheckVals[URLFieldName]) return true;

                return urlCheckVals[URLFieldName] !== formValues[checkFieldName];
            })
        )
            formValues = {};

        if (checkFields.includes('email') && urlCheckVals['email'] !== user.email) formValues = {};
    }

    const { create } = parse(location.search, {
        parseBooleans: true,
    });
    const showModalOnPageLoad = create === true;

    return showModalOnPageLoad ? formValues : undefined;
};
