import React from 'react';

import styled from 'styled-components';
import { format, isEqual } from 'date-fns';
import { useMediaQuery } from '@material-ui/core';

import { getDateRange } from 'utils/date_util';

import { Row, Column, Copy, Spacer, LabeledCheckbox, Card, AlignedRow, ExternalLink } from '../../ui';
import { withIconStyles } from '../../shared';
import { IconHeadline, Headline } from '../ui/Headline';
import Button from '../ui/Button';

import { ReactComponent as PersonIconSVG } from '../../images/icons/person.svg';
import { ReactComponent as CalendarIconSVG } from '../../images/icons/calendar.svg';
import ThemedLogo from 'components/ui/ThemedLogo';
import Form from 'components/Form';
import Pill from 'components/ui/Pill';

import { TProposalForm, TProposal } from './types';
import { EColors, colorStyledFn } from 'theme';
import colorFns from 'colorFns';
import useThemedColor from 'hooks/useThemedColor';
import fontFns from 'fontFns';

const DATE_FORMAT = 'MMM dd, yyyy';

const headingHeight = 288;
const tallerHeading = 328;

const HeadingRow = styled(Row)<{ taller?: boolean }>`
    min-height: ${({ taller }) => (taller ? tallerHeading : headingHeight)}px;
    height: ${({ taller }) => (taller ? tallerHeading : headingHeight)}px;
    max-height: ${({ taller }) => (taller ? tallerHeading : headingHeight)}px;

    margin-bottom: 70px;
`;

const CompatibleCol = styled(Column)`
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0px;
    width: inherit;
`;

const CardsHeading = styled(HeadingRow)`
    padding-top: 40px;
    box-sizing: border-box;
`;

const StaticCard = styled(Card)`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    min-width: 240px;
    max-width: 240px;
    height: 100%;
    box-sizing: border-box;
    margin-right: 0;
    margin-bottom: 0;

    justify-content: flex-start;
    align-items: stretch;
`;

const FlexCard = styled(Card)`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    height: 100%;
    box-sizing: border-box;
    margin-right: 0;
    margin-left: 36px;
    margin-bottom: 0;

    justify-content: flex-start;
    align-items: stretch;
`;

const Color = styled.span`
    ${colorStyledFn}
`;

const ClientLogo = styled.img`
    height: 48px;
    border-radius: 24px;
`;

const SidebarHeadline = styled(Headline)`
    white-space: initial;
    line-height: 1;
`;

export const IntroHeader = ({ client = {}, venue = {} }: Partial<TProposalForm>) => {
    const { firstName, lastName, companyName } = client;
    const { name, headline } = venue;

    return (
        <HeadingRow>
            <CompatibleCol>
                <ThemedLogo />
                <Spacer smallish />
                <SidebarHeadline>{name}</SidebarHeadline>
                <Copy $color={EColors.pureWhite} line-height={0.8}>
                    {headline}
                </Copy>
                <Spacer larger />
                <SidebarHeadline>
                    Create a proposal<Color $color={EColors.bizlyHeadlineDot}>.</Color>
                </SidebarHeadline>
                <Copy $color={EColors.pureWhite}>
                    Thank you for accepting an inquiry from {[firstName, lastName].join(' ')} at {companyName}. Please
                    complete this form to send a proposal for this upcoming meeting.
                </Copy>
            </CompatibleCol>
        </HeadingRow>
    );
};

const PersonIcon = withIconStyles(PersonIconSVG);
const CalendarIcon = withIconStyles(CalendarIconSVG);

const DatesCol = styled(Column)`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    /* for non IE11 */
    min-width: 240px;
`;

const NotesCol = styled(DatesCol)`
    flex-grow: 2;
    /* for IE11 */
    flex-basis: 220px;
`;

const StretchedRow = styled(Row)`
    align-items: stretch;
    width: unset;
    overflow: hidden;
    flex: 1;
`;

const MsaAnchor = styled(ExternalLink)`
    margin-top: auto;
`;

const InquiryNotes = styled(Copy)`
    overflow: auto;
    flex: 1;
`;

const DatesFlexibleCheckbox = styled(LabeledCheckbox)`
    max-height: 19px;
    pointer-events: none;
    > .MuiIconButton-root {
        padding-top: 0;
        padding-bottom: 0;
    }
`;

const SingleLineRow = styled(Row)`
    white-space: nowrap;
`;

const Hidden = styled.span`
    visibility: hidden;
`;

const datesFlexibleForm = {
    fields: {
        datesFlexibleNotes: {
            type: 'textarea',
            prompt: 'Alternative Dates',
            options: {
                rows: 2,
                rowsMax: 2,
                maxLength: 275,
                placeholder: 'Indicate which dates are available here',
            },
        },
    },
    schema: [{ key: 'datesFlexibleNotes', fields: ['datesFlexibleNotes'], spacing: false }],
};

const FormLabel = styled(Copy)`
    color: ${colorFns.formLabel};
    ${fontFns.formLabel}
`;

type TDatesFlexibleValue = Pick<TProposalForm['proposal'], 'datesFlexibleNotes'>;

const FlexibleNotesField = ({
    value = {},
    onChange,
    disabled,
}: {
    value?: TDatesFlexibleValue;
    onChange: (update: { value: TDatesFlexibleValue }) => void;
    disabled?: boolean;
}) => <Form {...datesFlexibleForm} value={value} onChange={onChange} disabled={disabled} />;

export const ProposalDetailsHeader = ({
    client = {},
    inquiry = {},
    event = {},
    proposal = {},
    eventSpaces = [],
    guestRooms = [],
    onChange,
    data,
    disabled,
}: Partial<TProposalForm> & {
    data?: Partial<TProposal>;
    onChange: (newProposalData: Partial<TProposal>) => void;
    disabled?: boolean;
}) => {
    const { firstName, lastName, companyName, logoUrl } = client;
    const { notes, datesFlexible } = inquiry;
    const { name, cancelledAt } = event;
    const { datesFlexibleNotes, msaDocumentUrl } = proposal;

    const { warningAction } = useThemedColor();

    const [startDate, endDate] = getDateRange(
        [...eventSpaces.map(eventSpace => eventSpace.requestedDate), ...guestRooms.map(gr => gr?.date?.substr(0, 10))] // TODO: Let's revist this
    );
    const dateDisplay =
        startDate &&
        endDate &&
        (isEqual(startDate, endDate)
            ? format(startDate, DATE_FORMAT)
            : `${format(startDate, DATE_FORMAT)} - ${format(endDate, DATE_FORMAT)}`);

    const isBelow1281 = useMediaQuery('(max-width:1280px)');

    return (
        <CardsHeading taller={isBelow1281}>
            <StaticCard>
                <IconHeadline icon={<PersonIcon />} headline="Client Details" />
                <Spacer small />
                <AlignedRow itemSpacing="small">
                    {logoUrl && <ClientLogo src={logoUrl} alt={companyName + '-logo'} />}
                    <Column itemSpacing="xsmall">
                        <Copy>{[firstName, lastName].join(' ')}</Copy>
                        <Copy>{companyName}</Copy>
                    </Column>
                </AlignedRow>
                {msaDocumentUrl && (
                    <MsaAnchor href={msaDocumentUrl} openInNewTab>
                        <Button width="100%">Client Agreement</Button>
                    </MsaAnchor>
                )}
            </StaticCard>

            <FlexCard>
                <IconHeadline icon={<CalendarIcon />} headline="Meeting Details" />

                <Spacer small />

                <StretchedRow itemSpacing="large">
                    <NotesCol itemSpacing="small">
                        <Column alignItems="flex-start">
                            {cancelledAt && <Pill color={warningAction} label="Cancelled" noShadow centerText />}
                            <FormLabel>{name}</FormLabel>
                        </Column>
                        <InquiryNotes regular>{notes}</InquiryNotes>
                    </NotesCol>

                    <DatesCol itemSpacing="small">
                        <Column>
                            {cancelledAt && (
                                <Hidden>
                                    <Pill color={warningAction} label="Cancelled" noShadow centerText />
                                </Hidden>
                            )}
                            <SingleLineRow itemSpacing="small">
                                <FormLabel>{dateDisplay}</FormLabel>
                                {!isBelow1281 && datesFlexible && (
                                    <DatesFlexibleCheckbox
                                        label="Dates are flexible"
                                        onChange={() => {}}
                                        isChecked={!!datesFlexible}
                                        disabled={false}
                                    />
                                )}
                            </SingleLineRow>
                        </Column>

                        {isBelow1281 && datesFlexible && (
                            <DatesFlexibleCheckbox
                                label="Dates are flexible"
                                onChange={() => {}}
                                isChecked={!!datesFlexible}
                                disabled={false}
                            />
                        )}
                        {datesFlexible && (
                            <FlexibleNotesField
                                value={data || { datesFlexibleNotes }}
                                onChange={({ value }: { value: TDatesFlexibleValue }) => onChange(value)}
                                disabled={disabled}
                            />
                        )}
                    </DatesCol>
                </StretchedRow>
            </FlexCard>
        </CardsHeading>
    );
};
