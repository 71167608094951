import React, { useMemo } from 'react';
import styled from 'styled-components';

import { padArray } from '../util';
import { Column, Row } from 'ui';
import Button from 'components/ui/Button';
import SmallCircleButton from 'components/ui/SmallCircleButton';
import VenueTile, { VenueTileEmpty } from 'components/VenueTile';
import { useHistory } from 'react-router';
import { useCurrentInquiry, currentInquiryActions } from 'stores/current-inquiry';
import { SpinnerOverlay as UISpinnerOverlay } from 'components/Spinner';
import { useEvent } from 'providers/event';
import { REJECTED_STATUSES } from './VenueCard';
import { ReactComponent as PlusIcon } from 'images/icons/plus.svg';

const Container = styled(Column)`
    position: relative;
    width: fit-content;

    ${SmallCircleButton} {
        align-self: center;
        margin-left: 12px;
    }
`;

const InquiryButton = styled(Button)`
    width: fit-content;
`;

const SpinnerOverlay = styled(UISpinnerOverlay)`
    z-index: 1300;
`;

const TILE_WIDTH = '197px',
    TILE_MIN_WIDTH = 'auto',
    TILE_RATIO = 'calc(160 / 208 * 100%)';

const MAX_VENUE_SLOTS = 4;

const InquiryCart = ({
    hideBuildInquiry,
    isCreateMeeting,
    hideReturnToSearch,
}: {
    hideBuildInquiry?: boolean;
    isCreateMeeting?: boolean;
    hideReturnToSearch?: boolean;
}) => {
    const { loading, inquiry, venues } = useCurrentInquiry();
    const history = useHistory();
    const { event } = useEvent();

    const validVenues = useMemo(() => (venues || [])?.filter(venue => !REJECTED_STATUSES.has(venue.status)), [venues]);

    const viewVenueListing = (venueId: number) =>
        history.push({
            ...history.location,
            pathname: isCreateMeeting
                ? `/events/${event.id}/edit/venue/listing/${venueId}`
                : `/event/${event.id}/venue/listing/${venueId}`,
            state: {
                ...history.location.state,
                shouldGoBack: true,
                backgroundLocation: history.location,
            },
        });

    const goToBuildInquiry = () =>
        history.push({
            ...history.location,
            pathname: `/event/${event.id}/venue/inquiries/${inquiry?.id || 'new'}`,
        });

    const goToVenueSearch = () => {
        history.push({
            ...history.location,
            pathname: `/event/${event.id}/venue`,
        });
    };

    const handleRemoveVenue = (venueId: number) => {
        inquiry?.id && currentInquiryActions.removeVenue(inquiry.id, venueId);
    };

    return (
        <Container itemSpacing="medium">
            {loading && <SpinnerOverlay />}
            <Row itemSpacing="smallish" style={{ width: 'fit-content' }}>
                {padArray(validVenues, MAX_VENUE_SLOTS).map((venue, idx) =>
                    venue ? (
                        <VenueTile
                            key={venue.id}
                            venue={{ ...venue.venue, status: venue.status }}
                            format="tile"
                            pillType={inquiry?.submittedAt ? 'inquiryStatus' : 'preferenceCategory'}
                            width={TILE_WIDTH}
                            minWidth={TILE_MIN_WIDTH}
                            fixedRatio={TILE_RATIO}
                            onClick={viewVenueListing}
                            onClose={
                                !inquiry?.submittedAt && event.editable ? () => handleRemoveVenue(venue.id) : undefined
                            }
                        />
                    ) : (
                        <VenueTileEmpty
                            key={'empty-tile-' + idx}
                            width={TILE_WIDTH}
                            minWidth={TILE_MIN_WIDTH}
                            fixedRatio={TILE_RATIO}
                            withPin
                        />
                    )
                )}
                {event.editable && !hideReturnToSearch && (
                    <SmallCircleButton onClick={goToVenueSearch} secondary={false}>
                        <PlusIcon />
                    </SmallCircleButton>
                )}
            </Row>
            {!hideBuildInquiry && (
                <InquiryButton
                    disabled={loading || (!inquiry?.submittedAt && !event.editable)}
                    onClick={goToBuildInquiry}
                >
                    {`${inquiry?.submittedAt || !event.editable || event.cancelledAt ? 'View' : 'Build'} Inquiry`}
                </InquiryButton>
            )}
        </Container>
    );
};

export default InquiryCart;
