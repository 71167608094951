import React from 'react';
import styled from 'styled-components/macro';
import { useSnackbar } from 'notistack';

import { getTasklist, updateTask } from 'api/taskLists';

import { Card, Column, Copy, LabeledCheckbox as _LabeledCheckbox, SpacedColumn, Spacer, Row } from 'ui';
import { IconHeadline } from 'components/ui/Headline';

import { ReactComponent as CheckIconSVG } from 'images/icons/check.svg';
import { withIconStyles } from 'shared';
import { EColors } from 'theme';

import { SpinnerOverlay } from 'components/Spinner';
import { SegmentedBar } from 'components/SegmentedBar';
import { Tabs, Tab } from 'components/Tabs';

import { dashboardCopy } from 'copy/event.json';
import { useUser } from 'providers/user';
import { useSchedule } from 'stores/schedule';

const calculateProgress = (items = [] as BizlyAPI.Task[]) => {
    const total = items.length;
    const tasksComplete = items.reduce((a, b) => (b.completed ? a + 1 : a), 0);
    const percent = total && Math.round((tasksComplete / total) * 100);
    return { total, tasksComplete, percent };
};

const CheckIcon = withIconStyles(CheckIconSVG);

const LabeledCheckbox = styled(_LabeledCheckbox)`
    padding: 9px 0;
    align-items: flex-start;
    flex-shrink: 0;

    .MuiCheckbox-root,
    .MuiCheckbox-root:hover {
        background-color: transparent;
        padding: 0 9px;
    }
`;

const SINGLE_GENERAL_LIST_NAME = 'General';

const isSingleList = (taskLists: BizlyAPI.TaskList[]) =>
    taskLists.length === 1 && taskLists[0].name === SINGLE_GENERAL_LIST_NAME;

type ChecklistProps = {
    eventId: number;
    showBrief?: boolean;
    readOnly?: boolean;
    noCard?: boolean;
    flexHeight?: boolean;
};

const Checklist = ({ eventId, showBrief, readOnly, noCard, flexHeight }: ChecklistProps) => {
    const { checklistCopy: copy } = dashboardCopy; // TODO: Refactor once we settle on a wholeistic structure for sharing copy
    const [tabIndex, setCurrentTabIndex] = React.useState(0);
    const [taskLists, setTaskLists] = React.useState<BizlyAPI.TaskList[]>([]);
    const [loading, setLoading] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const singleList = isSingleList(taskLists);

    React.useEffect(() => {
        setLoading(true);
        getTasklist(eventId)
            .then(data => setTaskLists(data.tasklists))
            .catch(() => {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    }, [eventId, enqueueSnackbar, setLoading]);

    const { tasks = [], name: taskName = '', id: tasklistId } = taskLists?.[tabIndex] ?? {};

    const { total, tasksComplete, percent } = calculateProgress(tasks);
    const isComplete = tasksComplete === total;

    const selectSubheaderText = (options: Record<'finished' | 'progress' | 'started', string>) => {
        if (singleList) return 'Follow these steps to organize your complete group experience.';

        const key = isComplete ? 'finished' : tasksComplete > 1 ? 'progress' : 'started';
        return options[key];
    };

    const selectProgressText = ({ progress, completion }: { progress: unknown[]; completion: unknown }) => {
        const ratio = `${tasksComplete} of ${total} completed.`;
        const index = tasksComplete <= progress.length ? tasksComplete : progress.length - 1;
        const progressCopy = progress[index] || progress[0];
        return `${ratio} ${!isComplete ? progressCopy : completion}`;
    };

    const handleChange = (_: never, newTabIndex: number) => {
        setCurrentTabIndex(newTabIndex);
    };

    const [saving, setSaving] = React.useState(false);
    const handleCheckbox = async (taskId: number, completed: boolean, index: number) => {
        if (saving) return;

        try {
            setSaving(true);
            const currentTabIdx = tabIndex; // snapshot before async code
            const { task: newTask } = await updateTask({
                eventId,
                tasklistId,
                taskId,
                task: { completed: !completed },
            });

            setTaskLists(prevTaskLists =>
                prevTaskLists.map((taskList, taskListIdx) =>
                    taskListIdx === currentTabIdx
                        ? {
                              ...taskList,
                              tasks: taskList.tasks.map(task => (task.id === newTask.id ? newTask : task)),
                          }
                        : taskList
                )
            );
        } catch {
            enqueueSnackbar('Something went wrong. PLease try again.', { variant: 'error' });
        } finally {
            setSaving(false);
        }
    };

    const { user } = useUser();
    const schedule = useSchedule();

    const agendasEnabled =
        (user.team && user.team.useAgendas) ||
        (schedule.loaded && (schedule.items.length > 0 || schedule.pendingItems.length > 0));
    const agendasUnknown = !user.team || !schedule.loaded;

    const filteredTasks = agendasUnknown
        ? []
        : agendasEnabled
        ? tasks
        : tasks.filter(task => !task.description.includes('agenda'));

    const renderContent = () => (
        <SpacedColumn
            style={{
                width: '100%',
                height: '100%',
                maxHeight: '100%',
            }}
        >
            <Column
                style={{
                    position: 'relative',
                    minHeight: 0,
                }}
                itemSpacing={singleList ? 'default' : 'small'}
            >
                <IconHeadline
                    icon={<CheckIcon />}
                    headline={copy.heading}
                    description={loading ? '' : selectSubheaderText(copy.subheading)}
                />

                {!loading && !singleList && (
                    <div>
                        <Copy xsmall>{taskName.toUpperCase()}</Copy>
                        <Copy $color={EColors.highlightedText}>{selectProgressText(copy)}</Copy>
                        <Spacer xsmall />
                        <SegmentedBar size={percent} />
                    </div>
                )}

                <Column scrollable style={singleList ? { minHeight: 100 } : {}}>
                    {filteredTasks.map((task, index) => (
                        <LabeledCheckbox
                            disabled={!!readOnly}
                            key={task.id}
                            label={task.name}
                            isChecked={task.completed}
                            onChange={() => handleCheckbox(task.id, task.completed, index)}
                        />
                    ))}
                </Column>
                {loading && <SpinnerOverlay transparent />}
            </Column>

            {!loading && !singleList && (
                <Tabs value={tabIndex} onChange={handleChange as any}>
                    <Tab label="Pre meeting" />
                    <Tab label="Day of" />
                    <Tab label="Post meeting" />
                </Tabs>
            )}
        </SpacedColumn>
    );

    return noCard ? (
        <Row
            style={flexHeight ? { height: '100%', maxHeight: '100%' } : { height: showBrief ? '423px' : '647px' }}
            alignItems="stretch"
        >
            {renderContent()}
        </Row>
    ) : (
        <Card
            style={flexHeight ? { maxHeight: '100%' } : { paddingBottom: '0', height: showBrief ? '423px' : '647px' }}
        >
            {renderContent()}
        </Card>
    );
};

export default Checklist;
