import React, { useMemo } from 'react';

import styled from 'styled-components/macro';
import keyBy from 'lodash/keyBy';

import { Route, Switch, RouteComponentProps, withRouter } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';

import {
    submitInquiry as submitInquiryAPI,
    removeVenueFromInquiry,
    getInquiryDraft,
    updateInquiryDraft,
    addVenueToInquiryDraft,
} from 'api';
import { useCurrentInquiry, currentInquiryActions, LoadCurrentInquiry } from 'stores/current-inquiry';
import { useUser } from 'providers/user';

import { Column, Row, Spacer } from '../ui';
import { SpinnerOverlay } from 'components/Spinner';
import ConfirmationModal from 'components/ConfirmationModal';

import VenueListing from 'pages/VenueListing';
import VenueSearch from 'pages/VenueSearch';
import VenueDiscoveryPage from 'pages/VenueDiscovery';

import InquiryCart from 'components/InquiryCart';
import InquiryDrawer from 'components/InquiryDrawer';
import { REJECTED_OR_ADDED, REJECTED_STATUSES } from 'components/VenueCard';

const PaddedContent = styled(Column)<{ sideMargin: number }>`
    height: 100%;
    padding: 68px ${({ sideMargin }) => sideMargin}px;
    box-sizing: border-box;
`;

const FullWidthColumn = styled(Column)`
    height: 100%;
    width: 100%;
`;

const InquiryCartWrapperRow = styled(Row)<{ sideMargin: number }>`
    padding: 68px ${({ sideMargin }) => sideMargin}px 0;
    width: auto;
`;

const MAX_INQUIRY_VENUE_SLOTS = 4;
const getActiveVenues = (venues: Bizly.Venue[]) => venues.filter(venue => !REJECTED_OR_ADDED.has(venue.status));
const getActiveVenuesCount = (venues: Bizly.Venue[]) => getActiveVenues(venues).length;

const getSelectedVenueInqs = (venues: Bizly.Venue[]) => venues.filter(venue => !REJECTED_STATUSES.has(venue.status));

const Venue = ({
    match,
    event,
    suggestedVenues,
    viewVenueListing,
    viewInquiries,
    sideMargin = 152,
    pageOffset = 0,
}: RouteComponentProps & {
    event: Bizly.Event;
    suggestedVenues: Bizly.Venue[];
    viewVenueListing: (venueId: number) => void;
    viewInquiries: () => void;
    venuesRefreshKey?: number;
    onUpdateVenues?: () => void;
    sideMargin?: number;
    pageOffset?: number;
}) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { toggles } = useUser();

    const [submitKey, setSubmitKey] = React.useState(0);
    const [venueBooked, setVenueBooked] = React.useState(false);

    const {
        inquiry: currentInquiry,
        venues: currentInquiryVenues,
        loading: loadingCurrent,
        loaded: loadedCurrent,
    } = useCurrentInquiry();
    const inquiryId = currentInquiry && currentInquiry.id;

    const [inquiryDraft, setInquiryDraft] = React.useState<any>(undefined);

    React.useEffect(() => {
        getInquiryDraft(event.id).then(draft => {
            setInquiryDraft(draft);
        });
    }, [event.id]);

    const updateDraft = async ({ datesFlexible, notes }: { datesFlexible: boolean; notes: string }) => {
        setInquiryDraft((prevDraft: any) => ({ ...prevDraft, flexibleDates: datesFlexible, notes }));
        const { inquiry } = await updateInquiryDraft(event.id, {
            inquiryId: inquiryId ?? undefined,
            datesFlexible,
            notes,
        });

        currentInquiryActions.setInquiry(event.id, inquiry);
    };

    const canSubmitInquiry = !venueBooked && event.editable && loadedCurrent;

    const isDraft = !loadingCurrent && currentInquiry?.draft;
    const isSubmitted = !loadingCurrent && currentInquiry?.submittedAt;

    React.useEffect(() => {
        if (currentInquiry && currentInquiryVenues) {
            const bookedVenue = !!currentInquiryVenues.find(venue => venue.booked);
            if (bookedVenue) setVenueBooked(bookedVenue);
        }
    }, [currentInquiry, currentInquiryVenues]);

    const availableSlots = React.useMemo(
        () =>
            !loadedCurrent || !currentInquiryVenues || venueBooked
                ? 0
                : MAX_INQUIRY_VENUE_SLOTS - getActiveVenuesCount(currentInquiryVenues),
        [loadedCurrent, venueBooked, currentInquiryVenues]
    );

    const addedVenuesCount = React.useMemo(
        () =>
            !currentInquiryVenues
                ? 0
                : currentInquiryVenues.filter(venueInquiry => venueInquiry.status === 'Added').length,
        [currentInquiryVenues]
    );

    const openSlots = React.useMemo(() => Math.max(availableSlots - addedVenuesCount, 0), [
        availableSlots,
        addedVenuesCount,
    ]);

    const selectedVenues = useMemo(() => {
        if (venueBooked) return {};
        return keyBy(
            getSelectedVenueInqs(currentInquiryVenues || []).map(venueInq => venueInq.venue),
            'id'
        );
    }, [venueBooked, currentInquiryVenues]);

    const [stagedVenue, setStagedVenue] = React.useState<Bizly.Venue | undefined>();
    const [updatingVenues, setUpdatingVenues] = React.useState(false);

    const addVenueConfirm = (venue: Bizly.Venue) => setStagedVenue(venue);

    async function addVenue(venue: Bizly.Venue) {
        if (openSlots > 0 && !selectedVenues[venue.id]) {
            setUpdatingVenues(true);

            try {
                const { inquiry } = await addVenueToInquiryDraft(event.id, {
                    inquiryId: inquiryId ?? undefined,
                    venueId: venue.id,
                });

                if (isSubmitted) {
                    // TODO: Revisit this restructuring of venue objects
                    currentInquiryActions.mergeVenues(event.id, [{ ...venue, venue: venue, status: 'Submitted' }]);
                    history.push(`/event/${event.id}/venue/inquiries`);
                } else {
                    currentInquiryActions.setInquiry(event.id, inquiry);
                    currentInquiryActions.mergeVenues(event.id, [{ ...venue, venue: venue, status: 'Added' }]);
                }
            } catch {
                enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
            } finally {
                setUpdatingVenues(false);
            }
        }
        setStagedVenue(undefined);
    }

    async function removeVenue(venue: Bizly.Venue) {
        if (!inquiryId) return;

        setUpdatingVenues(true);

        try {
            const { inquiry } = await removeVenueFromInquiry(inquiryId, venue.id);

            currentInquiryActions.setInquiry(event.id, inquiry);
            currentInquiryActions.removeVenueInq(event.id, venue.id);
        } catch {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        } finally {
            setUpdatingVenues(false);
        }
    }

    const submitInquiry = async () => {
        if (!inquiryId) return;

        try {
            await submitInquiryAPI(inquiryId);
        } catch {
            enqueueSnackbar("Oops! We weren't able to submit your inquiry. Please try again.", {
                variant: 'error',
            });
        }
        setInquiryDraft(undefined);
        currentInquiryActions.load(event.id);
        setSubmitKey(submitKey + 1);
    };

    const addVenueIfCan =
        openSlots > 0 && canSubmitInquiry && !loadingCurrent && !updatingVenues
            ? isSubmitted
                ? addVenueConfirm
                : addVenue
            : undefined;

    const removeVenueIfCan =
        Object.keys(selectedVenues).length > 0 && canSubmitInquiry && !loadingCurrent && !updatingVenues && isDraft
            ? removeVenue
            : undefined;

    return (
        <FullWidthColumn>
            {stagedVenue && (
                <ConfirmationModal
                    isActive
                    headline="Add to inquiry"
                    prompt={
                        <>
                            <span>
                                Adding a venue will automatically submit an inquiry to the venue. <Spacer small />
                                Do you want to add this venue to your inquiries?
                            </span>
                            {updatingVenues && <SpinnerOverlay />}
                        </>
                    }
                    ctaLabel="Add"
                    onDismiss={() => setStagedVenue(undefined)}
                    onProceed={() => addVenue(stagedVenue)}
                />
            )}

            {!toggles.gate.useActivityCalendar && ( // TODO: Remove this code once the new Inquiry Builder feature is available to all clients
                <Switch>
                    <Route path={match.path + '/listing/:id'} render={() => null} />
                    {!loadingCurrent && canSubmitInquiry && (
                        <InquiryDrawer
                            addedVenues={(currentInquiryVenues || []).filter(venue => venue.status === 'Added')}
                            availableSlots={availableSlots}
                            removeVenue={removeVenue}
                            submitInquiry={submitInquiry}
                            viewVenueListing={viewVenueListing}
                            viewInquiries={viewInquiries}
                            inquiryDraft={inquiryDraft}
                            updateDraft={updateDraft}
                        />
                    )}
                </Switch>
            )}

            {toggles.gate.useActivityCalendar && (
                <Switch>
                    <Route path={match.path + '/listing/:id'} render={() => null} />
                    <Route>
                        <InquiryCartWrapperRow sideMargin={sideMargin}>
                            <LoadCurrentInquiry />
                            <InquiryCart hideBuildInquiry isCreateMeeting />
                        </InquiryCartWrapperRow>
                    </Route>
                </Switch>
            )}

            <Switch>
                <Route
                    path={match.path + '/listing/:id'}
                    render={(props: RouteComponentProps<{ id: string }>) => (
                        <VenueListing
                            id={props.match.params.id}
                            contentPadding={`68px ${sideMargin}px`}
                            addToInquiry={addVenueIfCan}
                            removeFromInquiry={removeVenueIfCan}
                            selected={!!selectedVenues[parseInt(props.match.params.id)]}
                            pageOffset={pageOffset}
                        />
                    )}
                />
                <Route
                    render={() => {
                        return (
                            <PaddedContent sideMargin={sideMargin}>
                                <Switch>
                                    <Route
                                        path={match.path + '/search'}
                                        render={(props: any) => (
                                            <VenueSearch
                                                event={event}
                                                suggestedVenues={suggestedVenues}
                                                onSelect={addVenueIfCan}
                                                onDeselect={removeVenueIfCan}
                                                viewVenueListing={viewVenueListing}
                                                selectedVenues={selectedVenues}
                                                isCreateMeeting
                                                {...props}
                                            />
                                        )}
                                    />

                                    <Route
                                        render={(props: any) => (
                                            <VenueDiscoveryPage
                                                suggestedVenues={suggestedVenues}
                                                onSelect={addVenueIfCan}
                                                onDeselect={removeVenueIfCan}
                                                viewVenueListing={viewVenueListing}
                                                selectedVenues={selectedVenues}
                                                {...props}
                                                isCreateMeeting
                                            />
                                        )}
                                    />
                                </Switch>
                            </PaddedContent>
                        );
                    }}
                />
            </Switch>
        </FullWidthColumn>
    );
};

export default withRouter(Venue);
