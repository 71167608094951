import capitalize from 'lodash/capitalize';
import keyBy from 'lodash/keyBy';

import { SLIDER_VAL_TO_GRADE } from './VenueSearchGrades';
import { units } from 'stores/measurement-units';

type TVenueGrade = { value: number; label: string };
export const gradeLabels = SLIDER_VAL_TO_GRADE.map(g => capitalize(g));
const venueGrades: TVenueGrade[] = gradeLabels.map((grade, idx) => ({
    value: idx,
    label: grade,
}));

type TVenueType = { id: BizlyAPI.Venue.Types; label: string };
const venueTypes: TVenueType[] = [
    {
        id: 'hotel',
        label: 'Hotels',
    },
    {
        id: 'restaurant',
        label: 'Restaurants',
    },
    {
        id: 'unique venue',
        label: 'Unique',
    },
    {
        id: 'activity',
        label: 'Activity',
    },
    {
        id: 'conference center',
        label: 'Conference Center',
    },
    {
        id: 'event venue',
        label: 'Meeting Venue',
    },
];
export const venueTypesDict = keyBy(venueTypes, type => type.id) as Record<BizlyAPI.Venue.Types, TVenueType>;

type TVenueStyle = { id: BizlyAPI.Venue.Decors; label: string };
const venueStyles: TVenueStyle[] = [
    {
        id: 'Modern',
        label: 'Modern',
    },
    {
        id: 'Elegant',
        label: 'Elegant',
    },
    {
        id: 'Traditional',
        label: 'Traditional',
    },
];
export const venueStylesDict = keyBy(venueStyles, style => style.id) as Record<BizlyAPI.Venue.Decors, TVenueStyle>;

export const formatRadiusMi = (val: Distance.Mile) => `${val >= 100 ? '100+' : Math.round(val)} mi`;
export const formatRadiusKm = (val: Distance.Kilometer) => `${val >= 150 ? '150+' : Math.round(val)} km`;

export const fields = (
    teamName?: string,
    venueBrands?: Bizly.VenueBrand[],
    { disabledBrands, hideBrandPrompt }: { disabledBrands?: boolean; hideBrandPrompt?: boolean } = {}
) => ({
    grades: {
        prompt: 'Grade',
        type: 'slider',
        perRow: '2/3',
        options: {
            min: 0,
            max: 2,
            marks: venueGrades,
        },
    },
    hideUngraded: {
        type: 'checkbox',
        options: {
            label: 'Hide nongraded venues',
            placeholder: 'Showing nongraded venues',
        },
    },
    types: {
        prompt: 'Venue Type',
        type: 'multiselect',
        options: {
            options: teamName
                ? [
                      ...venueTypes,
                      {
                          id: 'corporate office',
                          label: teamName,
                      },
                  ]
                : venueTypes,
            perRow: 3,
            placeholder: 'Not Filtered',
        },
    },
    decors: {
        prompt: 'Venue Style',
        type: 'multiselect',
        options: {
            options: venueStyles,
            perRow: 3,
            placeholder: 'Not Filtered',
        },
    },
    preferredOnly: {
        prompt: 'Preferred Venue',
        type: 'checkbox',
        options: {
            label: 'Only show Preferred Venues',
            placeholder: 'Showing All Venues',
        },
    },
    radius: {
        prompt: 'Distance',
        type: 'slider',
        perRow: '2/3',
        options: {
            min: 1,
            max: 100,
            valueLabelFormat: formatRadiusMi,
        },
    },
    radiusKm: {
        prompt: 'Distance',
        type: 'slider',
        perRow: '2/3',
        options: {
            min: 1,
            max: 150,
            valueLabelFormat: formatRadiusKm,
        },
    },
    brandIds: {
        type: 'multiselect',
        ...(hideBrandPrompt
            ? {}
            : {
                  prompt: 'Brands',
              }),
        disabled: disabledBrands,
        options: {
            options: venueBrands ?? [],
            perRow: 2,
        },
    },
});

export const schema = (unit: units.kilometer | units.mile, showBrands?: boolean) =>
    [
        {
            key: 'grades',
            fields: ['grades'],
            spacing: 'small',
        },
        {
            key: 'hideUngraded',
            fields: ['hideUngraded'],
            spacing: 'large',
        },
        {
            key: 'types',
            fields: ['types'],
            spacing: 'large',
        },
        {
            key: 'decors',
            fields: ['decors'],
            spacing: 'large',
        },
        {
            key: 'preferred',
            fields: ['preferredOnly'],
            spacing: 'large',
        },
        ...(showBrands
            ? ([
                  {
                      key: 'radius',
                      fields: unit === units.kilometer ? (['radiusKm'] as const) : (['radius'] as const),
                      spacing: 'large',
                  },
                  {
                      fields: ['brandIds'],
                      spacing: false,
                  },
              ] as const)
            : ([
                  {
                      key: 'radius',
                      fields: unit === units.kilometer ? (['radiusKm'] as const) : (['radius'] as const),
                      spacing: false,
                  },
              ] as const)),
    ] as const;
