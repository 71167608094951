import React from 'react';

import styled from 'styled-components';

import { tzMoment, userTimeZone } from 'utils/moment';
import { format, differenceInMilliseconds, startOfDay } from 'date-fns';
import { is12AM } from 'utils/date_util';

import { EventWrapperProps } from 'react-big-calendar';
import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';

import colorFns from 'colorFns';
import { Row, Column, TruncatingSingleLineCopy } from 'ui';
import { H3Headline } from 'components/ui/Headline';
import { Tooltip } from '@material-ui/core';
import { SpinnerOverlay } from 'components/Spinner';
import { getColorPickerColor } from 'components/Form';

import { OnlySingleLine } from 'shared';

import { LABEL_OFFSET, HEADER_BORDER, BLOCK_PADDING } from '../styles';

const BLOCK_TIME_FORMAT = 'h:mmaa';

const Block = styled(Row)<{ small?: boolean; selected?: boolean }>`
    position: absolute;
    margin-left: -1px;

    background-color: ${colorFns.listItemBackground};
    border: 1px solid ${colorFns.softBorder};
    border-radius: 8px;

    box-sizing: border-box;
    margin-top: 0.5px;

    padding: ${BLOCK_PADDING}px;
    ${({ small }) =>
        small
            ? `
    padding: 0 ${BLOCK_PADDING}px;
    align-items: center;
    `
            : ''}
    overflow: hidden;

    ${({ selected, theme }) =>
        selected
            ? `
    box-shadow: -5px 5px 10px 0px ${colorFns.pureBlack.alpha(0.15)({ theme })};
        `
            : ''}
`;

const BlockLineColor = styled.div<{ fill: string }>`
    display: block;
    height: 100%;
    width: 6px;

    position: absolute;
    left: 0;
    top: 0;

    background-color: ${({ fill }) => fill};
`;

const ScrollerWrapper = styled.div<{ draft?: boolean }>`
    position: absolute;
    ${({ draft }) => (!draft ? `margin-top: ${(HEADER_BORDER + LABEL_OFFSET + BLOCK_PADDING) * -1}px;` : '')}
`;

const DescriptionCol = styled(Column)`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    min-width: 0;
`;

const BlockTimes = styled(TruncatingSingleLineCopy)`
    font-size: 12px;
    font-weight: 700;
`;

const BlockName = styled(H3Headline)`
    ${OnlySingleLine}
    color: ${colorFns.accentedHeadline};
    line-height: 24px;
    margin-top: -3px;
`;

const Strike = styled.s`
    text-decoration-thickness: from-font;
    text-decoration-style: single;
`;

const getTopAndHeightPercent = ({
    start,
    end,
    continuesEarlier,
    continuesLater,
}: {
    start: Date;
    end: Date;
    continuesEarlier?: boolean;
    continuesLater?: boolean;
}) => {
    const msInDay = 24 * 60 * 60 * 1000;
    const msElapsedInDay = (date: Date) => differenceInMilliseconds(date, startOfDay(date));

    const top = 100 * (continuesEarlier ? 0 : msElapsedInDay(start) / msInDay);
    const bottom = 100 * (continuesLater ? 1 : msElapsedInDay(end) / msInDay);
    const height = bottom - top;

    return { top, height };
};

type TBaseBlock = {
    id: number;
    name?: string;
    cancelledAt?: BizlyAPI.Meeting['cancelledAt'] | null;

    start: Date;
    end: Date;

    colorId?: number | null;

    loading?: boolean;
};

type TEventBlockProps = {
    draft?: boolean;
    selected?: boolean;

    blockRef?: React.MutableRefObject<HTMLDivElement | null>;
    onMouseDown?: React.MouseEventHandler;
};

type TEventBlock<TBlock extends TBaseBlock = TBaseBlock> = EventWrapperProps<TBlock> & TEventBlockProps;

export default function EventBlock<TBlock extends TBaseBlock>({
    style,
    event,
    continuesEarlier,
    continuesLater,
    draft,
    selected,

    blockRef,
    ...rest
}: TEventBlock<TBlock>) {
    if (continuesEarlier && !continuesLater && is12AM(event.end)) return null;

    const { xOffset, width, color, backgroundColor, ...restOfStyle } = style || {};

    const { top, height } = getTopAndHeightPercent({ ...event, continuesEarlier, continuesLater });

    const styleProp = {
        ...restOfStyle,

        top: top + '%',
        left: xOffset + '%',
        height: height + '%',
        width: width + '%',
        // prevent overflow past 100%
        maxHeight: `calc(${100 - top}% - ${LABEL_OFFSET}px)`,
    };

    const small = height < 4;
    const times = [format(event.start, BLOCK_TIME_FORMAT), format(event.end, BLOCK_TIME_FORMAT)].join(' to ');

    const showTimes = !small || !event.name;
    const showName = !small || event.name;

    const { cancelledAt } = event;
    const cancelledAtTime =
        cancelledAt &&
        `Cancelled on ${tzMoment(cancelledAt.date, cancelledAt.timezone)
            .tz(userTimeZone)
            .format('MMM DD, YYYY h:mm a z')}`;

    const renderedDescription = (
        <DescriptionCol itemSpacing="xsmall">
            {showTimes && <BlockTimes small>{times}</BlockTimes>}
            {showName && <BlockName>{event.name}</BlockName>}
        </DescriptionCol>
    );

    return (
        <Block
            style={styleProp}
            {...rest}
            small={small}
            selected={selected}
            ref={inst => inst && blockRef && (blockRef.current = inst)}
        >
            {selected && (draft ? !continuesEarlier : true) && (
                <ScrollerWrapper draft={draft}>
                    <ScrollIntoViewIfNeeded
                        options={{
                            behavior: 'smooth',
                            block: draft ? 'center' : 'start',
                            inline: 'center',
                            scrollMode: 'if-needed',
                        }}
                    >
                        <span />
                    </ScrollIntoViewIfNeeded>
                </ScrollerWrapper>
            )}
            {cancelledAt ? (
                <Tooltip title={cancelledAtTime} placement="top">
                    <Strike>{renderedDescription}</Strike>
                </Tooltip>
            ) : (
                renderedDescription
            )}
            <BlockLineColor fill={getColorPickerColor(event.colorId ?? null)} />
            {event.loading && <SpinnerOverlay />}
        </Block>
    );
}
