import React from 'react';
import styled from 'styled-components';
import { parseISO, format, isBefore } from 'date-fns';
import uniqueId from 'lodash/uniqueId';

import { padArray } from '../../util';

import { SpacedRow, Matchbox, MatchboxPlaceholder, SpacedColumn } from 'ui';
import { AvatarWithTooltip } from 'components/ui/Avatar';
import CircleCheckIcon from 'components/CircleCheckIcon';

const GriddedMatchbox = styled(Matchbox)`
    margin: 12px;
`;

const GriddedMatchboxPlaceholder = styled(MatchboxPlaceholder)`
    margin: 12px;
`;

type MatchboxProps = {
    status: ReturnType<typeof eventStatus>;
    selected?: boolean;
};

const MatchboxTop = styled(SpacedColumn)<MatchboxProps>`
    padding: 12px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    height: 126px;
    border-radius: 12px 12px 0 0;

    background-color: ${({ status, theme: { getColor, EColors } }) =>
        status === 'completed'
            ? getColor(EColors.completedEvent)
            : status === 'dataEntered'
            ? getColor(EColors.activeEvent)
            : getColor(EColors.uneditedEvent)};

    ${({ selected, status, theme: { getColor, EColors } }) =>
        selected &&
        `
    background-color: ${
        status === 'completed'
            ? getColor(EColors.completedEventHover)
            : status === 'dataEntered'
            ? getColor(EColors.activeEventHover)
            : getColor(EColors.uneditedEvent)
    };`}

    ${GriddedMatchbox}:hover & {
        background-color: ${({ status, theme: { getColor, EColors } }) =>
            status === 'completed'
                ? getColor(EColors.completedEventHover)
                : status === 'dataEntered'
                ? getColor(EColors.activeEventHover)
                : getColor(EColors.uneditedEvent)};
    }

    transition: all 0.15s ease-out;
`;

const MatchboxBottom = styled(SpacedColumn)<MatchboxProps>`
    padding: 8px 12px;
    color: ${({ status, theme: { getColor, EColors } }) =>
        status === 'dataEntered' ? getColor(EColors.darkestGrey) : getColor(EColors.darkGrey)};
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    border-radius: 0 0 12px 12px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const eventStatus = (event: Bizly.Event) => {
    const { endsAt, name } = event;
    if (!endsAt && name === 'Untitled Meeting') {
        return 'initial';
    } else if (endsAt && isBefore(parseISO(endsAt), new Date())) {
        return 'completed';
    } else {
        return 'dataEntered';
    }
};

type TEventBox = {
    event: Bizly.Event;
    user: Bizly.User;
    selected?: boolean;
    onSelect?: (event: Bizly.Event) => void;
};

export default function EventBox({ event, user, selected, onSelect }: TEventBox) {
    return (
        <div>
            <GriddedMatchbox onClick={() => onSelect?.(event)} selected={selected}>
                <MatchboxTop status={eventStatus(event)} selected={selected}>
                    <SpacedRow>
                        <span style={{ fontSize: '22px' }}>
                            {event.startsAt && event.startsAt.toString() !== 'Invalid Date'
                                ? format(parseISO(event.startsAt), 'MMM do')
                                : ''}
                        </span>
                        {selected ? (
                            <CircleCheckIcon inverted />
                        ) : (
                            event.plannedBy.firstName !== user.firstName &&
                            event.plannedBy.lastName !== user.lastName && <AvatarWithTooltip user={event.plannedBy} />
                        )}
                    </SpacedRow>
                    <span>{event.name || <em>Untitled Meeting</em>}</span>
                </MatchboxTop>
                <MatchboxBottom status={eventStatus(event)}>
                    <span style={{ minHeight: '24px' }}>
                        {event.state && event.city ? `${event.city.name}, ${event.state.code}` : event.location || ''}
                    </span>
                    <span>{event.attendeeCounts.attending} Attending</span>
                </MatchboxBottom>
            </GriddedMatchbox>
        </div>
    );
}

export const padWithPlaceholder = (events: Bizly.Event[], padTo: number, mapper: (event: Bizly.Event) => JSX.Element) =>
    padArray(events, padTo).map(event => (!event ? <GriddedMatchboxPlaceholder key={uniqueId()} /> : mapper(event)));
