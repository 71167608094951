import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { groupBy } from 'lodash';
import { useHistory } from 'react-router';
import moment from 'moment';

import { useUser } from 'providers/user';
import { useEventTemplates as useEventPlaybooks } from 'stores/eventTemplates'; // TODO: Update these helper names to use "Playbook" once old "Templates" page is depricated
import { isBlankTemplateId as isBlankPlaybookId } from 'api/eventTemplates'; // TODO: Update these helper names to use "Playbook" once old "Templates" page is depricated

import { Column, Row, Spacer } from 'ui';
import Button from 'components/ui/Button';
import { ReactComponent as PlusSVG } from 'images/icons/plus.svg';
import { LargeHeadline } from 'components/ui/Headline';
import { SpinnerOverlay } from 'components/Spinner';
import PagedPlaybooks from 'components/Templates/PagedPlaybooks';
import FilterList from './components/FilterList';

import {
    ESECTION,
    sectionToTitle,
    sectionToSubheading,
    getTemplatesUrlFilters as getPlaybookUrlFilters, // TODO: Update these helper names to use "Playbook" once old "Templates" page is depricated
    setTemplatesUrlFilters as setPlaybookUrlFilters, // TODO: Update these helper names to use "Playbook" once old "Templates" page is depricated
} from 'components/Templates/utils';
import useShowModal from 'hooks/useShowModal';
import PlaybookCreateModal from 'pages/Playbooks/components/PlaybookCreateModal';

const { PERSONAL, SHARED, GLOBAL } = ESECTION;

type TPlaybooksByCategory = Record<ESECTION, Bizly.EventTemplate[]>;

const DEFAULT_PLAYBOOKS: TPlaybooksByCategory = {
    [PERSONAL]: [],
    [SHARED]: [],
    [GLOBAL]: [],
};
const ORDERED_CATEGORIES: ESECTION[] = [PERSONAL, SHARED, GLOBAL];

const PlusIcon = styled(PlusSVG)``;
const CreateButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 45px;

    border-radius: 8px;

    ${PlusIcon} {
        display: inline-flex;
        white-space: nowrap;
        padding: 0;
        width: 26px;
        height: 26px;

        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};

        border-radius: 50%;
        min-width: 0;
        align-items: center;
        justify-content: center;

        margin-right: 10px;
    }
`;

const ContentRow = styled(Row)`
    position: relative;
    height: 100%;
`;
const LeftColumn = styled(Column)`
    position: relative;
    min-height: 100%;
    max-width: 300px;
`;
const RightColumn = styled(Column)`
    border-left: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    padding-left: 48px;
    margin-left: 48px;
`;

const Sticky = styled.span`
    position: sticky;
    top: 24px;
`;

const sortByMostRecent = (playbooks: Bizly.EventTemplate[]) =>
    playbooks.slice().sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? -1 : 1));

export default function PlaybooksPage() {
    const [playbooksByCategory, setPlaybooksByCategory] = useState<TPlaybooksByCategory>(DEFAULT_PLAYBOOKS);
    const { templates: playbooks, loading: loadingPlaybooks } = useEventPlaybooks();
    useEffect(() => {
        const arrangePlaybooks = (allPlaybooks: Bizly.EventTemplate[]) => {
            const { personal, shared, global } = {
                ...DEFAULT_PLAYBOOKS,
                ...groupBy(sortByMostRecent(allPlaybooks), 'category'),
            };
            const globalTempsWithoutBlank = global.filter(({ id }) => !isBlankPlaybookId(id));

            return setPlaybooksByCategory({
                personal: personal,
                shared: shared,
                global: globalTempsWithoutBlank,
            });
        };

        return playbooks && arrangePlaybooks(playbooks);
    }, [playbooks]);

    const history = useHistory();
    const { user, toggles } = useUser();
    const { modalShown: createModalShown, showModal: showCreateModal, hideModal: hideCreateModal } = useShowModal();

    const { section: currentSection, tags: currentTags } = getPlaybookUrlFilters(history.location);
    useEffect(() => {
        if (currentSection && ![PERSONAL, SHARED, GLOBAL].includes(currentSection))
            setPlaybookUrlFilters(history, curFilterValue => ({ ...curFilterValue, section: undefined }));
    }, [currentSection, history]);

    const showAllCategory = (section: ESECTION) => currentSection === section;
    const showCategory = (section: ESECTION) => !currentSection || showAllCategory(section);

    const getOnShowAll = (category: ESECTION) => () =>
        setPlaybookUrlFilters(history, curFilters => ({ ...curFilters, section: category }));
    const onBack = () => setPlaybookUrlFilters(history, curFilters => ({ ...curFilters, section: undefined }));

    const tagsSet = new Set(currentTags);
    const applyFilter = (playbooks: Bizly.EventTemplate[]) =>
        tagsSet.size ? playbooks.filter(playbook => playbook.tags?.some(tag => tagsSet.has(tag.id))) : playbooks;

    const categoryToTitle = (category: ESECTION) => sectionToTitle(category, user);
    const categoryToSubheading = (category: ESECTION) => sectionToSubheading(category);

    return (
        <>
            <Column alignItems="flex-start">
                <LargeHeadline>Playbooks</LargeHeadline>
                <Spacer small />
                <CreateButton
                    width="auto"
                    onClick={toggles.gate.createPlaybooksFlow ? showCreateModal : () => history.push('/playbooks/new')}
                    small
                >
                    <Row alignItems="center">
                        <PlusIcon />
                        Create New Playbook
                    </Row>
                </CreateButton>
                <Spacer large />

                <ContentRow alignItems="stretch">
                    <LeftColumn>
                        <Sticky>
                            <FilterList />
                        </Sticky>
                    </LeftColumn>
                    <RightColumn itemSpacing="default">
                        {ORDERED_CATEGORIES.map(
                            category =>
                                showCategory(category) && (
                                    <PagedPlaybooks
                                        key={category}
                                        playbooks={applyFilter(playbooksByCategory[category])}
                                        title={categoryToTitle(category)}
                                        subheading={categoryToSubheading(category)}
                                        showAll={showAllCategory(category)}
                                        onShowAll={getOnShowAll(category)}
                                        onBack={onBack}
                                    />
                                )
                        )}
                    </RightColumn>
                </ContentRow>
            </Column>
            {loadingPlaybooks && <SpinnerOverlay fixedSpinner />}
            {toggles.gate.createPlaybooksFlow && createModalShown && <PlaybookCreateModal onClose={hideCreateModal} />}
        </>
    );
}
