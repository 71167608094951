import React from 'react';
import styled from 'styled-components';

import { useEventTemplateTags } from 'stores/event-template-tags';

import Form from 'components/Form';
import { Spinner } from 'components/Spinner';

import { ReactComponent as CircleIcon } from 'images/icons/circle.svg';
import colorFns from 'colorFns';

const CircularBubble = styled(CircleIcon)<{ checked?: boolean }>`
    color: ${colorFns.lightGrey};
    stroke: ${colorFns.grey};

    ${props =>
        props.checked &&
        `
        color: ${colorFns.primaryAction(props)};
    `}
`;

export default function CategoriesField({
    field,
    value: valueProp = [],
    onChange: onChangeProp,
    readonly,
    disabled,
    perRow,
    circleIcon,
}: {
    field: string;
    value: number[];
    onChange: (update: { field: string; value: number[] }) => void;
    readonly?: boolean;
    disabled?: boolean;
    perRow?: number;
    circleIcon?: boolean;
}) {
    const tagsStore = useEventTemplateTags();

    const fields = {
        categories: {
            type: 'multiselect',
            options: {
                options: tagsStore.tags ?? [],
                perRow: perRow || 3,
                ...(circleIcon
                    ? {
                          customIcon: <CircularBubble />,
                          customIconChecked: <CircularBubble checked />,
                      }
                    : {}),
            },
        },
    };
    const schema = [{ fields: ['categories'] }];
    const value = { categories: valueProp };
    const onChange = ({ value }: { value: { categories: number[] } }) => {
        onChangeProp({ field, value: value.categories });
    };

    return !tagsStore.loaded ? (
        <Spinner />
    ) : (
        <Form
            fields={fields}
            schema={schema}
            value={value}
            onChange={onChange}
            readonly={readonly}
            disabled={disabled}
        />
    );
}
