import React from 'react';
import styled from 'styled-components';

import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import { useSchedule } from 'stores/schedule';
import { selectMeeting, useMeetings } from 'stores/meetings';

import { Column as UIColumn, Row } from 'ui';

import DashboardCard from 'components/EventDashboard/DashboardCard';
import CommunicationSection from 'components/EventDashboard/CommunicationSection';
import VenueSection from 'components/EventDashboard/VenueSection';
import VendorsSection from 'components/EventDashboard/VendorsSection';
import GuestlistSection from 'components/EventDashboard/GuestlistSection';
import AgendaSection from 'components/EventDashboard/AgendaSection';
import PlannerSection from 'components/PlannerSection'; // Todo: Remove this once we activate the new Calendar for all users
import EventBriefing from 'components/EventDashboard/EventBriefing';
import EventDetailsSection from 'components/EventDashboard/EventDetailsSection';
import Checklist from 'components/EventDashboard/Checklist';

import { Redirect } from 'react-router';
import { getHeaderHeight } from 'components/EventHeader';
import { SpinnerOverlay } from 'components/Spinner';

const CardsColumn = styled(UIColumn)`
    width: 100%;
`;
const DashboardCols = styled(Row)`
    > ${CardsColumn} {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 0;
    }
`;

const UNACCOUNTED_FOR_HEIGHT = 2; // TODO: investigate, this causes the page to display scrollbars even though everything is box-sizing: border-box

const Dashboard = styled(Row)<{ headerHeight: number; pagePadding: number }>`
    max-width: 1600px;

    padding: ${({ pagePadding }) => pagePadding}px;
    padding-bottom: 0;
    box-sizing: border-box;

    min-height: calc(900px - ${({ headerHeight }) => headerHeight}px - ${UNACCOUNTED_FOR_HEIGHT}px);
    max-height: calc(100vh - ${({ headerHeight }) => headerHeight}px - ${UNACCOUNTED_FOR_HEIGHT}px);
    height: 100%;
    align-items: stretch;

    > ${DashboardCols} {
        max-height: 960px;
        align-items: stretch;

        > ${CardsColumn} {
            max-height: 100%;
        }
    }
`;

const DoubleCards = styled(CardsColumn)`
    > *:first-child {
        height: unset;
        flex-basis: 460px;
        flex-grow: 1;
        flex-shrink: 0;
    }

    > *:last-child {
        height: unset;
        flex-basis: 295px;
        flex-grow: 1;
        flex-shrink: 0;
    }
`;

const VenueCol = styled(DoubleCards)`
    min-width: 400px !important;
`;

const EventDetailsWrap = styled.div``;
const ChecklistWrap = styled.div``;
const EventBriefingWrap = styled.div``;

const MainColumn = styled(CardsColumn)`
    > ${EventDetailsWrap} {
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;
    }

    > ${ChecklistWrap} {
        flex-basis: 0;
        min-height: 0;
        flex-grow: 1;
        flex-shrink: 0;
    }

    > ${EventBriefingWrap} {
        flex-basis: auto;
        flex-grow: 0;
        flex-shrink: 0;
    }
`;

type TEventDashboard = {
    viewVenueListing: (venueId: number) => void;
    viewProposal: (proposalId: number) => void;
    goToVenueSearch: () => void;
    viewInquiries: () => void;
    pagePadding?: number;
};

export default function EventDashboard({
    viewVenueListing,
    viewProposal,
    goToVenueSearch,
    viewInquiries,
    pagePadding = 0,
}: TEventDashboard) {
    const { user, toggles } = useUser();
    const { event, template, suggestedVenues, transparentHeader } = useEvent();
    const meeting = useMeetings(selectMeeting(event.id));

    const headerHeight = getHeaderHeight(transparentHeader);

    const schedule = useSchedule();

    const scheduleItems =
        (schedule.loaded &&
            schedule.eventId === event.id &&
            schedule.items.filter(item => item.date && item.startTime && item.endTime)) ||
        [];
    const hasScheduleItems = schedule.loaded && scheduleItems.length > 0;

    const agendasEnabled = user.team?.useAgendas || hasScheduleItems;

    const bookingDashboardCard = (
        <DashboardCard scrollable>
            <VenueSection
                suggestedVenues={suggestedVenues}
                eventId={event.id}
                editable={event.editable && !event.cancelledAt}
                eventName={event.name}
                location={event.location}
                googlePlaceId={event.googlePlaceId}
                lat={event.lat}
                lng={event.lng}
                startsAt={event.startsAt}
                endsAt={event.endsAt}
                viewVenueListing={viewVenueListing}
                viewProposal={viewProposal}
                onSearch={goToVenueSearch}
                onEditInquiries={viewInquiries}
                cancelledAt={event.cancelledAt}
            />
        </DashboardCard>
    );

    if (toggles.gate.createMeetingsFlow) {
        return <Redirect to={`/events/${event.id}`} push={false} />;
    }

    return (
        <>
            {!meeting ? (
                <SpinnerOverlay />
            ) : (
                <Dashboard headerHeight={headerHeight} pagePadding={pagePadding}>
                    <DashboardCols itemSpacing="default" justifyContent="center">
                        <MainColumn itemSpacing="default">
                            <EventDetailsWrap>
                                <EventDetailsSection
                                    meeting={meeting}
                                    editable={meeting.editable}
                                    templateDescription={template.longDescription}
                                    templateObjective={template.objectiveDescription}
                                    superHeading={
                                        user.team?.uniqueMeetingCreateFields?.includes('cventId')
                                            ? `${user.team?.meetingInternalReferenceFieldLabel ||
                                                  'Internal Reference'}: ${meeting.internalReference}`
                                            : undefined
                                    }
                                />
                            </EventDetailsWrap>
                            <ChecklistWrap>
                                <Checklist
                                    eventId={event.id}
                                    showBrief={template.showBrief}
                                    readOnly={!event.editable}
                                    noCard
                                    flexHeight
                                />
                            </ChecklistWrap>
                            {template.showBrief && (
                                <EventBriefingWrap>
                                    <EventBriefing
                                        title={template.briefTitle}
                                        desc={template.shortDescription}
                                        imageSmall={template.briefImageSmall}
                                        readMoreLink={template.readMoreLink}
                                    />
                                </EventBriefingWrap>
                            )}
                        </MainColumn>

                        <DoubleCards itemSpacing="default">
                            {agendasEnabled ? (
                                <DashboardCard>
                                    {toggles.gate.useActivityCalendar ? <AgendaSection /> : <PlannerSection />}
                                </DashboardCard>
                            ) : (
                                bookingDashboardCard
                            )}
                            <DashboardCard small>
                                <GuestlistSection editable={event.editable} attendeeCount={event.attendeeCounts} />
                            </DashboardCard>
                        </DoubleCards>

                        <VenueCol itemSpacing="default">
                            {agendasEnabled ? (
                                bookingDashboardCard
                            ) : (
                                <DashboardCard>
                                    <VendorsSection />
                                </DashboardCard>
                            )}
                            <DashboardCard small>
                                <CommunicationSection editable={event.editable} />
                            </DashboardCard>
                        </VenueCol>
                    </DashboardCols>
                </Dashboard>
            )}
        </>
    );
}
