import React from 'react';
import styled from 'styled-components';

import { Row } from 'ui';

const ViewportFullscreenFixedBottom = styled(Row)`
    position: absolute;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;

    justify-content: center;

    pointer-events: none; // Prevent transparent element from blocking controls behind it
`;

const BottomBannerDiv = styled.div`
    max-width: 1020px;
    min-height: 90px;
    padding: 40px;
    box-sizing: border-box;
    border-radius: 2000px; /* always make it look like a pill */
    box-shadow: 0 2px 44px 0 ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.3)};
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.homeScreenBanner, 0.9)};

    position: fixed;
    bottom: 36px;

    display: flex;
    justify-content: space-around;
    align-items: center;

    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    font-size: 13px;

    pointer-events: auto; // Allow children of overlay to remain clickable
`;

export default function BottomBanner({ children }: { children: React.ReactNode }) {
    return (
        <ViewportFullscreenFixedBottom>
            <BottomBannerDiv>{children}</BottomBannerDiv>
        </ViewportFullscreenFixedBottom>
    );
}
