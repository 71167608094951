import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import moment from 'moment';
import range from 'lodash/range';
import { is12AM } from 'utils/date_util';
import useShowModal from 'hooks/useShowModal';

import { useUser } from 'providers/user';

import { Row, Spacer, Column } from 'ui';
import { LargeHeadline } from 'components/ui/Headline';
import { Spinner } from 'components/Spinner';
import { Tab, Tabs } from 'components/Tabs';
import Button from 'components/ui/Button';
import Toolbar from 'components/BigCalendar/components/Toolbar';
import ScheduledList from './ScheduledList';
import UnschedList from './UnschedList';
import BigCalendar from 'components/BigCalendar';
import EventCreateModal, { EventCreateProtocolModal } from 'components/EventCreateModal';

import { ReactComponent as PlusSVG } from 'images/icons/plus.svg';
import { ReactComponent as ListIconSVG } from 'images/icons/list.svg';
import { ReactComponent as CalIconSVG } from 'images/icons/calendar.svg';

import { useMeetings, selectMeetings, isLoading, LoadMeetings } from 'stores/meetings';
import colorFns from 'colorFns';

const ListIcon = styled(ListIconSVG)`
    height: 18px;
    width: 18px;
`;
const CalIcon = styled(CalIconSVG).attrs({ viewBox: '-3 0 27 27' })`
    width: 22px;
    height: 22px;
`;

const TABS_CONTAINER_STYLES = {
    width: 'auto',
    display: 'inline-block',
    margin: '0 -10px',
};

const TAB_STYLES = {
    padding: 0,
    margin: '0 10px',
};

const CircleButton = styled(Button)`
    border-radius: 100px;
    text-align: center;
    height: 36px;
    width: 36px;
`;

const AutoRow = styled(Row)`
    width: auto;
`;

const Sticky = styled(Column)`
    width: 100%;
    position: sticky;
    top: 0px;
    align-items: flex-start;
    padding-top: 24px;

    background: ${colorFns.pureWhite};
    z-index: 1;
`;

const ContentCol = styled(Column)`
    position: relative;
    width: 100%;
    flex: 1 0 0;
`;

const PlusIcon = styled(PlusSVG)``;
const CreateButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 45px;

    border-radius: 8px;

    ${PlusIcon} {
        display: inline-flex;
        white-space: nowrap;
        padding: 0;
        width: 26px;
        height: 26px;

        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};

        border-radius: 50%;
        min-width: 0;
        align-items: center;
        justify-content: center;

        margin-right: 10px;
    }
`;

const APIToCalBlock = (event: BizlyAPI.Meeting) => {
    const start = moment(event.startsAt);
    const end = moment(event.endsAt);

    const diffDays = end.diff(start, 'day');
    const sameDayEndsAt12 = diffDays === 1 && is12AM(end.toDate());

    return {
        ...event,
        name: event.name || undefined,
        start: start.toDate(),
        end: end.toDate(),
        multiDay: diffDays > 0 && !sameDayEndsAt12,
    };
};

export default function Events({ unscheduled }: { unscheduled?: boolean }) {
    const { user } = useUser();

    const history = useHistory();
    const goToScheduled = () => history.push(`/events`);
    const goToUnscheduled = () =>
        history.push(user.featureFlags?.createMeetingsFlow ? `/events/drafts` : `/events/unscheduled`);

    const viewEvent = (event: { id: number; published?: boolean }) => {
        if (user.featureFlags?.createMeetingsFlow) {
            if (!event.published) return history.push(`/events/${event.id}/edit`);
            return history.push(`/events/${event.id}`);
        }

        return history.push(`/event/${event.id}`);
    };

    const [date, setDate] = React.useState<Date>(new Date());
    const [view, setView] = React.useState<'cal' | 'list'>('list');

    const { meetings } = useMeetings();
    const asList = React.useMemo(
        () =>
            Object.values(meetings)
                .filter(m => m.startsAt && m.endsAt)
                .map(APIToCalBlock),
        [meetings]
    );

    const startOfWeek = moment(date)
        .startOf('week')
        .toDate();

    const week = range(7).map(days =>
        moment(date)
            .startOf('week')
            .add(days, 'days')
    );

    const ofWeek = useMeetings(selectMeetings(startOfWeek));
    const isLoadingWeek = useMeetings(isLoading(startOfWeek));
    const unsched = useMeetings(selectMeetings());
    const loadingUnscheduled = useMeetings(isLoading());

    const perWeek = week.map(date => [date, ofWeek.filter(e => moment(e.startsAt).isSame(date, 'day'))]) as [
        moment.Moment,
        BizlyAPI.Meeting[]
    ][];

    const {
        modalShown: protocolModalShown,
        showModal: showProtocolModal,
        hideModal: hideProtocolModal,
    } = useShowModal();

    const { modalShown: createModalShown, showModal: showCreateModal, hideModal: hideCreateModal } = useShowModal();
    const handleCreateMeetingButtonClick = async () => {
        if (user.team?.authMeetingCreateRedirect) return showProtocolModal();
        if (user?.featureFlags?.createMeetingsFlow) return history.push(`/events/new`);

        showCreateModal();
    };

    const [deferFocus, setDeferFocus] = React.useState(true);
    React.useEffect(() => () => setDeferFocus(!isLoadingWeek), [isLoadingWeek]);

    const TabsRoute = ({ viewSched, asPublished }: { viewSched?: boolean; asPublished?: boolean }) => (
        <Tabs value={Number(viewSched)} style={TABS_CONTAINER_STYLES}>
            <Tab
                label={asPublished ? 'Meetings' : 'Scheduled'}
                value={1}
                onClick={() => goToScheduled()}
                style={TAB_STYLES}
            />
            <Tab
                label={asPublished ? 'Drafts' : 'Unscheduled'}
                value={0}
                onClick={() => goToUnscheduled()}
                style={TAB_STYLES}
            />
        </Tabs>
    );

    const NavBar = () => (
        <Row alignItems="center" justifyContent="space-between">
            <AutoRow alignItems="center" itemSpacing="large">
                <Toolbar
                    date={date}
                    view="week"
                    onView={() => {}}
                    onNavigate={(action, newDate) => {
                        if (action === 'DATE' && newDate) return setDate(newDate);
                        setDate(
                            moment(date)
                                .add(action === 'NEXT' ? 1 : action === 'PREV' ? -1 : 0, 'weeks')
                                .toDate()
                        );
                    }}
                    hideRange
                    noMargin
                    noIcon
                    noBorder
                />
                {isLoadingWeek && <Spinner suppressMargin />}
            </AutoRow>
            <AutoRow itemSpacing="small">
                <CircleButton width="auto" onClick={() => setView('list')} secondary={view !== 'list'}>
                    <ListIcon />
                </CircleButton>
                <CircleButton width="auto" onClick={() => setView('cal')} secondary={view !== 'cal'}>
                    <CalIcon />
                </CircleButton>
            </AutoRow>
        </Row>
    );

    const Header = () => (
        <>
            <LargeHeadline>All Meetings</LargeHeadline>
            <Spacer small />
            <CreateButton width="auto" onClick={handleCreateMeetingButtonClick}>
                <Row alignItems="center">
                    <PlusIcon />
                    Create New Meeting
                </Row>
            </CreateButton>
            <Spacer />
        </>
    );

    const Overlays = () => (
        <>
            {createModalShown && <EventCreateModal onClose={hideCreateModal} />}{' '}
            {protocolModalShown && <EventCreateProtocolModal onBackdropClick={hideProtocolModal} />}
        </>
    );

    if (unscheduled) {
        return (
            <>
                <Sticky>
                    <Header />
                    <TabsRoute viewSched={false} asPublished={user.featureFlags?.createMeetingsFlow} />
                </Sticky>

                <Spacer large />

                <ContentCol>
                    <LoadMeetings limit={1000} />
                    <UnschedList meetings={unsched} loading={loadingUnscheduled} onClick={viewEvent} />
                </ContentCol>

                <Overlays />
            </>
        );
    }

    return (
        <>
            <Sticky>
                <Header />
                <TabsRoute viewSched={true} asPublished={user.featureFlags?.createMeetingsFlow} />
                <NavBar />
            </Sticky>
            <Spacer />
            <ContentCol>
                <LoadMeetings date={date} />
                {view === 'cal' && (
                    <BigCalendar
                        events={asList}
                        view="week"
                        date={date}
                        onNavigate={newDate => setDate(newDate)}
                        lockView
                        noToolbar
                        onClickBlock={viewEvent}
                        triggerFocus={!deferFocus}
                        showMultiDayTimes
                    />
                )}
                {view === 'list' && <ScheduledList perWeek={perWeek} onClick={viewEvent} />}
            </ContentCol>

            <Overlays />
        </>
    );
}
