import 'whatwg-fetch';

const preset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET; // managed here: https://cloudinary.com/console/settings/upload#upload_presets
const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
const host = `https://api.cloudinary.com/v1_1/${cloudName}`;

export const getExtension = (path: string) => path.substr(path.lastIndexOf('.') + 1);
export const getFileName = (path: string) => path.substr(path.lastIndexOf('/') + 1);

const upload = (payload: FormData, useRaw: boolean) =>
    fetch(`${host}/${useRaw ? 'raw' : 'image'}/upload`, {
        method: 'POST',
        body: payload,
    })
        .then(res => res.json())
        .then(json => {
            if (json.error) {
                console.error(json.error);
                throw new Error(json.error.message);
            }
            return {
                cloudinaryId: json.public_id,
                title: json.original_filename,
                url: json.secure_url,
                format: getExtension(json.secure_url),
                resourceType: json.resource_type,
                bytes: json.bytes,
            };
        });

export const uploadFile = (file: any) => {
    const extension = (file: any) => getExtension(file.path);
    const isImage = extension(file).match(/(jpeg|jpg|gif|png)/);

    const payload = new FormData();
    preset && payload.append('upload_preset', preset);
    payload.append('file', file);
    payload.append('resource_type', isImage ? 'image' : 'raw');

    return upload(payload, !isImage);
};

export const uploadRaw = (data: BlobPart, filename: string, type: string) => {
    const payload = new FormData();
    preset && payload.append('upload_preset', preset);
    payload.append('file', new Blob([data], { type }));
    payload.append('resource_type', 'raw');
    filename && payload.append('public_id', filename);

    return upload(payload, true);
};
