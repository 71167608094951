import React from 'react';

import { DietaryQuestionsField, CustomQuestionsForm } from 'pages/RSVPForm';
import Form from 'components/Form';

import { statusOptions, attendanceTypeOptions } from 'shared';

import { useUser } from 'providers/user';

const FormAny = Form as any;

type AttendeeFormProps<TReadonly extends boolean | undefined> = {
    attendee: BizlyAPI.Attendee &
        Partial<{ dietaryRestrictions: unknown; foodAllergies: unknown; checkInDate: unknown }>;
    eventOptions: { dietaryRestrictionOptions: unknown; foodAllergyOptions: unknown };
    readonly?: TReadonly;
} & (true extends TReadonly
    ? { readonly: true }
    : {
          readonly?: false;
          externalIdFieldLabel?: string;
          onChange: (value: BizlyAPI.Attendee) => void;
          error: boolean;
      });

function AttendeeForm<TReadonly extends boolean | undefined = undefined>(props: AttendeeFormProps<TReadonly>) {
    const {
        attendee,
        eventOptions,
        readonly,
        externalIdFieldLabel = '',
        onChange: onChangeProp,
        error = false,
    } = props as AttendeeFormProps<false>;

    const { toggles } = useUser();
    const { dietaryRestrictionOptions, foodAllergyOptions } = eventOptions;
    const formValue = {
        ...attendee,
        dietary: {
            dietaryRestrictions: attendee.dietaryRestrictions,
            foodAllergies: attendee.foodAllergies,
        },
        accommodation: attendee.checkInDate
            ? 'Overnight accommodation required.'
            : 'No overnight accommodation required.',
    };

    const fields = {
        firstName: {
            prompt: 'First Name',
            type: 'text',
            perRow: 2,
        },
        lastName: {
            prompt: 'Last Name',
            type: 'text',
            perRow: 2,
        },
        phone: {
            prompt: 'Phone Number',
            type: 'phone',
            perRow: 2,
        },
        externalId: {
            prompt: externalIdFieldLabel,
            type: 'text',
            perRow: 2,
        },
        checkInDate: {
            prompt: 'Check-in Date',
            type: 'date',
            perRow: 3,
        },
        checkOutDate: {
            prompt: 'Check-out Date',
            type: 'date',
            perRow: 3,
        },
        status: {
            prompt: 'Status',
            type: 'select',
            perRow: 3,
            options: {
                options: statusOptions,
            },
        },
        dietary: {
            type: DietaryQuestionsField,
            readonly,
            options: {
                prompt: 'Dietary Needs',
                dietaryRestrictionOptions,
                foodAllergyOptions,
                perRow: readonly ? 1 : 4,
            },
        },
        rsvpComments: {
            prompt: 'Notes',
            type: 'textarea',
            readonly,
            perRow: 2,
            options: {
                placeholder: readonly ? 'No notes.' : 'Add a note about this attendee.',
            },
        },
        attendanceType: {
            prompt: 'Attendance Type',
            type: 'radioselect',
            perRow: 2,
            options: {
                options: attendanceTypeOptions,
            },
        },
        customQuestions: {
            type: CustomQuestionsForm,
            options: {
                questions: formValue.customQuestions,
            },
        },
        accommodation: {
            prompt: 'Accommodation',
            type: 'text',
            perRow: 2,
        },
    };

    const schema = [
        {
            key: 'name',
            fields: ['firstName', 'lastName'],
        },
        {
            key: 'phoneExternalId',
            fields: ['phone', 'externalId'],
        },
        {
            key: 'dates',
            fields: ['checkInDate', 'checkOutDate', 'status'],
        },
        {
            key: 'dietary',
            fields: ['dietary'],
        },
        {
            key: 'rsvpComments',
            fields: ['rsvpComments'],
        },
        ...(toggles.gate.useNewPlanner
            ? [
                  {
                      key: 'attendanceType',
                      fields: ['attendanceType'],
                  },
              ]
            : []),
        {
            key: 'customQuestions',
            header: 'Custom RSVP Questions',
            fields: ['customQuestions'],
        },
    ];

    const readOnlySchema = [
        {
            key: 'accommodation',
            fields: ['accommodation'],
        },
        ...(formValue.checkInDate
            ? [
                  {
                      key: 'checkDates',
                      fields: ['checkInDate', 'checkOutDate'],
                  },
              ]
            : []),
        {
            key: 'dietaryRsvpComments',
            fields: ['dietary', 'rsvpComments'],
        },
        ...(toggles.gate.useNewPlanner
            ? [
                  {
                      key: 'attendanceType',
                      fields: ['attendanceType'],
                  },
              ]
            : []),
        {
            key: 'customQuestions',
            header: 'Custom RSVP Questions',
            fields: ['customQuestions'],
        },
    ];

    const onChange = ({ value: { dietary, ...newValue } }: { value: BizlyAPI.Attendee & { dietary: any } }) => {
        onChangeProp({ ...newValue, ...dietary });
    };

    return (
        <FormAny
            fields={fields}
            schema={readonly ? readOnlySchema : schema}
            value={formValue}
            readonly={readonly}
            errors={error ? { phone: true } : {}}
            onChange={onChange}
        />
    );
}

export default AttendeeForm;
