import React from 'react';

import styled from 'styled-components/macro';

import { Row, Button } from '../ui';
import { ReactComponent as CloseIcon } from '../images/icons/close.svg';
import colorFns from 'colorFns';

const TokenButton = styled(Button)`
    background-color: ${({ onClick, ...props }) => (onClick ? colorFns.venueSearchToken(props) : colorFns.grey(props))};
    ${({ onClick }) => (onClick ? '' : 'cursor: not-allowed;')}

    &:hover {
        background-color: ${({ onClick, ...props }) =>
            onClick ? colorFns.venueSearchTokenHover(props) : colorFns.grey(props)};
    }
`;

const TokenLabel = styled.span`
    font-size: 13px;
    font-weight: 500;
`;

const TokenClose = styled(CloseIcon)`
    width: 18px;
    height: 18px;
    margin-left: 10px;
`;

type TToken = { label: string; onRemove?: () => any };

const Token = ({ label, onRemove }: TToken) => (
    <TokenButton {...(onRemove ? { onClick: () => onRemove() } : {})}>
        <TokenLabel>{label}</TokenLabel>
        {onRemove && <TokenClose />}
    </TokenButton>
);

// negative margin to negate individual margins
const WrappingRow = styled(Row)`
    margin: -8px;
    flex-flow: wrap;

    > ${TokenButton} {
        margin: 8px;
    }
`;

export default function Tokenizer({ tokens }: { tokens: (TToken & { id: string | number })[] }) {
    return (
        <WrappingRow>
            {tokens.map(({ id, label, onRemove }) => (
                <Token key={id} label={label} onRemove={onRemove} />
            ))}
        </WrappingRow>
    );
}
