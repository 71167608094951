import queryString from 'query-string';

import { toMiles, takeFirstIfArray, mToKm, kmToM } from '../../util';

import { TFilterValue } from './VenueSearchFilters';
import { sliderValToGrades, gradesToSliderVal } from './VenueSearchGrades';

export type TPlaceQuery = Partial<{
    q: string; // semantic name of the place
    place_id: string; // google place id
    lat: number;
    lng: number;
}>;

type TGrades = BizlyAPI.Venue.Grades;
type TTypes = BizlyAPI.Venue.Types;
type TDecors = BizlyAPI.Venue.Decors;

// formats values from the URL params to the form data
export function queryParamsToFormFilters(params: string): TFilterValue {
    const { grades, types, decors } = queryString.parse(params);
    const { hideUngraded, preferredOnly } = queryString.parse(params, {
        parseBooleans: true,
    });
    const parsed = queryString.parse(params, { parseNumbers: true });
    const radius = parsed.radius as Nullable<Distance.Meter>;

    return {
        grades: grades ? gradesToSliderVal(toArray(grades as TGrades[])) : undefined,
        hideUngraded: hideUngraded ? true : undefined,
        types: types ? toArray(types as TTypes[]) : undefined,
        decors: decors ? toArray(decors as TDecors[]) : undefined,
        brandIds: parsed.brandIds ? toArray(parsed.brandIds as number[]) : undefined,
        preferredOnly: preferredOnly ? true : undefined,
        radius: radius && radius !== null && typeof radius === 'number' ? toMiles(radius) : undefined,
        radiusKm: radius && radius !== null && typeof radius === 'number' ? mToKm(radius) : undefined,
    };
}

// a "place" must have either the semantic name (q) and the place id or lat/lng
export function queryParamsToPlace(params: string) {
    const { q, place_id, lat, lng } = takeFirstIfArray(queryString.parse(params));

    if (!q) return undefined;

    const parsedLat = (lat && !isNaN(parseFloat(lat)) && parseFloat(lat)) || undefined;
    const parsedLng = (lng && !isNaN(parseFloat(lng)) && parseFloat(lng)) || undefined;

    const hasPlace = place_id || (parsedLat && parsedLng);
    if (!hasPlace) return undefined;

    return { q, place_id: place_id || undefined, lat: parsedLat, lng: parsedLng };
}

// converts form data and place query into URL params
export function toQueryParams(filters: TFilterValue & TPlaceQuery): BizlyAPI.VenueFacets & TPlaceQuery {
    const { grades, radius, radiusKm, ...applicableFilters } = filters;

    return {
        ...applicableFilters,
        radius: radiusKm ? kmToM(radiusKm === 150 ? (16000 as Distance.Kilometer) : radiusKm) : undefined,
        grades: grades ? sliderValToGrades(grades) : undefined,
    };
}

// converts an array or non-array value into an array
function toArray<T>(singleOrArray: T | Array<T>): Array<T> {
    return ([] as Array<T>).concat(singleOrArray);
}
