import React, { Component } from 'react';
import styled, { css } from 'styled-components/macro';
import { Link, withRouter } from 'react-router-dom';
import { isBefore, format, parseISO } from 'date-fns';
import { parse } from 'query-string';

import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { withStyles } from '@material-ui/core/styles';

import { getEvents } from '../api';
import {
    Column,
    Copy,
    Grid,
    HomeScreenBanner,
    Matchbox,
    MatchboxPlaceholder,
    Row,
    SpacedRow,
    Spacer,
    SpacedColumn,
    TernaryElement,
} from '../ui';

import { AvatarWithTooltip } from 'components/ui/Avatar';
import { Headline, LargeHeadline } from 'components/ui/Headline';
import SideNav from 'components/SideNav';

import { withUserContext } from 'providers/user';
import { padArray, parseName, randomSelect } from '../util';

import TemplatesCarousel from 'components/TemplatesCarousel';

import * as copy from 'copy/home.json';

import mixpanel from 'utils/mixpanel';

const sideWidth = 232;

const Shell = styled(Row)`
    height: 100%; /* TODO: another way to get side to cover full height */
    margin: 0;
`;

const Main = styled(Column)`
    width: 100%;
    max-width: 1788px; /* exactly 8 event tiles */
    border-radius: 12px;
    padding: 24px 94px;
    padding-left: ${sideWidth + 94}px;
    padding-bottom: 48px;
    box-sizing: border-box;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

    & > :last-child {
        padding-bottom: 128px; /* better create space for homescreenbanner */
    }
`;

const GriddedMatchbox = styled(Matchbox)`
    margin: 12px;
`;

const GriddedMatchboxPlaceholder = styled(MatchboxPlaceholder)`
    margin: 12px;
`;

type MatchboxProps = {
    readonly status: ReturnType<typeof eventStatus>;
};

const MatchboxTop = styled(SpacedColumn)<MatchboxProps>`
    padding: 12px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    height: 126px;
    border-radius: 12px 12px 0 0;
    background-color: ${({ status, theme: { getColor, EColors } }) =>
        status === 'completed'
            ? getColor(EColors.completedEvent)
            : status === 'dataEntered'
            ? getColor(EColors.activeEvent)
            : getColor(EColors.uneditedEvent)};
    ${GriddedMatchbox}:hover & {
        background-color: ${({ status, theme: { getColor, EColors } }) =>
            status === 'completed'
                ? getColor(EColors.completedEventHover)
                : status === 'dataEntered'
                ? getColor(EColors.activeEventHover)
                : getColor(EColors.uneditedEvent)};
    }

    transition: all 0.15s ease-out;
`;

const MatchboxBottom = styled(SpacedColumn)<MatchboxProps>`
    padding: 8px 12px;
    color: ${({ status, theme: { getColor, EColors } }) =>
        status === 'dataEntered' ? getColor(EColors.darkestGrey) : getColor(EColors.darkGrey)};
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    border-radius: 0 0 12px 12px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const LeftAlignedGrid = styled(Grid)`
    margin: -12px;

    justify-content: flex-start;
`;

const Divider = styled.div`
    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    margin-bottom: 48px;
`;

const BootstrapInput = withStyles(() => ({
    input: {
        borderBottom: 'none',
        padding: '0.5em',
    },
}))(InputBase);

const sortAlphabeticallyForProperty = (arr: any[], key: string) =>
    arr.slice().sort((a, b) => (!a[key] ? 1 : a[key].localeCompare(b[key], 'en', { sensitivity: 'base' })));

const sortByDate = (arr: any[]) =>
    arr.slice().sort((a, b) => {
        const aTime = a.startsAt ? parseISO(a.startsAt).getTime() : Infinity;
        const bTime = b.startsAt ? parseISO(b.startsAt).getTime() : Infinity;

        return aTime < bTime ? -1 : 1; // negative = prioritize aTime
    });

const sortEvents = (events: any[], method: string) => {
    switch (method) {
        case 'date':
            return sortByDate(events);
        case 'location':
            return sortAlphabeticallyForProperty(events, 'location');
        case 'eventTitle':
            return sortAlphabeticallyForProperty(events, 'name');
        default:
            return events;
    }
};

const eventStatus = (event: any) => {
    const { endsAt, name } = event;
    if (!endsAt && name === 'Untitled Meeting') {
        return 'initial';
    } else if (isBefore(parseISO(endsAt), new Date())) {
        return 'completed';
    } else {
        return 'dataEntered';
    }
};

const EventBox = (
    { event, user }: any // TODO: Consider splitting this component out into the components folder so that it can reduce noise in this file
) => (
    <Link to={`/event/${event.id}`} key={event.id}>
        <GriddedMatchbox>
            <MatchboxTop status={eventStatus(event)}>
                <SpacedRow>
                    <span style={{ fontSize: '22px' }}>
                        {event.startsAt && event.startsAt.toString() !== 'Invalid Date'
                            ? format(parseISO(event.startsAt), 'MMM do')
                            : ''}
                    </span>
                    {event.plannedBy.firstName !== user.firstName && event.plannedBy.lastName !== user.lastName && (
                        <AvatarWithTooltip user={event.plannedBy} />
                    )}
                </SpacedRow>
                <span>{event.name}</span>
            </MatchboxTop>
            <MatchboxBottom status={eventStatus(event)}>
                <span style={{ minHeight: '24px' }}>
                    {event.state && event.city ? `${event.city.name}, ${event.state.code}` : event.location || ''}
                </span>
                <span>{event.attendeeCounts.attending} Attending</span>
            </MatchboxBottom>
        </GriddedMatchbox>
    </Link>
);

export class HomeClass extends Component<any, any> {
    interval: any;
    nextButton: any;
    nextButtonClicked = false;

    state: any = {
        eventsFetchSuccess: null,
        activeEvents: [],
        pastEvents: [],
        upcomingEvent: {},
        sort: 'date',
    };

    async componentDidMount() {
        const { eventsFetchSuccess } = this.state;

        if (!eventsFetchSuccess) {
            const { success, events } = await getEvents();
            const now = Date.now();
            const sortedEvents: any = {
                activeEvents: [],
                pastEvents: [],
                incompleteEvents: [],
            };

            events.forEach((event: any) => {
                const { endsAt } = event;

                if (!endsAt) {
                    sortedEvents.incompleteEvents.unshift(event);
                } else {
                    const eventWorkflow = parseISO(endsAt).getTime() >= now ? 'activeEvents' : 'pastEvents';
                    sortedEvents[eventWorkflow].unshift(event);
                }
            });
            const { activeEvents, pastEvents, incompleteEvents } = sortedEvents;

            this.setState({
                eventsFetchSuccess: success,
                upcomingEvent: activeEvents[0] || {},
                activeEvents: activeEvents.concat(incompleteEvents),
                pastEvents,
            });
        }

        this.clickTemplatesNextButton();

        const { sso_auth: ssoAuth } = parse(this.props.location.search);
        if (ssoAuth) {
            mixpanel.track('Logged in with SSO');
        }
    }

    /* 
        TODO: Fix the bug where the initial click of the carousel next button in event templates gallery is not working properly.
        This is a quick workaround in the interest of releasing
    */
    clickTemplatesNextButton() {
        if (!this.nextButton && !this.interval) {
            this.interval = setInterval(() => {
                this.nextButton = window.document.getElementsByTagName('button')[1];
                if (
                    this.nextButton &&
                    !this.nextButtonClicked &&
                    this.nextButton.className.includes('Carousel__NextButton')
                ) {
                    this.nextButton.click();
                    clearInterval(this.interval);
                }
            }, 500);
        }
    }

    handleUserMenuClose() {
        this.setState({
            avatarAnchor: null,
        });
    }

    handleSortChange(newValue: any) {
        const { activeEvents, pastEvents } = this.state;
        this.setState({
            sort: newValue,
            activeEvents: sortEvents(activeEvents, newValue),
            pastEvents: sortEvents(pastEvents, newValue),
        });
    }

    hasEventsCopy: any = randomSelect(copy.heading.hasEventsCopy);

    parseHeading(userName: any) {
        const { activeEvents } = this.state;
        const { noEventsCopy, noEventsNoNameCopy } = copy.heading;
        let selectedCopy;

        if (activeEvents.length) {
            selectedCopy = this.hasEventsCopy;
        } else {
            selectedCopy = userName ? noEventsCopy : noEventsNoNameCopy;
        }

        return parseName(selectedCopy, userName);
    }

    parseEventsSubheading() {
        const { activeEvents, upcomingEvent } = this.state;
        const { hasEventsCopy, noUpcomingEventsCopy, noEventsCopy } = copy.subheading;
        let selectedCopy;

        if (upcomingEvent.startsAt) {
            selectedCopy = hasEventsCopy
                .replace('%C', activeEvents.length)
                .replace('%N', upcomingEvent.name || '')
                .replace('%D', format(parseISO(upcomingEvent.startsAt), 'MMMM do') || '');
        } else {
            selectedCopy = activeEvents.length ? noUpcomingEventsCopy : noEventsCopy;
        }

        return selectedCopy;
    }

    render() {
        const { eventsFetchSuccess, sort, activeEvents, pastEvents } = this.state;
        const hasEvents = !!activeEvents.length || !!pastEvents;
        const user = this.props.userContext.user;

        if (!eventsFetchSuccess) {
            return null;
        }

        return user.loaded ? (
            <>
                <Shell>
                    <SideNav />
                    <Main>
                        <Spacer />
                        <TernaryElement condition={hasEvents} trueElement={SpacedRow} fallbackElement="div">
                            <div>
                                <LargeHeadline
                                    css={css`
                                        text-transform: none;
                                    `}
                                >
                                    {this.parseHeading(user.firstName)}
                                </LargeHeadline>
                                <div style={{ width: '550px' }}>
                                    <Copy>{this.parseEventsSubheading()}</Copy>
                                </div>
                                <Spacer />
                            </div>
                        </TernaryElement>
                        <Spacer />
                        <TemplatesCarousel />
                        <Spacer />
                        <SpacedRow
                            css={css`
                                align-items: flex-end;
                            `}
                        >
                            <Headline>Active Meetings</Headline>
                            <FormControl>
                                <InputLabel
                                    style={{
                                        bottom: '32px',
                                        left: '8.5px',
                                        top: 'auto',
                                    }}
                                    htmlFor="event-list-sort"
                                >
                                    Sort by
                                </InputLabel>
                                <Select
                                    value={sort}
                                    onChange={e => this.handleSortChange(e.target.value)}
                                    style={{
                                        width: '120px',
                                    }}
                                    input={<BootstrapInput name="sort" id="sort-simple" />}
                                >
                                    {copy.dropdown.values.map(({ key, value, text }) => (
                                        <MenuItem key={key} value={value}>
                                            {text}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </SpacedRow>
                        <Divider />
                        <LeftAlignedGrid>
                            {/* Design calls for placeholder cells up to three rows in case a user has not created any events, so we use fill() */}
                            {padArray(activeEvents, 12).map((event: any) =>
                                !event ? (
                                    <GriddedMatchboxPlaceholder key={Math.random()} />
                                ) : (
                                    <EventBox key={event.id} event={event} user={user} />
                                )
                            )}
                        </LeftAlignedGrid>
                        {!!pastEvents.length && (
                            <>
                                <Headline>Past Meetings</Headline>
                                <Divider />
                                <LeftAlignedGrid>
                                    {/* Design calls for placeholder cells up to three rows in case a user has not created any events, so we use fill() */}
                                    {padArray(pastEvents, 12).map((event: any) =>
                                        !event ? (
                                            <GriddedMatchboxPlaceholder key={Math.random()} />
                                        ) : (
                                            <EventBox key={event.id} event={event} user={user} />
                                        )
                                    )}
                                </LeftAlignedGrid>
                            </>
                        )}
                    </Main>
                    {user.team.bulletinMessage && (
                        <HomeScreenBanner left={sideWidth}>
                            <img style={{ height: '26px' }} src={user.team.imageUrlWhite} alt="logo-white" />
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: user.team.bulletinMessage,
                                }}
                                style={{ marginLeft: 40 }}
                            />
                        </HomeScreenBanner>
                    )}
                </Shell>
            </>
        ) : null;
    }
}

export const OldHome = withRouter(withUserContext(HomeClass));
