import React from 'react';

import styled from 'styled-components';

import { Column, Spacer, Row, Button } from '../ui';
import { H3Headline } from './ui/Headline';

import { EColors } from 'theme';

const TemplatePopoverColumn = styled(Column)`
    width: 440px;
    max-width: 440px;

    padding: 32px;
    box-sizing: border-box;

    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const Description = styled.div`
    font-size: 15px;
    line-height: 1.47;

    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
`;

const ChipsRow = styled(Row)`
    margin: -4px;
    & > * {
        margin: 4px;
    }

    flex-wrap: wrap;
`;

const Chip = styled.div`
    line-height: 21px;
    font-size: 11px;
    font-weight: 500;

    white-space: nowrap;

    padding: 0 11px;
    text-align: center;

    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

    border-radius: 11px;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
`;

type TTemplatePopover = {
    template: Bizly.EventTemplate;
    onSelectTemplate: (templateId: number) => void;
};

export default function TemplatePopover({ template, onSelectTemplate }: TTemplatePopover) {
    const { id, name, shortDescription, tags } = template;

    return (
        <TemplatePopoverColumn>
            <H3Headline $color={EColors.accentedHeadline}>{name}</H3Headline>
            <Spacer xsmall />
            {tags && tags.length > 0 && (
                <ChipsRow>
                    {tags.map(({ id, name }) => (
                        <Chip key={id}>{name}</Chip>
                    ))}
                </ChipsRow>
            )}
            <Spacer />
            <Description>{shortDescription}</Description>

            <Spacer />
            <Row>
                <Button onClick={() => onSelectTemplate(id)}>Create Meeting</Button>
            </Row>
        </TemplatePopoverColumn>
    );
}
