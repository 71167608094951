import React from 'react';
import styled from 'styled-components/macro';

import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

import { LoadSchedule } from 'stores/schedule';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';

import { PageNotFound } from 'pages/PageNotFound';

import { Column, Row } from 'ui';
import EventHeader from 'components/EventHeader';
import { Spinner } from 'components/Spinner';
import { SIDE_NAV_WIDTH } from 'components/SideNav';
import VenueComponent from 'components/VenueComponent';

const CenteredRow = styled(Row)`
    justify-content: center;

    flex-grow: 1;
    flex-shrink: 0;
    min-height: 0;
`;

export const PAGE_PADDING = 36;

const Height100Column = styled(Column)`
    height: 100%;
`;

const FullWidthAndHeight = styled(Column)`
    flex: 1 1 0;
    display: flex;
    justify-content: center;

    width: 100%;
`;

const HideableColumn = styled(Column)<{ hidden: boolean }>`
    flex-shrink: 0;
    min-height: 100%;
    width: 100%;

    ${props =>
        props.hidden
            ? `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: -1;
    opacity: 0; /* without this, certain things still show */
    visibility: hidden; /* performance reasons */

    pointer-events: none
  `
            : ``}
`;

const EVENT_HEADER_BLACKLIST = ['/communication/*', '/guest-list/*', '/agenda/*'];

const Header = ({
    match,
    loading,
    noBackButton,
    pageOffset,
    maxSearchWidth,
}: {
    match: RouteComponentProps['match'];
    loading: boolean;
    noBackButton?: boolean;
    pageOffset?: number;
    maxSearchWidth?: number;
}) => (
    <Switch>
        {EVENT_HEADER_BLACKLIST.map(path => (
            <Route key={path} path={match.path + path} component={() => null} />
        ))}
        <Route
            render={props => (
                <EventHeader
                    loading={loading}
                    {...props}
                    noBackButton={noBackButton}
                    pageOffset={pageOffset}
                    isCreateMeeting
                    maxSearchWidth={maxSearchWidth}
                />
            )}
        />
    </Switch>
);

const Event = ({
    location,
    match,
    history,
    backgroundLocation,
}: RouteComponentProps<{ eventId: string }> & {
    backgroundLocation?: RouteComponentProps['location'] | false;
}) => {
    const { loading, event, error, suggestedVenues } = useEvent();
    const { toggles } = useUser();

    // let the foreground location handle loading/error
    if (backgroundLocation && (error || loading)) {
        return null;
    }

    if (error) {
        return <Route component={PageNotFound} />;
    }

    if (loading) {
        const content = (
            <Height100Column>
                <FullWidthAndHeight>
                    <Spinner />
                </FullWidthAndHeight>
            </Height100Column>
        );

        if (toggles.gate.createMeetingsFlow) {
            return (
                <FullWidthAndHeight>
                    <Spinner />
                </FullWidthAndHeight>
            );
        }

        return content;
    }

    const viewVenueListing = (venueId: number) =>
        history.push(`${match.url}/venue/listing/${venueId}`, {
            shouldGoBack: true,
            backgroundLocation: location,
        });
    const viewInquiries = () => history.push(`/events/${event.id}/venue/inquiries`);

    return (
        <HideableColumn hidden={Boolean(backgroundLocation)}>
            <Header
                match={match}
                loading={false}
                noBackButton={toggles.gate.createMeetingsFlow}
                pageOffset={toggles.gate.createMeetingsFlow ? SIDE_NAV_WIDTH : undefined}
                maxSearchWidth={toggles.gate.createMeetingsFlow ? 360 : undefined}
            />

            <CenteredRow>
                <LoadSchedule />

                <Switch {...(backgroundLocation ? { location: backgroundLocation } : {})}>
                    <Route path={[`${match.path}/venue`]}>
                        <VenueComponent
                            event={event}
                            suggestedVenues={suggestedVenues}
                            viewVenueListing={viewVenueListing}
                            viewInquiries={viewInquiries}
                            sideMargin={toggles.gate.createMeetingsFlow ? 68 : undefined}
                            pageOffset={toggles.gate.createMeetingsFlow ? SIDE_NAV_WIDTH : undefined}
                        />
                    </Route>
                </Switch>
            </CenteredRow>
        </HideableColumn>
    );
};

export default function EventPage(props: any) {
    const location = useLocation();
    const backgroundLocation = location.state && location.state.backgroundLocation;
    const history = useHistory<{ shouldGoBack?: boolean }>();
    const match = useRouteMatch();

    const locProps = { location, history, match };

    const content = (
        <>
            {/* We must be careful not to conditionally render this base event and just change it's props so we don't lose it's state */}
            <Event {...props} backgroundLocation={backgroundLocation} {...locProps} />
            {backgroundLocation && <Event {...props} {...locProps} />}
        </>
    );

    return content;
}
