import React from 'react';

import styled, { css } from 'styled-components/macro';

import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';

import { format, parseISO } from 'date-fns';
import { serializeDate, API_DATE_FORMAT } from 'utils/date_util';
import { formatCurrency, formatPercentage, formatDate, padArray, downloadFromUrl } from '../util';

import { getInquiry, getVenueInquiriesNotAdded, getVenueProposal, serializeDay, exportProposals } from '../api';
import { currentInquiryActions } from 'stores/current-inquiry';

import { EventContext } from '../providers/event';

import { Column, Copy, SmallerCopy, Row, SpacedRow, Spacer, InlineRow } from '../ui';
import colorFns from 'colorFns';
import fontFns from 'fontFns';

import Button from '../components/ui/Button';
import { Headline } from '../components/ui/Headline';
import VenueTile, { VenueTileEmpty, FixedRatio } from './VenueTile/VenueTile';

import ProposalAcceptModal from './ProposalAcceptModal';
import ProposalRejectModal from './ProposalRejectModal';
import InquiryDetailsModal from './InquiryDetailsModal';
import VenueCancelModal from './VenueCancelModal';
import { AreaUnit, useMeasurementUnits, units } from 'stores/measurement-units';
import { sqftToSqm } from 'utils/units';

const STATUS_TO_RANK: { [status: string]: number } = {
    Booked: 3,
    'Contract Pending': 2,
    'Proposal Received': 1,
    'Extension Pending': 1,
    'Proposal Expired': 1,
    Submitted: 0,
    'Extension Rejected': -1,
    'Proposal Rejected': -1,
    'Proposal Cancelled': -1,
    'Inquiry Rejected': -1,
    'Inquiry Cancelled': -1,
};

function sortGalleryInquiries(inquiries: any[]) {
    return inquiries.sort(
        (a, b) => (STATUS_TO_RANK[b.status as string] || 0) - (STATUS_TO_RANK[a.status as string] || 0)
    );
}

function inquiryToSchedule({ eventSpaces, guestrooms }: BizlyAPI.Inquiry) {
    const NO_DATE = '';

    const spacesWithDates = eventSpaces.map(eventSpace => {
        const isoDate = serializeDate(eventSpace.start);
        return {
            ...eventSpace,
            isoDate: isoDate ? format(isoDate, API_DATE_FORMAT) : NO_DATE,
        };
    });
    const roomsWithDate = guestrooms.map(guestroom => {
        const isoDate = serializeDate(guestroom.date);
        return {
            ...guestroom,
            isoDate: isoDate ? format(isoDate, API_DATE_FORMAT) : NO_DATE,
        };
    });

    const spacesByDate = groupBy(spacesWithDates, space => space.isoDate);
    const roomsByDate = groupBy(roomsWithDate, room => room.isoDate);

    const allIsoDates = [...new Set([...Object.keys(spacesByDate), ...Object.keys(roomsByDate)])];

    type ScheduleAgenda = {
        id: string;
        name?: string;
        start?: string;
        end?: string;
        roomSetup?: number;
        amenities: {
            av: number[];
            foodAndBeverage: { optionId?: number }[];
        };
    };

    type ScheduleGuestroom = { count?: number };

    type Day = {
        start?: string;
        agenda: ScheduleAgenda[];
        guestrooms: ScheduleGuestroom[];
    };

    type Schedule = Day[];

    const ZERO_ISO_TIME = 'T00:00:00';

    const schedule: Schedule = allIsoDates.map(isoDate => ({
        start: isoDate === NO_DATE ? undefined : isoDate + ZERO_ISO_TIME,
        agenda: (spacesByDate[isoDate] || []).map(item => ({
            ...item,
            roomSetup: item.setup?.id,
            amenities: {
                av: item.avNeeds.map(need => need.id),
                foodAndBeverage: item.fbNeeds.map(need => ({ optionId: need.id })),
            },
        })),
        guestrooms: roomsByDate[isoDate] || [],
    }));

    return schedule;
}

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
`;

const FormHeading = styled.h5`
    font-size: 18px;
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
`;

const VenueLabel = styled('label')<{ small?: boolean }>`
    font-size: ${props => (props.small ? '15px' : '18px')};
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel}
`;

const NoSpaceImage = styled.div`
    width: 100%;
    padding-top: calc(140 / 242 * 100%);
    border-radius: 8px;

    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackgroundNested)};

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
        content: 'No Image Available';
        font-size: 15px;
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        align-items: center;
        justify-content: center;
    }
`;

const EventSpaceHeader = styled.div`
    display: flex;
`;

const ProposalButtons = styled(Row)`
    position: sticky;
    width: 100%;
    height: 68px;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

    bottom: 0;
    align-items: center;
    justify-content: space-evenly;

    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};

    box-sizing: border-box;

    padding: 0;
`;

const GalleryRow = styled(Row)`
    margin: 40px -10px;
    width: calc(100% + 20px);
    flex-wrap: wrap;
`;

const GalleryItem = styled.div`
    position: relative;
    width: calc(25% - 20px);
    max-width: calc(25% - 20px);

    padding: 0 10px;
`;

const DayContainer = styled.section`
    background-color: ${colorFns.softAccentedBackground};

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    padding-bottom: 24px;
`;

const DayHeader = styled.div`
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: ${colorFns.agendaDayPane};
    color: ${colorFns.agendaDayPaneText};

    display: flex;
    justify-content: space-between;

    padding: 12px;
`;

const DayCol = styled(Column)`
    justify-content: center;
    margin: 0 13px;
`;

type RoundedImageProps = {
    url?: string;
    width: string | number;
    height: string | number;
    fixedRatio: string;
};

const RoundedImage = styled(FixedRatio)<Partial<RoundedImageProps>>`
    background-image: url(${props => props.url});
    background-size: cover;
`;

const Price = styled.div`
    font-size: 40px;
    font-weight: 300;
    line-height: 0.95;
    letter-spacing: -1.22px;
    color: ${colorFns.displayPricing};
`;

const Smaller = styled.span`
    font-size: 26px;
`;

const DaySubtotals = styled(Column)`
    position: sticky;
    bottom: 124px;

    margin: 12px 0;
    padding: 13px;

    background: ${colorFns.softAccentedBackground};
    border: 1px solid ${colorFns.softBorder};
    border-radius: 8px;
`;

const ProposalTotals = styled(Column)`
    height: 28px;

    position: sticky;
    bottom: 68px;

    margin: 12px 0;
    padding: 13px;

    background: ${colorFns.softAccentedBackground};
    border: 1px solid ${colorFns.softBorder};
    border-radius: 8px;
`;

const SubtotalHeading = styled.label`
    font-size: 18px;
    letter-spacing: -0.1px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
    text-align: center;
`;

const SubtotalAmount = styled(Copy)`
    text-align: right;
`;

const BigPrice = ({ label, price }: { label: React.ReactNode; price: React.ReactNode }) => (
    <Column fillWidth>
        <FormHeading as="label">{label}</FormHeading>
        <Price>{price}</Price>
    </Column>
);

const DataRow = ({ label, data }: { label: React.ReactNode; data?: React.ReactNode }) => (
    <SpacedRow>
        <VenueLabel small>{label}</VenueLabel>
        {data && <div>{data}</div>}
    </SpacedRow>
);

const grSubtotal = (guestroom: Bizly.VenueDayGR) => (guestroom?.quantity ? guestroom.rate * guestroom.quantity : null);
const esSubtotal = (eses: Bizly.EventSpace[], useDdr?: boolean) =>
    eses.length > 0
        ? eses.reduce((agg, cur) => {
              return agg + (useDdr ? (cur.ratePerPerson ?? 0) * (cur.minGuests ?? 0) : cur.fbMinimum + cur.rate);
          }, 0)
        : null;

function ProposalDay({
    proposal,
    day,
    idx,
    sizeUnit,
}: {
    proposal: Bizly.VenueProposal;
    day: Bizly.VenueDay;
    idx: number;
    sizeUnit: AreaUnit;
}) {
    const useDdr = !!proposal.useDdr;
    const currencyCode = proposal.currency?.code ?? 'USD';

    const guestRooms = day.guestRooms[0] && (
        <Column itemSpacing="default">
            <Column itemSpacing="default">
                <Copy>{day.guestRooms[0].quantity} guest rooms</Copy>

                {day.guestRooms[0].imageUrl ? (
                    <RoundedImage url={day.guestRooms[0].imageUrl} fixedRatio="calc(140 / 242 * 100%)" />
                ) : (
                    <NoSpaceImage />
                )}

                <BigPrice
                    label="Guest Room Rental"
                    price={
                        day.guestRooms[0].quantity === 0
                            ? '-'
                            : formatCurrency(day.guestRooms[0].subTotal, currencyCode)
                    }
                />

                <Column>
                    <SmallerCopy>
                        {proposal.commissionable ? 'Guestrooms are Commissionable.' : <>&nbsp;</>}
                    </SmallerCopy>
                    <SmallerCopy>Amount excludes taxes and fees.</SmallerCopy>
                </Column>
            </Column>
            <DataRow label="Room Name" data={day.guestRooms[0].name} />
            <Column itemSpacing="xsmall">
                <DataRow label="Room Rate" data={formatCurrency(day.guestRooms[0].rate, currencyCode)} />

                <Line />
                <DataRow label="Occupancy Tax" data={Number(proposal.occupancyTax) + '%'} />

                <Line />
                <DataRow label="Resort Fee" data={formatCurrency(proposal.resortFee, currencyCode)} />

                <Line />
            </Column>
        </Column>
    );

    const eventSpaces = day.eventSpaces.map(
        ({
            fbMinimum,
            rate,
            minGuests,
            ratePerPerson,
            requestedSpaceName,
            imageUrl: eventSpaceImageUrl,
            venueSpace,
            setup,
            guests,
            startsAt,
            endsAt,
        }) => (
            <Column itemSpacing="default">
                <Column itemSpacing="default">
                    <EventSpaceHeader>
                        <img src={setup.iconUrl} alt="meetingspace-icon" />
                        <Spacer xsmall />
                        <Copy>
                            {requestedSpaceName || setup.name}
                            <Spacer xsmall />
                            {formatDate(startsAt)} - {formatDate(endsAt)}
                            <Spacer xsmall />
                            {guests} attendees
                        </Copy>
                    </EventSpaceHeader>
                    {eventSpaceImageUrl ? (
                        <RoundedImage url={eventSpaceImageUrl} fixedRatio="calc(140 / 242 * 100%)" />
                    ) : (
                        <NoSpaceImage />
                    )}
                    {useDdr ? (
                        <>
                            <BigPrice
                                label="Min. Guests"
                                price={
                                    <>
                                        {minGuests ?? 0}
                                        <Smaller> guests</Smaller>
                                    </>
                                }
                            />
                            <BigPrice label="Per Person" price={formatCurrency(ratePerPerson ?? 0, currencyCode)} />
                        </>
                    ) : (
                        <>
                            <BigPrice label="F&B Min." price={formatCurrency(fbMinimum, currencyCode)} />
                            <BigPrice label="Room Rental" price={formatCurrency(rate, currencyCode)} />
                        </>
                    )}
                    <SmallerCopy>Amount excludes taxes and fees.</SmallerCopy>
                </Column>

                <Column itemSpacing="small">
                    <DataRow label="Room Name" data={venueSpace.name} />
                    <DataRow
                        label={`Room Size (${sizeUnit})`}
                        data={sizeUnit === units.sqm ? sqftToSqm(venueSpace.size) : venueSpace.size}
                    />
                    <DataRow label="Room Max Capacity" data={venueSpace.maxCapacity?.toLocaleString()} />
                </Column>

                <Column itemSpacing="xsmall">
                    <DataRow label="Service Charge" data={formatPercentage(proposal.serviceCharge)} />
                    <Line />
                    <DataRow label="Sales Tax" data={formatPercentage(proposal.salesTax)} />
                    <Line />
                    <DataRow label="Gratuity" data={formatPercentage(proposal.gratuity)} />
                </Column>
            </Column>
        )
    );

    const grSubtotalVal = grSubtotal(day.guestRooms[0]);
    const grSubtotalDisplay = grSubtotalVal === null ? '-' : formatCurrency(grSubtotalVal, currencyCode);
    const esSubtotalVal = esSubtotal(day.eventSpaces, useDdr);
    const esSubtotalDisplay = esSubtotalVal === null ? '-' : '+ ' + formatCurrency(esSubtotalVal, currencyCode);
    const totalDisplay = '= ' + formatCurrency((grSubtotalVal ?? 0) + (esSubtotalVal ?? 0), currencyCode);

    const daySubtotals = (
        <DaySubtotals itemSpacing="xsmall">
            <SubtotalHeading>Day {idx + 1} Min.</SubtotalHeading>
            <Row justifyContent="space-between">
                <VenueLabel small>Guest Rooms</VenueLabel>
                <SubtotalAmount>{grSubtotalDisplay}</SubtotalAmount>
            </Row>
            <Row justifyContent="space-between">
                <VenueLabel small>Event Spaces</VenueLabel>
                <SubtotalAmount>{esSubtotalDisplay}</SubtotalAmount>
            </Row>
            <Line />
            <SubtotalAmount>{totalDisplay}</SubtotalAmount>
        </DaySubtotals>
    );

    return (
        <>
            <Spacer />
            <DayHeader>
                <div>Day {idx + 1}</div>
                <div>{format(parseISO(day.day), 'iii, LLL dd, yyyy')}</div>
            </DayHeader>
            <Column itemSpacing="small">
                <DayContainer>
                    <DayCol itemSpacing="small">
                        {guestRooms}
                        {eventSpaces}
                    </DayCol>
                </DayContainer>
                {daySubtotals}
            </Column>
        </>
    );
}

const ProposalSummary = ({
    proposal,
    onAccept,
    onReject,
    readonly,
    sizeUnit,
    expired,
}: {
    proposal: Bizly.VenueProposal;
    onAccept: () => void;
    onReject: () => void;
    readonly?: boolean;
    sizeUnit: AreaUnit;
    expired?: boolean;
}) => {
    const useDdr = !!proposal.useDdr;
    const currencyCode = proposal.currency?.code;

    const grSubtotalVal = proposal.byDay.reduce((sum, day) => sum + (grSubtotal(day.guestRooms[0]) ?? 0), 0);
    const esSubtotalVal = proposal.byDay.reduce((sum, day) => sum + (esSubtotal(day.eventSpaces, useDdr) ?? 0), 0);
    const totalDisplay = '= ' + formatCurrency(grSubtotalVal + esSubtotalVal, currencyCode);

    return (
        <>
            {proposal.byDay.map((day, idx) => (
                <ProposalDay proposal={proposal} day={day} idx={idx} sizeUnit={sizeUnit} />
            ))}
            <ProposalTotals itemSpacing="xsmall">
                <Row justifyContent="space-between">
                    <VenueLabel small>Proposal Total</VenueLabel>
                    <SubtotalAmount>{totalDisplay}</SubtotalAmount>
                </Row>
            </ProposalTotals>
            {!readonly && (
                <ProposalButtons>
                    <Button onClick={onReject} warning>
                        Reject
                    </Button>
                    <Button onClick={onAccept} width={expired ? 150 : undefined}>
                        {expired ? 'Request Extension' : 'Accept'}
                    </Button>
                </ProposalButtons>
            )}
        </>
    );
};

function hasProposal(venue: Bizly.Venue) {
    return (
        [
            'Booked',
            'Contract Pending',
            'Proposal Received',
            'Proposal Expired',
            'Extension Pending',
            'Extension Rejected',
            'Proposal Rejected',
            'Proposal Cancelled',
            'Booking Cancelled',
        ].includes(venue.status) || venue.booking
    );
}

function hasInquiry(venue: Bizly.Venue) {
    return (
        hasProposal(venue) ||
        ['Submitted', 'Inquiry Cancelled', 'Inquiry Rejected'].includes(venue.status) ||
        venue.booking
    );
}

function proposalActive(venue: Bizly.Venue) {
    return ['Proposal Received', 'Proposal Expired', 'Extension Pending', 'Extension Rejected'].includes(venue.status);
}

function proposalExpired(venue: Bizly.Venue) {
    return ['Proposal Expired', 'Extension Pending', 'Extension Rejected'].includes(venue.status);
}

const VenueInquiry = ({
    viewProposal,
    submitKey,
    setVenueBooked,
    readonly,
}: {
    viewVenueListing: (venueId: number) => void;
    viewProposal: (proposalId: number) => void;
    submitKey: number;
    setVenueBooked: (booked: boolean) => void;
    readonly: boolean;
}) => {
    const { event, setCurrency } = React.useContext(EventContext);

    const { area: areaUnit } = useMeasurementUnits();

    const [inquiredVenues, setInquiredVenues] = React.useState<Bizly.Venue[]>([]);
    const onUpdateVenues = (venues: Bizly.Venue[]) => {
        setInquiredVenues(venues);
        currentInquiryActions.mergeVenues(event.id, venues);
    };
    const [acceptModalOpen, setAcceptModalOpen] = React.useState<Bizly.VenueProposal | undefined>();
    const [rejectModalOpen, setRejectModalOpen] = React.useState<number | undefined>();

    const [inquiryDetails, setInquiryDetails] = React.useState<
        | {
              schedule: any[];
              submittedAt?: string;
              notes?: string;
              datesFlexible?: boolean;
              rejectedAt?: string;
              rejectionReason?: string;
              rejectionNotes?: string;
          }
        | undefined
    >();
    const [showInquiryDetails, setShowInquiryDetails] = React.useState(false);

    const [venueDetails, setVenueDetails] = React.useState<{
        id: number;
        inquiryId: number;
    }>();

    const [venueProposals, setVenueProposals] = React.useState<{
        [id: number]: Bizly.VenueProposal;
    }>({});

    const [compareVenues, setCompareVenues] = React.useState(false);

    React.useEffect(() => {
        getVenueInquiriesNotAdded(event.id).then(venues => {
            setInquiredVenues(venues);
            getProposals(venues);
            if (venues.filter(venueInquiry => proposalActive(venueInquiry) && venueInquiry.proposalId).length > 1) {
                setCompareVenues(true);
            }
        });

        function getProposals(venues: Bizly.Venue[]) {
            const proposalsPromises = venues
                .filter(venue => proposalActive(venue))
                .map(venue => venue.proposalId && getVenueProposal(venue.proposalId));

            Promise.all(proposalsPromises).then(responses => {
                const proposals = responses.map(response => response.proposal);

                setVenueProposals(keyBy(proposals, 'id'));
            });
        }
    }, [event.id, submitKey]);

    const venueProposalsEmpty = isEmpty(venueProposals);

    function getProposalsSubmitted() {
        return inquiredVenues.filter(i => proposalActive(i) && i.proposalId);
    }

    const getMenuItems = (venue: Bizly.Venue) => [
        ...(hasProposal(venue)
            ? [
                  {
                      label: 'View Proposal',
                      handler: () => {
                          viewProposal(venue.proposalId!);
                      },
                  },
              ]
            : []),
        ...(hasInquiry(venue)
            ? [
                  {
                      label: 'Inquiry Details',
                      handler: async () => {
                          setShowInquiryDetails(true);
                          const { inquiry } = await getInquiry(venue.inquiryId!);
                          const venueInquiry = inquiry.venues.find(v => v.id === venue.id);

                          setInquiryDetails({
                              schedule:
                                  inquiry.scheduleFragment?.map(day => serializeDay(day)) ?? inquiryToSchedule(inquiry),
                              submittedAt: inquiry.submittedAt,
                              notes: inquiry.notes,
                              datesFlexible: inquiry.flexibleDates,
                              rejectedAt: venueInquiry?.rejectedAt || undefined,
                              rejectionReason: venueInquiry?.rejectionReason || undefined,
                              rejectionNotes: venueInquiry?.rejectionNotes || undefined,
                          });
                      },
                  },
              ]
            : []),
        ...(!readonly && proposalActive(venue)
            ? [
                  {
                      label: proposalExpired(venue) ? 'Request Extension' : 'Accept',
                      handler: () => {
                          setAcceptModalOpen(venueProposals[venue.proposalId!]);
                      },
                  },
                  {
                      label: 'Reject',
                      handler: () => {
                          setRejectModalOpen(venue.proposalId!);
                      },
                  },
              ]
            : []),
        ...(!readonly && ['Submitted'].includes(venue.status)
            ? [
                  {
                      label: 'Cancel',
                      handler: () =>
                          setVenueDetails(
                              venue as {
                                  id: number;
                                  inquiryId: number;
                              }
                          ),
                  },
              ]
            : []),
    ];

    async function handleExportClick() {
        downloadFromUrl(await exportProposals(event.id));
    }

    return (
        <>
            <main>
                <SpacedRow>
                    <Headline large>Your Inquiries</Headline>
                    <InlineRow itemSpacing="smallish">
                        {inquiredVenues.some(venue => hasProposal(venue)) && (
                            <Button onClick={handleExportClick} secondary width={140}>
                                Export Proposals
                            </Button>
                        )}
                        {getProposalsSubmitted().length > 1 && (
                            <Button onClick={() => setCompareVenues(!compareVenues)}>Compare</Button>
                        )}
                    </InlineRow>
                </SpacedRow>
                {!inquiredVenues.length && (
                    <Copy>
                        Your submitted inquiries will appear here and you’ll be able to view, accept and decline them.
                    </Copy>
                )}
                <GalleryRow>
                    {padArray(sortGalleryInquiries(inquiredVenues), 4).map((venue: Bizly.Venue | undefined) =>
                        venue ? (
                            <GalleryItem>
                                <Column
                                    css={css`
                                        width: 100%;
                                        ${compareVenues && `padding-bottom: 20px;`}
                                    `}
                                >
                                    <VenueTile
                                        key={venue.id}
                                        venue={{
                                            ...venue.venue,
                                            status:
                                                venue.booking && venue.status === 'Inquiry Cancelled'
                                                    ? 'Booking Cancelled'
                                                    : venue.status,
                                        }}
                                        format="thumb"
                                        pillType="inquiryStatus"
                                        width="100%"
                                        fixedRatio="calc(172 / 266 * 100%)"
                                        padding="0 0 24px 0"
                                        menuOptions={getMenuItems(venue)}
                                        onClick={hasProposal(venue) ? () => viewProposal(venue.proposalId!) : undefined}
                                    />
                                    {compareVenues && !venueProposalsEmpty && proposalActive(venue) ? (
                                        <ProposalSummary
                                            proposal={venueProposals[venue.proposalId!]}
                                            onAccept={() => setAcceptModalOpen(venueProposals[venue.proposalId!])}
                                            onReject={() => setRejectModalOpen(venue.proposalId!)}
                                            readonly={readonly}
                                            sizeUnit={areaUnit}
                                            expired={proposalExpired(venue)}
                                        />
                                    ) : null}
                                </Column>
                            </GalleryItem>
                        ) : (
                            <VenueTileEmpty width="25%" fixedRatio="calc(172 / 266 * 100%)" padding="0 10px" withPin />
                        )
                    )}
                </GalleryRow>
            </main>
            {acceptModalOpen && (
                <ProposalAcceptModal
                    open
                    closeModal={() => setAcceptModalOpen(undefined)}
                    eventId={event.id}
                    proposal={acceptModalOpen}
                    setVenueBooked={booked => {
                        acceptModalOpen && setCurrency(acceptModalOpen.currency);
                        setVenueBooked(booked);
                    }}
                />
            )}
            <ProposalRejectModal
                open={!!rejectModalOpen}
                closeModal={() => setRejectModalOpen(undefined)}
                eventId={event.id}
                proposalId={rejectModalOpen}
            />

            <VenueCancelModal
                open={!!venueDetails}
                closeModal={() => setVenueDetails(undefined)}
                venueDetails={venueDetails}
                inquiredVenues={inquiredVenues}
                onCancelled={onUpdateVenues}
            />
            {showInquiryDetails && (
                <InquiryDetailsModal
                    open
                    closeModal={() => {
                        setShowInquiryDetails(false);
                        setInquiryDetails(undefined);
                    }}
                    inquiryDetails={inquiryDetails}
                />
            )}
        </>
    );
};

export default VenueInquiry;
