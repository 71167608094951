import React from 'react';

import styled, { css } from 'styled-components/macro';

import { format, parseISO } from 'date-fns';

import { useEventOptions } from '../../providers/event-options';

import { timeOptions, DATE_USED_TO_FORMAT_TIME } from '../../utils/date_util';
import { Pane } from '../Pane';
import { Spinner } from '../Spinner';
import { AlignedRow, Column, Copy, Grid, Row, Spacer } from '../../ui';

import Dropdown from '../ui/Dropdown';
import CounterInput from '../ui/Input/CounterInput';

import IconToggleButton from './IconToggleButton';

import useThemedColor from 'hooks/useThemedColor';
import fontFns from 'fontFns';
import colorFns from 'colorFns';
import { SelectField } from 'components/FormFields';

const timeFormat = 'h:mm a';

const paneStyles = (borderColor: Themed.Color) => ({
    border: `1px solid ${borderColor}`,
    borderRadius: '12px 12px',
    marginBottom: '16px',
});

const iconToggleButtonStyles = {
    marginRight: '8px',
    marginBottom: '8px',
    width: '200px',
};

const AttendeeStepper = ({
    value,
    onChange,
    disabled,
}: {
    value: number;
    onChange: (newValue: number) => void;
    disabled: boolean;
}) => (
    <div
        css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        `}
    >
        <label
            css={css`
                margin: 10px 0 15px;
                font-size: 15px;
                line-height: 1;
                color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
                ${fontFns.formLabel}
            `}
        >
            No. of Attendees
        </label>
        <CounterInput disabled={disabled} count={value} min={1} onCountChange={onChange} />
    </div>
);

const FormLabel = styled(Copy)`
    color: ${colorFns.formLabel};
    ${fontFns.formLabel}
`;

const FormFieldLabel = styled(FormLabel)`
    margin: 10px 0 15px;
    font-size: 15px;
    line-height: 1;
`;

const SelectFormField = styled(SelectField)`
    margin: 0;
    width: 184px;
    max-height: 38px;
`;

type AgendaProps = {
    eventId: number;
    editable: boolean;
    onChange: (agendaItem: Bizly.AgendaItem) => void;
    beginExpanded: boolean;
    index: number;
    data: Bizly.AgendaItem;
    onRemove: (argument: unknown) => void;
};

const Agenda = (props: AgendaProps) => {
    const { softBorder, pureWhite } = useThemedColor();

    const options = useEventOptions();

    function updateAmenities(type: 'av' | 'foodAndBeverage', option: number | { optionId: number }, newValue: unknown) {
        const { data, onChange } = props;
        const relatedAmenities = data.amenities[type];
        const updated = {
            ...data,
            amenities: {
                ...data.amenities,
                [type]: newValue
                    ? (relatedAmenities as (number | { optionId: number })[]).concat(option)
                    : (relatedAmenities as (number | { optionId: number })[]).filter(o => o !== option),
            },
        };
        onChange && onChange(updated);
    }

    function handleDetailChange(key: string, newValue: unknown) {
        const { data, onChange } = props;
        const updated = {
            ...data,
            [key]: newValue,
        };
        onChange && onChange(updated);
    }

    function handleAudioVisualOptionToggle(option: number | { optionId: number }, newValue: unknown) {
        updateAmenities('av', option, newValue);
    }

    function handleFoodAndBeverageOptionToggle(optionId: number, isNowSelected: boolean) {
        const { data, onChange } = props;
        const relatedAmenities = data.amenities.foodAndBeverage;
        const updated = {
            ...data,
            amenities: {
                ...data.amenities,
                foodAndBeverage: isNowSelected
                    ? relatedAmenities.concat({ optionId, styleId: undefined })
                    : relatedAmenities.filter(amenity => amenity.optionId !== optionId),
            },
        };
        onChange && onChange(updated);
    }

    function handleDiningStyleChange(fbOptionId: number, diningStyleId: number) {
        const { data, onChange } = props;
        const relatedAmenities = data.amenities.foodAndBeverage;
        const updated = {
            ...data,
            amenities: {
                ...data.amenities,
                foodAndBeverage: relatedAmenities.map(amenity =>
                    amenity.optionId === fbOptionId ? { optionId: fbOptionId, styleId: diningStyleId } : amenity
                ),
            },
        };
        onChange && onChange(updated);
    }

    const { beginExpanded, index, data, onRemove, editable } = props;
    const { attendees, amenities, name, roomSetup, startTime: agStartTime, endTime: agEndTime } = data;

    if (!options) {
        return <Spinner />;
    }

    const { avOptions, fbOptions, setupOptions } = options;

    return (
        <Pane
            beginExpanded={beginExpanded}
            contentStyle={{
                backgroundColor: pureWhite,
            }}
            id={`space${index}`}
            label={name || `Meeting Space ${index + 1}`}
            onHeaderChange={editable ? newValue => handleDetailChange('name', newValue) : undefined}
            onRemove={onRemove}
            readOnly={!editable}
            placeholder="Name this meeting space"
            secondaryLabel={
                agStartTime && agEndTime
                    ? `${format(parseISO(`${DATE_USED_TO_FORMAT_TIME} ${agStartTime}`), timeFormat)} to ${format(
                          parseISO(`${DATE_USED_TO_FORMAT_TIME} ${agEndTime}`),
                          timeFormat
                      )}`
                    : ''
            }
            style={paneStyles(softBorder)}
            nested
        >
            {() => (
                <Column>
                    <AlignedRow>
                        <Dropdown
                            disabled={!editable}
                            value={timeOptions.find(t => t.value === agStartTime)?.name}
                            onClick={time => handleDetailChange('startTime', time.value)}
                            label="Start Time"
                            placeholder="Start Time"
                            options={timeOptions}
                        />
                        <Spacer />
                        <Dropdown
                            disabled={!editable}
                            value={timeOptions.find(t => t.value === agEndTime)?.name}
                            onClick={time => handleDetailChange('endTime', time.value)}
                            label="End Time"
                            placeholder="End Time"
                            options={timeOptions}
                        />
                        <Spacer />
                        <Column>
                            <FormFieldLabel>Room Setup</FormFieldLabel>
                            <SelectFormField
                                field=""
                                readonly={false}
                                disabled={!editable}
                                options={setupOptions.filter(setupOption => setupOption.id)}
                                value={roomSetup}
                                onChange={({ value: event }: any) => {
                                    // this will be fixed when form typings are out
                                    handleDetailChange('roomSetup', event.target.value);
                                }}
                                placeholder="Select setup"
                                overflowTruncate
                            />
                        </Column>
                        <Spacer />
                        <AttendeeStepper
                            value={attendees}
                            onChange={(newValue: number) => handleDetailChange('attendees', newValue)}
                            disabled={!editable}
                        />
                    </AlignedRow>
                    <Spacer />
                    <FormLabel>Audio/Visual</FormLabel>
                    <Spacer />
                    <Grid>
                        {avOptions.map(avOption => (
                            <IconToggleButton
                                readOnly={!editable}
                                iconUrl={avOption.iconUrl}
                                key={avOption.id}
                                aria-label={`audio-visual-option-${avOption.name}`}
                                label={avOption.name}
                                onChange={newValue => handleAudioVisualOptionToggle(avOption.id, newValue)}
                                style={iconToggleButtonStyles}
                                selected={amenities.av.includes(avOption.id)}
                            />
                        ))}
                    </Grid>
                    <Spacer />
                    <FormLabel>Food &amp; Beverage</FormLabel>
                    <Spacer />
                    <Grid>
                        {fbOptions.map(fbOption => {
                            const selectedAmenity = amenities.foodAndBeverage.find(
                                amenity => amenity.optionId === fbOption.id
                            );

                            return (
                                <IconToggleButton
                                    readOnly={!editable}
                                    iconUrl={fbOption.iconUrl}
                                    key={fbOption.id}
                                    aria-label={`food-beverage-option-${fbOption.name}`}
                                    label={fbOption.name}
                                    onChange={newValue => handleFoodAndBeverageOptionToggle(fbOption.id, newValue)}
                                    style={iconToggleButtonStyles}
                                    selected={!!selectedAmenity}
                                >
                                    {selectedAmenity && !!fbOption.diningStyles.length && (
                                        <>
                                            <Spacer small />
                                            <Row
                                                css={css`
                                                    width: 100%;
                                                    justify-content: center;

                                                    & li {
                                                        color: ${({ theme: { getColor, EColors } }) =>
                                                            getColor(EColors.darkerGrey)};
                                                    }

                                                    & label {
                                                        align-self: flex-start;
                                                        color: ${({ theme: { getColor, EColors } }) =>
                                                            getColor(EColors.formLabel)};
                                                        ${fontFns.formLabel}
                                                    }

                                                    & form {
                                                        width: 100%;
                                                    }
                                                `}
                                            >
                                                <Dropdown
                                                    disabled={!editable}
                                                    placeholder="Select"
                                                    label="Dining Style"
                                                    onClick={(newValue: { id: number }) => {
                                                        handleDiningStyleChange(fbOption.id, newValue.id);
                                                    }}
                                                    value={
                                                        fbOption.diningStyles.find(
                                                            diningStyle => diningStyle.id === selectedAmenity.styleId
                                                        )?.name
                                                    }
                                                    options={fbOption.diningStyles}
                                                />
                                            </Row>
                                        </>
                                    )}
                                </IconToggleButton>
                            );
                        })}
                    </Grid>
                </Column>
            )}
        </Pane>
    );
};

export default Agenda;
