import React from 'react';
import { Copy } from 'ui';

export const fields = {
    expiryDate: {
        type: 'date_outlined',
        perRow: '3',
        options: {
            minDate: Date.now(),
            format: 'MMM d, yyyy',
            parseFormat: 'yyyy-MM-dd',
            minDateMessage: 'Date cannot be in the past',
        },
    },
    expiryDateNote: {
        type: () => <Copy small>Provide the latest date you can hold this space.</Copy>,
        perRow: 2,
    },
    additionalDetails: {
        type: 'textarea',
        prompt: 'Additional Details',
        perRow: '2/3',
        options: {
            maxLength: 275,
        },
    },
    attachments: {
        type: 'upload_list',
        perRow: '3',
        options: {
            prompt: 'Add Attachment',
        },
    },
};

const expiryNested = (timeDisplay: string) => ({
    type: 'nested',
    prompt: 'Space Held Until',
    schema: [
        {
            fields: ['expiryDateNote'],
            spacing: false,
        },
        {
            fields: [
                'expiryDate',
                {
                    type: 'display',
                    value: timeDisplay,
                },
            ],
            spacing: false,
        },
    ],
});

export const schema = (timeDisplay: string) => [
    { key: 'expiryDate', fields: [expiryNested(timeDisplay)] },
    { key: 'additionalDetails', fields: ['additionalDetails'], spacing: 'default' },
    { key: 'attachments', fields: ['attachments'] },
];
