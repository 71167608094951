import React from 'react';
import styled from 'styled-components';

import usePersistentSnackbar from 'hooks/usePersistentSnackbar';

import { createPlaybookActions, useCreatePlaybook } from './store';

import { Column } from 'ui';
import { default as FormBase } from 'components/Form';
import Errors from './components/Errors';
import InternalDetailsForm from './InternalDetailsForm';
import TagsForm from './TagsForm';

const fields = {
    imageUrl: {
        type: 'image_uploader',
        prompt: 'Cover Photo',
        options: {
            heightRatio: '40%',
        },
    },
    name: {
        prompt: 'What would you like to call this playbook?',
        type: 'text',
        perRow: '2/3',
        options: {},
    },
    description: {
        prompt: 'Description',
        type: 'textarea',
        options: {
            rows: 3,
            rowsMax: 5,
        },
    },
};

const schema = [
    {
        fields: ['name'],
        spacing: 'default',
    },
    {
        fields: ['description'],
        spacing: 'default',
    },
    { fields: ['imageUrl'], itemSpacing: 'larger', spacing: false },
];

export type TBasicInfoValue = Partial<{
    imageUrl: string;
    name: string;
    description: string;
}>;

const Form = styled(FormBase)`
    width: 100%;
`;

export default function BasicInfoForm() {
    const { basicInfo, basicInfoErrors, internalDetails, stepIdx } = useCreatePlaybook();

    const enqueueSnackbar = usePersistentSnackbar([basicInfoErrors, stepIdx]);
    React.useEffect(() => {
        const hasErrors = Object.values(basicInfoErrors).some(v => v);
        if (hasErrors) {
            enqueueSnackbar(<Errors errors={basicInfoErrors} />, { variant: 'error' });
        }
    }, [basicInfoErrors, enqueueSnackbar]);

    return (
        <Column itemSpacing="large">
            <Form
                fields={fields}
                schema={schema}
                value={basicInfo}
                onChange={({ value }: { value: TBasicInfoValue }) => {
                    createPlaybookActions.setBasicForm(value);
                    createPlaybookActions.setBasicFormErrors({});
                }}
            />
            <InternalDetailsForm value={internalDetails} />
            <TagsForm />
        </Column>
    );
}
