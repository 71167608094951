import React, { useState, CSSProperties } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import colorFns from 'colorFns';
import { Column, SpacedColumn, Spacer, AlignedRow } from 'ui';
import ThemedLogo from 'components/ui/ThemedLogo';
import Avatar from 'components/ui/Avatar';
import UserMenu from 'components/UserMenu';
import { useUser } from 'providers/user';
import { parseName } from '../util';
import { ReactComponent as HomeIconSvg } from 'images/icons/home.svg';
import { ReactComponent as CalendarIconSvg } from 'images/icons/calendar2.svg';
import MuiSearchIcon from '@material-ui/icons/Search';
import { ReactComponent as PlaybookIconSvg } from 'images/icons/playbook.svg';
import { Transition } from 'react-spring/renderprops';
import usePrevious from 'hooks/usePrevious';

export const SIDE_NAV_WIDTH = 280;

const ContentsWrapper = styled.div<{
    fillWidth?: boolean;
    fillHeight?: boolean;
    fullWidth?: boolean;
    fullHeight?: boolean;
}>`
    position: relative;
    margin-left: ${SIDE_NAV_WIDTH}px;

    max-width: 1694px; /* Exactly 8 event tiles */
    padding: 24px 94px;
    padding-bottom: 48px;
    padding-bottom: 128px; /* Space for homescreenbanner */

    box-sizing: border-box;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    ${({ fillWidth }) =>
        fillWidth
            ? `
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: calc(100% - ${SIDE_NAV_WIDTH}px);
    min-height: calc(100vh);
    `
            : ''}

    ${({ fillHeight }) =>
        fillHeight
            ? `
    padding-bottom: 24px;
    padding-top: 0;
    overflow: auto;
    `
            : ''}

    ${({ fullWidth }) =>
        fullWidth
            ? `
    padding-left: 0;
    padding-right: 0;`
            : ''}

    ${({ fullHeight }) =>
        fullHeight
            ? `
    padding-top: 0;
    padding-bottom: 0;
    `
            : ''}
`;

const Side = styled(SpacedColumn)`
    position: fixed;
    width: ${SIDE_NAV_WIDTH}px;
    height: 100%;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    padding: 36px;
    padding-top: 40px;
    box-sizing: border-box;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.brand)};
    z-index: 200;
    overflow: hidden;
`;

const HomeIcon = styled(HomeIconSvg)<{ active?: boolean }>`
    height: 16px;
    width: 15px;
    color: ${colorFns.pureWhite};
    fill: ${colorFns.pureWhite};
    margin-left: 18px;
    margin-right: 9px;

    ${props =>
        props.active &&
        `         
         fill: ${colorFns.brand({ theme: props.theme })};
    `}
`;

const CalendarIcon = styled(CalendarIconSvg)<{ active?: boolean }>`
    height: 16px;
    width: 15px;
    margin-left: 18px;
    margin-right: 9px;
    color: ${colorFns.pureWhite};

    ${props =>
        props.active &&
        `         
        color: ${colorFns.brand({ theme: props.theme })} !important;
    `}
`;

const SearchIcon = styled(MuiSearchIcon)<{ active?: boolean }>`
    height: 18px;
    width: 18px;
    margin-left: 18px;
    margin-right: 9px;
    color: ${colorFns.pureWhite};

    ${props =>
        props.active &&
        `         
        color: ${colorFns.brand({ theme: props.theme })} !important;
    `}
`;

const PlaybookIcon = styled(PlaybookIconSvg)<{ active?: boolean }>`
    height: 16px;
    width: 15px;
    margin-left: 18px;
    margin-right: 9px;
    color: ${colorFns.pureWhite};

    ${props =>
        props.active &&
        `         
        color: ${colorFns.brand({ theme: props.theme })} !important;
    `}
`;

const SidebarLink = styled.span<{ active?: boolean; icon?: string }>`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    cursor: pointer;
    width: calc(${SIDE_NAV_WIDTH}px - 72px);
    display: block;
    padding: 11px 0;
    display: flex;
    align-items: center;

    ${props =>
        props.active &&
        `
        background:#fff;
        color:${colorFns.brand(props)};
        font-weight:600;
        border-radius:8px;
        box-shadow: 0px 2px 4px 0px ${props.theme.getColor(props.theme.EColors.brand)}; 
    `}
`;

const HOME_PATH = '/',
    EVENTS_PATH = '/events',
    SEARCH_PATH = '/search',
    PLAYBOOKS_PATH = '/playbooks';

type TPaths = typeof HOME_PATH | typeof EVENTS_PATH | typeof SEARCH_PATH | typeof PLAYBOOKS_PATH;

const AnimatedCol = styled(Column)<{ left?: number }>`
    position: ${({ left }) => (left ? 'absolute' : 'relative')};
    top: 0;
`;

export default function SideNav({
    fillWidth,
    fillHeight,
    fullWidth,
    fullHeight,
    hideUser,
    routeChildren,
    children,
    className,
    style,
}: {
    fillWidth?: boolean;
    fillHeight?: boolean;
    fullWidth?: boolean;
    fullHeight?: boolean;
    hideUser?: boolean;
    routeChildren?: React.ReactNode;
    children?: React.ReactNode;
    className?: string;
    style?: CSSProperties;
}) {
    const [avatarAnchor, setAvatarAnchor] = useState<React.ReactNode | null>(null);
    const { user, toggles } = useUser();

    const RouteButtons = ({ path }: { path?: TPaths }) => (
        <>
            <Spacer small />
            <Link to={HOME_PATH}>
                <SidebarLink active={path === HOME_PATH}>
                    <HomeIcon active={path === HOME_PATH} />
                    <span>{toggles.gate.useTemplatesV2 ? 'Home' : 'My Meetings'}</span>
                </SidebarLink>
            </Link>
            {toggles.gate.useTemplatesV2 && (
                <>
                    <Link to={EVENTS_PATH}>
                        <SidebarLink active={path === EVENTS_PATH}>
                            <CalendarIcon active={path === EVENTS_PATH} />
                            My Meetings
                        </SidebarLink>
                    </Link>
                    <Link to={SEARCH_PATH}>
                        <SidebarLink active={path === SEARCH_PATH}>
                            <SearchIcon active={path === SEARCH_PATH} />
                            Search
                        </SidebarLink>
                    </Link>
                    <Link to={PLAYBOOKS_PATH}>
                        <SidebarLink active={path === PLAYBOOKS_PATH}>
                            <PlaybookIcon active={path === PLAYBOOKS_PATH} />
                            Playbooks
                        </SidebarLink>
                    </Link>
                </>
            )}
        </>
    );

    const DefaultRouteButtons = (
        <Switch>
            <Route path={`/`} exact>
                <RouteButtons path={HOME_PATH} />
            </Route>
            <Route path={[`/events`, `/events/unscheduled`, `/events/drafts`]} exact>
                <RouteButtons path={EVENTS_PATH} />
            </Route>
            <Route path={[`/search`]} exact>
                <RouteButtons path={SEARCH_PATH} />
            </Route>
            <Route path={`/playbooks`} exact>
                <RouteButtons path={PLAYBOOKS_PATH} />
            </Route>
            <Route>
                <RouteButtons />
            </Route>
        </Switch>
    );

    const prev = usePrevious(routeChildren);

    const buttons = (
        <div style={{ position: 'relative' }}>
            <Transition
                items={!!routeChildren}
                initial={{ left: 0 }}
                from={{ left: 150 }}
                enter={{ left: 0 }}
                leave={{ left: 150 }}
            >
                {show => {
                    const getForwardStyle = ({ left }: { left?: number }) => ({
                        transform: `translate3d(${left ?? 0}%, 0, 0)`,
                    });

                    const getReverseStyle = ({ left }: { left?: number }) => ({
                        transform: `translate3d(${0 - (left ?? 0)}%, 0, 0)`,
                    });

                    const Forward = (props: { left?: number }) => (
                        <AnimatedCol
                            {...props}
                            style={getForwardStyle(props)}
                            itemSpacing="medium"
                            children={routeChildren || prev}
                        />
                    );
                    const Reverse = (props: { left?: number; children?: React.ReactNode }) => (
                        <AnimatedCol {...props} style={getReverseStyle(props)} itemSpacing="medium" />
                    );

                    return show
                        ? props => <Forward {...props} />
                        : props => <Reverse {...props}>{DefaultRouteButtons}</Reverse>;
                }}
            </Transition>
        </div>
    );

    return (
        <>
            <Side>
                <Column itemSpacing="medium">
                    <Link to={HOME_PATH}>
                        <ThemedLogo />
                    </Link>
                    {buttons}
                </Column>
                {!hideUser && (
                    <AlignedRow>
                        <Avatar user={user} onClick={e => setAvatarAnchor(avatarAnchor ? null : e.target)} />
                        <UserMenu
                            label={user.firstName ? parseName('Hi, %N', user.firstName) : 'Hi, user'}
                            style={{ marginLeft: '8px' }}
                        />
                    </AlignedRow>
                )}
            </Side>
            {children && (
                <ContentsWrapper
                    fillWidth={fillWidth}
                    fillHeight={fillHeight}
                    fullWidth={fullWidth}
                    fullHeight={fullHeight}
                    className={className}
                    style={style}
                >
                    {
                        children // TODO: Only necessary while supporting OldHome page
                    }
                </ContentsWrapper>
            )}
        </>
    );
}
