import React from 'react';
import styled, { css } from 'styled-components';

import {
    createPlaybookActions,
    useCreatePlaybook,
    ESteps,
    stepList,
    selCurStep,
    stepToTitle as stepToName,
} from './store';

import GenericNavButtons from 'components/GenericNavButtons';

import { ReactComponent as OverviewIconSvg } from 'images/icons/overview.svg';
import { ReactComponent as CheckIconSvg } from 'images/icons/check-mark.svg';
import { ReactComponent as VenuesIconSvg } from 'images/icons/venue_pin.svg';
import { ReactComponent as InquiryIconSvg } from 'images/icons/registration.svg';
import { ReactComponent as CommunicationIconSvg } from 'images/icons/envelope.svg';
import { ReactComponent as CollaborationIconSvg } from 'images/icons/guests.svg';
import colorFns from 'colorFns';

const iconStyles = css<{ active?: boolean }>`
    height: 16px;
    width: 15px;
    color: ${colorFns.pureWhite};
    fill: ${colorFns.pureWhite};
    margin-left: 18px;
    margin-right: 9px;
`;

const OverviewIcon = styled(OverviewIconSvg)`
    ${iconStyles}
`;
const CheckIcon = styled(CheckIconSvg)`
    ${iconStyles}
`;
const VenuesIcon = styled(VenuesIconSvg)`
    ${iconStyles}
`;
const InquiryIcon = styled(InquiryIconSvg)`
    ${iconStyles}
`;
const CollaborationIcon = styled(CollaborationIconSvg)`
    ${iconStyles}
`;
const CommunicationIcon = styled(CommunicationIconSvg)`
    ${iconStyles}
`;

const stepToIcon = {
    [ESteps.basic]: OverviewIcon,
    [ESteps.venues]: VenuesIcon,
    [ESteps.inquiry]: InquiryIcon,
    [ESteps.communication]: CommunicationIcon,
    [ESteps.collaboration]: CollaborationIcon,
    [ESteps.preview]: CheckIcon,
};

export default function NavButtons() {
    const curStep = useCreatePlaybook(selCurStep);
    const { stepIdx: curStepIdx, id: playbookId } = useCreatePlaybook();

    const linksList = stepList.map((step, stepIdx) => {
        const Icon = stepToIcon[step];
        const name = stepToName[step];

        const disabled = stepIdx > curStepIdx;

        return {
            key: name,
            active: curStep === step,
            disabled,
            onClick: disabled ? () => {} : () => createPlaybookActions.goToStep(step),
            children: (
                <>
                    <Icon active={curStep === step} />
                    {name}
                </>
            ),
        };
    });

    return <GenericNavButtons backTo={`/playbooks/${playbookId}`} links={linksList} />;
}
