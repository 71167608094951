import React from 'react';

import { useSnackbar } from 'notistack';

import { meetingsActions } from './store';

import moment from 'moment';

export default function LoadMeetings({ date, limit }: { date?: Date; limit?: number }) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        try {
            meetingsActions.load(
                date
                    ? moment(date)
                          .startOf('week')
                          .toDate()
                    : undefined,
                limit
            );
        } catch (e) {
            enqueueSnackbar('Something went wrong. Please try again.', { variant: 'error' });
        }
    }, [date, limit, enqueueSnackbar]);

    return null;
}
