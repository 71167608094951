import React, { useMemo } from 'react';
import styled from 'styled-components/macro';

import { EColors } from 'theme';
import { withIconStyles } from 'shared';

import { H2Headline, H3Headline } from '../ui/Headline';
import { Column, Row, Spacer, Copy, ExternalLink } from '../../ui';
import { ReactComponent as LocationPinIcon } from '../../images/icons/location_pin.svg';
import DinovaLogo from '../../images/icons/dinova-logo.png';
import { ReactComponent as GatherLogo } from '../../images/icons/gather_logo.svg';

import { useUser } from '../../providers/user';

import VenueMap from '../VenueMap';
import VenueSpaceThumb from './VenueSpaceThumb';
import VenueFeatures, { getVenueFeatures } from './VenueFeatures';
import { ERankColors, DEFAULT_PREFERENCE_COLOR } from '../VenueTile/VenuePill';

const PreferenceNotesBox = styled(Column)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackground)};
    border-radius: 8px;

    overflow: hidden;
`;
const PreferenceNotesHeader = styled(Row)`
    height: 60px;
    padding: 0 16px;
    box-sizing: border-box;

    justify-content: space-between;
    align-items: center;

    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.teamPreferenceNotesHeader)};
`;
const PreferenceNotes = styled(Column)`
    padding: 32px;

    white-space: pre-wrap;

    p {
        line-height: 1.47;
        margin-top: 8px;
        margin-bottom: 8px;

        &:first-child {
            margin-top: 0;
        }
    }
    strong {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.preferenceNotesHighlightedText)};
    }
`;

const PreferenceInfo = ({ venue }: { venue: Bizly.Venue }) => {
    const { user } = useUser();
    const teamLogo = useMemo(() => (user && user.team ? user.team.imageUrlWhite : ''), [user]);

    return (
        <PreferenceNotesBox>
            <PreferenceNotesHeader>
                <WhiteCopy>{venue.preferenceCategory.name}</WhiteCopy>
                {teamLogo && <img src={teamLogo} alt="team-logo" style={{ height: 26 }} />}
            </PreferenceNotesHeader>
            <PreferenceNotes
                dangerouslySetInnerHTML={{
                    __html: venue.preferenceNotes,
                }}
            />
        </PreferenceNotesBox>
    );
};

const DoubleSpanColumn = styled(Column)`
    flex: 2 0 0;
`;
const SingleSpanColumn = styled(Column)`
    flex: 1 0 0;
`;
const FullHeightItemsRow = styled(Row)`
    align-items: stretch;
`;
const SpacedItemsRow = styled(Row)<{ spacing: number }>`
    align-items: center;
    & > *:not(:last-child) {
        margin-right: ${props => props.spacing || 0}px;
    }
`;

const Brand = styled(Copy)`
    font-size: 13px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
`;
const PropertyId = styled(Copy)`
    font-size: 13px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkGrey)};
`;
const Capacity = styled(Copy)`
    font-size: 20px;
    font-weight: bold;
    line-height: 0.8;
    letter-spacing: -0.11px;
`;
const Neighborhood = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
    margin-left: 41px;
`; // 41px matches icon + 12px

const WhiteCopy = styled(Copy)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
`;

const Summary = styled(Copy)`
    font-size: 18px;
    line-height: 1.5;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
`;

const Chip = styled(Copy)`
    font-size: 11px;
    font-weight: 500;
    line-height: 1.18;
    text-transform: uppercase;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

    padding: 4px 12px;
    border-radius: 14px;
`;
const TempClosedChip = styled(Chip)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.cancelledOrRejectedStatus)};
`;
const PreferenceChip = styled(Chip)<{ rank: number }>`
    background-color: ${({ rank, theme: { getColor } }) =>
        getColor(ERankColors[('r' + rank) as keyof typeof ERankColors] || DEFAULT_PREFERENCE_COLOR)};
`;
const TagChip = styled(Chip)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.tagColor)};
`;
const RestaurantTagChip = styled(Chip)`
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.restaurantTagColor)};
`;

const LocationPin = styled(withIconStyles(LocationPinIcon))`
    min-width: 29px;
`;

const FittedVenueMap = styled(VenueMap)`
    position: relative;
    width: 100%;
    height: 100%;

    min-height: 220px;
    max-height: 440px;
`;

const VenueListingInfo = ({ venue, spaces }: { venue: Bizly.Venue; spaces: Bizly.Space[] }) => {
    const center = useMemo(() => ({ lat: venue.lat, lng: venue.lng }), [venue.lat, venue.lng]);
    const hasSpaces = useMemo(() => spaces.length > 0, [spaces]);
    const hasPreferenceCategory = useMemo(() => venue.preferred && venue.preferenceCategory, [
        venue.preferred,
        venue.preferenceCategory,
    ]);
    const hasFeatures = useMemo(() => getVenueFeatures(venue).length > 0, [venue]);

    // 2 is restaurant
    const ChipComponent = useMemo(() => (venue.type && venue.type.id === 2 ? RestaurantTagChip : TagChip), [
        venue.type,
    ]);

    if (!venue.id) {
        return null;
    }

    return (
        <Column style={{ flex: '1 0 auto' }}>
            <FullHeightItemsRow>
                <DoubleSpanColumn>
                    {venue.headline && (
                        <>
                            <H2Headline $color={EColors.accentedHeadline}>{`${venue.headline}.`}</H2Headline>
                            <Spacer small />
                        </>
                    )}
                    <SpacedItemsRow spacing={16}>
                        {venue.brand && <Brand>{`Brand: ${venue.brand.name}`}</Brand>}
                        <PropertyId>{`Property ID #${venue.id}`}</PropertyId>
                    </SpacedItemsRow>
                    <Spacer small />

                    {(venue.maxCapacity ||
                        venue.maxCapacity === 0 ||
                        venue.roomCount ||
                        venue.roomCount === 0 ||
                        venue.dinova) && (
                        <>
                            <Spacer larger />
                            <SpacedItemsRow spacing={32}>
                                {venue.maxCapacity && <Capacity>{`${venue.maxCapacity} Guest Max`}</Capacity>}
                                {venue.roomCount && <Capacity>{`${venue.roomCount} Spaces`}</Capacity>}
                                {venue.dinova && <img src={DinovaLogo} style={{ height: 21 }} alt="dinova-logo" />}
                                {venue.gather && <GatherLogo style={{ height: 21 }} />}
                            </SpacedItemsRow>
                        </>
                    )}

                    {venue.summary && (
                        <>
                            <Spacer larger />
                            <Summary
                                dangerouslySetInnerHTML={{
                                    __html: venue.summary,
                                }}
                            />
                        </>
                    )}

                    {venue.externalUrl && (
                        <>
                            <Spacer larger />
                            <Copy $color={EColors.darkerGrey}>
                                {`Please use internal system to reserve: `}
                                <ExternalLink href={venue.externalUrl} target="_blank" rel="noopener noreferrer">
                                    {venue.externalUrl}
                                </ExternalLink>
                            </Copy>
                        </>
                    )}

                    {(hasPreferenceCategory ||
                        venue.tempClosed ||
                        venue.primaryDecor ||
                        venue.secondaryDecor ||
                        venue.primaryCuisine ||
                        venue.secondaryCuisine ||
                        venue.qualityLevel ||
                        venue.type) && (
                        <>
                            <Spacer />
                            <SpacedItemsRow spacing={12}>
                                {venue.tempClosed && <TempClosedChip>{'Temporarily Closed'}</TempClosedChip>}
                                {hasPreferenceCategory && (
                                    <PreferenceChip rank={venue.preferenceCategory.rank}>
                                        {venue.preferenceCategory.name}
                                    </PreferenceChip>
                                )}
                                {venue.primaryDecor && <ChipComponent>{venue.primaryDecor.name}</ChipComponent>}
                                {venue.secondaryDecor && <ChipComponent>{venue.secondaryDecor.name}</ChipComponent>}
                                {venue.primaryCuisine && <ChipComponent>{venue.primaryCuisine.name}</ChipComponent>}
                                {venue.secondaryCuisine && <ChipComponent>{venue.secondaryCuisine.name}</ChipComponent>}
                                {venue.qualityLevel && <ChipComponent>{venue.qualityLevel}</ChipComponent>}
                                {venue.type && <ChipComponent>{venue.type.name}</ChipComponent>}
                                <Spacer />
                            </SpacedItemsRow>
                        </>
                    )}

                    {hasFeatures && (
                        <Column>
                            <Spacer larger />
                            <H3Headline $color={EColors.accentedHeadline}>This Venue Features:</H3Headline>
                            <Spacer />
                            <VenueFeatures venue={venue} />
                        </Column>
                    )}

                    {!hasSpaces && hasPreferenceCategory && (
                        <>
                            <Spacer />
                            <Column style={{ flex: '1 0 auto' }}>
                                <PreferenceInfo venue={venue} />
                            </Column>
                        </>
                    )}
                </DoubleSpanColumn>

                <Spacer />

                <SingleSpanColumn>
                    <SpacedItemsRow spacing={12}>
                        <SpacedItemsRow spacing={12}>
                            <LocationPin />
                            <H3Headline $color={EColors.darkestGrey}>{venue.address}</H3Headline>
                        </SpacedItemsRow>

                        {venue.neighborhood && (
                            <>
                                <Spacer small />
                                <Neighborhood>{venue.neighborhood}</Neighborhood>
                            </>
                        )}
                    </SpacedItemsRow>
                    <Spacer small />
                    <Spacer larger />

                    <FittedVenueMap center={center} venues={[venue]} />
                </SingleSpanColumn>
            </FullHeightItemsRow>

            {hasSpaces && (
                <Column>
                    <Spacer medium />
                    <Spacer larger />
                    <H2Headline $color={EColors.darkestGrey}>Featured Spaces</H2Headline>
                    <Spacer larger />
                    <Row>
                        <DoubleSpanColumn>
                            {spaces.map(s => (
                                <VenueSpaceThumb
                                    key={s.id}
                                    fixedRatio={hasPreferenceCategory ? 'calc(69%)' : 'calc(69% / 2)'}
                                    space={s}
                                    perRow={hasPreferenceCategory ? 1 : 2}
                                />
                            ))}
                        </DoubleSpanColumn>

                        {hasPreferenceCategory && (
                            <>
                                <Spacer />
                                <SingleSpanColumn>
                                    <PreferenceInfo venue={venue} />
                                </SingleSpanColumn>
                            </>
                        )}
                    </Row>
                </Column>
            )}
        </Column>
    );
};

export default VenueListingInfo;
