import React, { useState } from 'react';
import styled from 'styled-components/macro';
import range from 'lodash/range';
import memoize from 'lodash/memoize';

import { format as dateFnsFormat, parseISO, parse } from 'date-fns';

import { SpacedRow, Grid, LabeledCheckbox, Copy, Row, AlignedRow, Column, TruncatingSingleLineCopy } from '../ui';
import DatePicker from '../components/ui/DatePicker';
import { Uploader } from '../components/Uploader';
import { ImageUploader } from '../components/ImageUploader';
import MuiMenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiTextField from '@material-ui/core/TextField';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import { List, ListItem, ListItemText, Slider as MuiSlider } from '@material-ui/core';
import MuiSelect from '@material-ui/core/Select';
import CounterInput from './ui/Input/CounterInput';

import { ReactComponent as DownArrow } from '../images/icons/down-arrow.svg';
import { ReactComponent as CheckMark } from '../images/icons/check-mark.svg';
import { withInteractibleIconStyles } from 'shared';

import { EColors } from 'theme';
import useThemedColor from 'hooks/useThemedColor';

const PER_ROW = {
    default: '0',
    auto: 'auto',
    1: '100%',
    2: '50%',
    '2/3': '66.66%',
    3: '33.33%',
    4: '25%',
    5: '20%',
    6: '16.66%',
};

const perRowToPercentage = perRow => {
    if (perRow !== 0 && !perRow) {
        return perRow;
    }

    const [items, spaces] = typeof perRow === 'string' ? perRow.split('/') : [1, perRow];
    return ((items / spaces) * 100).toFixed(2) + '%';
};

export const perRowStyles = perRow => `
    flex-basis: ${PER_ROW[perRow] || perRowToPercentage(perRow) || PER_ROW.default};
  
    ${
        !perRow || perRow === 'default'
            ? `
    flex-grow: 1;
    min-width: 0;
    `
            : ``
    };
  `;

export const fixedWidthStyles = fixedWidth => `
    flex-basis: ${fixedWidth}px;
    flex-grow: 0;
    flex-shrink: 0;
    min-width: ${fixedWidth}px;
`;

const denseMargin = `
  margin-top: 8px;
  margin-bottom: 4px;
`;

// NONE FIELD
// when you want to define a field as none
export const NoneField = () => null;

// DISPLAY FIELD
// when you want to define a field as none
const SingleLineRow = styled(Row)`
    align-items: center;
    max-height: 19px;
`;
const FittedImageContainer = styled.div`
    display: flex;
    min-width: 43px;
    max-width: 43px;
    max-height: 19px;
    height: 19px;
    align-items: center;
`;

const FittedImage = styled.img`
    width: 24px;
`;

const TruncatableCopy = styled(({ overflowTruncate, ...props }) => {
    const CopyComponent = overflowTruncate ? TruncatingSingleLineCopy : Copy;

    return <CopyComponent {...props} />;
})`
    line-height: 19px;
`;

const LabelWithIcon = ({ icon, iconUrl, iconAlt, label, doubleHeight, overflowTruncate }) => (
    <SingleLineRow doubleHeight={doubleHeight}>
        {(iconUrl || icon) && (
            <FittedImageContainer doubleHeight={doubleHeight}>
                {iconUrl ? <FittedImage src={iconUrl} alt={iconAlt} doubleHeight={doubleHeight} /> : icon}
            </FittedImageContainer>
        )}
        <TruncatableCopy overflowTruncate={overflowTruncate}>{label}</TruncatableCopy>
    </SingleLineRow>
);
export const DisplayField = ({
    iconUrl = undefined,
    iconAlt = undefined,
    label = undefined,
    value = undefined,
    formatter = val => val,
    className = '',
}) => (
    <FormTextFieldReadonly className={className}>
        <LabelWithIcon iconUrl={iconUrl} iconAlt={iconAlt} label={label || formatter(value)} />
    </FormTextFieldReadonly>
);

// CHECKBOX
const LabeledRadio = ({ disabled, isChecked, label, labelPlacement, onChange, className }) => (
    <FormControlLabel
        className={className}
        label={label}
        control={<Radio color="primary" disabled={disabled} checked={isChecked} onChange={onChange} />}
        labelPlacement={labelPlacement}
    />
);

const CheckboxField = styled(FormControlLabel)`
    margin: 0;
    margin-left: -10px;
    ${denseMargin}
    .MuiCheckbox-root {
        padding: 8px;
    }
`;

// This is a special field that displays a radio select with "Yes" and "No"
// 3 possible values: null for no value, true/false or valueAs/null if value is selected
const YES = 'Yes';
const NO = 'No';

const RadioCheckboxControl = styled(LabeledRadio)`
    margin: 0;
    ${({ perRow }) => perRowStyles(perRow)}
    .MuiIconButton-root {
        padding: 8px;
    }
`;

export const RadioCheckbox = ({ value: valueProp = null, onChange: onChangeProp, valueAs, disabled }) => {
    const value = valueProp === true ? YES : valueProp === false ? NO : null;

    return (
        <Row>
            <RadioCheckboxControl
                label={YES}
                disabled={disabled}
                isChecked={value === YES}
                onChange={evt => onChangeProp(evt, true)}
            />
            <RadioCheckboxControl
                label={NO}
                disabled={disabled}
                isChecked={value === NO}
                onChange={evt => onChangeProp(evt, false)}
            />
        </Row>
    );
};

export const Checkbox = ({ field, value, readonly, disabled, onChange, label, asRadio, placeholder }) => {
    const props = {
        field,
        disabled,

        value,
        checked: value,
        onChange: (evt, checked) => {
            onChange({
                field,
                value: checked,
                errors: null,
            });
        },
    };

    const radio = <RadioCheckbox {...props} />;
    const checkbox = <MuiCheckbox color="primary" {...props} />;

    if (readonly) {
        return props.checked ? (
            <DisplayField label={label} />
        ) : placeholder ? (
            <DisplayField label={placeholder} />
        ) : null;
    }

    return <CheckboxField label={label} control={asRadio ? radio : checkbox} />;
};

// CHECKBOX GRID
const ControlsGrid = styled(Grid)`
    flex-flow: row wrap;
    justify-content: flex-start;

    flex: 1 0 0;
    min-height: 0;
    flex-basis: auto;
`;

const griddedStyles = `
    margin: 0 ;
    transform: translate(-10px);
  `;

const getOptionVals = option =>
    typeof option === 'string' || typeof option === 'number'
        ? {
              optionKey: option,
              optionLabel: option,
          }
        : {
              ...option,
              optionKey: option.id,
              optionLabel: option.name || option.label,
          };

const GriddedCheckbox = styled(LabeledCheckbox)`
    ${griddedStyles}
    ${({ perRow }) => perRowStyles(perRow)}
`;

const MultiselectListItem = styled(ListItem)`
    ${({ densePadding }) => (densePadding ? 'padding: 0;' : 'padding: 16px 0 0 0;')}
`;

export const Multiselect = ({
    field,
    value: val,
    disabled,
    readonly,
    densePadding,
    onChange,
    perRow,
    options,
    placeholder = '',
    customIcon,
    customIconChecked,
}) => {
    const value = val || [];

    if (readonly) {
        const valuesSet = new Set(value.map(getOptionVals).map(o => o.optionKey));

        let selectedOptions = options.map(getOptionVals).filter(({ optionKey }) => valuesSet.has(optionKey));

        if (selectedOptions.length === 0 && placeholder) {
            selectedOptions = [{ optionLabel: placeholder }];
        }

        return (
            <List disablePadding={densePadding}>
                {selectedOptions.map(({ optionKey, optionLabel }, index) => (
                    <MultiselectListItem key={optionKey} densePadding={densePadding}>
                        <ListItemText primary={optionLabel} />
                    </MultiselectListItem>
                ))}
            </List>
        );
    }
    return (
        <ControlsGrid>
            {options.map(option => {
                const { optionKey, optionLabel } = getOptionVals(option);

                return (
                    <GriddedCheckbox
                        key={optionKey}
                        label={optionLabel}
                        perRow={perRow}
                        disabled={disabled}
                        isChecked={value.includes(optionKey)}
                        customIcon={customIcon}
                        customIconChecked={customIconChecked}
                        onChange={(evt, checked) => {
                            onChange({
                                field,
                                value: checked ? [...value, optionKey] : value.filter(v => v !== optionKey),
                                errors: null,
                            });
                        }}
                    />
                );
            })}
        </ControlsGrid>
    );
};

// RADIO GRID
const GriddedRadio = styled(LabeledRadio)`
    ${griddedStyles}
    ${({ perRow }) => perRowStyles(perRow)}
    ${({ noTranslation }) => (noTranslation ? 'transform: unset' : '')}
`;

export const RadioSelect = ({
    field,
    value,
    readonly,
    densePadding,
    onChange,
    perRow,
    options,
    noTranslation,
    placeholder = '',
    ...props
}) => {
    if (readonly) {
        return <FormTextFieldReadonly densePadding={densePadding}>{value || placeholder}</FormTextFieldReadonly>;
    }
    return (
        <ControlsGrid>
            {options.map(option => {
                const { optionKey, optionLabel } = getOptionVals(option);

                return (
                    <GriddedRadio
                        {...props}
                        key={optionKey}
                        label={optionLabel}
                        perRow={perRow}
                        noTranslation={noTranslation}
                        isChecked={value === optionKey}
                        onChange={() =>
                            onChange({
                                field,
                                value: optionKey,
                                errors: null,
                            })
                        }
                    />
                );
            })}
        </ControlsGrid>
    );
};

const FlexSpan = styled.span`
    flex-basis: 0;
    text-align: center;
`;

export const LinearScaleField = ({ options, ...props }) => {
    const { lowLabel, highLabel, ...ranges } = options;

    const buildOptions = memoize(
        ({ lowValue = 0, highValue = 10 }) => {
            const numbers = range(lowValue, highValue + 1);
            return numbers.map(value => ({
                id: value,
                name: `${value}`,
            }));
        },
        ({ lowValue = 0, highValue = 10 }) => `${lowValue},${highValue}`
    );

    return (
        <AlignedRow itemSpacing="xsmall">
            {!props.readonly && lowLabel && <FlexSpan>{lowLabel}</FlexSpan>}
            <RadioSelect {...props} options={buildOptions(ranges)} labelPlacement="bottom" noTranslation />
            {!props.readonly && highLabel && <FlexSpan>{highLabel}</FlexSpan>}
        </AlignedRow>
    );
};

const getBasicOnChange = (field, onChange) => val =>
    onChange({
        field,
        value: val,
        errors: null,
    });

// SELECT
export const FormTextFieldBorders = ({ theme: { getColor, EColors } }) => `
    &.MuiOutlinedInput-root.Mui-disabled,
    .MuiOutlinedInput-root.Mui-disabled {
        opacity: 0.7;

        fieldset.MuiOutlinedInput-notchedOutline {
            border-color: ${getColor(EColors.lightGrey)} !important;
            box-shadow: 0 0 0 0 !important;
        } 
    }

    &.MuiOutlinedInput-root:hover > fieldset,
    .MuiOutlinedInput-root:hover > fieldset, 

    .MuiOutlinedInput-notchedOutline {
        border-color: ${getColor(EColors.lightGrey)};
    }
`;

const Select = styled(MuiSelect)`
    ${denseMargin};

    .MuiSelect-root {
        background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)} !important;
        border-radius: 8px;
        padding: 10.5px 30px 10.5px 14px;
    }

    .MuiSelect-icon {
        top: unset;
        right: 19px;
    }

    ${FormTextFieldBorders};
`;

const MenuItem = styled(MuiMenuItem)`
    padding: 10.5px 19px 10.5px 14px;

    &:hover,
    &.Mui-focusVisible {
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.dropdownItemHover)} !important;
    }

    &.Mui-selected {
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.dropdownItem)};
    }
`;

const RightCheckMark = styled(withInteractibleIconStyles(CheckMark))`
    position: absolute;
    right: 19px;
    top: 50%;
    transform: translateY(-50%);
`;

const Emphasize = styled.div`
    font-weight: 600;
    width: 0;
    flex-grow: 1;
`;

const NOTHING_SELECTED = '';

export const SelectField = ({
    field,
    value,
    readonly,
    disabled,
    onChange,
    options,
    placeholder = '',
    className = '',
    overflowTruncate = false,
    valueAsOption = false,
    autoFocusOptionKey = '',
}) => {
    const container = React.useRef();
    const standardized = {
        value: value === null || value === undefined ? getOptionVals('') : getOptionVals(value),
        options: options.map(getOptionVals),
    };
    const selectedOption =
        value !== null && value !== undefined
            ? standardized.options.find(o => o.optionKey === standardized.value.optionKey) || standardized.value
            : standardized.value;

    const fieldValue =
        value === null || value === undefined ? NOTHING_SELECTED : valueAsOption ? selectedOption : value;

    const selectedOptionDisplayProps = {
        iconUrl: selectedOption.iconUrl,
        iconAlt: selectedOption.optionLabel,
        label: selectedOption.label || selectedOption.optionLabel,
    };

    return readonly ? (
        <DisplayField {...selectedOptionDisplayProps} className={className} />
    ) : (
        <Select
            ref={container}
            className={className}
            MenuProps={{
                anchorEl: () => container.current,
                getContentAnchorEl: null,

                transformOrigin: { vertical: -8, horizontal: 'left' },
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                MenuListProps: { disablePadding: true },

                style: { maxHeight: 340 },
            }}
            variant="outlined"
            value={fieldValue}
            onChange={getBasicOnChange(field, onChange)}
            disabled={disabled}
            displayEmpty={placeholder}
            IconComponent={DownArrow}
            renderValue={optionKey =>
                optionKey === NOTHING_SELECTED ? (
                    <TruncatableCopy overflowTruncate={overflowTruncate} $color={EColors.darkGrey}>
                        {placeholder}
                    </TruncatableCopy>
                ) : (
                    <LabelWithIcon {...selectedOptionDisplayProps} overflowTruncate={overflowTruncate} />
                )
            }
        >
            {standardized.options.map(option => {
                const { optionKey, optionLabel, iconUrl, icon, doubleHeight, emphasize } = option;
                const optionVal = valueAsOption ? option : optionKey;
                return (
                    <MenuItem key={optionKey} value={optionVal} autoFocus={optionKey === autoFocusOptionKey}>
                        <LabelWithIcon
                            icon={icon}
                            iconUrl={iconUrl}
                            iconAlt={optionLabel}
                            label={
                                <>
                                    {emphasize ? <Emphasize>{optionLabel}</Emphasize> : optionLabel}
                                    {fieldValue === optionVal && <RightCheckMark />}
                                </>
                            }
                            doubleHeight={doubleHeight}
                            overflowTruncate={overflowTruncate}
                        />
                    </MenuItem>
                );
            })}
        </Select>
    );
};

// DATE PICKER
// undefined value causes it to be current day
export const DatePickerField = ({ field, value = null, readonly, densePadding, onChange, placeholder = '' }) =>
    readonly ? (
        <FormTextFieldReadonly densePadding={densePadding}>
            {value ? dateFnsFormat(parseISO(value), 'MMM d, yyyy') : placeholder}
        </FormTextFieldReadonly>
    ) : (
        <MuiDatePicker
            margin="dense"
            variant="outlined"
            value={value}
            clearable
            onChange={getBasicOnChange(field, onChange)}
        />
    );

// DATE PICKER OUTLINED
// undefined value causes it to be current day
export const DatePickerOutlined = styled(DatePicker)`
    input {
        font-size: 16px !important;
        min-height: 38px;
        width: 100%;
    }

    &:hover input {
        border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack, 0.23)};
    }
`;

export const DatePickerOutlinedField = ({
    field,
    value = null,
    readonly,
    densePadding,
    onChange,
    disabled,
    placeholder,
    minDate,
    maxDate,
    minDateMessage,
    datesToDisable = [],
    format = 'MMMM do',
    parseFormat,
}) => {
    const fieldValue = parseFormat && typeof value === 'string' ? parse(value, parseFormat, new Date()) : value;

    return readonly ? (
        <FormTextFieldReadonly densePadding={densePadding}>
            {fieldValue ? dateFnsFormat(fieldValue, format) : ''}
        </FormTextFieldReadonly>
    ) : (
        <DatePickerOutlined
            margin="dense"
            format={format}
            placeholder={placeholder}
            minDate={minDate}
            maxDate={maxDate}
            minDateMessage={minDateMessage}
            shouldDisableDate={newDateObj => {
                const currDateObj = parseFormat ? dateFnsFormat(newDateObj, 'yyyy-MM-dd') : newDateObj;
                return currDateObj !== value && datesToDisable.includes(currDateObj);
            }}
            onChange={newDateObj =>
                onChange({
                    field,
                    value: parseFormat ? dateFnsFormat(newDateObj, 'yyyy-MM-dd') : newDateObj,
                    errors: null,
                })
            }
            value={fieldValue}
            disabled={disabled}
        />
    );
};

// TEXT FIELD
const TextField = React.forwardRef(({ value, onChange, maxLength, inputProps = {}, ...props }, ref) => {
    return (
        <MuiTextField
            inputRef={ref}
            onChange={e => onChange(e.target.value)}
            margin="dense"
            value={value || ''}
            variant="outlined"
            inputProps={{ maxLength, ...inputProps }}
            {...props}
        />
    );
});

const FormTextFieldLabel = styled(Copy)`
    flex: ${props => props.labelSpan || 1} 0 0;
    margin-right: 16px;
`;

export const FormTextField = styled(TextField)`
    flex: ${props => props.inputSpan || 1} 0 0;
    pointer-events: ${props => (props.disabled ? 'none' : 'inherit')};

    ${FormTextFieldBorders}

    background: ${({
        disabled,
        theme: {
            getColor,
            EColors: { disabledAction, pureWhite },
        },
    }) => getColor(disabled ? disabledAction : pureWhite)};
    border-radius: 8px;
`;

const FormTextFieldReadonly = styled(Copy)`
    line-height: 19px;
    padding: ${props => (props.densePadding ? '0' : '10.5px')} 0;
    ${denseMargin}
    ${props => (props.color ? 'color: ' + props.color : '')};
`;

const getBasicOnChangeInteger = (field, onChange, saveCursor = () => {}, resetCursor = () => {}) => val => {
    const isValid = val === '' || val.match(/^\d+$/g);
    const int = parseInt(val);
    if (isValid) {
        saveCursor(null);
        return onChange({
            field,
            value: int,
            errors: null,
        });
    } else {
        resetCursor();
    }
};

const getBasicOnChangePercentage = (
    field,
    onChange,
    fractionLimit = 10,
    saveCursor = () => {},
    resetCursor = () => {}
) => val => {
    const isValid = val === '' || val.match(new RegExp(`^(\\d*)$|^(\\d+\\.{1}\\d{0,${fractionLimit}})$`, 'g'));
    if (isValid) {
        saveCursor(null);
        return onChange({
            field,
            value: val,
            errors: null,
        });
    } else {
        resetCursor();
    }
};

const getBasicOnBlurPercentage = (field, onChange) => e => {
    const val = e.target.value;
    const newVal = val.match(/^(\d+.)$/g) ? val.replace('.', '') : val;

    if (newVal !== val)
        return onChange({
            field,
            value: newVal,
            errors: null,
        });
};

export const LabeledTextField = ({
    field,
    value,
    readonly = undefined,
    densePadding = undefined,
    onChange,
    label = undefined,
    labelSpan = undefined,
    inputSpan = undefined,
    placeholder = '',
    currency = 'USD',
    numberType = undefined,
    ...props
}) => {
    const [focus, setFocus] = React.useState(false);
    const [range, setSelectionRange] = React.useState([0, 0]);
    const resetCursor = () => {
        setTimeout(() => {
            range && inputRef.current.setSelectionRange(range[0], range[1]);
        }, 0);
    };

    const saveCursor = element => {
        setSelectionRange(element ? [element.selectionStart, element.selectionEnd] : null);
    };

    const inputRef = React.useRef();

    let valueFormatter = val => val;
    let defaultPlaceholder;
    let onFieldChange = getBasicOnChange(field, onChange);
    let onFieldFocus = () => {};
    let onFieldBlur = () => {};
    let onKeyPress = numberType ? e => saveCursor(e.target) : () => {};

    const onFocus = e => {
        setFocus(true);
        resetCursor();
        onFieldFocus(e);
        return props.onFocus?.(e);
    };
    const onBlur = e => {
        setFocus(false);
        saveCursor(document.activeElement === e.target ? e.target : null);
        onFieldBlur(e);
        return props.onBlur?.(e);
    };

    if (numberType === 'currency') {
        const userLocale = Intl.NumberFormat().resolvedOptions().locale;
        const currencyFormatter = new Intl.NumberFormat(userLocale, {
            style: 'currency',
            currency,
            currencyDisplay: 'symbol',
        });
        const decimals = currencyFormatter.resolvedOptions().maximumFractionDigits;
        const currencySymbol = new Intl.NumberFormat(userLocale, {
            style: 'currency',
            currency,
            currencyDisplay: 'symbol',
            maximumSignificantDigits: 1,
        })
            .format(0)
            .replace('0', '');
        defaultPlaceholder = currencySymbol || '';

        valueFormatter = val => (!focus && (val || val === 0) ? currencyFormatter.format(val) : val);

        onFieldChange = getBasicOnChangePercentage(field, onChange, decimals, saveCursor, resetCursor);

        const fixFractions = () => {
            const float = parseFloat(value);
            const isNumber = !Number.isNaN(float);
            const isFloat = isNumber && !Number.isInteger(float);
            const fixed = isFloat ? float.toFixed(decimals) : value;

            if (fixed !== float) {
                return onChange({
                    field,
                    value: fixed,
                    errors: null,
                });
            }
        };
        onFieldBlur = fixFractions;
    }
    if (numberType === 'percentage') {
        defaultPlaceholder = '%';
        valueFormatter = val => (!focus && (val || val === 0) ? val + '%' : val);
        onFieldChange = getBasicOnChangePercentage(field, onChange, 10, saveCursor, resetCursor);
        onFieldBlur = getBasicOnBlurPercentage(field, onChange);
    }
    if (numberType === 'integer') {
        valueFormatter = val => (val || val === 0 ? val.toString() : '');
        onFieldChange = getBasicOnChangeInteger(field, onChange, saveCursor, resetCursor);
    }

    return readonly ? (
        <FormTextFieldReadonly densePadding={densePadding}>
            {valueFormatter(value) || placeholder || defaultPlaceholder}
        </FormTextFieldReadonly>
    ) : (
        <SpacedRow>
            {label && <FormTextFieldLabel labelSpan={labelSpan}>{label}</FormTextFieldLabel>}
            <FormTextField
                {...props}
                inputSpan={inputSpan}
                placeholder={placeholder || defaultPlaceholder}
                value={valueFormatter(value)}
                onChange={onFieldChange}
                onFocus={onFocus}
                onBlur={onBlur}
                ref={inputRef}
                onKeyPress={onKeyPress}
            />
        </SpacedRow>
    );
};

// TEXT AREA FIELD
const RelativeRow = styled(Row)`
    position: relative;
`;
const MaxLengthCopy = styled(Copy)`
    position: absolute;
    bottom: 8px;
    right: 8px;
`;
export const TextArea = ({
    field,
    value,
    readonly,
    densePadding,
    onChange,
    disabled,
    maxLength,
    rows = 4,
    rowsMax = 8,
    placeholder = '',
}) => {
    const { darkGrey, black } = useThemedColor();
    return readonly ? (
        <FormTextFieldReadonly densePadding={densePadding} color={value ? black : darkGrey}>
            {value || placeholder || ''}
        </FormTextFieldReadonly>
    ) : (
        <RelativeRow>
            <FormTextField
                multiline
                rows={rows}
                rowsMax={rowsMax}
                placeholder={placeholder}
                value={value}
                maxLength={maxLength}
                onChange={getBasicOnChange(field, onChange)}
                disabled={disabled}
            />
            {maxLength !== undefined && (
                <MaxLengthCopy small color={darkGrey}>
                    {maxLength - (value?.length || 0)}
                </MaxLengthCopy>
            )}
        </RelativeRow>
    );
};

// SLIDER FIELD
const markLabelStyles = `
  color: ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
  top: -12px;
  transform: translateX(-50%) translateY(-100%);
  font-size: 15px;
`;
const markLabelActiveStyles = `
  color: currentColor
`;

const AirbnbSlider = styled(MuiSlider)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
    height: 3px;
    padding: 13px 0;
    margin-top: 25px;
    margin-bottom: 0;

    .MuiSlider-thumb {
        height: 28px;
        width: 28px;
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
        border: 1px solid currentColor;
        margin-top: -12px;
        margin-left: -13px;
    }
    .MuiSlider-valueLabel {
        left: calc(-50% + 4px);
    }
    .MuiSlider-track {
        height: 3px;
    }
    .MuiSlider-rail {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
        opacity: 1;
        height: 3px;
    }
    .MuiSlider-mark {
        background-color: transparent;
    }
    .MuiSlider-markLabel {
        ${markLabelStyles}
    }
    .MuiSlider-markLabelActive {
        ${markLabelActiveStyles}
    }
`;

const ThumbBar = styled.span`
    height: 10px;
    width: 1px;
    background-color: currentColor;
    margin-left: 1.5px;
    margin-right: 1.5px;
`;

const ValueLabel = styled.span`
    position: absolute;
    left: 50%;
    white-space: nowrap;
    ${markLabelStyles}
    ${markLabelActiveStyles}
`;

const AirbnbThumbComponent = ({ valueLabelFormat, marks = [] }) => {
    const marksSet = new Set(marks.map(m => m.value));
    return props => {
        const value = props['aria-valuenow'];
        return (
            <span {...props}>
                {!marksSet.has(value) && <ValueLabel>{valueLabelFormat ? valueLabelFormat(value) : value}</ValueLabel>}
                <ThumbBar />
                <ThumbBar />
            </span>
        );
    };
};

export const Slider = ({ field, value, onChange, min, max, marks, valueLabelFormat, readonly }) => {
    // slider is very choppy without this as entire form is updated for every uncommitted value change
    const [val, setVal] = useState(value);

    if (readonly) {
        const isArray = Array.isArray(value);
        const left = isArray ? value[0] : value;
        const right = isArray ? value[1] : value;

        const getLabel = val => {
            if (val === undefined || val === null) return '';
            return valueLabelFormat ? valueLabelFormat(val) : marks.find(mark => mark.value === val)?.label;
        };

        const leftLabel = getLabel(left);
        const rightLabel = getLabel(right);

        return <DisplayField label={leftLabel === rightLabel ? leftLabel : [leftLabel, rightLabel].join(' - ')} />;
    }

    return (
        <AirbnbSlider
            ThumbComponent={AirbnbThumbComponent({ valueLabelFormat, marks })}
            min={min}
            max={max}
            marks={marks}
            value={val}
            onChange={(e, newVal) => setVal(newVal)}
            onChangeCommitted={(e, newVal) => onChange({ field, value: newVal, errors: null })}
        />
    );
};

// UPLOAD LIST
export const UploadList = ({
    field,
    value = [],
    onChange,
    prompt,
    description,
    ctaLabel = undefined,
    limit = undefined,
    readonly,
    disabled,
    nested = false,
}) => {
    const removeSelf = targetIdx =>
        onChange({
            field,
            value: value.filter((resource, idx) => idx !== targetIdx),
            errors: null,
        });
    const addResource = resource => onChange({ field, value: [...value, resource], errors: null });

    const limitReached = limit && value.length === limit;

    return (
        <Column itemSpacing="small">
            {value.map((resource, index) => (
                <Column key={`${resource.url}_${index}`}>
                    <Uploader
                        onChange={newValue => newValue === null && removeSelf(index)}
                        resource={resource}
                        disabled={disabled}
                        readonly={readonly}
                        nested={nested}
                    />
                </Column>
            ))}
            {!limitReached && !readonly && !disabled && (
                <Uploader
                    prompt={prompt}
                    description={description}
                    onChange={newResource => addResource(newResource)}
                    resource={null}
                    ctaLabel={ctaLabel}
                    nested={nested}
                />
            )}
        </Column>
    );
};

// UPLOAD SINGLE
export const UploadSingle = ({ field, value, onChange, prompt, description, ctaLabel, readonly, disabled, nested }) => {
    return (
        <UploadList
            limit={1}
            field={field}
            value={value ? [{ url: value }] : []}
            onChange={({ field, value, error }) => onChange({ field, value: value[0]?.url ?? null, error })}
            prompt={prompt}
            description={description}
            ctaLabel={ctaLabel}
            readonly={readonly}
            disabled={disabled}
            nested={nested}
        />
    );
};

// IMAGE UPLOAD FIELD
const ImageUploaderField = styled(ImageUploader)`
    ${denseMargin}
`;
export const ImageUploadField = ({ field, value = null, onChange, readonly, disabled, heightRatio }) => {
    return (
        <ImageUploaderField
            onChange={value => onChange({ field, value: value.url, errors: null })}
            resource={typeof value === 'string' ? { url: value } : value}
            disabled={readonly || disabled}
            heightRatio={heightRatio}
        />
    );
};

// COUNTER
// This will align it with the rest of the fields
const CounterInputField = styled(CounterInput)`
    ${denseMargin}
    input {
        font-size: 16px;
    }
    padding: 1px 0;
`;
export const CounterField = ({ field, value = 0, onChange, disabled, min, max, readonly }) => {
    return (
        <CounterInputField
            onCountChange={getBasicOnChange(field, onChange)}
            count={value}
            min={min}
            max={max}
            disabled={disabled}
            readonly={readonly}
        />
    );
};
