import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { format, parseISO } from 'date-fns';
import Grid from '@material-ui/core/Grid';

import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import { useAsync } from 'hooks/useAsync';
import useThemedColor from 'hooks/useThemedColor';
import { getVenueInquiries } from 'api';

import { ReactComponent as VenuePinIconSVG } from 'images/icons/venue_pin.svg';
import { withIconStyles } from 'shared';

import { Button, Column, Row } from 'ui';
import { IconHeadline } from 'components/ui/Headline';
import { SpinnerOverlay } from 'components/Spinner';
import { VenueTileWithoutActions } from 'components/VenueTile/VenueTile';
import { VenueTiles } from './VenueTiles';
import FindVenueField from './FindVenueField';

import { DEFAULT, HAS_BOOKED, HAS_INQUIRIES, NO_CITY, TBookedStatus } from './utils';

const VenuePinIcon = withIconStyles(VenuePinIconSVG);

const CardColumn = styled(Column)`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
`;

const Headline = styled(Row)`
    > * {
        flex-shrink: 1; // TODO: Revisit all IconHeadline with flex-shrink 0
    }
`;

const StyledGrid = styled(Grid)`
    margin: -10px -10px 0;
    width: calc(100% + 20px);
    margin-bottom: -24px;
`;

const VenueSection = ({
    suggestedVenues,
    eventId,
    editable,
    location,
    googlePlaceId,
    startsAt,
    endsAt,
    viewVenueListing,
    viewProposal,
    onSearch,
    onEditInquiries,
    cancelledAt,
}: Partial<Bizly.Event> & {
    eventId: number;
    eventName: string;
    suggestedVenues: Bizly.Venue[];
    viewVenueListing: (venueId: number) => void;
    viewProposal: (proposalId: number) => void;
    onSearch: () => void;
    onEditInquiries: () => void;
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const { highlightedText } = useThemedColor();
    const { toggles, user } = useUser();
    const { onLocationChange } = useEvent();

    const handleFindVenue = async ({
        location: newLocation,
        googlePlaceId: newGooglePlaceId,
    }: Pick<Bizly.Event, 'location' | 'googlePlaceId'>) => {
        const locationChanged = location !== newLocation && googlePlaceId !== newGooglePlaceId;

        try {
            if (!!(newLocation?.length && newGooglePlaceId?.length) && locationChanged) {
                setIsLoading(true);
                await onLocationChange(newLocation, newGooglePlaceId);
            }
            onSearch();
        } finally {
            setIsLoading(false);
        }
    };

    const { data: inquiries, loading: loadingInquiries } = useAsync(
        React.useCallback(() => getVenueInquiries(eventId), [eventId])
    );

    const bookedInquiry = inquiries?.find(inquiry => inquiry.booked);
    const timeFormat = 'MMM do h:mm a';
    const copy = {
        [DEFAULT]: {
            subheading: 'Find a great venue to help make your meeting shine. Here are some suggestions in the area.',
        },
        [NO_CITY]: {
            subheading:
                'Let us know where you’re looking by selecting a city up top, then we’ll help you find a great venue for your meeting.',
        },
        [HAS_INQUIRIES]: {
            subheading:
                'You’ve submitted an inquiry. If venues can accommodate your meeting, they’ll respond with a proposal.',
        },
        [HAS_BOOKED]: {
            subheading: toggles.gate.useActivityCalendar
                ? ''
                : startsAt && endsAt
                ? `Your booking’s confirmed for ${format(parseISO(startsAt), timeFormat)}–${format(
                      parseISO(endsAt),
                      timeFormat
                  )}.`
                : 'Unable to retrieve the dates of your booking.',
        },
    };

    let bookedState: TBookedStatus;

    const hasInquiryVenues = inquiries && inquiries.some(inquiry => inquiry.venue && inquiry.status !== 'Added');

    if (hasInquiryVenues) {
        bookedState = bookedInquiry ? HAS_BOOKED : HAS_INQUIRIES;
    } else {
        bookedState = location ? DEFAULT : NO_CITY;
    }

    const venues = hasInquiryVenues
        ? inquiries?.map(inquiry => ({ status: inquiry.status, ...inquiry.venue }))
        : location
        ? suggestedVenues
        : [];

    const renderVenueCards = () =>
        bookedInquiry ? (
            <VenueTileWithoutActions
                key={bookedInquiry.venue.id}
                venue={{ ...bookedInquiry.venue, status: bookedInquiry.status }}
                format="proposalThumb"
                pillType="inquiryStatus"
                fixedRatio="56%"
                width="100%"
                onClick={viewVenueListing}
            />
        ) : (
            venues && <VenueTiles venues={venues || []} bookedState={bookedState} viewVenueListing={viewVenueListing} />
        );

    if (loadingInquiries) {
        return (
            <Column>
                <IconHeadline icon={<VenuePinIcon />} headline="Venue" description={''} />
                <SpinnerOverlay transparent />
            </Column>
        );
    }

    return (
        <CardColumn itemSpacing="default" justifyContent="space-between">
            <Headline justifyContent="space-between" alignItems="baseline">
                <IconHeadline
                    icon={<VenuePinIcon />}
                    headline="Venue"
                    description={cancelledAt ? 'Your meeting has been cancelled.' : copy[bookedState].subheading}
                    {...(bookedInquiry ? { descriptionColor: highlightedText } : {})}
                />
                {[HAS_BOOKED, HAS_INQUIRIES].includes(bookedState) ? (
                    <Button
                        variant="outlined"
                        onClick={() => {
                            if (bookedState === 'hasBooked') {
                                viewProposal(bookedInquiry?.proposalId as number);
                            } else if (bookedState === HAS_INQUIRIES) {
                                onEditInquiries();
                            } else {
                                onSearch();
                            }
                        }}
                    >
                        {bookedState === HAS_BOOKED || !editable ? 'View' : 'Edit'}
                    </Button>
                ) : null}
            </Headline>

            {![HAS_BOOKED, HAS_INQUIRIES].includes(bookedState) && (
                <FindVenueField
                    location={location}
                    googlePlaceId={googlePlaceId}
                    onFindVenue={handleFindVenue}
                    editable={editable}
                    allowGlobal={user.team?.allowGlobal}
                />
            )}

            <div>
                <StyledGrid container justify="center">
                    {renderVenueCards()}
                </StyledGrid>
            </div>
            {isLoading && <SpinnerOverlay transparent />}
        </CardColumn>
    );
};

export default VenueSection;
