import React from 'react';
import styled from 'styled-components';
import { default as FormBase } from 'components/Form';
import CollaboratorsList from 'components/Playbook/CollaboratorsList';
import ResourcesList from 'components/Playbook/ResourcesList';

const Form = styled(FormBase)`
    width: 500px;
`;

export default function CollaborationForm({
    playbookId,
    readOnly,
}: {
    playbookId: string | number;
    readOnly?: boolean;
}) {
    const fields = {
        collaborators: {
            type: CollaboratorsList,
            prompt: readOnly ? 'Collaborators' : 'Add Collaborators',
            description: readOnly
                ? ''
                : 'Add pre-set collaborators to this playbook so you always have the right people involved to support the meeting.',
            options: { playbookId },
        },
        resources: {
            type: ResourcesList,
            prompt: 'Documents',
            description: readOnly ? '' : 'Pre-attach documents to this playbook.',
            options: { playbookId },
        },
    };

    return (
        <>
            <Form
                fields={fields}
                schema={[{ fields: ['collaborators'] }, { fields: ['resources'] }]}
                value={{}}
                onChange={() => {}}
                readonly={readOnly}
            />
        </>
    );
}
