import React from 'react';
import styled from 'styled-components';

import { useLocation, useHistory } from 'react-router';

import { Column, Row, Spacer } from 'ui';
import { Dialog } from '@material-ui/core';
import TextButton from 'components/ui/Button/TextButton';

import Form from 'components/Form';
import CategoriesField from './CategoriesField';

import { TTemplateFilterValue, getTemplatesUrlFilters, setTemplatesUrlFilters } from './utils';

const fields = {
    tags: {
        prompt: 'Select tags',
        type: CategoriesField,
        options: {
            perRow: 3,
        },
    },
};

const schema = [
    {
        fields: ['tags'],
        spacing: false,
    },
];

const Modal = styled(Column)`
    padding: 36px;
    width: 480px;
`;

export default function FiltersPopover({ onClose }: { onClose: () => void }) {
    const location = useLocation();

    const [filterValue, setFilterValue] = React.useState(getTemplatesUrlFilters(location));
    React.useEffect(() => setFilterValue(getTemplatesUrlFilters(location)), [location]);

    const history = useHistory();
    const onClear = () => setFilterValue(curFilters => ({ ...curFilters, tags: [] }));
    const onApply = () => {
        setTemplatesUrlFilters(history, curFilters => ({ ...curFilters, ...filterValue }));
        onClose();
    };

    return (
        <Dialog open onClose={onClose}>
            <Modal>
                <Form
                    fields={fields}
                    schema={schema}
                    value={filterValue}
                    onChange={({ value }: { value: TTemplateFilterValue }) => setFilterValue(value)}
                />
                <Spacer larger />
                <Row alignItems="center" justifyContent="space-between">
                    <TextButton onClick={onClear} secondary>
                        Clear
                    </TextButton>

                    <Column>
                        <Row itemSpacing="smallish">
                            <TextButton onClick={onClose} secondary>
                                Cancel
                            </TextButton>
                            <TextButton onClick={onApply}>Apply</TextButton>
                        </Row>
                    </Column>
                </Row>
            </Modal>
        </Dialog>
    );
}
