import createStore from 'zustand';

import { withCache } from 'stores/utils';

import { acceptInvite, rejectInvite, loadAttendees, quickInviteAttendee } from 'api/attendees';
import { meetingsActions } from 'stores/meetings';

type State = {
    loading: boolean;
    pendingAttendees: BizlyAPI.BasicAttendee[];
} & (
    | {
          loaded: false;
          meetingId?: string | number;
          attendees: null;
      }
    | {
          loaded: true;
          meetingId: string | number;
          attendees: BizlyAPI.Attendee[];
      }
);
type Store = State;

const initialState: State = {
    attendees: null,
    loaded: false,
    loading: false,
    pendingAttendees: [],
};

export const [useAttendees, attendeesStoreApi] = createStore<Store>(() => initialState);
const loadWithCache = withCache({
    storeApi: attendeesStoreApi,
    cacheFn: state => (state.attendees ? { attendees: state.attendees, meetingId: state.meetingId } : {}),
    key: 'meetingId',
    shouldCache: state => !!state.attendees,
});

const { setState, getState } = attendeesStoreApi;
export const attendeesActions = {
    load: loadWithCache(async (meetingId: string | number) => {
        let { meetingId: curMeetingId } = getState();
        setState({
            loading: true,
            ...(meetingId !== curMeetingId
                ? {
                      attendees: null,
                      meetingId,
                      loaded: false,
                  }
                : {}),
        });

        try {
            const attendees = await loadAttendees(meetingId);

            ({ meetingId: curMeetingId } = getState());
            if (curMeetingId === meetingId)
                setState({
                    attendees,
                    meetingId,
                    loaded: true,
                    loading: false,
                });

            return attendees;
        } catch (e) {
            setState({
                loading: false,
            });
            throw e;
        }
    }),

    invite: async (meetingId: string | number, email: string) => {
        let currentState = getState();
        if (meetingId !== currentState.meetingId || !currentState.loaded) return;

        const newEmail = email;
        setState({
            pendingAttendees: [...currentState.pendingAttendees, { email }],
        });

        try {
            await quickInviteAttendee(meetingId, email);
            const attendees = await loadAttendees(meetingId);

            currentState = getState();
            if (meetingId !== currentState.meetingId || !currentState.loaded) return;

            setState({
                pendingAttendees: currentState.pendingAttendees.filter(item => item.email !== newEmail),
                attendees,
            });
        } catch (e) {
            currentState = getState();
            if (meetingId !== currentState.meetingId || !currentState.loaded) return;

            setState({ pendingAttendees: currentState.pendingAttendees.filter(item => item.email !== newEmail) });
            throw e;
        }
    },

    accept: async (meetingId: string | number, attendeeEmail: string) => {
        let currentState = getState();
        if (meetingId !== currentState.meetingId || !currentState.loaded) return;

        setState({
            pendingAttendees: [...currentState.pendingAttendees, { email: attendeeEmail }],
        });
        try {
            const { meeting } = await acceptInvite(meetingId);

            meetingsActions.merge(meeting);

            currentState = getState();
            if (meetingId !== currentState.meetingId || !currentState.loaded) return;

            setState({
                pendingAttendees: currentState.pendingAttendees.filter(item => item.email !== attendeeEmail),
                attendees: currentState.attendees.map(attendee =>
                    attendee.email !== attendeeEmail ? attendee : { ...attendee, status: 'attending' }
                ),
            });
        } catch (e) {
            currentState = getState();
            if (meetingId !== currentState.meetingId || !currentState.loaded) return;

            setState({ pendingAttendees: currentState.pendingAttendees.filter(item => item.email !== attendeeEmail) });
            throw e;
        }
    },

    reject: async (meetingId: string | number, attendeeEmail: string) => {
        let currentState = getState();
        if (meetingId !== currentState.meetingId || !currentState.loaded) return;

        setState({
            pendingAttendees: [...currentState.pendingAttendees, { email: attendeeEmail }],
        });
        try {
            const { meeting } = await rejectInvite(meetingId);

            meetingsActions.merge(meeting);

            currentState = getState();
            if (meetingId !== currentState.meetingId || !currentState.loaded) return;

            setState({
                pendingAttendees: currentState.pendingAttendees.filter(item => item.email !== attendeeEmail),
                attendees: currentState.attendees.map(attendee =>
                    attendee.email !== attendeeEmail ? attendee : { ...attendee, status: 'not attending' }
                ),
            });
        } catch (e) {
            currentState = getState();
            if (meetingId !== currentState.meetingId || !currentState.loaded) return;

            setState({ pendingAttendees: currentState.pendingAttendees.filter(item => item.email !== attendeeEmail) });
            throw e;
        }
    },
};

export const isPending = (email: string) => getState().pendingAttendees.some(item => item.email === email);
