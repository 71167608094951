import React from 'react';
import styled from 'styled-components';

import { withIconStyles } from '../../shared';
import colorFns from 'colorFns';

import { ReactComponent as DocumentIconSVG } from '../../images/icons/document.svg';
import { Row, Column, Card as UICard } from '../../ui';
import { H3Headline } from '../ui/Headline';

const DocumentIcon = withIconStyles(DocumentIconSVG);

const Card = styled(UICard)`
    justify-content: 'space-between';
`;

const TopRow = styled(Row)`
    margin: 10px 0;

    svg {
        margin-right: 4px;
    }
`;

const BottomRow = styled(Row)`
    height: 100%;
`;

const ImageBox = styled.div`
    height: 114px;
    width: 114px;
    overflow: hidden;
    border-radius: 8px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    margin-right: 24px;

    img {
        height: 100%;
        width: auto;
    }
`;

const CopyColumn = styled(Column)`
    justify-content: space-between;
`;

const TitleCopy = styled.p`
    font-size: 16px;
    font-weight: bold;
    line-height: 1.25;
    letter-spacing: -0.09px;
    color: ${colorFns.accentedHeadline};
    margin: 0;
`;

const DescCopy = styled.p`
    font-size: 15px;
    line-height: 1.47;
    letter-spacing: normal;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
    margin: 4px 0 0 0;
`;

const LinkCopy = styled.a`
    font-size: 15px;
    font-weight: 600;
    line-height: 1;
    color: ${colorFns.primaryAction};
    margin: 12px 0 0 0;
`;

type EventBriefingProps = {
    title?: string;
    desc?: string;
    imageSmall?: string;
    readMoreLink?: string;
};

const EventBriefing = ({ title, desc, imageSmall, readMoreLink }: EventBriefingProps) => (
    <Card>
        <TopRow>
            <DocumentIcon />
            <H3Headline>Meeting Briefing</H3Headline>
        </TopRow>
        <BottomRow>
            {imageSmall && (
                <Column>
                    <ImageBox>
                        <img alt={title} src={imageSmall} />
                    </ImageBox>
                </Column>
            )}
            <CopyColumn>
                <TitleCopy>{title}</TitleCopy>
                <DescCopy>{desc}</DescCopy>
                <LinkCopy href={readMoreLink} target="_blank">
                    Read more
                </LinkCopy>
            </CopyColumn>
        </BottomRow>
    </Card>
);

export default EventBriefing;
