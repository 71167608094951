import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';

import { loadParcels } from 'api';
import { useAsync } from 'hooks/useAsync';
import { withIconStyles } from 'shared';

import { ReactComponent as MegaphoneIconSVG } from 'images/icons/megaphone.svg';

import { IconHeadline } from 'components/ui/Headline';
import { Column, Row } from 'ui';
import { ParcelMatchboxThumb } from 'components/Communication/CommunicationParcel';
import { Link } from 'react-router-dom';
import Button from 'components/ui/Button';
import { padArray } from '../../util';
import { uniqueId } from 'lodash';

const MegaphoneIcon = withIconStyles(MegaphoneIconSVG);

const CardColumn = styled(Column)`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
`;

const ParcelRow = styled(Row)`
    max-width: 100%;
    overflow-x: auto;
`;

const DashboardParcelMatchboxThumb = styled(ParcelMatchboxThumb)`
    width: 71px;
    margin-bottom: 0;
`;

const EmptyParcelMatchbox = styled.div`
    flex-basis: auto;
    flex-shrink: 0;
    flex-grow: 0;
    width: 71px;
    height: 83px;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.softAccentedBackgroundNested)};
    border-radius: 8px;
`;

type TCommunicationSectionProps = {
    editable: boolean;
};

export default function CommunicationSection({ editable }: TCommunicationSectionProps) {
    const { eventId } = useParams();

    const { data: parcels = [] } = useAsync(useCallback(() => loadParcels(eventId), [eventId]));
    const hasParcels = parcels?.length > 0;

    const CTAButton = () => (
        <Link to={`/event/${eventId}/communication`} style={{ width: 'fit-content' }}>
            <Button>{editable ? (hasParcels ? 'Edit' : 'Create') : 'View'}</Button>
        </Link>
    );

    return (
        <CardColumn
            {...(hasParcels ? { itemSpacing: 'larger' } : { itemSpacing: 'default', justifyContent: 'space-between' })}
        >
            <Row alignItems="center" justifyContent="space-between">
                <IconHeadline
                    icon={<MegaphoneIcon />}
                    headline="Communication"
                    description={
                        !hasParcels ? 'Send beautifully branded invites, notes and reminders to your guests.' : ''
                    }
                    flexShrink={1}
                />
                {hasParcels && <CTAButton />}
            </Row>

            <ParcelRow itemSpacing="default">
                {padArray(parcels, 4).map((parcel: any) =>
                    parcel ? (
                        <DashboardParcelMatchboxThumb key={parcel.id} parcel={parcel} eventId={eventId} />
                    ) : (
                        <EmptyParcelMatchbox key={uniqueId()} />
                    )
                )}
            </ParcelRow>
            {!hasParcels && <CTAButton />}
        </CardColumn>
    );
}
