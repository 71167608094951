import { parseISO } from 'date-fns';
import { DATE_USED_TO_FORMAT_TIME } from './utils/date_util';

type EventSpace = {
    name: string;
    setup: string | null;
    start: Date | null;
    end: Date | null;
    attendees: number;
};

type AmenityNeeds = Array<{ id: number; name: string; eventSpace: string }>;

const addEventSpaceToNeeds = (needs: { id: number; name: string }[], eventSpaceName: string) => {
    return needs.map(({ id, name }) => ({
        id,
        name,
        eventSpace: eventSpaceName,
    }));
};

const getInfoFromAgenda = ({ name, startTime, endTime, attendees, setup, avNeeds, fbNeeds }: Bizly.AgendaItem) => {
    return {
        setup,
        eventDetails: {
            name: name,
            setup: setup && setup.name,
            attendees,
            start: startTime ? parseISO(`${DATE_USED_TO_FORMAT_TIME} ${startTime}`) : null,
            end: endTime ? parseISO(`${DATE_USED_TO_FORMAT_TIME} ${endTime}`) : null,
        },
        avNeeds: addEventSpaceToNeeds(avNeeds, name),
        fbNeeds: addEventSpaceToNeeds(fbNeeds, name),
    };
};

const getInfoFromAgendas = (agendas: Bizly.AgendaItem[]) => agendas.map(agenda => getInfoFromAgenda(agenda));

const getPlannerInfoFromDay = (day: Bizly.Day) => getInfoFromAgendas(day.agenda);

const consolidatePlannerItems = (
    plannerItems: { eventDetails: EventSpace; avNeeds: AmenityNeeds; fbNeeds: AmenityNeeds }[]
) => {
    return plannerItems.reduce<{ eventSpaces: EventSpace[]; avNeeds: AmenityNeeds; fbNeeds: AmenityNeeds }>(
        (previous, current) => {
            return {
                eventSpaces: [...previous.eventSpaces, current.eventDetails],
                avNeeds: [...previous.avNeeds, ...current.avNeeds],
                fbNeeds: [...previous.fbNeeds, ...current.fbNeeds],
            };
        },
        { eventSpaces: [], avNeeds: [], fbNeeds: [] }
    );
};

const combineNeeds = (needs: AmenityNeeds) => {
    const tempNeeds: { [id: string]: { name: string; eventSpaces: string[] } } = {};

    needs.forEach(({ id, name, eventSpace }) => {
        if (!tempNeeds[id]) {
            tempNeeds[id] = {
                name,
                eventSpaces: [eventSpace],
            };
        } else {
            tempNeeds[id].eventSpaces.push(eventSpace);
        }
    });

    const finalNeeds = Object.keys(tempNeeds).map(needId => ({
        id: needId,
        name: tempNeeds[needId].name,
        eventSpaces: tempNeeds[needId].eventSpaces,
    }));

    return finalNeeds;
};

const combineAvAndFbNeeds = (consolidatedPlannerItems: {
    eventSpaces: EventSpace[];
    avNeeds: AmenityNeeds;
    fbNeeds: AmenityNeeds;
}) => ({
    ...consolidatedPlannerItems,
    avNeeds: combineNeeds(consolidatedPlannerItems.avNeeds),
    fbNeeds: combineNeeds(consolidatedPlannerItems.fbNeeds),
});

const getPlannerItemsFromDay = (day: Bizly.Day) => ({
    ...combineAvAndFbNeeds(consolidatePlannerItems(getPlannerInfoFromDay(day))),
    guestRoomCount: day.guestrooms && day.guestrooms.length ? day.guestrooms[0].count : 0,
});

export default getPlannerItemsFromDay;
