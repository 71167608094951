import React from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { emailIsValid } from 'utils';

import { searchCollaborators } from 'api';
import { attendeesActions, LoadAttendees, useAttendees } from 'stores/attendees';

import { Column, Copy, Row } from 'ui';
import SmallCircleButton from 'components/ui/SmallCircleButton';
import AutoComplete from 'components/ui/AutoComplete';
import { Link, LinkProps } from 'react-router-dom';
import { Dialog, Popover } from '@material-ui/core';
import AttendeesLists from './AttendeesLists';
import useShowModal from 'hooks/useShowModal';

type TOption = PartialExcept<BizlyAPI.EventCollaboratorSuggestion, 'email'>;

const AutoRow = styled(Row)`
    width: auto;
`;

const AttendeesPopover = styled(Popover)`
    pointer-events: none;

    .MuiPaper-root {
        max-height: 50vh;
    }
`;

const AttendeesDialog = styled(Dialog)`
    .MuiPaper-root {
        max-height: 50vh;
    }
`;

export default function QuickInviteAttendees({
    meetingId,
    editable,
    disableInvites,
    editAttendeesLocation,
}: {
    meetingId: string | number;
    editable?: boolean;
    disableInvites?: boolean;
    editAttendeesLocation?: LinkProps['to'];
}) {
    const attendeesStore = useAttendees();
    const attending = attendeesStore.attendees?.filter(attendee => attendee.status === 'attending') || [];
    const attendingCount = attending.length;
    const attendees = attendeesStore.attendees ?? [];

    const { enqueueSnackbar } = useSnackbar();
    const addAttendee = async (newAttendee: BizlyAPI.BasicAttendee) => {
        try {
            await attendeesActions.invite(meetingId, newAttendee.email);
            enqueueSnackbar(`${newAttendee.email} invited.`, { variant: 'info' });
        } catch {
            enqueueSnackbar(`Could not invite ${newAttendee.email}. Please try again.`, { variant: 'error' });
        }
    };

    const curAttendeesEmailsSet = new Set([
        ...(attendeesStore.attendees?.map(attendee => attendee.email.toLowerCase()) ?? []),
        ...attendeesStore.pendingAttendees.map(attendee => attendee.email.toLowerCase()),
    ]);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<TOption[]>([]);
    const [value, setValue] = React.useState<TOption>({ email: '' });

    async function handleSuggestions(query: string) {
        if (!query) {
            setLoading(false);
        }
        setValue({ email: query });
        if (query?.length < 3) {
            setOptions([]);
        }
        setLoading(true);
        const response = await searchCollaborators(query);
        setLoading(false);

        const suggestions = response.suggestions.filter(
            suggestion => !curAttendeesEmailsSet.has(suggestion.email.toLowerCase())
        );

        const queryIsValidEmail = emailIsValid(query);
        const emailIsNew =
            queryIsValidEmail && !suggestions.find(response => response.email.toLowerCase() === query.toLowerCase());

        setOptions([...(emailIsNew ? [{ email: query }] : []), ...suggestions]);
    }

    const clear = () => {
        setValue({ email: '' });
        setLoading(false);
    };

    const { modalShown, hideModal, showModal } = useShowModal();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const CounterPopover = () =>
        attendeesStore.loaded && attendees.length > 0 ? (
            <AttendeesPopover
                open={!!anchorEl}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={() => setAnchorEl(null)}
                disableRestoreFocus
            >
                <AttendeesLists attendees={attendees} />
            </AttendeesPopover>
        ) : null;

    const CounterDialog = () => (
        <AttendeesDialog open={modalShown} onBackdropClick={hideModal}>
            <AttendeesLists attendees={attendees} full />
        </AttendeesDialog>
    );

    const attendeeCounter = (
        <>
            <SmallCircleButton
                secondary={false}
                onClick={editable ? () => {} : showModal}
                onMouseEnter={event => setAnchorEl(event.currentTarget)}
                onMouseLeave={() => setAnchorEl(null)}
                disabled={!attendeesStore.loaded}
            >
                {attendeesStore.loaded ? attendingCount : <>&nbsp;</>}
            </SmallCircleButton>
            <CounterPopover />
            <CounterDialog />
        </>
    );

    return (
        <AutoRow alignItems="center" itemSpacing="smallish">
            <LoadAttendees meetingId={meetingId} />
            {editable && editAttendeesLocation ? (
                <Link to={editAttendeesLocation}>{attendeeCounter}</Link>
            ) : (
                attendeeCounter
            )}
            {editable && !disableInvites && (
                <AutoComplete
                    style={{ width: 240 }}
                    disabled={!attendeesStore.loaded}
                    blurOnSelect
                    options={options}
                    loading={loading}
                    getOptionLabel={option => option?.email?.toString() || ''}
                    getOptionSelected={option => attendees.some(p => p.email === option.email)}
                    noOptionsText={
                        (value?.email?.length ?? 0) < 3
                            ? 'A minimum of 3 characters is required to search'
                            : 'No results'
                    }
                    value={value}
                    onChange={(_, newValue) => {
                        addAttendee(newValue);
                        clear();
                    }}
                    asField
                    inModalOrPopover
                    InputProps={{
                        autoFocus: true,
                        placeholder: 'Invite by name or email',
                    }}
                    onKeyUp={e => {
                        if (e.key === 'Enter' && emailIsValid(value.email)) {
                            addAttendee(value);
                            clear();
                        }
                    }}
                    filterOptions={o => o}
                    onInputChange={handleSuggestions}
                    renderOption={contact => (
                        <Column itemSpacing="xsmall">
                            <b>
                                <Copy>{[contact.firstName, contact.lastName].join(' ')}</Copy>
                            </b>
                            <Copy>{contact.email}</Copy>
                        </Column>
                    )}
                />
            )}
        </AutoRow>
    );
}
