import { get, put } from '.';

export const getTasklist = (eventId: number | string) =>
    get(`events/${eventId}/tasklists`) as Promise<{ tasklists: BizlyAPI.TaskList[] }>;

export const updateTask = ({
    eventId,
    tasklistId,
    taskId,
    task,
}: {
    eventId: number | string;
    tasklistId: number | string;
    taskId: number | string;
    task: { completed?: boolean };
}) => put(`events/${eventId}/tasklists/${tasklistId}/tasks/${taskId}`, task) as Promise<{ task: BizlyAPI.Task }>;
