import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useSnackbar } from 'notistack';

import { padArray, parcelType } from 'utils';
import { contentWidth } from 'shared';
import { deleteParcel, loadParcels, copyParcel } from 'api';
import { getPlaybookParcels, deletePlaybookParcel } from 'api/playbooks';

import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import { selectMeeting, useMeetings } from 'stores/meetings';

import { Column, Copy, Grid, MatchboxPlaceholder, Row, SpacedRow, Spacer } from 'ui';
import { LargeHeadline } from 'components/ui/Headline';
import { Link, Redirect } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import { Tooltip } from '@material-ui/core';

import { ButtonMenu } from 'components/ButtonMenu';
import { ProTip } from 'components/ProTip';
import { Spinner } from 'components/Spinner';
import ParcelMatchbox from 'components/Communication/CommunicationParcel';
import { DeleteConfirmationModal } from 'components/DeleteConfirmationModal';

const Main = styled(Column)`
    width: 100%;
`;

const Tips = styled(Column)`
    width: 440px;

    div:not(:last-child) {
        margin-bottom: 24px;
    }
`;

const Content = styled(Row)`
    justify-content: space-between;
`;

const CreateMenuEvent = ({ eventId }: { eventId?: string | number }) => {
    const meeting = useMeetings(selectMeeting(eventId));
    const { toggles } = useUser();

    return !meeting ? (
        <Spinner />
    ) : (
        <ButtonMenu label="Create">
            {meeting.cancelledAt ? (
                <Tooltip title="Meeting is cancelled" placement="top">
                    <div>
                        <MenuItem disabled>Invite</MenuItem>
                    </div>
                </Tooltip>
            ) : (
                <Link to={`/event/${eventId}/communication/create/invite`}>
                    <MenuItem>Invite</MenuItem>
                </Link>
            )}
            <Link to={`/event/${eventId}/communication/create/note`}>
                <MenuItem>{toggles.gate.surveysEnabled ? 'Note / Survey' : 'Note'} </MenuItem>
            </Link>
        </ButtonMenu>
    );
};

//TODO possibly move routes to parent and pass as props (See EVENT.tsx)
const CreateMenuPlaybook = ({ playbookId }: { playbookId?: string | number }) => {
    const { toggles } = useUser();
    return (
        <ButtonMenu label="Create">
            <Link to={`/playbooks/${playbookId}/edit/communication/create/invite`}>
                <MenuItem>Invite</MenuItem>
            </Link>

            <Link to={`/playbooks/${playbookId}/edit/communication/create/note`}>
                <MenuItem>{toggles.gate.surveysEnabled ? 'Note / Survey' : 'Note'} </MenuItem>
            </Link>
        </ButtonMenu>
    );
};

type CommunicationProps = {
    playbookId?: number | string;
    readonly?: boolean;
    asWizardModule?: boolean;
};

export default function Communication({ playbookId, readonly = false, asWizardModule = false }: CommunicationProps) {
    const { user, toggles } = useUser();
    const { event } = useEvent();
    const { enqueueSnackbar } = useSnackbar();

    const [deleteIntent, setDeleteIntent] = useState();
    const [parcels, setParcels] = useState([]);

    const getParcels = playbookId ? getPlaybookParcels : loadParcels;
    const removeParcel = playbookId ? deletePlaybookParcel : deleteParcel;
    const id = playbookId ?? event.id;

    const editLinkDestination = (parcel: Bizly.Parcel) => {
        if (playbookId) {
            if (readonly) {
                if (asWizardModule) {
                    return `/playbooks/${playbookId}/edit/communication/${parcelType(parcel)}/${parcel.id}`;
                }
                return `/playbooks/${playbookId}/communication/${parcelType(parcel)}/${parcel.id}`;
            }
            return `/playbooks/${playbookId}/edit/communication/edit/${parcelType(parcel)}/${parcel.id}`;
        }

        return `/event/${event.id}/communication/edit/${parcelType(parcel)}/${parcel.id}`;
    };

    const load = useCallback(async () => {
        let parcelsResponse;
        try {
            parcelsResponse = await getParcels(id);
        } catch (e) {
            enqueueSnackbar(`Could not fetch parcels for ${playbookId ? `playbook  ${id}` : `meeting ${id}`}`);
        }

        if (playbookId) {
            setParcels(parcelsResponse.parcels);
        } else {
            setParcels(parcelsResponse);
        }
    }, [enqueueSnackbar, getParcels, id, playbookId]);

    useEffect(() => {
        load();
    }, [load]);

    const handleCopyClick = async (parcel: any) => {
        await copyParcel(parcel.id);
        load();
    };

    const handleDeleteClick = (parcel: any) => {
        setDeleteIntent(parcel);
    };

    const handleDeleteConfirm = async () => {
        await removeParcel(id, deleteIntent);
        load();
        setDeleteIntent(null);
    };

    const handleDeleteDismiss = () => {
        setDeleteIntent(null);
    };

    const proTips = user?.team?.proTips.filter(proTip => proTip.section === 'communications') || [];

    if (toggles.gate.createMeetingsFlow) {
        return <Redirect to={`/events/${event.id}`} push={false} />;
    }

    if (!parcels) {
        return <Spinner />;
    }

    return (
        <Main>
            <DeleteConfirmationModal
                onDismiss={handleDeleteDismiss}
                onProceed={handleDeleteConfirm}
                prompt={`Deleting this email will remove it from your ${
                    playbookId ? 'playbook' : 'meeting'
                }. This process cannot be undone.`}
                isActive={!!deleteIntent}
            />
            <SpacedRow>
                {!playbookId ? (
                    <div>
                        <LargeHeadline>Communication</LargeHeadline>

                        <div style={{ width: contentWidth }}>
                            <Copy>
                                Let’s get your message out there. Here you can create branded invites and send meeting
                                updates.
                            </Copy>
                        </div>
                    </div>
                ) : (
                    <div />
                )}
                {!readonly &&
                    (playbookId ? (
                        <CreateMenuPlaybook playbookId={id} />
                    ) : (
                        event.editable && <CreateMenuEvent eventId={id} />
                    ))}
            </SpacedRow>
            <Spacer />
            <Content>
                <Grid style={{ width: '696px', justifyContent: 'flex-start' }}>
                    {padArray(parcels ?? [], readonly ? 4 : 4 * 2).map((parcel: any) =>
                        !parcel ? (
                            <MatchboxPlaceholder style={{ pointerEvents: 'none' }} key={Math.random()} />
                        ) : (
                            <ParcelMatchbox
                                key={parcel.id}
                                editable={playbookId ? true : event.editable}
                                onCopy={handleCopyClick}
                                onDelete={handleDeleteClick}
                                parcel={parcel}
                                readonly={readonly}
                                editParcelLink={editLinkDestination(parcel)}
                            />
                        )
                    )}
                </Grid>
                {proTips && !readonly && (
                    <Tips>
                        {proTips.map(proTip => (
                            <ProTip key={proTip.id} message={proTip.content} />
                        ))}
                    </Tips>
                )}
            </Content>
        </Main>
    );
}
