import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import * as URI from 'uri-js';

import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import { createVirtualMeeting, updateVirtualMeeting } from 'api';
import { Column, Row, Copy, Button } from 'ui';
import TextButton from 'components/ui/Button/TextButton';
import { defaultVMFields, VMSchemaLink, VMSchemaNotes } from 'components/Schedule/scheduleFormSchema';
import { Headline as UIHeadline, H3Headline } from 'components/ui/Headline';
import { SpinnerOverlay as UISpinnerOverlay } from 'components/Spinner';
import { ProTipsDropdown, filterProtips } from 'components/ProTip';
import Form from 'components/Form';
import colorFns from 'colorFns';

const Container = styled(Column)<{ asModalContent?: boolean }>`
    border-radius: 8px;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    overflow: hidden;
    ${({ asModalContent }) => (asModalContent ? `max-height: 100%;` : '')}
`;

const SpinnerOverlay = styled(UISpinnerOverlay)`
    z-index: 99;
`;

const VirtualContentContainer = styled(Column)<{ asModalContent?: boolean }>`
    ${({ asModalContent }) =>
        asModalContent
            ? `
    flex: 1 1 auto;
    min-height: 0;
    `
            : ''}
`;

const Head = styled(Row)`
    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formHeading)};
    padding: 16px;
    width: auto;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
`;

const Headline = styled(UIHeadline)`
    font-size: 24px;
    font-weight: 500;
`;

const Body = styled(Column)`
    padding: 16px;
    margin-top: 0;

    flex: 1 1 auto;
    overflow: auto;
`;

const Bottom = styled(Row)`
    border-top: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.softBorder)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formHeading)};
    padding: 16px;
    width: auto;
    overflow: hidden;
    border-radius: 0 0 8px 8px;

    background: ${colorFns.pureWhite};
    z-index: 1;
`;

const RecServiceCol = styled(Column)`
    max-width: 50%;
`;

const RecServiceRow = styled(Row)`
    > ${RecServiceCol} {
        flex-grow: 1;
    }
`;

const AddButton = styled(Button)`
    border-radius: 4px;
    padding: 2px;
`;

const CancelButton = styled(TextButton)`
    font-weight: normal;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.secondaryTextAction)};
`;

const PreferredService = ({ name, notes }: Partial<Bizly.VirtualMeetingServiceProvider>) => (
    <RecServiceCol itemSpacing="small">
        <Copy>
            <strong>{name}</strong>
        </Copy>
        <Copy>{notes}</Copy>
    </RecServiceCol>
);

const CALENDAR_PAGE = 'planner', // TODO: Update this to match what the database stores for this new page once that is ready
    VM_SECTION = 'virtualMeetings';

type TVMValue = Partial<Bizly.VirtualMeeting>;

export const ManageVirtualMeetingComponent = ({
    template,
    disabled,
    value,
    onChange,
    onClose,
    onApply,
    saveCta,
    videoConferencingText,
    asModalContent,
}: {
    template?: Bizly.EventTemplate;
    disabled?: boolean;
    value?: TVMValue | null;
    onChange: (newData: TVMValue) => void;
    onApply: () => void;
    onClose: () => void;
    saveCta?: string;
    videoConferencingText?: boolean;
    asModalContent?: boolean;
}) => {
    const { user, toggles } = useUser();

    const serviceProviders = user.team?.virtualMeetingServiceProviders || [];
    const { recommendationCopy } = toggles?.compliance?.virtual || {};
    const preferredServices = useMemo(() => serviceProviders.filter(sp => sp.preferred).slice(0, 3), [
        serviceProviders,
    ]);
    const VMFields = useMemo(
        () =>
            defaultVMFields(
                serviceProviders.map(({ name, ...provider }) => ({
                    ...provider,
                    name: provider.preferred ? name + ' *' : name,
                }))
            ),
        [serviceProviders]
    );

    const vmProTips = useMemo(
        () => filterProtips([...user.team?.proTips, ...(template?.proTips ?? [])], CALENDAR_PAGE, VM_SECTION),
        [user.team, template]
    );

    const handleUpdateVM = ({ value }: { value: Partial<Bizly.VirtualMeeting> }) => onChange(value);

    return (
        <VirtualContentContainer asModalContent={asModalContent}>
            <Head justifyContent="space-between">
                <H3Headline>{videoConferencingText ? 'Recommendation' : 'Virtual Meeting Recommendation'}</H3Headline>
                <ProTipsDropdown protips={vmProTips} />
            </Head>
            <Body itemSpacing="smallish">
                {recommendationCopy && (
                    <>
                        <Headline>Recommendation</Headline>
                        <Copy>{recommendationCopy}</Copy>
                    </>
                )}

                {!!preferredServices?.length && (
                    <RecServiceRow itemSpacing="small">
                        {preferredServices.map(({ name, notes }) => (
                            <PreferredService name={name} notes={notes} />
                        ))}
                    </RecServiceRow>
                )}

                <Column itemSpacing="large">
                    <div>
                        <Form
                            fields={VMFields}
                            schema={VMSchemaLink}
                            value={value}
                            onChange={handleUpdateVM}
                            disabled={disabled}
                        />
                    </div>
                    <Form
                        fields={VMFields}
                        schema={VMSchemaNotes}
                        value={value}
                        onChange={handleUpdateVM}
                        disabled={disabled}
                    />
                </Column>
            </Body>
            <Bottom justifyContent="space-between">
                <CancelButton onClick={onClose}>Cancel</CancelButton>
                {!disabled && <AddButton onClick={onApply}>{value?.id ? 'Save' : saveCta ?? 'Add'}</AddButton>}
            </Bottom>
        </VirtualContentContainer>
    );
};

const ManageVirtualMeeting = ({
    onSave,
    onClose,
    asModalContent,
}: {
    onSave?: (virtualMeeting: Bizly.VirtualMeeting) => void;
    onClose: () => void;
    asModalContent?: boolean;
}) => {
    const { event, template, refreshEvent } = useEvent();
    const [stagedVMData, setStagedVMData] = useState<Partial<Bizly.VirtualMeeting>>(event.virtualMeeting || {});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();

    const getErrorVirtualMeeting = () => {
        if (!stagedVMData.link) {
            return 'A link is required for a virtual meeting';
        }

        const parsedLink = URI.parse(stagedVMData.link);
        if (!parsedLink.scheme) {
            return 'Make sure the link includes the protocol (http or https)';
        }

        return false;
    };

    const handleSaveVM = async () => {
        const validationError = getErrorVirtualMeeting();
        if (validationError) {
            enqueueSnackbar(validationError, { variant: 'error' });
            return;
        }

        setIsLoading(true);
        try {
            if (!!stagedVMData.id) {
                const { virtualMeeting: updatedVM } = await updateVirtualMeeting(
                    event.id,
                    stagedVMData,
                    stagedVMData.id
                );
                if (onSave) onSave(updatedVM);
            } else {
                const { virtualMeeting: newVM } = await createVirtualMeeting(event.id, stagedVMData);
                if (onSave) onSave(newVM);
            }
            refreshEvent();
            onClose();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container asModalContent={asModalContent}>
            {isLoading && <SpinnerOverlay />}
            <ManageVirtualMeetingComponent
                template={template}
                disabled={!event.editable}
                value={stagedVMData}
                onChange={setStagedVMData}
                onClose={onClose}
                onApply={handleSaveVM}
                asModalContent={asModalContent}
            />
        </Container>
    );
};

export default ManageVirtualMeeting;
