import React from 'react';
import styled, { css } from 'styled-components';

import moment from 'moment';
import { useParams } from 'react-router';
import { connectorToProviderId, getServiceProviderId } from 'utils/virtualMeeting';

import { useUser } from 'providers/user';
import { createMeetingActions, useCreateMeeting } from './store';
import { selectMeeting, useMeetings } from 'stores/meetings';

import { Column, Copy, CopyFaded, Row, Spacer } from 'ui';
import TextButton from 'components/ui/Button/TextButton';
import Button from 'components/ui/Button';
import FormBase from 'components/Form';
import ParcelPreviewCard, { PREVIEW_CARD_WIDTH } from 'pages/ParcelPreviewCard';
import MeetingDetail from 'components/MeetingDetails';
import { H3Headline } from 'components/ui/Headline';

import { ReactComponent as BackArrowSvg } from 'images/icons/StealthLeftArrow.svg';
import { ReactComponent as CalendarIconSvg } from 'images/icons/calendar.svg';
import { ReactComponent as RegistrationIconSvg } from 'images/icons/registration.svg';
import colorFns from 'colorFns';
import useShowModal from 'hooks/useShowModal';
import TeamSettingsPrompt from 'components/TeamSettingsPrompt';
import { nudgesActions, useNudges } from 'stores/nudges';

const FormCol = styled(Column)`
    height: 100%;
`;

const PreviewCol = styled(Column)`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: ${colorFns.softAccentedBackground};
`;

const FormPage = styled(Row)`
    ${FormCol} {
        border-right: 1px solid ${colorFns.softBorder};
        padding-right: 54px;
        position: sticky;
        top: 0px;
        height: 100vh;
        box-sizing: border-box;
        padding-top: 140px;

        margin-left: -16px;
    }

    ${PreviewCol} {
        padding-left: 54px;
        padding-top: 100px;
    }
`;

const Form = styled(FormBase)`
    width: ${PREVIEW_CARD_WIDTH}px;
`;

const Title = styled(H3Headline)`
    font-size: 22px;
`;

const MeetingDetailsCol = styled.div`
    max-width: 460px;
`;

const ButtonName = styled(Copy)`
    font-weight: 500;
`;

const StyledButton = styled(Button)`
    width: 316px;
    height: 100px;
    color: ${colorFns.darkerGrey} !important;
    padding: 16px;
`;

const StyledText = styled(TextButton)`
    width: 316px;
    height: 100px;
    color: ${colorFns.darkerGrey} !important;
    padding: 16px;
    border: 1px solid transparent;
`;

const ButtonText = styled(CopyFaded)`
    white-space: normal;
    text-align: left;
`;

const FillCol = styled(Column)`
    width: 100%;
`;

const ButtonContent = ({ icon, name, text }: { icon?: React.ReactNode; name?: string; text?: string }) => {
    return (
        <Row itemSpacing="small">
            <Column>{icon}</Column>
            <FillCol alignItems="flex-start" itemSpacing="small">
                <Row justifyContent="space-between" alignItems="center">
                    <ButtonName large>{name}</ButtonName>
                    <BackArrowIcon />
                </Row>
                <ButtonText>{text}</ButtonText>
            </FillCol>
        </Row>
    );
};

type TextButtonProps = Parameters<typeof TextButton>[any];
const SpecialButton = (props: {
    selected?: boolean;
    onClick: TextButtonProps['onClick'];
    children: React.ReactNode;
}) => {
    if (props.selected) {
        const { selected, ...rest } = props;
        return <StyledButton {...rest} disabled />;
    }
    const { selected, ...rest } = props;
    return <StyledText {...rest} secondary />;
};

export type TInviteValue = { image?: string | null; description?: string | null; type?: 'simple' | 'custom' };

const BackArrowIcon = styled(BackArrowSvg)`
    height: 11px;
    width: 20px;
    transform: rotateZ(180deg);
`;

const IconStyles = css`
    width: 24px;
    height: 24px;
`;

const CalendarIcon = styled(CalendarIconSvg).attrs(() => ({ viewBox: '0 4 21 21' }))`
    ${IconStyles}
`;
const RegistrationIcon = styled(RegistrationIconSvg)`
    ${IconStyles}
`;

const fields = {
    image: {
        prompt: 'Invite Image',
        type: 'upload_single',
        options: {
            prompt: 'Image for invite background',
            ctaLabel: 'Add Image',
            nested: true,
        },
    },
    description: {
        prompt: 'Invite Description',
        type: 'rich_text',
        options: {
            backgroundWhite: true,
        },
    },
};

const schema = [
    { fields: ['image'], spacing: 'default' },
    { fields: ['description'], spacing: false },
];

export default function InvitesForm() {
    const { basicInfo, agenda, invite, inviteDescriptionChanged } = useCreateMeeting();

    const { user } = useUser();

    const { startDate, endDate, startTime, endTime, ...basic } = basicInfo;

    const start = startDate ? moment(startDate) : '';
    const end = endDate ? moment(endDate) : '';
    const dates =
        start && end && startTime && endTime
            ? {
                  startDate: start?.format('MM-DD-YYYY'),
                  endDate: end?.format('MM-DD-YYYY'),
                  startTime,
                  endTime,
              }
            : null;

    const serviceProviders = user.team?.virtualMeetingServiceProviders || [];

    const providerId = basic.virtualMeeting?.deferredService
        ? connectorToProviderId(basic.virtualMeeting?.deferredService)
        : basic.virtualMeeting?.link
        ? getServiceProviderId(basic.virtualMeeting.link)
        : undefined;
    const serviceProvider = serviceProviders.find(provider => providerId === provider.id);

    const { id } = useParams<{ id?: string }>();
    const meeting = useMeetings(selectMeeting(id));

    React.useEffect(() => {
        if (!inviteDescriptionChanged) {
            createMeetingActions.copyPurpose();
        }
    }, [inviteDescriptionChanged, basic.purpose]);

    const { modalShown, showModal, hideModal } = useShowModal();
    const { showTeamSettings } = useNudges();
    const useCustomInvite = showTeamSettings
        ? () => {
              showModal();
          }
        : () => createMeetingActions.setInviteType('custom');
    React.useEffect(() => {
        if (modalShown && !showTeamSettings) {
            createMeetingActions.setInviteType('custom');
            hideModal();
        }
    }, [modalShown, hideModal, showTeamSettings]);

    const teamSettingsModal = modalShown && (
        <TeamSettingsPrompt
            title="First, Update Your Team's Settings"
            prompt="Custom invites use your team's logo and color."
            onCancel={hideModal}
            onIgnore={() => nudgesActions.mergeNudges({ showTeamSettings: false })}
        />
    );

    const CustomInvite = (
        <>
            <Form
                fields={fields}
                schema={schema}
                value={invite}
                onChange={({ field, value }: { field: keyof TInviteValue; value: TInviteValue }) =>
                    createMeetingActions.setInvite(value, field === 'description')
                }
            />
            <Spacer larger />
            <ParcelPreviewCard
                name={basic.name || ''}
                image={invite.image || ''}
                description={invite.description || ''}
                parcelDates={dates}
                useParcelDates
                plannedBy={meeting?.createdBy || user}
                vmSharingMethod="share"
                virtualMeeting={serviceProvider ? { ...basic.virtualMeeting, serviceProvider } : undefined}
            />
            <Spacer largest />
        </>
    );

    const SimpleInvite = (
        <MeetingDetailsCol>
            {basicInfo.name && <Title>{basicInfo.name}</Title>}
            <Spacer large />
            <MeetingDetail meeting={{ ...basicInfo, agenda }} />
        </MeetingDetailsCol>
    );

    return (
        <FormPage>
            <FormCol>
                <Column itemSpacing="default" alignItems="center">
                    <SpecialButton
                        selected={invite.type === 'simple'}
                        onClick={() => createMeetingActions.setInviteType('simple')}
                    >
                        <ButtonContent
                            icon={<CalendarIcon />}
                            name="Send Calendar Invite"
                            text="Attendees receive a simple email to view the meeting in Bizly."
                        />
                    </SpecialButton>
                    <SpecialButton selected={invite.type === 'custom'} onClick={useCustomInvite}>
                        <ButtonContent
                            icon={<RegistrationIcon />}
                            name="Send Custom Invite"
                            text="Attendees receive a custom email to view the meeting in Bizly."
                        />
                    </SpecialButton>
                </Column>
            </FormCol>

            <PreviewCol>
                {invite.type === 'custom' && CustomInvite}
                {invite.type === 'simple' && SimpleInvite}
            </PreviewCol>

            {teamSettingsModal}
        </FormPage>
    );
}
