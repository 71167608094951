import React from 'react';
import styled from 'styled-components';
import { getExtension } from 'cloudinary';

import { Column, CopyFaded, ExternalLink, Spacer, TopRounded } from 'ui';
import { H3Headline } from 'components/ui/Headline';
import LongButton from 'components/ui/Button/LongButton';
import { Link, LinkProps } from 'react-router-dom';

const AddButton = styled(LongButton)`
    max-width: 215px;
`;

export default function DocumentsCard({
    attachments,
    editable,
    editDocsLocation,
}: {
    attachments: BizlyAPI.Meeting['attachments'];
    editable?: boolean;
    editDocsLocation: LinkProps['to'];
}) {
    return (
        <Column itemSpacing="small">
            <H3Headline>Documents</H3Headline>
            <CopyFaded small>These are documents you would like to share with team for meeting</CopyFaded>
            <Spacer xsmall />
            {attachments?.map(({ url, title }) => (
                <ExternalLink href={url} openInNewTab underline>
                    <TopRounded>{[title, url && getExtension(url)].join('.')}</TopRounded>
                </ExternalLink>
            ))}
            {editable && (
                <Link to={editDocsLocation}>
                    <AddButton isSecondary onClick={() => {}}>
                        Add Document
                    </AddButton>
                </Link>
            )}
        </Column>
    );
}
