import React from 'react';

import { useCreatePlaybook } from './store';

import { Spinner } from 'components/Spinner';
import CollaborationForm from './CollaborationForm';

export default function Collaboration() {
    const { id: playbookId } = useCreatePlaybook();
    if (!playbookId) return <Spinner />;
    return <CollaborationForm playbookId={playbookId} />;
}
